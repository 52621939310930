import React from 'react';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  label: string;
  hidden?: boolean;
  mask?: boolean; // FullStory fs-mask
  className?: string; // className of root <div>
  id?: string; // id of root <div>
  forId?: string; // <label for="id">
  children?: React.ReactNode;
}

// <Labler>
//  pass value as children
export const Labler: React.FunctionComponent<Props> = ({
  label,
  hidden = false,
  className,
  id,
  forId,
  children,
  mask,
  ...rest
}) => {
  let additionalClassNames = className ? ` ${className}` : '';
  if (mask) {
    additionalClassNames += ' fs-mask';
  }

  return (
    <>
      {!hidden && (
        <div id={id} className={`labler${additionalClassNames}`} {...rest}>
          <label htmlFor={forId}>{label}</label>
          <div className="value" data-auto={generateDataAuto('label', label)}>
            {children}
          </div>
        </div>
      )}
    </>
  );
};
