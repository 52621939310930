// TODO: Should be some translation here
import { GenericResponse } from 'src/js/model/api/response/generic-response';

/**
 * Represents any `ApiSauce` server error.
 */
// TODO: We should probably have some better typing here
// DEPRECATED: Once we migrate away and use the generic network functions, we can delete this block.
export class ServerError extends Error {
  constructor(data: GenericResponse | any, status: number) {
    let message;
    if (data) {
      if (data.message) {
        message = data.message;
      } else if (typeof data === 'string' && data.substr(0, 14) !== '<?xml version=') {
        message = data;
      } else if (typeof data === 'object' && typeof data.error === 'string') {
        message = data.error;
      }
    } else if (status == 403) {
      /* eslint-disable-next-line no-console */
      console.warn('Not enough permission to perform operation.');
    }

    const prefix = 'Error: ';
    if (message.startsWith(prefix)) {
      message = message.substring(prefix.length);
    }
    super(message);
  }
}
