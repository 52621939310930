import { DropdownButton, DropdownMenuItem, FormGroup, FormLabel, Icon, IconButton, PanelItem } from '@hai/ui-react';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { isNil } from 'ramda';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Subject } from 'rxjs';
import { AudioMeter } from 'src/js/component/base/audio-meter';
//import { FormSelect } from 'src/js/component/base/form/form-select';
import { SDIType, SdiCheckbox } from 'src/js/component/base/form/sdi-checkbox';
import { FormAggregatorClientInterface } from 'src/js/component/multiple-form-aggregator';
import { triggerEvent, watchEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useStores } from 'src/js/hook/use-stores';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import {
  DecoderAction,
  DecoderViewModel,
  QuadModeEnum,
  decoderIsStopped,
  decoderStateToIcon,
  decoderStateToHintText,
} from 'src/js/pages/streaming/decoder/decoder-model';
import decoderModels, { decodersStore, resetDecoderStatistics } from 'src/js/pages/streaming/decoder/decoder-store';
import streamModels, { fetchStreams, resetStreamStatistics } from 'src/js/pages/streaming/stream/stream-store';
import { decoderOptionsStreamName } from 'src/js/pages/streaming/stream/stream-view-model-mapper';
import { useWatchObject } from 'src/js/store/use-watch';
import { constant } from 'src/js/constant';
import { useLocalStorage } from 'usehooks-ts';

import { DecoderItemGraph } from './decoder-item-graphs';
import { DecoderItemStatistics } from './decoder-item-statistics';
import { DecoderResetAllStatsModal } from './decoder-reset-all-stats-modal';
import ReactSelect from 'react-select';
import { useMutable } from 'src/js/hook/use-mutable';
import { compareModels } from 'src/js/util/generic-store';
import BusyIcon from 'src/js/component/base/busy-icon';
import { generateDataAuto } from 'src/js/util/automation';

interface DecoderItemProps {
  decoder: DecoderViewModel;
  onClickGlobalSave: Subject<string>;
  onFormSubmit: any;
  onFormChange: any;
  formAggregator: FormAggregatorClientInterface;
}

export const DecoderItem: React.FunctionComponent<DecoderItemProps> = ({
  decoder,
  onClickGlobalSave,
  onFormSubmit,
  onFormChange,
  formAggregator,
  ...rest
}) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const navigate = useNavigate();

  const uniqueIdFormInput = `dec${decoder.id}-input`;
  useEffect(() => {
    fetchStreams(t);

    formAggregator?.register(uniqueIdFormInput);
    return () => {
      formAggregator?.unregister(uniqueIdFormInput);
    };
  }, []);

  const [streams, setStreams] = useState(streamModels);
  useWatchObject(streamModels, setStreams);
  /*  useWatchObject(streamModels, (s)=>  {
      console.log('fetched streams', s)
    });*/

  const [hasDecoderChanged, setHasDecoderChanged] = useState(false);
  useWatchObject(decodersStore, (_updated) => {
    // console.log('has changed?', decodersStore.hasItemChanged(decoder.id))
    if (decodersStore.hasItemChanged(decoder.id)) {
      setHasDecoderChanged(true);
    } else {
      setHasDecoderChanged(false);
    }
  });

  // stream options
  let _streamOptions: { value: string | number; label: string }[] = [
    { value: '-1', label: t('decoder.streamSelectNone') },
  ];
  let selectedStreamOption = _streamOptions[0]; // for react-select
  //let selectedStreamOptionValue = streamOptions[0].value; // for HaiUI dropdowns
  _streamOptions = _streamOptions.concat(
    streams.map((stream) => {
      const option = {
        value: stream.id,
        label: decoderOptionsStreamName(t, stream),
      };
      if (decoder.streamId === stream.id) {
        selectedStreamOption = option;
        //selectedStreamOptionValue = option.value;
        //selectedStreamOptionValue = stream.uuid;
      }
      return option;
    }),
  );
  const [streamOptions, setStreamOptions] = useMutable(_streamOptions);
  if (compareModels(streamOptions(), _streamOptions, null)) {
    // console.log('Stream options have changed', decoder.id)
    setStreamOptions(_streamOptions);
  }

  // console.log('streamOptions', streamOptions)
  // console.log('selectedStreamOptionValue', selectedStreamOption);

  const quadMode2SI = decoder.outputs.every((output) => output) && decoder.quadMode == QuadModeEnum.QUAD_2SI;

  // thumbnmail refresh
  const [thumbnailDate, setThumbnailDate] = useState<string>(`${new Date().getTime()}`);
  useEffect(() => {
    let endrun = false;
    let timer: any;
    const triggerRefresh = () => {
      if (decoder.previewEnabled) {
        setThumbnailDate(`${new Date().getTime()}`);
        if (!endrun && decoder.previewIntervalSec >= 1) {
          timer = setTimeout(triggerRefresh, decoder.previewIntervalSec * 1000);
        }
      }
    };

    timer = setTimeout(() => {
      triggerRefresh();
    }, decoder.previewIntervalSec * 1000);

    return () => {
      // cleanup
      endrun = true;
      clearTimeout(timer);
    };
  }, [decoder.previewIntervalSec, decoder.previewEnabled]);

  watchEvent('decoders-apply-completed', () => {
    if (decoder.previewIntervalSec >= 1) {
      setThumbnailDate(`${new Date().getTime()}`);
    }
  });

  const form = useRef<FormikProps<DecoderViewModel>>();
  useEffect(() => {
    form.current?.validateField('bufferingDelay');
  }, [decoder.stats?.delayRangeMinMs, decoder.stats?.delayRangeMaxMs]);

  useEffect(() => {
    form.current?.validateField('multisyncBufferingDelay');
  }, [decoder.stats?.multisyncDelayRangeMinMs, decoder.stats?.multisyncDelayRangeMaxMs]);

  const handleClickDecoder = () => {
    navigate('/streaming/decoder/', { state: { id: decoder?.id, stats: true } });
  };

  const handleResetStatistics = (dec: DecoderViewModel) => {
    resetDecoderStatistics(dec, t).then(() => {
      const stream = streams.find((s) => s.decoderId === dec.id);
      if (stream == null) {
        // we are done
        createAndDispatchNotification(t('decoder.notifications.resetDecoderSuccess'), NotificationVariant.SUCCESS, t);
      } else {
        resetStreamStatistics(stream, t).then((response) => {
          if (response.ok) {
            createAndDispatchNotification(
              t('decoder.notifications.resetDecoderSuccess'),
              NotificationVariant.SUCCESS,
              t,
            );
          }
        });
      }
    });
  };

  const [resetAllStatsShown, showResetAllStats, hideResetAllStats] = useModalControls();
  const handleResetAllStatistics = () => {
    hideResetAllStats();
    Promise.all(decoderModels.map((e) => resetDecoderStatistics(e, t))).then((responses) => {
      const success = responses.filter((r) => r.ok);
      if (success.length === decoderModels.length) {
        Promise.all(
          decoderModels.map((e) =>
            resetStreamStatistics(
              streams.find((s) => s.decoderId === e.id),
              t,
            ),
          ),
        ).then((responses) => {
          const success = responses.filter((r) => r.ok);
          if (success.length === decoderModels.length) {
            createAndDispatchNotification(
              t('decoder.notifications.resetAllDecoderSuccess'),
              NotificationVariant.SUCCESS,
              t,
            );
          }
        });
      }
    });
  };

  /* UNUSED
    const handleClickOpenStats = () => {
      navigate('/streaming/decoder/', { state: { id: decoder?.id, stats: true } });
    };*/

  const handleClickOpenSettings = () => {
    navigate('/streaming/decoder/', { state: { id: decoder?.id, stats: false } });
  };

  const handleClickOpenStreamStats = () => {
    navigate('/streaming/stream/', { state: { id: stream?.id, stats: true } });
  };

  /* UNUSED
    const handleClickOpenStreamEdit = () => {
      navigate('/streaming/stream/', { state: { id: stream?.id, stats: false } });
    };    */

  const stream = streams.find((s) => s.decoderId === decoder.id);
  const showDetailedStats = () => {
    navigate(`/streaming/decoder/stats?decoder=${decoder.id}`);
  };

  const [busy, setBusy] = useState(false);
  const handleClickStartStop = (event: React.FormEvent<HTMLFormElement>) => {
    setBusy(true);
    const isStopped = decoderIsStopped(decoder.state);
    onFormSubmit(event, isStopped ? DecoderAction.START : DecoderAction.STOP)?.then(() => {
      triggerEvent('preset-update');
      triggerEvent('fast-update');
      setBusy(false);
    });
  };

  const [showAudioMeters, setShowAudioMeters] = useLocalStorage(
    `${constant.lStorage.showAudioMeters}:${sessionStore.username}`,
    true,
  );
  const menuItems = !sessionStore.isUser()
    ? [
        {
          title: t('dashboard.decoderSettings'),
          onSelect: () => handleClickOpenSettings(),
          selectable: false,
        },
        {
          title: t('dashboard.detailedStatistics'),
          onSelect: showDetailedStats,
          selectable: false,
        },
        {
          title: t('dashboard.resetStatistics'),
          onSelect: () => handleResetStatistics(decoder),
          selectable: false,
        },
        {
          title: t('dashboard.resetAllStatistics'),
          onSelect: showResetAllStats,
          selectable: false,
        },
      ]
    : [];

  menuItems.push({
    title: showAudioMeters ? t('dashboard.hideAudioChannels') : t('dashboard.showAudioChannels'),
    onSelect: () => setShowAudioMeters(!showAudioMeters),
    selectable: false,
  });

  const statusColor = decoder.color;

  const ref = useRef<HTMLDivElement>();
  const panelRef = useRef<HTMLDivElement>();
  const [thumbnailWidth, setThumbnailWidth] = useState(0);
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        setThumbnailWidth(ref.current.clientWidth);
      }
    });

    const pageContent = document.querySelector('.pageContent');
    if (!isNil(pageContent)) {
      resizeObserver.observe(pageContent);
    }

    return () => {
      if (!isNil(pageContent)) {
        resizeObserver.unobserve(pageContent);
      }
    };
  }, [showAudioMeters]);

  const possibleText = decoderStateToHintText(decoder, t);
  useEffect(() => {
    const panelIconDiv = panelRef.current?.parentElement?.parentElement?.querySelector('div.icon');
    if (panelIconDiv) {
      if (possibleText) {
        (panelIconDiv as HTMLDivElement).title = possibleText;
      } else if (
        !possibleText &&
        (panelIconDiv as HTMLDivElement).title &&
        (panelIconDiv as HTMLDivElement).title.length
      ) {
        // cleaning title
        (panelIconDiv as HTMLDivElement).title = '';
      }
    }
  }, [decoder?.stats.state, possibleText]);

  let barWidth = 0;
  let emptyBars = decoder.previewEnabled ? 0 : 6 - decoder.stats?.audioPairs?.length;
  if (!decoder.previewEnabled && thumbnailWidth > 250) {
    emptyBars += 2; // if preview disabled and we have space, add empty bars
  }
  if (!decoder.previewEnabled && thumbnailWidth > 400) {
    emptyBars += 2; // if preview disabled and we have space, add empty bars
  }
  // console.log('thumbnail width', thumbnailWidth)
  if (isNaN(emptyBars)) {
    emptyBars = 0;
  }
  const noAudio = !(decoder.stats?.audioPairs?.length > 0);

  const AudioMeters: React.FunctionComponent = () => {
    return thumbnailWidth === 0 ? null : (
      <div data-auto="audio_meter" className="audio-meters d-flex">
        <>
          {decoder.stats?.audioPairs?.map((meter, index) => {
            const width = Math.min(
              35,
              (thumbnailWidth - 10) / Math.min(decoder.stats?.audioPairsAmount + emptyBars, 10) - 10,
            );
            barWidth = width;
            return <AudioMeter key={index} channel={index + 1} meter={meter} width={width} />;
          })}
          {[...Array(emptyBars)].map((_id, index) => {
            return <AudioMeter key={index} channel={index + 1} meter={null} width={barWidth} />;
          })}
        </>
      </div>
    );
  };

  return (
    <>
      <PanelItem
        panelColor={statusColor}
        menuItems={menuItems}
        icon={<Icon iconname={decoderStateToIcon(decoder)} style={{ fill: statusColor }} size="sm2" />}
        iconHover={<Icon iconname="ArrowThinRight" size="sm2" color="white" />}
        title={decoder.name}
        useFsMask
        onClickTitle={handleClickDecoder}
        {...rest}
      >
        <div className="mk-dec-sdi-buttons-row d-flex justify-content-between" ref={panelRef}>
          <FormGroup className="sdi-checkbox-container d-flex align-items-center hai-mb-1">
            <FormLabel className="hai-mr-2 hai-mb-2">{t('dashboard.sdiOutputs')}</FormLabel>
            <div
              className={classNames('sdi-checkboxes', quadMode2SI && 'quadMode2SI', thumbnailWidth <= 230 && 'tiny')}
              data-auto={generateDataAuto('sdi_checkbox', quadMode2SI && 'quadMode2SI')}
            >
              {decoder.outputs.map((value, index) => (
                <SdiCheckbox
                  key={index}
                  name={`outputs[${index}]`}
                  checked={value}
                  type={
                    value !== decoder.outputsOrig[index] && value == true
                      ? SDIType.HIGHLIGHT
                      : value !== decoder.outputsOrig[index] && value == false
                      ? SDIType.OUTLINE
                      : SDIType.NORMAL
                  }
                  onChange={onFormChange}
                >{`${index + 1}`}</SdiCheckbox>
              ))}
            </div>
          </FormGroup>
          {decoder.outputs.every((output) => output) && (
            <DropdownButton
              title={t(`decoder.enums.quadMode.${decoder.quadMode}`)}
              withCheckmark={false}
              onChange={onFormChange}
            >
              <DropdownMenuItem eventKey="quadMode 0" title={t(`decoder.enums.quadMode.${0}`)} />
              <DropdownMenuItem eventKey="quadMode 1" title={t(`decoder.enums.quadMode.${1}`)} />
            </DropdownButton>
          )}
        </div>
        {decoder.previewEnabled && (
          <div className="thumbnail fs-mask" ref={ref} data-auto={generateDataAuto('decoder', 'thumbnail')}>
            <img src={`/apis/decoders/${decoder.id}/preview?t=${thumbnailDate}`} width={'100%'}></img>
            {showAudioMeters && decoder.stats?.audioPairsAmount > 0 && <AudioMeters />}
          </div>
        )}
        {!decoder.previewEnabled && showAudioMeters && (
          <div className="previewDisabledVuMeters" ref={ref}>
            {noAudio && <span className="centeredFlexNotice">{t('decoder.noAudioChannels')}</span>}
            {decoder.stats?.audioPairsAmount > 0 && <AudioMeters />}
          </div>
        )}
        <FormGroup
          className="hai-mt-3 fs-mask"
          data-auto={generateDataAuto('decoder_dropdown', `${selectedStreamOption.label}`)}
        >
          {/*<FormSelect
              name="streamId"
              defaultValue={selectedStreamOptionValue}
              options={streamOptions}
              onChange={onFormChange}
            />*/}
          <ReactSelect
            value={selectedStreamOption}
            options={streamOptions()}
            onChange={onFormChange}
            className="react-select-container"
            classNamePrefix="react-select"
            menuPlacement="auto"
          />
        </FormGroup>
        <div
          className="d-flex align-items-center action-list justify-content-center"
          data-auto={generateDataAuto('decoder', 'buttons')}
        >
          <IconButton onClick={handleClickOpenStreamStats} title={t('dashboard.Stats')} disabled={!(stream?.id > 0)}>
            <Icon iconname="Streams" />
          </IconButton>
          <div className="divider"></div>
          {/*<IconButton onClick={handleClickOpenStreamEdit} title={t('dashboard.Configure')}>
              <Icon iconname="OpenSettings" />
            </IconButton>
          <div className="divider"></div>*/}
          <BusyIcon
            iconButton={true}
            activeIcon="Stop"
            inactiveIcon="Play"
            isActive={!decoderIsStopped(decoder.state)}
            disabled={decoderIsStopped(decoder.state) && hasDecoderChanged}
            onClick={handleClickStartStop}
            busy={busy}
            tooltip={
              decoderIsStopped(decoder.state)
                ? hasDecoderChanged
                  ? t('dashboard.startDisabledTooltip')
                  : t('dashboard.Start')
                : t('dashboard.Stop')
            }
          />
        </div>
        <DecoderItemGraph stream={stream} decoder={decoder} />
        <DecoderItemStatistics
          decoder={decoder}
          stream={stream}
          data-auto={generateDataAuto('decoder', 'statistics')}
        />
      </PanelItem>
      {resetAllStatsShown && (
        <DecoderResetAllStatsModal onCancel={hideResetAllStats} onAccept={handleResetAllStatistics} />
      )}
    </>
  );
};
