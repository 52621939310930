import { Icon, Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import systemInfo from 'src/js/data/systemInfo';
import { BandwidthGraph, LegendLocation } from './bandwidth-graph';

interface Props {
  onAccept?: VoidFunction;
  onCancel?: VoidFunction;
}

export const BandwidthGraphModal: React.FunctionComponent<Props> = ({ /*onAccept, */ onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      id="bandwidth-graph-modal"
      title={
        <div className="flexVerticalCenter">
          {t('statistics.chart.network', { deviceName: systemInfo.deviceName })}
          <a href="/bandwidth" target="_blank">
            <Icon iconname="GoTo" size="sm2" className="box hai-ml-3 cursor-pointer" color="haiui-blue-04"></Icon>
          </a>
        </div>
      }
      headerIcon="Meter"
      accentColor="#30cbd1"
      onClose={onCancel}
      show={true}
      // size="xl"
      height="80vh"
      dialogType="activity"
      content={
        <BandwidthGraph fullpage showTimescale legend={LegendLocation.BOTTOM} title={t('statistics.bandwidth.title')} />
      }
    />
  );
};
