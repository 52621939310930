const getMinutesString = (minutes: number): string => {
  return minutes === 0 ? '' : `${minutes}m`;
};

const getSecondsString = (seconds: number): string => {
  return seconds === 0 ? '' : `${seconds}s`;
};

export const getRemainingTime = (remainingTime: number): string => {
  const minutes = Math.trunc(remainingTime / 60);
  const seconds = remainingTime - minutes * 60;
  return [getMinutesString(minutes), getSecondsString(seconds)].join(' ');
};
