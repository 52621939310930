import { ApisauceConfig } from 'apisauce';
import { BaseClient } from 'src/js/api/base-client';
import { GenericController } from 'src/js/controller/generic-controller';
import { NetworkErrorHandler } from 'src/js/controller/network-error-handler';
import { RawController } from 'src/js/controller/raw-controller';
import { SessionController } from 'src/js/controller/session-controller';
import { SettingsController } from 'src/js/controller/settings-controller';
import { SettingsDateTimeController } from 'src/js/controller/settings-date-time-controller';
import { SettingsNetworkController } from 'src/js/controller/settings-network-controller';
import { SettingsServicesController } from 'src/js/controller/settings-services-controller';
import { SettingsStatusController } from 'src/js/controller/settings-status-controller';
import { SettingsUpgradeController } from 'src/js/controller/settings-upgrade-controller';

/*
 * Madra Docs: https://haivision.jira.com/wiki/spaces/MAD/pages/34537584/REST+API
 * Kraken Gateway Docs: https://haivision.jira.com/wiki/spaces/VSG/pages/81264877/REST+API
 */

export class ApiClient extends BaseClient {
  rawController: RawController;
  genericController: GenericController;
  // FIXME replace all below with genericController usage
  sessionController: SessionController;
  settingsController: SettingsController;
  settingsDateTimeController: SettingsDateTimeController;
  settingsNetworkController: SettingsNetworkController;
  settingsServicesController: SettingsServicesController;
  settingsStatusController: SettingsStatusController;
  settingsUpgradeController: SettingsUpgradeController;

  constructor(config: ApisauceConfig, errorHandler: NetworkErrorHandler) {
    super(config);

    this.rawController = new RawController(this.api, this.config, null);
    this.genericController = new GenericController(this.api, this.config, errorHandler);
    this.sessionController = new SessionController(this.api, this.config, errorHandler);
    this.settingsController = new SettingsController(this.api, this.config, errorHandler);
    this.settingsController = new SettingsController(this.api, this.config, errorHandler);
    this.settingsDateTimeController = new SettingsDateTimeController(this.api, this.config, errorHandler);
    this.settingsNetworkController = new SettingsNetworkController(this.api, this.config, errorHandler);
    this.settingsStatusController = new SettingsStatusController(this.api, this.config, errorHandler);
    this.settingsServicesController = new SettingsServicesController(this.api, this.config, errorHandler);
    this.settingsUpgradeController = new SettingsUpgradeController(this.api, this.config, errorHandler);
  }

  public setLanguageHeader(locale: string) {
    this.api.setHeader('Accept-Language', locale);
  }
}
