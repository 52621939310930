import { IHaiColumnSort } from '@hai/ui-react/dist/types';
import { concat, head, uniq } from 'ramda';

export const presetName = (name: string): string => (name ? head(name.split('.cfg')) : '');

export const presetNames = (data: string[]): string[] => {
  const presetsWithoutExtension = data.map<string>((value: string) => presetName(value));
  return uniq(concat(data, presetsWithoutExtension));
};

export const sortDataTable = (sortState: IHaiColumnSort, key: any) => {
  return (value: any, compareTo: any) => {
    if (value[key] === compareTo[key]) {
      return 0;
    } else {
      const valueKey = value[key];
      const compareToKey = compareTo[key];

      if (sortState.order === 'descending') {
        return valueKey.localeCompare(compareToKey, undefined, { numeric: true }) < 0 ? 1 : -1;
      } else {
        return valueKey.localeCompare(compareToKey, undefined, { numeric: true }) > 0 ? 1 : -1;
      }
    }
  };
};
