import { OverlayTrigger } from '@hai/ui-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { constant } from 'src/js/constant';

interface Props {
  hidden?: boolean;
  className?: string; // className of root <div>
  color?: string;
  hoverText?: string;
  hoverBgColor?: string;
  href?: string;
  id?: number | string; // id of the href object <div>
  children?: React.ReactNode;
}

// <PushButton>
//  pass value as children
export const PushButton: React.FunctionComponent<Props> = ({
  hidden,
  className,
  color,
  hoverText,
  hoverBgColor,
  href,
  id,
  children,
  ...rest
}) => {
  let colorCss;
  let additionalClassNames = className ? ` ${className}` : '';
  if (color && color.startsWith('#')) {
    colorCss = color;
  } else if (color && color.length) {
    additionalClassNames += ' ' + color;
  }
  if (href) {
    additionalClassNames += ' cursor-pointer';
  }

  // to support on-the-fly 'href' change, this would need to be in a state variable, but not needed now.
  const navigate = useNavigate();
  const onClick = href
    ? () => {
        navigate(href, { state: { id: id } });
      }
    : undefined;

  return (
    <>
      {hidden !== true && (
        <OverlayTrigger
          popoverContent={<div className="properTooltip">{hoverText}</div>}
          popoverBgColor={hoverBgColor ?? constant.productColor}
          placement="top"
        >
          <div
            className={`pushButton${additionalClassNames}`}
            {...rest}
            style={{ backgroundColor: colorCss }}
            onClick={onClick}
          >
            <div className="value haiui-label-02-med">{children}</div>
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};
