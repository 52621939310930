import { ButtonStateType } from '@hai/ui-react/dist/types';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'src/js/hook/use-stores';
import { AccountPasswordChangeForm } from 'src/js/pages/security/accounts/account-form';

import { AccountViewModel } from './account-models';
import { AccountPubkeyTable } from './account-pubkey-table';
import { getAccount, updateAccount } from './account-store';

interface Props {
  model?: AccountViewModel;
  onCancel?: VoidFunction;
  user?: boolean;
}

export const AccountSettings: React.FunctionComponent<Props> = ({ model, onCancel, user }) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const [settings, setSettings] = useState<AccountViewModel>(model);
  useEffect(() => {
    if (!isNil(model)) {
      setSettings(model);
    }
  }, [model]);

  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);

  const handleSubmit = (values: any, callback: VoidFunction) => {
    setButtonState('pending');
    updateAccount(values, t).then((res) => {
      if (res.ok) {
        callback();
      }
      setButtonState(undefined);
    });
  };

  const shouldReloadData = (): void => {
    getAccount(settings.name, t, false);
  };

  return (
    <>
      <AccountPasswordChangeForm
        settings={settings}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        buttonState={buttonState}
        requireCurrentPassword={
          !sessionStore.isAdmin() || (sessionStore.isAdmin() && settings.name === sessionStore.username)
        }
      />
      <AccountPubkeyTable model={settings} shouldReloadData={shouldReloadData} user={user} />
    </>
  );
};
