import { Button } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { FormikProps, useField } from 'formik';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTextFieldWithIcon } from 'src/js/component/base/form/formik-text-field-with-icon';
import { RxTask } from 'src/js/helper/mobx-tasks';
import { useErrorReaction, useSuccessReaction } from 'src/js/hook/use-reaction';
import { NtpServerStatus, NtpStatus } from 'src/js/model/ntp-server-status';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';

import { DateAndTimeFormValues } from './date-and-time-form';
import { getInputStatus } from './ntp-server-helper';

interface InternalProps {
  validateNtpServerTask?: RxTask<NtpServerStatus>;
}

export const ServerTest = (props: InternalProps & FormikProps<DateAndTimeFormValues>) => {
  const { t } = useTranslation();
  const { validateNtpServerTask } = props;
  const [ntpStatus, setNtpStatus] = useState<NtpServerStatus>(undefined);
  const [, , helper] = useField('server');

  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);
  const testServer = (): void => {
    setButtonState('pending');
    validateNtpServerTask(props.values.server);
  };

  useSuccessReaction(validateNtpServerTask, (response: NtpServerStatus) => {
    if (response.status === NtpStatus.Error) {
      helper.setError(response.message);
    } else if (response.status === NtpStatus.Valid) {
      createAndDispatchNotification(
        t('settings.dateTime.notifications.ntpTestSuccess'),
        NotificationVariant.SUCCESS,
        t,
      );
    }
    setNtpStatus(response);
    setButtonState(undefined);

    props.validateForm();
  });

  useErrorReaction(validateNtpServerTask, () => {
    setNtpStatus({ status: NtpStatus.Error });
    validateNtpServerTask.reset();
    setButtonState(undefined);

    props.validateForm();
  });

  useEffect(() => {
    validateNtpServerTask.reset();
    setNtpStatus(undefined);
  }, [props.values.server]);

  return (
    <div id="ntp-server-fields" className="box">
      <FormikTextFieldWithIcon
        name="server"
        mask
        placeholder={t('settings.dateTime.section.server.ntpServerPlaceholder')}
        title={t('settings.dateTime.section.server.ntpServer')}
        status={isNil(ntpStatus) ? undefined : getInputStatus(ntpStatus.status)}
        required
        disabled={buttonState === 'pending'}
      />
      <Button
        variant="secondary"
        onClick={testServer}
        state={buttonState}
        style={{ marginTop: '23px' }}
        disabled={props.values.server?.length === 0}
      >
        {t('general.test')}
      </Button>
    </div>
  );
};
