import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadZone } from 'src/js/component/base/upload-zone';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { constant } from 'src/js/constant';

import { PresetOverwriteModal } from './preset-overwrite-modal';
import { uploadPreset } from './preset-store';

interface Props {
  presetNames?: string[];
  onCancel?: VoidFunction;
  onImport?: VoidFunction;
}

export const PresetImport: React.FunctionComponent<Props> = ({ presetNames = [], onCancel, onImport }) => {
  const { t } = useTranslation();
  const [overwriteShown, showOverwrite, hideOverwrite] = useModalControls();
  const [userFile, setUserFile] = useState<any>(null);

  const handleImportPreset = (file: File): Promise<any> => {
    return uploadPreset(file, t).then((res) => {
      if (res.ok) {
        onImport?.();
      }
    });
  };

  const handleContinueUpload = (file?: File): void => {
    if (file) {
      handleImportPreset(file);
    } else if (userFile) {
      handleImportPreset(userFile);
    }
  };

  const handleStartUpload = (file: File) => {
    setUserFile(file);
    if (presetNames.includes(file.name)) {
      showOverwrite();
      return;
    }
    handleContinueUpload(file);
  };

  return (
    <>
      <div className="hai-mt-6">
        <UploadZone
          accept={{ '': constant.presetFileTypes }}
          onCancel={onCancel}
          onUpload={handleStartUpload}
          uploadBtnText={t('general.import')}
        />
      </div>
      {overwriteShown && (
        <PresetOverwriteModal
          name={userFile.name}
          onAccept={() => handleContinueUpload()} // Needed
          onCancel={hideOverwrite}
        />
      )}
    </>
  );
};
