import { UpdateServicesResponse } from 'src/js/model/api/response/update-services-response';
import { Services } from 'src/js/model/services';

export const mapUpdateServices = (response: UpdateServicesResponse): Services => ({
  ems: !!response.ems,
  http: !!response.http,
  preview: !!response.preview,
  snmp: !!response.snmp,
  ssh: !!response.ssh,
  telnet: !!response.telnet,
  nic: response.nic,
});
