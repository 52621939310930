import { Icon, Heading3Med } from '@hai/ui-react';
import classNames from 'classnames';
import { isNil } from 'ramda';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { StatisticRowViewModel, StatisticSectionViewModel } from 'src/js/pages/statistic/statistic-view-model';
import { generateDataAuto } from 'src/js/util/automation';

import { StatisticRow } from './statistic-row';

export enum StatsMode {
  withGraph = 'withGraph',
  plainStats = 'plainStats',
}

interface Props {
  className?: string;

  /**
   * mode
   * 'withGraph' a graph is to be displayed before the statistics
   *  'plainStats' no graph is displayed with the statistics
   * */
  mode?: StatsMode;
  /**
   * minimum group size for wrapping
   */
  minimumGroup?: number;

  sections: StatisticSectionViewModel[];

  /**
   * Statistics title
   */
  title?: string;
  /**
   * shortlist
   * Will only display row items marked with shortlist.
   */
  shortlist?: boolean;

  /**
   * formatString
   * Separate the string into it's various componments i.e. value [percentage] (units)
   */
  formatString?: boolean;

  fullHeader?: boolean;

  fullHeaderStatusColor?: string;
  fullHeaderIcon?: string;
  fullHeaderTitle?: string;
}

export const randomNumber: () => number = () => Math.floor(Math.random() * Number.MAX_VALUE);

export interface StatisticTableViewModel {
  title?: string;
  sections?: StatisticSectionViewModel[];
}

export const StatisticTable: React.FunctionComponent<Props> = ({
  className,
  mode = StatsMode.plainStats,
  sections,
  title,
  minimumGroup = 0,
  shortlist = false,
  formatString = false,
  fullHeader,
  fullHeaderStatusColor,
  fullHeaderIcon,
  fullHeaderTitle,
}) => {
  const ref = useRef<HTMLDivElement>();
  const [render, setRender] = useState(0);
  const [columns, setColumns] = useState(3);

  let endrun = false;
  useEffect(() => {
    return () => {
      endrun = true;
    };
  }, []);
  const MAX_TRY_TO_GET_THE_SIZE = 10;
  const DELAY_BETWEEN_TRY = 10;
  const MAX_COLUMN_WIDTH = 360;
  useLayoutEffect(() => {
    if (ref.current?.offsetWidth / 3 > MAX_COLUMN_WIDTH) {
      setColumns(3);
    } else if (ref.current?.offsetWidth / 2 > MAX_COLUMN_WIDTH) {
      setColumns(2);
    } else {
      setColumns(1);
    }
    if (render < MAX_TRY_TO_GET_THE_SIZE || render === 0) {
      // re-schedule
      setTimeout(() => {
        if (!endrun) {
          setRender(render + 1);
        }
      }, DELAY_BETWEEN_TRY);
    }
  }, [ref, render]);

  // Handle resize
  useEffect(() => {
    const onResizeLocal = (_ev: UIEvent) => {
      setRender(0); // trigger ellipsis calculations
    };
    // TODO we could also watch sidebar collapse to trigger recalculation
    if (minimumGroup > 0) {
      window.addEventListener('resize', onResizeLocal);
      return () => {
        window.removeEventListener('resize', onResizeLocal);
      };
    }
    return undefined;
  }, [minimumGroup]);

  // slice sections into wrappable groups
  let count = 0;
  const tables: StatisticTableViewModel[] = [];
  let groupedSections: StatisticSectionViewModel[] = [];
  let nextTitle: string = undefined;
  let twoColumns: boolean = undefined;
  let sectionClassName: string = undefined;
  let multiColumnMode = false;
  // TODO: if showing shortlist, only print the first header and group all in a single section

  let nextTableTitle = title;
  sections
    .filter((s) => s.rows.length > 0)
    .map((section: StatisticSectionViewModel) => {
      count = 0;
      nextTitle = section.title;
      twoColumns = section.twoColumns;
      sectionClassName = section.className;
      let currentGroup: StatisticRowViewModel[] = [];

      if (section.sectionBreak && !shortlist) {
        // in shortlisted mode, we just output one master table
        tables.push({ sections: groupedSections, title: nextTableTitle });
        groupedSections = [];
        nextTableTitle = section.sectionBreakTitle;
      }

      section.rows.map((row) => {
        if (row.when !== false && (!shortlist || row.shortlisted)) {
          count++;
          currentGroup.push(row);
        }
      });

      // let see if we can divide by 3 the items
      const itemsPerColumn = Math.ceil(count / columns);
      if (minimumGroup > 0 && itemsPerColumn > minimumGroup) {
        multiColumnMode = true;
        // split in 3
        let loop = 0;
        while (count > 0) {
          const newBlock = currentGroup.splice(0, itemsPerColumn);
          groupedSections.push({
            title: nextTitle,
            rows: newBlock,
            twoColumns: twoColumns,
            className: sectionClassName,
          });
          count -= newBlock.length;
          twoColumns = undefined;
          sectionClassName = undefined;
          loop++;
          if (loop > 4) {
            // console.log('break 4')
            break;
          }
        }
      }
      if (currentGroup.length > 0) {
        groupedSections.push({
          title: nextTitle,
          rows: currentGroup,
          twoColumns: twoColumns,
          className: sectionClassName,
          span: shortlist ? undefined : section.span,
        });
        currentGroup = [];
        nextTitle = undefined;
        twoColumns = undefined;
        sectionClassName = undefined;
      }
    });

  if (groupedSections?.length > 0) {
    tables.push({ sections: groupedSections, title: nextTableTitle });
    groupedSections = [];
  }

  count = 0;

  const renderSections = (section: StatisticSectionViewModel) => {
    return (
      <>
        {section.rows.map((row: StatisticRowViewModel) => {
          const key = `${section.title}-${row.name}`;
          return (
            <StatisticRow
              key={key}
              data-auto={generateDataAuto('stats', `row ${row.name}`)}
              name={row.name}
              value={row.value}
              value2={row.value2}
              twoColumns={section.twoColumns}
              className={row.className}
              warning={row.warning}
              mask={row.mask}
              formatString={row.formatString || formatString} //TODO deprecate global formatString, should be a row config.
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      {fullHeader && (
        <div className="full-page-header">
          <div className="status-indicator" style={{ backgroundColor: 'green' }} />
          <Icon iconname={fullHeaderIcon} style={{ fill: fullHeaderStatusColor }} size={'md'} />
          <Heading3Med className="header-title fs-mask">{fullHeaderTitle}</Heading3Med>
        </div>
      )}
      {tables.map((table, index) => {
        return (
          <>
            {table.title && (
              // Remove gap below title
              <span
                className={classNames('mk-stats-section-title tuc d-block', index > 0 && 'underlined')}
                data-auto={generateDataAuto('title', table.title)}
              >
                {table.title}
              </span>
            )}
            {multiColumnMode && (
              <div className="d-flex justify-content-center statistics-header" title={table.sections[0].title}>
                {table.sections[0].title}
              </div>
            )}
            <div className={classNames('mk-stats-table', mode, className, multiColumnMode && 'journal')} ref={ref}>
              {table.sections
                .filter((s) => s.rows.length > 0)
                .map((section: StatisticSectionViewModel) =>
                  section.title ? (
                    <div
                      className={classNames('mk-stats-group', section.twoColumns && 'twoColumns', section.className)}
                      data-auto={generateDataAuto('stats_group', section.title)}
                      key={`${section.title}-${++count}`}
                      style={!isNil(section.span) ? { gridRow: `span ${Number(section.span)}` } : {}}
                    >
                      {!multiColumnMode && (
                        <div className="d-flex justify-content-center statistics-header" title={section.title}>
                          {section.title}
                        </div>
                      )}
                      <div className={classNames('mk-stats-rows', section.twoColumns && 'twoColumns')}>
                        {renderSections(section)}
                      </div>
                    </div>
                  ) : (
                    /* no title */
                    <div
                      key={`section-${++count}`}
                      className={classNames('mk-stats-rows', section.twoColumns && 'twoColumns')}
                    >
                      {renderSections(section)}
                    </div>
                  ),
                )}
            </div>
          </>
        );
      })}
    </>
  );
};
