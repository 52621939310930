import { IReactionDisposer, IReactionOptions, reaction } from 'mobx';
import { task, Task, TaskGroup } from 'mobx-task';
import { Observable } from 'rxjs';

export declare type RxTask<T> = Task<any[], Promise<T>>;
export declare type RxTaskOrGroup<T> = Task<any[], Promise<T>> | TaskGroup<any[], Promise<T>>;
export declare type RxTaskFunc<T> = (...args: any[]) => Observable<T>;

// Changes Observable to Promise
export const rxTaskFactory = <T>(worker: RxTaskFunc<T>): RxTask<T> => {
  const create = () =>
    task.resolved(async (...args: any[]) => await worker(...args).toPromise(), {
      swallow: true,
    });

  return create();
};

export const onSuccessReaction = <T>(
  t: RxTaskOrGroup<T>,
  callback: (result?: T) => void,
  opts?: IReactionOptions,
): IReactionDisposer =>
  reaction(
    () => t.result,
    (result?) => {
      if (t.resolved && t.result) {
        callback(result);
      }
    },
    opts,
  );

export const onErrorReaction = <T>(
  t: RxTaskOrGroup<any>,
  callback: (error?: T) => void,
  opts?: IReactionOptions,
): IReactionDisposer =>
  reaction(
    () => t.error,
    () => {
      if (t.error) {
        callback(t.error as T);
      }
    },
    opts,
  );

export const onPendingReaction = <T>(
  t: RxTaskOrGroup<T>,
  callback: (pending: boolean) => void,
  opts?: IReactionOptions,
): IReactionDisposer =>
  reaction(
    () => t.pending,
    () => {
      callback(t.pending);
    },
    opts,
  );
