import { TFunction } from 'i18next';
import * as yup from 'yup';

export interface BannerModel {
  enabled: boolean;
  banner: string;
}

/*
 * Validation
 */
export const bannerValidationSchema = (t: TFunction) =>
  yup.object<BannerModel>().shape({
    banner: yup.string().when('enabled', {
      is: (value) => value == true,
      then: yup.string().required(t('validation.required')),
    }),
  });
