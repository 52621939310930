import { Dialog } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { FormSelect } from 'src/js/component/base/form/form-select';
import { UploadZone } from 'src/js/component/base/upload-zone';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useMutable } from 'src/js/hook/use-mutable';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { constant } from 'src/js/constant';
import { FormChangeEvent } from 'src/js/util/global-type';

import { StillImageOverwriteModal } from './stillimage-overwrite-modal';

interface Props {
  listOfUsedNames?: string[];
  onCancel?: VoidFunction;
  afterImport?: VoidFunction;
}

const selectOptions: { value: string | number; label: string }[] = [
  { value: '1080', label: '1920x1080' },
  { value: '720', label: '1280x720' },
  { value: '480', label: '720x480' },
  { value: '576', label: '720x576' },
  { value: 'vga', label: '640x480' },
  { value: 'svga', label: '800x600' },
  { value: 'xga', label: '1024x768' },
  { value: 'xga+', label: '1152x864' },
  { value: 'wxga', label: '1280x768' },
  { value: 'wxga2', label: '1280x800' },
  { value: 'sxga', label: '1280x1024' },
  { value: 'wxga3', label: '1360x768' },
  { value: 'wxga4', label: '1366x768' },
  { value: 'wxga+', label: '1440x900' },
  { value: 'sxga+', label: '1400x1050' },
  { value: 'hd+', label: '1600x900' },
  { value: 'uxga', label: '1600x1200' },
  { value: 'wsxga+', label: '1680x1050' },
  { value: 'wuxga', label: '1920x1200' },
];

export const StillImageImportModal: React.FunctionComponent<Props> = ({ listOfUsedNames, onCancel, afterImport }) => {
  const { t } = useTranslation();
  const [state, setState] = useState<ButtonStateType>(undefined);
  const [resolution, setResolution] = useMutable('1080');

  const [userFile, setUserFile] = useState<any>(null);
  const handleContinueUpload = (overwrite: boolean, file?: File): void => {
    setState('pending');
    apiClientWithoutHandler.genericController
      .upload(APIPath.stillimages.upload, {
        file: file || userFile,
        resolution: resolution(),
        overwrite,
      })
      .then((res: any) => {
        if (!res.ok && res.data?.error === true && res.data?.message?.includes('already exists')) {
          showOverwrite();
          return res;
        }
        notificationHandler(
          res,
          t('settings.stillImages.notifications.uploadSuccessMsg'),
          t('settings.stillImages.notifications.uploadErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        setState(undefined);
        if (res.ok) {
          afterImport?.();
        }
        return res;
      });
  };

  const handleChangeResolution = (event: FormChangeEvent): void => {
    setResolution(event.target.value);
  };

  const [overwriteShown, showOverwrite, hideOverwrite] = useModalControls();
  const handleStartUpload = (file: File): void => {
    setUserFile(file);
    if (listOfUsedNames.includes(file.name)) {
      showOverwrite();
      return;
    }

    handleContinueUpload(false, file);
  };

  return (
    <>
      <Dialog
        accentColor={constant.productColor}
        title={t('settings.stillImages.import.title')}
        onClose={onCancel}
        show={true}
        dialogType="activity"
        headerIcon="StillImage"
        icon="none"
        content={
          <>
            <FormSelect
              name="quality"
              title={t('settings.stillImages.resolution')}
              options={selectOptions}
              defaultValue={selectOptions[0].value}
              onChange={handleChangeResolution}
            />
            <UploadZone
              accept={{ '': constant.stillImageFileTypes }}
              onUpload={handleStartUpload}
              onCancel={onCancel}
              uploadBtnText={t('general.import')}
              uploadBtnState={state}
            />
          </>
        }
      />
      {overwriteShown && (
        <StillImageOverwriteModal
          name={userFile.name}
          onAccept={() => handleContinueUpload(true)}
          onCancel={hideOverwrite}
        />
      )}
    </>
  );
};
