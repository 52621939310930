import { Dialog, colorValue } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Status } from 'src/js/component/actionBar/actionBar';
import { SortType } from 'src/js/component/sort-manager';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useStores } from 'src/js/hook/use-stores';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { StreamingActionBar, scrollPanelIntoView } from 'src/js/component/actionBar/streaming-action-bar';
import { useWatchObject } from 'src/js/store/use-watch';

import { AddAccountModal } from './account-add-modal';
import { AccountFormValues } from './account-form';
import { AccountList } from './account-list';
import {
  AccountViewModel,
  RoleType,
  accountIsDisabled,
  accountIsLocked,
  accountModelToStatus,
  mapAccountViewModel,
} from './account-models';
import { AccountSettings } from './account-settings';
import accountModels, {
  createAccount,
  deleteAccount,
  enableAccount,
  fetchAccounts,
  getAccount,
  lockUnlockAccount,
  saveResponseToAccounts,
} from './account-store';

export const Accounts: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const isSameUser = (name: string) => (name ? name === sessionStore.username : false);
  const location = useLocation();
  const scrollToId = sessionStore.isAdmin() && location?.state?.id;

  const [accounts, setAccounts] = useState<AccountViewModel[]>(accountModels);
  useWatchObject(accountModels, setAccounts);

  useEffect(() => {
    if (sessionStore.isAdmin()) {
      fetchAccounts(t);
    } else {
      getAccount(sessionStore.username, t, false).then((res) => {
        if (res.ok) {
          const response = res.data;
          if (response) {
            const viewModel = mapAccountViewModel(response, t);
            saveResponseToAccounts([viewModel]);
          }
        }
      });
    }
  }, []);

  const [addShown, showAdd, hideAdd] = useModalControls();
  const [addButtonState, setAddButtonState] = useState<ButtonStateType>(undefined);

  const handleAddAccount = (values: AccountFormValues) => {
    const filtered = accounts.filter((e) => e.name === values.name);
    if (filtered.length > 0) {
      createAndDispatchNotification(
        t('security.accounts.notifications.userAlreadyExits', { name: values.name }),
        NotificationVariant.ERROR,
        t,
      );
    } else {
      setAddButtonState('pending');
      createAccount(values, t).then((res) => {
        setAddButtonState(undefined);
        if (res.ok) {
          hideAdd();
          const viewModel = mapAccountViewModel(res.data, t);
          const data = [...accounts];
          data.splice(0, 0, viewModel);

          setAccounts(data);
          scrollPanelIntoView({ id: viewModel.id }, true);
        }
      });
    }
  };

  const filteredLock = () => accounts.filter((e) => e.selected && !isSameUser(e.name) && !accountIsLocked(e.state));
  const handleLockAccount = () => {
    const filtered = filteredLock();
    for (let i = 0; i < filtered.length; i++) {
      lockUnlockAccount(filtered[i], true, true, t);
    }
  };

  const filteredUnlock = () => accounts.filter((e) => e.selected && !isSameUser(e.name) && accountIsLocked(e.state));
  const handleUnlockAccount = () => {
    const filtered = filteredUnlock();
    for (let i = 0; i < filtered.length; i++) {
      lockUnlockAccount(filtered[i], false, true, t);
    }
  };

  const filteredEnable = () => accounts.filter((e) => e.selected && !isSameUser(e.name) && accountIsDisabled(e.state));
  const handleEnableAccount = () => {
    const filtered = filteredEnable();
    for (let i = 0; i < filtered.length; i++) {
      enableAccount(filtered[i], true, t);
    }
  };

  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const filteredDelete = () =>
    accounts.filter((e) => e.selected && !isSameUser(e.name) && e.role !== RoleType.SUPERUSER);
  const handleDeleteAccount = () => {
    const filtered = filteredDelete();
    for (let i = 0; i < filtered.length; i++) {
      deleteAccount(filtered[i], t);
    }
  };

  const statusTypes: Status[] = [
    { status: 'ACTIVE', color: colorValue('haiui-green-01') },
    { status: 'LOCKED', color: colorValue('haiui-gray-10') },
    { status: 'DISABLED', color: colorValue('haiui-gray-07') },
  ];

  const handleSelect = (item: AccountViewModel, selected: boolean) => {
    const accountModels = accounts.map((a: AccountViewModel) => (a.name === item.name ? { ...a, selected } : a));
    setAccounts(accountModels);
  };

  const sortInfo = {
    name: { title: t('sort.sortText.name'), type: SortType.NATURAL_SORT },
    stateSortable: { title: t('sort.sortText.status'), type: SortType.GENERIC },
  };

  const user = sessionStore.isAdmin() ? null : accounts.find((a) => a.name === sessionStore.username);

  return (
    <>
      {user ? (
        <div className="hai-mt-6" style={{ paddingTop: '3rem' }}>
          <AccountSettings model={user} user={true} />
        </div>
      ) : (
        <>
          <StreamingActionBar
            items={accounts}
            searchKeys={['name', 'expiryTxt', 'role']}
            sortInfo={sortInfo}
            statusTypes={statusTypes}
            getStateValue={accountModelToStatus}
            scrollToId={scrollToId}
            actionButtons={[
              { onClick: handleLockAccount, counter: filteredLock, label: t('general.lock') },
              { onClick: handleUnlockAccount, counter: filteredUnlock, label: t('general.unlock') },
              { onClick: showDelete, counter: filteredDelete, label: t('general.deleteBtn') },
              { onClick: handleEnableAccount, counter: filteredEnable, label: t('general.enable') },
            ]}
            List={AccountList}
            updateSelection={handleSelect}
            storageKey="account"
            actionRightPrimary={{
              onClick: showAdd,
              children: t('security.accounts.modal.add.title'),
            }}
            placeholderActions={2}
            placeholderRightComponents={1}
            placeholderNumPanels={4}
          />
          {addShown && (
            <AddAccountModal onApply={handleAddAccount} onCancel={hideAdd} addButtonState={addButtonState} />
          )}
          {deleteShown && (
            <Dialog
              headline={t('security.accounts.modal.delete.title', {
                count: filteredDelete().length,
              })}
              bodyText={t('general.cannotUndo')}
              onClose={hideDelete}
              show={true}
              buttons={[
                { label: t('general.cancel') },
                { label: t('general.deleteBtn'), variant: 'danger', onClick: handleDeleteAccount },
              ]}
            />
          )}
        </>
      )}
    </>
  );
};
