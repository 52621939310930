import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AccountKeyData } from './account-models';
import { deletePubicKey } from './account-store';

interface Props {
  name: string;
  itemsToDelete: AccountKeyData[];
  onSuccess?: (items: AccountKeyData[]) => void;
  onCancel?: VoidFunction;
}

export const DeleteAccountPubkeyModal: React.FunctionComponent<Props> = ({
  name,
  itemsToDelete,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation();

  const handleDelete = (): void => {
    Promise.all(itemsToDelete.map((e) => deletePubicKey(name, e.name, t))).then((responses) => {
      const failed = responses.filter((r) => !r.ok);
      if (failed.length === 0) {
        onSuccess(itemsToDelete);
      }
    });
  };

  return (
    <Dialog
      headline={t('security.accounts.pubkeys.delete.title', { count: itemsToDelete.length })}
      bodyText={t('general.cannotUndo')}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel') },
        { label: t('general.deleteBtn'), variant: 'danger', onClick: handleDelete },
      ]}
    />
  );
};
