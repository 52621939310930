export enum Link {
  MANUAL = 'manual',
  AUTO = 'auto',
}
export enum Speed {
  ANY = 'any',
  THOUSAND = '1000',
  HUNDRED = '100',
  TEN = '10',
}
export enum SpeedManual {
  THOUSAND = '1000',
  HUNDRED = '100',
  TEN = '10',
}

export enum Duplex {
  FULL = 'full',
  HALF = 'half',
}

export enum DNSPrecedence {
  IPV6 = 'ipv6',
  IPV4 = 'ipv4',
}

// TODO: Set proper values
enum Duration {
  FIFTEEN = '15',
}

// TODO: Set proper values
enum Resolution {
  SEVEN_TWENTY_FIFTY_NINE = '720p59',
}

export enum Ipv6Addressing {
  AUTOMATIC = 'auto',
  AUTOMATIC_DHCP = 'dhcp',
  STATIC = 'static',
}

export interface Ipv6List {
  0?: string;
  1?: string;
  2?: string;
  3?: string;
  4?: string;
  5?: string;
  6?: string;
  7?: string;
}

export interface NetworkInterfaceResponse {
  ipv4: boolean;
  dhcp: number;
  ip: string;
  netmask: string;
  gateway: string;
  dhcpVendorID?: string;
  linklocal: number;

  // TODO: Not currently used
  osdDuration?: Duration;
  osdEnable?: string;
  osdResolution?: Resolution;

  // IPV6 section
  ipv6: boolean;
  ipv6_mode: Ipv6Addressing;
  ipv6_ip: string;
  ipv6_prefix_length: number;
  ipv6_gateway: string;
  ipv6_privacy_extensions: boolean;
  ipv6_ips: Ipv6List;
  ipv6_linklocal: string;

  // Ethernet section
  link: Link;
  speed: Speed;
  currentSpeed: Speed;
  duplex: Duplex;
  mac: string;
  bandwidth: number;
}

export interface NetworkResponse {
  autoDns: number;
  dns: string;
  dns2: string;
  dnsPrecedence: DNSPrecedence;
  domain: string;
  eth0: NetworkInterfaceResponse;
  eth1: NetworkInterfaceResponse;
  hasEth1: boolean;
  hostname: string;
  isEth1Sfp: boolean;
  mDnsEnabled: number;
  mDnsName: string;
}
