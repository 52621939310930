import 'moment/min/locales';

import { CreateHaiThemeContext, FormControl } from '@hai/ui-react';
import { useFavicon } from '@hai/ui-react/dist/hook';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { bootstrap } from 'src/js/bootstrap';
import { MakitoRoutes } from 'src/js/component/router/routes';
import { Config } from 'src/js/config';
import { NotificationController } from 'src/js/notification/notification-controller';

import systemInfo, { fetchSystemInfo } from './data/systemInfo';
import { watchEvent } from './events';
import { useMutable } from './hook/use-mutable';
import { NotificationVariant } from './notification/notification';
import { createAndDispatchNotification } from './notification/notification-helper';
import { constant } from './constant';

export const ThemeContext = CreateHaiThemeContext(Config.theme);

export const App: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  (async () => {
    if (systemInfo.version === '') {
      await fetchSystemInfo();
      setLoading(false);
    }
  })().catch((e) => {
    /* eslint-disable-next-line no-console */
    console.error('Failed to load system info', e);
  });

  const [communicationLostCount, setCommunicationLostCount] = useMutable(0);
  const [communicationLostShown, setCommunicationLostShown] = useMutable(false);
  watchEvent('connection-lost', () => {
    setCommunicationLostCount(communicationLostCount() + 1);
    if (communicationLostCount() >= 2 && communicationLostShown() === false) {
      setCommunicationLostShown(true);
      createAndDispatchNotification(t('notifications.lostConnection'), NotificationVariant.WARNING, t, {
        autohide: false,
      });
    }
  });
  watchEvent('connection-restored', () => {
    if (communicationLostShown() === true) {
      setCommunicationLostCount(0);
      setCommunicationLostShown(false);
      createAndDispatchNotification(t('notifications.communicationRestored'), NotificationVariant.SUCCESS, t, {
        clearPrevious: true,
      });
    }
  });

  // Initialize moment's locale
  moment.locale(navigator.language);
  momentTZ.updateLocale(navigator.language, (moment.localeData() as any)._config);

  useFavicon(constant.productName);

  return loading ? null : (
    <>
      <NotificationController />
      <BrowserRouter>
        <ThemeContext.Provider value={Config.theme}>
          {/* Include haiui stylesheets sooner than our stylesheet */}
          <FormControl id="stylesheets" style={{ display: 'none' }} />
          <MakitoRoutes />
        </ThemeContext.Provider>
      </BrowserRouter>
    </>
  );
};

bootstrap();
