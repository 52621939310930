import { Dialog, colorValue } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { isNil } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'src/js/component/actionBar/actionBar';
import { NoContentAvailable } from 'src/js/component/no-content-available';
import { SortType } from 'src/js/component/sort-manager';
import { triggerEvent, watchEvent } from 'src/js/events';
import { useAdaptiveFetch } from 'src/js/hook/use-adaptive-fetch';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useStores } from 'src/js/hook/use-stores';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import decoderModels, { fetchDecoders } from 'src/js/pages/streaming/decoder/decoder-store';
import { StreamingActionBar, scrollPanelIntoView } from 'src/js/component/actionBar/streaming-action-bar';
import { useWatchObject } from 'src/js/store/use-watch';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';

import { AddStream, StreamAddFormRef } from './add-stream-modal';
import { StreamDeleteConfirmModal } from './delete-confirm-modal';
import { StreamList } from './stream-list';
import { StreamEncapsulation, StreamViewModel } from './stream-models';
import { StreamSetLatencyModal } from './stream-set-latency-modal';
import streamModels, { deleteStream, fetchStreams, resetStreamStatistics, updateStream } from './stream-store';
import { createNewStreamViewModel, streamModelToStatus } from './stream-view-model-mapper';

let connectionLost = false;
export const Streams: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();
  const newStreamModel = createNewStreamViewModel();
  const addStreamRef = useRef<StreamAddFormRef>();

  // state management BEGINs
  const [streams, setStreams] = useState(streamModels);
  const [initialized, setInitialized] = useState(false);
  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const [addModalShown, showAddModal, hideAddModal] = useModalControls();
  const [addButtonState, setAddButtonState] = useState<ButtonStateType>(undefined);

  const updateStreamList = (updates: StreamViewModel[]) => {
    setStreams((existing) => {
      return updates.map((s) => {
        //const decoder = decoders.find((d) => (s.id ? d.streamId === s.id : false));
        const item = existing.find((i) => i.id == s.id); // TODO we need some uuid in a private field for deletable objects like streams.
        if (item) {
          s.selected = item.selected;
          s.hidden = item.hidden;
        }
        return s;
      });
    });
  };

  useWatchObject(streamModels, updateStreamList);

  useAdaptiveFetch(
    () =>
      fetchStreams(t).then((res) => {
        if (res.ok) {
          setInitialized(true);
          if (connectionLost) {
            triggerEvent('connection-restored');
            connectionLost = false;
          }
        } else if (res.problem === 'NETWORK_ERROR') {
          triggerEvent('connection-lost');
          connectionLost = true;
        }
      }),
    5000,
  );

  const fetchData = () => {
    fetchDecoders(t);
  };

  useEffect(() => {
    fetchData();
  }, []);

  watchEvent('preset-load', () => {
    fetchStreams(t);
    fetchData();
  });
  // state management ENDs

  const statusTypes: Status[] = [
    { status: 'STREAMING', color: colorValue('haiui-green-01') },
    { status: 'LISTENING', color: colorValue('haiui-blue-04') },
    { status: 'ALERT', color: colorValue('haiui-amber-01') },
    { status: 'INACTIVE', color: colorValue('haiui-gray-07') },
  ];

  const filteredSelect = () => streams.filter((e) => e.selected);
  const handleDeleteStreams = () => {
    const filtered = filteredSelect();
    if (filtered.length > 0) {
      Promise.all(filtered.map((e) => deleteStream(e))).then((responses) => {
        triggerEvent('preset-update');
        const failed = responses.filter((r) => !r.ok);
        if (failed.length > 0) {
          createAndDispatchNotification(
            t('stream.notifications.deleteError', { count: failed.length }),
            NotificationVariant.ERROR,
            t,
          );
        }
        // remove all deleted streams from decoder streamId
        responses
          .filter((r) => r.ok)
          .forEach((r) => {
            const streamId = (r.data as any).streamId;
            if (streamId > 0) {
              decoderModels
                .filter((d) => d.streamId === streamId)
                .forEach((d) => {
                  d.streamId = -1;
                });
            }
          });
      });
    }
  };

  const handleResetStreamStatistics = () => {
    const filtered = filteredSelect();
    if (filtered.length > 0) {
      Promise.all(filtered.map((e) => resetStreamStatistics(e, t))).then((responses) => {
        const failed = responses.filter((r) => !r.ok);
        if (failed.length > 1) {
          createAndDispatchNotification(
            t('stream.notifications.resetStatsError', { count: failed.length }),
            NotificationVariant.ERROR,
            t,
          );
        } else {
          createAndDispatchNotification(
            t('stream.notifications.resetSelectedStreamsSuccess', { count: filtered.length }),
            NotificationVariant.SUCCESS,
            t,
          );
        }
      });
    }
  };

  const [latencyShown, showLatency, hideLatency] = useModalControls();

  const filteredLatency = () => streams.filter((e) => e.selected && e.encapsulation === StreamEncapsulation.SRT);
  const handleSetStreamLatency = (data: any) => {
    hideLatency();
    const filtered = filteredLatency();
    if (filtered.length > 0) {
      Promise.all(filtered.map((e) => updateStream({ ...e, latency: data.latency }, t, false))).then((responses) => {
        triggerEvent('preset-update');
        const success = responses.filter((r) => r.ok);
        createAndDispatchNotification(
          t('stream.notifications.latencyChangeSuccessMsg', {
            count: success.length,
            latency: data.latency,
          }),
          NotificationVariant.SUCCESS,
          t,
        );
      });
    }
  };

  const handleSelect = (item: StreamViewModel, selected: boolean) => {
    const models = streams.map((i) => (i.id === item.id ? { ...i, selected } : i));
    setStreams(models);
  };

  useEffect(() => {
    // reset all checkbox states when navigating for first time
    return () => {
      // cleanup, so when we come back to the page, we are not flashed with a partially or fully selected list
      streams.map((i) => (i.selected = false));
    };
  }, []);

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') {
      const button = document.getElementById('stream-add-form-submit');
      if (!isNilOrEmpty(button)) {
        e.preventDefault();
        button.click();
      }
    }
  };

  const handleAddStream = () => {
    setAddButtonState('pending');
    addStreamRef.current.submitAddStream().then((res) => {
      setAddButtonState(undefined);
      if (res?.ok) {
        const response = res.data.data;
        hideAddModal();
        scrollPanelIntoView({ id: response.info.id }, true);
      }
    });
  };

  const sortInfo = {
    id: { title: t('sort.sortText.id'), type: SortType.GENERIC },
    name: { title: t('sort.sortText.name'), type: SortType.NATURAL_SORT },
    encapsulationTextual: { title: t('sort.sortText.encapsulation'), type: SortType.NATURAL_SORT }, // aka Protocol "TS over UDP"
    connection: { title: t('sort.sortText.connection'), type: SortType.NATURAL_SORT },
    decoderId: { title: t('sort.sortText.decoder'), type: SortType.GENERIC },
    stateSortable: { title: t('sort.sortText.status'), type: SortType.GENERIC },
  };

  return (
    <>
      {initialized && (isNil(streams) || streams.length === 0) ? (
        <NoContentAvailable
          title={t('stream.noContent.title')}
          subText={t('stream.noContent.subText')}
          btnText={t('stream.noContent.btnText')}
          iconName="Streams"
          onClick={showAddModal}
          btnDisabled={sessionStore.isUser()}
        />
      ) : (
        <StreamingActionBar
          items={streams}
          searchKeys={['name', 'encapsulationTextual', 'connection', 'srtLatency']}
          sortInfo={sortInfo}
          statusTypes={statusTypes}
          getStateValue={streamModelToStatus}
          actionButtons={
            sessionStore.isUser()
              ? []
              : [
                  {
                    onClick: handleResetStreamStatistics,
                    counter: filteredSelect,
                    label: t('stream.RESETSTATS'),
                  },
                  {
                    onClick: showLatency,
                    counter: filteredLatency,
                    label: t('stream.setLatency'),
                  },
                  { onClick: showDelete, counter: filteredSelect, label: t('general.delete') },
                ]
          }
          List={StreamList}
          updateSelection={handleSelect}
          storageKey="streams"
          actionRightPrimary={
            sessionStore.isUser()
              ? null
              : {
                  onClick: showAddModal,
                  children: t('stream.button.addStream'),
                }
          }
          placeholderThumbnail={true}
          placeholderToggles={4}
          placeholderRightComponents={1}
        />
      )}
      {deleteShown && (
        <StreamDeleteConfirmModal
          itemsToDelete={filteredSelect()}
          onCancel={hideDelete}
          onApply={handleDeleteStreams}
        />
      )}
      {addModalShown && (
        <Dialog
          title={t('stream.addStream')}
          dialogType="activity"
          headerIcon="Streams"
          accentColor={constant.productColor}
          size="lg"
          className="mk-three-columns"
          content={<AddStream model={newStreamModel} ref={addStreamRef} />}
          onClose={hideAddModal}
          onKeyUp={handleOnKeyUp}
          buttons={[
            { variant: 'secondary', onClick: hideAddModal, label: t('general.cancel') },
            {
              id: 'stream-add-form-submit',
              variant: 'primary',
              state: addButtonState,
              close: false,
              onClick: handleAddStream,
              label: t('stream.button.addStream'),
            },
          ]}
        />
      )}
      {latencyShown && (
        <StreamSetLatencyModal streams={filteredLatency()} onSubmit={handleSetStreamLatency} onCancel={hideLatency} />
      )}
    </>
  );
};
