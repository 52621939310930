import { ApiErrorResponse, ApiOkResponse } from 'apisauce';
// TODO move to streams/
import { FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { convertCSVStringToData } from 'src/js/helper/csv-helper';
import { StreamSrtStatsResponse } from 'src/js/model/api/response/stream-srt-stats-response';
import { NotificationVariant } from 'src/js/notification/notification';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { createWatchStore, updateWatchStore } from 'src/js/store/use-watch';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { isEmptyIp } from 'src/js/validator/ip-validator-helper';
import { SourceType } from './stream-helper';
import {
  GetAllStreamsResponse,
  GetStreamResponse,
  SRTMode,
  StreamEncapsulation,
  StreamResponse,
  StreamViewModel,
} from './stream-models';
import { mapStreamRequest, mapStreamViewModel } from './stream-view-model-mapper';

// values will be inited from API at runtime
const streamModels: StreamViewModel[] = [];
createWatchStore(streamModels);
export default streamModels;

export const saveResponseToStreams = (response: StreamViewModel[]) => {
  updateWatchStore(streamModels, response);
};

const replaceViewModel = (response: StreamResponse, t: TFunction, selected = false) => {
  const viewModel = mapStreamViewModel(response, t);
  const newList = [...streamModels];
  const index = newList.findIndex((e) => e.id === viewModel.id);
  if (index > -1) {
    newList.splice(index, 1, { ...viewModel, selected: selected, hidden: false, updated: null });
  }
  saveResponseToStreams(newList);
};

export const addViewModel = (response: StreamResponse, t: TFunction, selected = false) => {
  const viewModel = mapStreamViewModel(response, t);
  const newList = [...streamModels];
  const index = newList.findIndex((e) => e.id === viewModel.id);
  if (index !== -1) {
    /* eslint-disable no-console */
    console.error('model is already on list', viewModel.id);
  }
  newList.splice(0, 0, { ...viewModel, selected: selected, hidden: false, updated: null });
  saveResponseToStreams(newList);
};

export const removeStreamViewModel = (id: number) => {
  const newList = [...streamModels];
  const index = newList.findIndex((e) => e.id === id);
  if (index > -1) {
    newList.splice(index, 1);
  }
  saveResponseToStreams(newList);
};

export const validateHostname = async (
  formik: any,
  hostname: string,
  fieldName: string,
  showRedText: boolean,
  t: TFunction,
) => {
  let isValid = true;
  const path = APIPath.hostnamevalidate.stream;
  return apiClientWithoutHandler.genericController.post(path, { hostname: hostname }).then((res: any) => {
    // notification
    if (res.ok) {
      const response = res.data;
      if (response != null && response.success == false) {
        // Success false means we need to print an warning or error
        let notificationType = NotificationVariant.WARNING;
        let message = response.message ?? 'Please correct';
        if (response.message && response.message.startsWith("Failed to resolve host name '")) {
          // crop the ending hostname
          message = t('error.failedToResolve');
        }
        if (response.error === true) {
          notificationType = NotificationVariant.ERROR;
          isValid = false;
          formik.setErrors({ [fieldName]: message });
        } else if (showRedText === true) {
          const data = { [fieldName]: message };
          formik.setErrors(data);
        }

        if (response.message != null) {
          createAndDispatchNotification(response.message, notificationType, t);
        } else {
          createAndDispatchNotification(t('error.Unable to resolve destination address'), notificationType, t);
        }
      }
      return isValid;
    } else {
      createAndDispatchNotification(t('error.Failed to validate stream address'), NotificationVariant.ERROR, t);
      return false; // keep going anyway, dont stop stream creation for validation api problems.
    }
    return true; // unexpected failure, continue anyway
  });
};

export const fetchStreams = (t: TFunction) => {
  return apiClientWithoutHandler.genericController.get<GetAllStreamsResponse>(APIPath.streams.list).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        const streamsViewModels = response.data?.map((stream: StreamResponse) => mapStreamViewModel(stream, t));
        saveResponseToStreams(streamsViewModels);
      }
    }
    return res;
  });
};

// if returning true, continue saving
const prepareSaveFunc = async (model: StreamViewModel, formik: FormikProps<any>, t: TFunction) => {
  const warningGetRedText = false; // not showing because the form is refreshed after submit

  if (model.udpStreamConversion) {
    // validate IP
    const warningGetRedText = false;
    const proceed = await validateHostname(formik, model.srtToUdpAddress, 'srtToUdpAddress', warningGetRedText, t);

    if (!proceed) {
      return false;
    }
  }

  if (
    (model.encapsulation === StreamEncapsulation.UDP || model.encapsulation === StreamEncapsulation.RTP) &&
    model.sourceType === SourceType.multicast &&
    !isEmptyIp(model.sourceIp)
  ) {
    // validate IP
    const proceed = await validateHostname(formik, model.sourceIp, 'sourceIp', warningGetRedText, t);
    if (!proceed) {
      return false;
    }
  } else if (model.encapsulation == StreamEncapsulation.SRT && model.srtMode !== SRTMode.LISTENER) {
    // validate IP
    const proceed = await validateHostname(formik, model.address, 'address', warningGetRedText, t);
    if (!proceed) {
      return false;
    }
  }

  return true;
};

export const addStream = async (
  model: StreamViewModel,
  t: TFunction,
): Promise<Partial<ApiErrorResponse<GetStreamResponse> | ApiOkResponse<GetStreamResponse>>> => {
  const path = APIPath.streams.list;
  const request = mapStreamRequest(model);
  request.userData = uuidv4();

  return apiClientWithoutHandler.genericController
    .post<GetStreamResponse>(path, request)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          addViewModel(response.data, t);
        }
      }
      return res;
    })
    .then((res) => {
      notificationHandler(
        res,
        isNilOrEmpty(res.data?.data?.info?.name)
          ? t('stream.notifications.addSuccessEmptyName')
          : t('stream.notifications.addSuccess', { name: res.data?.data?.info?.name }),
        isNilOrEmpty(res.data?.data?.info?.name)
          ? t('stream.notifications.addErrorEmptyName')
          : t('stream.notifications.addError', { name: res.data?.data?.info?.name }),
        t,
      );
      return res;
    });
};

export const addStreamForm = async (model: StreamViewModel, formikContext: FormikProps<any>, t: TFunction) => {
  const proceed = await prepareSaveFunc(model, formikContext, t);
  if (!proceed) {
    return { ok: false };
  }

  return addStream(model, t);
};

export const updateStream = (model: StreamViewModel, t: TFunction, displayMsg = true) => {
  const path = APIPathWithArguments(APIPath.streams.single, { id: model.id });
  const request = mapStreamRequest(model);
  /* add in MX4D 1.7 
  if (request.userData === '') {
    request.userData = uuidv4(); // upgrade stream with unique ID
  }*/
  return apiClientWithoutHandler.genericController
    .put<GetStreamResponse>(path, request)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          replaceViewModel(response.data, t);
        }
      }
      return res;
    })
    .then((res) => {
      notificationHandler(
        res,
        displayMsg
          ? isNilOrEmpty(res.data?.data?.info?.name)
            ? t('stream.notifications.updateSuccessEmptyName')
            : t('stream.notifications.updateSuccess', { name: res.data?.data?.info?.name })
          : null,
        isNilOrEmpty(res.data?.data?.info?.name)
          ? t('stream.notifications.updateErrorEmptyName')
          : t('stream.notifications.updateError', { name: res.data?.data?.info?.name }),
        t,
      );
      return res;
    });
};

export const updateStreamForm = async (model: StreamViewModel, formikContext: FormikProps<any>, t: TFunction) => {
  const proceed = await prepareSaveFunc(model, formikContext, t);
  if (!proceed) {
    return { ok: false };
  }

  return updateStream(model, t);
};

export const fetchStreamSRTGraph = (
  model: StreamViewModel,
  interval: string,
  redundancyPath?: number,
  setter?: (values: StreamSrtStatsResponse[]) => void,
) => {
  return apiClientWithoutHandler.genericController
    .get<string>(APIPathWithArguments(APIPath.streams.stats, { id: model.id }), {
      interval: interval,
      type: 'srt',
      redundancyPath: redundancyPath,
    })
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        const array = convertCSVStringToData<StreamSrtStatsResponse>(response);
        if (array) {
          setter(array);
        }
      } else {
        setter([]);
      }
      return res;
    });
};

export const fetchStreamGenericGraph = (
  model: StreamViewModel,
  interval: string,
  setter?: (values: StreamSrtStatsResponse[]) => void,
) => {
  return apiClientWithoutHandler.genericController
    .get<string>(APIPathWithArguments(APIPath.streams.stats, { id: model.id }), {
      interval: interval,
      type: 'generic',
    })
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        const array = convertCSVStringToData<StreamSrtStatsResponse>(response);
        if (array) {
          setter(array);
        }
      } else {
        setter([]);
      }
      return res;
    });
};

export const fetchStreamStatistics = (
  model: Partial<StreamViewModel>,
  t: TFunction,
  setStatistics?: (values: StreamViewModel) => void,
) => {
  return apiClientWithoutHandler.genericController
    .get<GetStreamResponse>(APIPathWithArguments(APIPath.streams.stats, { id: model.id }))
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        setStatistics?.(mapStreamViewModel(response.data, t));
      }
      return res;
    });
};

export const resetStreamStatistics = (
  model: StreamViewModel,
  t: TFunction,
  setStatistics?: (values: StreamViewModel) => void,
) => {
  if (model == null) {
    return Promise.resolve({ ok: true });
  }
  return apiClientWithoutHandler.genericController
    .delete<GetStreamResponse>(APIPathWithArguments(APIPath.streams.stats, { id: model?.id ?? -1 }), {})
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (setStatistics) {
          setStatistics?.(mapStreamViewModel(response.data, t));
        } else {
          replaceViewModel(response.data, t);
        }
      }
      return res;
    });
};

export const startStopStream = (model: StreamViewModel, start: boolean, t: TFunction) => {
  const path = APIPathWithArguments(start ? APIPath.streams.start : APIPath.streams.stop, {
    id: model.id,
  });
  return apiClientWithoutHandler.genericController
    .put<GetStreamResponse>(path)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          replaceViewModel(response.data, t);
        }
      }
      return res;
    })
    .then((res) => {
      notificationHandler(res, null, null, t);
      return res;
    });
};

export const deleteStream = (model: StreamViewModel) => {
  const path = APIPathWithArguments(APIPath.streams.single, {
    id: model.id,
  });
  return apiClientWithoutHandler.genericController.delete(path).then((res) => {
    if (res.ok) {
      removeStreamViewModel(model.id);
    }
    res.data = { streamId: model.id };
    return res;
  });
};

//export const isNewStream = (model: Partial<StreamViewModel>) => model.id === -1;
export const isNewStreamId = (id: number) => id === -1;
