import { Button, Dialog } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { isNil } from 'ramda';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { triggerEvent } from 'src/js/events';
import { constant } from 'src/js/constant';
import { useMutable } from '../hook/use-mutable';
import { createListStore, ListSelector } from '../util/listSelector';
import { v4 as uuidv4 } from 'uuid';

import { PresetLoadTable } from './preset-load-table';
import { Presets, Preset } from './preset-models';
import { loadPreset } from './preset-store';

interface Props {
  presets: Presets;
  onCancel: VoidFunction;
  shouldReloadData: VoidFunction;
}

export const PresetLoad: React.FunctionComponent<Props> = ({ presets, onCancel, shouldReloadData }) => {
  const { t } = useTranslation();

  let listController: ListSelector<Preset> = null;
  const [uuid] = useMutable(uuidv4());
  listController = createListStore(presets.data, uuid(), 'name');

  const [selected, setSelected] = useState(false);
  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);

  const handleLoad = () => {
    setButtonState('pending');
    loadPreset(listController.selectedRows()[0], t).then((res) => {
      if (res.ok) {
        listController.unselectAll();
        setButtonState(undefined);
        onCancel();
        shouldReloadData();
        triggerEvent('preset-load');
      }
    });
  };

  if (isNil(presets)) {
    return null;
  }

  const content = (): ReactNode => {
    return (
      <form className="HaiForm">
        <PresetLoadTable listController={listController} setSelected={setSelected} />
        <div className="d-flex justify-content-end hai-mt-8">
          <Button onClick={onCancel}>{t('general.cancel')}</Button>
          <Button onClick={handleLoad} state={buttonState} variant="primary" disabled={!selected}>
            {t('general.load')}
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('presets.presetLoad')}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="Presets"
      content={content()}
    />
  );
};
