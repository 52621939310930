import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';

export const NetworkGeneralForm = () => {
  const { t } = useTranslation();

  return (
    <Form>
      <FormSection title={t('settings.network.section.general')}>
        <DynamicContainer minColumns={3}>
          <FormikTextfield name="hostname" mask title={t('settings.network.form.hostname')} required />
        </DynamicContainer>
      </FormSection>
    </Form>
  );
};
