import React from 'react';
import { Route, Routes } from 'react-router';
import { NotificationsTest } from 'src/js/component-tester/components/notifications-test';
import { UploadZoneTest } from 'src/js/component-tester/components/upload-zone-test';
import { NotFound } from 'src/js/component/not-found';
import { RebootingUpgrade } from 'src/js/reboot/rebooting-upgrade';
import { BandwidthStaticTest } from './components/bandwidth-static-test';
import { BandwidthTest } from './components/bandwidth-test';

import { UpgradeUploadCompletedTest } from './components/upgrade-upload-completed';

/**
 * Router to test component in a standalone environment.
 * To use:
 *  - Add a route with the data being your component name. This will be used for the URL.
 *  - Add the component to test. You might need to create test component that simply renders that you need.
 *    The components to test should all in `/component-tester/components`
 *  - The component will then be available using `{URL}/test/{componentName}`.
 *
 *  Example:
 *  <Route exact
 *  path={compileWithArgs<TestPathArgs>(Paths.test)({ componentName: 'upload-zone' })}
 *  component={UploadZoneTest} />
 *  - path: only modify the `componentName` of the data, put the name you want for your url.
 *  - component: specify your component to test, it should be standalone and not require props (see TODO)
 *  - Component is accessible via `{URL}/test/upload-zone`.
 *
 *  USAGE:
 *    http://localhost:3000/test/upload-zone
 *    http://localhost:3000/test/notifications
 *
 *  TODO:
 *   - Add a way to pass custom props here (mock data?).
 *
 */
export const TestRouter: React.FunctionComponent = () => (
  <Routes>
    <Route path={'bandwidth/static'} element={<BandwidthStaticTest />} />
    <Route path={'bandwidth'} element={<BandwidthTest />} />
    <Route path={'notifications'} element={<NotificationsTest />} />
    <Route path={'rebooting-upgrade'} element={<RebootingUpgrade />} />
    <Route path={'upgrade-upload-completed'} element={<UpgradeUploadCompletedTest />} />
    <Route path={'upload-zone'} element={<UploadZoneTest />} />
    <Route element={<NotFound />} />
  </Routes>
);
