import { useRef } from 'react';
import { RxTask } from 'src/js/helper/mobx-tasks';

/**
 * This hook simplifies the usage of tasks in component.
 * It is merely a wrapper around the `task.current` so we don't need to call the current method.
 * Create a const with the hook and the task like so:
 *    const componentTask = useTask(task());
 * You can then use your task as a function
 *
 */
export const useTask = <T>(task: RxTask<T>) => useRef<RxTask<T>>(task).current;
