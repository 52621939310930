import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onSave?: VoidFunction;
  onLogout?: VoidFunction;
  onCancel?: VoidFunction;
  bodyText?: string;
}

export const LogoutNeededNotSavedModal: React.FunctionComponent<Props> = ({ onSave, onLogout, onCancel, bodyText }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      headline={t('logoutNotSavedModal.title')}
      bodyText={bodyText ?? t('logoutNotSavedModal.body')}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel'), onClick: onCancel },
        { label: t('logoutNotSavedModal.button.signout'), onClick: onLogout },
        { label: t('logoutNotSavedModal.button.saveSignout'), variant: 'primary', onClick: onSave },
      ]}
    />
  );
};
