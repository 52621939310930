import { TFunction } from 'i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { createWatchStore, updateWatchStore } from 'src/js/store/use-watch';

import { presetName } from './preset-helper';
import {
  GetAllPresetsResponse,
  PresetRequestModel,
  PresetResponse,
  PresetSavedResponse,
  PresetViewModel,
  Presets,
} from './preset-models';

const presets: Presets = {
  data: undefined,
  active: '',
  activeIsStartup: false,
  activeWasModified: false,
  autosave: false,
};

createWatchStore(presets);
export default presets;

export const saveResponseToPresets = (response: Presets) => {
  updateWatchStore(presets, response);
};

export const fetchPresets = (_t: TFunction) => {
  return apiClientWithoutHandler.genericController.get<GetAllPresetsResponse>(APIPath.presets.list).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        saveResponseToPresets(response);
      }
    }
    return res;
  });
};

export const savePresetWithoutChangingActive = (model: PresetViewModel, t: TFunction) => {
  return apiClientWithoutHandler.genericController
    .post<PresetResponse>(APIPath.presets.add, { ...model, changeActive: false })
    .then((res) => {
      notificationHandler(
        res,
        t('presets.notifications.saveWithoutActiveSuccessMsg', { name: presetName(model.name) }),
        t('presets.notifications.saveWithoutActiveErrorMsg', { name: presetName(model.name) }),
        t,
      );
      return res;
    });
};

export const createPreset = (model: PresetRequestModel, overwrite: boolean, t: TFunction) => {
  return apiClientWithoutHandler.genericController.post<PresetResponse>(APIPath.presets.add, model).then((res) => {
    notificationHandler(
      res,
      t(overwrite ? 'presets.notifications.overwriteSuccessMsg' : 'presets.notifications.createSuccessMsg', {
        name: presetName(model.name),
      }),
      t(overwrite ? 'presets.notifications.overwriteErrorMsg' : 'presets.notifications.createErrorMsg', {
        name: presetName(model.name),
      }),
      t,
    );
    return res;
  });
};

export const updatePreset = (model: PresetViewModel, overwrite: boolean, displaySuccess: boolean, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.presets.update, {
    name: model.name,
  });
  return apiClientWithoutHandler.genericController
    .put<PresetResponse>(path, { ...model, overwriteIfAlreadyExists: overwrite })
    .then((res) => {
      notificationHandler(
        res,
        displaySuccess ? t('presets.notifications.updateSuccessMsg', { name: presetName(model.name) }) : null,
        t('presets.notifications.updateErrorMsg', { name: presetName(model.name) }),
        t,
      );
      return res;
    });
};

export const loadPreset = (model: PresetViewModel, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.presets.load, {
    name: model.name,
  });
  return apiClientWithoutHandler.genericController.put<PresetResponse>(path).then((res) => {
    notificationHandler(
      res,
      t('presets.notifications.loadSuccessMsg', { name: presetName(model.name) }),
      t('presets.notifications.loadErrorMsg', { name: presetName(model.name) }),
      t,
    );
    return res;
  });
};

export const deletePreset = (model: PresetViewModel, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.presets.delete, {
    name: model.name,
  });
  return apiClientWithoutHandler.genericController.delete<PresetResponse>(path).then((res) => {
    notificationHandler(res, null, t('presets.notifications.deleteErrorMsg', { name: presetName(model.name) }), t);
    return res;
  });
};

export const uploadPreset = (file: File, t: TFunction) => {
  return apiClientWithoutHandler.genericController.upload(APIPath.presets.list, { file: file }).then((res) => {
    notificationHandler(
      res,
      t('presets.notifications.importSuccessMsg', { name: file.name }),
      t('presets.notifications.importErrorMsg', { name: file.name }),
      t,
    );
    return res;
  });
};

export const duplicatePreset = (model: PresetViewModel, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.presets.duplicate, {
    name: model.name,
  });
  return apiClientWithoutHandler.genericController.put<PresetResponse>(path, model).then((res) => {
    notificationHandler(
      res,
      t('presets.notifications.duplicateSuccessMsg', { name: presetName(model.name) }),
      t('presets.notifications.duplicateErrorMsg', { name: presetName(model.name) }),
      t,
    );
    return res;
  });
};

export const renamePreset = (
  model: PresetViewModel /* new name is in model */,
  overwrite: boolean,
  currentName: string,
  t: TFunction,
) => {
  const path = APIPathWithArguments(APIPath.presets.rename, {
    name: currentName,
  });

  return apiClientWithoutHandler.genericController
    .put<PresetResponse>(path, { ...model, overwriteIfAlreadyExists: overwrite })
    .then((res) => {
      notificationHandler(
        res,
        t('presets.notifications.renameSuccessMsg', { name: presetName(currentName) }),
        t('presets.notifications.renameErrorMsg', { name: presetName(currentName) }),
        t,
      );
      return res;
    });
};

export const setAutoSavePreset = (autoSave: boolean, t: TFunction) => {
  return apiClientWithoutHandler.genericController.put(APIPath.presets.list, { autosave: autoSave }).then((res) => {
    notificationHandler(
      res,
      autoSave ? t('presets.notifications.autoSaveOnSuccessMsg') : t('presets.notifications.autoSaveOffSuccessMsg'),
      t('presets.notifications.autoSaveErrorMsg'),
      t,
    );
    return res;
  });
};

export const setStartupPreset = (model: PresetViewModel, t: TFunction) => {
  const url = APIPathWithArguments(APIPath.presets.startup, { name: model.name });
  if (model.startup) {
    return apiClientWithoutHandler.genericController.delete<PresetResponse>(url).then((res) => {
      notificationHandler(
        res,
        t('presets.notifications.startupUnsetSuccessMsg', { name: model.name }),
        t('presets.notifications.startupUnsetErrorMsg', { name: model.name }),
        t,
      );
      return res;
    });
  }
  return apiClientWithoutHandler.genericController.put<PresetResponse>(url).then((res) => {
    notificationHandler(
      res,
      t('presets.notifications.startupSetSuccessMsg', { name: model.name }),
      t('presets.notifications.startupSetErrorMsg', { name: model.name }),
      t,
    );
    return res;
  });
};

export const isCurrentPresetSaved = () => {
  return apiClientWithoutHandler.genericController.get<PresetSavedResponse>(APIPath.presets.isSaved).then((res) => {
    return res;
  });
};
