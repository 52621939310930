import { action, observable, computed } from 'mobx';

export class PreferenceStore {
  @observable
  private doNotDisplayNetworkModal = false;

  @computed get shouldDislayNetworkModal(): boolean {
    return !this.doNotDisplayNetworkModal;
  }

  @action
  setHasSeenNetworkModal() {
    this.doNotDisplayNetworkModal = true;
  }
}
