import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';

import { DateTimeResponse } from 'src/js/model/api/response/date-time-response';
import { Dispatch, SetStateAction } from 'react';
import { DateTime, mapDateTime } from './date-and-time-view-model';

export const fetchDateTime = (setDateTime: Dispatch<SetStateAction<DateTime>>) => {
  return apiClientWithoutHandler.genericController.get<DateTimeResponse>(APIPath.datetime).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        setDateTime(mapDateTime(response));
      }
    }
    return res;
  });
};
