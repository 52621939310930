import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onAccept?: VoidFunction;
  onCancel?: VoidFunction;
}

export const DecoderResetAllStatsModal: React.FunctionComponent<Props> = ({ onAccept, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      headline={t('dashboard.resetAllModal.title')}
      bodyText={t('dashboard.resetAllModal.body')}
      onClose={onCancel}
      show={true}
      buttons={[{ label: t('general.cancel') }, { label: t('general.reset'), variant: 'primary', onClick: onAccept }]}
    />
  );
};
