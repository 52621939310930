import { colorValue } from '@hai/ui-react';
// We need this rule because backend uses snake cases in some cases
import { TFunction } from 'i18next';
import isNil from 'ramda/src/isNil';
import pick from 'ramda/src/pick';
import pickBy from 'ramda/src/pickBy';
import without from 'ramda/src/without';
import { IconStatusType } from 'src/js/model/icon-status-type';
import {
  DecoderStatsStateEnum,
  DecoderViewModel,
  translateDecoderState,
} from 'src/js/pages/streaming/decoder/decoder-model';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { wrapString } from 'src/js/util/string-util';
import { ipv6Regex, isEmptyIp, isMulticast } from 'src/js/validator/ip-validator-helper';
import { isEmptyPort } from 'src/js/validator/port-validator-helper';
import v from 'voca';

import { SourceType, translateStreamEncapsulation, translateStreamState } from './stream-helper';
import {
  FecRTP,
  getStreamUuid,
  NetworkInterfaces,
  SRTAuthentication,
  SRTMode,
  SrtPathRedundancyMode,
  StreamEncapsulation,
  StreamRequest,
  StreamResponse,
  StreamSourceType,
  StreamState,
  StreamViewModel,
} from './stream-models';

/* Unused as of 1.4, replaced with a washed down connection string
const emptyStreamNameFormat = (encapsulation: string, address: string, port: string): string =>
  `${encapsulation}://${address}:${port}`;
*/

const connectionFormat = (sourceAddress: string, destinationAddress: string): string =>
  `${sourceAddress} ➜ ${destinationAddress}`;

const streamEncapsulationToString = (streamEncapsulation: StreamEncapsulation, t: TFunction): string =>
  t(`stream.enums.encapsulation.${streamEncapsulation}`);

/* Unused as of 1.4 but could be useful later
const decoderEncapsulationToString = (
  streamEncapsulation: StreamEncapsulation,
  t: TFunction,
): string => t(`decoder.encapsulation.${streamEncapsulation}`);
*/

export const streamStateToIconStatusType = (state: StreamState, srtToUDP = false): IconStatusType => {
  switch (state) {
    case StreamState.ACTIVE:
      return srtToUDP ? IconStatusType.STREAMING : IconStatusType.ACTIVE;
    case StreamState.LISTENING:
    case StreamState.CONNECTING:
    case StreamState.CONNECTED:
      return IconStatusType.READY;
    case StreamState.FAILED:
    case StreamState.INVALID:
      return IconStatusType.ERROR;
    case StreamState.STOPPED:
      return IconStatusType.STOPPED;
    case StreamState.UNKNOWN:
      return IconStatusType.UNASSIGNED;
    case StreamState.RESOLVING:
    case StreamState.SCRAMBLED:
    case StreamState.SECURING:
    case StreamState.UNLICENSED:
      return IconStatusType.WARNING;
  }
};

export const streamStateToIcon = (streamState: StreamState): string => {
  switch (streamState) {
    case StreamState.ACTIVE:
      return 'StatusStreaming';
    case StreamState.LISTENING:
      return 'StatusListening';
    case StreamState.CONNECTING:
    case StreamState.CONNECTED:
      return 'StatusConnecting';
    case StreamState.SECURING:
      return 'StatusSecuring';
    case StreamState.RESOLVING:
      return 'StatusResolving';
    case StreamState.SCRAMBLED:
      return 'StatusScrambled';
    case StreamState.FAILED:
    case StreamState.INVALID:
    case StreamState.UNLICENSED:
      return 'StatusError';
    case StreamState.STOPPED:
      return 'StatusStopped';
    default:
      return 'StreamFailed';
  }
};

export const streamStateMessage = (stream: StreamViewModel, decoder: DecoderViewModel, t: TFunction): string => {
  let streamMsg = translateStreamState(stream?.stats?.state, t);
  if (stream?.state === StreamState.ACTIVE) {
    if (decoder && decoder?.stats?.state === DecoderStatsStateEnum.NOT_DECODING && decoder?.stats?.troubleCode <= -1) {
      streamMsg = translateDecoderState(decoder?.stats?.state, decoder?.stats?.troubleCode, t);
    }
  }
  return streamMsg;
};

export const iconStatusTypeToPanelColor = (statusType: IconStatusType): string | undefined => {
  switch (statusType) {
    case IconStatusType.OK:
    case IconStatusType.ACTIVE:
      return 'green-01';
    case IconStatusType.STREAMING:
      return 'purple';
    case IconStatusType.READY:
      return 'blue-01';
    case IconStatusType.ERROR:
      return 'red-01';
    case IconStatusType.STOPPED:
      return 'gray';
    case IconStatusType.UNASSIGNED:
      return 'gray';
    case IconStatusType.WARNING:
      return 'yellow';
  }
  return undefined;
};

// if editing here, also adjust streamStateToSortable()
export const streamStateToColor = (state: StreamState): string => {
  switch (state) {
    case StreamState.ACTIVE:
      return colorValue('haiui-green-01');
    case StreamState.STOPPED:
      return colorValue('haiui-gray-07');
    case StreamState.LISTENING:
      return colorValue('haiui-blue-04');
    case StreamState.CONNECTED:
    case StreamState.RESOLVING:
    case StreamState.SCRAMBLED:
    case StreamState.CONNECTING:
    case StreamState.SECURING:
      return colorValue('haiui-amber-01');
    case StreamState.FAILED:
    case StreamState.INVALID:
    case StreamState.UNLICENSED:
    case StreamState.UNKNOWN:
      return colorValue('haiui-red-01');
    default:
      return colorValue('haiui-amber-01');
  }
};

export const streamStateToSortable = (state: StreamState): number => {
  switch (state) {
    case StreamState.ACTIVE:
      return 1;
    case StreamState.STOPPED:
      return 4;
    case StreamState.LISTENING:
      return 2;
    case StreamState.CONNECTED:
    case StreamState.RESOLVING:
    case StreamState.SCRAMBLED:
    case StreamState.CONNECTING:
    case StreamState.SECURING:
      return 3;
    case StreamState.FAILED:
    case StreamState.INVALID:
    case StreamState.UNLICENSED:
    case StreamState.UNKNOWN:
      return 5;
    default:
      return 5;
  }
};

// For ActionBar status filter
export const streamStateToStatus = (state: StreamState): string => {
  switch (state) {
    case StreamState.ACTIVE:
      return 'STREAMING';
    case StreamState.LISTENING:
      return 'LISTENING';
    case StreamState.CONNECTED:
    case StreamState.RESOLVING:
    case StreamState.SCRAMBLED:
    case StreamState.FAILED:
    case StreamState.CONNECTING:
    case StreamState.SECURING:
    case StreamState.UNKNOWN:
    case StreamState.UNLICENSED:
      return 'ALERT';
    case StreamState.STOPPED:
      return 'INACTIVE';
  }
  return 'ALERT';
};

export const streamModelToStatus = (model: StreamViewModel): string => {
  return streamStateToStatus(model.state);
};

// Covered by unit test stream-view-model-mapper.spec.ts
const connectionString = (response: StreamResponse, t: TFunction, forDecoderDropdown?: boolean): string => {
  {
    const { srtMode, encapsulation } = response.info;
    let { address, sourceIp, sourcePort, port } = response.info;
    const state = response.stats.state;
    let sourceAddress = response.stats.sourceAddress;
    const localPort = response.stats.srt?.localPort;
    let remotePort = response.stats.srt?.remotePort;

    let multicastIp = null; // any specific address here is intended to be a multicast address.
    if (!isEmptyIp(address)) {
      multicastIp = address; // any specific address here is intended to be a multicast address.
    }

    if (response.info.encapsulation === StreamEncapsulation.SRT && !isNil(response.stats.srt)) {
      if (sourceAddress === 'Unknown') {
        if (
          response.stats.srt.peerAddress &&
          !isEmptyIp(response.stats.srt.peerAddress) &&
          response.stats.srt.state === 'ACTIVE'
        ) {
          sourceAddress = response.stats.srt.peerAddress;
          if (srtMode === SRTMode.CALLER || srtMode === SRTMode.LISTENER) {
            sourcePort = response.stats.srt.localPort; // R4KD-1398
          }
        } else if (
          response.stats.srt.path2?.peerAddress &&
          !isEmptyIp(response.stats.srt.path2?.peerAddress) &&
          response.stats.srt.path2?.state === 'ACTIVE'
        ) {
          // path 1 is inactive; path2 is active
          address = sourceAddress = response.stats.srt.path2.peerAddress;
          remotePort = response.stats.srt.path2.remotePort;
          if (srtMode === SRTMode.CALLER || srtMode === SRTMode.LISTENER) {
            port = response.info.srtRedundancyPath2Port; // R4KD-1398
            sourcePort = response.stats.srt.path2.localPort; // R4KD-1398
          }
        }
      }
    }

    let sourceLeftSide = t('stream.defaultListAddress');
    let sourceRightSide = '';

    if (encapsulation === StreamEncapsulation.RTSP) {
      return address;
    }

    const isSrtCaller = encapsulation === StreamEncapsulation.SRT && srtMode === SRTMode.CALLER;
    const isSrtRendezVous = encapsulation === StreamEncapsulation.SRT && srtMode === SRTMode.RENDEZVOUS;
    const isSrtCallerOrRendezVous = isSrtCaller || isSrtRendezVous;

    if (state === StreamState.STOPPED || isEmptyIp(sourceAddress) || forDecoderDropdown === true) {
      sourceAddress = null; // ignore sourceAddress from stats
    }

    if (isEmptyIp(sourceIp)) {
      sourceIp = null;
    }

    if (isSrtCallerOrRendezVous) {
      sourceLeftSide = address;
      if (ipv6Regex.test(sourceLeftSide)) {
        sourceLeftSide = wrapString(sourceLeftSide, '[', ']');
      }

      if (isSrtCaller) {
        sourceLeftSide += ':' + port;
      }
    }

    if (isSrtCaller) {
      if (sourcePort && sourcePort !== 0) {
        sourceRightSide += ':' + sourcePort;
      } else if (state === StreamState.ACTIVE && localPort && localPort !== 0) {
        sourceRightSide += ':' + localPort;
      } else {
        sourceRightSide += ':' + t('stream.defaultPort');
      }
      multicastIp = null;
    } else if (isSrtRendezVous) {
      if (sourcePort && sourcePort !== 0) {
        sourceLeftSide += ':' + sourcePort;
      } else {
        sourceLeftSide += ':' + port;
      }
      multicastIp = null;
    } else if (sourceAddress && !isEmptyIp(sourceAddress)) {
      sourceLeftSide = sourceAddress;
      if (ipv6Regex.test(sourceAddress)) {
        if (encapsulation === StreamEncapsulation.SRT && srtMode === SRTMode.LISTENER && state === StreamState.ACTIVE) {
          // listener
          sourceLeftSide = wrapString(sourceLeftSide, '[', ']');
        }
      }
    }

    // FD-771, case 11
    if (
      encapsulation === StreamEncapsulation.SRT &&
      srtMode === SRTMode.LISTENER &&
      state === StreamState.ACTIVE &&
      remotePort &&
      remotePort !== 0
    ) {
      sourceLeftSide += ':' + remotePort;
    }

    if (multicastIp !== null) {
      if (ipv6Regex.test(multicastIp)) {
        multicastIp = wrapString(multicastIp, '[', ']');
      }

      sourceRightSide += multicastIp;

      if (sourceIp && !isEmptyIp(sourceIp)) {
        sourceLeftSide = sourceIp;
      }

      if (sourceAddress && !isEmptyIp(sourceAddress)) {
        // give precedence to STATS over INFO (FD-1761)
        sourceLeftSide = sourceAddress;
      }
    }

    if (state !== StreamState.STOPPED && state !== StreamState.ACTIVE && !isSrtCallerOrRendezVous) {
      if (multicastIp === null || sourceIp === null) {
        sourceLeftSide = t('stream.defaultPort'); // (none)
      }
    }

    if (!isSrtCaller) {
      sourceRightSide += ':' + port;
    }

    if (sourceRightSide !== null) {
      return connectionFormat(sourceLeftSide, sourceRightSide);
    }
    return sourceLeftSide;
  }
};

export const decoderOptionsStreamName = (_t: TFunction, stream: StreamViewModel): string => {
  if (stream == null) {
    return '';
  }
  if (isNilOrEmpty(stream.name)) {
    const address = stream.address;
    if (stream.encapsulation === StreamEncapsulation.RTSP) {
      return stream.id.toString() + ') ' + address;
    }
    return stream.id.toString() + ') ' + stream.connectionForDecoderDropdown;
  }
  return stream.name;
};

export const streamListStreamName = (t: TFunction, name?: string): string =>
  isNilOrEmpty(name) ? t('stream.unnamedStream') : name;

// new stream default values
export const createNewStreamViewModel = (): StreamViewModel => ({
  id: constant.newModelId,
  name: '',
  connection: ' ',
  connectionForDecoderDropdown: ' ',
  protocol: '',
  address: '',
  sourceIp: '',
  encapsulation: StreamEncapsulation.UDP,
  encapsulationTextual: 'TS over UDP',
  networkInterface: NetworkInterfaces.AUTO,
  stateSortable: 0,
  srtLatency: 0,
  sourceType: SourceType.unicast,
  fecRtp: FecRTP.DISABLED,
  srtMode: SRTMode.LISTENER,
  sourcePort: '',
  port: '',
  latency: '125',
  encrypted: false,
  passphrase: '',
  udpStreamConversion: false,
  srtToUdpAddress: '',
  srtToUdpPort: '',
  srtToUdpTtl: '64',
  srtToUdpTos: '0x80',
  srtStreamId: false /* pure UI variable */,
  srtAccessControlPublishingIdType: 'standard',
  lastUpdated: null,
  authentication: SRTAuthentication.NONE,
  srtRedundancyMode: SrtPathRedundancyMode.NONE,
  srtRedundancyPath1Role: 'primary',
  srtRedundancyPath1Address: '',
  srtRedundancyPath2Address: '',
  srtRedundancyPath1Port: '',
  srtRedundancyPath2Port: '',
  srtRedundancyPath1SourcePort: '',
  srtRedundancyPath2SourcePort: '',
  srtRedundancyPath1Name: '',
  srtRedundancyPath2Name: '',
  srtRedundancyPath2NetworkInterface: 'auto',
  srtListenerSecondPort: '',
  audioPids: [],
  stats: {},
  selected: false,
  hidden: false,
  updated: new Date().toISOString(),
});

export const mapStreamViewModel = (stream: StreamResponse, t: TFunction): StreamViewModel => {
  const status = streamStateToIconStatusType(stream.stats?.state, stream.info.srtToUdp);
  return {
    name: stream.info.name ?? '',
    protocol: streamEncapsulationToString(stream.info.encapsulation, t),
    id: stream.info.id,
    decoderId: stream.info.decoderId,
    connection: connectionString(stream, t),
    connectionForDecoderDropdown: connectionString(stream, t, true),
    state: stream.stats.state,
    status,
    statusColor: iconStatusTypeToPanelColor(status),
    encapsulationTextual: translateStreamEncapsulation(stream.info.encapsulation, t),
    srtLatency: stream.stats?.srt?.latency > 0 ? stream.stats?.srt?.latency : stream.info.latency,
    stateSortable: streamStateToSortable(stream.stats?.state),
    userData: stream.info.userData,
    uuid: getStreamUuid(stream.info),

    // Form management
    address: isEmptyIp(stream.info.address) ? '' : stream.info.address,
    sourceIp: isEmptyIp(stream.info.sourceIp) ? '' : stream.info.sourceIp,
    encapsulation: stream.info.encapsulation,
    sourceType:
      !isNil(stream.info.address) && isMulticast(stream.info.address) ? SourceType.multicast : SourceType.unicast,
    fecRtp: stream.info.fecRtp ?? FecRTP.DISABLED,
    srtMode: stream.info.srtMode ?? SRTMode.LISTENER,
    sourcePort: isEmptyPort(stream.info.sourcePort?.toString()) ? '' : stream.info.sourcePort.toString(),
    port: stream.info.port.toString(),
    audioPids: Array.isArray(stream.info.audioPids) ? stream.info.audioPids.join(',') : stream.info.audioPids,
    videoPids: Array.isArray(stream.info.videoPids) ? stream.info.videoPids.join(',') : stream.info.videoPids,
    dataPids: Array.isArray(stream.info.dataPids) ? stream.info.dataPids.join(',') : stream.info.dataPids,
    audioPidsPlaceholder: stream.stats?.sources
      ?.filter((s) => s.type === StreamSourceType.AUDIO)
      .map((s) => s.programId)
      .join(', '),
    videoPidsPlaceholder: stream.stats?.sources
      ?.filter((s) => s.type === StreamSourceType.VIDEO)
      .map((s) => s.programId)
      .join(', '),
    dataPidsPlaceholder: stream.stats?.sources
      ?.filter((s) => s.type === StreamSourceType.DATA)
      .map((s) => s.programId)
      .join(', '),
    programNumber: stream.info.programNumber > 0 ? stream.info.programNumber : '',
    programNumberPlaceholder:
      stream.stats?.programNumber != '' && stream.stats?.programNumber != '0' ? stream.stats.programNumber : undefined,
    latency: `${stream.info.latency ?? '125'}`,
    networkInterface: isNilOrEmpty(stream.info.networkInterface)
      ? NetworkInterfaces.AUTO
      : stream.info.networkInterface,
    passphrase: '',
    passphraseSet: stream.info.passphraseSet,
    encrypted: stream.info.passphraseSet ?? false,
    authentication: stream.info.authentication,
    udpStreamConversion: stream.info.srtToUdp ?? false,
    srtToUdpAddress: isEmptyIp(stream.info.srtToUdp_address) ? '' : stream.info.srtToUdp_address,
    srtToUdpPort: isEmptyPort(stream.info.srtToUdp_port?.toString()) ? '' : stream.info.srtToUdp_port.toString(),
    srtToUdpTos: isNilOrEmpty(stream.info.srtToUdp_tos) ? '0x80' : stream.info.srtToUdp_tos,
    srtToUdpTtl: `${stream.info.srtToUdp_ttl ?? '64'}`,
    srtStreamId: stream.info.srtAccessPublishingID !== undefined && stream.info.srtAccessPublishingID.length > 0,
    srtAccessControlPublishingIdType: stream.info.srtAccessControlPublishingIdType,
    srtAccessPublishingID: stream.info.srtAccessPublishingID,
    srtAccessResourceName: stream.info.srtAccessResourceName,
    srtAccessUserName: stream.info.srtAccessUserName,
    lastUpdated: Date.now(),
    srtRedundancyMode: stream.info.srtRedundancyMode ?? SrtPathRedundancyMode.ACTIVE_ACTIVE,
    srtRedundancyPath1Role: stream.info.srtRedundancyPath1Role ?? 'primary',
    srtRedundancyPath1Address: stream.info.srtRedundancyPath1Address ?? '',
    srtRedundancyPath2Address:
      stream.info.srtRedundancyPath2Address === undefined ||
      stream.info.srtRedundancyPath2Address === 'Any' ||
      stream.info.srtRedundancyPath2Address === '0.0.0.0'
        ? ''
        : stream.info.srtRedundancyPath2Address,
    srtRedundancyPath1Port:
      stream.info.srtRedundancyPath1Port === 0 || stream.info.srtRedundancyPath1Port === undefined
        ? ''
        : stream.info.srtRedundancyPath1Port.toString(),
    srtRedundancyPath2Port:
      stream.info.srtRedundancyPath2Port === 0 || stream.info.srtRedundancyPath2Port === undefined
        ? ''
        : stream.info.srtRedundancyPath2Port.toString(),
    srtRedundancyPath1SourcePort:
      stream.info.srtRedundancyPath1SourcePort === 0 || stream.info.srtRedundancyPath1SourcePort === undefined
        ? ''
        : stream.info.srtRedundancyPath1SourcePort.toString(),
    srtRedundancyPath2SourcePort:
      stream.info.srtRedundancyPath2SourcePort === 0 || stream.info.srtRedundancyPath2SourcePort === undefined
        ? ''
        : stream.info.srtRedundancyPath2SourcePort.toString(),
    srtRedundancyPath1Name: stream.info.srtRedundancyPath1Name ?? '',
    srtRedundancyPath2Name: stream.info.srtRedundancyPath2Name ?? '',
    srtRedundancyPath2NetworkInterface:
      stream.info.srtRedundancyPath2NetworkInterface && stream.info.srtRedundancyPath2NetworkInterface.length > 0
        ? stream.info.srtRedundancyPath2NetworkInterface
        : 'auto',
    srtListenerSecondPort:
      stream.info.srtListenerSecondPort === 0 || stream.info.srtListenerSecondPort === undefined
        ? ''
        : stream.info.srtListenerSecondPort.toString(),
    stats: stream.stats,

    hidden: false,
    selected: false,
    updated: new Date().toISOString(),
  };
};

// All the fields to be selected for the request based on the selection
const commonFields = [
  'id',
  'encapsulation',
  'name',
  'port',
  'stillImage',
  'passphrase',
  'authentication',
  'networkInterface',
  'srtToUdp',
  'audioPids',
  'videoPids',
  'dataPids',
  'programNumber',
];
const udpRTPUnicastFields = ['fecRtp'];
const udpRTPMulticastFields = ['address', 'sourceIp'];
const srtCommonFields = ['latency', 'srtMode', 'srtToUdp', 'srtAccessPublishingID'];
const srtPathRedundancyFields = [
  'srtRedundancyMode',
  'srtRedundancyPath1Role',
  'srtRedundancyPath1SourcePort',
  'srtRedundancyPath2Address',
  'srtRedundancyPath2Port',
  'srtRedundancyPath2SourcePort',
  'srtRedundancyPath1Name',
  'srtRedundancyPath2Name',
  'srtRedundancyPath2NetworkInterface',
];
const srtPathRedundancyOffFields = ['srtRedundancyMode'];
const srtCallerRendezVousFields = ['sourcePort', 'address'];
const srtListenerFields = ['srtListenerSecondPort'];
const srtUdpConversionFields = ['srtToUdp_address', 'srtToUdp_port', 'srtToUdp_tos', 'srtToUdp_ttl'];

export const mapStreamRequest = (streamViewModel: StreamViewModel): StreamRequest => {
  // We have to do the isNaN logic here because we convert string to number using Number()
  // If you pass an invalid value to Number(), it will return NaN, which is not trimmed automatically.
  const cleanedRequest: any = pickBy((value) => typeof value !== 'number' || !isNaN(value), {
    id: streamViewModel.id === constant.newModelId ? undefined : streamViewModel.id,
    name: streamViewModel.name,
    encapsulation: streamViewModel.encapsulation,
    address: streamViewModel.address,
    port: Number(streamViewModel.port),
    sourceIp: streamViewModel.sourceIp,
    latency: Number(streamViewModel.latency),
    networkInterface: streamViewModel.networkInterface,
    audioPids: streamViewModel.audioPids,
    videoPids: streamViewModel.videoPids,
    dataPids: streamViewModel.dataPids,
    programNumber: streamViewModel.programNumber,
    fecRtp: streamViewModel.fecRtp,
    srtMode: streamViewModel.srtMode,
    sourcePort: Number(streamViewModel.sourcePort),
    passphrase: streamViewModel.encrypted
      ? streamViewModel.passphraseSet && streamViewModel.passphrase === ''
        ? constant.obfuscatedPassphrase
        : streamViewModel.passphrase
      : '',
    authentication: streamViewModel.encrypted ? streamViewModel.authentication : '',
    srtToUdp: streamViewModel.udpStreamConversion,
    srtToUdp_address: streamViewModel.srtToUdpAddress,
    srtToUdp_port: Number(streamViewModel.srtToUdpPort),
    srtToUdp_tos: streamViewModel.srtToUdpTos,
    srtToUdp_ttl: Number(streamViewModel.srtToUdpTtl),
    srtAccessPublishingID: streamViewModel.srtStreamId ? streamViewModel.srtAccessPublishingID : '',

    srtRedundancyMode: streamViewModel.srtRedundancyMode,
    srtRedundancyPath1Role: streamViewModel.srtRedundancyPath1Role,
    srtRedundancyPath1SourcePort: streamViewModel.srtRedundancyPath1SourcePort,
    srtRedundancyPath2Address: streamViewModel.srtRedundancyPath2Address,
    srtRedundancyPath2Port: streamViewModel.srtRedundancyPath2Port,
    srtRedundancyPath2SourcePort: streamViewModel.srtRedundancyPath2SourcePort,
    srtRedundancyPath1Name: streamViewModel.srtRedundancyPath1Name,
    srtRedundancyPath2Name: streamViewModel.srtRedundancyPath2Name,
    srtRedundancyPath2NetworkInterface: streamViewModel.srtRedundancyPath2NetworkInterface,
    srtListenerSecondPort: streamViewModel.srtListenerSecondPort,
  });
  // We have to cleanup the request based on what the user has chosen
  let fieldsToPick: string[] = [];

  switch (+streamViewModel.encapsulation) {
    case StreamEncapsulation.RTP:
    case StreamEncapsulation.UDP:
      fieldsToPick = fieldsToPick.concat(udpRTPUnicastFields);
      if (streamViewModel.sourceType === SourceType.multicast) {
        fieldsToPick = fieldsToPick.concat(udpRTPMulticastFields);
      }
      break;
    case StreamEncapsulation.SRT:
      fieldsToPick = fieldsToPick.concat(srtCommonFields);
      if (+streamViewModel.srtMode === SRTMode.RENDEZVOUS || +streamViewModel.srtMode === SRTMode.CALLER) {
        fieldsToPick = fieldsToPick.concat(srtCallerRendezVousFields);
      } else if (+streamViewModel.srtMode === SRTMode.LISTENER) {
        fieldsToPick = fieldsToPick.concat(srtListenerFields);
      }

      if (
        +streamViewModel.srtMode === SRTMode.CALLER &&
        (streamViewModel.srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_ACTIVE ||
          streamViewModel.srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_BACKUP)
      ) {
        fieldsToPick = fieldsToPick.concat(srtPathRedundancyFields);
        cleanedRequest.sourcePort = cleanedRequest.srtRedundancyPath1SourcePort;
      } else {
        fieldsToPick = fieldsToPick.concat(srtPathRedundancyOffFields);
        if (+streamViewModel.srtMode === SRTMode.LISTENER) {
          cleanedRequest.srtRedundancyMode = SrtPathRedundancyMode.OPTIONAL;
        } else {
          cleanedRequest.srtRedundancyMode = SrtPathRedundancyMode.NONE;
        }
      }
      if (streamViewModel.udpStreamConversion) {
        fieldsToPick = fieldsToPick.concat(srtUdpConversionFields);
      }
  }
  fieldsToPick = fieldsToPick.concat(commonFields);

  // If encrypted is true but passphrase is empty, we need to strip it down because that's
  // how the backend manages whether the passphrase is set or not
  if (streamViewModel.encrypted && v.isEmpty(streamViewModel.passphrase)) {
    fieldsToPick = without(['passphrase'], fieldsToPick);
  }
  // The cast here is safe since we know all the required fields are in `commonFields`
  const returnObj = pick(fieldsToPick, cleanedRequest) as StreamRequest;
  return returnObj;
};
