import { isNil } from 'ramda';
import { GetLocateStatusResponse, LedStatusColor } from 'src/js/model/api/response/get-locate-status-response';
import { LocateStatus } from 'src/js/model/locate-status';
import { isNilOrEmpty } from 'src/js/util/global-util';

export const mapLedStatus = (response: GetLocateStatusResponse): LocateStatus => {
  let blinkingWillStopIn = 0;
  if (!isNil(response.blinkingWillStopIn)) {
    const hours = /(\d+)\s*h/.exec(response.blinkingWillStopIn);
    if (!isNilOrEmpty(hours)) {
      blinkingWillStopIn = Number.parseInt(hours[0], 10) * 3600;
    }
    const minutes = /(\d+)\s*m/.exec(response.blinkingWillStopIn);
    if (!isNilOrEmpty(minutes)) {
      blinkingWillStopIn = Number.parseInt(minutes[0], 10) * 60;
    }
    const seconds = /(\d+)\s*s/.exec(response.blinkingWillStopIn);
    if (!isNilOrEmpty(seconds)) {
      if (isNil(blinkingWillStopIn)) {
        blinkingWillStopIn = Number.parseInt(seconds[0], 10);
      } else {
        blinkingWillStopIn += Number.parseInt(seconds[0], 10);
      }
    }
  }

  return {
    statusColor: LedStatusColor[response.statusLED],
    statusTransitions: response.statusTransitions,
    blinkingWillStopIn: blinkingWillStopIn,
  };
};
