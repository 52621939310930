import { Button } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { isNil } from 'ramda';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import {
  AccountViewModel,
  RoleTypeAdd,
  accountValidationSchemaNoPassword,
} from 'src/js/pages/security/accounts/account-models';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { validateFormContext } from 'src/js/validator/validator';

import { AccountFormValues } from './account-form';

interface GeneralFormProps {
  viewModel?: AccountViewModel;
  onCancel?: VoidFunction;
  onSubmit?: (formValues: AccountFormValues) => void;
  addButtonState: ButtonStateType;
  translator: TFunction;
}

export const AccountAddForm: React.FunctionComponent<GeneralFormProps> = (props) => {
  const { t } = useTranslation();
  const { onCancel, addButtonState } = props;

  const nameInputRef = useRef(undefined);
  useEffect((): void => {
    if (!isNil(nameInputRef.current)) {
      nameInputRef.current.focus();
    }
  }, [nameInputRef]);

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') {
      const button = document.getElementById('account-add-form-submit');
      if (!isNilOrEmpty(button)) {
        e.preventDefault();
        button.click();
      }
    }
  };
  const initialValues = {
    name: props.viewModel.name,
    role: props.viewModel.role,
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  };
  return (
    <Formik
      onSubmit={props.onSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={() => accountValidationSchemaNoPassword(t)}
    >
      {(formikProps) => {
        return (
          <form className="HaiForm" onKeyUp={handleOnKeyUp}>
            <FormikTextfield
              name="name"
              title={t('security.accounts.form.userName')}
              inputClassName="fs-mask" // FullStory Mask
              inputRef={nameInputRef}
              required
            />
            <FormikSelect
              name="role"
              title={t('security.accounts.form.role')}
              selectValues={Object.values(RoleTypeAdd)}
              selectLabels={Object.values(RoleTypeAdd).map((value: string) => t(`security.accounts.roleType.${value}`))}
            />
            <FormikTextfield
              name="password"
              title={t('security.accounts.form.password')}
              autoComplete="new-password"
              type="password"
              pwdToggle
              required
            />
            <FormikTextfield
              name="passwordConfirm"
              title={t('security.accounts.form.passwordConfirm')}
              autoComplete="new-password"
              type="password"
              pwdToggle
              required
            />
            <div className="d-flex justify-content-end hai-mt-6">
              <Button onClick={onCancel}>{t('general.cancel')}</Button>
              <Button
                onClick={() => validateFormContext(formikProps, t)}
                variant="primary"
                id="account-add-form-submit"
                state={addButtonState}
              >
                {t('security.accounts.modal.add.title')}
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
