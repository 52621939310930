export enum StatsType {
  SRT = 'srt',
}

export enum StatsInterval {
  LAST_5_MINS = 'last5min',
  LAST_60_MINS = 'last60min',
  LAST_24_HOURS = 'last24hours',
}

export interface StreamStatRequest {
  interval?: StatsInterval;
  type?: StatsType;
  redundancyPath?: number;
}
