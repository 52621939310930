import { FormControl, FormControlError } from '@hai/ui-react';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { isNil } from 'ramda';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericModal } from 'src/js/component/base/generic-modal';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { getRemainingTime } from 'src/js/pages/settings/status/locate-modal-helper';
import { createStartLocateTask, createStopLocateTask } from 'src/js/task/settings-status-tasks';
import { FormChangeEvent } from 'src/js/util/global-type';
import * as yup from 'yup';

interface Props {
  started: boolean;
  blinkingWillStopIn?: number;
  onStart?: VoidFunction;
  onStop?: VoidFunction;
  onClose?: VoidFunction;
  onDone?: VoidFunction;
}

interface Locate {
  duration: number;
}

let isFormValid = true;
export const LocateModal: React.FunctionComponent<Props> = ({
  started,
  blinkingWillStopIn,
  onStart,
  onStop,
  onClose,
}) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState<number | string>(15);
  const startLocateTask = useTask(createStartLocateTask());
  const stopLocateTask = useTask(createStopLocateTask());

  // Start button with clear timer logic BEGINs
  const [clearTimer, setClearTimer] = useState(undefined);
  useSuccessReaction(startLocateTask, () => {
    if (onStart) {
      onStart();
    }
    const timer = setTimeout(() => {
      startLocateTask.reset();
      setClearTimer(undefined);
    }, 3000);
    setClearTimer(timer);
  });
  useEffect(() => {
    return () => {
      // cleanup
      if (clearTimer) {
        clearTimeout(clearTimer);
      }
    };
  }, [clearTimer]);
  // Start button with clear timer logic ENDs

  useSuccessReaction(stopLocateTask, () => {
    if (onStop) {
      onStop();
    }
  });

  const handleStartLocate = (): void => {
    if (!isFormValid) {
      createAndDispatchNotification(t('validation.invalidSubmit'), NotificationVariant.ERROR, t);
      return;
    }
    startLocateTask(duration);
  };

  const handleStopLocate = (): void => {
    stopLocateTask();
  };

  const locateValidationSchema = (t: TFunction) =>
    yup.object<Locate>().shape({
      duration: yup
        .number()
        .typeError(t('validation.number'))
        .required(t('validation.required'))
        .min(1, t('settings.system.locateModal.validation'))
        .max(60, t('settings.system.locateModal.validation')),
    });

  const renderNotStartedBody = (): ReactNode => (
    <Formik
      initialValues={{ duration: duration }}
      onSubmit={handleStartLocate}
      validationSchema={locateValidationSchema(t)}
    >
      {(formikProps) => {
        isFormValid = formikProps.isValid;
        return (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <div className="modal-body-text">{t('settings.system.locateModal.notStarted.body1')}</div>
              <FormControl
                type="number"
                name="duration"
                min={1}
                max={60}
                value={duration}
                className={`${
                  formikProps.errors?.duration !== undefined && formikProps.errors.duration.length > 0
                    ? 'invalid red'
                    : ''
                }`}
                onChange={(formEvent: FormChangeEvent) => {
                  setDuration(formEvent.target.value);
                  formikProps.handleChange(formEvent);
                }}
              />
              <div className="modal-body-text">{t('settings.system.locateModal.notStarted.body2')}</div>
            </div>
            <div className="locate-validation-error">
              <FormControlError show={!formikProps.isValid} mssg={formikProps.errors.duration} />
            </div>
          </>
        );
      }}
    </Formik>
  );

  if (started && !isNil(blinkingWillStopIn)) {
    const remainingTime = getRemainingTime(blinkingWillStopIn);
    return (
      <GenericModal
        id="locate-device-modal"
        title={t('settings.system.locateModal.started.title')}
        text={t('settings.system.locateModal.started.body', {
          time: remainingTime,
        })}
        applyLabel={t('general.stop')}
        onApply={handleStopLocate}
        applyTask={stopLocateTask}
        cancelLabel={t('general.close')}
        onCancel={onClose}
      />
    );
  } else {
    return (
      <GenericModal
        id="locate-device-modal"
        title={t('settings.system.locateModal.notStarted.title')}
        renderBody={renderNotStartedBody}
        applyLabel={t('general.start')}
        onApply={handleStartLocate}
        applyTask={startLocateTask}
        cancelLabel={t('general.cancel')}
        onCancel={onClose}
      />
    );
  }
};
