import React, { Ref, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { triggerEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { RebootResponse } from 'src/js/model/api/response/reboot-response';
import { Paths } from 'src/js/route';

import { isCurrentPresetSaved } from '../presets/preset-store';
import { createRebootTask } from '../task/settings-tasks';
import { RebootConfirmModal } from './reboot-confirm-modal';
import { RebootNeededNotSavedModal } from './reboot-needed-not-saved-modal';

interface IsSavedProps {
  className?: string;
}

export interface IsSavedRef {
  show: () => void;
}

// To be used with Reboot buttons
export const RebootIsSaved = forwardRef((props: IsSavedProps, ref: Ref<IsSavedRef>) => {
  const { className, ...rest } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rebootTask = useTask(createRebootTask());
  const [savePresetModalShown, showModal, hideModal] = useModalControls();
  const [confirmModalShown, showConfirmModal, hideConfirmModal] = useModalControls();

  useSuccessReaction(rebootTask, (result: RebootResponse) => {
    navigate(result.upgrade ? Paths.rebootingUpgrade : Paths.rebooting);
  });

  const show = () => {
    isCurrentPresetSaved().then((response) => {
      if (response.data?.isSaved === false) {
        showModal();
      } else {
        showConfirmModal();
      }
    });
  };
  useImperativeHandle(ref, () => ({ show }));

  const onCancel = () => {
    hideModal();
  };

  // is saved begins
  const proceedToReboot = (): void => {
    rebootTask();
  };

  return (
    <div className={className} {...rest}>
      {savePresetModalShown && (
        <RebootNeededNotSavedModal
          bodyText={t('reboot.notSaved.body')}
          onCancel={onCancel}
          onReboot={() => {
            hideModal();
            proceedToReboot();
          }}
          onSave={(): void => {
            hideModal();
            triggerEvent('preset-save-reboot');
          }}
        />
      )}
      {confirmModalShown && (
        <RebootConfirmModal
          onCancel={hideConfirmModal}
          onReboot={() => {
            hideConfirmModal();
            rebootTask();
          }}
        />
      )}
    </div>
  );
});

RebootIsSaved.displayName = 'rebootIsSaved'; // this is needed when you make your own component that can take a reference
