import { useRef } from 'react';

/**
 * Hook to get a mutable object.
 * Use it when you want to store a value in your component, but don't want to render the component when it changes.
 * It uses useRef internally.
 * It returns an array of the getter and the setter, similar to useState, but don't forget that the getter is a function.
 * e.g. const [hasChanged, setHasChanged] = useMutable<boolean>(false);
 * @param initialValue
 */
export const useMutable = <T>(initialValue: T): [() => T, (newValue: T) => void] => {
  const ref = useRef<T | null>(initialValue);
  return [
    () => ref.current,
    (newValue: T) => {
      ref.current = newValue;
    },
  ];
};
