import { DropdownButton, DropdownMenuItem } from '@hai/ui-react';
import React from 'react';

/**
 * this component renders a dropdown menu with options
 * options: array of DropdownOption
 * selectedIndex: index of selected option
 */

interface DropdownModel {
  id?: string;
  label?: string;
  className?: string;
  options: DropdownOption[];
  selectedIndex?: number;
  style?: any;
}
/**
 * title: title of option as displayed in menu
 * selectedTitle: title of option when displayed
 * onSelect: function to execute when the option is selected
 * value: a value for the option (to be able to select them properly)
 * label: optional lighter gray top title
 */

export interface DropdownOption {
  title: string;
  selectedTitle: string;
  onSelect: () => void;
  value: any;
}

export const DropdownMenu: React.FunctionComponent<DropdownModel> = ({
  id,
  className,
  options,
  selectedIndex = 0,
  label,
  style,
}) => {
  const selectedTitle = selectedIndex >= 0 ? options[selectedIndex]?.selectedTitle : undefined;
  return (
    <DropdownButton
      label={label}
      title={selectedTitle}
      id={id}
      className={className}
      withCheckmark={false}
      style={style}
      align="right"
    >
      {options.map((option: DropdownOption) => (
        <DropdownMenuItem
          onClick={() => {
            option.onSelect();
          }}
          title={option.title}
          key={option.title}
        />
      ))}
    </DropdownButton>
  );
};
