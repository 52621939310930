import pino from 'pino';

const inferDefaultLogLevel: () => string = () => {
  if (process.env.NODE_ENV === 'test' && process.env.DEBUG_TEST == null) {
    return 'debug';
  }

  if (process.env.NODE_ENV === 'production') {
    return 'info';
  }

  return 'debug';
};

export const log = pino({ level: process.env.LOG_LEVEL || inferDefaultLogLevel() });
