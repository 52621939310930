import { Dialog, DynamicContainer } from '@hai/ui-react';
import { Formik } from 'formik';
import { isNil } from 'ramda';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { UploadZone } from 'src/js/component/base/upload-zone';
import { constant } from 'src/js/constant';

import { CertificateIdentityImport, identityUploadValidationSchema, newIdentityViewModel } from './certificates-models';

interface GeneralFormProps {
  onSubmit: (model: CertificateIdentityImport) => Promise<any>;
  onCancel?: VoidFunction;
}

export const IdentityImportModal = (props: GeneralFormProps) => {
  const { onCancel, onSubmit, ...rest } = props;
  const { t } = useTranslation();
  const nameInputRef = useRef(undefined);

  useEffect((): void => {
    if (!isNil(nameInputRef.current)) {
      nameInputRef.current.focus();
    }
  }, [nameInputRef]);

  const handleStartUpload = (formikProps: any, file?: File, event?: React.FormEvent<HTMLFormElement>): void => {
    formikProps.setFieldValue('file', file); // formik wont add automatically the drop zone file in the form so do it manually here
    formikProps.handleSubmit(event);
  };

  enum IdentityImportTypes {
    IDENTITY = 'id',
    CACHAIN = 'chain',
  }
  const typeValues = Object.values(IdentityImportTypes);

  const formatChoices = [
    { value: 'auto', label: 'Auto' },
    { value: 'pem', label: 'PEM' },
    { value: 'der', label: 'DER' },
    { value: 'p7', label: 'PKCS#7' },
    { value: 'p12', label: 'PKCS#12' },
    { value: 'pfx', label: 'PFX' },
  ];

  const content = (): ReactNode => {
    return (
      <Formik
        initialValues={newIdentityViewModel()}
        onSubmit={onSubmit}
        validationSchema={identityUploadValidationSchema(t)}
        validateOnBlur={false}
        validateOnChange={true}
        {...rest}
      >
        {(formikProps) => {
          return (
            <form className="HaiForm">
              <FormikTextfield name="name" title={t('general.name')} inputRef={nameInputRef} required />
              <FormikTextfield
                name="password"
                title={t('general.password')}
                autoComplete="new-password"
                type="password"
                pwdToggle
              />
              <DynamicContainer minColumns={2} className="hai-mb-4">
                <FormikSelect
                  title={t('general.type')}
                  name="type"
                  selectValues={typeValues}
                  selectLabels={Object.values(IdentityImportTypes).map((type) =>
                    t(`security.certificates.identity.import.typeEnumToLabel.${type}`),
                  )}
                />
                <FormikSelect
                  title={t('general.format')}
                  name="infmt"
                  selectValues={formatChoices.map((item) => item.value)}
                  selectLabels={formatChoices.map((item) => item.label)}
                />
              </DynamicContainer>
              <UploadZone
                onUpload={(file?: File, event?: React.FormEvent<HTMLFormElement>) =>
                  handleStartUpload(formikProps, file, event)
                }
                onCancel={onCancel}
                uploadBtnText={t('general.import')}
              />
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('security.certificates.identity.import.title')}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="Security"
      content={content()}
    />
  );
};
