import { List } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { triggerEvent, watchEvent } from 'src/js/events';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import { scrollPanelIntoView } from 'src/js/component/actionBar/streaming-action-bar';
import { constant } from 'src/js/constant';

import { AccountViewModel, accountIsLocked, accountStateToColor, accountStateToIcon } from './account-models';
import { AccountSettings } from './account-settings';
import { lockUnlockAccount } from './account-store';

interface AccountItemProps {
  model: AccountViewModel;
  onSelect: any;
  scrollToId: number | string;
}
export const AccountItem: React.FunctionComponent<AccountItemProps> = ({ model, onSelect, scrollToId }) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const isSameUser = (name: string) => (name ? name === sessionStore.username : false);

  const location = useLocation();
  const scroll = location?.state?.id === model.id || scrollToId === model.id;

  const [isExpanded, setIsExpanded] = useState<boolean>(scroll);
  const [collapseTimeoutId, setCollapseTimeoutId] = useMutable(null);
  const [expandContent, setExpandContent] = useState<string>(scroll ? 'SETTINGS' : null);
  const onExpandedStateChange = (expandedState: { eventKey: any; expanded: boolean }) => {
    if (isExpanded !== expandedState.expanded) {
      setIsExpanded(expandedState.expanded);
    }
  };

  watchEvent('expandAll:edit', () => {
    setExpandContent('SETTINGS');
    setIsExpanded(true);
  });
  watchEvent('expandAll:stats', () => {
    setExpandContent('STATISTICS');
    setIsExpanded(true);
  });
  const collapseAll = () => {
    setIsExpanded(false);
    setCollapseTimeoutId(
      setTimeout(() => {
        // the component needs to stay rendered during close for smooth closing
        setExpandContent(null);
        setCollapseTimeoutId(null);
      }, constant.collapseTime),
    );
  };
  watchEvent('collapseAll:edit', collapseAll);
  watchEvent('collapseAll:stats', collapseAll);

  const onSelectHandler = (eventKey: string, expanded: boolean, ev: KeyboardEvent) => {
    switch (eventKey) {
      case 'SETTINGS':
        if (ev && ev.shiftKey) {
          // expend all
          if (expanded) {
            triggerEvent('expandAll:edit');
          } else {
            triggerEvent('collapseAll:edit');
          }
          return;
        }
        setExpandContent(eventKey);
        if (expanded) {
          setIsExpanded(true);
          if (collapseTimeoutId() !== null) {
            clearTimeout(collapseTimeoutId());
            setCollapseTimeoutId(null); // prevent closing of panel
          }
          scroll && scrollPanelIntoView({ id: model.id });
        } else {
          setIsExpanded(false);
          setCollapseTimeoutId(
            setTimeout(() => {
              // the component needs to stay rendered during close for smooth closing
              //setExpandContent(null); commented out to remember last setting
              setCollapseTimeoutId(null);
            }, constant.collapseTime),
          );
        }
        break;
      case 'LOCK':
        lockUnlockAccount(model, !accountIsLocked(model.state), false, t);
        break;
      default:
        break;
    }
  };

  const btnId = `account-settings-${model.id}`;
  const handleCancelEdit = () => {
    const btn = document.getElementById(btnId);
    if (!isNil(btn)) {
      btn.click();
    }
  };

  const statusColor = accountStateToColor(model.state);

  return (
    <div id={`panel-${model.id}`} className={model.hidden ? 'mk-row-hidden' : ''} style={{ scrollMarginTop: '21px' }}>
      <List.Panel
        checked={model.selected}
        onSelect={(selected: boolean) => onSelect(model, selected)}
        panelColor={statusColor}
        expandedState={{ eventKey: expandContent, expanded: isExpanded }}
        onExpandedStateChange={onExpandedStateChange}
      >
        <List.PanelTitle
          useFsMask={true}
          title={model.name}
          statusIcon={accountStateToIcon(model.state)}
          iconColor={statusColor}
          className="fs-mask" // FullStory Mask
        />
        <List.PanelDetail>
          <List.PanelDetailItem divider="vertical" text={model.role} />
          <List.PanelDetailItem text={model.expiryTxt} />
        </List.PanelDetail>
        <List.Actions>
          <List.ActionItem
            expandButton
            eventKey="SETTINGS"
            icon="Settings"
            title={t('general.settings')}
            onSelect={onSelectHandler}
            id={btnId}
          />
          <List.ActionItem isDivider />
          <List.ActionItem
            eventKey="LOCK"
            icon={accountIsLocked(model.state) ? 'Lock' : 'Unlock'}
            showActive={false}
            onSelect={onSelectHandler}
            disabled={isSameUser(model.name)}
          />
        </List.Actions>
        <List.ExpandedPanel>
          {expandContent === 'SETTINGS' && <AccountSettings model={model} onCancel={handleCancelEdit} />}
        </List.ExpandedPanel>
      </List.Panel>
    </div>
  );
};
