import { FormControl, FormControlError, FormGroup, FormLabel, Icon } from '@hai/ui-react';
import { useField } from 'formik';
import { isNil } from 'ramda';
import React from 'react';
import { isNilOrEmpty } from 'src/js/util/global-util';

export enum Status {
  Error = 'error',
  Inactive = 'inactive',
  OK = 'ok',
  Paused = 'paused',
  Ready = 'ready',
  Starting = 'starting',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Streaming = 'streaming',
  ToStorage = 'to-storage',
  Unassigned = 'unassigned',
  Warning = 'warning',
}

interface Props {
  name: string;
  mask?: boolean;
  status?: Status;
  title?: string;
  disabled?: boolean;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  controlProps?: any;
  className?: string;
}

export const FormikTextFieldWithIcon: React.FunctionComponent<Props> = ({
  name,
  mask,
  title,
  status,
  disabled,
  placeholder,
  hint,
  required = false,
  className,
}) => {
  const [field, meta] = useField(name);
  const hasErrors = meta.error && meta.touched;

  const hasIcon = !isNil(status);
  return (
    <FormGroup className={className}>
      <div className="d-flex">
        {!isNilOrEmpty(title) && <FormLabel required={required}>{title}</FormLabel>}
        {hasIcon && <Icon className="hai-ml-1" type="status" subtype={status} size="sm3" />}
      </div>
      <FormControl
        disabled={disabled}
        useFsMask={mask}
        invalid={hasErrors}
        placeholder={hint ?? placeholder}
        showHint={!isNilOrEmpty(hint)}
        {...field}
      ></FormControl>
      <FormControlError show={hasErrors} mssg={meta.error} />
    </FormGroup>
  );
};
