import { clone, isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { FormAggregatorClientInterface } from 'src/js/component/multiple-form-aggregator';
import { triggerEvent } from 'src/js/events';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import { notificationHandler } from 'src/js/notification/notification-handler'; // TODO bring that code in here for proximity to usage
import { DecoderItem } from 'src/js/pages/dashboard/decoder-item';
import {
  DecoderAction,
  DecoderInfoStateEnum,
  DecoderResponse,
  DecoderViewModel,
  mapDecoderViewModel,
} from 'src/js/pages/streaming/decoder/decoder-model';
import decoderModels, { decodersStore } from 'src/js/pages/streaming/decoder/decoder-store';
import { useWatchObject } from 'src/js/store/use-watch';

import { mapViewModelToDecoder } from '../streaming/decoder/decoder-mapper';

export interface DecoderItemFetchProps {
  id: number;
  onClickGlobalSave: Subject<string>;
  formAggregator: FormAggregatorClientInterface;
}

//  Array<Partial<DecoderViewModel>>
const getFirstRoundOfApplies = () => {
  // sort by order of apply
  // split into two steps, (1) unassignation of SDI output, (2) assignations
  // this is to avoid conflict and do changes without stopping.
  const partialUpdates: Array<Partial<DecoderViewModel>> = [];
  decodersStore.getAll().forEach((decoder) => {
    const original = decodersStore.getInitial(decoder.id);

    let addThisDecoderToPrep = false;
    const partialUpdateStep1: Partial<DecoderViewModel> = { outputs: [] };
    if (decoder.state === DecoderInfoStateEnum.STARTED) {
      for (let i = 0; i < decoder.outputs.length; i++) {
        if (original.outputs[i] && !decoder.outputs[i]) {
          // removing output i
          partialUpdateStep1.id = decoder.id;
          partialUpdateStep1.outputs[i] = false;
          addThisDecoderToPrep = true;
        } else if (original.outputs[i] && decoder.outputs[i]) {
          // keep that output on during partial update
          partialUpdateStep1.id = decoder.id;
          partialUpdateStep1.outputs[i] = true;
        }
      }
      if (addThisDecoderToPrep) {
        partialUpdates.push(partialUpdateStep1);
      }
    }
  });

  // console.log('STEP1 apply', partialUpdates)
  return partialUpdates;
};

/* This is for a single decoder item, the HTTP GET & PUT operations and handling of states */
export const DecoderItemFetch: React.FunctionComponent<DecoderItemFetchProps> = ({
  id,
  onClickGlobalSave,
  formAggregator,
  ...rest
}) => {
  const { t } = useTranslation();
  const decoderIndex = decoderModels.findIndex((i) => i.id == id);
  const [decoder, setDecoder] = useState(decoderIndex >= 0 ? clone(decoderModels[decoderIndex]) : null);
  const { sessionStore } = useStores();
  const [updatedDecoderValues, setUpdatedDecoderValues] = useMutable<DecoderViewModel>(null); // this is what is submitted in PUT request

  //FIXME put back fast refresh after apply
  //const refreshInterval = 6000; // dynamic, normal speed
  // const refreshIntervalFast = 2000; // dynamic, goes faster after action
  // let refreshIntervalFastCounter = 0;

  useWatchObject(decoderModels, (updated) => {
    const myDecoder = decodersStore.get(id);
    if (!isNil(myDecoder)) {
      //setDecoder(clone(myDecoder));
      const decForStats = updated.find((m) => m.id === id);
      setDecoder({
        ...myDecoder,
        state: decForStats.state,
        previewIntervalSec: decForStats.previewIntervalSec,
        previewEnabled: decForStats.previewEnabled,
        color: decForStats.color,
        dashboardColor: decForStats.dashboardColor,
        stats: decForStats?.stats,
      });
    }
  });

  const sendAndSubscribeToRequest = (action: DecoderAction) => {
    const viewModel = decodersStore.get(decoder.id);
    if (viewModel == null) {
      /*eslint no-console: 0*/
      console.error('Failed to find decoder from id', decoder.id, decodersStore);
      return null;
    }
    const request = mapViewModelToDecoder(viewModel);
    let path;
    if (action === DecoderAction.START) {
      path = APIPathWithArguments(APIPath.decoder.start, { id: decoder.id });
    } else if (action === DecoderAction.STOP) {
      path = APIPathWithArguments(APIPath.decoder.stop, { id: decoder.id });
    } else {
      path = APIPathWithArguments(APIPath.decoder.put, { id: decoder.id });
    }

    return apiClientWithoutHandler.genericController
      .put<DecoderResponse>(path, request)
      .then((res) => {
        // notifications
        let errorMessage = '';
        switch (action) {
          case DecoderAction.START:
            errorMessage = t('decoder.notifications.startError');
            break;
          case DecoderAction.STOP:
            errorMessage = t('decoder.notifications.stopError');
            break;
          default:
            errorMessage = t('decoder.notifications.settingsChangeErrorMsg');
        }
        notificationHandler(res, null, errorMessage, t);
        return res;
      })
      .then((res) => {
        if (res.ok) {
          const decoderViewModel = mapDecoderViewModel(res.data, t);
          if (action !== DecoderAction.STOP) {
            // reset apply button
            setUpdatedDecoderValues(null);
            // reset apply button ends
            setDecoder({
              // This will cause the view to update with new values
              ...decoder,
              ...decoderViewModel,
            });
            decodersStore.updateInitial(decoderViewModel);
            // FIMXE Move to global save
            decodersStore.reset();
          } else if (action === DecoderAction.STOP) {
            // pending changes need to be kept active in form
            const myInitial = decodersStore.getInitial(decoder.id);
            setDecoder({
              // This will cause the START/STOP button to update with good state after action
              ...decoder,
              state: decoderViewModel.state,
            });
            if (myInitial) {
              decodersStore.updateInitial({ ...myInitial, state: decoderViewModel.state }); // If user click Cancel on dashboard, this preserve Start/Stop button state button state
            }
          }
          // refreshIntervalFastCounter = 4;
          triggerEvent('decoders-apply-completed');
        }
        return res;
      })
      .finally(() => {
        triggerEvent('decoders-apply-completed');
      });
  };

  // cleanup on exit
  useEffect(() => {
    //cleanup
  }, []);
  // End section: Handling form submission subscriptions/unsubscribes

  // 'save'
  // global SAVE button event handler
  useEffect(() => {
    // tag(ORDER-SAVE)
    // from onClickApplyDecoders().next('save')
    const onClickSaveSubscription = onClickGlobalSave.subscribe(
      (message) => {
        if (message === 'cancel') {
          // re init our store
          const original = decodersStore.getInitial(decoder.id);
          setDecoder({ ...original });
          setUpdatedDecoderValues(null); // reset to null to signify no changes
          return;
        } else if (message === 'prep') {
          if (decoder.id === 0) {
            // lets do preps
            const partialUpdate = getFirstRoundOfApplies();
            for (const dec of partialUpdate) {
              if (Object.keys(dec).length) {
                const path = APIPathWithArguments(APIPath.decoder.get, { id: dec.id });
                apiClientWithoutHandler.genericController.put<DecoderResponse>(path, dec);
              }
            }
            // proceed to step 2
            onClickGlobalSave.next('save');
          }
          return;
        }
        // Save all request in updatedDecoderValues()
        if (updatedDecoderValues()) {
          // save only if user changed something
          //FIXME more exact method so we can detect if user reverted back to initial
          sendAndSubscribeToRequest(DecoderAction.SAVE).then(() => {
            //
          });
        } else {
          //console.log('save clicked - no changes');
        }
      },
      (_err) => ({}), // error
      () => ({}), // HTTP request completed)
    );

    return () => {
      onClickSaveSubscription?.unsubscribe();
    };
  }, [decoder]);

  const onFormChange = (formEvent: any) => {
    if (sessionStore.isUser()) {
      return; // read=only
    }

    // This block is specifically for react-select custom event
    if (!formEvent.stopPropagation && formEvent.label && formEvent.eventKey === undefined) {
      setDecoder({
        ...decoder,
        streamId: formEvent.value, // streamId selection is currently the only react-select dropdown in this view
      });
      setUpdatedDecoderValues({ ...decoder, streamId: formEvent.value });
      decodersStore.onChangeItem(updatedDecoderValues());
      return;
    }

    if (!formEvent.stopPropagation) {
      if (formEvent.eventKey) {
        // This block is specifically for DropdownMenu custom event
        const split = formEvent.eventKey.split(' ');
        setDecoder({
          ...decoder,
          [split[0]]: Number(split[1]),
        });
        setUpdatedDecoderValues({ ...decoder, [split[0]]: Number(split[1]) });
        decodersStore.onChangeItem(updatedDecoderValues());
        return;
      }
    }

    if (formEvent.stopPropagation) {
      formEvent.stopPropagation();
    }
    const { name } = formEvent.target;
    let { value } = formEvent.target;
    if (formEvent.target.type === 'checkbox') {
      value = formEvent.target.checked;
    }

    if (formEvent.target.dataset && formEvent.target.dataset.numeric === 'true') {
      value = parseInt(value, 10);
    }
    const matches = /^([\w\d]+)\[([\w\d]+)\]$/.exec(name);
    if (matches && matches.length >= 3) {
      // property where `name` like `outputs[3]` as in `decoder.outputs[3] = true;`
      const property = matches[1];
      const index = matches[2];
      const object = decoder[property as keyof DecoderViewModel];
      const objClone = clone(object);
      (objClone as any)[index] = value;
      setDecoder({ ...decoder, [property]: objClone });
      setUpdatedDecoderValues({ ...decoder, [property]: objClone }); // for apply all
      decodersStore.onChangeItem(updatedDecoderValues());
    } else {
      // console.log('changed', name, value);
      setDecoder({
        ...decoder,
        [name]: value,
      });
      setUpdatedDecoderValues({ ...decoder, [name]: value }); // for apply all
      decodersStore.onChangeItem(updatedDecoderValues());
    }
  };

  // Form SUBMIT
  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>, action?: DecoderAction) => {
    event.preventDefault();
    //log.warn('onFormSubmit sending data', decoder, action);

    // FIXME for STOP, it seems to clear the state and doesnt resubmit changes on next START
    return sendAndSubscribeToRequest(action);
  };

  return (
    <>
      {decoder && (
        <DecoderItem
          decoder={decoder}
          onFormSubmit={onFormSubmit}
          onFormChange={onFormChange}
          onClickGlobalSave={onClickGlobalSave}
          formAggregator={formAggregator}
          {...rest}
        />
      )}
    </>
  );
};
