import classNames from 'classnames';
import { includes } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adjustInactiveColors, toggleItem } from 'src/js/helper/chart-helper';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { mapToVictoryData, mapToVictoryInMinutes } from 'src/js/pages/statistic/statistic-mapper';
import { axisTitleStyle, defaultChartColors, horizontalAxisStyle, verticalAxisStyle } from 'src/js/theme/chart';
import { ChartViewModel } from 'src/js/view-model/chart-view-model';
import { VictoryAxis, VictoryChart, VictoryGroup, VictoryLabel, VictoryLine, VictoryVoronoiContainer } from 'victory';
import { compareModels } from 'src/js/util/generic-store';

import { LegendHorizontal } from './legend-horizontal';
import { LegendVertical } from './legend-vertical';
import { constant } from 'src/js/constant';
import { generateDataAuto } from 'src/js/util/automation';

interface MkAxisRange {
  limit: number;
  divideBy: number;
  label: string;
}

export enum AdaptivePadding {
  OFF,
  LIGHT,
  REGULAR,
}

/*
 * Line Chart component for statistics page
 *
 * data: StatsChartViewModel
 * title: Chart title
 * subTitle: Chart subtitle (Used on dashboard)
 * fontSize: size of font for x/y axis labels
 * yTickCount: number of ticks on the y axis
 * padding: padding around graph
 * yFields: fields to render from the data
 * height: height of chart in pixels
 * width: width of chart in pixels
 * xLabel: x axis label
 * yLabel: y axis label
 * showYLabels: whether to show y axis labels
 * chartProps: additional chart props conforming to VictoryThemeDefinition
 * showLegend: whether to display or hide the legend
 *
 */

interface LineChartProps {
  data: ChartViewModel[];
  title?: string;
  subTitle?: string;
  fontSize?: number;
  xTickCount?: number;
  yTickCount?: number;
  padding?: { top: number; bottom: number; right: number; left: number };
  adaptiveLeftPadding?: AdaptivePadding;
  timescale: StatsInterval;
  xAxisMinutes?: boolean;
  height?: number;
  width?: number;
  xLabel?: string;
  yLabel?: string;
  yTitle?: string;
  pathSelected?: string[];
  yRange?: MkAxisRange[];
  showLegend?: boolean | 'left';
  showRedundancy?: boolean;
  dashboard?: boolean;
  showCommasInAxisLabels?: boolean;
  lineColors: string[];
  defaultInactiveFields?: string[];
  setDefaultInactiveFields?: (fields: string[]) => void;
  /** will actively maintain take aspect ratio of parent container
  responsive?: boolean; */
}

type TimeAxisPoint = {
  unix: number;
  label: string;
};

const allPaths = ['path1', 'path2'];

export const LineChart: React.FunctionComponent<LineChartProps> = (props) => {
  const {
    data,
    title,
    subTitle,
    fontSize,
    xTickCount = 5,
    yTickCount = 5,
    padding = { top: 13, bottom: 16, right: 0, left: 0 },
    adaptiveLeftPadding = AdaptivePadding.REGULAR,
    timescale,
    xAxisMinutes = false,
    height,
    width,
    yLabel,
    xLabel,
    yTitle,
    yRange,
    pathSelected = allPaths,
    showLegend = true,
    showRedundancy = false,
    dashboard = false,
    showCommasInAxisLabels = true,
    lineColors = defaultChartColors,
    defaultInactiveFields = constant.emptyArray,
    setDefaultInactiveFields,
    ...rest
  } = props;

  const { t } = useTranslation();
  const yFieldsPath1 = data.map((d) => d.name).filter((n) => !n.endsWith('_Path2'));
  const yFieldsPath2 = data.map((d) => d.name).filter((n) => n.endsWith('_Path2'));

  const [inactiveFields, setInactiveFields] = useState<string[]>(defaultInactiveFields);
  const [inactivePathFields, setInactivePathFields] = useState<string[]>([]);

  useEffect(() => {
    if (defaultInactiveFields && Array.isArray(defaultInactiveFields) && defaultInactiveFields.length > 0) {
      const different = compareModels(defaultInactiveFields, inactiveFields, null);
      if (different) {
        setInactiveFields(defaultInactiveFields);
      }
    }
  }, [defaultInactiveFields]);

  if (yTitle) {
    padding.top += 12;
  }
  useEffect(() => {
    setInactivePathFields((inactiveFields) => {
      if (pathSelected.includes('path1')) {
        // remove all path1 from inactive fields
        inactiveFields = inactiveFields.filter((f) => !yFieldsPath1.includes(f));
      } else {
        // add all path 1 to inactive fields
        inactiveFields = inactiveFields.concat(yFieldsPath1);
      }

      if (pathSelected.includes('path2')) {
        // remove all path 2 from inactive fields
        inactiveFields = inactiveFields.filter((f) => !yFieldsPath2.includes(f));
      } else {
        // add all path 2 to inactive fields
        inactiveFields = inactiveFields.concat(yFieldsPath2);
      }
      return inactiveFields;
    });
  }, [pathSelected]);

  let visibleData = data.filter((chartViewModel: ChartViewModel) => !includes(chartViewModel.name, inactiveFields));
  visibleData = visibleData.filter(
    (chartViewModel: ChartViewModel) => !includes(chartViewModel.name, inactivePathFields),
  ); // filter out unselected path as per path selection dropdown

  let maxYvalue = 0;
  let maxYvalueAjustedAfterTarget = 0;
  let targetRange: MkAxisRange = null;
  let tickValues: TimeAxisPoint[] = undefined;
  const series = visibleData.map((viewModels) => {
    if (xAxisMinutes && viewModels.values !== undefined && viewModels.values.length) {
      let firstPoint = viewModels.values[0].collectedAt;
      const lastPoint = viewModels.values[viewModels.values.length - 1].collectedAt;
      if (lastPoint > firstPoint) {
        firstPoint = lastPoint;
      }
      if (firstPoint != null) {
        tickValues = [0, 1, 2, 3, 4, 5].map((c) => ({
          unix: firstPoint.clone().subtract(c, 'minutes').unix(),
          label: c.toString(),
        }));
      }
      //console.log('tickValues', tickValues);
    }

    viewModels.values.map((yPoint) => {
      if (yPoint.value > maxYvalue) {
        maxYvalue = yPoint.value;
      }
    });

    return {
      data: xAxisMinutes
        ? mapToVictoryInMinutes(viewModels.values, viewModels.label)
        : mapToVictoryData(viewModels.values, timescale, viewModels.label),
      name: viewModels.name,
      style: viewModels.name.includes('_Path2')
        ? { data: { strokeDasharray: '2,7', strokeWidth: '3.3px', strokeLinecap: 'round', color: '#220098' } }
        : undefined,
    };
  });

  let yScale = 'K'; // 999 kbps and less
  if (maxYvalue >= 100000) {
    yScale = '10M'; // 10,000 kbps +
  } else if (maxYvalue >= 10000) {
    yScale = 'M'; // 1,000 kbps +
  }

  if (yRange) {
    yRange.forEach((range) => {
      if (maxYvalue > range.limit) {
        if (targetRange === null) {
          targetRange = range;
        } else if (range.limit > targetRange.limit) {
          // keep highest limit
          targetRange = range;
        }
      }
    });

    if (targetRange) {
      series.map((line) => {
        line.data.map((point) => {
          if (point.yOriginal === undefined) {
            point.yOriginal = point.y;
          }
          point.y /= targetRange.divideBy;
          if (point.y > maxYvalueAjustedAfterTarget) {
            maxYvalueAjustedAfterTarget = point.y;
          }
        });
      });
    } else {
      series.map((line) => {
        line.data.map((point) => {
          if (point.y > maxYvalueAjustedAfterTarget) {
            maxYvalueAjustedAfterTarget = point.y;
          }
        });
      });
    }
  }

  // filter out all _Path2 from data to get the line y-values names & labels (Latency, RTT, etc..)
  const yFields = data.filter((chartViewModel) => !showRedundancy || !chartViewModel.name.endsWith('_Path2'));

  const yFieldNames = yFields.map((chartViewModel) => chartViewModel.name);

  // Map legend labels from array to object
  let legendItems = yFields.map((yField) => ({ label: yField.label, value: yField.name }));

  // remove legend item for lines without data
  legendItems = legendItems.filter((legend) => {
    const populatedSeries = data.filter((serie) => serie.values.length > 0);
    return populatedSeries.find((serie) => serie.name === legend.value);
  });
  // legendItem = yFields.map((yField) => ({ label: yField.label, value: yField.name }));

  const adjustedColorScale = adjustInactiveColors(yFieldNames, inactiveFields, lineColors); // FIXME also add inactive from path selections
  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /* NKE-2421 to make large labels fit */
  // calculate max amount of digits shown in label
  if (adaptiveLeftPadding === AdaptivePadding.REGULAR) {
    if (yScale === '10M') {
      padding.left = 70;
    } else if (yScale === 'M') {
      padding.left = 60;
    } else {
      padding.left = 53;
    }
    if (dashboard) {
      padding.left += 11; // NKE-2469 dashboard have smaller graphs and axis labels need more reserved space
    }
  } else if (adaptiveLeftPadding === AdaptivePadding.LIGHT) {
    if (yRange) {
      if (maxYvalueAjustedAfterTarget > 100000) {
        padding.left += 20;
      }
      if (maxYvalueAjustedAfterTarget > 10000) {
        padding.left += 20;
      }
      if (maxYvalueAjustedAfterTarget > 1000) {
        padding.left += 20;
      }
    } else {
      if (yScale === '10M') {
        padding.left += 40;
      } else if (yScale === 'M') {
        padding.left += 20;
      }
    }
  }
  //padding.left = 0;
  //console.log('adjustedColorScale', adjustedColorScale);
  //adjustedColorScale.push('#009911')
  return (
    <div className={classNames('mk-chart-container', showLegend === 'left' && 'leftLegend')} {...rest}>
      {title && (
        <div className="statistics-graph-header" title={title}>
          {title}
        </div>
      )}
      {subTitle && (
        <div className="statistics-graph-subheader" title={subTitle}>
          {subTitle}
        </div>
      )}
      <div className="mk-victory-container" data-auto={generateDataAuto('line', 'graph')}>
        <VictoryChart
          domainPadding={{ x: 10, y: 15 }}
          height={height}
          width={width}
          padding={padding}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => {
                // tooltip graph
                // console.log('datum', datum);
                const amountOfSeries = lineColors?.length;
                if (amountOfSeries === 1) {
                  // dont print axis label since there is only one
                  if (Math.round(datum.y as number) == 0 && datum.y > 0 && datum.yOriginal > 0) {
                    return `${Math.round(datum.yOriginal as number)} ${yTitle}`;
                  }
                  if (yTitle != null) {
                    return `${Math.round(datum.y as number)} ${targetRange ? targetRange.label : yTitle}`;
                  }
                  return `${Math.round(datum.y as number)} ${targetRange ? targetRange.label : ''}`;
                }

                if (Math.round(datum.y as number) == 0 && datum.y > 0 && datum.yOriginal > 0) {
                  return `${datum.lineName as string} ${Math.round(datum.yOriginal as number)} ${yTitle}`; // TODO we could still improve by accessing other targetRanges instead of going to the default one
                }
                let value;
                if (datum.y >= 9.9) {
                  value = Math.round(datum.y as number);
                } else {
                  value = Math.round((datum.y * 100) as number) / 100;
                }
                if (yTitle != null) {
                  return `${datum.lineName as string} ${value} ${targetRange ? targetRange.label : yTitle}`;
                }
                return `${datum.lineName as string} ${value} ${targetRange ? targetRange.label : ''}`;
              }}
            />
          }
        >
          <VictoryGroup colorScale={adjustedColorScale}>
            {series.map((line, index: number) => (
              <VictoryLine data={line.data} key={`${data[index].name}-${index}`} style={line.style} />
            ))}
          </VictoryGroup>
          {yTitle && (
            <VictoryLabel
              x={47}
              y={8}
              text={targetRange ? targetRange.label : yTitle}
              style={axisTitleStyle}
              textAnchor="end"
            />
          )}
          <VictoryAxis
            dependentAxis
            label={yLabel}
            style={{
              ...verticalAxisStyle,
              tickLabels: {
                ...verticalAxisStyle.tickLabels,
                fontSize: fontSize ? fontSize : verticalAxisStyle.tickLabels.fontSize,
              },
              axis: {
                stroke: 'transparent',
              },
            }}
            // We round to have 3 decimals maximum, no need for more precision, it makes the graphs look odd
            // We use the `Math.round` to not add the trailing .0 if not needed
            tickFormat={(tick) => {
              const value = Math.round(Number(tick) * 1000) / 1000;
              if (showCommasInAxisLabels === false) {
                return value;
              }
              return numberWithCommas(value);
            }}
            tickCount={yTickCount}
          />
          <VictoryAxis
            style={{
              ...horizontalAxisStyle,
              tickLabels: {
                ...horizontalAxisStyle.tickLabels,
                fontSize: fontSize ? fontSize : horizontalAxisStyle.tickLabels.fontSize,
              },
            }}
            tickValues={tickValues?.map((t) => t.unix)}
            tickCount={tickValues ? tickValues.length : xTickCount}
            tickFormat={(tick) => {
              const item = tickValues?.find((t) => t.unix === tick);
              if (item) {
                return item.label + 'm';
              }
              return tick;
            }}
            padding={{ left: 20, bottom: 20, top: 20 }}
          />
        </VictoryChart>
      </div>
      {showLegend &&
        (showLegend === 'left' ? (
          <LegendVertical
            compact
            handleClick={(item) => {
              if (legendItems && legendItems.length === 1) {
                // dont allow turning off single legend item
                return;
              }
              // one legend item controls both path on/off
              setInactiveFields((inactiveFields) => toggleItem(item, inactiveFields));
              setInactiveFields((inactiveFields) => toggleItem(item + '_Path2', inactiveFields));
            }}
            items={legendItems}
            colorScale={lineColors}
            inactiveItems={inactiveFields}
            title={t('statistics.bandwidth.legendTitle')}
          />
        ) : (
          <LegendHorizontal
            handleClick={(item) => {
              if (legendItems && legendItems.length === 1) {
                // dont allow turning off single legend item
                return;
              }
              // one legend item controls both path on/off
              setInactiveFields((inactiveFields) => {
                const newfields = toggleItem(item, inactiveFields);
                setDefaultInactiveFields?.(newfields);
                return newfields;
              });
              setInactiveFields((inactiveFields) => {
                const newfields = toggleItem(item + '_Path2', inactiveFields);
                setDefaultInactiveFields?.(newfields);
                return newfields;
              });
            }}
            items={legendItems}
            colorScale={lineColors}
            inactiveItems={inactiveFields}
          />
        ))}
    </div>
  );
};
