import { Button, Form, FormControl, FormSection } from '@hai/ui-react';
import { isNil } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { SecurityTabContent } from 'src/js/pages/security/security-tab-content';
import useSWR from 'swr';

import { DeleteMessagesConfirmModal } from './messages-delete-modal';

export const Messages: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [deleteShown, showDelete, hideDelete] = useModalControls();

  const fetcher = (url: string) => {
    return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
      return res?.data;
    });
  };

  const { data, mutate } = useSWR(APIPath.messages, fetcher);
  const shouldReloadData = () => {
    mutate();
  };

  const handleDelete = () => {
    hideDelete();
    shouldReloadData();
  };

  return (
    <SecurityTabContent name={t('security.messages.title')}>
      <Form>
        <FormSection className="hai-mt-3">
          <div id="messages">
            <div className="d-flex hai-mb-6">
              <Button
                className="ml-auto"
                variant="primary"
                onClick={showDelete}
                disabled={isNil(data) || data?.messages.length === 0}
              >
                {t('general.clear')}
              </Button>
            </div>
            {data && (
              <FormControl
                className="hai-mb-6 scrollable fs-mask" // FullStory Mask
                as="textarea"
                rows="25"
                value={data.messages ? data.messages.replace(/&#10;/g, '\n') : ''}
              />
            )}
          </div>
          {deleteShown && <DeleteMessagesConfirmModal onCancel={hideDelete} onApply={handleDelete} />}
        </FormSection>
      </Form>
    </SecurityTabContent>
  );
};
