export enum MonitorFirmwareUploadState {
  UPLOADING = 1,
  UNPACKING = 2,
  SYNCING = 3,
  ERROR = 4, // Strictly frontend. Not returned in API
}

export interface MonitorFirmwareUploadResponse {
  uploaded?: number;
  total?: number;
  state: MonitorFirmwareUploadState;
}
