import { TFunction } from 'i18next';
import { DNSPrecedence, Duplex, Ipv6Addressing, Link, Speed } from 'src/js/model/api/response/network-response';
import { dnsOrEmpty, domain, ipv4, ipv6, netmask } from 'src/js/validator/validator';
import * as yup from 'yup';

export enum Ipv4Addressing {
  DHCP = 'dhcp',
  STATIC = 'static',
}

export type EthX = 'eth0' | 'eth1';

export interface NetworkInterfaceViewModel {
  // IPV4 section
  ipv4: boolean;
  ipv4Addressing: Ipv4Addressing;
  ip: string;
  netmask: string;
  gateway: string;
  dhcpVendorId?: string;
  linklocal?: boolean;

  // IPV6 section
  ipv6: boolean;
  ipv6Addressing: Ipv6Addressing;
  ipv6Address: string;
  ipv6PrefixLength: string;
  ipv6Gateway: string;
  ipv6PrivacyExtension: boolean;
  ipv6LinkLocal: string;
  ipv6List: string[];

  // Ethernet section
  link: Link;
  speed: Speed;
  currentSpeed: Speed;
  duplex: Duplex;
  macAddress: string;
  bandwidth: number;
}

export interface NetworkViewModel {
  alternateDns: string;
  autoDns: boolean;
  dnsPrecedence: DNSPrecedence;
  domain: string;
  eth0: NetworkInterfaceViewModel;
  eth1?: NetworkInterfaceViewModel;
  hasEth1: boolean;
  hostname: string;
  isEth1Sfp: boolean;
  mDnsEnabled: boolean;
  mDnsName: string;
  primaryDns: string;
}

export interface UpdateNetworkViewModel extends NetworkViewModel {
  isSaved: boolean; // current preset
}

// remove trailing subnet
export const filterIPv6Value = (ip: string) => {
  // remove /64 on IPs
  const matches = /^(.*)\/\d+$/.exec(ip);
  if (matches) {
    return matches[1].trim();
  }
  return ip.trim();
};

/*
 * Validation
 */

const networkInterfaceValidationSchema = (t: TFunction) =>
  yup.object().shape({
    ipv4: yup.boolean().required(t('validation.required')),
    ipv4Addressing: yup
      .mixed()
      .oneOf(['dhcp', 'static'], t('validation.network.ipv4DHCPMode'))
      .when('ipv4', {
        is: true,
        then: yup.string().required(t('validation.required')),
      }),
    ip: yup.string().when(['ipv4', 'ipv4Addressing'], {
      is: (ipv4, addressing) => {
        if (ipv4 && addressing === Ipv4Addressing.STATIC) {
          return true;
        }
        return false;
      },
      then: ipv4(t).required(t('validation.required')),
    }),
    netmask: yup.string().when(['ipv4', 'ipv4Addressing'], {
      is: (ipv4, addressing) => {
        if (ipv4 && addressing === Ipv4Addressing.STATIC) {
          return true;
        }
        return false;
      },
      then: netmask(t).required(t('validation.required')),
    }),
    gateway: yup.string().when(['ipv4', 'ipv4Addressing'], {
      is: (ipv4, addressing) => {
        if (ipv4 && addressing === Ipv4Addressing.STATIC) {
          return true;
        }
        return false;
      },
      then: ipv4(t).required(t('validation.required')),
    }),
    linklocal: yup.boolean().when('ipv4Addressing', {
      is: Ipv4Addressing.DHCP,
      then: yup.boolean().required(t('validation.required')),
    }),

    // IPV6 section
    ipv6: yup.boolean().required(t('validation.required')),
    ipv6Addressing: yup
      .mixed()
      .oneOf(['auto', 'dhcp', 'static'], t('validation.network.ipv6DHCPMode'))
      .when('ipv6', {
        is: true,
        then: yup.string().required(t('validation.required')),
      }),
    ipv6Address: yup.string().when(['ipv6', 'ipv6Addressing'], {
      is: (ipv6, addressing) => {
        if (ipv6 && addressing === Ipv6Addressing.STATIC) {
          return true;
        }
        return false;
      },
      then: ipv6(t).required(t('validation.required')),
    }),
    ipv6PrefixLength: yup.number().when(['ipv6', 'ipv6Addressing'], {
      is: (ipv6, addressing) => {
        if (ipv6 && addressing === Ipv6Addressing.STATIC) {
          return true;
        }
        return false;
      },
      then: yup
        .number()
        .typeError(t('validation.number'))
        .required(t('validation.required'))
        .min(1, t('validation.network.ipv6PrefixLength'))
        .max(128, t('validation.network.ipv6PrefixLength')),
    }),
    ipv6Gateway: yup.string().when(['ipv6', 'ipv6Addressing'], {
      is: (ipv6, addressing) => {
        if (ipv6 && addressing === Ipv6Addressing.STATIC) {
          return true;
        }
        return false;
      },
      then: ipv6(t).required(t('validation.required')),
    }),
    ipv6PrivacyExtension: yup.boolean().when('ipv6Addressing', {
      is: Ipv6Addressing.AUTOMATIC,
      then: yup.boolean().required(t('validation.required')),
    }),
    ipv6LinkLocal: yup.string(),
    ipv6List: yup.array(yup.string()),
    macAddress: yup.string().required(t('validation.required')),
    bandwidth: yup
      .number()
      .required(t('validation.required'))
      .typeError(t('validation.network.bandwidth'))
      .min(0, t('validation.network.bandwidth')),
  });

export const networkValidationSchema = (t: TFunction) =>
  yup.object().shape({
    hostname: yup.string().required(t('validation.required')),
    // IPV4 & IPv6 sections
    eth0: networkInterfaceValidationSchema(t),
    eth1: yup.object().when('hasEth1', {
      is: true,
      then: networkInterfaceValidationSchema(t),
    }),
    // DNS section
    domain: domain(t),
    primaryDns: dnsOrEmpty(t),
    alternateDns: dnsOrEmpty(t),
    mDnsName: yup
      .string()
      .matches(/^[^_]*$/, t('validation.network.mDnsNameUnderscore'))
      .matches(/^(?!\s).*(?<!\s)$/, t('validation.network.mDnsNameSpace')),
  });
