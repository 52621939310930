import * as FS from '@fullstory/browser';
import { useEffect } from 'react';
import { Config } from 'src/js/config';
import systemInfo from 'src/js/data/systemInfo';
import { constant } from 'src/js/constant';
import { DecoderInfoStateEnum, DecoderStatsStateEnum, QuadModeEnum } from '../pages/streaming/decoder/decoder-model';
import { StreamEncapsulation, StreamState } from '../pages/streaming/stream/stream-models';
import systemStatus from '../data/systemStatus';
import streamModels from '../pages/streaming/stream/stream-store';
import decoderModels from '../pages/streaming/decoder/decoder-store';
import { licensesList } from '../pages/settings/license/licenses';
import { dataStoreCa, dataStoreIdentity } from '../pages/security/certificates/certificates';

// INPUT: string to hash
// OUTPUT: hash string
async function digestMessage(message: string) {
  let hashHex = null;
  try {
    const encoder = new TextEncoder();
    const data = encoder?.encode?.(message);
    if (!data) {
      return null;
    }
    const hash = await crypto?.subtle?.digest?.('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hash)); // convert buffer to byte array
    hashHex = hashArray?.map((b) => b?.toString?.(16)?.padStart(2, '0'))?.join(''); // convert bytes to hex string
  } catch (ex) {
    return null;
  }
  return hashHex;
}

async function createAnonymizedDeviceId() {
  return digestMessage(systemStatus.serialNumber + ':' + systemInfo.hostname ?? systemInfo.deviceIp);
}

export function useFullStory(isFSEnabled: boolean, systemInfoFetched: boolean) {
  const debug = false;

  useEffect(() => {
    const isInit = FS.isInitialized();
    if (isFSEnabled && !isInit && systemInfoFetched) {
      FS.init({ orgId: 'o-1EQT7G-na1', devMode: Config.isDebug, debug: debug }, ({ sessionUrl }) => {
        /* eslint-disable-next-line no-console */
        console.info(`Started FullStory session: ${sessionUrl}`, systemInfo.version);
        FS.setUserVars({
          product_str: constant.productName,
          environment_str: process.env.NODE_ENV,
          version_str: systemInfo.version,
          platform_str: 'web',
        });
      });
    }
  }, [isFSEnabled, systemInfoFetched]);
}

export function useSendFsStats(isFSEnabled: boolean, decodersFetched: boolean, streamsFetched: boolean) {
  useEffect(() => {
    // console.log('useSendFsStats check?', isFSEnabled , decodersFetched , streamsFetched)
    if (isFSEnabled && FS.isInitialized() && decodersFetched && streamsFetched) {
      createAnonymizedDeviceId()?.then((deviceIdAnonymised) => {
        // console.log('deviceIdAnonymised', deviceIdAnonymised);
        const streams = streamModels;
        const decoders = decoderModels;
        const content = {
          name: 'MX4D Device Statistics',
          properties: {
            device_id: deviceIdAnonymised.substring(0, 16), // unique identifier per device to filter
            device_firmware: systemStatus.firmwareVersion, // the devices installed firmware version
            streams_total: streams.length, // number all configured streams
            streams_active: streams.filter((s) => s.state === StreamState.ACTIVE).length, // number of streaming streams
            decoders_active: decoders.filter((s) => s.stats?.state === DecoderStatsStateEnum.ACTIVE).length, // number of active audio encoders
            srt_streams: streams.filter((s) => s.encapsulation === StreamEncapsulation.SRT).length,
            udp_streams: streams.filter((s) => s.encapsulation === StreamEncapsulation.UDP).length,
            rtp_streams: streams.filter((s) => s.encapsulation === StreamEncapsulation.RTP).length,
            rtsp_streams: streams.filter((s) => s.encapsulation === StreamEncapsulation.RTSP).length,
            sqd_2si: decoders.find((d) => d.state === DecoderInfoStateEnum.STARTED)?.quadMode === QuadModeEnum.QUAD_2SI,
            temperature: systemStatus.temperature,
            uptime: systemStatus.uptime,
            video_decoder_count: systemInfo.videoDecoders,
          },
        };
        // console.log('FS event content', content);
        FS.event('trackEvent', content);
      });
    }
  }, [isFSEnabled, decodersFetched, streamsFetched]);
}

export function useSendFsAdminStats(
  isFSEnabled: boolean,
  licensesFetched: boolean,
  certificatesFetched: boolean,
  stillImagesCount?: number,
) {
  useEffect(() => {
    const isInit = FS.isInitialized();
    // console.log('useSendFsAdminStats check?', isFSEnabled, isInit, licensesFetched, certificatesFetched);
    if (isFSEnabled && isInit && (licensesFetched || certificatesFetched || stillImagesCount >= 0)) {
      createAnonymizedDeviceId()?.then((deviceIdAnonymised) => {
        // console.log('admin stats deviceIdAnonymised', deviceIdAnonymised);
        const content: any = {
          name: 'MX4D Admin Device Statistics',
          properties: {
            device_id: deviceIdAnonymised.substring(0, 18), // unique identifier per device to filter
          },
        };
        if (licensesFetched) {
          content.properties.licenses = licensesList?.list?.length;
        }
        if (certificatesFetched) {
          content.properties.identity_certificates = dataStoreIdentity?.list?.length;
          content.properties.identity_certificates_type_selfsigned = dataStoreIdentity?.list?.filter((c) =>
            c.type?.toLowerCase()?.includes('self'),
          )?.length;
          content.properties.identity_certificates_type_casigned = dataStoreIdentity?.list?.filter((c) =>
            c.type?.toLowerCase()?.includes('ca'),
          )?.length;
          content.properties.ca_certificates = dataStoreCa?.list?.length;
        }
        if (stillImagesCount >= 0) {
          content.properties.still_images = stillImagesCount;
        }
        // console.log('FS admin event content', content);
        FS.event('trackEvent', content);
      });
    }
  }, [isFSEnabled, licensesFetched, certificatesFetched, stillImagesCount]);
}
