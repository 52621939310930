import { TFunction } from 'i18next';
import * as yup from 'yup';

export enum PasswordQuality {
  Basic = 'Basic',
  Strong = 'Strong',
}

export enum Compliance {
  NONE = 'NONE',
  FIPS1402 = 'FIPS140',
  NDPPv11 = 'NDPP11',
  SP80052_REV1 = 'SP800-52R1', // Deprecated
  SP80052_REV2 = 'SP800-52R2',
}

export interface Policies {
  minLen: number;
  uppercase: number;
  digits: number;
  symbols: number;
  rememberedPasswords: number;
  expiration: boolean;
  expirationDays: number;
  minimumLifetime: number;
  hsts: boolean;
  autoLogout: boolean;
  autoLogoutTime: number;
  inactiveLock: boolean;
  inactiveLockTime: number;
  compliance: string;
  ssl3: boolean;
  tls1: boolean;
  tls1_1: boolean;
  tls1_2: boolean;
}

/*
 * Yup validation schema for the comment form
 */
export const policiesValidationSchema = (t: TFunction) =>
  yup.object<Policies>().shape({
    minLen: yup
      .number()
      .required(t('validation.required'))
      .typeError(t('validation.policies.minLen'))
      .min(6, t('validation.policies.minLen'))
      .max(40, t('validation.policies.minLen')),
    uppercase: yup.number().when('quality', {
      is: (value) => value == PasswordQuality.Strong,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.uppercase'))
        .min(0, t('validation.policies.uppercase'))
        .max(40, t('validation.policies.uppercase')),
    }),
    rememberedPasswords: yup.number().when('quality', {
      is: (value) => value == PasswordQuality.Strong,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.rememberedPasswords'))
        .min(5, t('validation.policies.rememberedPasswords'))
        .max(500, t('validation.policies.rememberedPasswords')),
    }),
    minimumLifetime: yup.mixed().when('quality', {
      is: (value) => value == PasswordQuality.Strong,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.minimumLifetime'))
        .min(0, t('validation.policies.minimumLifetime'))
        .max(7, t('validation.policies.minimumLifetime')),
    }),
    digits: yup.number().when('quality', {
      is: (value) => value == PasswordQuality.Strong,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.digits'))
        .min(0, t('validation.policies.digits'))
        .max(40, t('validation.policies.digits')),
    }),
    symbols: yup.number().when('quality', {
      is: (value) => value == PasswordQuality.Strong,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.symbols'))
        .min(0, t('validation.policies.symbols'))
        .max(40, t('validation.policies.symbols')),
    }),
    expirationDays: yup.number().when('expiration', {
      is: (value) => value == true,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.expirationDays'))
        .min(1, t('validation.policies.expirationDays'))
        .max(180, t('validation.policies.expirationDays')),
    }),
    autoLogoutTime: yup.number().when('autoLogout', {
      is: (value) => value == true,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.autoLogoutTime'))
        .min(1, t('validation.policies.autoLogoutTime'))
        .max(1440, t('validation.policies.autoLogoutTime')),
    }),
    inactiveLockTime: yup.number().when('inactiveLock', {
      is: (value) => value == true,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.inactiveLockTime'))
        .min(1, t('validation.policies.inactiveLockTime'))
        .max(365, t('validation.policies.inactiveLockTime')),
    }),
    limitPwdRetriesMaxAttempts: yup.number().when('limitPwdRetries', {
      is: (value) => value == true,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.limitPwdRetriesMaxAttempts'))
        .min(3, t('validation.policies.limitPwdRetriesMaxAttempts'))
        .max(10, t('validation.policies.limitPwdRetriesMaxAttempts')),
    }),
    limitPwdRetriesInterval: yup.number().when('limitPwdRetries', {
      is: (value) => value == true,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.policies.limitPwdRetriesInterval'))
        .min(5, t('validation.policies.limitPwdRetriesInterval'))
        .max(60, t('validation.policies.limitPwdRetriesInterval')),
    }),
  });
