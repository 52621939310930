import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StateButton } from 'src/js/component/base/state-button';
import { useErrorReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { createUnpairEmsTask } from 'src/js/task/settings-services-tasks';

interface Props {
  disabled?: boolean;
  onOperationStarted?: VoidFunction;
}
export const UnpairButton: React.FunctionComponent<Props> = ({ disabled, onOperationStarted, ...rest }) => {
  const { t } = useTranslation();
  const unpairEmsTask = useTask(createUnpairEmsTask());
  const [unpairingStarted, setUnpairingStarted] = useState<boolean>(false);

  const handlePairingCompleted = (): void => {
    setUnpairingStarted(false);
  };

  useEffect((): VoidFunction => {
    return handlePairingCompleted;
  }, []);

  useErrorReaction(unpairEmsTask, handlePairingCompleted);

  return (
    <StateButton
      onClick={(): void => {
        setUnpairingStarted(true);
        unpairEmsTask();
        onOperationStarted?.();
      }}
      isLoading={unpairingStarted}
      disabled={disabled}
      {...rest}
    >
      {t('settings.services.section.ems.button.unpair')}
    </StateButton>
  );
};
