import { DataTable } from '@hai/ui-react';
import { HaiSortDirection } from '@hai/ui-react/dist/types';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'src/js/hook/use-stores';
import { constant } from 'src/js/constant';
import { useLocalStorage } from 'usehooks-ts';
import { ListSelector } from '../util/listSelector';

import { presetName, sortDataTable } from './preset-helper';
import { PresetViewModel } from './preset-models';

interface Props {
  listController: ListSelector<PresetViewModel>;
  setSelected: (selected: boolean) => void;
}

export const PresetLoadTable: React.FunctionComponent<Props> = ({ listController, setSelected }) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const [fieldKey, setFieldKey] = useLocalStorage(
    `${constant.lStorage.presetLoadTable}:fieldKey:${sessionStore.username}`,
    'name',
  );
  const [sortFieldKey, setSortFieldKey] = useLocalStorage(
    `${constant.lStorage.presetLoadTable}:sortFieldKey:${sessionStore.username}`,
    'name',
  );
  const [direction, setDirection] = useLocalStorage<string>(
    `${constant.lStorage.presetLoadTable}:directionKey:${sessionStore.username}`,
    HaiSortDirection.ASC,
  );

  const onChangeSort = (fieldKey: string, order: string, sortFieldKey: string) => {
    setFieldKey(fieldKey);
    setDirection(order);
    setSortFieldKey(sortFieldKey);
  };

  const handleSelect = (name: string) => {
    // TODO simplify this logic
    // Enforce Single Selection
    if (listController.selectedRows().length > 0) {
      if (listController.isSelected(name)) {
        listController.toggleRow(name);
      } else {
        listController.unselectAll();
        listController.select(name);
      }
    } else {
      listController.select(name);
    }
    setSelected(listController.isSelected(name));
  };

  // Add title to presets that are too long
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.querySelectorAll('.preset-table .text-data').forEach((e: any) => {
        if (e.offsetWidth < e.scrollWidth) {
          e.setAttribute('title', e.textContent);
        }
      });
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="preset-table load scrollable">
      <DataTable
        selectable={true}
        sortable={true}
        allowSortNone={false}
        initialSort={{ fieldKey: fieldKey, order: direction, sortFieldKey: sortFieldKey }}
        onSort={onChangeSort}
        sortFunction={sortDataTable}
        columnStructure={[
          { fieldKey: 'name', useFsMask: true, title: t('general.name') },
          { fieldKey: 'modifiedTime', title: t('presets.modifiedTime'), sortFieldKey: 'mtime' },
        ]}
        className="datatable-modal"
        maxHeight="339px"
        singleSelect
        emptyStateText={t('presets.addFirst')}
      >
        <DataTable.Header />
        {listController.list?.map((value: PresetViewModel, index: number) => (
          <DataTable.Row
            key={`${value.name}`}
            rowData={{
              index: index,
              id: `${value.name}`,
              modifiedTime: moment.unix(Number(value.mtime)).format(constant.momentFormat.presetDateTime),
              name: presetName(value.name),
              mtime: value.mtime,
            }}
            checked={listController.isSelected(value.name)}
            onSelect={() => handleSelect(value.name)}
          ></DataTable.Row>
        ))}
      </DataTable>
    </div>
  );
};
