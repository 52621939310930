import { ButtonToggleGroup } from '@hai/ui-react';
import React, { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useAdaptiveFetch } from 'src/js/hook/use-adaptive-fetch';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { StreamSrtStatsResponse } from 'src/js/model/api/response/stream-srt-stats-response';
import { LineChart } from 'src/js/pages/statistic/plot/line-chart';
import { mapStreamSRTStatisticToPlotData } from 'src/js/pages/statistic/statistic-mapper';
import { StreamViewModel } from 'src/js/pages/streaming/stream/stream-models';
import { chartColors } from 'src/js/theme/chart';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { DecoderFrameGraphResponse, DecoderInfoStateEnum, DecoderViewModel } from '../streaming/decoder/decoder-model';
import { fetchDecoderFrameGraph } from '../streaming/decoder/decoder-store';

import { fetchStreamGenericGraph, fetchStreamSRTGraph } from '../streaming/stream/stream-store';
import { DashboardBarGraph } from './dashboard-bar-graph';
import { generateDataAuto } from 'src/js/util/automation';

export const GraphCtx = createContext({ fontSize: 0, width: 0, height: 0 });

interface ChartProps {
  graphData?: StreamSrtStatsResponse[];
  yTitle: string;
  fields: string[];
  defaultInactiveFields?: string[];
  lineColors?: string[];
}

const DashboardChart: React.FunctionComponent<ChartProps> = ({
  graphData,
  yTitle,
  fields,
  defaultInactiveFields,
  lineColors,
}) => {
  const { t } = useTranslation();
  const { fontSize, width, height } = useContext(GraphCtx);

  return (
    <LineChart
      yTitle={yTitle}
      yTickCount={2}
      fontSize={fontSize}
      data={graphData ? mapStreamSRTStatisticToPlotData(graphData, fields, t, false, 'csvValuesDashboard') : []}
      defaultInactiveFields={defaultInactiveFields}
      timescale={StatsInterval.LAST_5_MINS}
      xAxisMinutes={true}
      lineColors={lineColors}
      width={width}
      height={height}
      showLegend={true}
      yRange={[
        { limit: 9900, divideBy: 1000, label: 'Mbps' },
        { limit: 9900000, divideBy: 1000000, label: 'Gbps' },
      ]}
    />
  );
};

interface GraphProps {
  graphData: StreamSrtStatsResponse[];
}

const DashboardGraphBandwidth: React.FunctionComponent<GraphProps> = ({ graphData }) => {
  return (
    <DashboardChart
      yTitle="kbps"
      fields={constant.chart.stream.secondFieldsDashboard}
      defaultInactiveFields={constant.chart.stream.defaultFieldsRate}
      lineColors={chartColors.stream.secondDashboard}
      graphData={graphData}
    />
  );
};

const DashboardGraphDelay: React.FunctionComponent<GraphProps> = ({ graphData }) => {
  return (
    <DashboardChart
      yTitle="ms"
      fields={constant.chart.stream.firstFieldsDashboard}
      defaultInactiveFields={constant.chart.stream.defaultFieldsDelay}
      lineColors={chartColors.stream.firstDashboard}
      graphData={graphData}
    />
  );
};

const DashboardGraphGeneric: React.FunctionComponent<GraphProps> = ({ graphData }) => {
  return (
    <DashboardChart
      yTitle="kbps" // TODO
      fields={constant.chart.stream.genericFields}
      defaultInactiveFields={constant.emptyArray}
      lineColors={chartColors.stream.generic}
      graphData={graphData}
    />
  );
};

interface DecoderFrameProps {
  id: number | string;
  graphData: DecoderFrameGraphResponse[];
  t: TFunction;
}

const DashboardGraphDecoderFrames: React.FunctionComponent<DecoderFrameProps> = ({ id, graphData, t }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <span className="dashboard-graph-title">{t('dashboard.decoderFrameGraphTitle')}</span>
      </div>
      <DashboardBarGraph
        id={id}
        yTitle=""
        fields={constant.chart.decoder.frameBarGraphFields}
        inactiveFields={[]}
        graphData={graphData}
      />
    </>
  );
};

const DashboardGraphEmpty: React.FunctionComponent = () => {
  return <DashboardChart yTitle="kbps" fields={['']} />;
};

interface Props {
  stream: StreamViewModel;
  decoder?: DecoderViewModel;
}

enum GRAPH_TYPE {
  BANDWIDTH = 'BANDWIDTH', // srt
  DELAY = 'DELAY', // srt
  GENERIC = 'GENERIC', // non-srt
}

export const DecoderItemGraph: React.FunctionComponent<Props> = ({ stream, decoder }) => {
  const { t } = useTranslation();

  const fontSize = 10;
  const width = 400;
  const [height, setHeight] = useState(150);
  const [adj, setAdj] = useState(1.0);
  const ref = useRef<HTMLImageElement>();

  useLayoutEffect(() => {
    const onResizeLocal = (_ev: UIEvent) => {
      const parentWidth = ref.current.clientWidth;
      const newHeight = Math.round(parentWidth * (9 / 16));
      setHeight(Math.min(135, newHeight > 200 ? newHeight / 2 : newHeight - 10));
    };
    window.addEventListener('resize', onResizeLocal);
    window.dispatchEvent(new Event('resize'));
    return () => {
      window.removeEventListener('resize', onResizeLocal);
    };
  }, []);

  // Handle local width/height change
  useEffect(() => {
    const ratio = ref.current.clientWidth / width;
    const curFontSize = fontSize * ratio;
    if (curFontSize != fontSize) {
      const adjustment = curFontSize / fontSize;
      setAdj(adjustment);
    }
  }, [height]);

  const [graphType, setGraphType] = useState(GRAPH_TYPE.BANDWIDTH);
  const [graphData, setGraphData] = useState<StreamSrtStatsResponse[]>();
  const intervalSrt = stream?.stats?.srt ? constant.dashboardGraphRefreshRateMs : undefined;

  useAdaptiveFetch(
    () => {
      if (!stream) {
        return undefined;
      }
      if (graphType === GRAPH_TYPE.GENERIC) {
        setGraphType(GRAPH_TYPE.BANDWIDTH);
      }
      return fetchStreamSRTGraph(stream, StatsInterval.LAST_5_MINS, undefined, (updated) => {
        setGraphData(updated);
      });
    },
    intervalSrt,
    [intervalSrt, stream?.id],
  );
  const intervalGeneric = stream?.stats?.srt ? undefined : constant.dashboardGraphRefreshRateMs;
  useAdaptiveFetch(
    () => {
      if (!stream) {
        return undefined; // first fetches
      }
      if (graphType !== GRAPH_TYPE.GENERIC) {
        setGraphType(GRAPH_TYPE.GENERIC);
      }
      return fetchStreamGenericGraph(stream, StatsInterval.LAST_5_MINS, (updated) => {
        setGraphData(updated);
      });
    },
    intervalGeneric,
    [intervalGeneric, stream?.id],
  );

  const [frameGraphData, setFrameGraphData] = useState<DecoderFrameGraphResponse[]>();
  const intervalFrameGraph =
    decoder?.state === DecoderInfoStateEnum.STARTED ? constant.dashboardBarGraphRefreshRateMs : undefined;
  useAdaptiveFetch(
    () => {
      if (!decoder) {
        return undefined; // first fetches
      }
      return fetchDecoderFrameGraph(decoder, StatsInterval.LAST_5_MINS, (updated) => {
        setFrameGraphData(updated);
      });
    },
    constant.dashboardBarGraphRefreshRateMs, // always on even when stopped
    [intervalFrameGraph],
  );

  const options = [
    {
      active: graphType === GRAPH_TYPE.BANDWIDTH,
      label: t('statistics.chart.bandwidth'),
      onClick: () => setGraphType(GRAPH_TYPE.BANDWIDTH),
    },
    {
      active: graphType === GRAPH_TYPE.DELAY,
      label: t('statistics.chart.delay'),
      onClick: () => setGraphType(GRAPH_TYPE.DELAY),
    },
  ];
  // console.log(fontSize, width, height, adj);

  const displayGraphs = () => {
    return (
      <div className="hai-ml-n3" ref={ref}>
        {!isNilOrEmpty(graphData) ? (
          <>
            {graphType === GRAPH_TYPE.BANDWIDTH && <DashboardGraphBandwidth graphData={graphData} />}
            {graphType === GRAPH_TYPE.DELAY && <DashboardGraphDelay graphData={graphData} />}
            {graphType === GRAPH_TYPE.GENERIC && <DashboardGraphGeneric graphData={graphData} />}
          </>
        ) : (
          <DashboardGraphEmpty />
        )}
      </div>
    );
  };

  return (
    <GraphCtx.Provider value={{ fontSize: fontSize, width: width * adj, height: height }}>
      <div className="sunken-container hai-mt-5" data-auto={generateDataAuto('decoder', 'graph statistics')}>
        {!isNilOrEmpty(graphData) ? (
          <>
            {graphType !== GRAPH_TYPE.GENERIC && (
              <div className="d-flex justify-content-center mk-graph-toggle-bar">
                <ButtonToggleGroup options={options} />
              </div>
            )}
            {graphType === GRAPH_TYPE.GENERIC && (
              <div className="d-flex justify-content-center mk-graph-title">
                <span className="dashboard-graph-title">{t('dashboard.streamGenericGraphTitle')}</span>
              </div>
            )}
            {displayGraphs()}
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center mk-graph-title align-items-center" style={{ height: '24px' }}>
              <span className="dashboard-graph-title">{t('dashboard.noStatistics')}</span>
            </div>
            {displayGraphs()}
          </>
        )}
      </div>
      <div className="sunken-container hai-mt-5" data-auto={generateDataAuto('decoder', 'graph decoded frames')}>
        <DashboardGraphDecoderFrames t={t} id={decoder.id} graphData={frameGraphData} />
      </div>
    </GraphCtx.Provider>
  );
};
