import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UploadZone } from 'src/js/component/base/upload-zone';
import { constant } from 'src/js/constant';

import { uploadLicense } from './license-store';

interface Props {
  onCancel?: VoidFunction;
  onImport?: VoidFunction;
}

export const LicenseImportModal: React.FunctionComponent<Props> = ({ onCancel, onImport }) => {
  const { t } = useTranslation();

  const handleStartUpload = (file: File) => {
    uploadLicense(file, t).then((res) => {
      if (res.ok) {
        onImport?.();
      }
    });
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('settings.licensing.modal.import.title')}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="Settings"
      icon="none"
      content={
        <UploadZone
          accept={{ '': constant.licenseFileTypes }}
          onUpload={handleStartUpload}
          onCancel={onCancel}
          uploadBtnText={t('general.install')}
        />
      }
    />
  );
};
