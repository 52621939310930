import { Status } from 'src/js/component/base/form/formik-text-field-with-icon';
import { NtpStatus } from 'src/js/model/ntp-server-status';

/**
 * Returns the status for the text input according to the NTP status
 * @param ntpStatus
 */
export const getInputStatus = (ntpStatus: NtpStatus): Status | null => {
  switch (ntpStatus) {
    case NtpStatus.Error:
      return Status.Error;
    case NtpStatus.Warning:
      return Status.Warning;
    case NtpStatus.Valid:
      return Status.OK;
    default:
      return null;
  }
};
