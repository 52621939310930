import { colorValue } from '@hai/ui-react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

export interface LegendTextProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * the children will be displayed as options
   */
  children?: any;

  /**
   * Custom class to be applied to the component.
   */
  className?: string;

  /**
   * Event dispatched when one option is clicked.
   * It provides the new option state as parameter with the following interface: {label: string; value?: string; active?: boolean}
   */
  onOptionClicked?: (opt: LegendTextToggleOption) => void;

  /**
   * Array of optionItems for the group.
   * Each option as an object with the following interface: {label: string; value?: string; active?: boolean}
   */
  options?: LegendTextToggleOption[];
  title?: string;
}

export interface LegendTextToggleOption {
  [key: string]: any;
  active?: boolean;
  children?: JSX.Element;
  className?: string;
  color?: string;
  indicatorColor?: string;
  label: string;
  value?: string;
}

export const LegendText = (props: LegendTextProps) => {
  const { onOptionClicked = null, className = null, options, title, children, ...rest } = props;

  const statueFiltersToOpts = (): LegendTextToggleOption[] => {
    return children?.map((ch: any, idx: number) => ({
      active: ch.props.active,
      color: ch.props.color,
      children: ch,
      label: '',
      disabled: ch.props.disabled,
      value: `${ch.props.numberText as string}-${ch.props.status as string}-${idx}`,
    }));
  };

  const digestOptions = useCallback(
    (opts: LegendTextToggleOption[]): LegendTextToggleOption[] => {
      if (!opts || opts.length === 0) {
        return [];
      }

      const items = children ? statueFiltersToOpts() : options;
      const digestedOptions = items.map((option) => {
        if (option.value) {
          return option;
        } else {
          return {
            ...option,
            value: option.label,
          };
        }
      });

      // UNUSED const activatedOptions = digestedOptions.filter((opt) => opt.active);
      return digestedOptions;
    },
    [options, children],
  );

  const onOptionClick = (opt: LegendTextToggleOption) => {
    // if at least one option needs to be active and the selected option is the only one currently selected, we do nothing
    opt.active = !opt.active;

    const newOptions = optionItems.map((option) => {
      if (option.value === opt.value) {
        return opt;
      } else {
        return option;
      }
    });

    // if multi selection isn't allowed, we need to ensure that only one option is activated
    if (opt.active) {
      for (const option of newOptions) {
        if (option.value !== opt.value) {
          option.active = false;
        }
      }
    }

    setOptionItems(newOptions);

    if (onOptionClicked) {
      onOptionClicked(opt);
    }
  };

  const [optionItems, setOptionItems] = useState<LegendTextToggleOption[]>(
    digestOptions(children ? statueFiltersToOpts() : options),
  );

  useEffect(() => {
    setOptionItems(digestOptions(children ? statueFiltersToOpts() : options));
  }, [props, digestOptions]);

  return (
    <div className={classNames('mk-legend-text', className, optionItems?.length === 1 && 'single')} {...rest}>
      {optionItems.map((opt, idx) => {
        const { active, color, label, children, value, className, disabled, indicatorColor, ...rest } = opt;

        return (
          <div
            key={idx}
            className={classNames('button-toggle-group-item', className)}
            onClick={() => onOptionClick(opt)}
            {...rest}
          >
            {indicatorColor && (
              <div
                className="color-indicator"
                style={{ backgroundColor: active ? indicatorColor : colorValue('haiui-gray-05') }}
              />
            )}
            {label}
          </div>
        );
      })}
    </div>
  );
};
