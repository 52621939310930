import * as moment from 'moment-timezone';
import setupDuration from 'moment-duration-format';
import { initializeI18n } from 'src/js/i18n';
import { initializePersistence } from 'src/js/service/persistence';
import { persistedStores } from 'src/js/store';

const bootstrapPersistence = (): void => {
  initializePersistence(persistedStores, localStorage);
};

const bootstrapMoment = (): void => {
  setupDuration(moment);
};

export const bootstrap = (): void => {
  bootstrapPersistence();
  bootstrapMoment();
  initializeI18n();
};
