export const dashboard = {
  dashboard: {
    Start: 'Start',
    startDisabledTooltip: 'Apply or Cancel changes first',
    Stop: 'Stop',
    Configure: 'Configure',
    Stats: 'Stream',
    resources: {
      title: 'RESOURCES',
      cpu: 'CPU',
      ram: 'Memory',
    },
    decoderFrameGraphTitle: 'Decoded Frames',
    decoderSettings: 'Decoder Settings',
    detailedStatistics: 'Detailed Statistics',
    hideAudioChannels: 'Hide Audio Channels',
    manageStillImages: 'Manage Still Images...',
    noStatistics: 'No Statistics Available',
    streamGenericGraphTitle: 'Stream Bandwidth',
    resetStatistics: 'Reset Statistics',
    resetAllStatistics: 'Reset All Statistics',
    showAudioChannels: 'Show Audio Channels',
    resetAllModal: {
      title: 'Reset All Statistics?',
      body: 'This will reset the statistics for all decoders and all streams. This cannot be undone.',
    },
    title: 'Dashboard',
    sdiOutputs: 'SDI',
    decoding: {
      title: 'DECODING',
      inUse: 'In Use',
      decoder: 'Decoder',
      decoders: 'Decoders',
    },
    bandwidth: {
      title: 'NETWORK',
    },
    deviceStatus: {
      emsStatus: 'Haivision Services',
      firmware: 'Firmware',
      serialNumber: 'Serial Number',
      systemUptime: 'System Uptime',
      title: 'DEVICE STATUS',
    },
    statistics: {
      bitrate: 'BR',
      bitrateExplain: 'Bitrate (Send Rate)',
      dropped: 'DP',
      droppedExplain: 'Dropped Packets',
      latency: 'LAT',
      latencyExplain: 'Latency',
      rtt: 'RTT',
      rttExplain: 'Round-Trip Time',
    },
  },
};
