import { PanelGroup } from '@hai/ui-react';
import isEmpty from 'ramda/src/isEmpty';
import React, { useCallback, useState } from 'react';
import { useModalControls } from 'src/js/hook/use-modal-controls';

import { DeleteStillImageConfirmModal } from './delete-stillimage-confirm-modal';
import { OneStillImage } from './onestillimage';
import { StillImage } from './still-image-model';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  viewModels: StillImage[];
  shouldReloadData: () => void;
}

export const StillImagesList: React.FunctionComponent<Props> = ({ viewModels }) => {
  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const [selected, setSelected] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({} as any), []);

  const onDelete = () => {
    hideDelete();
    delete viewModels[viewModels.indexOf(selectedItem)];
    forceUpdate();
  };

  const onSelect = (model: StillImage, selected: boolean) => {
    setSelected(selected ? model.name : null);
  };

  return (
    <>
      <div className="hai-mt-6" data-auto={generateDataAuto('still_images', 'list')}>
        <PanelGroup className="grid-4x">
          {!isEmpty(viewModels) &&
            viewModels &&
            viewModels.map((value: StillImage, index: number) => (
              <OneStillImage
                key={`${value.name}-${index}`}
                id={value.name}
                model={value}
                onDelete={() => {
                  setSelectedItem(value);
                  showDelete();
                }}
                onSelect={onSelect}
                selected={value.name === selected}
              />
            ))}
        </PanelGroup>
      </div>

      {deleteShown && (
        <DeleteStillImageConfirmModal itemToDelete={selectedItem} onCancel={hideDelete} onApply={onDelete} />
      )}
    </>
  );
};
