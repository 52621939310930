import { Button, NotFound as HaiNotFound } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { defaultRoute } from 'src/js/route';

export const NotFound: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate(defaultRoute, { replace: true });
  };

  return (
    <HaiNotFound
      pageTitle={t('notFound.title')}
      subTitle={t('notFound.containerTitle')}
      notFoundMessage={t('notFound.containerSubtitle')}
      homeButton={
        <Button onClick={handleNavigateHome} variant="primary">
          {t('general.home')}
        </Button>
      }
    />
  );
};
