export const stream = {
  stream: {
    addStream: 'Add Stream',
    button: {
      addStream: 'ADD STREAM',
    },
    dropdown: {
      statistics: 'STATISTICS',
    },
    tableHeader: {
      streamName: 'Stream Name',
      connection: 'Connection',
      protocol: 'Protocol',
      status: 'Status',
    },
    enums: {
      authentication: {
        none: '(None)',
        auto: 'Auto',
        'AES-GCM': 'AES-GCM',
      },
      encapsulation: {
        2: 'TS over UDP',
        3: 'TS over RTP',
        34: 'TS over SRT',
        64: 'RTSP',
      },
      fecRtp: {
        0: 'None',
        2: 'Pro-MPEG',
      },
      networkInterface: {
        auto: 'Auto',
        eth0: 'eth0',
        eth1: 'eth1',
      },
      sourceType: {
        multicast: 'Multicast',
        unicast: 'Unicast',
      },
      srtMode: {
        0: 'Caller',
        1: 'Listener',
        2: 'Rendezvous',
      },
      srtRedundancyOptions: {
        none: 'None',
        optional: 'Optional',
        active: 'Active-Active',
        backup: 'Active-Backup',
      },
      srtRedundancyRoleOptions: {
        primary: 'Primary',
        secondary: 'Secondary',
      },
      srtRedundancyPath1Role: {
        primary: 'Primary',
        secondary: 'Secondary',
      },
      srtRedundancyPath1NetworkInterface: {
        auto: 'Auto',
        eth0: 'eth0',
        eth1: 'eth1',
      },
      srtRedundancyPath2NetworkInterface: {
        auto: 'Auto',
        eth0: 'eth0',
        eth1: 'eth1',
      },
      srtAccessControlPublishingIdType: {
        custom: 'Custom',
        standard: 'Standard Keys',
      },
      state: {
        0: 'UNKNOWN',
        1: 'STOPPED',
        2: 'LISTENING',
        3: 'ACTIVE',
        4: 'RESOLVING',
        5: 'CONNECTING',
        6: 'SCRAMBLED',
        7: 'SECURING',
        8: 'CONNECTED',
        '-1': 'INVALID',
        '-2': 'FAILED',
        '-3': 'UNLICENCED',
      },
    },
    srtAccessControlSwitch: 'SRT Access Control',
    fields: {
      address: 'Address',
      authentication: 'Authentication',
      encapsulation: 'Protocol',
      encryption: 'Encryption',
      encrypted: 'Encrypted',
      unencrypted: 'Unencrypted',
      fecRtp: 'FEC',
      latency: 'Latency (ms)',
      latencyExplain: '20 - 8000',
      networkInterface: 'Network Interface',
      pathRole: 'Path Role',
      port: 'Port',
      portExplain: '1 - 65535',
      portNumberRTPExplain: '2 - 65534',
      portNumberRTPErrorExplain: 'Even Port Number',
      passphrase: 'Passphrase',
      sourcePort: 'Source Port',
      sourcePortPlaceholder: 'Auto-Assign',
      sourceType: 'Type',
      srtMode: 'Mode',
      srtListenerSecondPort: 'Alternate Port',
      srtListenerSecondPortPlaceholder: 'Optional',
      udpStreamConversion: 'SRT to UDP Conversion',
      srtAccessPublishingID: 'Stream Publishing ID',
      srtAccessUserName: 'User Name',
      srtAccessResourceName: 'Resource Name',
      srtAccessControlPublishingIdType: 'Format',
      srtRedundancyMode: 'Path Redundancy',
      srtRedundancyPath1Role: 'Path Role',
      srtRedundancyPath2Address: 'Address',
      srtRedundancyPath1Name: 'Path Name',
      srtRedundancyPath2Name: 'Path Name',
      srtRedundancyPath1NameExplain: 'Optional Descriptive Label',
      srtRedundancyPath2NameExplain: 'Optional Descriptive Label',
      srtRedundancyPath1SourcePort: 'Source Port',
      srtRedundancyPath2SourcePort: 'Source Port',
      srtRedundancyPath1SourcePortPlaceholder: 'Auto-Assign',
      srtRedundancyPath2SourcePortPlaceholder: 'Auto-Assign',
      srtRedundancyPath1Port: 'Destination Port',
      srtRedundancyPath2Port: 'Destination Port',
      srtToUdpAddress: 'Address',
      srtToUdpPort: 'Destination Port',
      srtToUdpTtl: 'TTL',
      srtToUdpTtlExplain: '1 - 255',
      srtToUdpTos: 'ToS',
      srtToUdpTosExplain: '0x00 - 0xFF',
      // pids
      audioPids: 'Audio PID',
      audioPidsExplain: '16 - 8190',
      videoPids: 'Video PID',
      videoPidsExplain: '16 - 8190',
      dataPids: 'Metadata PID',
      dataPidsExplain: '16 - 8190',
      pcrPid: 'PCR PID',
      pcrPidExplain: '16 - 8190',
      pmtPid: 'PMT PID',
      pmtPidExplain: '16 - 8190',
      programNumber: 'Program Number',
      programNumberExplain: '1 - 65535',
    },
    row: {
      latency: 'SRT Latency:',
      skippedPackets: 'Skipped Packets:',
    },
    sections: {
      path1: 'Path 1',
      path2: 'Path 2',
      parameters: 'STREAMING PARAMETERS',
      tsSettings: 'TS Settings',
      srtAccessControl: 'SRT ACCESS CONTROL',
      srtSettings: 'SRT SETTINGS',
      srtPathRedundancy: 'PATH REDUNDANCY',
      streamConversion: 'STREAM CONVERSION',
    },
    pidAutoDetect: 'Auto-Detect',
    showTsFields: 'TS Settings',
    notifications: {
      addError: "Stream '{{name}}' could not be added",
      addErrorEmptyName: 'Stream could not be added',
      addSuccess: "Stream '{{name}}' created successfully",
      addSuccessEmptyName: 'Stream created successfully',
      createSuccess: "Stream '{{name}}' created successfully",
      createSuccessEmptyName: 'Stream created successfully',
      deleteError_plural: '{{count}} streams could not be deleted',
      deleteError: '{{count}} stream could not be deleted',
      latencyChangeSuccessMsg_plural: '{{count}} stream SRT latencies set to {{latency}}ms',
      latencyChangeSuccessMsg: '{{count}} stream SRT latency set to {{latency}}ms',
      resetStatsError: 'Stream statistics could not be reset',
      startError_plural: '{{count}} streams could not be started',
      startError: '{{count}} stream could not be started',
      stopError_plural: '{{count}} streams could not be stopped',
      stopError: '{{count}} stream could not be stopped',
      updateError: "Stream '{{name}}' updated successfully",
      updateErrorEmptyName: 'Stream could not be updated',
      updateSuccess: "Stream '{{name}}' updated successfully",
      updateSuccessEmptyName: 'Stream updated successfully',
      resetDecoderSuccess: "Decoder's statistics have been reset",
      resetStreamSuccess: "Stream's statistics have been reset",
      resetBothSuccess: 'Statistics have been reset',
      resetSelectedStreamsSuccess: "The selected stream's statistics have been reset",
      resetSelectedStreamsSuccess_plural: 'The selected streams statistics have been reset',
    },
    noContent: {
      title: 'No Streams Configured',
      subText: 'Add a stream to start your journey',
      btnText: 'ADD STREAM',
    },
    defaultListAddress: '(any)',
    defaultPort: '(none)',
    deleteModal: {
      title: 'Delete this stream?',
      title_plural: 'Delete {{count}} streams?',
    },
    RESETSTATS: 'Reset Stats',
    resetStatistics: 'Reset Statistics',
    setLatency: 'Set Latency',
    unnamedStream: '(None)',
  },
};
