import { DataTable, Label3Med } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DecoderStats, DecoderViewModel, multiSyncDelayRange, multiSyncWarning } from './decoder-model';

const delayRange = (stats: DecoderStats) => {
  const range = multiSyncDelayRange(stats);
  return `${range.min} - ${range.max}ms`;
};

export const combinedRange = (data: DecoderViewModel[]) => {
  let min = 0;
  let max = 10000;
  data.forEach((value: DecoderViewModel) => {
    min = Math.max(min, value.stats.multisyncDelayRangeMinMs ?? 0);
    max = Math.min(max, value.stats.multisyncDelayRangeMaxMs > 0 ? value.stats.multisyncDelayRangeMaxMs : 10000);
  });
  return `${min} - ${max}`;
};

interface Props {
  decoders: DecoderViewModel[];
}

export const DecoderMultiSyncTable: React.FunctionComponent<Props> = ({ decoders }) => {
  const { t } = useTranslation();
  return (
    <div className="hai-mt-4">
      <DataTable
        selectable={false}
        sortable={false}
        compact={true}
        columnStructure={[
          { fieldKey: 'name', title: t('general.name') },
          { fieldKey: 'warning', title: '' },
          {
            fieldKey: 'delay',
            title: t('decoder.fields.bufferingDelay'),
            type: HaiDataTableColumnType.NUMERIC,
          },
          {
            fieldKey: 'range',
            title: t('decoder.fields.validRange'),
            type: HaiDataTableColumnType.NUMERIC,
          },
        ]}
        className="datatable-modal decoder-mulitsync"
      >
        <DataTable.Header />
        {decoders?.map((value: DecoderViewModel, index: number) => (
          <DataTable.Row
            key={`${value.name}`}
            rowData={{
              index: index,
              id: `${value.name}`,
              name: value.name,
              warning: multiSyncWarning(value.stats, t, true),
              delay: `${value.multisyncBufferingDelay}ms`,
              range: delayRange(value.stats),
            }}
          ></DataTable.Row>
        ))}
      </DataTable>
      {decoders?.length > 1 && (
        <div className="buffering-mode">
          <Label3Med className="ml-auto hai-gray-08">
            {t('decoder.fields.combinedRange', { range: combinedRange(decoders) })}
          </Label3Med>
        </div>
      )}
    </div>
  );
};
