import React from 'react';
import { useTranslation } from 'react-i18next';
import systemInfo from 'src/js/data/systemInfo';
import { BandwidthGraph } from 'src/js/pages/dashboard/bandwidth-graph';

export const BandwidthTest: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="fullpage mk-bandwidth-graph-page">
      <div className="header">
        <h1>{t('statistics.chart.network', { deviceName: systemInfo.deviceName })}</h1>
      </div>
      <BandwidthGraph fullpage />
    </div>
  );
};
