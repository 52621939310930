export const typography = {
  light: {
    'font-family': 'HCo Gotham SSm Light',
    'font-size': '12px',
    'font-style': 'normal',
    'line-height': '12px',
  },
  bold: {
    1: {
      'font-family': 'HCo Gotham SSm Bold',
      'font-size': '10px',
      'font-style': 'normal',
      'font-weight': '700',
      'line-height': '12px',
    },
    2: {
      'font-family': 'HCo Gotham SSm Bold',
      'font-size': '12px',
      'font-style': 'normal',
      'font-weight': '700',
      'line-height': '14px',
    },
    3: {
      'font-family': 'HCo Gotham SSm Bold',
      'font-size': '14px',
      'font-style': 'normal',
      'font-weight': '700',
      'line-height': '16px',
    },
    4: {
      'font-family': 'HCo Gotham SSm Bold',
      'font-size': '16px',
      'font-style': 'normal',
      'font-weight': '700',
      'line-height': '18px',
    },
  },
  med: {
    1: {
      'font-family': 'HCo Gotham SSm Medium',
      'font-size': '10px',
      'font-style': 'normal',
      'font-weight': '500',
      'line-height': '12px',
    },
    2: {
      'font-family': 'HCo Gotham SSm Medium',
      'font-size': '12px',
      'font-style': 'normal',
      'font-weight': '500',
      'line-height': '14px',
    },
    3: {
      'font-family': 'HCo Gotham SSm Medium',
      'font-size': '14px',
      'font-style': 'normal',
      'font-weight': '500',
      'line-height': '16px',
      'letter-spacing': '-0.35px',
    },
    4: {
      'font-family': 'HCo Gotham SSm Medium',
      'font-size': '16px',
      'font-style': 'normal',
      'font-weight': '500',
      'line-height': '18px',
    },
  },
  book: {
    1: {
      'font-family': 'HCo Gotham SSm Book',
      'font-size': '10px',
      'font-style': 'normal',
      'line-height': '12px',
    },
    2: {
      'font-family': 'HCo Gotham SSm Book',
      'font-size': '12px',
      'font-style': 'normal',
      'line-height': '14px',
    },
    3: {
      'font-family': 'HCo Gotham SSm Book',
      'font-size': '14px',
      'font-style': 'normal',
      'line-height': '16px',
      'letter-spacing': '-0.35px',
    },
    4: {
      'font-family': 'HCo Gotham SSm Book',
      'font-size': '26px',
      'font-style': 'normal',
      'line-height': '30px',
    },
  },
};
