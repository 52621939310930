import { Button } from '@hai/ui-react';
import { observer } from 'mobx-react';
import React from 'react';
import { ButtonProps } from 'src/js/component/base/button';
import { RxTaskOrGroup } from 'src/js/helper/mobx-tasks';

export interface StateButtonProps<T> extends ButtonProps {
  disabled?: boolean;
  isSuccessful?: boolean;
  isLoading?: boolean;
  isInvalid?: boolean;
  task?: RxTaskOrGroup<T>; // if a task is set, it will be used to set the isLoading, isDisabled and isSuccessful props
}

export const StateButton: <T>(props: StateButtonProps<T>) => React.ReactElement<StateButtonProps<T>> = observer(
  ({ children, disabled, isInvalid, isLoading, isSuccessful, task, ...rest }) => {
    const loading = isLoading || (task && task.pending);
    const success = isSuccessful || (task && task.resolved && task.result !== undefined);
    const state = loading ? 'pending' : success ? 'success' : undefined;

    const isButtonDisabled = Boolean(disabled || isInvalid || loading || success);
    return (
      <Button disabled={isButtonDisabled} state={state} {...rest}>
        {children}
      </Button>
    );
  },
);
