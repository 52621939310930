import 'mobx-react-lite/batchingForReactDom';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from 'src/js/app';

createRoot(document.getElementById('root')).render(
  // FIXME R4KD-1556
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
