import { isNil } from 'ramda';
import { CardStatus } from 'src/js/model/card-status';
import { IconStatusType } from 'src/js/model/icon-status-type';
import { SystemStatus } from 'src/js/model/status';
import { DeviceStatusViewModel } from 'src/js/pages/settings/status/device-status-view-model';

export const mapDeviceStatusViewModel = (status: SystemStatus): DeviceStatusViewModel => {
  if (isNil(status)) {
    return {
      bootVersion: undefined,
      cardStatus: undefined,
      cardStatusIconStatusType: undefined,
      cardType: undefined,
      cpldRevision: undefined,
      firmwareDate: undefined,
      firmwareVersion: undefined,
      hardwareCompatibility: undefined,
      hardwareRevision: undefined,
      partNumber: undefined,
      serialNumber: undefined,
      temperature: undefined,
      uptime: undefined,
    };
  } else {
    return {
      bootVersion: status.bootVersion,
      cardStatus: status.cardStatus,
      cardStatusIconStatusType: status.cardStatus === CardStatus.OK ? IconStatusType.OK : IconStatusType.ERROR,
      cardType: status.cardType,
      cpldRevision: status.cpldRevision,
      firmwareDate: status.firmwareDate,
      firmwareVersion: status.firmwareVersion,
      hardwareCompatibility: status.hardwareCompatibility,
      hardwareRevision: status.hardwareRevision,
      partNumber: status.partNumber,
      serialNumber: status.serialNumber,
      temperature: status.temperature.toString(),
      uptime: status.uptime,
    };
  }
};
