import { TabItem, Tabs } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, matchPath, useNavigate } from 'react-router-dom';
import PageHeader from 'src/js/component/page-header';
import useLocalStorageWithState from 'src/js/component/useLocalStorageWithState';
import systemInfo from 'src/js/data/systemInfo';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useNavigateListener } from 'src/js/hook/use-history-listener';
import { useStores } from 'src/js/hook/use-stores';
import { Paths } from 'src/js/route';
import SidebarLayout from 'src/js/sidebar';
import { useWatchObject } from 'src/js/store/use-watch';
import { constant } from 'src/js/constant';

export interface SecurityParameters {
  page?: SecurityKey;
}

export enum SecurityKey {
  ACCOUNTS = 'accounts',
  MESSAGES = 'messages',
  BANNER = 'banner',
  POLICIES = 'policies',
  CERTIFICATES = 'certificates',
  AUDIT = 'audit',
}

const AdminTabs = Object.values(SecurityKey);
const NonAdminTabs = ['accounts'];

export const SecurityPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionStore } = useStores();
  const [selectedTab, setSelectedTab] = useLocalStorageWithState(
    `${constant.lStorage.securityTab}:${sessionStore.username}`,
    SecurityKey.ACCOUNTS,
  );

  const [tabs, setTabs] = useState<string[]>([]);
  const updateTabs = () => {
    setTabs(sessionStore.isAdmin() ? AdminTabs : NonAdminTabs);
  };

  useEffect(() => {
    updateTabs();
  }, []);
  useWatchObject(systemInfo, updateTabs);
  useNavigateListener((newLocation) => {
    const routeName = '/security/:page';
    const matchedParam = matchPath(routeName, newLocation.pathname);
    // Prevent people from directly accessing tabs that are currently unavailable (or from redirect after login)
    if (!isNil(matchedParam?.params) && !isNil(tabs.find((t) => t === matchedParam.params?.page))) {
      setSelectedTab(matchedParam.params.page as SecurityKey);
    }
  });

  return (
    <SidebarLayout>
      <PageHeader title={t('sidebar.security')} iconName="Security" />
      <Tabs
        selectedTab={selectedTab}
        onSelect={(event: ChangeEvent<{ eventkey: SecurityKey }>) => {
          setSelectedTab(event.target.eventkey);
          navigate(
            compileWithArgs<SecurityParameters>(Paths.security)({
              page: event.target.eventkey,
            }),
          );
        }}
      >
        {tabs.map(
          (tab): ReactNode => (
            <TabItem key={tab} caption={t(`security.${tab}.title`)} eventkey={tab} />
          ),
        )}
      </Tabs>
      <Outlet />
    </SidebarLayout>
  );
};
