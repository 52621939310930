import { Heading2Med, Heading4Book, Icon } from '@hai/ui-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import systemInfo from 'src/js/data/systemInfo';
import { handleLogout } from 'src/js/pages/login/login';
import { clearAllNotifications } from 'src/js/notification/notification-helper';
import { watchStoresEmptyCollections } from 'src/js/store/use-watch';
import { constant } from 'src/js/constant';

export const RebootingUpgrade: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const checkStatusApi = () => {
    apiClientWithoutHandler.rawController.get(APIPath.status.get).then((res: any) => {
      if (!res.ok && res.status === 401) {
        // reboot completed
        handleLogout(false, navigate);
      }
    });
  };

  // execute the status task at every 5 seconds
  // if there is an error, it means the device has not reboot yet. Nothing happens.
  // if it's successful, we should get an unauthorized response, so the user will be redirected to login.
  useEffect(() => {
    systemInfo.upgradePending = true;

    // clear local stores (streams, accounts, etc)
    watchStoresEmptyCollections();

    clearAllNotifications();

    const statusTaskIntervalId = setInterval(() => {
      checkStatusApi();
    }, 5000);
    return () => {
      clearInterval(statusTaskIntervalId);
    };
  }, []);

  return (
    <div className="rebooting">
      <Icon
        className={constant.isMissionCritical ? 'aqua-svg' : 'purple-svg'}
        iconname="StatusLoading"
        spinning
        size={'lg2'}
      />
      <Heading2Med className="title">{t('reboot.upgrade.header')}</Heading2Med>
      <Heading4Book>{t('reboot.upgrade.subheader')}</Heading4Book>
    </div>
  );
};
