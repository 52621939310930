import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';

interface Props {
  id?: string;
  className?: string;
  title?: string;
  width?: string;
  maxWidth?: string;
  children?: ReactNode;
}

export const Ellipsis = (props: Props) => {
  const { id, className, title, width, maxWidth, children } = props;

  // from https://codesandbox.io/s/react-getnodetext-h21ss
  const getNodeText: (node: any) => string = (node: any) => {
    if (['string', 'number'].includes(typeof node)) {
      return node;
    } else if (node instanceof Array) {
      return node.map(getNodeText).join('');
    } else if (typeof node === 'object' && node) {
      return getNodeText(node.props.children);
    }
  };

  const ref = useRef<HTMLDivElement>();
  const [render, setRender] = useState(0);
  let endrun = false;
  useEffect(() => {
    return () => {
      endrun = true;
    };
  }, []);
  const MAX_TRY_TO_GET_THE_SIZE = 10;
  const DELAY_BETWEEN_TRY = 10;
  const isEllipsNeeded = () => {
    const e = ref.current;
    if (!e) {
      return false;
    }
    return e.offsetWidth < e.scrollWidth;
  };
  useLayoutEffect(() => {
    if (isEllipsNeeded()) {
      ref.current.title = title ?? getNodeText(children);
    } else {
      ref.current.title = '';
    }
    const e = ref.current;
    if ((e?.scrollWidth === 0 && render < MAX_TRY_TO_GET_THE_SIZE) || render === 0) {
      // re-schedule
      setTimeout(() => {
        if (!endrun) {
          setRender(render + 1);
        }
      }, DELAY_BETWEEN_TRY);
    }
  }, [ref, render]);

  // Handle resize
  useEffect(() => {
    const onResizeLocal = (_ev: UIEvent) => {
      setRender(0); // trigger ellipsis calculations
    };
    window.addEventListener('resize', onResizeLocal);
    return () => {
      window.removeEventListener('resize', onResizeLocal);
    };
  }, [title, children]);

  return (
    <div
      id={id}
      ref={ref}
      className={className}
      style={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: '0',
        padding: '0 1px 0 0',
        width: width,
        maxWidth: maxWidth,
      }}
    >
      {children}
    </div>
  );
};
