import { Heading4Med, Icon } from '@hai/ui-react';
import React from 'react';
import PresetMenu from 'src/js/presets/preset-widget';

import { constant } from 'src/js/constant';
import { generateDataAuto } from '../util/automation';

interface PageHeaderProps {
  title: string;
  iconName?: string;
  withPresets?: boolean;
}

const PageHeader: React.FunctionComponent<PageHeaderProps> = ({ title, iconName, withPresets = true }) => {
  return (
    <>
      <div className="tab-header-container">
        <div className="tab-header-section-container">
          {iconName && (
            <Icon iconname={iconName} className={constant.isMissionCritical ? 'aqua-svg' : 'purple-svg'} size="md" />
          )}
          <Heading4Med data-auto={generateDataAuto('title', title)}>{title}</Heading4Med>
        </div>
        {withPresets && <PresetMenu />}
      </div>
    </>
  );
};

export default PageHeader;
