import { LoginResponse } from 'src/js/model/api/response/login-response';
import { SessionViewModel } from 'src/js/view-model/session-view-model';

export const mapSessionViewModel = (loginResponse: LoginResponse): SessionViewModel => {
  return {
    username: loginResponse.username,
    gid: loginResponse.gid,
    passwordExp: loginResponse.passwordExp,
  };
};
