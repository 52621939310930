import { TFunction } from 'i18next';
import { append } from 'ramda';
import systemInfo from 'src/js/data/systemInfo';
import { filterUndefinedRows } from 'src/js/pages/statistic/statistic-mapper';
import { StatisticSectionViewModel } from 'src/js/pages/statistic/statistic-view-model';
import { formatUptime } from 'src/js/pages/streaming/stream/stream-helper';
import {
  formatLastOccuredTime,
  formatPreprocessorState,
  isNilOrEmpty,
  toTitleCase,
  toTitleCaseDefaultToString,
} from 'src/js/util/global-util';
import { deriveAudioLanguage } from '../stream/stream-statistics-content';

import { DecoderViewModel, decoderBufferingModeToString, translateDecoderState } from './decoder-model';

export const mapDecoderToStatistics = (
  decoder: DecoderViewModel,
  fullPage: boolean,
  t: TFunction,
): StatisticSectionViewModel[] => {
  let sections: StatisticSectionViewModel[] = [
    {
      title: t('statistics.decoder.section.general'),
      rows: [
        {
          name: t('statistics.stream.rows.id'),
          value: decoder.id.toString(),
        },
        {
          name: t('general.status'),
          value: translateDecoderState(decoder.stats.state, decoder.stats.troubleCode, t),
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.uptime'),
          value: formatUptime(decoder.stats.uptime),
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.bufferingState'),
          value: toTitleCase(decoder.stats.bufferingState),
          shortlisted: true,
          when: !fullPage,
        },
        {
          name: t('statistics.decoder.rows.bufferingMode'),
          value: decoderBufferingModeToString(decoder.stats.bufferingMode, t),
          shortlisted: true,
          when: !fullPage,
        },
        {
          name: t('statistics.decoder.rows.videoLatency'),
          value: decoder.stats.videoLatency,
          shortlisted: true,
          when: !fullPage,
        },
        {
          name: t('statistics.decoder.rows.hdrTypeIn'),
          value: decoder.stats.hdrTypeIn,
          shortlisted: true,
          when: !fullPage && systemInfo.hasHDR,
        },
        {
          name: t('statistics.decoder.rows.hdrType'),
          value: decoder.stats.hdrType,
          shortlisted: true,
          when: !fullPage && systemInfo.hasHDR,
        },
        {
          name: t('statistics.decoder.rows.lastReset'),
          value: decoder.stats.lastReset,
          shortlisted: true,
        },
      ],
    },
    {
      title: t('statistics.decoder.section.video'),
      span: 3,
      rows: [
        {
          name: t('statistics.decoder.rows.videoAlgorithm'),
          value: toTitleCase(decoder.stats.videoAlgorithm),
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.videoProfile'),
          value: decoder.stats.videoProfile,
        },
        {
          name: t('statistics.decoder.rows.videoLevel'),
          value: decoder.stats.videoLevel,
        },
        {
          name: t('statistics.decoder.rows.videoFraming'),
          value: decoder.stats.videoFraming,
        },
        {
          name: t('statistics.decoder.rows.videoSlicesPerFrame'),
          value: decoder.stats.videoSlicesPerFrame,
        },
        {
          name: t('statistics.decoder.rows.preprocessorState'),
          value: formatPreprocessorState(decoder.stats.preprocessorState),
        },
        {
          name: t('statistics.decoder.rows.unlicensedReason'),
          value: decoder.stats.unlicensedReason,
        },
        {
          name: t('statistics.decoder.rows.unsupportedReason'),
          value: decoder.stats.unsupportedReason,
        },
        {
          name: t('statistics.decoder.rows.vframerNumInputPackets'),
          value: decoder.stats.vframerNumInputPackets?.toString(),
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.videoDecoderState'),
          value: toTitleCaseDefaultToString(decoder.stats.videoDecoderState),
        },
        {
          name: t('statistics.decoder.rows.videoInputResolution'),
          value: decoder.stats.videoInputResolution,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.videoDisplayFormat'),
          value: decoder.stats.videoDisplayFormat,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.loadPercentage'),
          value: `${decoder.stats.loadPercentage}%`,
        },
        {
          name: t('statistics.decoder.rows.stillImage'),
          value: decoder.stats.stillImage,
        },
        {
          name: t('statistics.decoder.rows.corruptedFrames'),
          value: decoder.stats.corruptedFrames,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.corruptedFramesDropped'),
          value: decoder.stats.corruptedFramesDropped,
        },
        {
          name: t('statistics.decoder.rows.displayedOutputFrames'),
          value: decoder.stats.displayedOutputFrames,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.skippedOutputFrames'),
          value: decoder.stats.skippedOutputFrames,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.replayedOutputFrames'),
          value: decoder.stats.replayedOutputFrames,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.videoOutputJitterMs'),
          value: decoder.stats.videoOutputJitterMs + ' ms',
          when: decoder.stats.videoOutputJitterMs !== undefined,
        },
        {
          name: t('statistics.decoder.rows.videoStcLeadTime'),
          value: [decoder.stats.videoStcLeadTime, decoder.stats.videoStcLeadTimeDetails],
          formatString: true,
        },
      ],
    },
    {
      title: t('statistics.decoder.section.buffering'),
      rows: [
        {
          name: t('statistics.decoder.rows.bufferingState'),
          value: toTitleCase(decoder.stats.bufferingState),
        },
        {
          name: t('statistics.decoder.rows.bufferingMode'),
          value: toTitleCase(decoder.stats.bufferingMode),
        },
        {
          name: t('statistics.decoder.rows.bufferingAdjustments'),
          value: decoder.stats.bufferingAdjustments,
        },
        {
          name: t('statistics.decoder.rows.videoLatency'),
          value: decoder.stats.videoLatency,
        },
        {
          name: t('statistics.decoder.rows.stcToPcrLeadTime'),
          value: decoder.stats.stcToPcrLeadTime,
        },
      ],
    },
  ];

  if (decoder.stats.bufferingMode === 'MULTISYNC') {
    sections = append(
      {
        title: t('statistics.decoder.section.multisync'),
        rows: [
          {
            name: t('statistics.decoder.rows.multisyncStatus'),
            value: toTitleCase(decoder.stats.multisyncStatus),
          },
          {
            name: t('statistics.decoder.rows.multisyncDelayActual'),
            value: decoder.stats.multisyncDelayActual,
          },
          {
            name: t('statistics.decoder.rows.multisyncDelayRange'),
            value: decoder.stats.multisyncDelayRange,
          },
          {
            name: t('statistics.decoder.rows.multisyncDelaySet'),
            value: decoder.stats.multisyncDelaySet,
          },
          {
            name: t('statistics.decoder.rows.multisyncSystemTime'),
            value: decoder.stats.multisyncSystemTime,
          },
          {
            name: t('statistics.decoder.rows.multisyncTimecode'),
            value: decoder.stats.multisyncTimecode,
          },
          {
            name: t('statistics.decoder.rows.multisyncTimeDiff'),
            value: decoder.stats.multisyncTimeDiff,
          },
          {
            name: t('statistics.decoder.rows.multisyncTimecodePackets'),
            value: decoder.stats.multisyncTimecodePackets,
          },
          {
            name: t('statistics.decoder.rows.multisyncTransmissionTime'),
            value: decoder.stats.multisyncTransmissionTime,
          },
        ],
      },
      sections,
    );
  }

  sections = append(
    {
      title: t('statistics.decoder.section.clockRecovery'),
      rows: [
        {
          name: t('statistics.decoder.rows.clockTrackingMode'),
          value: toTitleCase(decoder.stats.clockTrackingMode),
          when: decoder.stats.clockTrackingMode === 'ENABLE',
        },
        {
          name: t('statistics.decoder.rows.clockStatus'),
          value: toTitleCase(decoder.stats.clockStatus),
          when: decoder.stats.clockTrackingMode === 'ENABLE',
        },
        {
          name: t('statistics.decoder.rows.clockReSyncCount'),
          value: decoder.stats.clockReSyncCount?.toString(),
          when: decoder.stats.clockTrackingMode === 'ENABLE',
        },
        {
          name: t('statistics.decoder.rows.clockCurrentStc'),
          value: decoder.stats.clockCurrentStc,
          when: decoder.stats.clockTrackingMode === 'ENABLE',
        },
        {
          name: t('statistics.decoder.rows.clockStcAvg'),
          value: decoder.stats.clockStcAvg,
          when: decoder.stats.clockTrackingMode === 'ENABLE',
        },
      ],
    },
    sections,
  );

  if (decoder.stats.klv) {
    sections = append(
      {
        title:
          t('statistics.decoder.section.klv') +
          (decoder.stats.klvUnlicensed ? ' (' + t('general.unlicensed') + ')' : ''),
        rows: [
          {
            name: t('statistics.decoder.rows.receivedPackets'),
            value: decoder.stats.klvReceivedPackets,
          },
          {
            name: t('statistics.decoder.rows.outputPackets'),
            value: decoder.stats.klvOutputPackets,
          },
        ],
      },
      sections,
    );
  }

  if (decoder.stats.closedCaption) {
    sections = append(
      {
        title: t('statistics.decoder.section.cc'),
        rows: [
          {
            name: t('statistics.decoder.rows.receivedPackets'),
            value: decoder.stats.ccReceivedPackets,
          },
          {
            name: t('statistics.decoder.rows.outputPackets'),
            value: decoder.stats.ccOutputPackets,
          },
        ],
      },
      sections,
    );
  }

  if (decoder.stats.timeCode) {
    sections = append(
      {
        title: t('statistics.decoder.section.tc'),
        rows: [
          {
            name: t('statistics.decoder.rows.receivedPackets'),
            value: decoder.stats.tcReceivedPackets,
          },
          {
            name: t('statistics.decoder.rows.outputPackets'),
            value: decoder.stats.tcOutputPackets,
          },
          {
            name: t('statistics.decoder.rows.tcTimecodeValue'),
            value: decoder.stats.tcTimecodeValue,
          },
        ],
      },
      sections,
    );
  }

  if (decoder.stats.afd) {
    sections = append(
      {
        title: t('statistics.decoder.section.afd'),
        rows: [
          {
            name: t('statistics.decoder.rows.receivedPackets'),
            value: decoder.stats.afdReceivedPackets,
          },
          {
            name: t('statistics.decoder.rows.outputPackets'),
            value: decoder.stats.afdOutputPackets,
          },
        ],
      },
      sections,
    );
  }

  if (systemInfo.hasHDR) {
    sections = append(
      {
        title: t('statistics.decoder.section.hdr'),
        rows: [
          {
            name: t('statistics.decoder.rows.hdrTypeIn'),
            value: decoder.stats.hdrTypeIn,
          },
          {
            name: t('statistics.decoder.rows.hdrType'),
            value: decoder.stats.hdrType,
          },
          {
            name: t('statistics.decoder.rows.hdrColourPrimaries'),
            value: decoder.stats.hdrColourPrimaries,
          },
          {
            name: t('statistics.decoder.rows.hdrTransferCharacteristics'),
            value: decoder.stats.hdrTransferCharacteristics,
          },
          {
            name: t('statistics.decoder.rows.hdrMatrixCoefficients'),
            value: decoder.stats.hdrMatrixCoefficients,
          },
        ],
      },
      sections,
    );
  }

  sections = append(
    {
      title: t('statistics.decoder.section.audio'),
      sectionBreak: true,
      sectionBreakTitle: t('statistics.decoder.audioTitle'),
      rows: [
        {
          name: t('statistics.decoder.rows.audioState'),
          value: toTitleCaseDefaultToString(decoder.stats.audioState),
        },
        {
          name: t('statistics.decoder.rows.audioPairsAmount'),
          value: decoder.stats.audioPairsAmount?.toString(),
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.downmixSurround'),
          value: decoder.stats.downmixSurround ? t('general.yes') : t('general.no'),
          when: decoder.stats.downmixSurround !== undefined,
        },
        {
          name: t('statistics.decoder.rows.audioDecodedFrames'),
          value: decoder.stats.audioDecodedFrames,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.audioPlayedFrames'),
          value: decoder.stats.audioPlayedFrames,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.audioSkippedFrames'),
          value: decoder.stats.audioSkippedFrames,
          shortlisted: true,
        },
        {
          name: t('statistics.decoder.rows.audioMode'),
          value: decoder.stats.audioPairs?.[0].mode,
          shortlisted: true,
          when: !fullPage && decoder.stats.audioPairsAmount > 0 && !isNilOrEmpty(decoder.stats.audioPairs),
        },
        {
          name: t('statistics.decoder.rows.audioCompression'),
          value: decoder.stats.audioPairs?.[0].compression,
          shortlisted: true,
          when: !fullPage && decoder.stats.audioPairsAmount > 0 && !isNilOrEmpty(decoder.stats.audioPairs),
        },
        {
          name: t('statistics.decoder.rows.audioBitrate'),
          value: decoder.stats.audioPairs?.[0].bitrate != '0' ? decoder.stats.audioPairs?.[0].bitrate : '0 kbps',
          shortlisted: true,
          when: !fullPage && decoder.stats.audioPairsAmount > 0 && !isNilOrEmpty(decoder.stats.audioPairs),
        },
      ],
    },
    sections,
  );

  if (decoder.stats.audioPairsAmount > 0 && !isNilOrEmpty(decoder.stats.audioPairs)) {
    let audioPairCounter = 0;
    for (const audioPair of decoder.stats.audioPairs) {
      audioPairCounter++;
      sections = append(
        {
          title: t('statistics.decoder.section.audioPair', { id: audioPairCounter }),
          span: 2,
          rows: [
            {
              name: t('statistics.decoder.rows.audioCompression'),
              value: audioPair.compression,
            },
            {
              name: t('statistics.decoder.rows.audioBitrate'),
              value: audioPair.bitrate != '0' ? audioPair.bitrate : '0 kbps',
            },
            {
              name: t('statistics.decoder.rows.language'),
              value: deriveAudioLanguage(audioPair.language) ?? t('general.none'),
            },
            {
              name: t('statistics.decoder.rows.surroundPair'),
              value: audioPair.surroundPair,
              when: decoder.stats.downmixSurround === false,
            },
            {
              name: t('statistics.decoder.rows.audioInputLayout'),
              value: [audioPair.inputLayout, audioPair.inputLayoutDetailed], // FIXME display Surround Sound
              formatString: true,
            },
            {
              name: t('statistics.decoder.rows.audioOutputLayout'),
              value: [audioPair.outputLayout, audioPair.outputLayoutDetailed],
              formatString: true,
            },
            {
              name: t('statistics.decoder.rows.sampleRateIn'),
              value: audioPair.sampleRateIn != 0 ? audioPair.sampleRateIn?.toString() + ' kHz' : 'N/A',
            },
            {
              name: t('statistics.decoder.rows.sampleRateOut'),
              value: audioPair.sampleRateOut != 0 ? audioPair.sampleRateOut?.toString() + ' kHz' : 'N/A',
            },
            {
              name: t('statistics.decoder.rows.dbLevelLeft'),
              value: [
                `${audioPair.dbLeft}dB`,
                `max=${audioPair.dbLeftMax}dB, ${formatLastOccuredTime(audioPair.dbLeftMaxLastTimeSec, t)}`,
              ],
              when: audioPair.dbLeft !== undefined,
              formatString: true,
            },
            {
              name: t('statistics.decoder.rows.dbLevelRight'),
              value: [
                `${audioPair.dbRight}dB`,
                `max=${audioPair.dbRightMax}dB, ${formatLastOccuredTime(audioPair.dbRightMaxLastTimeSec, t)}`,
              ],
              when: audioPair.dbRight !== undefined,
              formatString: true,
            },
            {
              name: t('statistics.decoder.rows.avSyncMs'),
              value: audioPair.avSyncMs != 0 ? audioPair.avSyncMs?.toString() + ' ms' : '0 ms',
            },
            {
              name: t('statistics.decoder.rows.audioStcLeadTime'),
              value: [audioPair.stcLeadTime, audioPair.stcLeadTimeDetails],
              formatString: true,
            },
            {
              name: t('statistics.decoder.rows.discontinuities'),
              value: audioPair.discontinuities?.toString(),
            },
            {
              name: t('statistics.decoder.rows.decodeErrors'),
              value: audioPair.decodeErrors?.toString(),
            },
            {
              name: t('statistics.decoder.rows.outputErrors'),
              value: audioPair.outputErrors?.toString(),
            },
          ],
        },
        sections,
      );
    }
  }

  // remove undefined rows
  return filterUndefinedRows(sections);
};
