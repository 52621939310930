import { omit } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useErrorReaction, useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { SettingsTabContent } from 'src/js/pages/settings/settings-tab-content';
import { IsSavedRef, RebootIsSaved } from 'src/js/reboot/reboot-is-saved';
import { RebootNeeded, RebootNeededRef } from 'src/js/reboot/reboot-needed';
import { createGetNetworkTask, createUpdateNetworkTask } from 'src/js/task/settings-network-tasks';
import { constant } from 'src/js/constant';

import { NetworkForm } from './form/network-form';
import { mapNetworkToNetworkViewModel } from './network-mapper';
import { NetworkViewModel, UpdateNetworkViewModel } from './network-view-model';
import { createUpdateNetworkRequest } from './update-network-request-factory';

interface NetworkProps {
  mockData?: NetworkViewModel;
}

export const Network: React.FunctionComponent<NetworkProps> = ({ mockData }) => {
  const { t } = useTranslation();
  const getNetworkTask = useTask(createGetNetworkTask());
  const updateNetworkTask = useTask(createUpdateNetworkTask());

  const [data, setData] = useState<NetworkViewModel>(mockData ?? mapNetworkToNetworkViewModel(undefined));
  const [gotData, setGotData] = useState<boolean>(mockData ? true : false);

  const rebootNeededRef = useRef<RebootNeededRef>(null);

  // the reboot handling for the reboot button
  const childRef = useRef<IsSavedRef>(null);
  const onRebootClicked = (): void => {
    childRef.current.show();
  };

  const handleApply = (viewModel: NetworkViewModel): void => {
    updateNetworkTask(createUpdateNetworkRequest(viewModel));
  };

  // Load the data on mount
  useEffect(() => {
    getNetworkTask();
  }, []);

  useSuccessReaction<UpdateNetworkViewModel>(
    updateNetworkTask,
    (result) => {
      updateNetworkTask.reset();
      setData(omit(['saved'], result));
      rebootNeededRef.current.show();
    },
    { delay: constant.stateButtonWaitTime },
  );

  useErrorReaction(
    updateNetworkTask,
    (_result) => {
      updateNetworkTask.reset();
    },
    { delay: constant.stateButtonWaitTime },
  );

  useSuccessReaction<NetworkViewModel>(getNetworkTask, (result) => {
    setData(result);
    setGotData(true);
  });

  return (
    <SettingsTabContent id="network-form" name={t('settings.network.title')}>
      {gotData && (
        <NetworkForm
          viewModel={data}
          onReboot={onRebootClicked}
          onSubmit={handleApply}
          onSubmitTask={updateNetworkTask}
        />
      )}

      <RebootIsSaved ref={childRef} />
      <RebootNeeded ref={rebootNeededRef} />
    </SettingsTabContent>
  );
};
