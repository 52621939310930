import { TFunction } from 'i18next';
import { ValidateNtpServerResponse } from 'src/js/model/api/response/validate-ntp-server-response';
import { NtpServerStatus, NtpStatus } from 'src/js/model/ntp-server-status';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { isNilOrEmpty } from 'src/js/util/global-util';

export const handleValidateNtpServerResponse = (response: ValidateNtpServerResponse, t: TFunction): NtpServerStatus => {
  if (response.error || !response.apply) {
    if (!isNilOrEmpty(response.shortmsg)) {
      createAndDispatchNotification(response.shortmsg, NotificationVariant.ERROR, t);
    }
    return { status: NtpStatus.Error, message: response.shortmsg };
  }
  if (!isNilOrEmpty(response.warning)) {
    createAndDispatchNotification(response.warning, NotificationVariant.WARNING, t);
    return { status: NtpStatus.Warning, message: response.shortmsg };
  }
  return { status: NtpStatus.Valid };
};
