import { Button, Icon } from '@hai/ui-react';
import React from 'react';
import { constant } from 'src/js/constant';
import { generateDataAuto } from '../util/automation';

interface NoContentAvailableProps {
  title: string;
  subText?: string;
  btnText?: string;
  onClick?: VoidFunction;
  iconName?: string;
  className?: string;
  btnDisabled?: boolean;
  maxWidth?: string;
}

export const NoContentAvailable: React.FunctionComponent<NoContentAvailableProps> = ({
  title,
  subText,
  btnText,
  onClick,
  iconName,
  className = 'h-100-header',
  btnDisabled = false,
  maxWidth = '430px',
}) => {
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center ${className}`}
      data-auto={generateDataAuto('container', 'no content')}
    >
      {iconName && (
        <Icon
          className={constant.isMissionCritical ? 'aqua-svg' : 'purple-svg'}
          iconname={iconName}
          style={{ width: 'auto', height: '55' }}
        />
      )}
      <div className="haiui-heading-02-med hai-mt-7 text-center" data-auto={generateDataAuto('title', 'no content')}>
        {title}
      </div>
      {subText && (
        <div
          className="haiui-heading-04-book hai-gray-07 hai-mt-4 text-center"
          data-auto={generateDataAuto('subtext', 'no content')}
          style={{ maxWidth: maxWidth }}
        >
          {subText}
        </div>
      )}
      {btnText && (
        <Button className="hai-mt-7" id="noContentBtn" disabled={btnDisabled} variant="primary" onClick={onClick}>
          {btnText}
        </Button>
      )}
    </div>
  );
};
