import { Sidebar as HaiSidebar, Icon } from '@hai/ui-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import MakitoLogoX4 from 'src/images/makito-x4-logo.svg';
import bgMK from 'src/images/sidebar-bg.jpg';
import SmallLogoX4 from 'src/images/small-logo-x4.svg';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import systemInfo, { systemInfoCapabilities } from 'src/js/data/systemInfo';
import { translateRoutes } from 'src/js/helper/sidebar-route-helper';
import { useFullStory, useSendFsAdminStats, useSendFsStats } from 'src/js/hook/use-full-story';
import { useIsSaved } from 'src/js/hook/use-is-saved';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useStores } from 'src/js/hook/use-stores';
import { useTask } from 'src/js/hook/use-task';
import { SidebarRoute } from 'src/js/model/sidebar-route';
import { handleLogout } from 'src/js/pages/login/login';
import { setBrightnessContrast } from 'src/js/pages/settings/status/user-settings';
import { Paths } from 'src/js/route';
import { log } from 'src/js/service/logger';
import { useWatch, useWatchObject } from 'src/js/store/use-watch';
import { createLogoutTask } from 'src/js/task/session-task';
import { constant } from 'src/js/constant';
import { openNewWindow } from 'src/js/util/global-util';
import { getInfoCenterURL, infoCenterHelpExists, localHelpExists } from 'src/js/util/online-status-util';
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts';
import { generateDataAuto } from '../util/automation';
import decoderModels from '../pages/streaming/decoder/decoder-store';
import streamModels from '../pages/streaming/stream/stream-store';
import { watchEvent } from '../events';

// Online help logic
let onlineHelp = false;
let localHelp = true; // by default local help needs to be enabled so that if internet is not reachable we dont have the link doing noth9ing for the first 30 seconds while waiting for the online help reply that will never come

const sidebarData: (currentPath: string, helpOnClick: VoidFunction) => SidebarRoute[] = (
  currentPath = '',
  helpOnClick,
) => {
  return [
    {
      key: 'dashboard',
      iconName: 'Dashboard',
      title: 'sidebar.dashboard',
      to: '/',
      defaultPath: currentPath,
    },
    {
      key: 'streams',
      iconName: 'Streams',
      title: 'sidebar.streams',
      to: '/streaming',
    },
    {
      key: 'settings',
      iconName: 'Settings',
      title: 'sidebar.settings',
      to: '/settings',
    },
    {
      key: 'security',
      iconName: 'Security',
      title: 'sidebar.security',
      to: '/security',
    },
    {
      key: 'helpCenter',
      iconName: 'HelpCenter',
      title: 'sidebar.help',
      to: '/help',
      onClick: helpOnClick,
    },
  ];
};

export const Sidebar: React.FunctionComponent = () => {
  const logoutTask = useTask(createLogoutTask());
  const { sessionStore } = useStores();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [collapsed, setCollapsed] = useLocalStorage(
    `${constant.lStorage.sidebarCollapsed}:${sessionStore.username}`,
    false,
  );

  const brightness = useReadLocalStorage<string>(`${constant.lStorage.brightness}:${sessionStore.username}`) ?? '100';
  const contrast = useReadLocalStorage<string>(`${constant.lStorage.contrast}:${sessionStore.username}`) ?? '100';

  useEffect(() => {
    // doc.haivision.com check routine
    infoCenterHelpExists((err) => {
      if (err) {
        localHelpExists((err) => {
          if (!err) {
            localHelp = true;
          }
        });
      } else {
        onlineHelp = true;
        localHelp = false;
      }
    });

    const body = document.getElementsByTagName('body');
    if (body && typeof body[0].classList !== undefined) {
      if (collapsed) {
        body[0].classList.add('sidebar-collapsed');
      } else {
        body[0].classList.remove('sidebar-collapsed');
      }
    }

    setBrightnessContrast(brightness, contrast);
  }, []);

  useSuccessReaction(logoutTask, () => {
    handleLogout(false, navigate);
  });

  // signout begins
  const onSignoutProceed = () => {
    logoutTask();
  };

  const onSignoutCancel = () => {
    log.trace('cancelled signout');
  };

  const { check, MyModal } = useIsSaved(onSignoutProceed, onSignoutCancel);
  const signout = () => {
    if (sessionStore.isUser()) {
      logoutTask(); // we dont have permission on presets or isSaved request. No need to check.
      return;
    }
    // check if unsaved preset first
    check();
  };
  // signout ends

  const handleHelpClick = () => {
    if (onlineHelp) {
      apiClientWithoutHandler.genericController.get(APIPath.status.get).then((res: any) => {
        if (res.ok && res.data.firmwareVersion) {
          const version = res.data.firmwareVersion.replace(/(.*)-.*/, '$1');
          openNewWindow(getInfoCenterURL('MakitoX4Dec', version, sessionStore.isAdmin()));
        } else {
          openNewWindow('/help/makitox/index.html', 'mk-help');
        }
      });
    } else if (localHelp) {
      openNewWindow('/help/makitox/index.html', 'mk-help');
    }
  };

  const handleSelect = (path?: string) => {
    if (path !== Paths.help) {
      navigate(path);
    }
  };

  const handleClickUserName = () => {
    navigate('/security/accounts', { state: { id: sessionStore.username } });
  };

  const toggleCollapse = () => {
    const body = document.getElementsByTagName('body');
    if (body && typeof body[0].classList !== undefined) {
      if (collapsed) {
        body[0].classList.remove('sidebar-collapsed');
      } else {
        body[0].classList.add('sidebar-collapsed');
      }
    }
    setCollapsed(!collapsed);
  };

  let defaultActive = location.pathname;
  if (/^\/settings\//.exec(defaultActive)) {
    defaultActive = '/settings';
  } else if (/^\/security\//.exec(defaultActive)) {
    defaultActive = '/security';
  } else if (/^\/dashboard/.exec(defaultActive)) {
    defaultActive = '/';
  } else if (/^\/streaming/.exec(defaultActive)) {
    defaultActive = '/streaming';
  }

  const [systemInfoFetched, setSystemInfoFetched] = useState(systemInfo.version !== undefined ?? false);
  const [analytics, setAnalytics] = useState(systemInfoCapabilities.hasAnalytics());
  useWatchObject(systemInfo, () => setSystemInfoFetched(true));
  useFullStory(analytics, systemInfoFetched);
  useWatch(systemInfo, 'analytics', () => setAnalytics(systemInfoCapabilities.hasAnalytics()));

  // FS analytics for decoders and streams
  const [decFetched, setDecFetched] = useState(false);
  useWatchObject(decoderModels, () => setDecFetched(true));
  const [streamsFetched, setStreamsFetched] = useState(false);
  useWatchObject(streamModels, () => setStreamsFetched(true));
  useSendFsStats(analytics, decFetched, streamsFetched);

  // FS analytics for admin stuff
  const [licensesFetched, setLiensesFetched] = useState(false);
  watchEvent('licenses-fetched', () => {
    setLiensesFetched(true);
  });
  const [certificatesFetched, setCertificatesFetched] = useState(false);
  watchEvent('certificates-fetched', () => {
    setCertificatesFetched(true);
  });

  const [stillImagesCount, setStillImageCount] = useState();
  watchEvent('stillimages-fetched', (data: any) => {
    setStillImageCount(data.count);
  });
  useSendFsAdminStats(analytics, licensesFetched, certificatesFetched, stillImagesCount);

  const sidebarMenu = translateRoutes(sidebarData(location.pathname, handleHelpClick), t);

  return (
    <>
      <HaiSidebar
        logo={<Icon src={MakitoLogoX4} style={{ width: '165px' }} />}
        /*logoSrc={logo} TODO we should also be able to use this but doesn't work at the moment with sizing */
        logoCollapsedSrc={SmallLogoX4}
        accentColor={constant.productColor}
        backgroundImage={bgMK}
        menuContent={sidebarMenu}
        userName={sessionStore.username}
        onClickUserName={handleClickUserName}
        defaultActive={defaultActive}
        data-auto={generateDataAuto('sidebar', collapsed ? 'collapsed' : 'expanded')}
        onSelect={handleSelect}
        onSignOut={signout}
        signOutText={t('general.signOut')}
        onCollapse={toggleCollapse}
        collapsed={collapsed}
      />
      <MyModal />
    </>
  );
};
