import { Observable } from 'rxjs';
import { APIPath } from 'src/js/api/route-path-index';
import { BaseController } from 'src/js/controller/base-controller';
import { NetworkErrorHandler } from 'src/js/controller/network-error-handler';
import { DeleteFirmwareResponse } from 'src/js/model/api/response/delete-firmware-response';
import { MonitorFirmwareUploadResponse } from 'src/js/model/api/response/monitor-firmware-upload-response';
import { UpgradeDetailsResponse } from 'src/js/model/api/response/upgrade-details-response';
import { UploadFirmwareResponse } from 'src/js/model/api/response/upload-firmware-response';

export class SettingsUpgradeController extends BaseController {
  /* Returns the upgrade details */
  public getUpgradeDetails(): Observable<UpgradeDetailsResponse> {
    return this.get$(APIPath.upgrade.get);
  }
  /* Deletes an uploaded firmware */
  public deleteFirmware(filename: string): Observable<DeleteFirmwareResponse> {
    return this.delete$(APIPath.deleteFirmware(filename));
  }
  /* Monitors the progress when uploading a firmware */
  public monitorFirmwareUpload(
    filename: string,
    errorHandler?: NetworkErrorHandler,
  ): Observable<MonitorFirmwareUploadResponse> {
    return this.get$(
      APIPath.monitorFirmwareUpload(filename),
      {},
      {
        customErrorHandler: errorHandler,
      },
    );
  }

  /* Uploads a new firmware */
  public uploadFirmware(file: File, callback: any): Observable<UploadFirmwareResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.post$(APIPath.upgrade.upload, formData, {
      onUploadProgress: (e) => {
        if (e && e.total) {
          callback(e);
        }
      },
    });
  }
}
