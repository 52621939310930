/*eslint no-console: 0*/
/*eslint curly: 0*/

import { clone } from 'ramda';
import React, { ForwardedRef, forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { log } from 'src/js/service/logger';

const DEBUG = false;

// public interface
export interface GridManagerClientInterface<T> {
  register: (id: string, el: Element, client: T) => void;
  unregister: (id: string) => void;
  debug: () => void;
  onKeyUp: () => T | null;
  onKeyDown: () => T | null;
  onKeyLeft: () => T | null;
  onKeyRight: () => T | null;
}

interface GridManagerProps<T> {
  children?: (options: GridManagerClientInterface<T>) => React.ReactNode;
  className?: string;
}

// private interface
export interface GridManagerRef<T> extends GridManagerClientInterface<T> {
  areAllClientsValid: () => boolean;
}

type GridClient<T> = {
  el: Element;
  client: T;
  id: number | string;
  rect: {
    x?: number;
    y?: number;
    w?: number;
    h?: number;
  };
  resizeObserver: ResizeObserver;
  selected?: boolean;
};

//let count = 0;
function GridManagerInner<T>(props: GridManagerProps<T>, ref: Ref<GridManagerRef<T>>) {
  const { className, ...rest } = props;
  const [clientsMap] = useState<Map<string, GridClient<T>>>(new Map());
  //const clientsMap = new Map(); using this line instead of the previous will break the unit test

  //console.log('GridManagerRef render', clientsMap.size, ++count);

  const reprocess = () => {
    let isValid = true;
    clientsMap.forEach((_value, _key) => {
      // if (value === false) {
      isValid = false;
      //  }
    });
    //console.log('isValid?', isValid);
    return isValid;
  };

  const debug = () => {
    clientsMap.forEach((value, key) => {
      /* eslint-disable no-console */
      console.log('client', key, value);
      /* eslint-enable no-console */
    });
  };

  const areAllClientsValid = () => {
    const isValid = reprocess();
    //console.log('areAllClientsValid', isValid);
    return isValid;
  };

  const register = (id: string, el: Element, opaque: T) => {
    if (clientsMap.has(id)) {
      return; // already existing client
    }

    if (el == null) {
      log.warn('el is null or undefined');
      return; // already existing
    }
    if (DEBUG) console.log('registring', id, el);
    clientsMap.set(id, {
      resizeObserver: null,
      id: id,
      el: el,
      rect: {},
      client: opaque,
    });
    const client = clientsMap.get(id);
    client.resizeObserver = new ResizeObserver((cl) => {
      const el = cl[0].target;
      const offsetLeft = (cl[0].target as any).offsetLeft;
      const offsetTop = (cl[0].target as any).offsetTop;
      const rect = cl[0].target.getBoundingClientRect();
      // console.log('rect', rect);
      client.rect.x = offsetLeft || cl[0].contentRect.left || Math.round(rect.left);
      client.rect.y = offsetTop || cl[0].contentRect.top || Math.round(rect.top);
      client.rect.w = cl[0].contentRect.width;
      client.rect.h = cl[0].contentRect.height;
      client.selected = el.classList.contains('selected');
      // console.log('classList', el.classList, client.selected);
    });
    client.resizeObserver.observe(el);
  };

  const unregister = (id: string) => {
    if (!clientsMap.has(id)) {
      log.warn('client already unregistered or was never added, check your code');
      return; // error
    }

    const client = clientsMap.get(id);
    if (client?.resizeObserver) {
      client.resizeObserver.unobserve(client.el);
    }

    clientsMap.delete(id);
  };

  const calculate = () => {
    const lines: any = [];
    const columns: any = [];
    let selected: GridClient<T>;
    clientsMap.forEach((client) => {
      client.selected = client.el.classList.contains('selected');
      if (client.selected) {
        selected = client;
      }
      const line = lines.find((l: any) => l.y === client.rect.y);
      if (!line) {
        // add line
        lines.push({ y: client.rect.y });
      }
      const column = columns.find((c: any) => c.x === client.rect.x);
      if (!column) {
        // add line
        columns.push({ x: client.rect.x });
      }
    });
    return { lines, columns, selected };
  };

  // private
  const selectNewTarget = (selected: GridClient<T>, adjX: number, adjY: number) => {
    const newTarget = clone(selected?.rect);
    if (newTarget) {
      newTarget.y += adjY;
      newTarget.x += adjX;
      let newSelectedClient: GridClient<T>;
      clientsMap.forEach((client) => {
        const diffx = client.rect.x - newTarget.x;
        const diffy = client.rect.y - newTarget.y;
        if (Math.abs(diffx) < 2.0 && Math.abs(diffy) < 2.0) {
          // found
          newSelectedClient = client;
        }
      });
      if (newSelectedClient) {
        if (newSelectedClient.client) {
          return newSelectedClient.client;
        }
        newSelectedClient.el.classList.add('selected');
        selected.el.classList.remove('selected');
      }
    }
    return null;
  };

  const onKeyUp = () => {
    const { lines, selected } = calculate();
    if (!selected) {
      if (DEBUG) console.warn('could not get a new selected item');
      return undefined;
    }
    let rowHeight = 0;
    if (lines.length > 1) {
      rowHeight = lines[1].y - lines[0].y;
    }
    // move up
    return selectNewTarget(selected, 0, -rowHeight);
  };

  const onKeyDown = () => {
    const { lines, selected } = calculate();
    if (!selected) {
      if (DEBUG) console.warn('could not get a new selected item');
      return undefined;
    }
    let rowHeight = 0;
    if (lines.length > 1) {
      rowHeight = lines[1].y - lines[0].y;
    }
    // move up
    return selectNewTarget(selected, 0, +rowHeight);
  };

  const onKeyLeft = () => {
    const { lines, columns, selected } = calculate();
    if (!selected) {
      if (DEBUG) console.warn('could not get a new selected item');
      return undefined;
    }
    let rowHeight = 0;
    let rowWidth = 0;
    let leftMost = 0xffffffff;
    let rightMost = -1;
    clientsMap.forEach((client) => {
      if (client.rect.x < leftMost) {
        leftMost = client.rect.x;
      }
      if (client.rect.x > rightMost) {
        rightMost = client.rect.x;
      }
    });
    if (lines.length > 1) {
      rowHeight = lines[1].y - lines[0].y;
    }
    if (columns.length > 1) {
      rowWidth = columns[1].x - columns[0].x;
    }

    let adjX = 0;
    let adjY = 0;
    // check if wrap up
    if (selected.rect.x === leftMost) {
      adjX = rightMost - leftMost;
      adjY = -rowHeight;
    } else {
      adjX = -rowWidth;
    }
    // move left
    return selectNewTarget(selected, adjX, adjY);
  };

  const onKeyRight = () => {
    const { lines, columns, selected } = calculate();
    if (!selected) {
      if (DEBUG) console.warn('could not get a new selected item');
      return undefined;
    }
    let rowHeight = 0;
    let rowWidth = 0;
    let leftMost = 0xffffffff;
    let rightMost = -1;
    clientsMap.forEach((client) => {
      if (client.rect.x < leftMost) {
        leftMost = client.rect.x;
      }
      if (client.rect.x > rightMost) {
        rightMost = client.rect.x;
      }
    });
    if (lines.length > 1) {
      rowHeight = lines[1].y - lines[0].y;
    }
    if (columns.length > 1) {
      rowWidth = columns[1].x - columns[0].x;
    }

    let adjX = 0;
    let adjY = 0;
    // check if wrap on next line
    if (selected.rect.x === rightMost) {
      adjX = leftMost - rightMost;
      adjY = +rowHeight;
    } else {
      adjX = +rowWidth;
    }
    // move left
    return selectNewTarget(selected, adjX, adjY);
  };

  const clientFuncs = {
    register,
    unregister,
    areAllClientsValid,
    debug,
    onKeyUp,
    onKeyDown,
    onKeyLeft,
    onKeyRight,
  };

  useImperativeHandle(ref, () => clientFuncs);

  if (typeof props.children === 'function') {
    return <React.Fragment {...rest}>{props.children(clientFuncs)}</React.Fragment>;
  } else {
    return <React.Fragment {...rest}>{props.children}</React.Fragment>;
  }
}

export const GridManager = forwardRef(GridManagerInner) as <T>(
  props: GridManagerProps<T> & { ref?: ForwardedRef<GridManagerRef<T>> },
) => ReturnType<any>;

export default GridManager;
//GridManager.displayName = 'GridManager';
