import { Button, Dialog } from '@hai/ui-react';
import { Formik } from 'formik';
import { isNil } from 'ramda';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { StateButton } from 'src/js/component/base/state-button';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { validateFormContext } from 'src/js/validator/validator';

import { IdentityGenerateViewModel, identityValidationSchema } from './certificates-models';

interface GeneralFormProps {
  onSubmit?: (model: IdentityGenerateViewModel) => void;
  onCancel?: VoidFunction;
  buttonState: string;
}

export const IdentityGenerateForm = (props: GeneralFormProps) => {
  const { onCancel, buttonState } = props;
  const { t } = useTranslation();
  const formRef = React.useRef(null);

  enum IdentityGenerateTypes {
    SELFSIGNED = 'self',
    CSR = 'request',
  }

  const nameInputRef = useRef(undefined);
  useEffect((): void => {
    if (!isNil(nameInputRef.current)) {
      nameInputRef.current.focus();
    }
  }, [nameInputRef]);

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') {
      const button = document.getElementById('identity-generate-form-submit');
      if (!isNilOrEmpty(button)) {
        e.preventDefault();
        button.click();
      }
    }
  };

  const content = (): ReactNode => {
    return (
      <Formik
        innerRef={formRef}
        initialValues={{ name: location.hostname, sign: 'self', subject: '' }}
        validationSchema={identityValidationSchema(t)}
        onSubmit={(values, _formikBag) => {
          props.onSubmit && props.onSubmit({ ...values });
        }}
      >
        {(formikProps) => {
          return (
            <form className="HaiForm" onKeyUp={handleOnKeyUp}>
              <FormikTextfield name="name" title={t('general.name')} inputRef={nameInputRef} required />
              <FormikSelect
                title={t('general.type')}
                name="sign"
                selectValues={Object.values(IdentityGenerateTypes)}
                selectLabels={Object.values(IdentityGenerateTypes).map((type) =>
                  t(`security.certificates.identityEnumToLabel.${type}`),
                )}
              />
              <FormikTextfield name="subject" title={t('general.subject')} />
              <div className="d-flex justify-content-end hai-mt-6">
                <Button onClick={onCancel}>{t('general.cancel')}</Button>
                <StateButton
                  onClick={() => validateFormContext(formikProps, t)}
                  variant="primary"
                  id="identity-generate-form-submit"
                  state={buttonState}
                >
                  {t('general.generate')}
                </StateButton>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('security.certificates.identityForm.title')}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="Security"
      content={content()}
    />
  );
};
