import { FormContext, FormControlContainer, FormGroup, FormLabel, Placeholder, Switch } from '@hai/ui-react';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { isNil } from 'ramda';
import React from 'react';

import { ConditionalWrapper } from './conditional-wrapper';

interface Props {
  title?: string;
  name?: string;
  mask?: boolean;
  disabled?: boolean;
  loading?: boolean;
  switchLabel?: string;
  checked?: boolean;
  className?: string;
  switchClassName?: string;
  labelPlacement?: string;
  container?: boolean;
  onChange?: Function;
}

export const FormSwitch: React.FunctionComponent<Props> = ({
  title,
  name,
  mask,
  disabled = false,
  loading = false,
  switchLabel,
  checked,
  className = '',
  switchClassName = '',
  labelPlacement = 'left',
  container = false,
  onChange,
}) => {
  return (
    <FormContext.Consumer>
      {(formContext: FormikProps<any>) => {
        // If HAIUI ever handles this internally for checkbox we could remove this block of formik code
        const meta = formContext?.getFieldMeta(name);
        const helper = formContext?.getFieldHelpers(name);
        const formikValue = meta?.touched ? meta.value : meta?.initialValue;
        const onChangeLocal = (checked: boolean) => {
          //FIXME prevent double-click
          if (!isNil(name)) {
            helper?.setTouched(true);
            helper?.setValue(checked);
          }
          onChange?.(checked);
        };
        return (
          <FormGroup className={className}>
            {title && <FormLabel>{title}</FormLabel>}

            <ConditionalWrapper
              condition={container}
              wrapper={(children) => <FormControlContainer>{children}</FormControlContainer>}
            >
              {loading === true ? (
                <div className={`${classNames('mk-switch-placeholder-label', switchClassName)}`}>
                  <div>{switchLabel}</div>
                  <Placeholder as="layout" />
                </div>
              ) : (
                <Switch
                  name={name}
                  useFsMask={mask}
                  disabled={disabled}
                  checked={checked ?? !!formikValue}
                  onChange={onChangeLocal}
                  label={switchLabel}
                  labelPlacement={labelPlacement}
                  className={switchClassName}
                />
              )}
            </ConditionalWrapper>
          </FormGroup>
        );
      }}
    </FormContext.Consumer>
  );
};
