import { lensProp, set } from 'ramda';
import { NetworkInterfaceRequest, NetworkRequest } from 'src/js/model/api/request/network-request';
import { Ipv6List } from 'src/js/model/api/response/network-response';
import {
  Ipv4Addressing,
  NetworkInterfaceViewModel,
  NetworkViewModel,
  filterIPv6Value,
} from 'src/js/pages/settings/network/network-view-model';

const ipv6ListToRequest = (ipv6Strings: string[]): Ipv6List => {
  let ipv6List: Ipv6List = {
    '0': '',
    '1': '',
    '2': '',
    '3': '',
    '4': '',
    '5': '',
    '6': '',
    '7': '',
  };
  ipv6Strings.forEach((value: string, index: number) => {
    ipv6List = set(lensProp(`${index}`), value, ipv6List);
  });
  return ipv6List;
};

export const createUpdateNetworkRequestInterfaceSection = (
  viewModel: NetworkInterfaceViewModel,
): NetworkInterfaceRequest => {
  return {
    ...viewModel,
    dhcp: viewModel.ipv4Addressing === Ipv4Addressing.DHCP ? 1 : 0,
    dhcpVendorID: viewModel.dhcpVendorId,
    linklocal: viewModel.linklocal ? 1 : 0,
    ipv6_mode: viewModel.ipv6Addressing,
    ipv6_ip: filterIPv6Value(viewModel.ipv6Address),
    ipv6_prefix_length: Number(viewModel.ipv6PrefixLength),
    ipv6_gateway: viewModel.ipv6Gateway,
    ipv6_privacy_extensions: viewModel.ipv6PrivacyExtension,
    ipv6_linklocal: viewModel.ipv6LinkLocal,
    ipv6_ips: ipv6ListToRequest(viewModel.ipv6List),
    mac: viewModel.macAddress,
    bandwidth: Number(viewModel.bandwidth),
  };
};

export const createUpdateNetworkRequest = (viewModel: NetworkViewModel): NetworkRequest => {
  const eth0_network = createUpdateNetworkRequestInterfaceSection(viewModel.eth0);
  const eth1_network = createUpdateNetworkRequestInterfaceSection(viewModel.eth1);
  return {
    autoDns: viewModel.autoDns ? 1 : 0,
    dns: viewModel.primaryDns,
    dns2: viewModel.alternateDns,
    dnsPrecedence: viewModel.dnsPrecedence,
    domain: viewModel.domain,
    eth0: eth0_network,
    eth1: eth1_network,
    hasEth1: viewModel.hasEth1,
    hostname: viewModel.hostname,
    isEth1Sfp: viewModel.isEth1Sfp,
    mDnsEnabled: viewModel.mDnsEnabled ? 1 : 0,
    mDnsName: viewModel.mDnsName,
  };
};
