import { Icon, Heading3Med } from '@hai/ui-react';
import classNames from 'classnames';
import React from 'react';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  className?: string;
  color?: string;
  icon?: string;
  leftBar?: boolean;
  title?: string;
}

export const FullHeader: React.FunctionComponent<Props> = ({ className, color, leftBar, icon, title }) => {
  return (
    <div className={classNames('full-page-header', className)}>
      {leftBar && <div className="status-indicator" style={{ backgroundColor: color }} />}
      <Icon iconname={icon} style={{ fill: color }} size={'md'} />
      <Heading3Med className="header-title fs-mask" data-auto={generateDataAuto('header', 'stream title')}>
        {title}
      </Heading3Med>
    </div>
  );
};
