import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StreamViewModel } from './stream-models';

interface Props {
  itemsToDelete: StreamViewModel[];
  onApply?: (items: StreamViewModel[]) => void;
  onCancel?: VoidFunction;
}

export const StreamDeleteConfirmModal: React.FunctionComponent<Props> = ({ itemsToDelete, onApply, onCancel }) => {
  const { t } = useTranslation();

  const handleDelete = (): void => {
    onApply(itemsToDelete);
  };

  return (
    <Dialog
      headline={t('stream.deleteModal.title', { count: itemsToDelete.length })}
      bodyText={t('general.cannotUndo')}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel') },
        { label: t('general.delete'), variant: 'danger', onClick: handleDelete },
      ]}
    />
  );
};
