import { createWatchStore, setProperty, updateWatchStore } from 'src/js/store/use-watch';

import { EmsService } from './ems-models';

// values will be inited from API at runtime
const emsService: EmsService = {
  state: undefined,
  emsPort: 0,
  emsHostname: '',
  emsKeepalive: 0,
  iconState: undefined,
};

createWatchStore(emsService);

const setEmsServiceProperty = (key: string, value: any) => {
  setProperty(emsService, key as keyof typeof emsService, value);
};
export { setEmsServiceProperty };
export const saveResponseToEmsService = (response: EmsService) => {
  updateWatchStore(emsService, response);
};

export default emsService;
