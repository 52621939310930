import { isNil } from 'ramda';
import { UpgradeDetails } from 'src/js/model/api/upgrade-details';
import { CurrentVersionViewModel } from 'src/js/pages/settings/upgrade/current-version-view-model';

export const mapCurrentVersionViewModel = (upgradeDetails: UpgradeDetails): CurrentVersionViewModel => {
  if (isNil(upgradeDetails)) {
    return {
      currentVersion: undefined,
      packageToBeInstalled: undefined,
    };
  } else {
    return {
      currentVersion: upgradeDetails.currentVersion,
      packageToBeInstalled: upgradeDetails.packageToBeInstalled,
    };
  }
};
