import { Button, ButtonToggleGroup, Label1Med } from '@hai/ui-react';
import classNames from 'classnames';
import { isNil } from 'ramda';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import defaultThumbnail from 'src/images/thumbnail-default.jpg'; // FIXME cache or pre-cache. When system goes offline it tries to fetch it again and appears as not found image.
import { ConditionalWrapper } from 'src/js/component/base/form/conditional-wrapper';
import { FormCheckbox } from 'src/js/component/base/form/form-checkbox';
import { constant } from 'src/js/constant';
import { useAdaptiveFetch } from 'src/js/hook/use-adaptive-fetch';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { StreamSrtStatsResponse } from 'src/js/model/api/response/stream-srt-stats-response';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { StatisticPlotContainer } from 'src/js/pages/statistic/statistic-plot-container';
import { StatisticTable, StatsMode } from 'src/js/pages/statistic/statistic-table';
import { SRTMode, StreamViewModel } from 'src/js/pages/streaming/stream/stream-models';
import { mapStreamToStatistics } from 'src/js/pages/streaming/stream/stream-statistics-content';
import {
  fetchStreamGenericGraph,
  fetchStreamSRTGraph,
  fetchStreamStatistics,
  resetStreamStatistics,
} from 'src/js/pages/streaming/stream/stream-store';
import { streamListStreamName, streamStateMessage } from 'src/js/pages/streaming/stream/stream-view-model-mapper';
import { FullHeader } from '../../statistic/full-header';
import { StreamGenericPlotContainer } from '../../statistic/stream-generic-plot-container';

import { DecoderViewModel } from './decoder-model';
import { mapDecoderToStatistics } from './decoder-statistics-content';
import decoderModels, { fetchDecoderStatistics, resetDecoderStatistics } from './decoder-store';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  decoder?: DecoderViewModel;
  stream: StreamViewModel;
  fullpage?: boolean;
  showDecoderStats?: boolean;
  isExpanded?: boolean;
  parent: string;
  refreshInterval?: number;
}

/* extends {id:string|number}*/
interface FirstLineProps {
  children: ReactElement[]; // each mapping to a T element with key = T.id
}

const FirstLineWrapper: (props: FirstLineProps) => React.ReactElement<Props> = ({ children, ...rest }) => {
  return (
    <div className="first-line" {...rest}>
      {children}
    </div>
  );
};

const protocolToShort = (input?: string) => {
  if (!input) {
    return input;
  }
  const matches = /TS over (.+)/.exec(input);
  if (matches) {
    return matches[1].toUpperCase();
  }
  return input;
};

export const DecoderStatistics: React.FunctionComponent<Props> = ({
  decoder,
  stream,
  fullpage = false,
  showDecoderStats = true,
  isExpanded = false,
  parent,
  refreshInterval,
}) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();
  const navigate = useNavigate();

  const [isSRT, setIsSRT] = useState(!isNil(stream?.stats?.srt));
  const [graphData, setGraphData] = useMutable<StreamSrtStatsResponse[]>([]);
  const [showRedundancy, setShowRedundancy] = useState(false);
  const [interval, setInterval] = useState(StatsInterval.LAST_5_MINS);
  const [graphInterval, setGraphInterval] = useState(undefined);
  const [mode, setMode] = useState<StatsMode>(
    !fullpage && parent === 'DECODER' ? StatsMode.plainStats : StatsMode.withGraph,
  );
  const graphRefreshRateMs = refreshInterval ?? constant.graphRefreshRateMs;
  const refreshRate = stream && parent !== 'DECODER' && (isExpanded || fullpage) ? graphRefreshRateMs : undefined;

  useAdaptiveFetch(
    () => {
      const _isSRT = !!stream?.stats?.srt; // for immediate correct fetch below
      if (isSRT && !stream?.stats?.srt) {
        setIsSRT(false);
      } else if (!isSRT && stream?.stats?.srt) {
        setIsSRT(true);
      }

      const redundancyPath: number = undefined;
      let srtRedundancyMode = stream.srtRedundancyMode;
      if (_isSRT && stream.srtMode === SRTMode.LISTENER) {
        // in listener mode we get the stream redundacy mode from the stats
        srtRedundancyMode = stream.stats?.srt.detectedRedundancyMode;
      }
      if (srtRedundancyMode === 'active' || srtRedundancyMode === 'backup') {
        // redundancyPath = 1;
        setShowRedundancy(true);
      } else {
        setShowRedundancy(false);
      }
      if (_isSRT) {
        return fetchStreamSRTGraph(stream, interval, redundancyPath, (updated) => {
          if (stream.stats.srt && updated?.length > 0) {
            setMode(StatsMode.withGraph); // to leave space for graph
          } else if (mode === StatsMode.withGraph) {
            setMode(StatsMode.withGraph);
          }
          setGraphData(updated);
          if (graphInterval !== interval) {
            setGraphInterval(interval);
          }
        }) /* If we ever fetch both graph path data separately like on MX4E 1.5 and before
        .then((updated) => {
          if (redundancyPath === 1) {
            return fetchStreamSRTGraph(model, interval ?? localTimescale, 2, (updated2) => {
              setGraphData2(updated2);
            });
          }
          return updated;
        })*/;
      } else {
        return fetchStreamGenericGraph(stream, interval, (updated) => {
          if (stream.stats.srt && updated?.length > 0) {
            setMode(StatsMode.withGraph); // to leave space for graph
          } else if (mode === StatsMode.withGraph) {
            setMode(StatsMode.withGraph);
          }
          setGraphData(updated);
          if (graphInterval !== interval) {
            setGraphInterval(interval);
          }
        });
      }

      return undefined;
    },
    refreshRate,
    [interval, refreshRate] /* this makes it refresh right away when we change interval */,
  );

  const [decoderData, setDecoderData] = useState<DecoderViewModel>(
    decoder ? decoder : decoderModels.find((v) => v.id === stream?.decoderId),
  );
  useEffect(() => {
    if (!isNil(decoder)) {
      setDecoderData(decoder);
    }
  }, [decoder]);

  useAdaptiveFetch(
    () => fetchDecoderStatistics(decoder, t, setDecoderData),
    fullpage || !showDecoderStats ? undefined : refreshRate,
    [refreshRate],
  );

  const [streamData, setStreamData] = useState<StreamViewModel>(stream);
  useEffect(() => {
    setStreamData(stream);
  }, [stream]);

  useAdaptiveFetch(() => fetchStreamStatistics(stream, t, setStreamData), fullpage ? undefined : refreshRate, [
    refreshRate,
  ]);

  const showThumbnail = fullpage;

  // thumbnail refresh
  const [thumbnailDate, setThumbnailDate] = useState<string>(`${new Date().getTime()}`);
  useEffect(() => {
    let endrun = false;
    let timer: any;
    const refreshRate = (decoderData?.previewIntervalSec ?? 10) * 1000;
    const triggerRefresh = () => {
      if (!endrun) {
        setThumbnailDate(`${new Date().getTime()}`);
        if (decoderData?.previewIntervalSec >= 1) {
          timer = setTimeout(triggerRefresh, refreshRate);
        }
      }
    };

    timer = setTimeout(() => {
      triggerRefresh();
    }, refreshRate);

    return () => {
      // cleanup
      endrun = true;
      clearTimeout(timer);
    };
  }, [decoderData?.previewIntervalSec]);

  const handleResetStatistics = () => {
    let decoderResetPromise;
    if (!isNil(decoderData) && showDecoderStats) {
      decoderResetPromise = resetDecoderStatistics(decoderData, t, setDecoderData);
    }
    if (parent === 'DECODER' && !fullpage) {
      // only decoder
      decoderResetPromise.then((res) => {
        if (res.ok) {
          createAndDispatchNotification(t('stream.notifications.resetDecoderSuccess'), NotificationVariant.SUCCESS, t);
        }
      });
      return;
    }
    !isNil(streamData) &&
      resetStreamStatistics(streamData, t, setStreamData).then((res) => {
        if (res.ok) {
          if (!isNil(decoderData) && showDecoderStats) {
            // both
            createAndDispatchNotification(t('stream.notifications.resetBothSuccess'), NotificationVariant.SUCCESS, t);
          } else {
            // only stream
            createAndDispatchNotification(t('stream.notifications.resetStreamSuccess'), NotificationVariant.SUCCESS, t);
          }
        }
      });
  };

  const showSrtRedundantPathSelector = isSRT && stream?.stats?.srt?.path2 && fullpage;

  const [pathSelected, setSelectedList] = useState(['path1', 'path2']);
  const onSelect = (item: any, ev: any) => {
    if (ev && ev.currentTarget && ev.currentTarget.checked) {
      const newList = [...pathSelected, item];
      setSelectedList(newList);
    } else {
      const newList = pathSelected.filter((loop) => loop !== item);
      setSelectedList(newList);
    }
  };
  /* when we had the dropdown
  const pathSelectedTitle =
    pathSelected.includes('path1') && pathSelected.includes('path2')
      ? t('statistics.pathSelectionAll')
      : pathSelected.includes('path1')
      ? t('statistics.path1')
      : pathSelected.includes('path2')
      ? t('statistics.path2')
      : t('statistics.pathSelectionNone');
      */

  const renderGraphHeader = () => {
    return (
      <>
        {mode === StatsMode.withGraph && fullpage && (
          <div className={classNames('mk-graph-dropdowns')} data-auto={generateDataAuto('graph', 'headers')}>
            <div className="d-flex justify-content-between align-items-center" style={{ height: '40px' }}>
              <Label1Med className="hai-gray-06">
                {isSRT
                  ? t('statistics.header.srtStatistics')
                  : t('statistics.header.genericStatistics', { protocol: protocolToShort(stream?.protocol) })}
              </Label1Med>
              {showSrtRedundantPathSelector && (
                <div className="mk-redundant-path-selector">
                  <div className="mk-checkbox-two-lines cursorPointer">
                    <label>
                      <FormCheckbox
                        name="path1"
                        label={t('statistics.path1')}
                        onChange={(evt: any) => onSelect('path1', evt)}
                        checked={pathSelected.includes('path1')}
                      />
                      <svg
                        height="20px"
                        width="71px"
                        color="white"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        className="strokeLineExample"
                      >
                        <line className="strokeGraphLegend path1" x1="0" x2="71" y1="9" y2="9" />
                      </svg>
                    </label>
                  </div>
                  <div className="mk-checkbox-two-lines cursorPointer">
                    <label>
                      <FormCheckbox
                        name="path2"
                        label={t('statistics.path2')}
                        onChange={(evt: any) => onSelect('path2', evt)}
                        checked={pathSelected.includes('path2')}
                      />

                      <svg
                        height="20px"
                        width="71px"
                        color="white"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        className="strokeLineExample"
                      >
                        <line className="strokeGraphLegend path2" x1="0" x2="71" y1="9" y2="9" />
                      </svg>
                    </label>
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center">
                <ButtonToggleGroup
                  data-auto={generateDataAuto('button_toggle', 'graph')}
                  options={[
                    {
                      label: t(`statistics.chart.timescale.optionsName.${StatsInterval.LAST_5_MINS}`),
                      value: StatsInterval.LAST_5_MINS,
                      active: interval === StatsInterval.LAST_5_MINS,
                    },
                    {
                      label: t(`statistics.chart.timescale.optionsName.${StatsInterval.LAST_60_MINS}`),
                      value: StatsInterval.LAST_60_MINS,
                      active: interval === StatsInterval.LAST_60_MINS,
                    },
                    {
                      label: t(`statistics.chart.timescale.optionsName.${StatsInterval.LAST_24_HOURS}`),
                      value: StatsInterval.LAST_24_HOURS,
                      active: interval === StatsInterval.LAST_24_HOURS,
                    },
                  ]}
                  onOptionClicked={(opt: any) => {
                    setInterval(opt.value);
                  }}
                ></ButtonToggleGroup>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const streamMsg = streamStateMessage(streamData, decoderData, t);
  const statsUrl =
    parent === 'DECODER'
      ? `/streaming/decoder/stats?decoder=${decoderData?.id}`
      : `/streaming/decoder/stats?stream=${streamData?.id}`;
  return (
    <div className={classNames('mk-stream-statistics-container', showRedundancy && 'showRedundancy')}>
      <div className={classNames('mk-stats-container', fullpage && 'fulldetails', isSRT && 'srt')}>
        <ConditionalWrapper
          condition={fullpage && (showThumbnail || mode === StatsMode.withGraph)}
          wrapper={(children: any) => <FirstLineWrapper>{children}</FirstLineWrapper>}
        >
          {showThumbnail && (
            <div className="mk-thumbnail-container">
              <div
                className="thumbnail-header d-flex align-items-center justify-content-left"
                data-auto={generateDataAuto('header', 'thumbnail')}
              >
                <Label1Med className="hai-gray-06">{t('statistics.thumbnailTitle')}</Label1Med>
              </div>
              <div className="thumbnail-section" data-auto={generateDataAuto('thumbnail', 'preview')}>
                {decoderData?.previewEnabled ? (
                  <img src={`/apis/decoders/${decoderData?.id}/preview?t=${thumbnailDate}`} />
                ) : (
                  <div className="thumbnail">
                    <img src={defaultThumbnail} />
                    <div className="overlay unselectable haiui-heading-04-med">{streamMsg}</div>
                  </div>
                )}
              </div>
            </div>
          )}
          {isSRT && mode === StatsMode.withGraph && (
            <StatisticPlotContainer
              width={480}
              height={235}
              model={stream}
              header={renderGraphHeader()}
              graphData={graphData()}
              timescale={graphInterval}
              pathSelected={pathSelected}
              showRedundancy={showRedundancy}
              fullpage={fullpage}
            />
          )}
          {!isSRT && mode === StatsMode.withGraph && (
            <StreamGenericPlotContainer
              width={fullpage ? 960 : 800}
              height={fullpage ? 235 : 160}
              model={stream}
              header={renderGraphHeader()}
              graphData={graphData()}
              timescale={graphInterval}
              fullpage={fullpage}
            />
          )}
        </ConditionalWrapper>
        {showDecoderStats && !isNil(decoderData) && (
          <StatisticTable
            mode={fullpage ? StatsMode.plainStats : mode}
            shortlist={!fullpage}
            //  title={fullpage ? t('statistics.decoder.subTitle') : null}
            sections={mapDecoderToStatistics(decoderData, fullpage, t)}
            className={!fullpage && 'flexEndContainer'}
          />
        )}
        {(!showDecoderStats || fullpage) && !isNil(streamData) && (
          <>
            {fullpage && (
              <FullHeader
                className="secondary-stats"
                title={streamListStreamName(t, streamData.name)}
                icon={'Streams'} //streamStateToIcon(streamData.stats?.state)}
                color={constant.productColor} //streamData.statusColor}
              />
            )}
            <StatisticTable
              mode={fullpage ? StatsMode.plainStats : mode}
              shortlist={!fullpage}
              sections={mapStreamToStatistics(streamData, fullpage, t)}
              title={null}
              className={!fullpage && 'flexEndContainer'}
            />
          </>
        )}
      </div>
      {!fullpage && (
        <div className="mk-stats-open-detailed-buttons-row d-flex flexEndContainer">
          {!sessionStore.isUser() && (
            <Button variant="secondary" onClick={handleResetStatistics}>
              {t('general.reset')}
            </Button>
          )}

          <div className="hai-ml-2">
            <a href={statsUrl}>
              <Button
                variant="secondary"
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  navigate(statsUrl);
                }}
              >
                {t('statistics.button.openDetailed')}
              </Button>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
