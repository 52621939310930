import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Hook to listen to history change, receive the history object in the callback to use it
 *
 * @param callback: The callback from the listen, get the history object for further processing
 */
export const useNavigateListener = (callback: (location?: Location) => void): void => {
  const location = useLocation();
  useEffect(() => {
    callback(location as any); // FIXME
  }, [location, callback]);
};
