export const decoder = {
  decoder: {
    currentSettings: 'No Changes',
    selectMode: 'Select Mode...',
    frameRateAuto: 'Automatic',
    name: 'Decoder {{id}}',
    sdiId: 'SDI {{id}}',
    noAudioChannels: 'NO AUDIO CHANNELS',
    primaryStream: 'Stream',
    RESETSTATS: 'RESET STATS',
    sdiOutputs: 'SDI Outputs',
    setBuffering: 'Set Buffering',
    streamSelectNone: '(None)',
    sections: {
      input: 'Input',
      metadata: 'Metadata',
      output: 'Output',
      stream: 'Stream',
    },
    fields: {
      afd: 'AFD',
      audio: 'Audio',
      bufferingDelayMS: 'Delay (ms)',
      bufferingDelay: 'Delay (ms)',
      bufferingMode: 'Buffering Mode',
      cc: 'Closed Captions',
      combinedRange: 'Combined Valid Range {{range}}ms',
      downmixSurround: 'Downmix to Stereo',
      downmixSurroundTitle: 'Audio',
      dropCorruptedFrames: 'Drop Corrupted Frames',
      hdrDynamicRange: 'Dynamic Range',
      klv: 'KLV',
      multisyncBufferingDelay: 'Delay (ms)',
      multisyncNtpTurnedOff: 'NTP Not Active',
      multisyncOutOfRange: 'Delay Out of Range',
      multisyncTimecodeInvalid: 'Timecode Invalid',
      multisyncTimecodeNotPresent: 'Timecode Not Present',
      multisyncNtpTurnedOffShort: 'NTP Inactive',
      multisyncOutOfRangeShort: 'Delay Out of Range',
      multisyncTimecodeNotPresentShort: 'No Timecode',
      multisyncTimecodeInvalidShort: 'Timecode Invalid',
      outputFrameRate: 'Frame Rate',
      outputResolution: 'Resolution',
      quadMode: 'Quad Mode',
      resolution: 'Resolution',
      stillImage: 'Still Image',
      stillImageDelay: 'Still Image Delay (s)',
      stillImageDelayExplain: '1 - 1000',
      streamId: 'Stream',
      tc: 'Timecode',
      validRange: 'Valid Range',
    },
    enums: {
      hdr: {
        '-1': 'Off (SDR)',
        0: 'Automatic',
        1: 'HDR-HLG',
        2: 'HDR-PQ',
      },
      stillImage: {
        0: '',
        1: 'Freeze',
        2: 'Black',
        3: 'Blue',
        4: 'Color Bars',
        5: 'Mute',
        6: 'Select Image...',
      },
      quadMode: {
        0: 'Normal',
        1: '2SI',
      },
    },
    metadata: {
      KLV: 'KLV',
      CC: 'Closed Caption',
      TC: 'Timecode',
      AFD: 'AFD',
      present: 'Present',
      notPresent: 'Not Present',
      unlicensed: 'Unlicensed',
      title: 'Metadata', //REMOVE
    },
    bufferingModes: {
      UNSET: 'Unset',
      MIN: 'Min',
      ADAPTIVE: 'Adaptive',
      FIXED: 'Fixed',
      AUTOMATIC: 'Automatic',
      MULTISYNC: 'MultiSync',
      MINIMUM: 'Minimum',
      STANDARD_HDR: 'Standard HDR',
      LOWLATENCY: 'Low Latency',
    },
    link: {
      statistics: 'Statistics',
      expandThumbnail: 'Show Preview',
      hideThumbnail: 'Hide Preview',
      stillImage: 'Still Image',
    },
    notifications: {
      resetAllDecoderSuccess: 'The statistics for all decoders have been reset',
      resetDecoderSuccess: "Decoder's statistics have been reset",
      resetSelectedDecoderSuccess: "The selected decoder's statistics have been reset",
      resetSelectedDecoderSuccess_plural: 'The selected decoders statistics have been reset',
      resetStatsError: 'Decoder statistics could not be reset',
      startError_plural: '{{count}} decoders could not be started',
      startError: 'Decoder could not be started',
      stopError_plural: '{{count}} decoders could not be stopped',
      stopError: 'Decoder could not be stopped',
      updateBufferSuccess_plural: '{{count}} decoder buffering modes updated',
      updateBufferSuccess: '{{count}} decoder buffering mode updated',
      updateError: "'{{name}}' could not be updated",
      updateSuccess: "'{{name}}' updated",
    },
    state: {
      0: 'Stopped',
      1: 'Started',
      2: 'Active',
      '-1': 'Not Decoding',
    },
    stateHint: {
      fixedDelayTooLow: 'Fixed delay too low',
      fixedDelayTooHigh: 'Fixed delay too high',
      multiSyncDelayTooLow: 'Multisync delay too low',
      multiSyncDelayTooHigh: 'Multisync delay too high',
      noStream: 'No stream assigned',
      active: 'Active',
      stopped: 'Stopped',
    },
    troubleCode: {
      0: 'None',
      '-1': 'Unsupported',
      '-2': 'Unlicensed',
      '-3': 'Oversubscribed',
      '-4': 'No Memory',
      '-5': 'Waiting for Keyframe',
    },
    encapsulation: {
      2: 'UDP',
      3: 'RTP',
      34: 'SRT',
      64: 'RTSP',
    },
  },
};
