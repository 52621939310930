import { map } from 'rxjs/operators';
import { apiClient } from 'src/js/api';
import { rxTaskFactory } from 'src/js/helper/mobx-tasks';
import { mapServices } from 'src/js/mapper/services-mapper';
import { mapUpdateServices } from 'src/js/mapper/update-services-mapper';
import { EmsPairRequest } from 'src/js/model/api/request/ems-pair-request';
import { createUpdateServicesRequest } from 'src/js/model/api/request/factory/update-services-request-factory';
import { ServicesResponse } from 'src/js/model/api/response/services-response';
import { UpdateServicesResponse } from 'src/js/model/api/response/update-services-response';
import { Service } from 'src/js/model/service';
import { Services } from 'src/js/model/services';
import { NetworkTaskGenerator, SameTypeNetworkTaskGenerator } from 'src/js/util/global-type';

export const createGetServicesTask: NetworkTaskGenerator<ServicesResponse, Services> = () =>
  rxTaskFactory(() =>
    apiClient.settingsServicesController
      .getServices()
      .pipe(map<ServicesResponse, Services>((response: ServicesResponse): Services => mapServices(response))),
  );

export const createUpdateServicesTask: NetworkTaskGenerator<UpdateServicesResponse, Services> = () =>
  rxTaskFactory((service: Service, value: boolean) =>
    apiClient.settingsServicesController
      .updateServices(createUpdateServicesRequest(service, value))
      .pipe(
        map<UpdateServicesResponse, Services>(
          (response: UpdateServicesResponse): Services => mapUpdateServices(response),
        ),
      ),
  );

export const createPairEmsTask: SameTypeNetworkTaskGenerator<unknown> = () =>
  rxTaskFactory((request: EmsPairRequest) => apiClient.settingsServicesController.pairEms(request));

export const createUnpairEmsTask: SameTypeNetworkTaskGenerator<unknown> = () =>
  rxTaskFactory(() => apiClient.settingsServicesController.unpairEms());

export const createForceUnpairEmsTask: SameTypeNetworkTaskGenerator<unknown> = () =>
  rxTaskFactory(() => apiClient.settingsServicesController.forceUnpairEms());
