import { Dialog } from '@hai/ui-react';
import { includes } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModalControls } from 'src/js/hook/use-modal-controls';

import { IdentityGenerateViewModel } from './certificates-models';
import { IdentityGenerateForm } from './identity-generate-form';

interface Props {
  listOfUsedNames?: string[];
  onSubmit?: (model: IdentityGenerateViewModel) => void;
  onCancel?: VoidFunction;
  buttonState: string;
}

export const IdentityGenerateModal: React.FunctionComponent<Props> = ({
  listOfUsedNames = [],
  onSubmit,
  onCancel,
  buttonState,
}) => {
  const { t } = useTranslation();
  const [overwriteShown, showOverwrite, hideOverwrite] = useModalControls();

  const handleSubmit = (model: IdentityGenerateViewModel): void => {
    if (includes(model.name, listOfUsedNames)) {
      showOverwrite();
    } else {
      onSubmit?.(model);
    }
  };

  return (
    <>
      <IdentityGenerateForm onSubmit={handleSubmit} onCancel={onCancel} buttonState={buttonState} />
      {overwriteShown && (
        <Dialog
          headline={t('security.certificates.modal.identity.overwrite.title')}
          bodyText={t('security.certificates.modal.identity.overwrite.bodyText', { name })}
          onClose={hideOverwrite}
          show={true}
          buttons={[{ label: t('general.ok') }]}
        />
      )}
    </>
  );
};
