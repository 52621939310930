import moment from 'moment';
import { DateTimeResponse } from 'src/js/model/api/response/date-time-response';
import { UpdateDateTime } from 'src/js/model/update-date-time';
import { PresetSavedResponse } from 'src/js/presets/preset-models';

export const mapUpdateDateTime = (response: DateTimeResponse & PresetSavedResponse): UpdateDateTime => ({
  timezone: response.timezone, // TODO get some kind of TimeZone object
  ntp: response.ntp,
  server: response.server,
  chronydRunning: response.chronydRunning,
  time: moment.tz(moment.unix(response.time), response.timezone),
  saved: response.isSaved,
});
