import { Button } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'src/js/hook/use-stores';

interface Props {
  locateStatusColor: string;
  locating: boolean;
  remainingTime?: number;
  onLocate?: VoidFunction;
  onSnapshot?: VoidFunction;
  onReboot?: VoidFunction;
}

export const StatusButtons: React.FunctionComponent<Props> = ({
  locateStatusColor,
  locating,
  remainingTime,
  onLocate,
  onSnapshot,
  onReboot,
}) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const minutes = Math.trunc(remainingTime / 60);
  const seconds = remainingTime - minutes * 60;

  return (
    <div className="buttonRow">
      {!sessionStore.isUser() && (
        <Button
          variant="locate"
          accentColor={locateStatusColor}
          onClick={onLocate}
          state={locating ? 'locating' : 'idle'}
          countdown={`${minutes}:${seconds}`}
        >
          {t('settings.system.button.locate')}
        </Button>
      )}
      <Button variant="secondary" onClick={onSnapshot}>
        {t('settings.system.button.snapshot')}
      </Button>
      {sessionStore.isAdmin() && (
        <Button variant="primary" onClick={onReboot}>
          {t('settings.system.button.reboot')}
        </Button>
      )}
    </div>
  );
};
