export enum LedStatusColor {
  Green = 'Green',
  Red = 'Red',
  Amber = 'Amber',
  Blue = 'Blue',
}

export interface GetLocateStatusResponse {
  statusLED: LedStatusColor;
  statusTransitions: number;
  blinkingWillStopIn?: string; // TODO currently a string in the format "4m33s". The backend should definitely return a number either in s or ms.
}
