import { isNil } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useStores } from 'src/js/hook/use-stores';
import { mapLedStatus } from 'src/js/mapper/locate-status-mapper';
import { LocateStatus } from 'src/js/model/locate-status';
import { SettingsTabContent } from 'src/js/pages/settings/settings-tab-content';
import { IsSavedRef, RebootIsSaved } from 'src/js/reboot/reboot-is-saved';
import { openNewWindow } from 'src/js/util/global-util';

import { DeviceName } from './device-name';
import { DeviceStatus } from './device-status';
import { DeviceStatusViewModel } from './device-status-view-model';
import { mapDeviceStatusViewModel } from './device-status-view-model-mapper';
import { LocateModal } from './locate-modal';
import { StatusButtons } from './status-buttons';
import { UserSettings } from './user-settings';

const FETCH_LOCATE_INTERVAL = 1000;

export const Status: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [locateModalShown, showLocateModal, hideLocateModal] = useModalControls();
  const { sessionStore } = useStores();

  const [blinkingWillStopIn, setBlinkingWillStopIn] = useState<number | undefined>(undefined);
  const [statusColor, setStatusColor] = useState<string>(undefined);
  const [locateStarted, setLocateStarted] = useState<boolean>(false);
  const [statusModel, setStatusModel] = useState<DeviceStatusViewModel>(undefined);

  const fetchStatus = (callback?: () => void) => {
    apiClientWithoutHandler.genericController.get(APIPath.status.get).then((res: any) => {
      if (res.ok) {
        const result: DeviceStatusViewModel = mapDeviceStatusViewModel(res.data);
        setStatusModel(result);
      }
      if (callback) {
        callback();
      }
    });
  };

  const fetchLeds = (callback?: () => void) => {
    // Use RAW controller so that we are not redirected to login by the controller during a reboot. Let the status API catch the 401
    apiClientWithoutHandler.rawController.get(APIPath.leds).then((res: any) => {
      if (res.ok) {
        const result: LocateStatus = mapLedStatus(res.data);
        setStatusColor(result.statusColor);
        setBlinkingWillStopIn(result.blinkingWillStopIn);
        setLocateStarted(!isNil(result.blinkingWillStopIn) && result.blinkingWillStopIn > 0);
      }
      if (callback) {
        callback();
      }
    });
  };

  useEffect(() => {
    let endrun = false;
    let timer: any; // adaptive fetch loop: it only trigger the next timeout once the previous one completed.

    fetchStatus();

    if (sessionStore.isUser()) {
      return () => {
        return;
      };
    }

    const triggerRefresh = () => {
      fetchLeds(() => {
        if (!endrun && locateStarted) {
          timer = setTimeout(triggerRefresh, FETCH_LOCATE_INTERVAL);
        }
      });
    };

    timer = setTimeout(() => {
      triggerRefresh();
    }, FETCH_LOCATE_INTERVAL);

    return () => {
      // cleanup
      endrun = true;
      clearTimeout(timer);
    };
  }, [locateStarted]);

  // the reboot handling for the reboot button
  const childRef = useRef<IsSavedRef>(null);
  const onRebootClicked = (): void => {
    childRef.current.show();
  };

  return (
    <SettingsTabContent name={t('settings.system.title')}>
      <StatusButtons
        locateStatusColor={statusColor}
        locating={locateStarted}
        remainingTime={blinkingWillStopIn}
        onReboot={onRebootClicked}
        onLocate={showLocateModal}
        onSnapshot={() => {
          openNewWindow(APIPath.status.snapshot, 'mk-snapshot');
        }}
      />

      <DeviceName />
      <DeviceStatus model={statusModel} />
      <UserSettings />
      <RebootIsSaved ref={childRef} />

      {locateModalShown && (
        <LocateModal
          started={locateStarted}
          blinkingWillStopIn={blinkingWillStopIn}
          onStart={(): void => {
            fetchLeds(null);
          }}
          onStop={(): void => {
            setLocateStarted(false);
            hideLocateModal();
          }}
          onClose={hideLocateModal}
          onDone={(): void => {
            setLocateStarted(false);
          }}
        />
      )}
    </SettingsTabContent>
  );
};
