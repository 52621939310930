import { from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { apiClient } from 'src/js/api';
import { rxTaskFactory } from 'src/js/helper/mobx-tasks';
import { NetworkRequest } from 'src/js/model/api/request/network-request';
import { NetworkResponse } from 'src/js/model/api/response/network-response';
import { mapNetworkToNetworkViewModel } from 'src/js/pages/settings/network/network-mapper';
import { NetworkViewModel, UpdateNetworkViewModel } from 'src/js/pages/settings/network/network-view-model';
import { PresetSavedResponse } from 'src/js/presets/preset-models';
import { NetworkTaskGenerator } from 'src/js/util/global-type';

import { isCurrentPresetSaved } from '../presets/preset-store';

export const createGetNetworkTask: NetworkTaskGenerator<NetworkResponse, NetworkViewModel> = () =>
  rxTaskFactory(() =>
    apiClient.settingsNetworkController
      .getNetwork()
      .pipe(map<NetworkResponse, NetworkViewModel>((response) => mapNetworkToNetworkViewModel(response))),
  );

export const createUpdateNetworkTask: NetworkTaskGenerator<
  NetworkResponse & PresetSavedResponse,
  UpdateNetworkViewModel
> = () =>
  rxTaskFactory((request: NetworkRequest) =>
    apiClient.settingsNetworkController.putNetwork(request).pipe(
      mergeMap((networkResponse: NetworkResponse) =>
        from(isCurrentPresetSaved()).pipe(
          map<any, UpdateNetworkViewModel>((isSavedResponse) => ({
            isSaved: isSavedResponse.data.isSaved,
            ...mapNetworkToNetworkViewModel(networkResponse),
          })),
        ),
      ),
    ),
  );
