import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StateButton } from 'src/js/component/base/state-button';
import { useErrorReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { createForceUnpairEmsTask } from 'src/js/task/settings-services-tasks';

interface Props {
  onOperationStarted?: VoidFunction;
}
export const CancelPairingButton: React.FunctionComponent<Props> = ({ onOperationStarted, ...rest }) => {
  const { t } = useTranslation();
  const forceUnpairEmsTask = useTask(createForceUnpairEmsTask());
  const [cancelPairingStarted, setCancelPairingStarted] = useState<boolean>(false);

  const handlePairingCompleted = (): void => {
    setCancelPairingStarted(false);
  };

  useEffect((): VoidFunction => {
    return handlePairingCompleted;
  }, []);

  useErrorReaction(forceUnpairEmsTask, handlePairingCompleted);

  return (
    <StateButton
      onClick={(): void => {
        setCancelPairingStarted(true);
        forceUnpairEmsTask();
        onOperationStarted?.();
      }}
      isLoading={cancelPairingStarted}
      {...rest}
    >
      {t('settings.services.section.ems.button.cancelPairing')}
    </StateButton>
  );
};
