import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { log } from 'src/js/service/logger';

export interface FormAggregatorClientInterface {
  register: (id: string) => void;
  unregister: (id: string) => void;
  invalid: (id: string) => void;
  valid: (id: string) => void;
  debug: () => void;
}

interface MultipleFormAggregatorProps {
  className?: string;
}

export interface MultipleFormAggregatorRef extends FormAggregatorClientInterface {
  areAllClientsValid: () => boolean;
}

//let count = 0;
export const MultipleFormAggregator = forwardRef(
  (props: MultipleFormAggregatorProps, ref: Ref<MultipleFormAggregatorRef>) => {
    const { className, ...rest } = props;
    const [clientsMap] = useState<Map<string, boolean>>(new Map());
    //const clientsMap = new Map(); using this line instead of the previous will break the unit test

    //console.log('MultipleFormAggregatorRef render', clientsMap.size, ++count);

    const reprocess = () => {
      let isValid = true;
      clientsMap.forEach((value, _key) => {
        if (value === false) {
          isValid = false;
        }
      });
      //console.log('isValid?', isValid);
      return isValid;
    };

    const debug = () => {
      clientsMap.forEach((value, key) => {
        /* eslint-disable no-console */
        console.log('client', key, value);
        /* eslint-enable no-console */
      });
    };

    const areAllClientsValid = () => {
      const isValid = reprocess();
      //console.log('areAllClientsValid', isValid);
      return isValid;
    };

    const register = (id: string) => {
      if (clientsMap.has(id)) {
        log.warn('client already exists, check your code');
        return; // already existing
      }
      clientsMap.set(id, true);
      // dont need to reprocess, all are initially valid
    };

    const unregister = (id: string) => {
      if (!clientsMap.has(id)) {
        log.warn('client already unregistered or was never added, check your code');
        return; // error
      }
      clientsMap.delete(id);
    };

    // set client invalid
    const invalid = (id: string) => {
      //console.log('client is invalid', id);
      if (!clientsMap.has(id)) {
        //log.warn('client not registered', id);
        return; // it happens when Formik is render but the useEffect for registering has not been called yet.
      }
      clientsMap.set(id, false);
    };

    // set client invalid
    const valid = (id: string) => {
      if (!clientsMap.has(id)) {
        //log.warn('client not registered', id);
        return; // error
      }
      clientsMap.set(id, true);
    };

    useImperativeHandle(ref, () => ({
      invalid,
      valid,
      register,
      unregister,
      areAllClientsValid,
      debug,
    }));

    return <div className={className} {...rest}></div>;
  },
);

MultipleFormAggregator.displayName = 'MultipleFormAggregator';
