import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { DateTimeResponse } from 'src/js/model/api/response/date-time-response';
import { ipOrHostname } from 'src/js/validator/validator';
import * as yup from 'yup';

import { timezoneNames } from './timezone-helper';

export interface DateTime {
  timezone: string;
  ntp: boolean;
  server: string;
  chronydRunning: boolean;
  time: Moment;
}

export const createDefaultDateTime = (): DateTime => {
  return {
    timezone: '',
    ntp: false,
    server: '',
    chronydRunning: false,
    time: undefined,
  };
};

export const mapDateTime = (response: DateTimeResponse): DateTime => ({
  timezone: response.timezone,
  ntp: response.ntp,
  server: response.server,
  chronydRunning: response.chronydRunning,
  time: moment.tz(moment.unix(response.time), response.timezone),
});

/*
 * Schema for the form
 */
export const dateAndTimeSchema = (t: TFunction) =>
  yup.object().shape({
    timezone: yup.mixed().oneOf(
      Array.from(timezoneNames).map((entry) => entry[0]),
      t('validation.timezone'),
    ),
    server: yup.string().when('ntp', {
      is: true,
      then: ipOrHostname(t).required(t('validation.required')),
    }),
    time: yup.date().when('ntp', {
      is: false,
      then: yup.date().required(t('validation.required')),
    }),
  });
