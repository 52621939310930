import { Analytics } from '@hai/ui-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import systemInfo, { updateSystemInfo } from 'src/js/data/systemInfo';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useWatchObject } from 'src/js/store/use-watch';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { useLocalStorage } from 'usehooks-ts';

export const UserAnalytics: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [showUserAnalytics, setShowUserAnalytics] = useLocalStorage(`${constant.lStorage.showUserAnalytics}`, true);

  const [shown, show, hide] = useModalControls();

  const [info, setInfo] = useState(systemInfo);
  useEffect(() => {
    if (!isNilOrEmpty(info.analytics)) {
      if (info.analytics === 'undecided') {
        if (showUserAnalytics) {
          show();
        }
      } else {
        setShowUserAnalytics(false);
      }
    }
  }, [info]);
  useWatchObject(systemInfo, setInfo);

  const handleAnalyticsClose = () => {
    setShowUserAnalytics(false);
    hide();
  };

  const handleAnalyticsChange = (accepted: boolean) => {
    updateSystemInfo({ ...systemInfo, analytics: accepted ? 'accepted' : 'declined' });
  };

  return (
    <Analytics
      show={shown}
      onClose={handleAnalyticsClose}
      onChange={handleAnalyticsChange}
      type="modal"
      localeInfo={{
        modalMainText: t('userAnalytics.modalMainText'),
        modalMoreInformationText: t('userAnalytics.modalMoreInformationText'),
        modalContinueText: t('userAnalytics.modalContinueText'),
        modalDeclineText: t('userAnalytics.modalDeclineText'),
        modalTitle: t('userAnalytics.modalTitle'),
        privacyPolicyText: t('userAnalytics.privacyPolicyText'),
      }}
    />
  );
};
