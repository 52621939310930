import { TFunction } from 'i18next';
import { append, isNil } from 'ramda';
import { filterUndefinedRows } from 'src/js/pages/statistic/statistic-mapper';
import { StatisticRowViewModel, StatisticSectionViewModel } from 'src/js/pages/statistic/statistic-view-model';
import { decoderName } from 'src/js/pages/streaming/decoder/decoder-store';
import { isNilOrEmpty, toTitleCase } from 'src/js/util/global-util';
import { formatLast } from '../../dashboard/decoder-item-statistics';

import { formatUptime, translateStreamEncapsulation, translateStreamState } from './stream-helper';
import {
  audioLanguages,
  FecRTP,
  SrtPathRedundancyMode,
  StreamEncapsulation,
  StreamSourceType,
  StreamViewModel,
} from './stream-models';
import { streamListStreamName } from './stream-view-model-mapper';

export const deriveAudioLanguage = (code: string) => {
  const exists = Object.prototype.hasOwnProperty.call(audioLanguages, code);
  if (exists) {
    return `${audioLanguages[code]} (${code})`;
  }
  return undefined;
};

export const mapStreamToStatistics = (
  stream: StreamViewModel,
  fullPage: boolean,
  t: TFunction,
): StatisticSectionViewModel[] => {
  const isSRT = !isNil(stream.stats.srt);

  const statRows: StatisticRowViewModel[] = [
    {
      name: t('statistics.stream.rows.uptime'),
      value: formatUptime(stream.stats.uptime),
      shortlisted: true,
    },
    {
      name: t('statistics.decoder.rows.lastReset'),
      value: stream.stats.lastReset,
      shortlisted: true,
    },
    {
      name: t('statistics.stream.rows.encapsulation'),
      value: translateStreamEncapsulation(stream.encapsulation, t),
    },
    {
      name: t('statistics.stream.rows.srtMode'),
      value: t(`stream.enums.srtMode.${stream.srtMode}`),
      when: stream.encapsulation === StreamEncapsulation.SRT,
    },
    {
      name: t('statistics.stream.rows.detectedRedundancyMode'),
      value:
        stream.stats.srt?.detectedRedundancyMode === SrtPathRedundancyMode.NONE ||
        stream.stats.srt?.detectedRedundancyMode === undefined
          ? undefined
          : t(`stream.enums.srtRedundancyOptions.${stream.stats.srt?.detectedRedundancyMode}`),
      shortlisted: true,
    },
    {
      name: t('statistics.stream.rows.bitrate'),
      value: stream.stats.bitrate,
      shortlisted: true,
      when: stream.stats.srt?.path2 === undefined,
    },
    {
      name: t('statistics.stream.rows.sourceAddress'),
      value: stream.stats.sourceAddress,
      when: stream.stats.srt?.path2 === undefined,
    },
    {
      name: t('statistics.stream.rows.connections'),
      value: stream.stats.connections,
    },
    {
      name: t('statistics.stream.rows.receivedPackets'),
      value: stream.stats.receivedPackets,
      shortlisted: true,
    },
    {
      name: t('statistics.stream.rows.receivedBytes'),
      value: stream.stats.receivedBytes,
      shortlisted: true,
      when: (!fullPage && isSRT) || fullPage,
    },
    {
      name: t('statistics.stream.rows.outputBytes'),
      value: stream.stats.outputBytes,
      shortlisted: false,
      when: (!fullPage && isSRT) || fullPage,
    },
    {
      name: t('statistics.stream.rows.droppedBytes'),
      value: stream.stats.droppedBytes,
      shortlisted: true,
    },
    {
      name: t('statistics.stream.rows.lastPidDropped'),
      value: stream.stats.lastPidDropped,
      shortlisted: true,
    },
    // {
    //   name: t('statistics.stream.rows.droppedPackets'),
    //   value: stream.stats.srt?.droppedPackets,
    //   shortlisted: true,
    //   when: !fullPage && !isSRT,
    // },
    // {
    //   name: t('statistics.stream.rows.lostPackets'),
    //   value: stream.stats.srt?.lostPackets,
    //   shortlisted: true,
    //   when: !fullPage && !isSRT,
    // },
    {
      name: t('statistics.stream.rows.lastReceived'),
      value: stream.stats.lastReceived,
    },
    {
      name: t('statistics.stream.rows.rtt'),
      value: stream.stats.srt?.rtt,
      shortlisted: true,
      when: !fullPage && !isSRT,
    },
    // {
    //   name: t('statistics.stream.rows.lostPackets'),
    //   value: stream.stats.lostPackets,
    // },
    {
      name: t('statistics.stream.rows.programNumber'),
      value: stream.stats.programNumber,
    },
    {
      name: t('statistics.stream.rows.pcrPid'),
      value: stream.stats.pcrPid,
    },
    {
      name: t('statistics.stream.rows.streamSummary'),
      value: stream.stats.streamSummary,
    },
    {
      name: t('statistics.stream.rows.filteredOutPids'),
      value: stream.stats.filteredOutPids,
      when: stream.stats.filteredOutPids != null && stream.stats.filteredOutPids !== '',
    },
  ];

  let stats: StatisticSectionViewModel[] = [
    {
      title: fullPage ? t('statistics.stream.section.stream') : t('statistics.header.statistics'),
      rows: [
        {
          name: t('statistics.stream.rows.id'),
          value: stream.id.toString(),
        },
        {
          name: t('statistics.stream.rows.name'),
          value: streamListStreamName(t, stream.name),
          when: fullPage,
          mask: true,
        },
        {
          name: t('general.status'),
          value: toTitleCase(translateStreamState(stream.stats.state, t)),
          shortlisted: true,
        },
        {
          name: t('statistics.stream.rows.decoder'),
          value: stream.decoderId === -1 ? t('statistics.stream.none') : decoderName(stream.decoderId),
        },
      ],
    },
  ];

  if (!fullPage) {
    stats[0].rows = stats[0].rows.concat(statRows);
  }
  if (stream.encapsulation === StreamEncapsulation.SRT && isSRT) {
    stats = append(
      {
        title: `${t('statistics.stream.section.srt')} (${t('statistics.stream.srtVersionPrefix')} ${
          stream.stats.srt.localVersion
        })`,
        twoColumns: !isNil(stream.stats.srt.path2),
        className: 'srt',
        span: 3,
        rows: [
          {
            name: t('statistics.path'),
            value: t('statistics.path1'),
            value2: t('statistics.path2'),
            className: 'light',
            when: !isNil(stream.stats.srt.path2),
          },
          {
            name: t('statistics.stream.rows.pathState'),
            value: stream.stats.srt.state !== '' ? toTitleCase(stream.stats.srt.state) : undefined,
            value2: stream.stats.srt.path2?.state !== '' ? toTitleCase(stream.stats.srt.path2?.state) : undefined,
          },
          {
            name: t('statistics.stream.rows.pathName'),
            value: stream.srtRedundancyPath1Name,
            value2: stream.srtRedundancyPath2Name,
            when: stream.srtRedundancyPath1Name !== '' || stream.srtRedundancyPath2Name !== '',
          },
          {
            name: t('statistics.stream.rows.peerVersion'),
            value: stream.stats.srt.peerVersion,
            value2: stream.stats.srt.path2?.peerVersion,
          },
          {
            name: t('statistics.stream.rows.rxStreamID'),
            value: stream.stats.srt.rxStreamID,
            value2: stream.stats.srt.path2?.rxStreamID,
            when: !isNilOrEmpty(stream.stats.srt.rxStreamID) || !isNilOrEmpty(stream.stats.srt.path2?.rxStreamID),
          },
          {
            name: t('statistics.stream.rows.peerEndpoint'),
            value: stream.stats.srt.peerEndpoint,
            value2: stream.stats.srt.path2?.peerEndpoint,
          },
          {
            name: t('statistics.stream.rows.localEndpoint'),
            value: stream.stats.srt.localEndpoint,
            value2: stream.stats.srt.path2?.localEndpoint,
          },
          {
            name: t('statistics.stream.rows.peerAddress'),
            value: stream.stats.srt.peerAddress,
            value2: stream.stats.srt.path2?.peerAddress,
          },
          {
            name: t('statistics.stream.rows.peerPort'),
            value: stream.stats.srt.peerPort ? stream.stats.srt.peerPort?.toString() : undefined,
            value2: stream.stats.srt.path2?.peerPort ? stream.stats.srt.path2?.peerPort.toString() : undefined,
          },
          {
            name: t('statistics.stream.rows.localAddress'),
            value: stream.stats.srt.localAddress,
            value2: stream.stats.srt.path2?.localAddress,
          },
          {
            name: t('statistics.stream.rows.localPort'),
            value: stream.stats.srt.localPort,
            value2: stream.stats.srt.path2?.localPort,
          },
          {
            name: t('statistics.stream.rows.encryption'),
            value: stream.stats.srt.encryption,
            value2: stream.stats.srt.path2?.encryption,
          },
          {
            name: t('statistics.stream.rows.decryption'),
            value: stream.stats.srt.decryptState,
            value2: stream.stats.srt.path2?.decryptState,
          },
          {
            name: t('statistics.stream.rows.keyLength'),
            value: stream.stats.srt.keyLength,
            value2: stream.stats.srt.path2?.keyLength,
          },
          {
            name: t('statistics.stream.rows.authentication'),
            value: stream.stats.srt.authentication,
            value2: stream.stats.srt.path2?.authentication,
          },
          {
            name: t('statistics.stream.rows.reconnections'),
            value: stream.stats.srt.reconnections,
            value2: stream.stats.srt.path2?.reconnections,
          },
          {
            name: t('statistics.stream.rows.rejectedLastTime'),
            value: stream.stats.srt.rejectedLastTime,
          },
          {
            name: t('statistics.stream.rows.rejectedReason'),
            value: stream.stats.srt.rejectedReason,
          },
          {
            name: t('statistics.stream.rows.rejectedStreamId'),
            value: stream.stats.srt.rejectedStreamId,
          },
          {
            name: t('statistics.stream.rows.localStreamID'),
            value: stream.srtAccessPublishingID,
            when:
              stream.stats.srt.rejectedReason != null &&
              stream.stats.srt.rejectedReason?.includes?.('PublishingID') &&
              stream.srtAccessPublishingID?.length > 0,
          },
          {
            name: t('statistics.stream.rows.lostPackets'),
            value: stream.stats.srt.lostPackets,
            value2: stream.stats.srt.path2?.lostPackets,
          },
          {
            name: t('statistics.stream.rows.recoveredPackets'),
            value: stream.stats.srt.recoveredPackets,
            value2: stream.stats.srt.path2?.recoveredPackets,
          },
          {
            name: t('statistics.stream.rows.skippedPackets'),
            value: stream.stats.srt.droppedPackets,
            value2: stream.stats.srt.path2?.droppedPackets,
          },
          {
            name: t('statistics.stream.rows.sentAcks'),
            value: stream.stats.srt.sentAcks,
            value2: stream.stats.srt.path2?.sentAcks,
          },
          {
            name: t('statistics.stream.rows.sentNaks'),
            value: stream.stats.srt.sentNaks,
            value2: stream.stats.srt.path2?.sentNaks,
          },
          {
            name: t('statistics.stream.rows.linkBandwidth'),
            value: stream.stats.srt.pathMaxBandwidth,
            value2: stream.stats.srt.path2?.pathMaxBandwidth,
          },
          {
            name: t('statistics.stream.rows.rtt'),
            value: stream.stats.srt.rtt.toString(),
            value2: stream.stats.srt.path2?.rtt.toString(),
          },
          {
            name: t('statistics.stream.rows.buffer'),
            value: stream.stats.srt.buffer.toString() + ' ms',
            value2: stream.stats.srt.path2?.buffer.toString() + ' ms',
          },
          {
            name: t('statistics.stream.rows.latency'),
            value: stream.stats.srt.latency.toString() + ' ms',
            value2: stream.stats.srt.path2?.latency.toString() + ' ms',
          },
        ],
      },
      stats,
    );
  }

  stats = append(
    {
      title: t('statistics.stream.section.errors'),
      rows: [
        {
          name: t('statistics.stream.rows.corruptedFrames'),
          value: stream.stats.corruptedFrames?.toString(),
        },
        {
          name: t('statistics.stream.rows.restarts'),
          value: stream.stats.restarts?.toString(),
        },
      ],
    },
    stats,
  );

  if (fullPage) {
    stats = append(
      {
        title: t('statistics.header.statistics'),
        rows: statRows,
        span: 2,
      },
      stats,
    );
  }

  // all beside audios
  if (stream.stats.sources) {
    for (let i = 0; i < stream.stats.sources.length; i++) {
      const pSource = stream.stats.sources[i];
      if (pSource.type !== StreamSourceType.AUDIO) {
        stats = append(
          {
            title: pSource.name,
            rows: [
              {
                name: t('statistics.stream.rows.sourceProgramId'),
                value: pSource.programId?.toString(),
              },
              {
                name: t('statistics.stream.rows.sourceCompression'),
                value: pSource.compression,
              },
              {
                name: t('statistics.stream.rows.sourceBitrate'),
                value: pSource.bitrate,
              },
              {
                name: t('statistics.stream.rows.sourceReceivedPackets'),
                value: formatLast(pSource.receivedPackets),
                formatString: true,
              },
              {
                name: t('statistics.stream.rows.sourceReceivedBytes'),
                value: formatLast(pSource.receivedBytes),
                formatString: true,
              },
              {
                name: t('statistics.stream.rows.sourcePts'),
                value: pSource.PTS,
              },
            ],
          },
          stats,
        );
      }
    }
  }

  if (stream.fecRtp === FecRTP.MPEG_PRO_FEC) {
    stats = append(
      {
        title: t('statistics.stream.section.fec'),
        rows: [
          isNil(stream.stats.fecLevel)
            ? {
                name: t('statistics.stream.rows.state'),
                value: `${t('statistics.general.notRunning')}`,
              }
            : {
                name: t('statistics.stream.rows.level'),
                value: stream.stats.fecLevel,
              },
          {
            name: t('statistics.stream.rows.numberOfColumns'),
            value: stream.stats.fecColumns,
          },
          {
            name: t('statistics.stream.rows.numberOfRows'),
            value: stream.stats.fecRows,
          },
          {
            name: t('statistics.stream.rows.blockAligned'),
            value: stream.stats.fecBlockAligned,
          },
          {
            name: t('statistics.stream.rows.recoveredPackets'),
            value: stream.stats.recoveredPackets,
          },
        ],
      },
      stats,
    );
  }

  // audio only
  if (stream.stats.sources) {
    let audioCount = 0;
    for (let i = 0; i < stream.stats.sources.length; i++) {
      const pSource = stream.stats.sources[i];
      if (pSource.type === StreamSourceType.AUDIO) {
        stats = append(
          {
            title: pSource.name,
            sectionBreak: audioCount++ == 0,
            sectionBreakTitle: t('statistics.stream.audioTitle'),
            rows: [
              {
                name: t('statistics.stream.rows.sourceProgramId'),
                value: pSource.programId?.toString(),
              },
              {
                name: t('statistics.stream.rows.sourceLanguage'),
                value: deriveAudioLanguage(pSource.language) ?? t('general.none'),
                when: pSource.type === StreamSourceType.AUDIO,
              },
              {
                name: t('statistics.stream.rows.sourceCompression'),
                value: pSource.compression,
              },
              {
                name: t('statistics.stream.rows.sourceBitrate'),
                value: pSource.bitrate,
              },
              {
                name: t('statistics.stream.rows.sourceReceivedPackets'),
                value: formatLast(pSource.receivedPackets),
                formatString: true,
              },
              {
                name: t('statistics.stream.rows.sourceReceivedBytes'),
                value: formatLast(pSource.receivedBytes),
                formatString: true,
              },
              {
                name: t('statistics.stream.rows.sourcePts'),
                value: pSource.PTS,
              },
            ],
          },
          stats,
        );
      }
    }
  }

  // remove undefined rows
  return filterUndefinedRows(stats);
};
