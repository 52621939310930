import { Button, FileUpload } from '@hai/ui-react';
import { IIconProps } from '@hai/ui-react/dist/icons/Icon';
import { Accept, ButtonStateType, FileUploadStages, IUploadState } from '@hai/ui-react/dist/types';
import { isEmpty, isNil } from 'ramda';
import React, { FormEvent, useEffect, useState } from 'react';
import { t } from 'src/js/i18n';

interface Props {
  accept?: Accept;
  browseMessage?: string;
  cancelBtnDisabledNoFile?: boolean;
  conjunction?: string;
  errorMessage?: string;
  file?: File | File[];
  hint?: string;
  icon?: string | IIconProps | React.ReactNode;
  mainMessage?: string;
  /** multiple files allowed */
  multiple?: boolean;
  onCancel?: VoidFunction;
  onFileChange?: (files: File[]) => void;
  /** If multiple == true use handler with File[]:
   * onUpload = (files: File[]) => ...
   * If multiple == false use handler with File:
   * onUpload = (files: File) => ...
   * */
  onUpload?: (file: any | File | File[], event?: FormEvent<HTMLFormElement>) => void;
  onUploadError?: (newuploadState: IUploadState) => void;
  successMessage?: string;
  uploadBtnText?: string;
  uploadBtnDisabled?: boolean;
  uploadErrorMessage?: string;
  uploadState?: IUploadState;
  uploadBtnState?: ButtonStateType;
  onKeyUp?: any;
}

export const UploadZone: React.FunctionComponent<Props> = ({
  accept,
  browseMessage = t('upload.browseMessage'),
  cancelBtnDisabledNoFile = false,
  conjunction = t('upload.or'),
  errorMessage = t('upload.incorrectFileType'),
  file,
  hint,
  icon,
  mainMessage = t('upload.mainMessage'),
  multiple = false,
  onCancel,
  onFileChange,
  onUpload,
  onUploadError,
  successMessage = t('upload.uploadSuccessMessage'),
  uploadBtnText = t('general.upload'),
  uploadBtnDisabled = false,
  uploadErrorMessage = t('upload.uploadErrorMessage'),
  uploadState,
  uploadBtnState,
  onKeyUp,
}) => {
  const [localFile, setLocalFile] = useState<File | File[]>(file);
  const localUploadState: IUploadState = {
    stage: FileUploadStages.NONE,
    progress: 0,
  };
  useEffect(() => {
    setLocalFile(file);
  }, [file]);

  const handleLocalFileChange = (files: File[]) => {
    if (multiple) {
      setLocalFile(isEmpty(files) ? undefined : files);
    } else {
      setLocalFile(isEmpty(files) ? undefined : files[0]);
    }
  };

  const handleCancel = () => {
    setLocalFile(undefined);
    onCancel?.();
  };

  return (
    <>
      <FileUpload
        accept={accept}
        onKeyUp={onKeyUp}
        dropzoneMessage={{
          browseMessage: browseMessage,
          mainMessage: mainMessage,
          conjunction: conjunction,
        }}
        errorMessage={errorMessage}
        files={
          onFileChange
            ? file
              ? Array.isArray(file)
                ? file
                : [file]
              : []
            : localFile
            ? Array.isArray(localFile)
              ? localFile
              : [localFile]
            : []
        }
        hint={hint}
        icon={icon}
        multiple={multiple}
        onFileChange={onFileChange || handleLocalFileChange}
        successMessage={successMessage}
        uploadState={{
          ...(uploadState || localUploadState),
          resetMessage: t('upload.tryAgain'),
          uploadErrorMessage: uploadErrorMessage,
        }}
        onUploadError={onUploadError}
      />
      {onUpload && (
        <div className="d-flex hai-mt-6">
          <div className="ml-auto">
            <Button
              className="hai-mr-6"
              variant="secondary"
              onClick={handleCancel}
              disabled={cancelBtnDisabledNoFile ? isNil(localFile) : false}
            >
              {t('general.cancel')}
            </Button>
            <Button
              id="id-button-upload"
              variant="primary"
              onClick={(e: FormEvent<HTMLFormElement>) => onUpload(localFile, e)}
              disabled={uploadBtnDisabled || isNil(localFile)}
              state={uploadBtnState}
              onKeyUp={onKeyUp}
            >
              {uploadBtnText}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
