import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  onCancel?: VoidFunction;
  onAccept?: VoidFunction;
}

export const StillImageOverwriteModal: React.FunctionComponent<Props> = ({ name, onCancel, onAccept }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      headline={t('settings.stillImages.overwrite.title')}
      bodyText={t('settings.stillImages.overwrite.bodyText', { name })}
      onClose={onCancel}
      show={true}
      buttons={[{ label: t('general.cancel') }, { label: t('general.ok'), variant: 'primary', onClick: onAccept }]}
    />
  );
};
