import { Button, Dialog, Form, FormContext } from '@hai/ui-react';
import { FormikProps } from 'formik';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTextfield } from 'src/js/component/base/form/form-textfield';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { validateFormContext } from 'src/js/validator/validator';

import { StreamViewModel, latencyValidationSchema } from './stream-models';

interface Props {
  streams: StreamViewModel[];
  onSubmit?: (stream: StreamViewModel) => void;
  onCancel?: VoidFunction;
}

export const StreamSetLatencyModal: React.FunctionComponent<Props> = ({ streams, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const formRef = useRef(null);

  const latencyInputRef = useRef(undefined);
  useEffect((): void => {
    if (!isNil(latencyInputRef.current)) {
      setTimeout(() => {
        latencyInputRef.current.select();
      }, 300);
    }
  }, [latencyInputRef]);

  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (streams.length > 0) {
      setData({
        latency: streams[0].latency,
      });
    }
  }, [streams]);

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') {
      const button = document.getElementById('stream-set-buffering-form-submit');
      if (!isNilOrEmpty(button)) {
        e.preventDefault();
        button.click();
      }
    }
  };

  const content = () => {
    return (
      <Form
        initialValues={data}
        defaultValidation={true}
        handleSubmit={onSubmit}
        restValidationProps={{
          enableReinitialize: true,
          validationSchema: latencyValidationSchema(t),
          innerRef: formRef,
        }}
      >
        <FormContext.Consumer>
          {(formContext: FormikProps<StreamViewModel>) => {
            return (
              <form className="HaiForm" onKeyDown={handleOnKeyDown}>
                <FormTextfield
                  name="latency"
                  title={t('stream.fields.latency')}
                  hint={t('stream.fields.latencyExplain')}
                  type="number"
                  inputRef={latencyInputRef}
                  required
                />

                <div className="d-flex justify-content-end hai-mt-8">
                  <Button onClick={onCancel}>{t('general.cancel')}</Button>
                  <Button
                    onClick={() => validateFormContext(formContext, t)}
                    variant="primary"
                    id="stream-set-buffering-form-submit"
                  >
                    {t('general.apply')}
                  </Button>
                </div>
              </form>
            );
          }}
        </FormContext.Consumer>
      </Form>
    );
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('stream.setLatency')}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="Settings"
      content={content()}
    />
  );
};
