import { TFunction } from 'i18next';
import { isNil } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { filterUndefinedRows } from 'src/js/pages/statistic/statistic-mapper';
import { StatisticTable } from 'src/js/pages/statistic/statistic-table';
import { StatisticSectionViewModel } from 'src/js/pages/statistic/statistic-view-model';
import {
  DecoderViewModel,
  decoderBufferingModeToString,
  multiSyncWarning,
} from 'src/js/pages/streaming/decoder/decoder-model';
import { formatUptime } from 'src/js/pages/streaming/stream/stream-helper';
import { StreamViewModel } from 'src/js/pages/streaming/stream/stream-models';
import { isNilOrEmpty } from 'src/js/util/global-util';

export const formatLast = (value: string) => {
  if (isNilOrEmpty(value)) {
    return [undefined, undefined];
  }

  const valueArr = value.split(' (');
  if (valueArr.length === 2) {
    valueArr[1] = valueArr[1].split(':')?.[1].split(')')?.[0].trim();
    const splitTime = valueArr[1].split(' ');
    if (splitTime.length === 2) {
      // Split d/h/m/s
      splitTime[0] = splitTime[0].replace('d', 'd ').replace('h', 'h ').replace('m', 'm ');
      valueArr[1] = splitTime.join(' ');
    }
    return valueArr;
  } else {
    return value;
  }
};

export const mapDashboardDecoderToStatistics = (
  decoder: DecoderViewModel,
  stream: StreamViewModel,
  t: TFunction,
): StatisticSectionViewModel[] => {
  let reconnections = stream?.stats?.srt?.reconnections;
  if (stream?.stats?.srt?.path2?.reconnections) {
    reconnections += ` / ${stream.stats?.srt?.path2?.reconnections}`;
  }
  let lostPackets = stream?.stats?.srt?.lostPackets;
  if (stream?.stats?.srt?.path2?.lostPackets) {
    lostPackets += ` / ${stream.stats?.srt?.path2?.lostPackets}`;
  }
  let rtt = stream?.stats?.srt?.rtt.toString();
  if (stream?.stats?.srt?.path2?.rtt) {
    rtt += ` / ${stream.stats?.srt?.path2?.rtt.toString()}`;
  }

  const sections: StatisticSectionViewModel[] = [
    {
      rows: [
        {
          name: t('statistics.decoder.rows.uptime'),
          value: formatUptime(decoder.stats.uptime),
        },
        {
          name: t('statistics.decoder.rows.videoInputResolution'),
          value: decoder.stats.videoInputResolution || '-',
        },
        {
          name: t('statistics.decoder.rows.bufferingMode'),
          value: decoderBufferingModeToString(decoder.stats.bufferingMode, t),
          warning: multiSyncWarning(decoder.stats, t, true),
        },
        {
          name: t('statistics.stream.rows.bitrate'),
          value: stream?.stats?.bitrate,
          when: isNil(stream?.stats?.srt?.path2),
        },
        {
          name: t('statistics.stream.rows.sourceBitrate'),
          value: stream?.stats?.sources?.[0]?.bitrate,
          when: !isNil(stream?.stats?.srt?.path2),
        },
        {
          name: t('statistics.stream.rows.sourceVideoCompression'),
          value: stream?.stats?.sources?.[0]?.compression || '-',
        },
        {
          name: t('statistics.decoder.rows.audioCompression'),
          value: decoder.stats?.audioPairs?.[0]?.compression || '-',
        },
      ],
    },
    {
      rows: [
        {
          name: t('statistics.decoder.rows.displayedOutputFrames'),
          value: formatLast(decoder.stats.displayedOutputFrames)[0],
        },
        {
          name: t('statistics.decoder.rows.skippedOutputFrames'),
          value: formatLast(decoder.stats.skippedOutputFrames)[0],
        },
        {
          name: t('statistics.decoder.rows.lastSkippedOutputFrames'),
          value: formatLast(decoder.stats.skippedOutputFrames)[1],
        },
        {
          name: t('statistics.decoder.rows.replayedOutputFrames'),
          value: formatLast(decoder.stats.replayedOutputFrames)[0],
        },
        {
          name: t('statistics.decoder.rows.lastReplayedOutputFrames'),
          value: formatLast(decoder.stats.replayedOutputFrames)[1],
        },
        {
          name: t('statistics.stream.rows.lastReceived'),
          value: stream?.stats?.lastReceived,
        },
      ],
    },
    {
      rows: [
        {
          name: t('statistics.stream.rows.connections'),
          value: formatLast(stream?.stats?.connections)[0],
        },
        {
          name: t('statistics.stream.rows.lastConnections'),
          value: formatLast(stream?.stats?.connections)[1],
        },
        {
          name: t('statistics.stream.rows.reconnections'),
          value: reconnections,
        },
        {
          name: t('statistics.stream.rows.lostPackets'),
          value: lostPackets,
        },
        {
          name: t('statistics.stream.rows.rtt'),
          value: rtt,
        },
      ],
    },
  ];

  // remove undefined rows
  return filterUndefinedRows(sections);
};

interface Props {
  decoder?: DecoderViewModel;
  stream: StreamViewModel;
  uptime?: boolean;
}

export const DecoderItemStatistics: React.FunctionComponent<Props> = ({ decoder, stream }) => {
  const { t } = useTranslation();

  return <StatisticTable sections={mapDashboardDecoderToStatistics(decoder, stream, t)} formatString={true} />;
};
