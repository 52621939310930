import { Button, Dialog, Form, FormContext } from '@hai/ui-react';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'src/js/component/base/form/form-select';
import { FormTextfield } from 'src/js/component/base/form/form-textfield';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { validateFormContext } from 'src/js/validator/validator';

import { BufferingModeEnum, DecoderViewModel, MkRange, bufferingValidationSchema } from './decoder-model';
import { DecoderMultiSyncTable, combinedRange } from './decoder-mulitsync-table';

interface Props {
  decoders: DecoderViewModel[];
  onSubmit?: (decoder: DecoderViewModel) => void;
  onCancel?: VoidFunction;
}

export const DecoderSetBufferingModal: React.FunctionComponent<Props> = ({ decoders, onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const formRef = useRef(null);

  const mapDecoderToFormData = (decoders: DecoderViewModel[]) => {
    let bufferingMode = BufferingModeEnum.UNSET;
    if (decoders.filter((d) => d.bufferingMode === BufferingModeEnum.FIXED).length === decoders.length) {
      bufferingMode = BufferingModeEnum.FIXED;
    } else if (decoders.filter((d) => d.bufferingMode === BufferingModeEnum.MULTISYNC).length === decoders.length) {
      bufferingMode = BufferingModeEnum.MULTISYNC;
    } else if (decoders.filter((d) => d.bufferingMode === BufferingModeEnum.AUTOMATIC).length === decoders.length) {
      bufferingMode = BufferingModeEnum.AUTOMATIC;
    }

    return {
      bufferingMode: bufferingMode,
      unsetDelay: undefined as number | undefined,
      bufferingDelay: decoders[0].bufferingDelay,
      multisyncBufferingDelay: decoders[0].multisyncBufferingDelay,
      disabledValue: '',
      decoders: decoders,
    };
  };

  const [data, setData] = useState<any>(mapDecoderToFormData(decoders));
  useEffect(() => {
    if (decoders.length > 0) {
      setData(mapDecoderToFormData(decoders));
    }
  }, [decoders]);

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') {
      const button = document.getElementById('decoder-set-buffering-form-submit');
      if (!isNilOrEmpty(button)) {
        e.preventDefault();
        button.click();
      }
    }
  };

  const multiSyncValidationRange: MkRange = { min: 0, max: 10000 }; //FIXME range should be written at one place only or from REST API
  const fixedDelayValidationRange: MkRange = { min: 0, max: 3000 };
  //const bufferingModeUnset = [{ value: BufferingModeEnum.UNSET, label: t(`decoder.selectMode`) }];
  const bufferingModeOptions = (data.id >= 0 ? [] : []).concat([
    { value: BufferingModeEnum.FIXED, label: t(`decoder.bufferingModes.FIXED`) },
    { value: BufferingModeEnum.MULTISYNC, label: t(`decoder.bufferingModes.MULTISYNC`) },
    { value: BufferingModeEnum.AUTOMATIC, label: t(`decoder.bufferingModes.AUTOMATIC`) },
  ]);

  const content = () => {
    return (
      <Form
        initialValues={data}
        defaultValidation={true}
        handleSubmit={onSubmit}
        restValidationProps={{
          enableReinitialize: false,
          validationSchema: bufferingValidationSchema(multiSyncValidationRange, fixedDelayValidationRange, t),
          innerRef: formRef,
        }}
      >
        <FormContext.Consumer>
          {(formContext: FormikProps<DecoderViewModel>) => {
            const values = formContext.values;
            const name =
              values.bufferingMode === BufferingModeEnum.UNSET
                ? 'unsetDelay'
                : values.bufferingMode === BufferingModeEnum.FIXED
                ? 'bufferingDelay'
                : 'multisyncBufferingDelay';

            const hint =
              values.bufferingMode === BufferingModeEnum.MULTISYNC
                ? combinedRange(decoders)
                : values.bufferingMode === BufferingModeEnum.FIXED
                ? `${fixedDelayValidationRange.min} - ${fixedDelayValidationRange.max}`
                : '';
            // console.log('hint', hint);
            const showMultisyncTable =
              values.bufferingMode === BufferingModeEnum.MULTISYNC &&
              decoders.find((d) => d.bufferingMode === BufferingModeEnum.MULTISYNC);
            return (
              <form className="HaiForm" onKeyDown={handleOnKeyDown}>
                <FormSelect
                  name="bufferingMode"
                  defaultSelect={t(`decoder.selectMode`)}
                  defaultValue={t(`decoder.selectMode`)}
                  id="bufferingModeDropdown"
                  title={t('decoder.fields.bufferingMode')}
                  options={bufferingModeOptions}
                  className={values.bufferingMode === BufferingModeEnum.UNSET && 'notChosen'}
                />
                {values.bufferingMode === BufferingModeEnum.AUTOMATIC ? (
                  <FormTextfield
                    name="disabledValue"
                    title={t('decoder.fields.bufferingDelayMS')}
                    type="number"
                    placeholder={t('general.automatic')}
                    disabled
                  />
                ) : (
                  <FormTextfield name={name} title={t('decoder.fields.bufferingDelayMS')} hint={hint} type="number" />
                )}
                {showMultisyncTable && <DecoderMultiSyncTable decoders={decoders} />}
                <div className="d-flex justify-content-end hai-mt-8">
                  <Button onClick={onCancel}>{t('general.cancel')}</Button>
                  <Button
                    onClick={() => validateFormContext(formContext, t)}
                    variant="primary"
                    disabled={values.bufferingMode === BufferingModeEnum.UNSET}
                    id="decoder-set-buffering-form-submit"
                  >
                    {t('general.apply')}
                  </Button>
                </div>
              </form>
            );
          }}
        </FormContext.Consumer>
      </Form>
    );
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('decoder.setBuffering')}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="Settings"
      content={content()}
    />
  );
};
