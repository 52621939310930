import { Button } from '@hai/ui-react';
import { observer } from 'mobx-react';
import { Task } from 'mobx-task';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StateButton } from 'src/js/component/base/state-button';

interface Props {
  onReboot?: VoidFunction;
  onStatistics?: VoidFunction;
  statisticsDisabled?: boolean;
  onSubmit?: VoidFunction;
  submitTask?: Task<any, any>;
  applyDisabled?: boolean;
}

export const DateTimeButtons: React.FunctionComponent<Props> = observer(
  ({ onReboot, onStatistics, statisticsDisabled, onSubmit, submitTask, applyDisabled = false }) => {
    const { t } = useTranslation();

    return (
      <div className="buttonRow">
        <Button variant="secondary" onClick={onReboot}>
          {t('general.reboot')}
        </Button>
        <Button variant="secondary" onClick={onStatistics} disabled={statisticsDisabled}>
          {t('statistics.header.statistics')}
        </Button>
        <StateButton variant="primary" onClick={onSubmit} task={submitTask} disabled={applyDisabled}>
          {t('general.apply')}
        </StateButton>
      </div>
    );
  },
);
