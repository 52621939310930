/*eslint no-console: 0*/

import natsort from 'natsort';
import React, { ReactElement, useEffect } from 'react';

export type Comparator<T> = (a: T, b: T) => -1 | 0 | 1;

export enum SortType {
  GENERIC = 1,
  NATURAL_SORT,
  CUSTOM_FUNCTION, // via 'comparator' func
}

export enum SortDirection {
  IDLE = 0,
  DESCENDING = -1,
  ASCENDING = 1,
}

// for our view models
export interface MakitoObject {
  id: number | string;
  selected: boolean;
  toBeRestored?: boolean; // used after an action to restore selection
  hidden: boolean;
}

/* extends {id:string|number}*/
interface Props<T extends MakitoObject> {
  children: ReactElement[]; // each mapping to a T element with key = T.id
  list: T[];
  sortInfo: {
    [K in keyof Partial<T> | string]: {
      type: SortType;
      /*
       *  Optional
       *  Supply custom comparator function receiving model A and B as input and returning -1 | 0 | 1
       */
      comparator?: Comparator<T>;
      /*
       *  Optional
       *  Value provider for the field. Takes model as input and output value to sort on
       */
      provider?: (arg: T) => any;
    };
  };
  sortKey: keyof T | string;
  direction: SortDirection;
  /** secondLevelSortKey
   *  second-level sorting
   */
  secondLevelSortKey?: keyof T | string;
}

// local helper functions
const natsortFunc = natsort({ insensitive: true });

// No sorting sorts by 'id'
const comparatorNoSorting = (a: any, b: any, dirVal: any): 0 | 1 | -1 => {
  let result = 0;
  if (a.id > b.id) {
    result = 1;
  } else if (a.id < b.id) {
    result = -1;
  }

  if (dirVal < 0) {
    if (result === -1) {
      return 1;
    } else if (result === 1) {
      return -1;
    }
    return 0;
  }

  return result as 0 | 1 | -1;
};

// Comparator for strings (natural sort)
const comparatorNaturalSort = (
  item: string | number | symbol,
  a: any,
  b: any,
  dirVal: any,
  provider?: (model: any) => any,
): 0 | 1 | -1 => {
  let valueA = a[item];
  let valueB = b[item];
  if (provider) {
    valueA = provider(a);
    valueB = provider(b);
  }

  // undefined and null values are always at the end regardless of order
  if (valueB == undefined && valueA != undefined) {
    return -1;
  }
  if (valueA == undefined && valueB != undefined) {
    return 1;
  }

  const result = natsortFunc(valueA, valueB);

  // keep new stream form on top
  if (a.id === -1) {
    return -1;
  }
  if (b.id === -1) {
    return 1;
  }

  if (dirVal < 0) {
    if (result === -1) {
      return 1;
    } else if (result === 1) {
      return -1;
    }
    return 0;
  }
  return result as 0 | 1 | -1;
};

// Generic comparator (for numbers)
const comparatorGeneric = (
  item: string | number | symbol,
  a: any,
  b: any,
  dirVal: any,
  provider?: (model: any) => any,
): 0 | 1 | -1 => {
  let result = 0;

  // keep new stream form on top
  if (a.id === -1) {
    return -1;
  }
  if (b.id === -1) {
    return 1;
  }

  let valueA = a[item];
  let valueB = b[item];
  if (provider) {
    valueA = provider(a) ?? '';
    valueB = provider(b) ?? '';
  }
  //console.log('valueA/B', valueA, valueB);
  if (valueA > valueB) {
    result = 1;
  } else if (valueA < valueB) {
    result = -1;
  }

  if (dirVal < 0) {
    return (-1 * result) as 1 | -1; // descending
  }

  return result as 0 | 1 | -1;
};

/**
 *
 */
export const SortManager: <T extends MakitoObject>(props: Props<T>) => React.ReactElement<Props<T>> = ({
  children,
  list,
  sortInfo,
  direction,
  sortKey,
  secondLevelSortKey,
}) => {
  let comparator = comparatorNoSorting;
  let comparatorSecondLevel = comparatorNoSorting;
  if (sortInfo[sortKey] !== undefined) {
    if (sortInfo[sortKey].type === SortType.GENERIC) {
      comparator = function (a: any, b: any) {
        return comparatorGeneric(sortKey, a, b, direction, sortInfo[sortKey].provider);
      };
    } else if (sortInfo[sortKey].type === SortType.NATURAL_SORT) {
      comparator = function (a: any, b: any) {
        return comparatorNaturalSort(sortKey, a, b, direction, sortInfo[sortKey].provider);
      };
    } else if (sortInfo[sortKey].type === SortType.CUSTOM_FUNCTION) {
      comparator = sortInfo[sortKey].comparator;
    }
  } else {
    console.error('Sort key is not supported, verify sortInfo or clear localstorage', sortKey);
  }

  if (secondLevelSortKey !== undefined && sortInfo[secondLevelSortKey] !== undefined) {
    if (sortInfo[secondLevelSortKey].type === SortType.GENERIC) {
      comparatorSecondLevel = function (a: any, b: any) {
        return comparatorGeneric(secondLevelSortKey, a, b, direction, sortInfo[secondLevelSortKey].provider);
      };
    } else if (sortInfo[secondLevelSortKey].type === SortType.NATURAL_SORT) {
      comparatorSecondLevel = function (a: any, b: any) {
        return comparatorNaturalSort(secondLevelSortKey, a, b, direction, sortInfo[secondLevelSortKey].provider);
      };
    } else if (sortInfo[secondLevelSortKey].type === SortType.CUSTOM_FUNCTION) {
      comparatorSecondLevel = sortInfo[secondLevelSortKey].comparator;
    }
  } else if (secondLevelSortKey !== undefined) {
    console.error(
      'Second level sort key is not supported, verify `secondLevelSortKey` or clear localstorage',
      secondLevelSortKey,
    );
  }

  // Actual sort
  if (Array.isArray(children)) {
    if (secondLevelSortKey !== undefined) {
      children = [...children].sort((a, b) => {
        if (a.props.dataId != null) {
          const item1 = list.find((item) => item?.id == a.props.dataId);
          const item2 = list.find((item) => item?.id == b.props.dataId);
          return comparatorSecondLevel(item1, item2, direction);
        } else {
          const item1 = list.find((item) => item?.id == a.key);
          const item2 = list.find((item) => item?.id == b.key);
          return comparatorSecondLevel(item1, item2, direction);
        }
      });
    }

    children = [...children].sort((a, b) => {
      //const item1 = list.find((item) => item?.id == a.key);
      //const item2 = list.find((item) => item?.id == b.key);
      if (a.props.dataId != null) {
        const item1 = list.find((item) => item?.id == a.props.dataId);
        const item2 = list.find((item) => item?.id == b.props.dataId);
        return comparator(item1, item2, direction);
      } else {
        const item1 = list.find((item) => item?.id == a.key);
        const item2 = list.find((item) => item?.id == b.key);
        return comparator(item1, item2, direction);
      }
    });
  }

  // WORKAROUND FOR NKE-2781 / HAI-1658 begins
  let timerId: NodeJS.Timeout;
  const fixRow = (el: Element) => {
    const overflows = el.querySelectorAll('.overflow-container-item');
    for (let i = 0; i < overflows.length; i++) {
      const item = overflows[i] as HTMLElement;
      const div = item?.querySelector('div');
      if (div != null) {
        if (div.style?.opacity === '0') {
          div.style.opacity = '1';
        }
        const overflowBreak = item.getElementsByClassName('list-panel-overflow-indicator');
        if (overflowBreak?.length) {
          break; // stop at the +X
        }
      }
    }
  };
  const resolveOpacityFix = () => {
    const rows = document.getElementsByClassName('HaiListPanelDetail HaiOverflowContainer');
    for (let i = 0; i < rows.length; i++) {
      fixRow(rows[i]);
    }
  };
  useEffect(() => {
    timerId = setTimeout(resolveOpacityFix, 200);
    return () => {
      if (timerId) {
        clearTimeout(timerId);
        timerId = undefined;
      }
    };
  }, [sortKey, direction]);
  // WORKAROUND FOR NKE-2781 / HAI-1658 ends

  return <>{children}</>;
};
