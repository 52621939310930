import { CardStatus } from 'src/js/model/card-status';
import { SystemStatus } from 'src/js/model/status';
import { createWatchStore, setProperty, updateWatchStore } from 'src/js/store/use-watch';

// values will be inited from API at runtime
const systemStatus: SystemStatus = {
  bootVersion: '',
  cardStatus: CardStatus.OK,
  cardType: '',
  cpldRevision: '',
  firmwareDate: null,
  firmwareVersion: '',
  hardwareCompatibility: '',
  hardwareRevision: '',
  partNumber: '',
  serialNumber: '',
  temperature: null,
  uptime: '',
  cpu: 0,
  ram: 0,
};

createWatchStore(systemStatus);

const setSystemStatusProperty = (key: string, value: any) => {
  setProperty(systemStatus, key as keyof typeof systemStatus, value);
};
export { setSystemStatusProperty };

export const saveResponseToSystemStatus = (response: any | SystemStatus) => {
  updateWatchStore(systemStatus, response);
};

export default systemStatus;
