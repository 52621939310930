import { Observable } from 'rxjs';
import { BaseController } from 'src/js/controller/base-controller';
import { RebootResponse } from 'src/js/model/api/response/reboot-response';
import { APIPath } from 'src/js/api/route-path-index';

/* User APIs pertaining to Accounts */
export class SettingsController extends BaseController {
  /* Reboots the device */
  public reboot(): Observable<RebootResponse> {
    return this.post$(APIPath.reboot);
  }
}
