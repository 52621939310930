export const base = {
  'SDI icons': 'SDI icons',
  btn: {
    saveAndUpdate: 'SAVE AND UPDATE',
    update: 'UPDATE',
  },
  general: {
    active: 'Active',
    auto: 'Auto',
    automatic: 'Automatic',
    autoAssign: 'Auto-Assign',
    add: 'ADD',
    ago: 'ago',
    all: 'All',
    any: 'Any',
    apply: 'APPLY',
    cancel: 'CANCEL',
    cannotUndo: 'You cannot undo this action.',
    clear: 'CLEAR',
    close: 'CLOSE',
    applyChanges: 'APPLY',
    create: 'CREATE',
    default: 'DEFAULT',
    delete: 'Delete',
    deleteBtn: 'DELETE',
    deleteSelected: 'DELETE SELECTED',
    disabled: 'Disabled',
    done: 'DONE',
    download: 'Download',
    duplicate: 'Duplicate',
    edit: 'Edit',
    enable: 'ENABLE',
    enabled: 'Enabled',
    format: 'Format',
    generate: 'GENERATE',
    home: 'HOME',
    id: 'ID',
    import: 'IMPORT',
    install: 'INSTALL',
    later: 'LATER',
    load: 'Load',
    lock: 'LOCK',
    mute: 'Mute',
    name: 'Name',
    neverOccured: 'never occurred',
    no: 'No',
    noMatches: 'No Matches',
    none: 'None',
    noneSelected: '(None)',
    off: 'Off',
    ok: 'OK',
    on: 'On',
    password: 'Password',
    proceed: 'Proceed',
    reboot: 'REBOOT',
    rebootLater: 'REBOOT LATER',
    redirecting: 'Redirecting...',
    rename: 'Rename',
    reset: 'RESET',
    retry: 'RETRY',
    save: 'Save',
    saveBtn: 'SAVE',
    saveReboot: 'SAVE & REBOOT',
    search: 'Search',
    select: 'SELECT',
    settings: 'Settings',
    signOut: 'Sign Out',
    start: 'START',
    state: 'State',
    statistics: 'Statistics',
    status: 'Status',
    stop: 'STOP',
    subject: 'Subject',
    test: 'TEST',
    type: 'Type',
    unknown: 'Unknown',
    unlock: 'UNLOCK',
    unlicensed: 'Unlicensed',
    unmute: 'Unmute',
    upload: 'UPLOAD',
    uploading: 'Uploading',
    yes: 'Yes',
    version: 'version',
    view: 'View',
  },
  presets: {
    addFirst: 'No Presets to Display',
    autoSave: 'Autosave',
    configNotSaved: 'Active configuration not saved',
    dropdownHeader: 'Device Presets',
    modifiedTime: 'Modified',
    noPreset: 'No Active Preset',
    presetLoad: 'Load Preset',
    presetManager: 'Preset Manager',
    replace: 'Replace',
    startup: 'Startup',

    notifications: {
      successSavingPreset: 'Preset saved',
      autoSaveErrorMsg: 'Autosave could not be set',
      autoSaveOnSuccessMsg: 'Autosave activated',
      autoSaveOffSuccessMsg: 'Autosave deactivated',
      createErrorMsg: "Preset '{{name}}' could not be created",
      createSuccessMsg: "Preset '{{name}}' created",
      overwriteErrorMsg: "Preset '{{name}}' could not be overwritten",
      overwriteSuccessMsg: "Preset '{{name}}' overwritten",
      saveWithoutActiveSuccessMsg: "Preset '{{name}}' updated with current settings",
      saveWithoutActiveErrorMsg: "Preset '{{name}}' could not be saved",
      duplicateErrorMsg: "Preset '{{name}}' could not be duplicated",
      duplicateSuccessMsg: "Preset '{{name}}' duplicated",
      deleteErrorMsg: "Preset '{{name}}' could not be deleted",
      importErrorMsg: "Preset '{{name}}' could not be imported.",
      importSuccessMsg: "Preset '{{name}}' imported.",
      loadErrorMsg: "Preset '{{name}}' could not be loaded",
      loadSuccessMsg: "Preset '{{name}}' loaded",
      renameSuccessMsg: "Preset '{{name}}' renamed",
      startupSetErrorMsg: "'{{name}}' could not be set as the startup preset",
      startupSetSuccessMsg: "'{{name}}' set as the startup preset",
      startupUnsetErrorMsg: "'{{name}}' could not be unset as the startup preset",
      startupUnsetSuccessMsg: "'{{name}}' unset as the startup preset",
      updateErrorMsg: "Preset '{{name}}' could not be saved",
      updateSuccessMsg: "Preset '{{name}}' saved",
    },
    menu: {
      load: 'Load...',
      managePresets: 'Manage Presets...',
      saveAs: 'Save As...',
    },
    modal: {
      form: {
        newTitle: 'New Preset',
        renameTitle: 'Rename Preset',
        saveAsTitle: 'Save Preset As',
        setAsStartup: 'Set as Startup',
      },
      delete: {
        title: 'Delete this preset?',
        title_plural: 'Delete {{count}} presets?',
      },
      overwrite: {
        title: 'Confirmation',
        bodyText: "'{{name}}' already exists. Do you want to replace it?",
      },
    },
  },
  systemNotLicensed: 'System requires license update',
  logoutNotSavedModal: {
    title: 'Unsaved Changes',
    body: 'The current running configuration was not saved.',
    button: {
      signout: 'SIGN OUT',
      saveSignout: 'SAVE & SIGN OUT',
    },
  },
  error: {
    failedToResolve: 'Failed to resolve host name',
    loginErrorIncorrect: 'Username or password is incorrect',
    loginErrorTooMany: 'Too many concurrent sessions',
    loginErrorUnknown: 'Unknown error. Please try again',
    requestInvalid: 'The request has been rejected by the server',
    serverError: 'An error occurred on the server',
    serverTimeout: 'Unable to reach the server in a timely manner',
    serverUnreachable: 'Unable to reach the server',
    systemNotLicensed: 'System requires license update',
    unknownError: 'An unknown error occurred',
  },
  notifications: {
    internalServerError: 'An unknown error occurred on the server',
    lostConnection: 'Communication with the device was lost',
    communicationRestored: 'Communication restored',
    prefixes: {
      Error: 'Error',
      Success: 'Success',
      SUCCESS_WITH_PREFIX: 'Success',
      Warning: 'Warning',
      Reminder: 'Reminder',
    },
    messages: {
      error: 'an unhandled error has occurred',
      success: 'the operation was successful',
      warning: 'an unhandled warning has occurred',
      reminder: 'a reminder was set',
    },
  },
  streaming: {
    tabs: {
      decoder: 'Decoders',
      stream: 'Streams',
    },
  },
  sort: {
    ascending: 'Ascending',
    descending: 'Descending',
    sortPrefix: 'Sort',
    sortBy: 'SORT BY',
    sortOrder: 'SORT ORDER',
    sortText: {
      address: 'Address',
      codec: 'Codec',
      connection: 'Connection',
      destination: 'Destination',
      decoder: 'Decoder',
      encapsulation: 'Protocol',
      id: 'ID',
      name: 'Name',
      resolution: 'Resolution',
      status: 'Status',
      type: 'Type',
    },
  },
  date: {
    longComponents: {
      years: 'Year',
      years_plural: 'Years',
      months: 'Month',
      months_plural: 'Months',
      days: 'Day',
      days_plural: 'Days',
      hours: 'Hour',
      hours_plural: 'Hours',
      minutes: 'Minute',
      minutes_plural: 'Minutes',
      seconds: 'Second',
      seconds_plural: 'Seconds',
    },
    shortComponents: {
      years: 'y',
      months: 'M',
      days: 'd',
      hours: 'h',
      minutes: 'm',
      seconds: 's',
    },
    picker: {
      amLabel: 'AM',
      applyLabel: 'APPLY',
      backLabel: 'BACK',
      cancelLabel: 'CANCEL',
      confirmLabel: 'CONFIRM',
      hhPlaceholder: 'hh',
      hoursLabel: '24 hours',
      mmPlaceholder: 'mm',
      monthLabel: 'Month',
      pmLabel: 'PM',
      yearLabel: 'Year',
    },
  },
  iconStatusType: {
    /* This is what is printed in stream table rows */
    inactive: 'Inactive',
    streaming: 'Active',
    listening: 'Listening',
    connecting: 'Connecting',
    connected: 'Connected',
    error: 'Error',
    warning: 'Warning',
    stopped: 'Inactive',
    ready: 'Listening',
    'to-storage': 'Streaming',
  },
  notFound: {
    title: 'Oops!',
    containerTitle: '404 Page Not Found',
    containerSubtitle:
      'The page you are looking for might have been removed, may be temporarily unavailable or was possibly eaten by a shark.',
  },
  reboot: {
    title: 'Rebooting',
    confirm: {
      title: 'Reboot the Decoder?',
      body: 'This action will terminate all processes currently running and may take several minutes to restart.',
    },
    notSaved: {
      body: 'The current running configuration was not saved and will be lost if you reboot.',
    },
    header: 'The system is rebooting',
    subheader: 'Please refresh this page in 2 minutes if it does not refresh automatically.',
    upgrade: {
      header: 'Please do not reboot again or shut down the system during the update process.',
      subheader: 'The page will refresh automatically in about 15 minutes when the update is complete.',
    },

    savedModal: {
      title: 'Reboot Needed',
      body: 'The system must be restarted for changes to take effect. Do you want to reboot now?',
    },
    notSavedModal: {
      title: 'Save Configuration?',
      body1: 'The currently active configuration is not saved and will be lost if you reboot.',
      body2: 'Choose "Save and Reboot" to save the configuration and reboot.',
    },
    upgradeNotSavedModal: {
      title: 'Save Configuration?',
      body1: 'The currently active configuration is not saved and will be lost if you update.',
      body2: 'Choose "Save and Update" to save the configuration and update.',
    },
  },
  sidebar: {
    dashboard: 'Dashboard',
    help: 'Help Center',
    security: 'Security',
    settings: 'Settings',
    streams: 'Streaming',
  },
  login: {
    password_required: 'Password required',
    rememberMe: 'Remember Me',
    signin: 'SIGN IN',
    username_required: 'Username required',
    capsLockOn: 'Caps Lock is on',
  },
  passwordExpired: {
    title: 'Change Password',
  },
  help: {
    title: 'Help Center',
  },
  upload: {
    browseMessage: 'Browse',
    uploadErrorMessage: 'Upload was not successful',
    incorrectFileType: 'Incorrect file type!',
    mainMessage: 'Drag and drop to add a file',
    uploadSuccessMessage: 'Upload Successful',
    tryAgain: 'Try Again',
    or: 'or',

    chooseFile: 'Choose a file',
    dragFile: 'or drag one here.',
    fileSelected: 'File Selected:',
  },
  userAnalytics: {
    inSettingsTitle: 'Product Analytics',
    inSettingsHeadingText: 'Enable Anonymous Product Analytics',
    inSettingsInfoText:
      'To help us build better products, we collect anonymous data from this product for analytics purposes. For more information, please review our',
    modalMainText:
      'Haivision is working to bring you the best products available. To help us better understand customer needs, we collect anonymous data from this product for analytics purposes. You can disable this by choosing Decline below, or at any time from the product’s settings.',
    modalMoreInformationText: 'For more information, please review our',
    modalContinueText: 'ACCEPT',
    modalDeclineText: 'DECLINE',
    modalTitle: 'Help Us Improve!',
    privacyPolicyText: 'privacy policy',
  },
};
