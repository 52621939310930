import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import { FormikProps, useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikDatetimePicker } from 'src/js/component/base/form/formik-datetime-picker';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { FormikSwitch } from 'src/js/component/base/form/formik-switch';
import { RxTask } from 'src/js/helper/mobx-tasks';
import { NtpServerStatus } from 'src/js/model/ntp-server-status';
import { CurrentTime } from './current-time';

import { DateAndTimeFormValues } from './date-and-time-form';
import { ServerTest } from './ntp-server';
import { timezoneNames } from './timezone-helper';

interface InternalProps {
  loading: boolean;
  validateNtpServerTask?: RxTask<NtpServerStatus>;
}

export const Server = (props: InternalProps & FormikProps<DateAndTimeFormValues>) => {
  const { t } = useTranslation();
  const [ntp] = useField('ntp');
  const { loading } = props;

  return (
    <Form>
      <FormSection title={t('settings.dateTime.section.server.title')}>
        <DynamicContainer minColumns={3} className="mk-dynamic-form">
          <FormikSwitch loading={loading} name="ntp" switchLabel={t('settings.dateTime.section.server.useNtpServer')} />
        </DynamicContainer>
        <DynamicContainer minColumns={3} className="mk-dynamic-form">
          {ntp.value ? (
            <ServerTest {...props} />
          ) : (
            <FormikDatetimePicker
              name="time"
              title={t('settings.dateTime.section.server.manualDateTime')}
              minimumDate={new Date('January 1, 2019 12:01')}
            />
          )}
          <FormikSelect
            title={t('settings.dateTime.section.timezone.timezoneLocale')}
            name="timezone"
            selectValues={Array.from(timezoneNames).map((entry) => entry[0])}
            selectLabels={Array.from(timezoneNames).map((entry) => entry[1])}
          />
          <CurrentTime time={props.values.time} />
        </DynamicContainer>
      </FormSection>
    </Form>
  );
};
