import { TFunction } from 'i18next';
import moment from 'moment-timezone';
import { append, includes, without } from 'ramda';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { DropdownOption } from '../component/base/dropdown-menu';
import {
  createDurationString,
  DurationFormatter,
  DurationFormatterType,
  formatterForType,
} from './duration-format-helper';

/**
 * Make any inactive lines invisible by adjusting the color scale.
 * It will set the colors to `none` for the inactive fields.
 * @param fields: all the chart fields
 * @param inactiveFields: all the inactive chart fields
 * @param colors: the colors
 */
export const adjustInactiveColors = (fields: string[], inactiveFields: string[], colors: string[]) => {
  return fields
    .map((field, index) => (includes(field, inactiveFields) ? 'none' : colors[index % colors.length]))
    .filter((color) => color !== 'none');
};

// return array with item added or removed depending on presence in inactiveItems
export const toggleItem = (item: string, list: string[]): string[] => {
  return includes(item, list) ? without([item], list) : append(item, list);
};

export const getItemLengthByTimescale = (ts: StatsInterval) => {
  switch (ts) {
    case StatsInterval.LAST_5_MINS:
      return 0.08333;
    case StatsInterval.LAST_60_MINS:
      return 0.08333;
    case StatsInterval.LAST_24_HOURS:
      return 0.00139;
    default:
      return 0.08333;
  }
};

/**
 * Create the interval options dropdown menu
 *
 * @param intervals: the intervals
 * @param selectedTitle: the string for the selected title
 * @param onSelect: the onSelect handler
 * @param t: translator
 */
export const createIntervalOptions = (
  intervals: string[],
  selectedTitle: string,
  onSelect: (value: number) => void,
  t: TFunction,
): DropdownOption[] => {
  return intervals.map((interval) => {
    const duration = moment.duration(interval);
    const longFormatter: DurationFormatter = formatterForType(DurationFormatterType.LONG, t);
    const shortFormatter: DurationFormatter = formatterForType(DurationFormatterType.SHORT, t);
    const milliseconds = duration.asMilliseconds();
    return {
      title: createDurationString(duration, longFormatter),
      selectedTitle: selectedTitle
        ? `${selectedTitle} ${createDurationString(duration, shortFormatter)}`
        : createDurationString(duration, longFormatter),
      onSelect: () => onSelect(milliseconds),
      value: milliseconds,
    };
  });
};
