import {
  ApiErrorResponse,
  ApiResponse,
  ApisauceConfig,
  ApisauceInstance,
  CLIENT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
} from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import clone from 'ramda/src/clone';
import isNil from 'ramda/src/isNil';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NetworkErrorHandler } from 'src/js/controller/network-error-handler';
import {
  ClientAuthenticationError,
  ClientError,
  ClientNetworkError,
  ClientTimeoutError,
  DefaultServerErrorResponse,
  Server503Error,
} from 'src/js/error/client-error';
import { ServerError } from 'src/js/error/server-error';
import { persistedStores } from '../store';
import { trunk } from '../service/persistence';

export interface NetworkRequestConfig extends AxiosRequestConfig {
  customErrorHandler?: NetworkErrorHandler;
}

export class BaseController {
  protected api: ApisauceInstance;
  protected config: ApisauceConfig;
  private errorHandler: NetworkErrorHandler;

  constructor(api: ApisauceInstance, config: ApisauceConfig, errorHandler: NetworkErrorHandler) {
    this.api = api;
    this.config = config;
    this.errorHandler = errorHandler;
  }

  protected delete$<T>(url: string, params?: {}, config?: NetworkRequestConfig): Observable<T> {
    return this.mapPromise(
      this.api.delete<T, DefaultServerErrorResponse>(url, params, config),
      config?.customErrorHandler,
    );
  }
  protected get$<T>(url: string, params?: {}, config?: NetworkRequestConfig): Observable<T> {
    return this.mapPromise(
      this.api.get<T, DefaultServerErrorResponse>(url, params, config),
      config?.customErrorHandler,
    );
  }
  protected post$<T>(url: string, params?: {}, config?: NetworkRequestConfig): Observable<T> {
    return this.mapPromise(
      this.api.post<T, DefaultServerErrorResponse>(url, params, config),
      config?.customErrorHandler,
    );
  }
  protected put$<T>(url: string, params?: {}, config?: NetworkRequestConfig): Observable<T> {
    return this.mapPromise(
      this.api.put<T, DefaultServerErrorResponse>(url, params, config),
      config?.customErrorHandler,
    );
  }

  /**
   * This version returns back an Observable with ApiResponse so that layers above can access the HTTP status code.
   * It does examine the response and handles 401 unauthorized => redirect to login
   *
   * DEPRECATED, replace with get() using promises
   *
   * **/
  protected getO<T>(url: string, params?: {}, config?: AxiosRequestConfig): Observable<ApiResponse<T>> {
    return this.mapAndExamineResponse(this.api.get<T>(url, params, config));
  }

  /**
   * This version returns back an Observable with ApiResponse so that layers above can access the HTTP status code.
   * It does examine the response and handles 401 unauthorized => redirect to login
   * DEPRECATED, replace with put() using promises
   *
   * **/
  protected putO<T>(url: string, params?: {}, config?: AxiosRequestConfig): Observable<ApiResponse<T>> {
    return this.mapAndExamineResponse(this.api.put<T>(url, params, config));
  }

  protected mapPromise<T>(
    promise: Promise<ApiResponse<T, DefaultServerErrorResponse>>,
    customErrorHandler?: NetworkErrorHandler,
  ): Observable<T> {
    return from(promise).pipe(
      map((response: ApiResponse<T, DefaultServerErrorResponse>) =>
        this.extractResponseBody(response, customErrorHandler),
      ),
    );
  }

  // This is used for simple 'get' and 'put' method that returns the APIResponse directly.
  // Currently it handles the 401 redirect.
  protected mapAndExamineResponse<T>(promise: Promise<ApiResponse<T>>): Observable<ApiResponse<T>> {
    return from(promise).pipe(map((response: ApiResponse<T>) => this.examineResponseGlobalHandler(response)));
  }

  // This is used for simple 'get' and 'put' method that returns the APIResponse directly.
  // Currently it handles the 401 redirect.
  protected examinePromise<T>(promise: Promise<ApiResponse<T>>): Promise<ApiResponse<T>> {
    return promise.then((response: ApiResponse<T>) => {
      return this.examineResponseGenericHandler(response);
    });
  }

  protected extractResponseBody<T>(
    response: ApiResponse<T, DefaultServerErrorResponse>,
    customErrorHandler?: NetworkErrorHandler,
  ): T {
    // If error is handled, return empty data
    if (!response.ok) {
      // If there is a custom handler, add it before and then remove it
      if (!isNil(customErrorHandler)) {
        customErrorHandler.successor = clone(this.errorHandler);
        this.errorHandler = customErrorHandler;
      }
      const error = this.errorHandler.handleError(
        this.mapProblemToError(response as ApiErrorResponse<DefaultServerErrorResponse>),
      );
      if (!isNil(customErrorHandler)) {
        this.errorHandler = customErrorHandler.successor;
      }

      throw this.mapProblemToError(!isNil(error) ? error : (response as ApiErrorResponse<DefaultServerErrorResponse>));
    }
    // in case no data is returned, the result will not be undefined/null
    if (!response.data) {
      return {} as T;
    }
    return response.data;
  }

  protected examineResponseGlobalHandler<T>(response: ApiResponse<T>): ApiResponse<T> {
    if (!response.ok) {
      throw this.errorHandler.handleError(this.mapProblemToError(response));
    }

    return response;
  }

  // Will not throw on server-side errors
  protected examineResponseGenericHandler<T>(response: ApiResponse<T>): ApiResponse<T> {
    if (!response.ok) {
      this.handleSignedOutResponse(response);
    }

    return response;
  }

  // Used by extractResponseBody and examineResponseGlobalHandler
  // DEPRECATED: Use examineResponseGenericHandler via the GenericController
  protected mapProblemToError(response: ApiErrorResponse<DefaultServerErrorResponse> | any): ClientError {
    const { problem, data, status } = response;

    if (status === 503) {
      return new Server503Error();
    }

    if (status === 401) {
      return new ClientAuthenticationError();
    }

    if (problem === SERVER_ERROR || problem === CLIENT_ERROR) {
      return new ServerError(data, status);
    }

    if (problem === CONNECTION_ERROR || problem === NETWORK_ERROR) {
      return new ClientNetworkError();
    }

    if (problem === TIMEOUT_ERROR) {
      return new ClientTimeoutError();
    }

    return new ClientError();
  }

  protected handleSignedOutResponse(response: ApiErrorResponse<DefaultServerErrorResponse> | any): void {
    const { status } = response;

    if (status === 401) {
      // console.log('401 generic handler');
      persistedStores.sessionStore.logout(); // 401 handle new way
      trunk.persist();
    }
  }
}
