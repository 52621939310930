import { Button, DynamicContainer, Form, FormContext } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { ApiErrorResponse, ApiOkResponse } from 'apisauce';
import { FormikProps } from 'formik';
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTextfield } from 'src/js/component/base/form/form-textfield';
import { FormBuilder, FormFieldType, FormLayout } from 'src/js/component/form-builder';
import systemInfo, { systemInfoCapabilities } from 'src/js/data/systemInfo';
import { triggerEvent } from 'src/js/events';
import { useStores } from 'src/js/hook/use-stores';
import streams, {
  addStreamForm,
  fetchStreamStatistics,
  updateStreamForm,
} from 'src/js/pages/streaming/stream/stream-store';
import { useWatchObject } from 'src/js/store/use-watch';
import { constant } from 'src/js/constant';
import { submitAndValidate, validateFormContext } from 'src/js/validator/validator';

import { StreamEditAccessControlForm, StreamEditAccessControlFormRef } from './stream-edit-access-control';
import { SourceType } from './stream-helper';
import {
  FecRTP,
  GetStreamResponse,
  NetworkInterfaces,
  SRTAuthentication,
  SRTAuthenticationListener,
  SRTAuthenticationOthers,
  SRTMode,
  SrtPathRedundancyMode,
  SrtPathRedundancyModeOffered,
  SrtRedundancyPathRole,
  StreamEncapsulation,
  StreamViewModel,
  streamValidationSchema,
  supportedStreamEncapsulation,
  supportedStreamEncapsulationWithoutSRT,
} from './stream-models';

interface Props {
  model: StreamViewModel;
  onCancel?: VoidFunction;
}

export interface StreamAddFormRef {
  submitAddStream: () => Promise<ApiErrorResponse<GetStreamResponse> | ApiOkResponse<GetStreamResponse>>;
}

export const StreamEdit = forwardRef((props: Props, ref: Ref<StreamAddFormRef>) => {
  const { model, onCancel } = props;
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const formRef = useRef<FormikProps<StreamViewModel>>();
  const accessControlFormRef = useRef<StreamEditAccessControlFormRef>();
  const nameField = useRef<HTMLInputElement>();
  const newStream = model.id === -1;

  // setting a state here prevents live update from modifying the edit form
  const [settings, setSettings] = useState<StreamViewModel>(model);
  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);
  const anyPidsManuallySet =
    model.audioPids?.length > 0 || model.videoPids?.length || model.dataPids?.length || model.programNumber > 0
      ? true
      : false;
  const [showTsFields, setShowTsFields] = useState(anyPidsManuallySet);

  useEffect(() => {
    if (!newStream) {
      fetchStreamStatistics(model, t, setSettings); // refetch individual is required to load sap parameters
    } else {
      nameField.current?.focus?.();
    }
  }, []);

  useWatchObject(streams, (_updates) => {
    // compare if any changes from upstream since form was opened (settings vs _updates) and notify
  });

  const handleSubmit = (values: any) => {
    setButtonState('pending');

    if (newStream) {
      return addStreamForm(values, formRef.current, t).then((res) => {
        setButtonState(undefined);
        if (res.ok) {
          triggerEvent('preset-update');
        }
        return res;
      });
    } else {
      return updateStreamForm(values, formRef.current, t).then((res: any) => {
        if (res.ok) {
          const stream = streams.find((e) => e.id === model.id);
          setSettings(stream); // update edit view with newest model
          formRef.current.resetForm({ values: stream });
          triggerEvent('preset-update');
        }
        setButtonState(undefined);
        return res;
      });
    }
  };

  const validateForm = (formContext: FormikProps<StreamViewModel>) => {
    validateFormContext(formContext, t);
    if (!formContext.isValid) {
      //checkTsFields(formContext);
    }
  };

  const submitAddStream = () => {
    return submitAndValidate(formRef.current, t).then((res) => {
      if (!formRef.current.isValid) {
        //checkTsFields(formRef.current);
      }
      return res;
    });
  };

  useImperativeHandle(ref, () => ({
    submitAddStream,
  }));

  const onChangeSrtMode = (e: any) => {
    if (e.target.value === SRTMode.RENDEZVOUS) {
      formRef.current.setFieldValue('sourcePort', formRef.current.values.port);
    }
    if (accessControlFormRef?.current) {
      accessControlFormRef.current.onChangeSrtMode?.();
    }
  };

  const onChangePort = (e: any) => {
    if (formRef.current.values.srtMode === SRTMode.RENDEZVOUS) {
      formRef.current.setFieldValue('sourcePort', e.target.value);
    }
  };

  const isSrtRedundancyOff = (model: StreamViewModel) => {
    if (model.encapsulation !== StreamEncapsulation.SRT) {
      return true;
    }
    if (model.srtMode === SRTMode.LISTENER || model.srtMode === SRTMode.RENDEZVOUS) {
      return true;
    }
    if (
      model.srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_ACTIVE ||
      model.srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_BACKUP
    ) {
      return false;
    }
    return true;
  };

  return (
    <Form
      initialValues={settings}
      defaultValidation={true}
      handleSubmit={handleSubmit}
      className="hai-mt-n8"
      restValidationProps={{
        enableReinitialize: false, // "false" requires manual resetForm() above. We can then do partial update of the form while it's opened.
        validationSchema: streamValidationSchema(t),
        innerRef: formRef,
      }}
    >
      <FormContext.Consumer>
        {(formContext: FormikProps<StreamViewModel>) => {
          const values = formContext.values;
          const showRedundancy = !isSrtRedundancyOff(values);
          let showRedundancyPrimaryPathRows = false;
          if (showRedundancy) {
            if (values.srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_ACTIVE) {
              showRedundancyPrimaryPathRows = false;
            } else {
              showRedundancyPrimaryPathRows = true;
            }
          }

          let showNonRedundantCallerRendezvous = false;
          if (values.encapsulation === StreamEncapsulation.SRT && showRedundancy === false) {
            if (values.srtMode === SRTMode.CALLER || values.srtMode === SRTMode.RENDEZVOUS) {
              showNonRedundantCallerRendezvous = true;
            }
          }
          const isListener = values.encapsulation === StreamEncapsulation.SRT && values.srtMode === SRTMode.LISTENER;

          const fixSrtFields = (
            srtMode: SRTMode,
            srtRedundancyMode: SrtPathRedundancyMode,
            authentication: SRTAuthentication,
          ) => {
            if (srtMode == SRTMode.LISTENER) {
              if (authentication === undefined || authentication === SRTAuthentication.NONE) {
                formContext.setFieldValue('authentication', SRTAuthenticationListener.AUTO);
              }
              if (srtRedundancyMode === undefined || srtRedundancyMode === SrtPathRedundancyMode.NONE) {
                formContext.setFieldValue('srtRedundancyMode', SrtPathRedundancyMode.OPTIONAL);
              }
            } else {
              // non-listener
              if (authentication === undefined || authentication === SRTAuthentication.AUTO) {
                formContext.setFieldValue('authentication', SRTAuthenticationOthers.NONE);
              }
              if (srtRedundancyMode === undefined || srtRedundancyMode === SrtPathRedundancyMode.OPTIONAL) {
                formContext.setFieldValue('srtRedundancyMode', SrtPathRedundancyMode.NONE);
              }
            }
          };
          // console.log('values', values)
          return (
            <>
              <DynamicContainer minColumns={2} maxColumns={2} className="hai-mt-panel-name-align">
                <FormTextfield
                  inputRef={nameField}
                  name="name"
                  mask
                  placeholder={values.name}
                  title={t('general.name')}
                  className="max-width"
                  autoFocus={newStream}
                />
                {!newStream && !sessionStore.isUser() && (
                  <div className="d-flex ml-auto hai-mt-panel-apply-sticky">
                    <Button className="hai-mr-6" onClick={onCancel}>
                      {t('general.cancel')}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => validateForm(formContext)}
                      disabled={!formContext.dirty}
                      state={buttonState}
                    >
                      {t('general.apply')}
                    </Button>
                  </div>
                )}
              </DynamicContainer>

              <FormBuilder
                translationPrefixLabels="stream.fields"
                translationPrefixEnums="stream.enums"
                layout={FormLayout.THREE_COLUMNS_GRID}
                fields={[
                  {
                    type: FormFieldType.TITLE,
                    label: t('stream.sections.parameters'),
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'encapsulation',
                    enum: systemInfoCapabilities.hasSRT()
                      ? supportedStreamEncapsulation
                      : supportedStreamEncapsulationWithoutSRT,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'srtMode',
                    enum: SRTMode,
                    when: values.encapsulation === StreamEncapsulation.SRT,
                    onChange: (value: any) => {
                      fixSrtFields(value.target.value, values.srtRedundancyMode, values.authentication);
                      onChangeSrtMode(value);
                    },
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'srtRedundancyMode',
                    options: Object.values(SrtPathRedundancyModeOffered).map((value) => ({
                      value: value,
                      label: t(`stream.enums.srtRedundancyOptions.${value}`),
                    })),
                    value: values.srtRedundancyMode,
                    when: values.encapsulation === StreamEncapsulation.SRT && values.srtMode === SRTMode.CALLER,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'sourceType',
                    enum: SourceType,
                    when:
                      values.encapsulation === StreamEncapsulation.UDP ||
                      values.encapsulation === StreamEncapsulation.RTP,
                  },
                  {
                    breakBefore: true,
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'port',
                    mask: true,
                    hint: t('stream.fields.portExplain'),
                    when:
                      isSrtRedundancyOff(values) &&
                      values.encapsulation === StreamEncapsulation.SRT &&
                      values.srtMode === SRTMode.LISTENER,
                    required: true,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'srtListenerSecondPort',
                    mask: true,
                    placeholder: t('stream.fields.srtListenerSecondPortPlaceholder'),
                    when: values.encapsulation === StreamEncapsulation.SRT && values.srtMode === SRTMode.LISTENER,
                  },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'address',
                    mask: true,
                    when:
                      (isSrtRedundancyOff(values) &&
                        values.encapsulation === StreamEncapsulation.SRT &&
                        (showRedundancy || !isListener)) ||
                      ((values.encapsulation === StreamEncapsulation.UDP ||
                        values.encapsulation === StreamEncapsulation.RTP) &&
                        values.sourceType === SourceType.multicast),
                    required: values.encapsulation !== StreamEncapsulation.SRT,
                  },
                  /* {
                    type: FormFieldType.LINE_BREAK,
                    when: !isSrtRedundancyOff(values.srtRedundancyMode)
                  },*/
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'sourcePort',
                    mask: true,
                    placeholder: t('stream.fields.srtRedundancyPath1SourcePortPlaceholder'),
                    hint: t('stream.fields.portExplain'),
                    when: !showRedundancy && showNonRedundantCallerRendezvous,
                    disabled: values.srtMode === SRTMode.RENDEZVOUS,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'port',
                    mask: true,
                    label: t('stream.fields.srtRedundancyPath1Port'),
                    hint: t('stream.fields.portExplain'),
                    when: !showRedundancy && showNonRedundantCallerRendezvous,
                    required: true,
                    onChange: onChangePort,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'port',
                    mask: true,
                    hint:
                      values.encapsulation === StreamEncapsulation.RTP
                        ? t('stream.fields.portNumberRTPExplain')
                        : t('stream.fields.portExplain'),
                    required: true,
                    when:
                      values.encapsulation === StreamEncapsulation.UDP ||
                      values.encapsulation === StreamEncapsulation.RTP,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'fecRtp',
                    enum: FecRTP,
                    when: values.encapsulation === StreamEncapsulation.RTP,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'networkInterface',
                    label: t('stream.fields.networkInterface'),
                    enum: NetworkInterfaces,
                    when:
                      systemInfo.hasEth1 &&
                      (values.encapsulation !== StreamEncapsulation.SRT ||
                        values.srtMode === SRTMode.RENDEZVOUS ||
                        (!showRedundancy && values.srtMode !== SRTMode.LISTENER)),
                  },
                  {
                    type: FormFieldType.LINE_BREAK,
                  },
                  {
                    type: FormFieldType.SWITCH,
                    label: t('stream.showTsFields'),
                    className: 'mk-inline-toggler',
                    value: showTsFields,
                    onChange: (checked: boolean) => {
                      if (!checked) {
                        // reset fields when collapsing the TS pids section
                        formContext.setFieldValue('audioPids', formContext.initialValues.audioPids);
                        formContext.setFieldValue('videoPids', formContext.initialValues.videoPids);
                        formContext.setFieldValue('dataPids', formContext.initialValues.dataPids);
                        formContext.setFieldValue('programNumber', formContext.initialValues.programNumber);
                      }
                      formContext.validateForm().then(() => {
                        setShowTsFields(checked);
                      });
                    },
                  },
                  {
                    type: FormFieldType.LINE_BREAK,
                  },
                  { type: FormFieldType.SECTION_START, when: showTsFields },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'videoPids',
                    placeholder:
                      values.videoPidsPlaceholder && values.videoPidsPlaceholder !== ''
                        ? values.videoPidsPlaceholder
                        : t('stream.pidAutoDetect'),
                    hint: t('stream.fields.videoPidsExplain'),
                  },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'audioPids',
                    placeholder:
                      values.audioPidsPlaceholder && values.audioPidsPlaceholder !== ''
                        ? values.audioPidsPlaceholder
                        : t('stream.pidAutoDetect'),
                    hint: t('stream.fields.audioPidsExplain'),
                  },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'dataPids',
                    placeholder:
                      values.dataPidsPlaceholder && values.dataPidsPlaceholder !== ''
                        ? values.dataPidsPlaceholder
                        : t('stream.pidAutoDetect'),
                    hint: t('stream.fields.dataPidsExplain'),
                  },
                  {
                    type: FormFieldType.STATIC_TEXT,
                    name: 'pcrPid',
                    hint: t('stream.fields.pcrPidExplain'),
                    value: values.stats.pcrPid,
                  },
                  {
                    type: FormFieldType.STATIC_TEXT,
                    name: 'pmtPid',
                    hint: t('stream.fields.pmtPidExplain'),
                    value: values.stats.pmtPid,
                  },
                  /* TS ID not in MX4D
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'tsStreamId',
                    hint: t('stream.fields.tsStreamIdExplain'),
                  },*/
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'programNumber',
                    placeholder: values.programNumberPlaceholder ?? t('stream.pidAutoDetect'),
                    hint: t('stream.fields.programNumberExplain'),
                    value: values.programNumber === 0 ? '' : values.programNumber,
                  },
                  { type: FormFieldType.SECTION_END },
                  /* REDUNDANCY SECTION BEGINS */
                  {
                    type: FormFieldType.TITLE,
                    label: t('stream.sections.srtPathRedundancy'),
                    when:
                      showRedundancy &&
                      systemInfoCapabilities.hasSrtRedundancy() &&
                      values.encapsulation === StreamEncapsulation.SRT,
                  },
                  {
                    type: FormFieldType.SUBTITLE,
                    label: t('stream.sections.path1'),
                    when: showRedundancy,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'srtRedundancyPath1Role',
                    enum: SrtRedundancyPathRole,
                    when: showRedundancyPrimaryPathRows,
                  },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'srtRedundancyPath1Name',
                    hint: t('stream.fields.srtRedundancyPath1NameExplain'),
                    when: showRedundancy,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'networkInterface',
                    label: t('stream.fields.networkInterface'),
                    enum: NetworkInterfaces,
                    when: systemInfo.hasEth1 && values.encapsulation === StreamEncapsulation.SRT && !isListener,
                  },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'address',
                    mask: true,
                    when: values.encapsulation === StreamEncapsulation.SRT && showRedundancy && !isListener,
                    required: values.srtMode === SRTMode.CALLER,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'srtRedundancyPath1SourcePort',
                    mask: true,
                    placeholder: t('stream.fields.srtRedundancyPath1SourcePortPlaceholder'),
                    hint: t('stream.fields.portExplain'),
                    when: showRedundancy,
                    disabled: values.srtMode === SRTMode.RENDEZVOUS,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'port',
                    mask: true,
                    label: t('stream.fields.srtRedundancyPath1Port'),
                    hint: t('stream.fields.portExplain'),
                    when: showRedundancy,
                    required: true,
                    onChange: onChangePort,
                  },
                  {
                    type: FormFieldType.SUBTITLE,
                    label: t('stream.sections.path2'),
                    when:
                      values.encapsulation === StreamEncapsulation.SRT &&
                      values.srtMode === SRTMode.CALLER &&
                      (values.srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_ACTIVE ||
                        values.srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_BACKUP),
                  },
                  {
                    type: FormFieldType.STATIC_TEXT,
                    name: 'pathRole',
                    label: t('stream.fields.pathRole'),
                    value:
                      values.srtRedundancyPath1Role === 'secondary'
                        ? t('stream.enums.srtRedundancyRoleOptions.primary')
                        : t('stream.enums.srtRedundancyRoleOptions.secondary'),
                    when: showRedundancyPrimaryPathRows,
                  },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'srtRedundancyPath2Name',
                    hint: t('stream.fields.srtRedundancyPath2NameExplain'),
                    when: showRedundancy,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'srtRedundancyPath2NetworkInterface',
                    label: t('stream.fields.networkInterface'),
                    enum: NetworkInterfaces,
                    when: showRedundancy && systemInfo.hasEth1,
                  },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'srtRedundancyPath2Address',
                    mask: true,
                    when: showRedundancy,
                    required: true,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'srtRedundancyPath2SourcePort',
                    mask: true,
                    placeholder: t('stream.fields.srtRedundancyPath2SourcePortPlaceholder'),
                    hint: t('stream.fields.portExplain'),
                    when: showRedundancy,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'srtRedundancyPath2Port',
                    mask: true,
                    hint: t('stream.fields.portExplain'),
                    when: showRedundancy,
                    required: true,
                  } /* TODO: conditional line break that supports "when" prop
                  {
                    type: FormFieldType.LINE_BREAK,
                    when: showRedundancy,
                  },*/,
                  {
                    type: FormFieldType.TITLE,
                    label: t('stream.sections.srtSettings'),
                    when: values.encapsulation === StreamEncapsulation.SRT,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'latency',
                    hint: t('stream.fields.latencyExplain'),
                    when: values.encapsulation === StreamEncapsulation.SRT,
                    required: true,
                  },
                  {
                    type: FormFieldType.SWITCH,
                    name: 'encrypted',
                    title: t('stream.fields.encryption'),
                    label: values.encrypted ? t('stream.fields.encrypted') : t('stream.fields.unencrypted'),
                    when: values.encapsulation === StreamEncapsulation.SRT,
                    onChange: () => {
                      fixSrtFields(values.srtMode, values.srtRedundancyMode, values.authentication);
                    },
                  },
                  {
                    type: FormFieldType.INPUT_PASSWORD,
                    name: 'passphrase',
                    id: 'passphrase',
                    autoComplete: 'new-password',
                    placeholder: values.encrypted && values.passphraseSet ? constant.obfuscatedPassphrase : undefined,
                    when: values.encapsulation === StreamEncapsulation.SRT && values.encrypted,
                    required: true,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'authentication',
                    enum: SRTAuthenticationListener,
                    value: values.authentication /* WORKAROUND HAIUI ISSUE */,
                    when:
                      values.encapsulation === StreamEncapsulation.SRT &&
                      values.encrypted &&
                      values.srtMode === SRTMode.LISTENER,
                  },
                  {
                    type: FormFieldType.DROPDOWN,
                    name: 'authentication',
                    enum: SRTAuthenticationOthers,
                    value: values.authentication /* WORKAROUND HAIUI ISSUE */,
                    when:
                      values.encapsulation === StreamEncapsulation.SRT &&
                      values.encrypted &&
                      values.srtMode !== SRTMode.LISTENER,
                  },
                  {
                    type: FormFieldType.TITLE,
                    label: t('stream.sections.srtAccessControl'),
                    when: values.encapsulation === StreamEncapsulation.SRT && values.srtMode !== SRTMode.RENDEZVOUS,
                  },
                  {
                    type: FormFieldType.NODE,
                    node: <StreamEditAccessControlForm ref={accessControlFormRef} {...formContext} />,
                  },
                  {
                    type: FormFieldType.LINE_BREAK,
                  },
                  {
                    type: FormFieldType.TITLE,
                    label: t('stream.sections.streamConversion'),
                    className: 'hai-mb-0',
                    when: values.encapsulation === StreamEncapsulation.SRT,
                  },
                  {
                    type: FormFieldType.SWITCH,
                    name: 'udpStreamConversion',
                    label: t('stream.fields.udpStreamConversion'),
                  },
                  { type: FormFieldType.LINE_BREAK },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'srtToUdpAddress',
                    mask: true,
                    when: values.udpStreamConversion,
                    required: true,
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'srtToUdpPort',
                    mask: true,
                    when: values.udpStreamConversion,
                  },
                  {
                    type: FormFieldType.INPUT_TEXT,
                    name: 'srtToUdpTos',
                    when: values.udpStreamConversion,
                    hint: t('stream.fields.srtToUdpTosExplain'),
                  },
                  {
                    type: FormFieldType.INPUT_NUMBER,
                    name: 'srtToUdpTtl',
                    when: values.udpStreamConversion,
                    hint: t('stream.fields.srtToUdpTtlExplain'),
                  },
                ]}
              />
            </>
          );
        }}
      </FormContext.Consumer>
    </Form>
  );
});

StreamEdit.displayName = 'StreamEdit';
