import { Banner, Icon } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import MakitoLogoX4 from 'src/images/makito-x4-logo.svg';
import { apiClientWithoutHandler } from 'src/js/api';
import { constant } from 'src/js/constant';
import useSWR from 'swr';

interface Props {
  children?: any;
}

export const BannerIfAny: React.FunctionComponent<Props> = (props: Props) => {
  const { children } = props;
  const [show, setShow] = useState(true);

  const escapeEl = document.createElement('textarea');

  const fetcher = (url: string) => {
    return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
      return res?.data;
    });
  };

  const { data } = useSWR('/apis/banner', fetcher, { revalidateOnFocus: false });

  useEffect(() => {
    if (data && (!data.banner || !data.enabled)) {
      setShow(false);
    }
  }, [data]);

  const unescapeHTML = (html: any) => {
    escapeEl.innerHTML = html;
    return escapeEl.textContent;
  };

  const onAccept = () => {
    setShow(false);
  };

  if (isNil(data)) {
    return null;
  }

  const banner = data?.banner ? unescapeHTML(data.banner) : '';

  return (
    <>
      {show ? (
        <>
          <div className="d-flex justify-content-center align-items-center h-100">
            <Banner
              useFsMask={true}
              accentColor={constant.productColor}
              appLogo={<Icon src={MakitoLogoX4} />}
              onConfirm={onAccept}
            >
              {banner}
            </Banner>
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
