import { ApiErrorResponse, ApiOkResponse } from 'apisauce';
import React, { Ref, forwardRef } from 'react';

import { StreamEdit } from './stream-edit';
import { GetStreamResponse, StreamViewModel } from './stream-models';

interface Props {
  model: StreamViewModel;
}
export interface StreamAddFormRef {
  submitAddStream: () => Promise<ApiErrorResponse<GetStreamResponse> | ApiOkResponse<GetStreamResponse>>;
}
export const AddStream = forwardRef((props: Props, ref: Ref<StreamAddFormRef>) => {
  const { model } = props;

  return (
    <div className="mk-add-stream">
      <StreamEdit ref={ref} model={model} />
    </div>
  );
});

AddStream.displayName = 'AddStream';
