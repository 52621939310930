export const settings = {
  settings: {
    status: {
      title: 'System',
    },
    system: {
      title: 'System',
      button: {
        locate: 'LOCATE',
        reboot: 'REBOOT',
        snapshot: 'SNAPSHOT',
      },
      section: {
        deviceName: {
          deviceName: 'Device Name',
        },
        deviceStatus: {
          bootVersion: 'Boot Version',
          cardStatus: 'Card Status',
          cardType: 'Card Type',
          cpldVersion: 'CPLD Version',
          firmwareDate: 'Firmware Date',
          firmwareVersion: 'Firmware Version',
          hardwareCompatibility: 'Hardware Compatibility',
          hardwareVersion: 'Hardware Version',
          partNumber: 'Part Number',
          serialNumber: 'Serial Number',
          systemUptime: 'System Uptime',
          temperature: 'Temperature',
          title: 'General',
        },
        userSettings: {
          title: 'User Preferences',
          brightness: 'Brightness',
          contrast: 'Contrast',
          clearStorage: 'Stored Preferences',
          clearStorageSuccess: 'Stored user preferences cleared',
        },
      },
      locateModal: {
        validation: 'Duration must be between 1 and 60',
        notStarted: {
          title: 'Device Locator',
          body1: 'Blink decoder LED for',
          body2: 'minutes.',
        },
        started: {
          title: 'Device Locator',
          body: 'Blinking of decoder LED will stop in {{time}}.',
        },
      },
      notifications: {
        updateSuccessMsg: 'System settings updated',
        updateErrorMsg: 'System settings could not be updated',
      },
    },
    upgrade: {
      title: 'Update',
      section: {
        currentVersion: {
          currentVersion: 'Current Version',
          packageToInstall: '{{fileName}} is pending installation. Reboot to install, or',
          packageToInstallCancel: 'cancel update',
        },
      },
      modal: {
        failedInstallStep: 'Failed to verify/install package',
        fileError: '{{filename}} is not a valid Makito {{product}} Decoder package',
        reboot: 'Please reboot to complete the update.',
        rebootLater: 'Reboot Later',
        title: 'Update Software',
        uploadHai: 'Upload a .hai file',
        uploadNotice: {
          willStop: 'All active decoders will be stopped temporarily during the upload and verification process',
          stopping: 'Stopping active decoders.',
        },
        uploadingTitle: {
          step1: 'Step 1 of 3: Uploading {{fileName}}',
          step2: 'Step 2 of 3: Verifying {{fileName}} file',
          step3: 'Step 3 of 3: Syncing file system',
          step4: 'It looks like there was an issue with {{fileName}}',
        },
        uploadingSubTitle: {
          step1: 'Please reboot to complete the update',
          step2: 'This will take a few minutes',
          step3: 'This will take a minute',
        },
        uploadCompleted: '{{fileName}} package is ready!',
      },
      cancelModal: {
        headline: 'Cancel Update?',
        bodyText: 'Canceling will no longer install {{fileName}} on reboot.',
        keepUpdate: 'KEEP UPDATE',
        cancelUpdate: 'CANCEL UPDATE',
      },
    },
    network: {
      title: 'Network',
      section: {
        general: 'General',
        interface: 'Interface',
        ethernet: 'Ethernet',
        dns: 'DNS',
      },
      addressing: {
        dhcp: 'DHCP',
        static: 'Static',
      },
      ipv6Addressing: {
        auto: 'Automatic',
        dhcp: 'Automatic (DHCP)',
        static: 'Static',
      },
      link: {
        manual: 'Manual',
        auto: 'Automatic',
      },
      speed: {
        any: 'Any',
        '10': '10 Mbps',
        '100': '100 Mbps',
        '1000': '1000 Mbps',
      },
      duplex: {
        full: 'Full',
        half: 'Half',
      },
      dnsPrecedence: {
        ipv4: 'IPv4',
        ipv6: 'IPv6',
      },
      form: {
        // TODO rename some to match the model name so rest api, form and translation use the same name and can be easily followed
        addressing: 'Addressing',
        alternateDns: 'Alternate DNS Server Address',
        bandwidth: 'Total TX Bandwidth',
        bandwidthExplain: 'kbps',
        currentSpeed: 'Current Speed',
        currentSpeedValue: '{{value}} Mbps',
        dhcpVendor: 'DHCP Vendor Class ID',
        dnsPrecedence: 'DNS Precedence',
        domain: 'Domain',
        duplex: 'Duplex',
        ipv6PrivacyExtension: 'Enable Privacy Extensions',
        gateway: 'Gateway',
        globalIpv6Address: 'Global IPv6 Address',
        hostname: 'Hostname',
        ipAddress: 'IP Address',
        ipv4: 'IPv4',
        ipv6: 'IPv6',
        ipv6AddressList: 'Global IPv6 Address',
        link: 'Link',
        linklocal: 'Assign Link-Local Address When DHCP Fails',
        ipv6LinkLocal: 'Link-Local IPv6 Address',
        macAddress: 'MAC Address',
        mdns: 'Enable mDNS',
        mDnsName: 'mDNS Identifier',
        netmask: 'Netmask',
        obtainDns: 'Obtain DNS Settings Automatically',
        primaryDns: 'Primary DNS Server Address',
        speed: 'Speed',
        subnetPrefixLength: 'Subnet Prefix Length',
      },
      mdnsModal: {
        title: 'Warning',
        body: 'With DHCP enabled, you may wish to consider also enabling mDNS to allow this decoder to be discovered by element management systems and other mDNS compliant applications. Be sure to include a unique name for this device in the "mDNS Identifier" field.',
        cancelButtonTitle: 'CLOSE',
        dontShowAgainButtonTitle: "DON'T SHOW AGAIN",
      },
      options: {
        all: 'All',
        eth0: 'eth0',
        eth1: 'eth1',
      },
    },
    stillImages: {
      title: 'Still Images',
      notifications: {
        deleteSuccessMsg: 'Success deleting still image',
        deleteErrorMsg: 'Image could not be deleted',
        uploadSuccessMsg: 'Image successfully uploaded',
        uploadErrorMsg: 'Image could not be uploaded',
      },
      resolution: 'Target Resolution',
      import: {
        title: 'Import a Still Image',
      },
      select: {
        title: 'Select a Still Image',
      },
      delete: {
        title: 'Delete this image?',
      },
      overwrite: {
        title: 'Confirmation',
        bodyText: "'{{name}}' already exists. Do you want to replace it?",
      },
    },
    dateTime: {
      title: 'Date and Time',
      notifications: {
        ntpTestSuccess: 'Valid NTP server',
      },
      section: {
        timezone: {
          title: 'TIMEZONE',
          timezoneLocale: 'Timezone - Locale',
          currentTime: 'Current Time',
        },
        server: {
          title: 'TIME SERVER',
          ntpServer: 'NTP Server',
          ntpServerPlaceholder: 'Add NTP Server',
          useNtpServer: 'Use NTP',
          manualDateTime: 'Manually Set Date & Time',
        },
        ptp: {
          title: 'PTP',
          clockState: 'Clock State',
          clockId: 'Clock ID',
        },
        statistics: {
          title: 'NTP Statistics',
          tracking: 'Tracking',
          sources: 'Sources',
          sourceStats: 'Source Statistics',
        },
      },
    },
    services: {
      title: 'Services',
      condirmHttpOffModal: {
        title: 'Warning',
        bodyText:
          'Once you stop the HTTP service you will no longer be able to access this device via its web interface. Web access can only be restored using the command line console. Before continuing, verify that you have SSH (or Telnet) access to this device.',
        proceedButton: 'STOP HTTP',
      },
      section: {
        general: {
          ems: 'EMS',
          http: 'HTTP',
          interface: 'Interface',
          preview: 'Thumbnail Preview',
          snmp: 'SNMP',
          ssh: 'SSH',
          telnet: 'Telnet',
          title: 'General',
        },
        ems: {
          title: 'EMS / Cloud Services',
          passcode: {
            label: 'Passcode',
            labelExplain: 'Copy from Haivision EMS or Cloud Services',
            validationName: 'Passcode',
          },
          connectionStatus: {
            label: 'Connection Status',
          },
          hostname: {
            label: 'Address',
            validationName: 'Address',
          },
          port: {
            label: 'Port',
            validationName: 'Port',
          },
          keepAlive: {
            label: 'Keep Alive (Seconds)',
            validationName: 'Keep Alive',
          },
          button: {
            pair: 'PAIR',
            unpair: 'UNPAIR',
            cancelPairing: 'CANCEL PAIRING',
          },
        },
        preview: {
          title: 'Thumbnail Preview',
          interval: 'Interval (Seconds)',
          intervalExplain: '1 - 60',
        },
      },
      notifications: {
        analyticsDisableSuccessMsg: 'Product analytics disabled',
        analyticsEnableSuccessMsg: 'Product analytics enabled',
        analyticsErrorMsg: 'Product analytics could not be changed',
        disableErrorMsg: '{{service}} service could not be disabled',
        disableSuccessMsg: '{{service}} service disabled',
        enableErrorMsg: '{{service}} service could not be enabled',
        enableSuccessMsg: '{{service}} service enabled',
        nicErrorMsg: 'Network interface could not be chnaged',
        nicSuccessMsg: 'Network interface changed',
        previewErrorMsg: 'Thumbnail Preview parameters could not be changed',
        previewSuccessMsg: 'Thumbnail Preview parameters changed',
      },
    },
    licensing: {
      title: 'Licensing',
      addFirst: 'No Licenses to Display',
      licenseFeatures: 'License Features',
      sectionTitle: 'INSTALLED LICENSES',
      modal: {
        delete: {
          title: 'Delete this license?',
          title_plural: 'Delete {{count}} licenses?',
          bodyText: 'Deleting licenses can disable important features needed for this device to function properly.',
        },
        import: {
          title: 'Install a License',
        },
      },
      notifications: {
        deleteErrorMsg: '{{count}} license could not be deleted',
        deleteErrorMsg_plural: '{{count}} licenses could not be deleted',
        installErrorMsg: "License '{{name}}' could not be installed",
      },
    },
    reboot: {
      title: 'Rebooting',
      confirm: {
        title: 'Confirm Reboot',
        body: 'Reboot the device?',
      },
      notSaved: {
        body: 'The current running configuration was not saved and will be lost if you reboot.',
      },
      header: 'The system is rebooting.',
      subheader1: 'This page will refresh in 2 minutes.',
      upgrade: {
        header: 'Please do not reboot again or shut down the system during the update process.',
        subheader1: 'The system is rebooting.',
        subheader2: 'The page will refresh automatically when the upgrade is complete',
        subheader3: 'The upgrade process can take up to 15 minutes.',
      },
    },
  },
};
