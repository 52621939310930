import classNames from 'classnames';
import { includes } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adjustInactiveColors, toggleItem } from 'src/js/helper/chart-helper';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { mapToVictoryData, mapToVictoryInMinutesReduce } from 'src/js/pages/statistic/statistic-mapper';
import { defaultBarChartColors, horizontalAxisStyle, verticalAxisStyle } from 'src/js/theme/chart';
import { constant } from 'src/js/constant';
import { ChartViewModel } from 'src/js/view-model/chart-view-model';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryVoronoiContainer } from 'victory';

import { LegendHorizontal } from './legend-horizontal';
import { LegendVertical } from './legend-vertical';
import { generateDataAuto } from 'src/js/util/automation';

// Ref.: https://commerce.nearform.com/open-source/victory/gallery/stacked-grouped-bars

interface MkAxisRange {
  limit: number;
  divideBy: number;
  label: string;
}
/*
 * Line Chart component for statistics page
 *
 * data: StatsChartViewModel
 * title: Chart title
 * subTitle: Chart subtitle (Used on dashboard)
 * fontSize: size of font for x/y axis labels
 * yTickCount: number of ticks on the y axis
 * padding: padding around graph
 * yFields: fields to render from the data
 * height: height of chart in pixels
 * width: width of chart in pixels
 * xLabel: x axis label
 * yLabel: y axis label
 * showYLabels: whether to show y axis labels
 * chartProps: additional chart props conforming to VictoryThemeDefinition
 * showLegend: whether to display or hide the legend
 *
 */

interface BarGraphProps {
  id?: string | number;
  data: ChartViewModel[];
  title?: string;
  subTitle?: string;
  fontSize?: number;
  xTickCount?: number;
  yTickCount?: number;
  padding?: any;
  timescale: StatsInterval;
  xAxisMinutes?: boolean;
  height?: number;
  width?: number;
  xLabel?: string;
  yLabel?: string;
  yTitle?: string;
  pathSelected?: string[];
  yRange?: MkAxisRange[];
  showLegend?: boolean | 'left';
  showRedundancy?: boolean;
  dashboard?: boolean;
  showCommasInAxisLabels?: boolean;
  lineColors: string[];
  defaultInactiveFields?: string[];
  /** will actively maintain take aspect ratio of parent container
  responsive?: boolean; */
}

type TimeAxisPoint = {
  unix: number;
  label: string;
};

const allPaths = ['path1', 'path2'];

export const BarGraph: React.FunctionComponent<BarGraphProps> = (props) => {
  const {
    id,
    data,
    title,
    subTitle,
    fontSize,
    xTickCount = 5,
    yTickCount = 5,
    padding = { top: 13, bottom: 16, right: 0, left: 50 },
    timescale,
    xAxisMinutes = false,
    height,
    width,
    yLabel,
    xLabel,
    yTitle,
    yRange,
    pathSelected = allPaths,
    showLegend = true,
    showRedundancy = false,
    dashboard = false,
    showCommasInAxisLabels = true,
    lineColors = defaultBarChartColors,
    defaultInactiveFields = [],
    ...rest
  } = props;

  const { t } = useTranslation();
  const yFieldsPath1 = data.map((d) => d.name).filter((n) => !n.endsWith('_Path2'));
  const yFieldsPath2 = data.map((d) => d.name).filter((n) => n.endsWith('_Path2'));

  const [inactiveFields, setInactiveFields] = useState<string[]>(defaultInactiveFields);
  const [inactivePathFields, setInactivePathFields] = useState<string[]>([]);
  // console.log('inactive fields', inactiveFields, inactivePathFields);

  const barWidth = 5;

  if (yTitle) {
    padding.top += 12;
  }

  // This part is unused for bar graph
  useEffect(() => {
    setInactivePathFields((inactiveFields) => {
      if (pathSelected.includes('path1')) {
        // remove all path1 from inactive fields
        inactiveFields = inactiveFields.filter((f) => !yFieldsPath1.includes(f));
      } else {
        // add all path 1 to inactive fields
        inactiveFields = inactiveFields.concat(yFieldsPath1);
      }

      if (pathSelected.includes('path2')) {
        // remove all path 2 from inactive fields
        inactiveFields = inactiveFields.filter((f) => !yFieldsPath2.includes(f));
      } else {
        // add all path 2 to inactive fields
        inactiveFields = inactiveFields.concat(yFieldsPath2);
      }
      return inactiveFields;
    });
  }, [pathSelected]);

  let visibleData = data.filter((chartViewModel: ChartViewModel) => !includes(chartViewModel.name, inactiveFields));
  visibleData = visibleData.filter(
    (chartViewModel: ChartViewModel) => !includes(chartViewModel.name, inactivePathFields),
  ); // filter out unselected path as per path selection dropdown

  // this convert to {x:,y:} format that VictoryChart expects
  //const barWidthMinutes = 1;
  let maxYvalue = 0;
  let targetRange: MkAxisRange = null;
  let tickValues: TimeAxisPoint[] = undefined;
  const series = visibleData.map((viewModels) => {
    if (xAxisMinutes && viewModels.values !== undefined && viewModels.values.length) {
      let firstPoint = viewModels.values[0].collectedAt;
      const lastPoint = viewModels.values[viewModels.values.length - 1].collectedAt;
      if (lastPoint > firstPoint) {
        firstPoint = lastPoint; // loop? need unit test
      }
      tickValues = [0, 1, 2, 3, 4, 5].map((c) => ({
        // TODO make dynamic instead of hardcoded
        unix: firstPoint.clone().subtract(c, 'minutes').unix(),
        label: c.toString(),
      }));
      //console.log('tickValues', tickValues);
    }

    viewModels.values.map((yPoint) => {
      if (yPoint.value > maxYvalue) {
        maxYvalue = yPoint.value;
      }
    });

    return {
      data: xAxisMinutes
        ? mapToVictoryInMinutesReduce(viewModels.values, constant.barGraphGroupWidthSec, viewModels.label)
        : mapToVictoryData(viewModels.values, timescale, viewModels.label),
      name: viewModels.name,
      style: viewModels.name.includes('_Path2') ? { data: { strokeDasharray: '5,3' } } : undefined,
    };
  });

  let yScale = 'K'; // 999 kbps and less
  if (maxYvalue >= 100000) {
    yScale = '10M'; // 10,000 kbps +
  } else if (maxYvalue >= 10000) {
    yScale = 'M'; // 1,000 kbps +
  }

  if (yRange) {
    yRange.forEach((range) => {
      if (maxYvalue > range.limit) {
        if (targetRange === null) {
          targetRange = range;
        } else if (range.limit > targetRange.limit) {
          // keep highest limit
          targetRange = range;
        }
      }
    });

    if (targetRange) {
      series.map((line) => {
        line.data.map((point) => {
          if (point.yOriginal === undefined) {
            point.yOriginal = point.y;
          }
          point.y /= targetRange.divideBy;
        });
      });
    }
  }

  // filter out all _Path2 from data to get the line y-values names & labels (Latency, RTT, etc..)
  const yFields = data.filter((chartViewModel) => !showRedundancy || !chartViewModel.name.endsWith('_Path2'));

  const yFieldNames = yFields.map((chartViewModel) => chartViewModel.name);

  // Map legend labels from array to object
  let legendItems = yFields.map((yField) => ({ label: yField.label, value: yField.name }));

  // remove legend item for lines without data
  legendItems = legendItems.filter((legend) => {
    const populatedSeries = data.filter((serie) => serie.values.length > 0);
    return populatedSeries.find((serie) => serie.name === legend.value);
  });
  // legendItem = yFields.map((yField) => ({ label: yField.label, value: yField.name }));

  // removed unselected series from the color array so the index match and graph color is consistent when hidding fields
  const adjustedColorScale = adjustInactiveColors(yFieldNames, inactiveFields, lineColors); // FIXME also add inactive from path selections
  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  /* NKE-2421 to make large labels fit */
  if (yScale === '10M') {
    padding.left = 70;
  } else if (yScale === 'M') {
    padding.left = 60;
  } else {
    padding.left = 33;
  }
  if (dashboard) {
    padding.left += 1;
  }

  const getPointFromTime = (x: number) => {
    const point = series.map((d) => d.data.find((v) => v.x === x));
    if (point && Array.isArray(point) && point.length && point[0] === undefined) {
      // false find, point is gone
      return null;
    }
    if (point && Array.isArray(point) && point.length) {
      return point;
    }
    return null;
  };

  const emptyGraph = !(series?.length >= 1 && series[0].data?.length > 0);

  return (
    <div className={classNames('mk-chart-container', showLegend === 'left' && 'leftLegend')} {...rest}>
      {title && (
        <div className="statistics-graph-header" title={title}>
          {title}
        </div>
      )}
      {subTitle && (
        <div className="statistics-graph-subheader" title={subTitle}>
          {subTitle}
        </div>
      )}
      <div className="mk-victory-container" data-auto={generateDataAuto('bar', 'graph')}>
        <VictoryChart
          domainPadding={{ x: 10, y: 7 }}
          height={height}
          width={width}
          padding={padding}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) => {
                const point = getPointFromTime(datum.x);
                if (point) {
                  const grouped = point.map((p) => `${p.lineName as string}: ${p.y as number}`).join('\n'); // print all grouped stats in same bubble/tooltip
                  const duplicated: any = {};
                  point.forEach((p) => {
                    duplicated[p.y] = (duplicated[p.y] || 0) + 1;
                  });
                  let duplicatedCount = 0;
                  for (const n in duplicated) {
                    if (duplicated[n] > 1) {
                      duplicatedCount++;
                    }
                  }
                  const sumValues = point.reduce((acc, p) => acc + p.y, 0);
                  if (sumValues > 0 && duplicatedCount === 0) {
                    // to prevent more than 3 values in tooltip
                    return grouped;
                  }
                }
                return `${datum.lineName as string}: ${datum.y as number}`;
              }}
            />
          }
        >
          {!emptyGraph && (
            <VictoryGroup offset={barWidth} style={{ data: { width: barWidth } }}>
              {series?.map((lines, index: number) => {
                return (
                  <VictoryBar key={index} data={lines.data} style={{ data: { fill: adjustedColorScale[index] } }} />
                );
              })}
            </VictoryGroup>
          )}

          <VictoryAxis
            dependentAxis
            label={yLabel}
            style={{
              ...verticalAxisStyle,
              tickLabels: {
                ...verticalAxisStyle.tickLabels,
                fontSize: fontSize ? fontSize : verticalAxisStyle.tickLabels.fontSize,
              },
              axis: {
                stroke: 'transparent',
              },
            }}
            tickValues={maxYvalue > 10 ? undefined : [0, 5, 10]} // if values are too small, keep minimum scale of 0 to 10
            // We round to have 3 decimals maximum, no need for more precision, it makes the graphs look odd
            // We use the `Math.round` to not add the trailing .0 if not needed
            tickFormat={(tick) => {
              const value = Math.round(Number(tick) * 1000) / 1000;
              if (showCommasInAxisLabels === false) {
                return value;
              }
              return numberWithCommas(value);
            }}
            tickCount={yTickCount}
          />
          <VictoryAxis
            style={{
              ...horizontalAxisStyle,
              tickLabels: {
                ...horizontalAxisStyle.tickLabels,
                fontSize: fontSize ? fontSize : horizontalAxisStyle.tickLabels.fontSize,
              },
            }}
            tickValues={tickValues?.map((t) => t.unix)}
            tickCount={tickValues ? tickValues.length : xTickCount}
            tickFormat={(tick) => {
              if (emptyGraph) {
                return '';
              }
              const item = tickValues?.find((t) => t.unix === tick);
              if (item) {
                return item.label + 'm';
              }
              return tick;
            }}
            padding={{ left: 20, bottom: 20, top: 20 }}
          />
        </VictoryChart>
      </div>
      {showLegend &&
        (showLegend === 'left' ? (
          <LegendVertical
            compact
            handleClick={(item) => {
              if (legendItems && legendItems.length === 1) {
                // dont allow turning off single legend item
                return;
              }
              // one legend item controls both path on/off
              setInactiveFields((inactiveFields) => toggleItem(item, inactiveFields));
              setInactiveFields((inactiveFields) => toggleItem(item + '_Path2', inactiveFields));
            }}
            items={legendItems}
            colorScale={lineColors}
            inactiveItems={inactiveFields}
            title={t('statistics.bandwidth.legendTitle')}
          />
        ) : (
          <LegendHorizontal
            handleClick={(item) => {
              if (legendItems && legendItems.length === 1) {
                // dont allow turning off single legend item
                return;
              }
              // one legend item controls both path on/off
              setInactiveFields((inactiveFields) => toggleItem(item, inactiveFields));
              setInactiveFields((inactiveFields) => toggleItem(item + '_Path2', inactiveFields));
            }}
            items={legendItems}
            colorScale={lineColors}
            inactiveItems={inactiveFields}
          />
        ))}
    </div>
  );
};
