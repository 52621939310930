import { observer } from 'mobx-react';
import { isNil, omit } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { RebootResponse } from 'src/js/model/api/response/reboot-response';
import { UpdateDateTime } from 'src/js/model/update-date-time';
import { SettingsTabContent } from 'src/js/pages/settings/settings-tab-content';
import { IsSavedRef, RebootIsSaved } from 'src/js/reboot/reboot-is-saved';
import { RebootNeeded, RebootNeededRef } from 'src/js/reboot/reboot-needed';
import { Paths } from 'src/js/route';
import { createUpdateDateTimeTask, createValidateNtpServerTask } from 'src/js/task/settings-date-time-tasks';
import { createRebootTask } from 'src/js/task/settings-tasks';

import { DateAndTimeForm, DateAndTimeFormValues } from './date-and-time-form';
import { DateTime, createDefaultDateTime } from './date-and-time-view-model';
import { fetchDateTime } from './datetime-store';
import { NtpStatisticsModal } from './ntp-statistics-modal';

export const DateAndTime: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [dateTime, setDateTime] = useState<DateTime>();
  useEffect(() => {
    fetchDateTime(setDateTime).then((res) => {
      if (res.ok) {
        setLoading(false);
      }
    });
  }, []);

  const updateDateTimeTask = useTask(createUpdateDateTimeTask()); // TODO: replace with generic controller, remove rxjs
  const rebootTask = useTask(createRebootTask());
  const validateNtpServerTask = useTask(createValidateNtpServerTask(t));
  const [statisticsModalShown, showStatisticsModal, hideStatisticsModal] = useModalControls();

  const handleSubmit = (data: DateAndTimeFormValues): void => {
    updateDateTimeTask(data.timezone, data.ntp, data.server, data.time);
  };

  useSuccessReaction(rebootTask, (result: RebootResponse) => {
    navigate(result.upgrade ? Paths.rebootingUpgrade : Paths.rebooting);
  });

  const rebootNeededRef = useRef<RebootNeededRef>(null);

  // the reboot handling for the reboot button
  const childRef = useRef<IsSavedRef>(null);
  const onRebootClicked = (): void => {
    childRef.current.show();
  };

  useSuccessReaction(updateDateTimeTask, (response: UpdateDateTime) => {
    updateDateTimeTask.reset();
    setDateTime(omit(['saved'], response));
    rebootNeededRef.current.show();
  });

  return (
    <SettingsTabContent name={t('settings.dateTime.title')} id="datetime">
      <DateAndTimeForm
        loading={loading}
        data={isNil(dateTime) ? createDefaultDateTime() : dateTime}
        translator={t}
        onReboot={onRebootClicked}
        onStatistics={showStatisticsModal}
        statisticsDisabled={!dateTime?.chronydRunning ?? true}
        onSubmit={handleSubmit}
        submitTask={updateDateTimeTask}
        validateNtpServerTask={validateNtpServerTask}
      />
      {statisticsModalShown && <NtpStatisticsModal onClose={hideStatisticsModal} />}
      <RebootIsSaved ref={childRef} />
      <RebootNeeded ref={rebootNeededRef} />
    </SettingsTabContent>
  );
});
