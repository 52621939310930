import React, { ReactNode } from 'react';
import { useMutable } from 'src/js/hook/use-mutable';
import { ListSelectionWithDataStore } from 'src/js/store/list-selection-with-data-store';

const storeContext = React.createContext(null);

interface Props<T> {
  render?: (store: ListSelectionWithDataStore<T>) => ReactNode;
  children: ReactNode;
}

export const ListSelectionWithDataStoreProvider = <T extends object>(props: Props<T>) => {
  const { render, children } = props;
  const [store] = useMutable<ListSelectionWithDataStore<T>>(new ListSelectionWithDataStore<T>());
  if (render) {
    return <storeContext.Provider value={store()}>{render(store())}</storeContext.Provider>;
  }
  return <storeContext.Provider value={store()}>{children}</storeContext.Provider>;
};

export const useListSelectionWithDataStore = <T extends object>(): ListSelectionWithDataStore<T> => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};
