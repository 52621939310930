import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormSelect } from 'src/js/component/base/form/form-select';
import { FormSwitch } from 'src/js/component/base/form/form-switch';
import systemInfo from 'src/js/data/systemInfo';
import { Service } from 'src/js/model/service';
import { ServicesViewModel } from 'src/js/pages/settings/services/services-view-model';
import { generateDataAuto } from 'src/js/util/automation';
import { FormChangeEvent } from 'src/js/util/global-type';

import { NicOptions } from './services';

interface Props {
  services: ServicesViewModel;
  onServiceChange: (service: Service, value: boolean) => Promise<any> | void;
  onServiceNicChange: (event: FormChangeEvent) => void;
  nicOptions: NicOptions;
  loading: boolean;
}

export const GeneralServices = (props: Props) => {
  const { services, onServiceChange, onServiceNicChange, nicOptions, loading } = props;

  const { t } = useTranslation();

  return (
    <Form className="hai-mb-6">
      <FormSection
        title={t('settings.services.section.general.title')}
        data-auto={generateDataAuto('form', 'section general')}
      >
        {systemInfo.hasEth1 ? (
          <>
            <DynamicContainer minColumns={6}>
              <FormSwitch
                checked={services.http}
                loading={loading}
                switchLabel={t('settings.services.section.general.http')}
                switchClassName="equal-width"
                container={true}
                onChange={(checked: boolean) => {
                  return onServiceChange(Service.HTTP, checked);
                }}
              />
              <FormSelect
                name={Service.HTTP}
                title={t('settings.services.section.general.interface')}
                defaultSelect={t(`settings.network.options.${services.nic?.http || 'all'}`)}
                options={nicOptions}
                onChange={onServiceNicChange}
              />
            </DynamicContainer>
            <DynamicContainer minColumns={6}>
              <FormSwitch
                checked={services.ssh}
                loading={loading}
                switchLabel={t('settings.services.section.general.ssh')}
                switchClassName="equal-width"
                container={true}
                onChange={(checked: boolean) => {
                  return onServiceChange(Service.SSH, checked);
                }}
              />
              <FormSelect
                name={Service.SSH}
                title={t('settings.services.section.general.interface')}
                defaultSelect={t(`settings.network.options.${services.nic?.ssh || 'all'}`)}
                options={nicOptions}
                onChange={onServiceNicChange}
              />
            </DynamicContainer>
            <DynamicContainer minColumns={6}>
              <FormSwitch
                checked={services.telnet}
                loading={loading}
                switchLabel={t('settings.services.section.general.telnet')}
                switchClassName="equal-width"
                container={true}
                onChange={(checked: boolean) => {
                  onServiceChange(Service.TELNET, checked);
                }}
              />
              <FormSelect
                name={Service.TELNET}
                title={t('settings.services.section.general.interface')}
                defaultSelect={t(`settings.network.options.${services.nic?.telnet || 'all'}`)}
                options={nicOptions}
                onChange={onServiceNicChange}
              />
            </DynamicContainer>
            <DynamicContainer minColumns={6}>
              <FormSwitch
                checked={services.snmp}
                loading={loading}
                switchLabel={t('settings.services.section.general.snmp')}
                switchClassName="equal-width"
                container={true}
                onChange={(checked: boolean) => {
                  onServiceChange(Service.SNMP, checked);
                }}
              />
              <FormSelect
                name={Service.SNMP}
                title={t('settings.services.section.general.interface')}
                defaultSelect={t(`settings.network.options.${services.nic?.snmp || 'all'}`)}
                options={nicOptions}
                onChange={onServiceNicChange}
              />
            </DynamicContainer>
            <DynamicContainer minColumns={6}>
              <FormSwitch
                checked={services.ems}
                switchLabel={t('settings.services.section.general.ems')}
                switchClassName="equal-width"
                container={true}
                onChange={(checked: boolean) => {
                  onServiceChange(Service.EMS, checked);
                }}
              />
              <FormSelect
                name={Service.EMS}
                title={t('settings.services.section.general.interface')}
                defaultSelect={t(`settings.network.options.${services.nic?.ems || 'all'}`)}
                options={nicOptions}
                onChange={onServiceNicChange}
              />
            </DynamicContainer>
          </>
        ) : (
          <>
            <DynamicContainer minColumns={6} maxColumns={6}>
              <FormSwitch
                checked={services.http}
                switchLabel={t('settings.services.section.general.http')}
                onChange={(checked: boolean) => {
                  onServiceChange(Service.HTTP, checked);
                }}
              />
              <FormSwitch
                checked={services.ssh}
                switchLabel={t('settings.services.section.general.ssh')}
                onChange={(checked: boolean) => {
                  onServiceChange(Service.SSH, checked);
                }}
              />
              <FormSwitch
                checked={services.telnet}
                switchLabel={t('settings.services.section.general.telnet')}
                onChange={(checked: boolean) => {
                  onServiceChange(Service.TELNET, checked);
                }}
              />
              <FormSwitch
                checked={services.snmp}
                switchLabel={t('settings.services.section.general.snmp')}
                onChange={(checked: boolean) => {
                  onServiceChange(Service.SNMP, checked);
                }}
              />
              <FormSwitch
                checked={services.ems}
                switchLabel={t('settings.services.section.general.ems')}
                onChange={(checked: boolean) => {
                  onServiceChange(Service.EMS, checked);
                }}
              />
            </DynamicContainer>
          </>
        )}
      </FormSection>
    </Form>
  );
};
