import { TFunction } from 'i18next';
import { clone } from 'ramda';
import { Dispatch, SetStateAction } from 'react';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { triggerEvent } from 'src/js/events';
import { convertCSVStringToData } from 'src/js/helper/csv-helper';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { createWatchStore, updateWatchStore } from 'src/js/store/use-watch';
import { MakitoArrayStore } from 'src/js/util/generic-store';

import {
  DecoderResponse,
  DecoderViewModel,
  GetAllDecodersResponse,
  mapDecoderViewModel,
  mapDecodersResponse,
  DecoderFrameGraphResponse,
} from './decoder-model';

// values will be inited from API at runtime
const decoderModels: DecoderViewModel[] = [];
createWatchStore(decoderModels);
export default decoderModels;

export const decodersStore = new MakitoArrayStore(decoderModels, ['stats', 'state', 'updated', 'hidden', 'selected']);
export const decoderName = (decoderId: number): string => {
  return decoderModels.find((d) => d.id === decoderId)?.name;
};

export const saveResponseToDecoders = (response: DecoderViewModel[]) => {
  updateWatchStore(decoderModels, clone(response));
};

const replaceViewModel = (response: DecoderResponse, t: TFunction, selected = false) => {
  const viewModel = mapDecoderViewModel(response, t);
  const decoders = [...decoderModels];
  const index = decoders.findIndex((d) => d.id === viewModel.id);
  if (index > -1) {
    decoders.splice(index, 1, { ...viewModel, selected: selected });
  }
  saveResponseToDecoders(decoders);
};

export const fetchDecoders = (t: TFunction) => {
  return apiClientWithoutHandler.genericController.get<GetAllDecodersResponse>(APIPath.decoder.list).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        const viewModels = mapDecodersResponse(response, t);
        saveResponseToDecoders(viewModels);
      }
    }
    return res;
  });
};
/*
export const fetchDecoder = (id: number, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.decoder.get, {
    id: id,
  });
  return apiClientWithoutHandler.genericController.get<DecoderResponse>(path).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        replaceViewModel(response, t);
      }
    }
    return res;
  });
};
*/
export const updateDecoder = (model: DecoderViewModel, t: TFunction, displayMsg = true) => {
  const path = APIPathWithArguments(APIPath.decoder.put, { id: model.id });
  return apiClientWithoutHandler.genericController
    .put<DecoderResponse>(path, model)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          replaceViewModel(response, t);
        }
      }
      return res;
    })
    .then((res) => {
      notificationHandler(
        res,
        displayMsg ? t('decoder.notifications.updateSuccess', { name: res.data?.info?.name }) : null,
        t('decoder.notifications.updateError', { name: res.data?.info?.name }),
        t,
      );

      return res;
    });
};

export const startStopDecoder = (model: DecoderViewModel, start: boolean, selected: boolean, t: TFunction) => {
  const path = APIPathWithArguments(start ? APIPath.decoder.start : APIPath.decoder.stop, {
    id: model.id,
  });
  return apiClientWithoutHandler.genericController
    .put<DecoderResponse>(path)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          replaceViewModel(response, t, selected);
        }
      }
      return res;
    })
    .then((res) => {
      triggerEvent('thumbnail-update');
      notificationHandler(res, null, null, t);
      return res;
    });
};

export const fetchDecoderStatistics = (
  model: Partial<DecoderViewModel>,
  t: TFunction,
  setStatistics: Dispatch<SetStateAction<DecoderViewModel>>,
) => {
  const path = APIPathWithArguments(APIPath.decoder.stats, { id: model.id });
  return apiClientWithoutHandler.genericController.get<DecoderResponse>(path).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        setStatistics(mapDecoderViewModel(response, t));
      }
    }
    return res;
  });
};

export const resetDecoderStatistics = (
  model: DecoderViewModel,
  t: TFunction,
  setStatistics?: Dispatch<SetStateAction<DecoderViewModel>>,
) => {
  const path = APIPathWithArguments(APIPath.decoder.stats, { id: model.id });
  return apiClientWithoutHandler.genericController.delete<DecoderResponse>(path).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        setStatistics?.(mapDecoderViewModel(response, t));
      }
    }
    return res;
  });
};

export const fetchDecoderFrameGraph = (
  model: DecoderViewModel,
  interval: string,
  setter?: (values: DecoderFrameGraphResponse[]) => void,
) => {
  return apiClientWithoutHandler.genericController
    .get<string>(APIPathWithArguments(APIPath.decoder.stats, { id: model.id }), {
      interval: interval,
      type: 'frame',
    })
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        const array = convertCSVStringToData<DecoderFrameGraphResponse>(response);
        if (array) {
          setter(array);
        }
      } else {
        setter([]);
      }
      return res;
    });
};
