import classNames from 'classnames';
import React from 'react';

const numLevels = 9;
const errorLevel = 8;
const warningLevel = 5;
const minDbValue = -54;

interface MeterProps {
  value: number;
}

const Meter: React.FunctionComponent<MeterProps> = ({ value }) => {
  interface LevelProps {
    color?: string;
  }
  const Level: React.FunctionComponent<LevelProps> = ({ color }) => {
    return <div className={classNames('indicator', color)} />;
  };
  const thresholdExceeded = (value: number, level: number) => {
    return value >= minDbValue - (minDbValue / numLevels) * level;
  };

  return (
    <>
      {Array.from(Array(numLevels).keys())
        .reverse()
        .map((level) => (
          <Level
            key={level}
            color={
              level >= errorLevel
                ? thresholdExceeded(value, level)
                  ? 'red'
                  : 'gray'
                : level >= warningLevel
                ? thresholdExceeded(value, level)
                  ? 'amber'
                  : 'gray'
                : thresholdExceeded(value, level)
                ? 'green'
                : 'gray'
            }
          />
        ))}
    </>
  );
};

interface MainProps {
  className?: string;
  channel: number;
  meter: any;
  width: number;
}

export const AudioMeter: React.FunctionComponent<MainProps> = ({ className, channel, meter, width }) => {
  return (
    <div className={classNames('audio-meter', className, !meter && 'empty')} style={{ width: width }}>
      <div className="d-flex meters">
        {meter && (
          <>
            <div className="left">
              <Meter value={Number(meter.dbLeft)} />
            </div>
            <div className="right">
              <Meter value={Number(meter.dbRight)} />
            </div>
          </>
        )}
      </div>
      <div className="channel d-flex align-items-center justify-content-center">{meter ? channel : '\u00A0'}</div>
    </div>
  );
};
