import { SRTMode } from './stream-models';

export const buildSRTStreamIDFromParts = (srtMode: SRTMode, userName: string, resourceName: string) => {
  let publishingId = '#!::';
  let publishPart = 'm=publish';
  if (srtMode === SRTMode.CALLER) {
    publishPart = 'm=request';
  }
  if (userName == null || userName?.length === 0) {
    publishingId += 'r=' + resourceName + ',' + publishPart;
  } else if (resourceName == null || resourceName?.length === 0) {
    publishingId += 'u=' + userName + ',' + publishPart;
  } else {
    publishingId += 'u=' + userName + ',r=' + resourceName + ',' + publishPart;
  }
  if ((userName == null && resourceName == null) || (userName?.length === 0 && resourceName?.length === 0)) {
    publishingId = '';
  }

  return publishingId;
};

export const buildSRTStreamIDFromPartsReversed = (srtMode: SRTMode, userName: string, resourceName: string) => {
  let publishingId = '#!::';
  let publishPart = 'm=publish';
  if (srtMode === SRTMode.CALLER) {
    publishPart = 'm=request';
  }
  if (userName == null || userName?.length === 0) {
    publishingId += 'r=' + resourceName + ',' + publishPart;
  } else if (resourceName == null || resourceName?.length === 0) {
    publishingId += 'u=' + userName + ',' + publishPart;
  } else {
    publishingId += 'r=' + resourceName + ',u=' + userName + ',' + publishPart;
  }
  if ((userName == null && resourceName == null) || (userName?.length === 0 && resourceName?.length === 0)) {
    publishingId = '';
  }

  return publishingId;
};
