import { IReactionOptions } from 'mobx';
import { DependencyList, useEffect } from 'react';
import { onErrorReaction, onPendingReaction, onSuccessReaction, RxTaskOrGroup } from 'src/js/helper/mobx-tasks';

/*
 * These hooks are simply syntactic sugar to use reaction directly with hooks
 */
export const useSuccessReaction = <T>(
  task: RxTaskOrGroup<T>,
  callback: (result?: T) => void,
  opts?: IReactionOptions,
  deps: DependencyList = [],
) => {
  useEffect(() => onSuccessReaction(task, callback, opts), deps);
};

export const useErrorReaction = <E extends Error>(
  task: RxTaskOrGroup<any>,
  callback: (error?: E) => void,
  opts?: IReactionOptions,
  deps: DependencyList = [],
) => {
  useEffect(() => onErrorReaction(task, callback, opts), deps);
};

export const usePendingReaction = (
  task: RxTaskOrGroup<any>,
  callback: (pending: boolean) => void,
  opts?: IReactionOptions,
  deps: DependencyList = [],
) => {
  useEffect(() => onPendingReaction(task, callback, opts), deps);
};
