import { PanelGroup } from '@hai/ui-react';
import React from 'react';
import { Subject } from 'rxjs';
import { FormAggregatorClientInterface } from 'src/js/component/multiple-form-aggregator';
import { DecoderItemFetch } from 'src/js/pages/dashboard/decoder-item-fetch';
import { DecoderViewModel } from 'src/js/pages/streaming/decoder/decoder-model';
import { generateDataAuto } from 'src/js/util/automation';

interface DecoderListProps {
  data: DecoderViewModel[];
  onClickGlobalSave: Subject<string>;
  formAggregator: FormAggregatorClientInterface;
}

export const DecoderList: React.FunctionComponent<DecoderListProps> = ({ data, onClickGlobalSave, formAggregator }) => {
  return (
    <PanelGroup className="grid-4x">
      {data.map((decoderViewModel: DecoderViewModel) => (
        <DecoderItemFetch
          id={decoderViewModel.id}
          data-auto={generateDataAuto('decoder', `${decoderViewModel.id}`)}
          key={decoderViewModel.id}
          onClickGlobalSave={onClickGlobalSave}
          formAggregator={formAggregator}
        />
      ))}
    </PanelGroup>
  );
};
