import { Button } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { triggerEvent, watchEvent } from 'src/js/events';
import { useStores } from 'src/js/hook/use-stores';
import { decodersStore } from 'src/js/pages/streaming/decoder/decoder-store';
import { useWatchObject } from 'src/js/store/use-watch';

interface DecoderButtonsProps {
  submitApplyAllDecoders: () => Promise<any>;
  cancelChanges: VoidFunction;
}

export const DecoderButtons: React.FunctionComponent<DecoderButtonsProps> = ({
  submitApplyAllDecoders,
  cancelChanges,
}) => {
  const { sessionStore } = useStores();
  const { t } = useTranslation();
  const [hasDecodersChanged, setHasDecodersChanged] = useState(false);
  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);

  const onSubmit = () => {
    setButtonState('pending');
    submitApplyAllDecoders().then(() => {
      // TODO could use here later instead of watchEvent below
      triggerEvent('preset-update');
    });
  };

  watchEvent('decoders-apply-completed', () => {
    setButtonState(undefined);
  });

  useWatchObject(decodersStore, (_updated) => {
    if (decodersStore.hasItemsChanged()) {
      setHasDecodersChanged(true);
    } else {
      setHasDecodersChanged(false);
    }
  });

  return (
    <>
      {hasDecodersChanged && (
        <div className="hai-mb-4 hai-mt-4" id="decoders-top-buttons">
          <div>
            {sessionStore.isUser() ? null : (
              <div className="d-flex justify-content-end">
                <Button
                  id="cancel-decoders"
                  className="hai-mr-4"
                  onClick={cancelChanges}
                  disabled={buttonState === 'pending'}
                >
                  {t('general.cancel')}
                </Button>
                <Button id="apply-decoders" variant="primary" onClick={onSubmit} state={buttonState}>
                  {t('general.applyChanges')}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
