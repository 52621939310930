import React, { ReactNode } from 'react';

interface Props {
  name: string;
  id?: string;
  children?: ReactNode;
}

export const SecurityTabContent: React.FunctionComponent<Props> = ({ name, id, children }) => {
  return (
    <div id={id} className="security-tab-content">
      {name && <div className="security-tab-name haiui-heading-04-med">{name}</div>}
      {children}
    </div>
  );
};
