import { StatusResponse } from 'src/js/model/api/response/status-response';
import { SystemStatus } from 'src/js/model/status';

export const mapStatus = (response: StatusResponse): SystemStatus => ({
  bootVersion: response.bootVersion,
  cardStatus: response.cardStatus,
  cardType: response.cardType,
  cpldRevision: response.cpldRevision,
  firmwareDate: response.firmwareDate,
  firmwareVersion: response.firmwareVersion,
  hardwareCompatibility: response.hardwareCompatibility,
  hardwareRevision: response.hardwareRevision,
  partNumber: response.partNumber,
  serialNumber: response.serialNumber,
  temperature: Number.parseInt(response.temperature),
  uptime: response.uptime,
  cpu: response.cpu,
  ram: response.ram,
});
