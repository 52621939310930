import React from 'react';
import { Navigate } from 'react-router-dom';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useStores } from 'src/js/hook/use-stores';
import { DecoderPage } from 'src/js/pages/streaming/decoder/decoder';
import { Paths } from 'src/js/route';
import { constant } from 'src/js/constant';
import { useReadLocalStorage } from 'usehooks-ts';

import { Streams } from './stream/stream';

export type StreamPageParameters = {
  page?: StreamKey;
};

export enum StreamKey {
  DECODERS = 'decoder',
  STREAMS = 'stream',
}

export const DefaultStreamsRoute: React.FunctionComponent = () => {
  const { sessionStore } = useStores();
  const selectedTab =
    useReadLocalStorage(`${constant.lStorage.streamingTab}:${sessionStore.username}`) ?? StreamKey.STREAMS;

  return (
    <Navigate
      to={compileWithArgs<StreamPageParameters>(Paths.streaming)({
        page: selectedTab as StreamKey, // show page in localstorage if a direct section access without any specific page
      })}
      replace={
        true
      } /* replace current entry in history stack instead of adding new one, thus allowing to use back button a single time to go back (as expected) instead of two */
    />
  );
};

export const StreamsRoutes: any = [
  {
    path: '',
    element: <DefaultStreamsRoute />,
  },
  {
    path: StreamKey.DECODERS,
    element: <DecoderPage />,
  },
  {
    path: StreamKey.STREAMS,
    element: <Streams />,
  },
];
