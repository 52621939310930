import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onApply?: VoidFunction;
  onCancel?: VoidFunction;
  fileName: string;
}

export const UpdateCancelConfirmModal = (props: Props) => {
  const { fileName, onApply, onCancel } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      headline={t('settings.upgrade.cancelModal.headline')}
      bodyText={t('settings.upgrade.cancelModal.bodyText', { fileName: fileName })}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('settings.upgrade.cancelModal.keepUpdate') },
        {
          label: t('settings.upgrade.cancelModal.cancelUpdate'),
          variant: 'danger',
          onClick: onApply,
        },
      ]}
    />
  );
};
