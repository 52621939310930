import { Icon, PanelItem, colorValue } from '@hai/ui-react';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridManagerClientInterface } from 'src/js/component/grid-manager';
import { constant } from 'src/js/constant';

import { StillImage } from './still-image-model';
import { generateDataAuto } from 'src/js/util/automation';

export interface Props {
  id: string;
  model: StillImage;
  selectable?: boolean;
  selected?: boolean;
  gridManager?: any | GridManagerClientInterface<StillImage>;
  onSelect: (model: StillImage, selected: boolean) => void;
  onDelete?: (model: StillImage) => void;
}

export const OneStillImage: React.FunctionComponent<Props> = ({
  model,
  selectable = false,
  selected,
  gridManager,
  onSelect,
  onDelete,
  ...rest
}) => {
  const { t } = useTranslation();

  const ref = useCallback(
    (node: any): any => {
      // console.log('callback of node?', node, gridManager);
      if (node && gridManager) {
        const gm = gridManager;
        const parent = node.parentNode.parentNode; // FIXME do something cleaner, if we had the ref on the correct element it would be more direct.
        gm?.register?.(model.name, parent, model);
      }
    },
    [gridManager],
  );

  const classNamesString = classNames('stillimage', selected && 'selected');
  return (
    <>
      <PanelItem
        useFsMask
        panelColor={selectable && selected ? constant.productColor : colorValue('haiui-gray-04')}
        className={classNamesString}
        menuItems={onDelete && [{ title: t('general.delete'), onSelect: () => onDelete(model) }]}
        icon={<Icon iconname="StillImage" style={{ fill: constant.productColor }} size="sm2" />}
        onClick={() => {
          onSelect(model, !selected);
        }}
        title={model?.name}
        subtitle={model?.resolution}
        data-auto={generateDataAuto('panel', model?.name)}
      >
        {model?.name &&
          (selectable ? (
            <div className="box grid fs-mask" ref={ref} data-auto={generateDataAuto('panel', 'thumbnail')}>
              <img {...rest} src={`/apis/stillimages/.${model.name}.png?mtime=${model.mtime ?? Date.now()}"}`} />
            </div>
          ) : (
            <a
              href={`/apis/stillimages/${model.name}`}
              target="_blank"
              rel="noopener noreferrer"
              className="fs-mask"
              data-auto={generateDataAuto('panel', 'thumbnail')}
            >
              <img {...rest} src={`/apis/stillimages/.${model.name}.png?mtime=${model.mtime ?? Date.now()}`} />
            </a>
          ))}
      </PanelItem>
    </>
  );
};
