import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { notificationHandler } from 'src/js/notification/notification-handler';

import { CertificateCa } from './certificates-models';

interface Props {
  itemsToDelete: CertificateCa[];
  onSuccess?: (deletedItems: CertificateCa[]) => void;
  onCancel?: VoidFunction;
}

export const CaDeleteModal: React.FunctionComponent<Props> = ({ itemsToDelete, onSuccess, onCancel }) => {
  const { t } = useTranslation();

  const handleDelete = (): void => {
    let count = 0;
    itemsToDelete.map((item, _index) => {
      const path = APIPathWithArguments(APIPath.ca.delete, { name: item.name });
      apiClientWithoutHandler.genericController.delete(path).then((res: any) => {
        notificationHandler(res, null, t('security.certificates.notifications.deleteErrorMsg'), t);
        if (++count === itemsToDelete.length) {
          onSuccess?.(itemsToDelete);
        }
      });
    });
  };

  return (
    <Dialog
      headline={t('security.certificates.modal.delete.title', { count: itemsToDelete.length })}
      bodyText={t('general.cannotUndo')}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel') },
        { label: t('general.deleteBtn'), variant: 'danger', onClick: handleDelete },
      ]}
    />
  );
};
