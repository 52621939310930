import React, { Ref, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { genericDataFetcher, mapSingleData } from 'src/js/api/generic-data-fetcher';
import { APIPath } from 'src/js/api/route-path-index';
import { triggerEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { RebootResponse } from 'src/js/model/api/response/reboot-response';
import { PresetSavedResponse } from 'src/js/presets/preset-models';
import { RebootNeededSavedModal } from 'src/js/reboot/reboot-needed-saved-modal';
import { Paths } from 'src/js/route';

import { createRebootTask } from '../task/settings-tasks';
import { RebootNeededNotSavedModal } from './reboot-needed-not-saved-modal';

interface RebootNeededProps {
  className?: string;
}

export interface RebootNeededRef {
  show: () => void;
}

// To be used with Apply buttons
export const RebootNeeded = forwardRef((props: RebootNeededProps, ref: Ref<RebootNeededRef>) => {
  const { className, ...rest } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const rebootTask = useTask(createRebootTask());
  const [rebootNeededNotSavedModalStep1Shown, showRebootNeededNotSavedModalStep1, hideRebootNeededNotSavedModalStep1] =
    useModalControls();
  const [rebootNeededNotSavedModalShown, showRebootNeededNotSavedModal, hideRebootNeededNotSavedModal] =
    useModalControls();

  const [rebootNeededSavedModalShown, showRebootNeededSavedModal, hideRebootNeededSavedModal] = useModalControls(); // Initiate the data using the mapper so we get a clean form state

  const IsSavedCheck = () => {
    const url = APIPath.presets.isSaved;
    genericDataFetcher(url, mapSingleData).then((response: PresetSavedResponse) => {
      if (response.isSaved) {
        showRebootNeededSavedModal();
      } else {
        showRebootNeededNotSavedModalStep1();
      }
    });
  };

  // FIXME remove mobx task
  useSuccessReaction(rebootTask, (result: RebootResponse) => {
    navigate(result.upgrade ? Paths.rebootingUpgrade : Paths.rebooting);
  });

  const show = () => {
    IsSavedCheck();
  };

  useImperativeHandle(ref, () => ({ show })); // hook internal show() to our forwarded props

  const proceedToStep2 = (): void => {
    showRebootNeededNotSavedModal();
  };

  const proceedToReboot = (): void => {
    rebootTask();
  };

  return (
    <div className={className} {...rest}>
      {rebootNeededNotSavedModalStep1Shown && (
        <RebootNeededSavedModal
          onCancel={hideRebootNeededNotSavedModalStep1}
          onReboot={() => {
            hideRebootNeededNotSavedModalStep1();
            proceedToStep2();
          }}
        />
      )}

      {rebootNeededNotSavedModalShown && (
        <RebootNeededNotSavedModal
          onCancel={hideRebootNeededNotSavedModal}
          onReboot={() => {
            hideRebootNeededNotSavedModal();
            proceedToReboot();
          }}
          onSave={(): void => {
            hideRebootNeededNotSavedModal();
            triggerEvent('preset-save-reboot');
          }}
          cancelLabel={t('general.rebootLater')}
        />
      )}

      {rebootNeededSavedModalShown && (
        <RebootNeededSavedModal
          onCancel={hideRebootNeededSavedModal}
          onReboot={() => {
            hideRebootNeededSavedModal();
            proceedToReboot();
          }}
        />
      )}
    </div>
  );
});

RebootNeeded.displayName = 'rebootNeeded';
