import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import moment from 'moment';
import { groupBy } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { genericDataFetcher } from 'src/js/api/generic-data-fetcher';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { FormValue } from 'src/js/component/base/form/form-value';
import { useStores } from 'src/js/hook/use-stores';
import { generateDataAuto } from 'src/js/util/automation';
import { capitalizeTheFirstLetters } from 'src/js/util/index';

export type LicenceFeatureParameters = {
  account: string;
};

interface ItemProps {
  item: any;
  index: number;
}

const LicenseFeatureSection: React.FunctionComponent<ItemProps> = ({ item, index }) => {
  let niceCategory = '';

  if (item[0] === 'avin') {
    niceCategory = 'Audio/Video Inputs';
  } else if (item[0] === 'adinsertion') {
    niceCategory = 'Ad Insertion';
  } else {
    niceCategory = capitalizeTheFirstLetters(item[0]);
  }

  return (
    <div key={`feature-${index}`}>
      <div className="haiui-heading-04-book hai-gray-08 hai-mb-5">{niceCategory}</div>
      <DynamicContainer key={index} minColumns={3}>
        {item[1].map((feature: any, featureIndex: number) => (
          <FormValue
            key={featureIndex}
            label={feature.feature}
            data-auto={generateDataAuto('feature', feature.feature)}
            value={
              feature.feature?.includes?.('Date') && moment(new Date(feature.value)).isValid()
                ? feature.value /* NKE-2953 No format on dates so they match what's displayed in CLI */
                : feature.value
            }
            labelClass="double-height"
          />
        ))}
      </DynamicContainer>
    </div>
  );
};

export const LicenseFeatures: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  let { account } = useParams<LicenceFeatureParameters>();
  if (!sessionStore.isAdmin()) {
    account = sessionStore.username;
  }

  const [data, setData] = useState<any>([]);
  const url = APIPathWithArguments(APIPath.license.get, { name: account });

  useEffect(() => {
    genericDataFetcher(url).then((data: any) => {
      setData(data);
    });
  }, []);

  const groupedData = Object.entries(groupBy((item: any) => item.category, data));

  return (
    <>
      {groupedData.map((item: any, index: number) => (
        <Form
          key={`feature-${index}`}
          id="license-features"
          className={`hai-p-0 ${index === 0 ? 'hai-mt-6' : index === groupedData.length - 1 ? 'hai-mb-6' : ''}`}
        >
          <FormSection title={index === 0 ? t('settings.licensing.licenseFeatures') : ''}>
            <LicenseFeatureSection item={item} index={index} />
          </FormSection>
        </Form>
      ))}
    </>
  );
};
