/*eslint no-console: 0*/
/*eslint curly: 0*/

//TODO new mode to work on uuid instead of model: T

import { v4 as uuidv4 } from 'uuid';
import { createWatchStore, updateWatchStore, updateWatchStoreProp } from 'src/js/store/use-watch';

const DEBUG = false;

type ID = string | number;

export interface ListSelectorProps<T> {
  reset: () => void;
  selectedRows: () => T[];
  unselectAll: () => void;
  isSelected: (item: T | ID) => boolean; // TODO improve typing so it's one or the other only and not both, maybe <T,K> ?
  select: (item: T | ID) => void;
  unselect: (item: T | ID) => void;
  toggleRow: (item: T | ID) => boolean;
  toggleSelectAll: () => void;
  updateList: (list: T[]) => void;
  allRowsSelected: boolean;
  isAnyRowSelected: boolean;
  rowMatrix: boolean[]; // true == selected, false == not selected
}

// private begins
const privateStoreData: { [key: string]: ListSelector<any> } = {};

export class ListSelector<T> implements ListSelectorProps<T> {
  private _selectedRows: T[];
  private _selectedRowIds: ID[];
  public list: T[];
  private uuid: string;
  private usePropId?: keyof T;

  allRowsSelected = false;
  isAnyRowSelected = false;
  rowMatrix: boolean[] = [];

  constructor(list: T[], uuid: string, usePropId?: keyof T) {
    this._selectedRows = [];
    this._selectedRowIds = [];
    if (list === undefined || list === null) {
      list = [];
    }
    this.list = list;
    this.uuid = uuid;
    this.allRowsSelected = false;
    this.usePropId = usePropId;
    createWatchStore(this, { storeKey: uuid });
    this.rowMatrix = Array(list.length).fill(false);
    return this;
  }

  reset = () => {
    if (DEBUG) console.log('reset');
    const before = { ...this }; // TODO test this better
    this._selectedRows = [];
    this._selectedRowIds = [];
    this.allRowsSelected = false;
    updateWatchStoreProp(this, { rowMatrix: Array(this.list.length).fill(false) as boolean[] });
    updateWatchStore(before, { ...this });
  };

  updateList = (list: T[]) => {
    const before = { ...this };
    if (DEBUG) console.log('update list');
    //this.list = list;
    updateWatchStoreProp(this, { list: list }); // FIXME, here we should use the uuid, or the store object
    updateWatchStore(before, { ...this });
  };

  selectedRows = () => {
    return this._selectedRows;
  };

  selectedRowIds = () => {
    return this._selectedRowIds;
  };

  selectAll = () => {
    if (DEBUG) console.error('selectAll');
    if (this.allRowsSelected === false) {
      updateWatchStoreProp(this, { rowMatrix: Array(this.list.length).fill(true) as boolean[] });
      this._selectedRows = [...this.list];
      updateWatchStore(this, { ...this, allRowsSelected: true });
      this.allRowsSelected = true;
    }
  };

  unselectAll = () => {
    if (DEBUG) console.error('unselectAll');
    if (this._selectedRows.length > 0) {
      updateWatchStoreProp(this, { rowMatrix: Array(this.list.length).fill(false) as boolean[] });
      this._selectedRows = [];
      updateWatchStore(this, { ...this, allRowsSelected: false });
      this.allRowsSelected = false;
    }
  };

  isSelected = (item: T | ID) => {
    // if (DEBUG) console.log('going to check if item is selected', item);
    let selected;
    if (this.usePropId) {
      if (typeof item === 'object') {
        console.error('An ID must be passed to the selector function when "usePropId" mode is on');
      }
      const key = item as any;
      selected = this._selectedRows.find((i) => i[this.usePropId] === key) !== undefined;
    } else {
      selected = this._selectedRows.includes(item as T);
    }
    return selected;
  };

  select = (item: T | ID) => {
    let index;
    if (this.usePropId) {
      if (typeof item === 'object') {
        console.error('An ID must be passed to the selector function when "usePropId" mode is on');
      }
      index = this._selectedRows.findIndex((i) => i[this.usePropId] === (item as any));
    } else {
      index = this._selectedRows.indexOf(item as T);
    }
    if (index >= 0) {
      return; // item already selected ??!
    }

    if (this.usePropId) {
      const obj = this.list.find((i) => i[this.usePropId] === (item as any));
      if (obj === undefined) {
        console.error('[listSelector] select() could not find item from argument', item);
        return;
      }
      item = obj;
      if (DEBUG) console.log('select', item);
    }

    this._selectedRows.push(item as T);
    if (DEBUG) console.log('after add, selectedRows', this._selectedRows);
    this.allRowsSelectedCalc();
    return;
  };

  unselect = (item: T | ID) => {
    let index;
    if (this.usePropId) {
      if (typeof item === 'object') {
        console.error('An ID must be passed to the selector function when "usePropId" mode is on');
      }
      index = this._selectedRows.findIndex((i) => i[this.usePropId] === (item as any));
    } else {
      index = this._selectedRows.indexOf(item as T);
    }
    if (index >= 0) {
      this._selectedRows.splice(index, 1);
      // console.log('after delete, selectedRows', this._selectedRows)
      this.allRowsSelectedCalc();
      return;
    } else {
      // row already not selected ??!
      return;
    }
  };

  toggleRow = (item: T | ID) => {
    let index;
    if (this.usePropId) {
      if (typeof item === 'object') {
        console.error('An ID must be passed to the selector function when "usePropId" mode is on');
      }
      index = this._selectedRows.findIndex((i) => i[this.usePropId] === (item as any));
      const obj = this.list.find((i) => i[this.usePropId] === (item as any));
      if (obj === undefined) {
        console.error('[listSelector] toggleRow() could not find item from argument', item);
        return false;
      }
      item = obj;
    } else {
      index = this._selectedRows.indexOf(item as T);
    }
    if (DEBUG) console.log('[toggleRow] is already selected?', index !== -1);
    if (index >= 0) {
      this._selectedRows.splice(index, 1);
      if (DEBUG) console.log('[toggleRow] after delete, selectedRows', this._selectedRows);
      this.allRowsSelectedCalc();
      return false;
    } else {
      this._selectedRows.push(item as T);
      if (DEBUG) console.log('[toggleRow] after add, selectedRows', this._selectedRows);
      this.allRowsSelectedCalc();
      return true;
    }
  };

  toggleSelectAll = () => {
    // console.log('[list] toggleSelectAll, areAllRowsSelected', this.areAllRowsSelected());
    if (this.areAllRowsSelected()) {
      if (DEBUG) console.log('[list] zeroing selected rows');
      this._selectedRows = [];
    } else {
      this.list.forEach((el) => {
        if (!this._selectedRows.includes(el)) {
          this._selectedRows.push(el);
        }
      });
      // console.log('[list] all rows should now be selected',  this._selectedRows);
    }

    this.allRowsSelectedCalc();
  };

  areAllRowsSelected = () => {
    let result = true;
    this.list.forEach((el) => {
      if (!this._selectedRows.includes(el)) {
        result = false;
      }
    });
    // console.log('areAllRowsSelected returning', result)
    return result;
  };

  allRowsSelectedCalc = () => {
    const rowMatrix = Array(this.list.length).fill(false);
    let result = true;
    let isAnyRowSelected = false;
    this.list.forEach((el, index: number) => {
      if (!this._selectedRows.includes(el)) {
        result = false;
      } else {
        isAnyRowSelected = true; // at least one row selected
        rowMatrix[index] = true;
      }
    });
    updateWatchStore(this, { ...this, isAnyRowSelected: isAnyRowSelected });
    this.isAnyRowSelected = isAnyRowSelected;
    updateWatchStore(this, { ...this, allRowsSelected: result });
    this.allRowsSelected = result;
    updateWatchStore(this, { ...this, rowMatrix: rowMatrix });
    this.rowMatrix = rowMatrix;
    return result;
  };
}
/*
const getKeyFromModel = <T>(model: T[]) => {
  let key: any = model;
  if (typeof model === 'string' || typeof model === 'number') {
    key = Symbol(model);
  }
  return key;
};

const getStoreFromObject = <T>(model: T[]):[ListSelector<T>, string] => {
  const existing = Object.keys(privateStoreData).find((key:string) => (privateStoreData[key].list === model));
  if (existing) {
    return [privateStoreData[existing], existing];
  }
  return [null, null];
};*/
// private ends

// create store or get existing
export const createListStore = <T>(list: T[], uuid: string, usePropId?: keyof T): ListSelector<T> => {
  if (list === null) {
    console.error('failed to create store, no object passed');
    //return null;
  }

  if (uuid === undefined) {
    uuid = uuidv4();
  }

  if (privateStoreData[uuid] === undefined) {
    privateStoreData[uuid] = new ListSelector(list, uuid, usePropId);
  } else {
    // console.log('reusing list', uuid);
    if (privateStoreData[uuid].list !== list) {
      if (DEBUG) console.warn('list changed');
      privateStoreData[uuid].updateList(list);
    }
  }

  // console.log('private data', privateStoreData)
  return privateStoreData[uuid];
};

/*
//TODO
// Deletes the private store, not the list
export const destroyListStore = <T>(list: T[]):boolean => {

  return false;
}*/
