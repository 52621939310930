import { Observable } from 'rxjs';
import { APIPath } from 'src/js/api/route-path-index';
import { BaseController } from 'src/js/controller/base-controller';

/* User APIs pertaining to Session */
export class SessionController extends BaseController {
  /* Logout */
  public logout(): Observable<{}> {
    return this.delete$(APIPath.session);
  }
}
