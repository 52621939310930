import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onReboot?: VoidFunction;
  onCancel?: VoidFunction;
}

export const RebootConfirmModal: React.FunctionComponent<Props> = ({ onReboot, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      headline={t('reboot.confirm.title')}
      onClose={onCancel}
      show={true}
      buttons={[{ label: t('general.cancel') }, { label: t('general.reboot'), variant: 'danger', onClick: onReboot }]}
    />
  );
};
