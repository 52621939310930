import { List } from '@hai/ui-react';
import React, { useEffect } from 'react';
import { SortDirection, SortManager } from 'src/js/component/sort-manager';
import { scrollPanelIntoView } from 'src/js/component/actionBar/streaming-action-bar';
import { AccountViewModel } from './account-models';
import { AccountItem } from './account-row';
import { constant } from 'src/js/constant';

interface AccountProps {
  list: AccountViewModel[];
  direction: SortDirection;
  onSelect: (model: AccountViewModel, selected: boolean) => void;
  sorting: string;
  sortInfo: any;
  scrollToId: number | string;
}

export const AccountList: React.FunctionComponent<AccountProps> = ({
  list,
  direction,
  onSelect,
  sorting,
  sortInfo,
  scrollToId,
}) => {
  useEffect(() => {
    scrollPanelIntoView({ id: scrollToId });
  }, [scrollToId]);

  return (
    <List accentColor={constant.productColor} removeMediaQueries className="account-list">
      <SortManager sortInfo={sortInfo} list={list} sortKey={sorting} direction={direction}>
        {list.map((model: AccountViewModel) => {
          return <AccountItem key={model.id} model={model} onSelect={onSelect} scrollToId={scrollToId} />;
        })}
      </SortManager>
    </List>
  );
};
