import { FormControl, FormControlError, FormGroup, FormLabel } from '@hai/ui-react';
import { useField } from 'formik';
import React, { MutableRefObject, useState } from 'react';
import { FormChangeEvent } from 'src/js/util/global-type';
import { isNilOrEmpty } from 'src/js/util/global-util';

interface Props {
  id?: string;
  name: string;
  mask?: boolean;
  title?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  type?: string;
  pwdToggle?: boolean;
  disabled?: boolean;
  controlProps?: any;
  autoFocus?: boolean;
  autoComplete?: string;
  className?: string;
  inputClassName?: string;
  onChange?: (event: React.KeyboardEvent<HTMLInputElement> & FormChangeEvent) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement> & FormChangeEvent) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement> & FocusEvent) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement> & FocusEvent) => void;
  inputRef?: MutableRefObject<any>;
  visibilityHidden?: boolean;
}

export const FormikTextfield: React.FunctionComponent<Props> = ({
  id,
  name,
  mask,
  title,
  placeholder,
  hint,
  required,
  type = 'text',
  pwdToggle,
  disabled,
  autoFocus,
  autoComplete,
  className,
  inputClassName,
  onChange,
  onKeyUp,
  onFocus,
  onBlur,
  inputRef,
  visibilityHidden,
}) => {
  const [field, meta] = useField(name);
  const { value: value, onChange: fieldOnChange, onBlur: fieldOnBlur, ...restField } = field; // restField contains name, multiple and checked
  const hasErrors = meta.error && meta.touched;
  let additionalClassNames = className ? ` ${className}` : '';
  if (visibilityHidden) {
    additionalClassNames += ' invisible';
  }

  const [focus, setFocus] = useState(false);

  const checkIfNumber = (event: React.KeyboardEvent<HTMLInputElement> & FormChangeEvent) => {
    return (
      !/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|Control|Meta|Home|End|v|c|x)/.test(event.key) &&
      event.preventDefault()
    );
  };

  const showError = isNilOrEmpty(hint) ? true : !focus;

  return (
    <FormGroup className={`FormItem${additionalClassNames}`}>
      {title && (
        <FormLabel required={required} htmlFor={id || name}>
          {title}
        </FormLabel>
      )}
      <FormControl
        id={id || name}
        className={inputClassName}
        useFsMask={mask}
        type={type}
        pwdToggle={pwdToggle}
        placeholder={focus ? hint ?? placeholder : placeholder ?? hint}
        invalid={hasErrors}
        disabled={disabled}
        autoFocus={autoFocus}
        inputRef={inputRef}
        onKeyUp={onKeyUp}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement> & FormChangeEvent) =>
          type === 'number' && checkIfNumber(event)
        }
        onChange={(event: React.KeyboardEvent<HTMLInputElement> & FormChangeEvent): void => {
          onChange?.(event);
          fieldOnChange(event);
        }}
        onFocus={(event: React.FocusEvent<HTMLInputElement> & FocusEvent): void => {
          setFocus(true);
          onFocus?.(event);
        }}
        onBlur={(event: React.FocusEvent<HTMLInputElement> & FocusEvent): void => {
          setFocus(false);
          fieldOnBlur(event);
          onBlur?.(event);
        }}
        value={value}
        autoComplete={autoComplete ?? 'off'}
        showHint={!isNilOrEmpty(hint)}
        {...restField}
      />
      {showError && <FormControlError show={hasErrors} mssg={meta.error} />}
    </FormGroup>
  );
};
