import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormValue } from 'src/js/component/base/form/form-value';
import { DeviceStatusViewModel } from 'src/js/pages/settings/status/device-status-view-model';
import { formatUptime } from 'src/js/pages/streaming/stream/stream-helper';
import { generateDataAuto } from 'src/js/util/automation';
import { constant } from 'src/js/constant';
interface Props {
  model: DeviceStatusViewModel;
}

export const DeviceStatus: React.FunctionComponent<Props> = ({ model }) => {
  const { t } = useTranslation();

  return (
    <>
      <Form className="hai-mb-6">
        <FormSection title={t('settings.system.section.deviceStatus.title')}>
          <DynamicContainer>
            <FormValue
              label={t('settings.system.section.deviceStatus.cardStatus')}
              value={model?.cardStatus}
              icon={model?.cardStatusIconStatusType}
            />
            <FormValue label={t('settings.system.section.deviceStatus.cardType')} value={model?.cardType} />
            <FormValue label={t('settings.system.section.deviceStatus.partNumber')} value={model?.partNumber} />
            <FormValue
              mask
              label={t('settings.system.section.deviceStatus.serialNumber')}
              value={model?.serialNumber}
              data-auto={generateDataAuto('form-value', 'serial number')}
            />
            <FormValue
              label={t('settings.system.section.deviceStatus.firmwareVersion')}
              value={model?.firmwareVersion}
              data-auto={generateDataAuto('form-value', 'firmware version')}
            />
            <FormValue
              label={t('settings.system.section.deviceStatus.firmwareDate')}
              value={
                model?.firmwareDate
                  ? moment(model?.firmwareDate, 'MMM D YYYY').format(constant.momentFormat.status)
                  : ''
              }
            />
            <FormValue
              label={t('settings.system.section.deviceStatus.hardwareVersion')}
              value={model?.hardwareRevision}
            />
            <FormValue
              label={t('settings.system.section.deviceStatus.hardwareCompatibility')}
              value={model?.hardwareCompatibility}
            />
            <FormValue label={t('settings.system.section.deviceStatus.cpldVersion')} value={model?.cpldRevision} />
            <FormValue
              label={t('settings.system.section.deviceStatus.systemUptime')}
              value={formatUptime(model?.uptime, true)}
              data-auto={generateDataAuto('form-value', 'system uptime')}
            />
            <FormValue
              label={t('settings.system.section.deviceStatus.temperature')}
              value={model?.temperature ? `${model?.temperature}°C` : ''}
            />
          </DynamicContainer>
        </FormSection>
      </Form>
    </>
  );
};
