import { Button, DataTable, FormSection } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useMutable } from 'src/js/hook/use-mutable';
import { createListStore, ListSelector } from 'src/js/util/listSelector';
import { v4 as uuidv4 } from 'uuid';

import { DeleteAccountPubkeyModal } from './account-delete-pubkey-modal';
import { AccountKeyData, AccountViewModel } from './account-models';
import { uploadPublicKey } from './account-store';
import { PubkeyImportModal } from './pubkey-import-modal';

interface Props {
  model: AccountViewModel;
  shouldReloadData: () => void;
  user?: boolean;
}

export const AccountPubkeyTable: React.FunctionComponent<Props> = ({ model, shouldReloadData, user }) => {
  const { t } = useTranslation();

  const list = model.keys.data;
  let listController: ListSelector<AccountKeyData> = null;
  const [uuid] = useMutable(uuidv4());
  listController = createListStore(list, uuid(), 'name');

  // if some state on the selection is needed:
  // const selectedMatrix = useStateWatch(listController, 'rowMatrix', listController.rowMatrix);
  /* const allRowsSelected = useStateWatch(
      listController,
      'allRowsSelected',
      listController.allRowsSelected,
    );*/

  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const [singleDeleteItem, setSingleDeleteItem] = useState<AccountKeyData>();
  const onSuccessDelete = (): void => {
    hideDelete();
    shouldReloadData();
    listController.reset();
    setSingleDeleteItem(undefined);
    setSelectAll(false);
  };

  const [importShown, showImport, hideImport] = useModalControls();
  const handleImportPubkey = (file: File): Promise<any> => {
    return uploadPublicKey(model.name, file, t).then((res) => {
      if (res.ok) {
        hideImport();
        shouldReloadData();
        listController.reset();
        setSelectAll(false);
      }
    });
  };

  const bulkActionComponents = () => {
    return (
      <Button state="idle" size="small" onClick={showDelete}>
        {t('general.deleteBtn')}
      </Button>
    );
  };

  const handleAction = (eventKey: string, data: any) => {
    let toDelete;
    switch (eventKey) {
      case 'delete':
        toDelete = list.find((i) => i.name === data.name);
        setSingleDeleteItem(toDelete);
        showDelete();
        break;

      default:
        break;
    }
  };

  const [selectAll, setSelectAll] = useState(false);
  const handleCheckAll = (checked: boolean) => {
    setSelectAll(checked);
    checked ? listController.selectAll() : listController.unselectAll();
  };
  // console.log('selected', listController.selectedRows());
  return (
    <>
      <FormSection className="hai-mb-6 hai-pt-0">
        <DataTable
          selectable={true}
          sortable={false}
          columnStructure={[
            { fieldKey: 'name', useFsMask: true, title: t('general.name') },
            {
              fieldKey: 'fingerprint',
              useFsMask: true,
              title: t('security.accounts.column.fingerprint'),
            },
            { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS },
          ]}
          onCheckAll={handleCheckAll}
          checkAll={selectAll}
          className={`datatable-form-section-title ${user ? 'user' : ''}`}
          datatableContainerClassName="datatable-formsection"
          emptyStateText={t('security.accounts.addFirst')}
        >
          <DataTable.TitleBar
            title={t('security.accounts.pubkeys.title')}
            rightComponent={
              <Button size="small" onClick={showImport}>
                {t('general.add')}
              </Button>
            }
          />
          <DataTable.Header bulkActions={bulkActionComponents} />
          {list.map((value: AccountKeyData, index: number) => (
            <DataTable.Row
              key={`${value.name}-${index}`}
              rowData={{
                index: index,
                id: `${value.name}-${index}`,
                name: value.name,
                fingerprint: value.fingerprint,
              }}
              onSelect={() => listController.toggleRow(value.name)}
              checked={listController.isSelected(value.name)}
              // if we need more reactive checked={selectedMatrix[index]}
              actionItems={[
                {
                  actionIcon: 'TrashCan',
                  title: t('general.delete'),
                  eventKey: 'delete',
                  onSelect: handleAction,
                },
              ]}
            ></DataTable.Row>
          ))}
        </DataTable>
      </FormSection>
      {importShown && <PubkeyImportModal onCancel={hideImport} onImport={handleImportPubkey} />}
      {deleteShown && (
        <DeleteAccountPubkeyModal
          name={model.name}
          itemsToDelete={singleDeleteItem ? [singleDeleteItem] : listController.selectedRows()}
          onCancel={() => {
            hideDelete();
            setSingleDeleteItem(undefined);
          }}
          onSuccess={onSuccessDelete}
        />
      )}
    </>
  );
};
