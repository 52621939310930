import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikCheckbox } from 'src/js/component/base/form/formik-checkbox';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { DNSPrecedence, Ipv6Addressing } from 'src/js/model/api/response/network-response';
import { NetworkFormValues } from 'src/js/pages/settings/network/form/network-form';

import { Ipv4Addressing } from '../network-view-model';

export const NetworkDnsForm = (props: FormikProps<NetworkFormValues>) => {
  const { t } = useTranslation();

  const isDNSAutomatic = props.values.autoDns;

  // if any is DHCP, on any interface, we allow it (enabled=true)
  const autoDnsEnabled =
    (props.values.eth0.ipv4 && props.values.eth0.ipv4Addressing === Ipv4Addressing.DHCP) ||
    (props.values.eth0.ipv6 &&
      (props.values.eth0.ipv6Addressing === Ipv6Addressing.AUTOMATIC ||
        props.values.eth0.ipv6Addressing === Ipv6Addressing.AUTOMATIC_DHCP));

  return (
    <Form className="hai-mt-6 hai-mb-6">
      <FormSection title={t('settings.network.section.dns')}>
        <DynamicContainer minColumns={3}>
          <FormikTextfield name="domain" mask title={t('settings.network.form.domain')} />
        </DynamicContainer>

        <DynamicContainer minColumns={1}>
          <FormikCheckbox
            name="autoDns"
            label={t('settings.network.form.obtainDns')}
            checked={autoDnsEnabled ? props.values.autoDns : false}
            disabled={!autoDnsEnabled}
          />
        </DynamicContainer>
        <DynamicContainer minColumns={3}>
          <FormikTextfield
            name="primaryDns"
            mask
            title={t('settings.network.form.primaryDns')}
            disabled={isDNSAutomatic && autoDnsEnabled}
          />
          <FormikTextfield
            name="alternateDns"
            mask
            title={t('settings.network.form.alternateDns')}
            disabled={isDNSAutomatic && autoDnsEnabled}
          />
          <FormikSelect
            name="dnsPrecedence"
            title={t('settings.network.form.dnsPrecedence')}
            selectValues={Object.values(DNSPrecedence)}
            selectLabels={Object.values(DNSPrecedence).map((value: string) =>
              t(`settings.network.dnsPrecedence.${value}`),
            )}
            visibilityHidden={!props.values.eth0.ipv4 || !props.values.eth0.ipv6}
          />
        </DynamicContainer>
        <DynamicContainer minColumns={1}>
          <FormikCheckbox name="mDnsEnabled" label={t('settings.network.form.mdns')} />
        </DynamicContainer>
        <DynamicContainer minColumns={3}>
          <FormikTextfield
            name="mDnsName"
            mask
            title={t('settings.network.form.mDnsName')}
            disabled={!props.values.mDnsEnabled}
            placeholder={props.values.hostname}
          />
        </DynamicContainer>
      </FormSection>
    </Form>
  );
};
