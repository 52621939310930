import { Moment } from 'moment-timezone';
import { UpdateDateTimeRequest } from 'src/js/model/api/request/update-date-time-request';

export const createUpdateDateTimeRequest = (
  timezone: string,
  ntp: boolean,
  server?: string,
  time?: Moment,
): UpdateDateTimeRequest => {
  if (ntp) {
    return {
      timezone,
      ntp,
      server,
    };
  } else {
    return {
      timezone,
      ntp,
      time_text: time?.format('MM DD YYYY HH:mm:ss'),
    };
  }
};
