import { TFunction } from 'i18next';
import { Duration } from 'moment-timezone';

export enum DurationFormatterType {
  LONG,
  SHORT,
}

export interface DurationFormatter {
  years: (years: number, t: TFunction) => string;
  months: (months: number, t: TFunction) => string;
  days: (days: number, t: TFunction) => string;
  hours: (hours: number, t: TFunction) => string;
  minutes: (minutes: number, t: TFunction) => string;
  seconds: (seconds: number, t: TFunction) => string;
  minorDelimeter: string;
  majorDelimeter: string;
  t: TFunction;
}

/* It will return all the components up to a year with the format:
 *  y = years, M = months, d = days, h = hours, m = minutes, s = seconds
 *
 * t: translation function
 * minorDelimeter: characters to place between count and unit
 *  e.g. the " " (space) in "3 weeks"
 * majorDelimeter: characters to place between strings
 *  e.g. the ", " (comma and space) in "3 weeks, 2 days"
 */

const shortDurationFormatter = (t: TFunction, minorDelimeter = '', majorDelimeter = ''): DurationFormatter => ({
  years: () => {
    return t('date.shortComponents.years');
  },
  months: () => {
    return t('date.shortComponents.months');
  },
  days: () => {
    return t('date.shortComponents.days');
  },
  hours: () => {
    return t('date.shortComponents.hours');
  },
  minutes: () => {
    return t('date.shortComponents.minutes');
  },
  seconds: () => {
    return t('date.shortComponents.seconds');
  },
  minorDelimeter,
  majorDelimeter,
  t,
});

/*
 * Used Moment lib to create a string with all the date components.
 * It will return all the components up to a year with the format,
 * automatically pluralized if necessary:
 * year, month, day, hour, minute, second
 */
const longDurationFormatter = (t: TFunction, minorDelimeter = ' ', majorDelimeter = ' '): DurationFormatter => ({
  years: (years: number) => {
    return t('date.longComponents.years', { count: years });
  },
  months: (months: number) => {
    return t('date.longComponents.months', { count: months });
  },
  days: (days: number) => {
    return t('date.longComponents.days', { count: days });
  },
  hours: (hours: number) => {
    return t('date.longComponents.hours', { count: hours });
  },
  minutes: (minutes: number) => {
    return t('date.longComponents.minutes', { count: minutes });
  },
  seconds: (seconds: number) => {
    return t('date.longComponents.seconds', { count: seconds });
  },
  minorDelimeter,
  majorDelimeter,
  t,
});

export const formatterForType = (
  formatType: DurationFormatterType,
  t: TFunction,
  minorDelimeter?: string,
  majorDelimeter?: string,
) => {
  switch (formatType) {
    case DurationFormatterType.SHORT:
      return shortDurationFormatter(t, minorDelimeter, majorDelimeter);
    case DurationFormatterType.LONG:
      return longDurationFormatter(t, minorDelimeter, majorDelimeter);
  }
};

export const createDurationString = (duration: Duration, durationFormatter: DurationFormatter): string => {
  const { minorDelimeter, majorDelimeter } = durationFormatter;
  const t = durationFormatter.t;
  let durationString = '';
  if (duration.years() > 0) {
    durationString = `${durationString}${duration.years()}${minorDelimeter}${durationFormatter.years(
      duration.years(),
      t,
    )}${majorDelimeter}`;
  }
  if (duration.months() > 0) {
    durationString = `${durationString}${duration.months()}${minorDelimeter}${durationFormatter.months(
      duration.months(),
      t,
    )}${majorDelimeter}`;
  }
  if (duration.days() > 0) {
    durationString = `${durationString}${duration.days()}${minorDelimeter}${durationFormatter.days(
      duration.days(),
      t,
    )}${majorDelimeter}`;
  }
  if (duration.hours() > 0) {
    durationString = `${durationString}${duration.hours()}${minorDelimeter}${durationFormatter.hours(
      duration.hours(),
      t,
    )}${majorDelimeter}`;
  }
  if (duration.minutes() > 0) {
    durationString = `${durationString}${duration.minutes()}${minorDelimeter}${durationFormatter.minutes(
      duration.minutes(),
      t,
    )}${majorDelimeter}`;
  }
  if (duration.seconds() > 0) {
    durationString = `${durationString}${duration.seconds()}${minorDelimeter}${durationFormatter.seconds(
      duration.seconds(),
      t,
    )}${majorDelimeter}`;
  }
  // remove trailing delimeter
  return durationString.replace(RegExp(`${majorDelimeter}$`), '');
};
