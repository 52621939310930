import * as FS from '@fullstory/browser';
import { Analytics, Button } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import systemInfo, { systemInfoCapabilities, updateSystemInfo } from 'src/js/data/systemInfo';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useMutable } from 'src/js/hook/use-mutable';
import { useErrorReaction, useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { Service } from 'src/js/model/service';
import { Services as ServicesModel } from 'src/js/model/services';
import { NotificationVariant } from 'src/js/notification/notification';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { SettingsTabContent } from 'src/js/pages/settings/settings-tab-content';
import { IsSavedRef, RebootIsSaved } from 'src/js/reboot/reboot-is-saved';
import { RebootNeeded, RebootNeededRef } from 'src/js/reboot/reboot-needed';
import { createGetServicesTask, createUpdateServicesTask } from 'src/js/task/settings-services-tasks';
import { generateDataAuto } from 'src/js/util/automation';
import { FormChangeEvent } from 'src/js/util/global-type';

import { ConfirmHttpOffModal } from './confirm-modal';
import { EmsForm } from './ems-form';
import { EmsServiceState } from './ems-models';
import emsService, { saveResponseToEmsService } from './ems-store';
import { GeneralServices } from './general-services';
import { PreviewForm, PreviewFormValues } from './preview-form';
import { PreviewResponse, PreviewViewModel } from './preview-view-model';
import { mapGeneralServicesViewModel } from './services-view-model-mapper';

interface Props {
  pollingInterval?: number;
}

export type NicOptions = {
  value: string | number;
  label: string;
}[];

export const Services: React.FunctionComponent<Props> = ({ pollingInterval = 5000 }) => {
  const { t } = useTranslation();
  const getServicesTask = useTask(createGetServicesTask());
  const updateServicesTask = useTask(createUpdateServicesTask());
  const [services, setServices] = useState<ServicesModel>(undefined);
  const [loading, setLoading] = useState(true);
  const [pollingTimeoutId, setPollingTimeoutId] = useMutable(undefined);
  const [selectedService, setSelectedService] = useState<string>('');

  const [previewViewModel, setPreviewViewModel] = useState<PreviewViewModel>(undefined);
  const [confirmModalShown, showConfirmModal, hideConfirmModal] = useModalControls();

  const nicOptions: NicOptions = [
    { value: 'all', label: t(`settings.network.options.all`) },
    { value: 'eth0', label: t(`settings.network.options.eth0`) },
    { value: 'eth1', label: t(`settings.network.options.eth1`) },
  ];

  const rebootNeededRef = useRef<RebootNeededRef>(null);

  // the reboot handling for the reboot button
  const childRef = useRef<IsSavedRef>(null);
  const handleReboot = (): void => {
    childRef.current.show();
  };

  const clearPollingTimeout = (): void => {
    if (!isNil(pollingTimeoutId())) {
      clearTimeout(pollingTimeoutId());
      setPollingTimeoutId(undefined);
    }
  };

  const getPreview = () => {
    return apiClientWithoutHandler.rawController.get<PreviewResponse>(APIPath.services.preview).then((res) => {
      if (res.ok) {
        setPreviewViewModel(res.data);
      }
      return res;
    });
  };

  const handleSubmitPreview = (values: PreviewFormValues) => {
    const previewValues = { ...values };

    previewValues.decoders.forEach((_decoder, index) => {
      values.decoders[index] = { interval: Number(values.decoders[0].interval), enabled: true }; // strip everything else (width, height, enabled)
    });

    return apiClientWithoutHandler.genericController
      .put<PreviewResponse>(APIPath.services.preview, previewValues)
      .then((res) => {
        notificationHandler(
          res,
          t('settings.services.notifications.previewSuccessMsg'),
          t('settings.services.notifications.previewErrorMsg'),
          t,
        );
        return res;
      })
      .then((res) => {
        if (res.ok) {
          setPreviewViewModel(res.data);
        }
        return res;
      });
  };

  useEffect(() => {
    getServicesTask();
    return clearPollingTimeout;
  }, []);

  useEffect(() => {
    getPreview();
  }, []);

  useSuccessReaction(getServicesTask, (result: ServicesModel) => {
    // re-execute the task in pollingTimeout ms
    setPollingTimeoutId(
      setTimeout((): void => {
        getServicesTask();
      }, pollingInterval),
    );
    setServices(result);
    setLoading(false);
  });

  useSuccessReaction(updateServicesTask, (result: ServicesModel) => {
    setServices(result);
    const service = updateServicesTask.args[0] as Service;
    const targetValue = updateServicesTask.args[1];
    const realValue = result[service];
    if (targetValue) {
      createAndDispatchNotification(
        realValue
          ? t('settings.services.notifications.enableSuccessMsg', {
              service: t(`settings.services.section.general.${service}`),
            })
          : t('settings.services.notifications.enableErrorMsg', {
              service: t(`settings.services.section.general.${service}`),
            }),
        realValue ? NotificationVariant.SUCCESS : NotificationVariant.ERROR,
        t,
      );
    } else {
      createAndDispatchNotification(
        realValue
          ? t('settings.services.notifications.disableErrorMsg', {
              service: t(`settings.services.section.general.${service}`),
            })
          : t('settings.services.notifications.disableSuccessMsg', {
              service: t(`settings.services.section.general.${service}`),
            }),
        realValue ? NotificationVariant.ERROR : NotificationVariant.SUCCESS,
        t,
      );
    }
    if (service === Service.PREVIEW) {
      setPreviewViewModel((current) => ({ ...current, enabled: realValue }));
    }
    if (service === Service.EMS) {
      saveResponseToEmsService({ ...emsService, state: EmsServiceState.DISABLED }); // R4KD-1820
    }
  });

  useErrorReaction(updateServicesTask, () => {
    createAndDispatchNotification(
      updateServicesTask.args[1]
        ? t('settings.services.notifications.enableErrorMsg', {
            service: t(`settings.services.section.general.${updateServicesTask.args[0] as Service}`),
          })
        : t('settings.services.notifications.disableErrorMsg', {
            service: t(`settings.services.section.general.${updateServicesTask.args[0] as Service}`),
          }),
      NotificationVariant.ERROR,
      t,
    );
  });

  const applyServiceNicChange = (service: string, nic: string): Promise<any> => {
    const model: any = { nic: {} };
    model.nic[service] = nic;
    setSelectedService(service);

    return apiClientWithoutHandler.genericController
      .put(APIPath.services.update, model)
      .then((res: any) => {
        notificationHandler(
          res,
          t('settings.services.notifications.nicSuccessMsg'),
          t('settings.services.notifications.nicErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        // restart needed ?
        const started = services[service as Service];
        if (!started) {
          return;
        }

        rebootNeededRef.current.show();
        return res;
      });
  };

  const handleServiceNicChange = (formEvent: FormChangeEvent) => {
    if (formEvent.stopPropagation) {
      formEvent.stopPropagation();
    }

    const { name, value } = formEvent.target;
    if (services.nic[name as keyof typeof services.nic] !== value) {
      applyServiceNicChange(name, value);
    }
  };

  const handleAnalyticsChange = (accepted: boolean) => {
    updateSystemInfo({ ...systemInfo, analytics: accepted ? 'accepted' : 'declined' }).then((res: any) => {
      notificationHandler(
        res,
        accepted
          ? t('settings.services.notifications.analyticsEnableSuccessMsg')
          : t('settings.services.notifications.analyticsDisableSuccessMsg'),
        t('settings.services.notifications.analyticsErrorMsg'),
        t,
      );
      if (FS.isInitialized()) {
        accepted ? FS.restart() : FS.shutdown();
      }
    });
  };

  const [scrollTo, setScrollTo] = useState<string>(undefined);
  useEffect(() => {
    if (!isNil(scrollTo)) {
      const item = document.getElementById(scrollTo);
      if (!isNil(item)) {
        item.scrollIntoView({ behavior: 'auto', block: 'nearest' });
        setScrollTo(undefined);
      }
    }
  }, [services, scrollTo]);

  const handleServiceChange = (service: Service, value: boolean): Promise<any> | void => {
    if (service === 'http' && value === false) {
      setSelectedService(service);
      showConfirmModal();
    } else {
      const promise = updateServicesTask(service, value);

      switch (service) {
        case Service.EMS:
          if (value) {
            setScrollTo('ems');
          }
          break;

        default:
          break;
      }

      return promise;
    }
  };

  const proceedToTurnOffHttp = () => {
    updateServicesTask('http', false);
  };

  const abortTurningOffHttp = () => {
    hideConfirmModal();

    // Update http to true to reset the switch. setServices is not the optimal solution but it works
    setServices((prev) => ({
      ...prev,
      http: false,
    }));
  };

  return (
    <SettingsTabContent name={t('settings.services.title')}>
      <div id="services">
        <div className="buttonRow">
          <Button variant="primary" onClick={handleReboot}>
            {t('general.reboot')}
          </Button>
        </div>
        <GeneralServices
          loading={loading}
          services={mapGeneralServicesViewModel(services)}
          onServiceChange={handleServiceChange}
          onServiceNicChange={handleServiceNicChange}
          nicOptions={nicOptions}
        />
        <Analytics
          data-auto={generateDataAuto('analytics', 'container')}
          className="hai-mb-6"
          enabled={systemInfoCapabilities.hasAnalytics()}
          onChange={handleAnalyticsChange}
          localeInfo={{
            inSettingsTitle: t('userAnalytics.inSettingsTitle'),
            inSettingsHeadingText: t('userAnalytics.inSettingsHeadingText'),
            inSettingsInfoText: t('userAnalytics.inSettingsInfoText'),
            privacyPolicyText: t('userAnalytics.privacyPolicyText'),
          }}
        />
        <PreviewForm
          viewModel={previewViewModel}
          onSubmit={handleSubmitPreview}
          previewStatus={previewViewModel?.enabled}
          onPreviewServiceChange={(value: boolean): void => {
            handleServiceChange(Service.PREVIEW, value);
          }}
        />
        {services?.ems && (
          <div id="ems">
            <EmsForm services={services} />
          </div>
        )}
        {confirmModalShown && (
          <ConfirmHttpOffModal
            service={selectedService}
            onAccept={proceedToTurnOffHttp}
            onCancel={abortTurningOffHttp}
          />
        )}
        <RebootIsSaved ref={childRef} />
        <RebootNeeded ref={rebootNeededRef} />
      </div>
    </SettingsTabContent>
  );
};
