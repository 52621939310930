declare global {
  interface Window {
    app: any;
  }
}

export const capitalizeTheFirstLetters = (input: string) => {
  if (input === null || input === undefined || input.toLowerCase === undefined) {
    return input;
  }
  const separateWord = input.toLowerCase().split(' ');
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(' ');
};

export const truncateString = (str: string, length: number) => {
  return str && str.length > length ? str.substring(0, length) + '...' : str;
};
