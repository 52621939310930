import { FormikErrors } from 'formik/dist/types';
import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StateButton } from 'src/js/component/base/state-button';
import { useErrorReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { createEmsPairRequest } from 'src/js/model/api/request/factory/ems-pair-request-factory';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { createPairEmsTask } from 'src/js/task/settings-services-tasks';

interface Props {
  busy: boolean;
  pairingCode: string;
  hostname: string;
  port: number;
  keepAlive: number;
  validate: () => Promise<FormikErrors<any>>;
  onOperationStarted?: VoidFunction;
}

export const PairButton: React.FunctionComponent<Props> = ({
  busy,
  pairingCode,
  hostname,
  port,
  keepAlive,
  validate,
  onOperationStarted,
  ...rest
}) => {
  const { t } = useTranslation();
  const pairEmsTask = useTask(createPairEmsTask());
  const [pairingStarted, setPairingStarted] = useState<boolean>(false);

  const handlePairingCompleted = (): void => {
    setPairingStarted(false);
  };

  useEffect((): VoidFunction => {
    return handlePairingCompleted;
  }, []);

  useErrorReaction(pairEmsTask, handlePairingCompleted);

  return (
    <StateButton
      onClick={(): void => {
        validate().then((errors) => {
          if (isEmpty(errors)) {
            setPairingStarted(true);
            pairEmsTask(createEmsPairRequest(pairingCode, hostname, port, keepAlive));
            onOperationStarted?.();
          } else {
            createAndDispatchNotification(
              t('validation.invalidSubmit', { count: Object.keys(errors).length }),
              NotificationVariant.ERROR,
              t,
            );
          }
        });
      }}
      isLoading={pairingStarted || busy}
      {...rest}
    >
      {t('settings.services.section.ems.button.pair')}
    </StateButton>
  );
};
