import { FormikProps } from 'formik';
import React, { Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormBuilder, FormFieldType } from 'src/js/component/form-builder';
import systemInfo from 'src/js/data/systemInfo';

import {
  SRTAuthentication,
  SRTMode,
  StreamEncapsulation,
  StreamPublishingMode,
  StreamViewModel,
} from './stream-models';
import { buildSRTStreamIDFromParts, buildSRTStreamIDFromPartsReversed } from './streamIdHelper';

export interface StreamEditAccessControlFormRef {
  onChangeSrtMode: () => void;
}

export const StreamEditAccessControlForm = forwardRef(
  (props: FormikProps<StreamViewModel>, ref: Ref<StreamEditAccessControlFormRef>) => {
    const { t } = useTranslation();
    const { values } = props;

    const verifyStreamIdIsStandard = (e: any) => {
      const srtAccessPublishingID = e.currentTarget.value;
      let userName = '';
      let resourceName = '';
      if (srtAccessPublishingID.startsWith('#!::')) {
        const parts = srtAccessPublishingID.substring(4).split(',');
        parts.map((block: string) => {
          if (block.startsWith('u=')) {
            userName = block.substring(2);
          } else if (block.startsWith('r=')) {
            resourceName = block.substring(2);
          }
        });
        // extract username

        const srtAccessControlPublishingIdType = props?.values.srtAccessControlPublishingIdType;
        const reconstructed = buildSRTStreamIDFromParts(props?.values.srtMode, userName, resourceName);
        const reconstructed2 = buildSRTStreamIDFromPartsReversed(props?.values.srtMode, userName, resourceName);
        if (srtAccessPublishingID !== reconstructed && srtAccessPublishingID !== reconstructed2) {
          if (srtAccessControlPublishingIdType === 'standard') {
            props?.setFieldValue('srtAccessControlPublishingIdType', 'custom');
          }
        } else {
          if (srtAccessControlPublishingIdType === 'custom') {
            props?.setFieldValue('srtAccessControlPublishingIdType', 'standard');
          }
        }
      } else {
        props?.setFieldValue('srtAccessControlPublishingIdType', 'custom');
      }
    };

    const buildSrtPublishingId = () => {
      let publishingId = '#!::';
      let publishPart = 'm=publish';
      if (props?.values.srtMode === SRTMode.CALLER) {
        publishPart = 'm=request';
      }
      const userName = props?.values.srtAccessUserName;
      const resourceName = props?.values.srtAccessResourceName;
      if (userName == null || userName?.length === 0) {
        publishingId += 'r=' + resourceName + ',' + publishPart;
      } else if (resourceName == null || resourceName?.length === 0) {
        publishingId += 'u=' + userName + ',' + publishPart;
      } else {
        publishingId += 'u=' + userName + ',r=' + resourceName + ',' + publishPart;
      }
      if ((userName == null && resourceName == null) || (userName?.length === 0 && resourceName?.length === 0)) {
        publishingId = '';
      }
      props?.setFieldValue('srtAccessPublishingID', publishingId);
    };

    const [rebuildOfStreamId, triggerRebuildOfStreamId] = useState(0);
    const onChangeSrtMode = () => {
      triggerRebuildOfStreamId(rebuildOfStreamId + 1);
    };
    useEffect(() => {
      if (props.values.srtAccessControlPublishingIdType === 'standard') {
        // re-build the stream id if standard mode
        buildSrtPublishingId();
      }
    }, [rebuildOfStreamId]);

    useImperativeHandle(ref, () => ({
      onChangeSrtMode,
    }));
    return (
      <>
        {systemInfo.hasSrtStreamId === true &&
          props.values.encapsulation === StreamEncapsulation.SRT &&
          (props.values.srtMode === SRTMode.CALLER || props.values.srtMode === SRTMode.LISTENER) && (
            <FormBuilder
              key="srt-access-control"
              translationPrefixLabels="stream.fields"
              translationPrefixEnums="stream.enums"
              fields={[
                {
                  type: FormFieldType.SWITCH,
                  name: 'srtStreamId',
                  label: t('stream.srtAccessControlSwitch'),
                  onChange: () => {
                    if (
                      props.values.srtAccessControlPublishingIdType !== 'custom' &&
                      props.values.srtAccessControlPublishingIdType !== 'standard'
                    ) {
                      // This can happen on an existing non-SRT stream. The srtAccessControlPublishingIdType will not be returned by REST API so undefined.
                      props.values.srtAccessControlPublishingIdType = 'standard'; // default is standard
                    }
                  },
                },
                { type: FormFieldType.LINE_BREAK },
                { type: FormFieldType.SECTION_START, when: values.srtStreamId },
                {
                  breakBefore: true,
                  type: FormFieldType.DROPDOWN,
                  name: 'srtAccessControlPublishingIdType',
                  value:
                    values.srtAccessControlPublishingIdType /* WORKAROUND FOR BUGGY HAIUI... TODO reproduce in haiui example and create issue */,
                  enum: StreamPublishingMode,
                  when: values.encapsulation === StreamEncapsulation.SRT,
                  onChange: (value: any) => {
                    if (value.target.value === StreamPublishingMode.custom) {
                      //
                    } else {
                      if (values.authentication === SRTAuthentication.AUTO) {
                        //  props.setFieldValue('authentication', SRTAuthentication.NONE);
                      }
                    }
                  },
                },
                {
                  type: FormFieldType.INPUT_TEXT,
                  name: 'srtAccessResourceName',
                  mask: true,
                  when: values.srtAccessControlPublishingIdType === 'standard',
                  onKeyUp: buildSrtPublishingId,
                },
                {
                  type: FormFieldType.INPUT_TEXT,
                  name: 'srtAccessUserName',
                  mask: true,
                  autoComplete: 'new-password',
                  when: values.srtAccessControlPublishingIdType === 'standard',
                  onKeyUp: buildSrtPublishingId,
                },
                { type: FormFieldType.LINE_BREAK },
                {
                  breakBefore: true,
                  type: FormFieldType.INPUT_TEXT,
                  mask: true,
                  name: 'srtAccessPublishingID',
                  autoComplete: 'new-password',
                  span: 3,
                  onChange: verifyStreamIdIsStandard,
                },
                { type: FormFieldType.SECTION_END },
              ]}
            />
          )}
      </>
    );
  },
);

StreamEditAccessControlForm.displayName = 'StreamEditAccessControlForm';
