import { TabItem, Tabs } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, matchPath, useNavigate, useParams } from 'react-router-dom';
import PageHeader from 'src/js/component/page-header';
import useLocalStorageWithState from 'src/js/component/useLocalStorageWithState';
import systemInfo from 'src/js/data/systemInfo';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useNavigateListener } from 'src/js/hook/use-history-listener';
import { useStores } from 'src/js/hook/use-stores';
import { StreamKey, StreamPageParameters } from 'src/js/pages/streaming/streaming-router';
import { Paths } from 'src/js/route';
import SidebarLayout from 'src/js/sidebar';
import { useWatchObject } from 'src/js/store/use-watch';
import { constant } from 'src/js/constant';

export const Streaming: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { page } = useParams<StreamPageParameters>();
  const { sessionStore } = useStores();
  const [selectedTab, setSelectedTab] = useLocalStorageWithState(
    `${constant.lStorage.streamingTab}:${sessionStore.username}`,
    page ?? StreamKey.STREAMS,
  );

  const [tabs, setTabs] = useState<string[]>([]);
  const updateTabs = () => {
    const tabs = Object.values(StreamKey);
    setTabs(tabs);
  };

  useEffect(() => {
    updateTabs();
    const validTabs: string[] = Object.values(StreamKey);
    if (page && page !== selectedTab && validTabs.includes(page)) {
      // direct url access
      setSelectedTab(page);
    }
  }, []);
  useWatchObject(systemInfo, updateTabs);

  useNavigateListener((newLocation) => {
    const routeName = '/streaming/:page';
    const matchedParam = matchPath(routeName, newLocation.pathname);
    // Prevent people from directly accessing tabs that are currently unavailable (or from redirect after login)
    if (!isNil(matchedParam?.params) && !isNil(tabs.find((t) => t === matchedParam.params?.page))) {
      setSelectedTab(matchedParam.params?.page as StreamKey);
    }
  });

  return (
    <SidebarLayout>
      <PageHeader title={t('sidebar.streams')} iconName="Streams" />
      <Tabs
        selectedTab={selectedTab}
        onSelect={(event: ChangeEvent<{ eventkey: StreamKey }>) => {
          setSelectedTab(event.target.eventkey);
          navigate(
            compileWithArgs<StreamPageParameters>(Paths.streaming)({
              page: event.target.eventkey,
            }),
            { replace: true },
          );
        }}
      >
        {tabs.map(
          (tab): ReactNode => (
            <TabItem key={tab} caption={t(`streaming.tabs.${tab}`)} eventkey={tab} />
          ),
        )}
      </Tabs>
      <Outlet />
    </SidebarLayout>
  );
};
