import React from 'react';
import { Navigate } from 'react-router-dom';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useStores } from 'src/js/hook/use-stores';
import { ListSelectionWithDataStoreProvider } from 'src/js/provider/list-selection-with-data-store-provider';
import { ListSelectionWithDataStoreProvider2 } from 'src/js/provider/list-selection-with-data-store-provider2';
import { Paths } from 'src/js/route';
import { constant } from 'src/js/constant';
import { useReadLocalStorage } from 'usehooks-ts';

import { Accounts } from './accounts/accounts';
import { Audit } from './audit/audit';
import { Banner } from './banner/banner';
import { Certificates } from './certificates/certificates';
import { Messages } from './messages/messages';
import { Policies } from './policies/policies';
import { SecurityKey, SecurityParameters } from './security';

export const DefaultSecurityRoute: React.FunctionComponent = () => {
  const { sessionStore } = useStores();

  const selectedTab =
    useReadLocalStorage(`${constant.lStorage.securityTab}:${sessionStore.username}`) ?? SecurityKey.ACCOUNTS;

  return (
    <Navigate
      to={compileWithArgs<SecurityParameters>(Paths.security)({
        page: selectedTab as SecurityKey, // show page in localstorage if a direct section access without any specific page
      })}
      /* replace current entry in history stack instead of adding new one, thus allowing to use back button a single time to go back (as expected) instead of two */
      replace={true}
    />
  );
};

export const SecurityRoutes: any = [
  {
    path: '',
    element: <DefaultSecurityRoute />,
  },
  {
    path: SecurityKey.ACCOUNTS,
    element: <Accounts />,
  },
  {
    path: SecurityKey.AUDIT,
    element: <Audit />,
    restricted: true,
  },
  {
    path: SecurityKey.MESSAGES,
    element: <Messages />,
    restricted: true,
  },
  {
    path: SecurityKey.BANNER,
    element: <Banner />,
    restricted: true,
  },
  {
    path: SecurityKey.POLICIES,
    element: <Policies />,
    restricted: true,
  },
  {
    path: SecurityKey.CERTIFICATES,
    element: (
      <ListSelectionWithDataStoreProvider>
        <ListSelectionWithDataStoreProvider2>
          <Certificates />
        </ListSelectionWithDataStoreProvider2>
      </ListSelectionWithDataStoreProvider>
    ),
    restricted: true,
  },
];
