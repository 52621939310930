import { EmsPairRequest } from 'src/js/model/api/request/ems-pair-request';

export const createEmsPairRequest = (
  pairingCode: string,
  emsHostname: string,
  emsPort: number,
  keepAlive: number,
): EmsPairRequest => ({
  pairingCode,
  emsHostname,
  emsPort: emsPort.toString(),
  emsKeepalive: keepAlive,
});
