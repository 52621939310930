import { Button, DataTable } from '@hai/ui-react';
import { HaiDataTableColumnType, HaiSortDirection } from '@hai/ui-react/dist/types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIPath } from 'src/js/api/route-path-index';
import { triggerEvent } from 'src/js/events';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useStores } from 'src/js/hook/use-stores';
import { constant } from 'src/js/constant';
import { useLocalStorage } from 'usehooks-ts';
import { ListSelector } from '../util/listSelector';

import { presetName, sortDataTable } from './preset-helper';
import { Preset, PresetViewModel } from './preset-models';
import { loadPreset, savePresetWithoutChangingActive, updatePreset } from './preset-store';

interface Props {
  listController: ListSelector<PresetViewModel>;
  active: string;
  autoSave?: boolean;
  onDelete: VoidFunction;
  onDuplicate: (preset: Preset) => void;
  onRename: VoidFunction;
  onSetAsStartup: (preset: PresetViewModel) => void;
  shouldReloadData: () => void;
  setSingleItem: (preset: Preset) => void;
}

export const PresetTable: React.FunctionComponent<Props> = observer(
  ({
    listController,
    active,
    autoSave,
    onDelete,
    onDuplicate,
    onRename,
    onSetAsStartup,
    shouldReloadData,
    setSingleItem,
  }) => {
    const { t } = useTranslation();
    const { sessionStore } = useStores();

    const [count, setcount] = useState(1);

    const [fieldKey, setFieldKey] = useLocalStorage(
      `${constant.lStorage.presetTable}:fieldKey:${sessionStore.username}`,
      'name',
    );
    const [sortFieldKey, setSortFieldKey] = useLocalStorage(
      `${constant.lStorage.presetTable}:sortFieldKey:${sessionStore.username}`,
      'name',
    );
    const [direction, setDirection] = useLocalStorage<string>(
      `${constant.lStorage.presetTable}:directionKey:${sessionStore.username}`,
      HaiSortDirection.ASC,
    );

    const onChangeSort = (fieldKey: string, order: string, sortFieldKey: string) => {
      setFieldKey(fieldKey);
      setDirection(order);
      setSortFieldKey(sortFieldKey);
    };

    const bulkActionComponents = () => {
      return (
        <Button state="idle" size="small" onClick={onDelete}>
          {t('general.deleteBtn')}
        </Button>
      );
    };

    // Add title to presets that are too long
    useEffect(() => {
      const timeout = setTimeout(() => {
        document.querySelectorAll('.preset-table .text-data').forEach((e: any) => {
          if (e.offsetWidth < e.scrollWidth) {
            e.setAttribute('title', e.textContent);
          }
        });
      }, 300);
      setInterval(() => {
        setcount(count + 1);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    const handleAction = (eventKey: string, data: any) => {
      switch (eventKey) {
        case 'save':
          if (listController.list[data.index].name === active) {
            const displaySuccess = true;
            const overwrite = true;
            updatePreset(listController.list[data.index], overwrite, displaySuccess, t).then((_res) => {
              // amber bullet 'unsaved' to dissapear after save and update modified time
              shouldReloadData();
            });
          } else {
            savePresetWithoutChangingActive(listController.list[data.index], t).then((_res) => {
              shouldReloadData();
            });
          }
          break;

        case 'load':
          loadPreset(listController.list[data.index], t).then((res) => {
            if (res.ok) {
              shouldReloadData();
              triggerEvent('preset-load');
            }
          });
          break;

        case 'rename':
          {
            listController.unselectAll();
            const item = listController.list.find((i) => i.name === data.id);
            setSingleItem(item);
            onRename();
          }
          break;

        case 'duplicate':
          {
            const item = listController.list.find((i) => i.name === data.id);
            onDuplicate(item);
          }
          break;

        case 'download':
          window.location.assign(compileWithArgs(APIPath.presets.download)({ name: data.name }));
          break;

        case 'delete':
          {
            const toDelete = listController.list.find((i) => i.name === data.id);
            setSingleItem(toDelete);
            onDelete();
          }
          break;

        default:
          break;
      }
    };

    const radioButton = (preset: PresetViewModel) =>
      (preset.startup ? 'RadioSelected' : 'RadioUnselected') + (autoSave ? 'Disabled' : '');

    const [selectAll, setSelectAll] = useState(false);
    const handleCheckAll = (checked: boolean) => {
      setSelectAll(checked);
      checked ? listController.selectAll() : listController.unselectAll();
    };
    // console.log('selected', listController.selectedRows());
    return (
      <div className={classNames('preset-table scrollable', autoSave && 'autoSave')}>
        <DataTable
          selectable={true}
          sortFunction={sortDataTable}
          sortable={true}
          allowSortNone={false}
          initialSort={{ fieldKey: fieldKey, order: direction, sortFieldKey: sortFieldKey }}
          onSort={onChangeSort}
          columnStructure={[
            { fieldKey: 'name', useFsMask: true, title: t('general.name') },
            { fieldKey: 'modifiedTime', title: t('presets.modifiedTime'), sortFieldKey: 'mtime' },
            { fieldKey: 'startup', title: t('presets.startup'), type: HaiDataTableColumnType.ICON },
            { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS },
          ]}
          onCheckAll={handleCheckAll}
          checkAll={selectAll}
          className="datatable-modal"
          maxHeight="378px"
          emptyStateText={t('presets.addFirst')}
        >
          <DataTable.Header bulkActions={bulkActionComponents} />
          {listController.list.map((value: PresetViewModel, index: number) => (
            <DataTable.Row
              key={`${value.name}`}
              id={`${value.name}`}
              rowData={{
                index: index,
                id: `${value.name}`,
                name: presetName(value.name),
                modifiedTime: moment.unix(Number(value.mtime)).format(constant.momentFormat.presetDateTime),
                mtime: value.mtime,
                startup: radioButton(value),
              }}
              onSelect={() => listController.toggleRow(value.name)}
              checked={listController.isSelected(value.name)}
              onIconClick={() => onSetAsStartup?.(value)}
              actionItems={[
                {
                  actionIcon: 'Save',
                  title: t('general.save'),
                  eventKey: 'save',
                  onSelect: handleAction,
                },
                {
                  actionIcon: 'Import',
                  title: t('general.load'),
                  eventKey: 'load',
                  onSelect: handleAction,
                },
                {
                  actionIcon: 'Edit',
                  title: t('general.rename'),
                  eventKey: 'rename',
                  onSelect: handleAction,
                },
                {
                  actionIcon: 'Duplicate',
                  title: t('general.duplicate'),
                  eventKey: 'duplicate',
                  onSelect: handleAction,
                },
                {
                  actionIcon: 'Download',
                  title: t('general.download'),
                  eventKey: 'download',
                  onSelect: handleAction,
                },
                {
                  actionIcon: 'TrashCan',
                  title: t('general.delete'),
                  eventKey: 'delete',
                  onSelect: handleAction,
                },
              ]}
            ></DataTable.Row>
          ))}
        </DataTable>
      </div>
    );
  },
);
