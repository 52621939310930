import React from 'react';
import { triggerEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';

import { LogoutNeededNotSavedModal } from 'src/js/component/logout-needed-not-saved-modal';
import { isCurrentPresetSaved } from '../presets/preset-store';

interface UsePanelResult {
  check: () => void;
  MyModal: any;
  showIsSavedModal: boolean;
}

export const useIsSaved = (proceed: VoidFunction, cancel: VoidFunction): UsePanelResult => {
  const [savePresetModalShown, showSavePresetModal, hideSavePresetModal] = useModalControls();

  const check = () => {
    isCurrentPresetSaved().then((response) => {
      if (response.data?.isSaved === false) {
        showSavePresetModal();
      } else {
        proceed();
      }
    });
  };

  const onCancel = () => {
    hideSavePresetModal();
    cancel();
  };

  const onProceed = () => {
    proceed(); // logout
  };

  const onSave = () => {
    hideSavePresetModal();
    triggerEvent('preset-save-logout');
  };

  const LogoutNeededWrapper: React.FunctionComponent = () => {
    return (
      <>
        {savePresetModalShown && <LogoutNeededNotSavedModal onCancel={onCancel} onSave={onSave} onLogout={onProceed} />}
      </>
    );
  };

  return { check: check, MyModal: LogoutNeededWrapper, showIsSavedModal: savePresetModalShown };
};
