import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { notificationHandler } from 'src/js/notification/notification-handler';

import { StillImage } from './still-image-model';

interface Props {
  itemToDelete: StillImage;
  onCancel?: VoidFunction;
  onApply: VoidFunction;
}

export const DeleteStillImageConfirmModal: React.FunctionComponent<Props> = ({ onApply, onCancel, itemToDelete }) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    const path = APIPathWithArguments(APIPath.stillimages.single, { id: itemToDelete.name });
    return apiClientWithoutHandler.genericController
      .delete(path, itemToDelete)
      .then((res: any) => {
        notificationHandler(
          res,
          t('settings.stillImages.notifications.deleteSuccessMsg'),
          t('settings.stillImages.notifications.deleteErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        if (res.ok) {
          onApply();
        }
      });
  };

  return (
    <Dialog
      headline={t('settings.stillImages.delete.title')}
      bodyText={t('general.cannotUndo')}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel') },
        { label: t('general.delete'), variant: 'danger', onClick: handleDelete },
      ]}
    />
  );
};
