import { observer } from 'mobx-react';
import { isNil } from 'ramda';
import { FunctionComponent, ReactElement, ReactNode, useEffect } from 'react';
import { RxTask } from 'src/js/helper/mobx-tasks';
import { useErrorReaction } from 'src/js/hook/use-reaction';

interface Props<T, E extends Error> {
  task: RxTask<T>;
  taskArgs?: any[];
  children: (result: T | null) => ReactNode;
  renderError?: (error: E) => ReactNode;
  onError?: (error?: E) => void; // callback on task error
}

/**
 * This component receives an RxTask, executes it and renders the children with the result.
 * Note that the result will be null until the task gets executed, so it is
 * the children's responsibility to manage null result.
 *
 * By default, when there is an error, children are rendered with a null result.
 * To override this, use the renderError prop.
 */
export const TaskRunner: <T, E extends Error>(props: Props<T, E>) => ReactElement<Props<T, E>> = observer((<
  T,
  E extends Error,
>(
  props: Props<T, E>,
) => {
  const { task, taskArgs, children, renderError, onError } = props;
  useEffect(() => {
    if (isNil(taskArgs)) {
      task();
    } else {
      task(...taskArgs);
    }
  }, []);

  useErrorReaction<E>(task, (error?: E) => {
    if (onError) {
      onError(error);
    }
  });

  // TODO refactor notification manager and use it here
  if (task.rejected) {
    if (renderError) {
      return renderError(task.error as E);
    }
    return children(null);
  }

  if (task.pending) {
    return children(null);
  }

  return children(task.result);
}) as FunctionComponent<Props<any, any>>);
