import { Login as HaiLogin, Icon } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MakitoLogoX4 from 'src/images/makito-x4-logo.svg';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import systemInfo, { fetchSystemInfo, setSystemInfoProperty } from 'src/js/data/systemInfo';
import { triggerEvent } from 'src/js/events';
import { useAuthenticated } from 'src/js/hook/use-authenticated';
import { useStores } from 'src/js/hook/use-stores';
import { mapSessionViewModel } from 'src/js/mapper/session-view-model-mapper';
import { NotificationVariant } from 'src/js/notification/notification';
import { clearAllPermanentNotifications, createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { BannerIfAny } from 'src/js/pages/login/banner-if-any';
import { fetchPresets } from 'src/js/presets/preset-store';
import { defaultRoute } from 'src/js/route';
import { trunk } from 'src/js/service/persistence';
import { persistedStores } from 'src/js/store';
import { constant } from 'src/js/constant';
import { SessionViewModel } from 'src/js/view-model/session-view-model';

import { LoginViewModel, loginSchema } from './login-view-model';
import { PasswordExpired } from './passwordExpired';

interface Props {
  redirectToPath?: string;
}

/**
 *
 * @param reload default false, set true to trigger location.reload()
 * @param navigate required if reload is false
 */
//TODO: split in two functions instead of having parameters
export const handleLogout = (reload = false, navigate: Function) => {
  persistedStores.sessionStore.logout();
  trunk.persist();
  reload ? location.reload() : navigate(defaultRoute);
};

export const clearLocalStorage = (username: string) => {
  localStorage.removeItem(`${constant.lStorage.streamingTab}:${username}`);
  localStorage.removeItem(`${constant.lStorage.settingsTab}:${username}`);
  localStorage.removeItem(`${constant.lStorage.securityTab}:${username}`);
  localStorage.removeItem(`${constant.lStorage.showUserAnalytics}`);
};

export const Login: React.FunctionComponent<Props> = ({ redirectToPath }) => {
  const { t } = useTranslation();
  const authenticated = useAuthenticated();
  const navigate = useNavigate();
  const { sessionStore } = useStores();
  const [isPasswordExpired, setPasswordExpired] = useState(false);
  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);
  const [loading, setLoading] = useState(true);
  const [sessionViewModel, setSessionViewModel] = useState<SessionViewModel>(undefined);
  const [periodicHuntForAuth, setPeriodicHuntForAuth] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  // if we need to display hostname/fqdn
  // const [fqdn, setFqdn] = useState(systemInfo.fqdn);
  // useWatch(systemInfo, 'fqdn', setFqdn);

  useEffect(() => {
    clearAllPermanentNotifications();
  }, []);

  useEffect(() => {
    if (isNil(redirectToPath)) {
      navigate(defaultRoute);
    } else {
      navigate(redirectToPath, { replace: true });
    }

    // If version has changed, issue hard reload document.location.reload();
    if (systemInfo.upgradePending) {
      setSystemInfoProperty('upgradePending', false); // this is not really needed because the whole page will be refreshed, but for style.
      (location.reload as any)(true); // "true" for Firefox only. Ignored by other browsers https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
    }

    if (!authenticated) {
      // fetch session if any. Needed for single sign-on (sso)
      apiClientWithoutHandler.rawController.get(APIPath.session).then((res) => {
        if (res.ok) {
          const response: any = res.data;
          clearLocalStorage(response.username);
          sessionStore.login(response.username, response.gid); // this triggers 'authenticated' to change
          triggerEvent('routes-refresh');
          trunk.persist();
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [authenticated, history, redirectToPath]);

  // periodic hunt for back authenticated
  const loginCheckIntervalSec = 8;
  useEffect(() => {
    let endrun = false;
    let timer: any;
    const triggerRefresh = () => {
      apiClientWithoutHandler.rawController.get(APIPath.status.get).then((res: any) => {
        if (!res.ok && res.status === 401) {
          // unauthenticated
        } else if (res.ok && res.status === 200 && !endrun) {
          // back authenticated
          document.location.reload();
          return;
        }

        if (periodicHuntForAuth) {
          timer = setTimeout(() => {
            triggerRefresh();
          }, loginCheckIntervalSec * 1000);
        }
      });
    };

    if (periodicHuntForAuth) {
      timer = setTimeout(() => {
        triggerRefresh();
      }, loginCheckIntervalSec * 1000);
    }

    return () => {
      // cleanup
      endrun = true;
      clearTimeout(timer);
    };
  }, [periodicHuntForAuth]);

  /* If we need remember me later on
  const [rememberMe, setRememberMe] = useLocalStorage(
    `${constant.lStorage.rememberMe}:${sessionStore.username}`,
    false,
  );
  const [userName, setUserName] = useLocalStorage(
    `${constant.lStorage.loginUsername}:${sessionStore.username}`,
    '',
  );
  const toggleRememberMe = (checked: boolean) => {
    setRememberMe(checked);
    if (!checked) {
      setUserName('');
    }
  };
  */

  const handleLogin = (username: string, password: string) => {
    setButtonState('pending');
    setPeriodicHuntForAuth(false);
    const viewModel: LoginViewModel = {
      username: username,
      password: password,
    };

    return apiClientWithoutHandler.rawController.post(APIPath.session, viewModel).then((res) => {
      if (res.ok) {
        const response: any = res.data;
        if (response) {
          const sessionViewModel = mapSessionViewModel(response.data);

          setButtonState(undefined);
          setErrorMessage(null);
          if (!isNil(sessionViewModel)) {
            // if (rememberMe) {
            //   setUserName(sessionViewModel.username);
            // }

            fetchSystemInfo().then((licenseResponse) => {
              if (!licenseResponse.data.licensed) {
                createAndDispatchNotification(t('error.systemNotLicensed'), NotificationVariant.WARNING, t, {
                  autohide: false,
                });
                if (!sessionStore.isUser()) {
                  navigate('/settings/licensing');
                }
              } else {
                createAndDispatchNotification(response.message, NotificationVariant.REMINDER, t);
              }
            });

            if (sessionViewModel.passwordExp) {
              setSessionViewModel(sessionViewModel);
              setPasswordExpired(true);
            } else {
              // Registration of login to the UI application, triggering page change
              clearLocalStorage(sessionViewModel.username);
              sessionStore.login(sessionViewModel.username, sessionViewModel.gid); // this triggers 'authenticated' to change
              triggerEvent('routes-refresh');
              trunk.persist();
            }

            // Guest role has no access to presets
            if (sessionViewModel.gid !== 513) {
              fetchPresets(t);
            }
          }
        }
        return res;
      } else {
        if (res.status === 401) {
          setErrorMessage(t('error.loginErrorIncorrect'));
        } else if (res.status === 503) {
          setErrorMessage(t('error.loginErrorTooMany'));
        } else {
          setErrorMessage(t('error.loginErrorUnknown'));
        }
        setButtonState(undefined);
        return res;
      }
    });
  };

  if (authenticated) {
    return null;
  } else {
    return (
      <div className="login">
        {!loading && (
          <BannerIfAny>
            {isPasswordExpired && sessionViewModel ? (
              <PasswordExpired session={sessionViewModel} />
            ) : (
              <HaiLogin
                accentColor={constant.productColor}
                useFullPageContainer={true}
                appLogo={<Icon src={MakitoLogoX4} style={{ width: '165px' }} />}
                errorMessages={{
                  general: errorMessage,
                }}
                capsLockMessage={t('login.capsLockOn')}
                /* No footer at the moment for "security" reason
                FIXME: if fqdn fits without ellipsis, display it
                formFooter={
                  <span className="login-footer-info" title={fqdn}>
                    {hostname}
                  </span>
                } */
                handleLogin={handleLogin}
                signInButton={{
                  caption: t('login.signin'),
                  state: buttonState,
                }}
                usernameLabel={t('security.accounts.form.userName')}
                usernamePlaceholder=""
                passwordLabel={t('security.accounts.form.password')}
                passwordPlaceholder=""
                validationSchema={loginSchema(t)}
              ></HaiLogin>
            )}
          </BannerIfAny>
        )}
      </div>
    );
  }
};
