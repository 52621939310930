import { Button, ButtonToggleGroup, ListActionBar, SearchInput } from '@hai/ui-react';
import { IButtonProps, LayoutDirection, SearchInputFilter } from '@hai/ui-react/dist/types';
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useState } from 'react';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  ActionButtons: ReactNode;
  actionRightPrimary?: IButtonProps;
  actionRightSecondary?: IButtonProps;
  searchKeys?: (string | any)[]; // TODO improve typing for string or one key-pair value
  sortDropdown?: ReactNode;
  statusTypes?: Status[];
  items: any;
  cleanItems: any;
  onSearch?: () => void;
  onSelectAll?: (selected: boolean | 'indeterminate' | 'none') => void; // if set, it will let the view handle it completely
  /** setItems set the filtered-in items (visible) */
  setItems: Dispatch<SetStateAction<any>>;
  getStateValue: (model: any) => string;
}

export interface Status {
  hideWhenEmpty?: boolean;
  numberText?: string;
  status: string;
  color: string;
}

interface Filter {
  label: string;
  value?: string;
  status?: string;
  active?: boolean;
}

const ActionBar = (props: Props) => {
  const {
    ActionButtons,
    actionRightPrimary = false,
    actionRightSecondary = false,
    searchKeys,
    sortDropdown,
    statusTypes = [],
    items,
    cleanItems,
    onSearch,
    onSelectAll,
    setItems,
    getStateValue,
  } = props;

  const [selectedFilter, setSelectedFilter] = useState('None');
  const [selectAll, setSelectAll] = useState<boolean | 'indeterminate' | 'none'>(false);
  const [searchKey] = useState(searchKeys);
  const [searchItems, setSearchItems] = useState<any>([]);

  const onSelectAllHandler = onSelectAll
    ? () => onSelectAll(selectAll)
    : useCallback(() => {
        const updated = items.map((i: any) => ({
          ...i,
          selected: selectAll === false,
        }));
        setItems(updated);
      }, [items, selectAll, setItems]);

  // Build out the status filter area
  const dataStatusFilter = useCallback(
    (cleanItems: any[]) => {
      const status: Status[] = statusTypes.filter((i) => !i.hideWhenEmpty).map((i) => ({ ...i, numberText: '00' }));

      cleanItems?.forEach((item: any) => {
        let existingStatus = status.findIndex((s: Status) => s.status === getStateValue(item));
        if (existingStatus === -1) {
          const statusFromType = statusTypes.find((s: Status) => s.status === getStateValue(item));
          if (statusFromType) {
            status.push({ ...statusFromType, numberText: statusFromType.numberText || '00' });
            existingStatus = status.length - 1;
          }
        }

        if (existingStatus !== -1) {
          status[existingStatus].numberText = (Number(status[existingStatus].numberText) + 1)
            .toString()
            .padStart(2, '0');
        }
      });

      return statusTypes.map((item) => status.find((i) => i.status === item.status)).filter(Boolean);
    },
    [getStateValue, statusTypes],
  );

  // filter click
  const onChangeStatusFilter = (filter: Filter) => {
    const newFilter = filter.active && filter.status ? filter.status : 'None';
    setSelectedFilter(newFilter);
  };

  useEffect(() => {
    let updated: any[] = [];
    // let updated = items.sort(
    //   sortStreams(selectedItem.eventKey, selecteOrder.eventKey),
    // )
    // --- Filter section
    if (selectedFilter && selectedFilter !== 'None') {
      updated = cleanItems.filter((i: any) => getStateValue(i) === selectedFilter);
    } else {
      updated = cleanItems;
    }
    // --- Search section
    if (searchKey && searchItems) {
      updated = updated?.filter((u: any) => {
        let searchText = '';
        searchKey.forEach((key) => {
          if (typeof key === 'object') {
            const keys = Object.keys(key);
            keys.map((subKey) => {
              const secondLevelKey = key[subKey]; // e.g. "resolution" in stats.resolution
              searchText += ' ' + u[subKey][secondLevelKey];
            });
          } else {
            searchText += ' ' + u[key];
          }
        });
        return searchItems.every((i: string) => searchText.toLowerCase().includes(i.toLowerCase()));
      });
    }
    setItems(updated);
  }, [cleanItems, searchKey, searchItems, selectedFilter, getStateValue]);

  const searchAddFilter = (filter: string | SearchInputFilter) => {
    setSearchItems((old: any) => {
      return [...old, filter];
    });
    onSearch?.();
  };

  const searchRemoveFilter = (filter?: string | SearchInputFilter) => {
    setSearchItems((old: any) => {
      return old.filter((item: string) => {
        return item !== filter;
      });
    });
    onSearch?.();
  };

  const Search = (
    <SearchInput
      layoutDirection={LayoutDirection.LEFT}
      collapsible={true}
      onAddFilter={searchAddFilter}
      onRemoveFilter={searchRemoveFilter}
      filters={searchItems}
      searchMode="simple"
    />
  );

  useEffect(() => {
    const selectedItems = items?.filter((p: any) => p.selected);
    const isSelectAll =
      selectedItems?.length === 0 ? false : selectedItems?.length === items?.length ? true : 'indeterminate';
    setSelectAll(isSelectAll);
  }, [items]);

  return (
    <ListActionBar
      selectAll={selectAll}
      onSelectAll={onSelectAllHandler}
      className="hai-mt-7"
      data-auto={generateDataAuto('list', 'action bar')}
    >
      {statusTypes.length > 0 ? (
        <ListActionBar.Item className="statustoggle" hideOnSelect={true}>
          <ButtonToggleGroup allowAllDeactivated onOptionClicked={onChangeStatusFilter}>
            {dataStatusFilter(cleanItems).map((item) => {
              // If no items to display, revert to no filter
              if (selectedFilter === item?.status && item?.numberText === '00') {
                if (selectedFilter !== 'None') {
                  // put back selection
                  items.forEach((element: any) => {
                    element.toBeRestored = true;
                  });
                }
                setSelectedFilter('None');
              }
              return (
                <ButtonToggleGroup.StatusFilter
                  key={`list-action-bar-status-filter-${item?.status}`}
                  active={selectedFilter === item?.status}
                  numberText={item?.numberText}
                  disabled={item?.numberText === '00'}
                  status={item?.status}
                  color={item?.color}
                />
              );
            })}
          </ButtonToggleGroup>
        </ListActionBar.Item>
      ) : (
        <></>
      )}
      <ListActionBar.Item as="button-group" hideOnSelect={false}>
        {ActionButtons}
      </ListActionBar.Item>
      {searchKey ? <ListActionBar.Item alignment="right">{Search}</ListActionBar.Item> : <></>}
      {sortDropdown ? (
        <ListActionBar.Item alignment="right" className="ml-1" data-auto={generateDataAuto('sort', 'dropdown')}>
          {sortDropdown}
        </ListActionBar.Item>
      ) : (
        <></>
      )}
      {actionRightSecondary ? (
        <ListActionBar.Item alignment="right">
          <Button variant="secondary" {...actionRightSecondary} />
        </ListActionBar.Item>
      ) : (
        <></>
      )}
      {actionRightPrimary ? (
        <ListActionBar.Item alignment="right">
          <Button variant="primary" {...actionRightPrimary} />
        </ListActionBar.Item>
      ) : (
        <></>
      )}
    </ListActionBar>
  );
};
export default ActionBar;
