import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { APIPath } from 'src/js/api/route-path-index';
import { BaseController } from 'src/js/controller/base-controller';
import { EmsPairRequest } from 'src/js/model/api/request/ems-pair-request';
import { UpdateServicesRequest } from 'src/js/model/api/request/update-services-request';
import { ServicesResponse } from 'src/js/model/api/response/services-response';
import { UpdateServicesResponse } from 'src/js/model/api/response/update-services-response';
import { EmsServiceResponse } from 'src/js/pages/settings/services/ems-models';

export class SettingsServicesController extends BaseController {
  /* Returns the services */
  public getServices(): Observable<ServicesResponse> {
    return this.get$(APIPath.services.status);
  }
  /* Updates the services */
  public updateServices(request: UpdateServicesRequest): Observable<UpdateServicesResponse> {
    return this.put$(APIPath.services.status, request);
  }
  /* Returns the EMS service */
  public getEmsService(): Observable<EmsServiceResponse> {
    return this.get$(APIPath.services.ems).pipe(pluck('data'));
  }
  /* Pair EMS */
  public pairEms(request: EmsPairRequest): Observable<{}> {
    return this.put$(APIPath.ems.pair, request);
  }
  /* Unpair EMS */
  public unpairEms(): Observable<{}> {
    return this.put$(APIPath.ems.unpair);
  }
  /* Force unpair EMS */
  public forceUnpairEms(): Observable<{}> {
    return this.put$(APIPath.ems.unpairForce);
  }
}
