import { List } from '@hai/ui-react';
import React, { useEffect } from 'react';
import { SortDirection, SortManager } from 'src/js/component/sort-manager';
import { scrollPanelIntoView } from 'src/js/component/actionBar/streaming-action-bar';
import { constant } from 'src/js/constant';

import { StreamViewModel } from './stream-models';
import { StreamRow } from './stream-row';

interface Props {
  list: StreamViewModel[];
  direction: SortDirection;
  sorting: string;
  onSelect: (item: StreamViewModel, selected: boolean) => void;
  sortInfo: any;
  scrollToId: any;
}

export const StreamList: React.FunctionComponent<Props> = ({
  list,
  direction,
  sorting,
  onSelect,
  sortInfo,
  scrollToId,
}) => {
  useEffect(() => {
    scrollPanelIntoView({ id: scrollToId });
  }, []);

  return (
    <>
      <List accentColor={constant.productColor} removeMediaQueries>
        <SortManager sortInfo={sortInfo} list={list} sortKey={sorting} direction={direction}>
          {list &&
            list.map((stream) => {
              return <StreamRow key={stream.id} model={stream} onSelect={onSelect} scrollToId={scrollToId} />;
            })}
        </SortManager>
      </List>
    </>
  );
};
