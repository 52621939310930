import { FormContext, FormControl, FormGroup, FormLabel, SelectOption } from '@hai/ui-react';
import React from 'react';
import { FormChangeHandler } from 'src/js/util/global-type';

export type SelectOptions = {
  value: number | string;
  label: string;
  disabled?: boolean; // to show grayed out
  when?: boolean; // to display or not
}[];

interface Props {
  id?: string;
  title?: string;
  name?: string;
  mask?: boolean;
  defaultSelect?: any;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: FormChangeHandler;
  options: SelectOptions;
  className?: string;
  direction?: string;
}

export const FormSelect: React.FunctionComponent<Props> = ({
  title,
  name,
  mask,
  defaultSelect,
  defaultValue,
  disabled,
  onChange,
  options,
  className,
  direction = 'down',
  ...rest
}) => {
  return (
    <FormContext.Consumer>
      {(formContext: any) => {
        const meta = formContext?.getFieldMeta(name);
        const formikValue = meta?.touched ? meta.value : meta?.initialValue;
        const displayedOptions = options.filter((o) => o.when === undefined || o.when === true);
        // At the moment we let the page handle this situation but if we would want to fix them all here it could be something like that
        // const existingInOptions = displayedOptions.find((option) => option.value == formikValue);
        // if (existingInOptions === undefined) {
        // console.warn('Selected option does not exists in dropdown', name)
        // set first option
        //}
        return (
          <FormGroup className={className}>
            <FormLabel>{title}</FormLabel>
            <FormControl
              as="select"
              name={name}
              useFsMask={mask}
              disabled={disabled}
              defaultSelect={defaultSelect}
              defaultValue={defaultValue ?? formikValue}
              onChange={onChange}
              selectMenuDirection={direction}
              {...rest}
            >
              {displayedOptions.map((option, index) => (
                <SelectOption
                  key={index}
                  value={typeof option?.value === 'number' ? option?.value : option?.value}
                  disabled={option?.disabled}
                >
                  {option?.label}
                </SelectOption>
              ))}
            </FormControl>
          </FormGroup>
        );
      }}
    </FormContext.Consumer>
  );
};
