import { ButtonToggleGroup, DynamicContainer, Form, FormSection } from '@hai/ui-react';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikCheckbox } from 'src/js/component/base/form/formik-checkbox';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { FormikSwitch } from 'src/js/component/base/form/formik-switch';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { NetworkFormValues } from 'src/js/pages/settings/network/form/network-form';
import { defaultDHCPVendorId } from 'src/js/pages/settings/network/network-mapper';
import { EthX, Ipv4Addressing } from 'src/js/pages/settings/network/network-view-model';

interface InternalProps {
  ethX: EthX;
  hasEth1: boolean;
  onOptionClicked: any;
}

export const NetworkIpv4Form = (props: InternalProps & FormikProps<NetworkFormValues>) => {
  const { t } = useTranslation();

  const { ethX, hasEth1, onOptionClicked } = props;
  const shouldDisplayIpv4 = props.values[ethX].ipv4;
  const isIpv4DHCP = props.values[ethX].ipv4Addressing === Ipv4Addressing.DHCP;

  return (
    <Form className="hai-mt-6">
      <FormSection
        title={t('settings.network.section.interface')}
        titleChildren={
          hasEth1 ? (
            <ButtonToggleGroup
              className="hai-ml-3"
              options={[
                { label: t('settings.network.options.eth0'), active: ethX === 'eth0' },
                { label: t('settings.network.options.eth1'), active: ethX === 'eth1' },
              ]}
              onOptionClicked={onOptionClicked}
            />
          ) : null
        }
      >
        <DynamicContainer minColumns={3}>
          <FormikSwitch name={ethX + '.ipv4'} switchLabel={t('settings.network.form.ipv4')} />
        </DynamicContainer>

        {shouldDisplayIpv4 && (
          <DynamicContainer minColumns={3}>
            <FormikSelect
              name={ethX + '.ipv4Addressing'}
              title={t('settings.network.form.addressing')}
              selectValues={Object.values(Ipv4Addressing)}
              selectLabels={Object.values(Ipv4Addressing).map((value: string) =>
                t(`settings.network.addressing.${value}`),
              )}
            />
          </DynamicContainer>
        )}

        {shouldDisplayIpv4 && (
          <DynamicContainer minColumns={2}>
            <FormikTextfield
              name={ethX + '.ip'}
              mask
              title={t('settings.network.form.ipAddress')}
              disabled={isIpv4DHCP}
              required={!isIpv4DHCP}
            />
            <FormikTextfield
              name={ethX + '.netmask'}
              mask
              title={t('settings.network.form.netmask')}
              disabled={isIpv4DHCP}
              required={!isIpv4DHCP}
            />
            <FormikTextfield
              name={ethX + '.gateway'}
              mask
              title={t('settings.network.form.gateway')}
              disabled={isIpv4DHCP}
              required={!isIpv4DHCP}
            />
          </DynamicContainer>
        )}

        {shouldDisplayIpv4 && isIpv4DHCP && (
          <>
            <DynamicContainer minColumns={3}>
              <FormikTextfield
                name={ethX + '.dhcpVendorId'}
                mask
                title={t('settings.network.form.dhcpVendor')}
                placeholder={defaultDHCPVendorId}
              />
            </DynamicContainer>
            <DynamicContainer minColumns={1}>
              <FormikCheckbox name={ethX + '.linklocal'} label={t('settings.network.form.linklocal')} />
            </DynamicContainer>
          </>
        )}
      </FormSection>
    </Form>
  );
};
