import { TFunction } from 'i18next';
import moment, { Moment } from 'moment';
import { append, has, prop } from 'ramda';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import systemInfo from 'src/js/data/systemInfo';
import { convertCSVStringToData } from 'src/js/helper/csv-helper';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { createWatchStore, updateWatchStore } from 'src/js/store/use-watch';
import { ChartViewModel } from 'src/js/view-model/chart-view-model';
import { mapColumns, mergeGraphDataInnerJoin } from '../statistic/statistic-mapper';

// numbers in bytes
export interface BandwidthRow {
  time: string;
  timeM?: Moment;
  eth0Rx?: number;
  eth0Tx?: number;
  eth1Rx?: number;
  eth1Tx?: number;
}

export type BandwidthResponse = BandwidthRow[];

// values will be inited from API at runtime
const bandwidth: BandwidthRow[] = [];
createWatchStore(bandwidth);
export default bandwidth;

export const mapBandwidthResponse = (json: BandwidthResponse) => {
  return json.map((item) => {
    item.timeM = moment(item.time);
    return item;
  });
};

export const mapBandwidthResponse2 = (json: BandwidthResponse) => {
  return json.map((item) => {
    item.timeM = moment(item.time);
    return item;
  });
};

const rangeToParam = (range: StatsInterval) => {
  if (range === StatsInterval.LAST_24_HOURS) {
    return '24h';
  }
  if (range === StatsInterval.LAST_60_MINS) {
    return '60m';
  }
  return '5m';
};
export const fetchBandwidth = (range?: StatsInterval, setReportedNics?: (nics: string[]) => void) => {
  return apiClientWithoutHandler.rawController
    .get<BandwidthResponse>(
      `${APIPathWithArguments(APIPath.bandwidth, { index: 'eth0' })}?range=${rangeToParam(range)}`,
    )
    .then((res) => {
      if (res.ok) {
        let response = res.data;
        if (systemInfo.hasEth1) {
          return apiClientWithoutHandler.rawController
            .get<BandwidthResponse>(
              `${APIPathWithArguments(APIPath.bandwidth, { index: 'eth1' })}?range=${rangeToParam(range)}`,
            )
            .then((res2) => {
              let response2: BandwidthResponse = [];
              let merged: BandwidthResponse;
              if (res2.ok) {
                response2 = res2.data;
                merged = mergeGraphDataInnerJoin(response, response2, ['RecvRate', 'XmitRate']);
                if (response2?.length > 0) {
                  setReportedNics(['eth0', 'eth1']);
                } else {
                  setReportedNics(['eth0']);
                }
              } else {
                setReportedNics(['eth0']);
                merged = response;
                if (typeof merged === 'string') {
                  merged = convertCSVStringToData(merged);
                }
              }
              if (Array.isArray(merged) && merged?.length > 120) {
                merged = merged.slice(-120);
              }
              const mapped = mapColumns(merged, [
                { DateTime: 'time' },
                { RecvRate: 'eth0Rx' },
                { XmitRate: 'eth0Tx' },
                { RecvRate2: 'eth1Rx' },
                { XmitRate2: 'eth1Tx' },
              ]);
              updateWatchStore(bandwidth, mapBandwidthResponse2(mapped));
              return res2;
            });
        } else if (response) {
          setReportedNics(['eth0']);
          if (typeof response === 'string') {
            response = convertCSVStringToData(response);
          }
          if (typeof response?.map === 'function') {
            const mapped = mapColumns(response, [
              { DateTime: 'time' },
              { RecvRate: 'eth0Rx' },
              { XmitRate: 'eth0Tx' },
              { RecvRate2: 'eth1Rx' },
              { XmitRate2: 'eth1Tx' },
            ]);
            mapBandwidthResponse(mapped);
            updateWatchStore(bandwidth, mapped);
          }
        }
      }
      return res;
    });
};

// incoming values in kbits/s
export const mapBandwidthToPlotData = (data: BandwidthResponse, t: TFunction): ChartViewModel[] => {
  const yFields = [
    { name: 'eth0Rx', label: t('statistics.bandwidth.eth0Rx') },
    { name: 'eth0Tx', label: t('statistics.bandwidth.eth0Tx') },
    { name: 'eth1Rx', label: t('statistics.bandwidth.eth1Rx') },
    { name: 'eth1Tx', label: t('statistics.bandwidth.eth1Tx') },
  ];

  // Create the view models with the proper key (will translate later)
  const viewModels: ChartViewModel[] = yFields.map((yField) => ({
    name: yField.name,
    values: [],
    label: yField.label,
  }));

  // Map all the data to the view models
  const renderWindow = data.slice(-120); // cap at 120 points
  renderWindow.map((series) => {
    // slice() is a failover if the backend returns spurious large amount of CSV data
    viewModels.map((field: ChartViewModel) => {
      // @ts-ignore
      const value = has(field.name, series) && prop(field.name, series);
      if (value != null && (value as any) !== false) {
        field.values = append({ collectedAt: series.timeM, value: Number(value) }, field.values);
      }
    });
  });

  // Translate the keys
  const mapped = viewModels.map((viewModel) => ({
    name: viewModel.name,
    label: t(`statistics.chart.csvValues.${viewModel.name}`),
    values: viewModel.values,
  }));

  return mapped;
};
