import { RefObject } from 'react';

/**
 * Returns the first parent (or itself) that is a scrollable container
 * @param ref
 */
const getParentScrollElementFromRef = (ref: RefObject<HTMLDivElement>): HTMLElement | null => {
  if (!ref || !ref.current) {
    return null;
  }

  let currentElement: HTMLElement | null = ref.current;

  while (currentElement) {
    const overflow = window.getComputedStyle(currentElement as Element).getPropertyValue('overflow-y');

    if (overflow === 'auto' || overflow === 'scroll') {
      return currentElement;
    }
    currentElement = currentElement.parentElement;
  }
  return null;
};

/**
 * Returns the first parent (or itself) that is a scrollable container
 * @param element HTMLElement
 */
const getParentScrollElement = (element: HTMLElement): HTMLElement | null => {
  if (!element) {
    return null;
  }

  let currentElement: HTMLElement | null = element;

  while (currentElement) {
    const overflow = window.getComputedStyle(currentElement as Element).getPropertyValue('overflow-y');

    if (overflow === 'auto' || overflow === 'scroll') {
      return currentElement;
    }
    currentElement = currentElement.parentElement;
  }
  return null;
};

/**
 * Scrolls to a specific html div element
 * @param ref
 */
export const scrollToRef = (ref: RefObject<HTMLDivElement>, offset = 0) => {
  if (ref && ref.current) {
    const scrollContainer = getParentScrollElementFromRef(ref);
    if (scrollContainer) {
      scrollContainer.scrollTop = ref.current.offsetTop - offset;
    }
  }
};

export const scrollToId = (id: string, offset = 0) => {
  if (id) {
    const element = document.getElementById(id);
    if (element) {
      const scrollContainer = getParentScrollElement(element);
      if (scrollContainer) {
        scrollContainer.scrollTop = element.offsetTop - offset;
      }
    }
  }
};
