import colorlib from 'color';

const palette = {
  gray: {
    1: 'rgb(37, 38, 44)',
    2: 'rgb(51, 51, 60)',
    3: 'rgb(60, 61, 72)',
    4: 'rgb(82, 83, 98)',
    5: 'rgb(109, 111, 129)',
    6: 'rgb(134, 134, 146)',
    7: 'rgb(149, 149, 161)',
    8: 'rgb(185, 186, 203)',
    9: 'rgb(218, 218, 230)',
    10: 'rgb(232, 233, 241)',
    11: 'rgb(239, 239, 244)',
  },
  hex: {
    c4c4c4: 'rgb(196, 196, 196)', // not in haiui but in UX design
  },
  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)',
  blue: {
    1: 'rgb(30, 111, 241)',
    2: 'rgb(152, 187, 243)',
    3: 'rgb(0, 114, 206)',
    4: 'rgb(61,132,243)',
  },
  red: 'rgb(234, 70, 69)',
  green: 'rgb(105, 227, 136)',
  amber: 'rgb(245, 190, 50)',
  aqua: 'rgb(48, 203, 209)',
  pink: '#fb5ac0',
  purple: 'rgba(149, 112, 227)',
  progress: {
    start: '#9570e3',
    end: '#d9bfff',
  },
};

const withAlpha =
  (color: string) =>
  (alphaValue: number): string => {
    return colorlib(color).alpha(alphaValue).string();
  };

export const colors = {
  ...palette,
  withAlpha,
};
