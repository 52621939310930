import { OverlayTrigger } from '@hai/ui-react';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { FormChangeHandler } from 'src/js/util/global-type';
import { generateDataAuto } from 'src/js/util/automation';

export enum SDIType {
  NORMAL = 'normal',
  HIGHLIGHT = 'highlight',
  OUTLINE = 'outline',
}

interface MainProps {
  checked?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  tooltip?: string;
  type?: SDIType;
  value?: string;
  onChange?: FormChangeHandler;
}

export const SdiCheckbox: React.FunctionComponent<MainProps> = ({
  checked,
  children,
  className,
  disabled,
  name,
  tooltip,
  type = SDIType.NORMAL,
  value,
  onChange,
  ...rest
}) => {
  return (
    <OverlayTrigger popoverContent={tooltip} placement="top-center">
      <label
        className={classNames('sdi-checkbox', 'unselectable', checked && 'checked', type, className)}
        data-auto={generateDataAuto('sdi_checkbox', name)}
      >
        <input
          disabled={disabled}
          type="checkbox"
          value={value}
          style={{ display: 'none' }}
          name={name}
          checked={checked}
          onChange={onChange}
          {...rest}
        />
        {children}
      </label>
    </OverlayTrigger>
  );
};
