import { FormControl, FormGroup, FormLabel } from '@hai/ui-react';
import React, { MutableRefObject, useState } from 'react';
import { FormChangeEvent, FormChangeHandler } from 'src/js/util/global-type';
import { isNilOrEmpty } from 'src/js/util/global-util';

interface Props {
  id?: string;
  className?: string;
  mask?: boolean;
  inputClassName?: string;
  title?: string;
  name: string;
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  type?: string;
  autoComplete?: string;
  pwdToggle?: boolean;
  onChange?: FormChangeHandler;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement> & FormChangeEvent) => void;
  autoFocus?: boolean;
  inputRef?: MutableRefObject<any>;
  maxLength?: string;
  style?: any;
}

export const FormTextfield: React.FunctionComponent<Props> = ({
  id,
  className,
  mask,
  inputClassName,
  title,
  name,
  value,
  defaultValue,
  placeholder,
  hint,
  required = false,
  type = 'text',
  autoComplete = 'off',
  pwdToggle = false,
  disabled,
  onChange,
  onKeyUp,
  autoFocus,
  inputRef,
  maxLength,
  style,
}) => {
  const checkIfNumber = (event: React.KeyboardEvent<HTMLInputElement> & FormChangeEvent) => {
    return (
      !/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight|Control|Meta|Home|End|v|c|x)/.test(event.key) &&
      event.preventDefault()
    );
  };

  const [focus, setFocus] = useState(false);

  return (
    <FormGroup className={className} style={style}>
      <FormLabel required={required} htmlFor={id || name}>
        {title}
      </FormLabel>
      <FormControl
        id={id || name}
        name={name}
        useFsMask={mask}
        className={inputClassName}
        disabled={disabled}
        inputRef={inputRef}
        placeholder={focus ? hint ?? placeholder : placeholder ?? hint}
        type={type}
        pwdToggle={pwdToggle}
        value={value}
        defaultValue={defaultValue}
        onKeyUp={onKeyUp}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement> & FormChangeEvent) =>
          type === 'number' && checkIfNumber(event)
        }
        onChange={onChange}
        onFocus={(): void => {
          setFocus(true);
        }}
        onBlur={(): void => {
          setFocus(false);
        }}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        showHint={!isNilOrEmpty(hint)}
        maxLength={maxLength}
      />
    </FormGroup>
  );
};
