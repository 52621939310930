import { DependencyList, useEffect, useRef } from 'react';

/*
 * useAdaptiveFetch() HOOK

 * This periodic fetch will wait for each fetch to return with the promise before scheduling the next iteration.
 * Like this if the server is not responding, or responding slower than the configured "delay", there won't be
 * piled up fetch commands backed up and all being served once the server comes back.
 * It will keep only one request pending.
 *
 * Note: The first fetch is immediate
 *
 * Examples:
 *  If the delay is 5 seconds, and the fetch time is 2 seconds, then each fetch iteration will be every 7 seconds.
 *  If the delay is 1 seconds, and the system reboots or goes offline and each request timeouts after 30 seconds, then the fetch iteration will be each 31 seconds until rebooted.
 *
 */
export const useAdaptiveFetch = (
  /**
   * function to call periodically. Must return Promise
   */
  callback: () => Promise<any> | undefined,
  /**
   * delay in milliseconds
   * Set to null or undefined to stop pooling. Can be changed on-the-fly but put in deps also to be safe
   */
  delay: number | null | undefined,
  /**
   * Optional list of additional React dependancies
   */
  deps: DependencyList = [],
): void => {
  const savedCallback = useRef<() => Promise<any>>();

  if (deps === undefined) {
    deps = [delay];
  } else {
    deps = deps.concat(delay);
  }

  // Remember the latest callback. This prevents to cancel the current timer and re-create it when the function changes. Instead update the ref.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const tick = (): Promise<any> | null => {
    if (savedCallback.current) {
      return savedCallback.current();
    }
    return null;
  };

  let endrun = false;
  useEffect(() => {
    let id: any;
    const executeFunc = () => {
      const promise = tick();
      if (promise !== null && promise !== undefined) {
        promise.then(() => {
          if (!endrun) {
            id = setTimeout(executeFunc, delay);
          }
        });
      } else if (!endrun) {
        id = setTimeout(executeFunc, delay);
      }
    };

    if (delay !== null && delay !== undefined && delay >= 0) {
      id = setTimeout(executeFunc); // immediate
    }
    return () => {
      endrun = true;
      if (id !== undefined) {
        clearTimeout(id);
      }
    };
  }, deps);
};
