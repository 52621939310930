import { Button, DataTable, Form, FormSection, Placeholder } from '@hai/ui-react';
import { ButtonStateType, HaiDataTableColumnType } from '@hai/ui-react/dist/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIPath } from 'src/js/api/route-path-index';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { openNewWindow } from 'src/js/util/global-util';

import {
  CertificateIdentityImport,
  CertificateIdentityViewModel,
  IdentityGenerateViewModel,
  currentIdentityNames,
  translateIdentityType,
} from './certificates-models';
import { IdentityDeleteModal } from './identity-delete-modal';
import { IdentityGenerateModal } from './identity-generate-modal';
import { IdentityImportModal } from './identity-import-modal';
import { generateDataAuto } from 'src/js/util/automation';
import { ListSelector } from 'src/js/util/listSelector';

interface Props {
  listController: ListSelector<CertificateIdentityViewModel>;
  shouldReloadData: () => void;
  onIdentityGenerate: (model: IdentityGenerateViewModel) => Promise<any>;
  onUploadIdentity: (model: CertificateIdentityImport) => Promise<any>;
  setAsDefault: (model: CertificateIdentityViewModel) => Promise<any>;
  loading?: boolean;
}

export const IdentityTable: React.FunctionComponent<Props> = ({
  listController,
  shouldReloadData,
  setAsDefault,
  onIdentityGenerate,
  onUploadIdentity,
  loading = false,
}) => {
  const { t } = useTranslation();

  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const [importShown, showImport, hideImport] = useModalControls();
  const [generateShown, showGenerate, hideGenerate] = useModalControls();

  const handleUploadIdentityLocal = (model: CertificateIdentityImport) => {
    return onUploadIdentity(model).then((res: any) => {
      if (res.ok) {
        hideImport();
        shouldReloadData();
        listController.reset();
        setSelectAll(false);
      }
    });
  };

  const onSuccessDelete = (): void => {
    hideDelete();
    shouldReloadData();
    listController.reset();
    setSelectAll(false);
  };

  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);
  const handleIdentityGenerateLocal = (model: IdentityGenerateViewModel): void => {
    setButtonState('pending');
    onIdentityGenerate(model).then((res: any) => {
      if (res.ok) {
        hideGenerate();
        shouldReloadData();
      }
      setButtonState(undefined);
    });
  };

  const bulkActionComponents = () => {
    return (
      <Button state="idle" size="small" onClick={showDelete}>
        {t('general.deleteBtn')}
      </Button>
    );
  };

  const handleAction = (eventKey: string, data: any) => {
    switch (eventKey) {
      case 'view':
        openNewWindow(compileWithArgs(APIPath.identity.download)({ name: data.name }), data.name);
        break;

      case 'delete':
        listController.unselectAll();
        listController.select(data.name);
        showDelete();
        break;

      default:
        break;
    }
  };

  const columnStructure = [
    { fieldKey: 'name', useFsMask: true, title: t('general.name') },
    { fieldKey: 'type', title: t('general.type') },
    {
      fieldKey: 'default',
      title: t('general.default'),
      type: HaiDataTableColumnType.ICON,
    },
    { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS },
  ];

  const [selectAll, setSelectAll] = useState(false);
  const handleCheckAll = (checked: boolean) => {
    setSelectAll(checked);
    checked ? listController.selectAll() : listController.unselectAll();
  };

  const PlaceHolderPage = () => {
    return <Placeholder.Table columnStructure={columnStructure} numRows={3} showTitlebar />;
  };
  // console.log('selected', listController.selectedRows())
  return (
    <>
      <Form className="hai-mb-6">
        <FormSection className="hai-p-0">
          {loading ? (
            <PlaceHolderPage />
          ) : (
            <DataTable
              selectable={true}
              sortable={false}
              columnStructure={columnStructure}
              onCheckAll={handleCheckAll}
              checkAll={selectAll}
              className="datatable-form-section-title"
              data-auto={generateDataAuto('datatable', 'identity')}
              emptyStateText={t('security.certificates.identity.addFirst')}
            >
              <DataTable.TitleBar
                title={t('security.certificates.identity.title')}
                rightComponent={
                  <>
                    <Button size="small" onClick={showGenerate} className="hai-mr-5">
                      {t('general.generate')}
                    </Button>
                    <Button size="small" onClick={showImport}>
                      {t('general.import')}
                    </Button>
                  </>
                }
              />
              <DataTable.Header bulkActions={bulkActionComponents} />
              {listController.list.map((value: CertificateIdentityViewModel, index: number) => (
                <DataTable.Row
                  key={`${value.name}-${index}`}
                  rowData={{
                    index: index,
                    id: `${value.name}-${index}`,
                    name: value.name,
                    type: translateIdentityType(value.type, t),
                    default: value.type === 'CSR' ? '' : value.default ? 'RadioSelected' : 'RadioUnselected',
                  }}
                  data-auto={generateDataAuto('table-row', value.name)}
                  onSelect={() => listController.toggleRow(value.name)}
                  checked={listController.isSelected(value.name)}
                  onIconClick={() => setAsDefault(value)}
                  actionItems={[
                    {
                      actionIcon: 'GoTo',
                      title: t('general.view'),
                      eventKey: 'view',
                      onSelect: handleAction,
                    },
                    {
                      actionIcon: 'TrashCan',
                      title: t('general.delete'),
                      eventKey: 'delete',
                      onSelect: handleAction,
                    },
                  ]}
                ></DataTable.Row>
              ))}
            </DataTable>
          )}
        </FormSection>
      </Form>
      {generateShown && (
        <IdentityGenerateModal
          listOfUsedNames={currentIdentityNames(
            listController.list.map((viewModel: CertificateIdentityViewModel) => viewModel.name),
          )}
          onCancel={hideGenerate}
          onSubmit={handleIdentityGenerateLocal}
          buttonState={buttonState}
        />
      )}
      {importShown && <IdentityImportModal onCancel={hideImport} onSubmit={handleUploadIdentityLocal} />}
      {deleteShown && (
        <IdentityDeleteModal
          itemsToDelete={listController.selectedRows()}
          onCancel={() => {
            hideDelete();
            listController.unselectAll();
          }}
          onSuccess={onSuccessDelete}
        />
      )}
    </>
  );
};
