import { Checkbox, FormControlError, FormGroup } from '@hai/ui-react';
import { useField } from 'formik';
import React from 'react';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  name: string;
  disabled?: boolean;
  label?: string;
  className?: string;
  checked?: boolean;
}

export const FormikCheckbox: React.FunctionComponent<Props> = ({ name, disabled, label, className, checked }) => {
  const [, meta, helper] = useField(name);
  const hasErrors = meta.error && meta.touched;
  const value = meta.touched ? meta.value : meta.initialValue;

  const additionalClassNames = className ? ` ${className}` : '';

  return (
    <FormGroup className={`FormItem${additionalClassNames}`} data-auto={generateDataAuto('form', `group ${name}`)}>
      <Checkbox
        checked={checked ?? value}
        onChange={(e: any) => {
          const { checked } = e.target;
          helper.setTouched(true);
          helper.setValue(checked);
        }}
        invalid={hasErrors ? 'true' : 'false'}
        disabled={disabled}
      >
        {label}
      </Checkbox>
      <FormControlError show={hasErrors} mssg={meta.error} />
    </FormGroup>
  );
};
