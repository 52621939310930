import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  service: string;
  onCancel?: VoidFunction;
  onAccept?: VoidFunction;
}

export const ConfirmHttpOffModal: React.FunctionComponent<Props> = ({ service, onCancel, onAccept }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      headline={t('settings.services.condirmHttpOffModal.title')}
      bodyText={t('settings.services.condirmHttpOffModal.bodyText', { service })}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel') },
        {
          label: t('settings.services.condirmHttpOffModal.proceedButton'),
          variant: 'primary',
          onClick: onAccept,
        },
      ]}
    />
  );
};
