import { colorValue } from '@hai/ui-react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { v4 as uuid } from 'uuid';

export interface LegendButtonsProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Allows to have all optionItems deactivated.
   * If false, and no option is active in first place, the first option will be selected.
   *
   * @default false
   */
  allowAllDeactivated?: boolean;

  /**
   * the children will be displayed as options
   */
  children?: any;

  /**
   * Custom class to be applied to the component.
   */
  className?: string;

  /**
   * Allows to have more than one option active at the same time.
   *
   * @default false
   */
  multiSelection?: boolean;

  /**
   * Event dispatched when one option is clicked.
   * It provides the new option state as parameter with the following interface: {label: string; value?: string; active?: boolean}
   */
  onOptionClicked?: (opt: LegendButtonsToggleOption) => void;

  /**
   * Array of optionItems for the group.
   * Each option as an object with the following interface: {label: string; value?: string; active?: boolean}
   */
  options?: LegendButtonsToggleOption[];
  title?: string;
}

export interface LegendButtonsSubComponentProps extends LegendButtonsProps {
  StatusFilter?: JSX.Element;
}

export interface LegendButtonsToggleOption {
  [key: string]: any;
  active?: boolean;
  children?: JSX.Element;
  className?: string;
  color?: string;
  indicatorColor?: string;
  label: string;
  value?: string;
}

export const LegendButtons = (props: LegendButtonsProps) => {
  const {
    onOptionClicked = null,
    className = null,
    multiSelection = false,
    allowAllDeactivated = false,
    options,
    title,
    children,
    ...rest
  } = props;

  const statueFiltersToOpts = (): LegendButtonsToggleOption[] => {
    return children?.map((ch: any, idx: number) => ({
      active: ch.props.active,
      color: ch.props.color,
      children: ch,
      label: '',
      disabled: ch.props.disabled,
      value: `${ch.props.numberText as string}-${ch.props.status as string}-${idx}`,
    }));
  };

  const digestOptions = useCallback(
    (opts: LegendButtonsToggleOption[]): LegendButtonsToggleOption[] => {
      if (!opts || opts.length === 0) {
        return [];
      }

      const items = children ? statueFiltersToOpts() : options;
      const digestedOptions = items.map((option) => {
        if (option.value) {
          return option;
        } else {
          return {
            ...option,
            value: option.label,
          };
        }
      });

      const activatedOptions = digestedOptions.filter((opt) => opt.active);
      if (activatedOptions.length === 0 && !allowAllDeactivated) {
        digestedOptions[0].active = true;
      } else if (activatedOptions.length > 1 && !multiSelection) {
        let firstActivatedOptionFound = false;
        for (const option of digestedOptions) {
          if (option.active) {
            if (!firstActivatedOptionFound) {
              firstActivatedOptionFound = true;
            } else {
              option.active = false;
            }
          }
        }
      }
      return digestedOptions;
    },
    [allowAllDeactivated, multiSelection, options, children],
  );

  const onOptionClick = (opt: LegendButtonsToggleOption) => {
    // if at least one option needs to be active and the selected option is the only one currently selected, we do nothing
    if (!allowAllDeactivated) {
      const currentlyActivatedOptions = optionItems.filter((o) => o.active);
      if (currentlyActivatedOptions.length === 1 && currentlyActivatedOptions[0].value === opt.value) {
        return;
      }
    }

    opt.active = !opt.active;

    const newOptions = optionItems.map((option) => {
      if (option.value === opt.value) {
        return opt;
      } else {
        return option;
      }
    });

    // if multi selection isn't allowed, we need to ensure that only one option is activated
    if (!multiSelection && opt.active) {
      for (const option of newOptions) {
        if (option.value !== opt.value) {
          option.active = false;
        }
      }
    }

    setOptionItems(newOptions);

    if (onOptionClicked) {
      onOptionClicked(opt);
    }
  };

  const [optionItems, setOptionItems] = useState<LegendButtonsToggleOption[]>(
    digestOptions(children ? statueFiltersToOpts() : options),
  );

  useEffect(() => {
    setOptionItems(digestOptions(children ? statueFiltersToOpts() : options));
  }, [props, digestOptions]);

  const showDividerClass = (idx: number) => {
    let show = false;
    if (optionItems?.length && idx > 0 && idx < optionItems.length) {
      show = !optionItems[idx].active && !optionItems[idx - 1].active;
    }

    return show ? 'show-divider' : '';
  };

  const renderOptionLabel = (opt: LegendButtonsToggleOption) => {
    const { indicatorColor, active, label } = opt;

    return (
      <div className="button-toggle-group-item">
        {indicatorColor && (
          <div
            className="color-indicator"
            style={{ backgroundColor: active ? indicatorColor : colorValue('haiui-gray-05') }}
          />
        )}
        <div>{label}</div>
      </div>
    );
  };

  // update active element
  const buttonRefs = useRef([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleEnterKeyPress = (targetIndex: number, opt: any) => {
    setActiveIndex(targetIndex);
    onOptionClick(opt);
  };

  const handleClick = (targetIndex: number, opt: any) => {
    setActiveIndex(targetIndex);
    onOptionClick(opt);
  };

  useEffect(() => {
    activeIndex && buttonRefs.current[activeIndex].focus();
  }, [activeIndex, buttonRefs, optionItems]);

  return (
    <div
      className={classNames(
        'mk-legend-buttons',
        className,
        optionItems?.length === 1 && 'single',
        multiSelection && 'multiSelection',
      )}
      {...rest}
    >
      {title && <div className="title">{title}</div>}
      {optionItems.map((opt, idx) => {
        const { active, color, label, children, value, className = '', disabled, indicatorColor, ...rest } = opt;

        const borderColor = color && active ? { borderColor: color } : {};
        const style = color
          ? {
              style: {
                color,
                ...borderColor,
              },
            }
          : {};

        const optEleProps = {
          key: `button-toggle-option-${uuid()}`,
          'aria-label': label,
          role: 'button',
          onKeyDown: (e: any) => {
            e.key === 'Enter' && handleEnterKeyPress(idx, opt);
          },
          onClick: () => handleClick(idx, opt),
          ref: (element: any) => (buttonRefs.current[idx] = element),
          tabIndex: 0,
          className: classNames(
            'mk-legend-option',
            'option',
            'unselectable',
            active && !disabled && 'active',
            disabled && 'disabled',
            className,
          ),
          ...rest,
        };

        return (
          <React.Fragment key={`button-toggle-option-with-divider-${idx}`}>
            {!multiSelection && !!idx && <div className={`button-toggle-divider ${showDividerClass(idx)}`} />}
            <div {...optEleProps} {...style}>
              {children ? children : renderOptionLabel(opt)}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
