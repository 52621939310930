/*eslint no-console: 0*/
/*eslint curly: 0*/

const DEBUG = false;
/**
 * changesArray
 * returns Array of changed keys or other unknown properties added/removed
 */
export const changesArray = <T>(actual: T, initial: T): Array<keyof T | any> => {
  if (actual == null) {
    console.warn('actual is empty');
    return [];
  }
  if (initial == null) {
    console.warn('initial is empty');
    return [];
  }

  const changedKeys: Array<keyof T | any> = [];

  // compare all props of a single item
  const compareFunc = (oldObj: any, newObj: any, level: number) => {
    let hasChanged = false;
    if (Array.isArray(oldObj)) {
      if (newObj == null) {
        hasChanged = true;
      } else {
        if (oldObj.length !== newObj.length) {
          if (DEBUG) console.warn('Changed array', oldObj, newObj);
          hasChanged = true;
        }
        for (let i = 0; i < oldObj.length; i++) {
          if (compareFunc(oldObj[i], newObj[i], level + 1)) {
            hasChanged = true;
            if (level === 1) {
              changedKeys.push(i);
            }
            if (DEBUG) console.warn('Changed array', oldObj, newObj);
          }
        }
      }
    } else if (typeof oldObj === 'object' && oldObj != null) {
      Object.keys(oldObj).map((key: any) => {
        if (Object.prototype.hasOwnProperty.call(oldObj, key)) {
          const original = oldObj[key as keyof T];
          if (newObj == null) {
            hasChanged = true;
            if (level === 1) {
              changedKeys.push(key);
            }
          } else {
            const newVal = newObj[key as keyof T];
            if (compareFunc(original, newVal, level + 1)) {
              hasChanged = true;
              if (level === 1) {
                changedKeys.push(key);
              }
              if (DEBUG) console.warn('Changed object', oldObj, newObj);
            }
          }
        }
      });
    } else {
      if (oldObj != newObj) {
        // soft compare
        hasChanged = true;
        if (level === 1) {
          changedKeys.push(''); // plain string ?
        }
        if (DEBUG) console.warn('Changed prop', oldObj, newObj);
      }
    }
    return hasChanged;
  };

  let hasChanged = compareFunc(initial, actual, 1);
  hasChanged = compareFunc(actual, initial, 1);
  if (DEBUG) console.error('hasItemChanged?', hasChanged, changedKeys);
  return changedKeys;
};

/**
 * changes
 * returns Object with changed keys or other unknown properties added/removed
 */
export const changes = <T>(model: T, initial: T) => {
  const changed = changesArray(model, initial).reduce((map: any, key) => {
    map[key] = model[key as keyof T];
    return map;
  }, {});
  return changed;
};
