import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from 'src/js/sidebar/sidebar';

interface Props {
  children?: ReactNode;
}

const Layout: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="layout-with-sidebar">
      <Sidebar />
      <Outlet />
      {props.children}
    </div>
  );
};

export default Layout;
