import { Button, DataTable, Form, FormSection, Placeholder } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIPath } from 'src/js/api/route-path-index';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { generateDataAuto } from 'src/js/util/automation';
import { openNewWindow } from 'src/js/util/global-util';
import { ListSelector } from 'src/js/util/listSelector';

import { CaDeleteModal } from './ca-delete-modal';
import { CaImportModal } from './ca-import-modal';
import { CertificateCa, CertificateCaImport } from './certificates-models';

interface Props {
  listController: ListSelector<CertificateCa>;
  shouldReloadData: () => void;
  onUploadCa: (model: CertificateCaImport) => Promise<any>;
  loading?: boolean;
}

export const CaTable: React.FunctionComponent<Props> = ({
  listController,
  shouldReloadData,
  onUploadCa,
  loading = false,
}) => {
  const { t } = useTranslation();

  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const [importShown, showImport, hideImport] = useModalControls();

  const handleUploadCaLocal = (model: CertificateCaImport) => {
    return onUploadCa(model).then((res: any) => {
      if (res.ok) {
        hideImport();
        shouldReloadData();
        listController.reset();
        setSelectAll(false);
      }
    });
  };

  const onSuccessDelete = (): void => {
    hideDelete();
    shouldReloadData();
    listController.reset();
    setSelectAll(false);
  };

  const bulkActionComponents = () => {
    return (
      <Button state="idle" size="small" onClick={showDelete}>
        {t('general.deleteBtn')}
      </Button>
    );
  };

  const handleAction = (eventKey: string, data: any) => {
    switch (eventKey) {
      case 'view':
        openNewWindow(compileWithArgs(APIPath.ca.download)({ name: data.name }), data.name);
        break;

      case 'delete':
        listController.unselectAll();
        listController.select(data.name);
        showDelete();
        break;

      default:
        break;
    }
  };

  const columnStructure = [
    { fieldKey: 'name', useFsMask: true, title: t('general.name') },
    { fieldKey: 'type', title: t('general.type') },
    { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS },
  ];

  const [selectAll, setSelectAll] = useState(false);
  const handleCheckAll = (checked: boolean) => {
    setSelectAll(checked);
    checked ? listController.selectAll() : listController.unselectAll();
  };

  const PlaceHolderPage = () => {
    return <Placeholder.Table columnStructure={columnStructure} numRows={3} showTitlebar />;
  };

  // console.log('selected', listController.selectedRows())
  return (
    <>
      <Form className="hai-mb-6">
        <FormSection className="hai-p-0">
          {loading ? (
            <PlaceHolderPage />
          ) : (
            <DataTable
              selectable={true}
              sortable={false}
              columnStructure={columnStructure}
              onCheckAll={handleCheckAll}
              checkAll={selectAll}
              className="datatable-form-section-title"
              data-auto={generateDataAuto('datatable', 'ca')}
              emptyStateText={t('security.certificates.ca.addFirst')}
            >
              <DataTable.TitleBar
                title={t('security.certificates.ca.title')}
                rightComponent={
                  <Button size="small" onClick={showImport}>
                    {t('general.import')}
                  </Button>
                }
              />
              <DataTable.Header bulkActions={bulkActionComponents} />
              {listController.list.map((value: CertificateCa, index: number) => (
                <DataTable.Row
                  key={`${value.name}-${index}`}
                  rowData={{
                    index: index,
                    id: `${value.name}-${index}`,
                    name: value.name,
                    type: value.type,
                    default: value.type === 'CSR' ? '' : value.default ? 'RadioSelected' : 'RadioUnselected',
                  }}
                  onSelect={() => listController.toggleRow(value.name)}
                  checked={listController.isSelected(value.name)}
                  actionItems={[
                    {
                      actionIcon: 'GoTo',
                      title: t('general.view'),
                      eventKey: 'view',
                      onSelect: handleAction,
                    },
                    {
                      actionIcon: 'TrashCan',
                      title: t('general.delete'),
                      eventKey: 'delete',
                      onSelect: handleAction,
                    },
                  ]}
                ></DataTable.Row>
              ))}
            </DataTable>
          )}
        </FormSection>
      </Form>
      {importShown && <CaImportModal onCancel={hideImport} onUploadCa={handleUploadCaLocal} />}

      {deleteShown && (
        <CaDeleteModal
          itemsToDelete={listController.selectedRows()}
          onCancel={() => {
            hideDelete();
            listController.unselectAll();
          }}
          onSuccess={onSuccessDelete}
        />
      )}
    </>
  );
};
