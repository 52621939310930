import { isNil, last } from 'ramda';
import React, { useState } from 'react';
import { RouteProps, useLocation } from 'react-router';
import systemInfo from 'src/js/data/systemInfo';
import { useAuthenticated } from 'src/js/hook/use-authenticated';
import { Login } from 'src/js/pages/login';
import { useWatch } from 'src/js/store/use-watch';
import { idleAutoLogoutManager } from 'src/js/util/idle-auto-logout-manager';

import Layout from '../layout';

let titleChanged = false;

interface AuthenticatedProps {
  sidebar?: boolean;
}

export const AuthenticatedRoute: React.FunctionComponent<RouteProps & AuthenticatedProps> = (props) => {
  const authenticated = useAuthenticated();
  const [title, setTitle] = useState(systemInfo.deviceName);
  const location = useLocation();
  useWatch(systemInfo, 'deviceName', (value) => {
    titleChanged = false;
    setTitle(value);
  });

  const getRedirectToPath = (): string | undefined => {
    const { path } = props;
    if (isNil(location?.pathname)) {
      if (isNil(path)) {
        return undefined;
      }
      if (Array.isArray(path)) {
        return last(path);
      }
      return path;
    }
    return location?.pathname + location?.search;
  };

  if (authenticated) {
    // make sure we have an autoLogout tracker
    idleAutoLogoutManager.startMonitoring();
    if (!titleChanged && title && title.length > 0) {
      (document as any).title = title;
      titleChanged = true;
    }

    const { sidebar = true } = props;
    return <>{sidebar ? <Layout>{props.children}</Layout> : <>{props.children}</>}</>;
  } else {
    idleAutoLogoutManager.stopMonitoring();
    return <Login redirectToPath={getRedirectToPath()} />;
  }
};
