// TODO: Should be some translation here
const defaultMessage = 'An unknown error occurred while performing client request';

/**
 * Represents any `ApiSauce` error.
 */
export class ClientError extends Error {
  constructor(message: string = defaultMessage) {
    super(message);
  }
}

export interface DefaultServerErrorResponse {
  type: string;
  message: string;
}

export class ClientErrorWithResponse extends ClientError {
  response: DefaultServerErrorResponse;

  constructor(response?: DefaultServerErrorResponse, message: string = defaultMessage) {
    super(message);
    this.response = response ?? {
      type: '',
      message: defaultMessage,
    };
  }
}

/**
 * Represents an `ApiSauce` `CONNECTION_ERROR` or `NETWORK_ERROR` problem.
 */
export class ClientNetworkError extends ClientError {
  constructor() {
    // TODO: Should be some translation here
    super('A network error occurred while performing client request');
  }
}

/**
 * Represents an `ApiSauce` `TIMEOUT_ERROR` problem.
 */
export class ClientTimeoutError extends ClientError {
  constructor() {
    // TODO: Should be some translation here
    super('A timeout occurred while performing client request');
  }
}

export class ClientAuthenticationError extends ClientError {
  constructor() {
    // TODO: Should be some translation here
    super('This request is unauthorized');
  }
}

export class Server503Error extends ClientError {
  constructor() {
    super('Server returned 503: Service Not Available');
  }
}
