import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { StreamSrtStatsResponse } from 'src/js/model/api/response/stream-srt-stats-response';
import { LineChart } from 'src/js/pages/statistic/plot/line-chart';
import { mapStreamSRTStatisticToPlotData } from 'src/js/pages/statistic/statistic-mapper';
import { StreamViewModel } from 'src/js/pages/streaming/stream/stream-models';
import { chartColors } from 'src/js/theme/chart';
import { constant } from 'src/js/constant';

interface Props {
  header?: React.ReactNode;
  model: StreamViewModel;
  graphData: StreamSrtStatsResponse[];
  pathSelected?: string[];
  showRedundancy?: boolean;
  timescale: StatsInterval;
  width?: number;
  height?: number;
  fullpage?: boolean;
}

export const StatisticPlotContainer: React.FunctionComponent<Props> = ({
  graphData,
  header,
  pathSelected,
  showRedundancy = false,
  timescale,
  width,
  height,
  fullpage,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mk-graph-container">
      {header}

      {graphData != null && (
        <>
          <LineChart
            data={mapStreamSRTStatisticToPlotData(graphData, constant.chart.stream.firstFields, t, showRedundancy)}
            height={height}
            lineColors={chartColors.stream.first}
            pathSelected={pathSelected}
            showLegend={true}
            showRedundancy={showRedundancy}
            timescale={timescale}
            title={fullpage ? t('statistics.chart.delayTitleFull') : t('statistics.chart.delayTitle')}
            width={width}
            yTitle="ms"
          />

          <LineChart
            data={mapStreamSRTStatisticToPlotData(graphData, constant.chart.stream.secondFields, t, showRedundancy)}
            defaultInactiveFields={constant.chart.stream.defaultInactiveRateFields}
            height={height}
            lineColors={chartColors.stream.second}
            pathSelected={pathSelected}
            showLegend={true}
            showRedundancy={showRedundancy}
            timescale={timescale}
            title={fullpage ? t('statistics.chart.bandwidthTitleFull') : t('statistics.chart.bandwidthTitle')}
            width={width}
            yTitle="kbps"
          />
        </>
      )}
    </div>
  );
};
