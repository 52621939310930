import { apiClientWithoutHandler } from '../api';
import { constant } from '../constant';

/**
 * Util method to know if user is currently online via a ping. Send a source URL to use
 * to do use callback to know if the user is online.
 * If there is no error, the user is online
 *
 * @param sourceURL: The URL to use to ping
 * @param callback: Callback, if `error` is nil, use is online, else, offline
 */

export const ping = (sourceURL: string, callback: (error?: Error) => void) => {
  const img = new Image();
  img.onload = (_event) => {
    callback(undefined);
  };
  img.onerror = () => {
    callback(new Error('Image could not be loaded'));
  };
  img.src = sourceURL + '?' + Date.now();
};

// Strip of trailing .0, i.e. 2.7.0 will be 2.7
// Leave .1, i.e. 2.7.1 will be 2.7.1
const truncateVersion = (version: string) => {
  return (version.match(/\./g) || []).length == 2 && version.endsWith('.0') ? version.slice(0, -2) : version;
};

// Generate documentation URL and display in tab
// product: Must match that used in InfoCenter.
// * CS, CDVR, HaiHelper, HMP, HMG, HVC, KB, Kraken, MakitoXEnc, MakitoXDec, STB
// * A complete list can be found at https://haivision.jira.com/wiki/spaces/MIX/pages/122257526/File+Naming+Convention+for+User+Documentation+HAI+Bundle
// version: 3.0, 2.6.1 etc.
// admin: true or false for whether user is administrator (default false)
export const getInfoCenterURL = function (product: string, version: string, admin: boolean) {
  version = truncateVersion(version);
  const data = '?utm_source=' + product + '&utm_campaign=' + version + '&utm_medium=' + (admin ? 'admin' : 'user');
  return `${constant.onlineDocURL}/${product}${version}${data}`;
};

// Check if InfoCenter help is available
export const infoCenterHelpExists = (callback: (error?: Error) => void) => {
  ping(constant.onlineCheckURL, callback);
};

// Check if local help is available
export const localHelpExists = (callback: (error?: string) => void) => {
  apiClientWithoutHandler.genericController.get('/help/index.html').then((res: any) => {
    if (res.ok) {
      callback(null);
    } else {
      callback('error');
    }
  });
};
