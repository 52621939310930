import { base } from 'src/js/i18n/english/base.en.i18n';
import { dashboard } from 'src/js/i18n/english/dashboard.en.i18n';
import { decoder } from 'src/js/i18n/english/decoder.en.i18n';
import { security } from 'src/js/i18n/english/security.en.i18n';
import { settings } from 'src/js/i18n/english/settings.en.i18n';
import { statistics } from 'src/js/i18n/english/statistics.en.i18n';
import { stream } from 'src/js/pages/streaming/stream/stream.en.i18n';
import { validation } from 'src/js/i18n/english/validation.en.i18n';

export const english = {
  ...base,
  ...dashboard,
  ...decoder,
  ...settings,
  ...security,
  ...statistics,
  ...stream,
  ...validation,
};
