import { ProgressRing, Card, colorValue } from '@hai/ui-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import systemStatus from 'src/js/data/systemStatus';
import { useWatchObject } from 'src/js/store/use-watch';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {}

export const ResourcesCard = (_props: Props) => {
  // const {  } = props;
  const { t } = useTranslation();

  const [status, setStatus] = useState(systemStatus);
  useWatchObject(systemStatus, setStatus);

  const percentToColor = (percent: number) => {
    if (percent >= 99) {
      return 'haiui-red-01';
    }
    if (percent >= 85) {
      return 'haiui-amber-01';
    }
    return 'haiui-green-01';
  };

  return (
    <Card
      title={t('dashboard.resources.title')}
      id="card-resources"
      className="audio-16"
      icon="CPU"
      //titleOnClick={() => navigate('/')}
    >
      <div className="dashboard-card">
        <ProgressRing
          data-auto={generateDataAuto('progress_ring', 'cpu')}
          label={t('dashboard.resources.cpu')}
          currentPercentage={status.cpu}
          currentPercentColor={colorValue(percentToColor(status.cpu))}
        ></ProgressRing>

        <ProgressRing
          label={t('dashboard.resources.ram')}
          data-auto={generateDataAuto('progress_ring', 'ram')}
          currentPercentage={status.ram}
          currentPercentColor={colorValue(percentToColor(status.ram))}
        ></ProgressRing>
      </div>
    </Card>
  );
};
