import { colors } from 'src/js/theme/colors';
import { typography } from 'src/js/theme/typography';
import { constant } from 'src/js/constant';
import { VictoryThemeDefinition } from 'victory-core';

/*
 * Dark theme for Victory Charts. Conforms to VictoryThemeDefinition
 */

export const defaultChartColors = [constant.productColor, colors.white, colors.amber];
export const defaultBarChartColors = ['#ea4645', '#f5be32', '#69e388'];
export const chartColors = {
  bandwidth: [colors.aqua, colors.pink, colors.purple, colors.blue[4]],
  stream: {
    first: [colors.purple, colors.white, colors.amber],
    firstDashboard: [colors.purple, colors.white, colors.amber],
    second: [colors.aqua, colors.amber, colors.blue[4]],
    secondDashboard: [colors.aqua, colors.amber, colors.blue[4]],
    generic: [colors.aqua],
  },
};

const baseProps = {
  width: 400,
  height: 150,
  colorScale: defaultChartColors,
};

const baseLabelStyles = {
  fontFamily: typography.med[2]['font-family'],
  fontSize: typography.med[2]['font-size'],
  padding: 4,
  fill: colors.gray['7'],
  stroke: 'transparent',
};

/* style props for horizontal axis */
export const horizontalAxisStyle = {
  grid: {
    //display: 'none',
    // FIXME
    strokeOpacity: 0.05,
    stroke: colors.white,
    strokeDasharray: '0',
    strokeWidth: 1,
  },
  axis: {
    strokeOpacity: 0.1,
    stroke: colors.white,
    strokeWidth: 1,
  },
  ticks: {
    display: 'none',
  },
  tickLabels: {
    ...baseLabelStyles,
    padding: 4,
  },
};

/* style props for vertical axis */
export const verticalAxisStyle = {
  axis: {
    stroke: 'transparent',
  },
  grid: {
    strokeOpacity: 0.1,
    stroke: colors.white,
    strokeDasharray: '0',
    strokeWidth: 1,
  },
  axisLabel: {
    padding: 0,
  },
  ticks: {
    display: 'none',
  },
  tickLabels: baseLabelStyles,
};

export const defaultChartTheme = (groupColors: string[] = defaultChartColors): VictoryThemeDefinition => ({
  axis: {
    style: {
      axis: {
        fill: 'transparent',
        stroke: colors.white,
        strokeWidth: 1,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
      },
      axisLabel: {
        textAnchor: 'middle',
        ...baseLabelStyles,
        padding: 0,
      },
      grid: {
        fill: 'none',
        stroke: 'none',
        pointerEvents: 'painted',
      },
      ticks: {
        fill: 'transparent',
        size: 1,
        stroke: 'transparent',
      },

      tickLabels: baseLabelStyles,
    },
  },
  chart: {
    ...baseProps,
  },
  group: {
    colorScale: groupColors,
    ...baseProps,
  },
  line: {
    style: {
      data: {
        fill: 'transparent',
        stroke: 'blue',
        strokeWidth: 1,
      },
      labels: baseLabelStyles,
    },
    ...baseProps,
  },
});

export const axisTitleStyle = {
  fill: colors.gray[8],
  zoom: 1.1,
  fontWeight: '600',
  textAlign: 'right',
};
