import { includes } from 'ramda';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { LegendButtons } from './legend-buttons';

/*
* Legend component to be used with LineChart
*
* colorScale: array of colors for filling symbols
  * NOTE: colorScale should match chart colorScale
  * or inconsistencies will exist between chart and legend colors
*
* fields: array of field names and labels
* handleClick: handler for clicking on key or label
  field: the field name (key) that was clicked
* inactiveItems: fields (series) that are hidden from display
*/

interface Props {
  items: { label: string; value: string }[];
  colorScale: string[];
  handleClick?: (field: string) => void;
  inactiveItems: string[];
}

export const LegendHorizontal: React.FunctionComponent<Props> = ({
  items,
  colorScale,
  handleClick,
  inactiveItems = [],
  ...rest
}) => {
  const onClick = (item: string) => {
    if (handleClick) {
      handleClick(item);
    }
  };

  const options = items.map((i, index) => ({
    key: uuid(),
    active: !includes(i.value, inactiveItems),
    label: i.label,
    indicatorColor: colorScale[index % colorScale.length],
    onClick: () => onClick(i.value),
  }));

  return (
    <div className={'mk-graph-legend horizontal'} {...rest}>
      <div>
        {options.length > 0 ? (
          <LegendButtons allowAllDeactivated multiSelection options={options} />
        ) : (
          <div style={{ height: '22px' }} />
        )}
      </div>
    </div>
  );
};
