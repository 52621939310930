// Initially <SettingsModal> in previous version

import { Button, Icon, Modal, ModalBody } from '@hai/ui-react';
import { TaskGroup } from 'mobx-task';
import { isNil } from 'ramda';
import React, { HTMLAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { StateButton } from 'src/js/component/base/state-button';
import { RxTask } from 'src/js/helper/mobx-tasks';
import { generateDataAuto } from 'src/js/util/automation';

interface Props extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  text?: string;
  iconname?: string;
  renderBody?: () => ReactNode;
  applyLabel?: string;
  onApply?: VoidFunction;
  applyTask?: RxTask<any> | TaskGroup<any, any>;
  applyDisabled?: boolean;
  cancelLabel?: string;
  onCancel?: VoidFunction;
  cancelDisabled?: boolean;
  onHide?: VoidFunction;
  size?: 'lg' | 'fit' | undefined;
}

export const GenericModal: React.FunctionComponent<Props> = ({
  title,
  text,
  iconname,
  renderBody,
  applyLabel,
  onApply,
  applyTask,
  applyDisabled = false,
  cancelLabel,
  onCancel,
  cancelDisabled = false,
  onHide,
  size,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleOnHide = (): void => {
    if (onHide) {
      onHide();
    } else if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal
      show
      onHide={handleOnHide}
      centered
      type={size === 'fit' ? 'fit' : undefined}
      size={size !== 'fit' ? size : undefined}
      {...rest}
    >
      <ModalBody>
        <div className="modal-align-center-container">
          {iconname && <Icon className="hai-mb-5" iconname="StatusWarning" size="lg2" />}
          {title && <div className="hai-mb-4 modal-body-title">{title}</div>}
          {text && (
            <div className="modal-body-text" data-auto={generateDataAuto('modal', 'text')}>
              {text}
            </div>
          )}
          {renderBody && renderBody()}
        </div>
        {(!isNil(onCancel) || !isNil(onApply)) && (
          <div className="hai-mt-9 d-flex justify-content-center">
            {onCancel && (
              <Button variant="secondary" onClick={onCancel} disabled={cancelDisabled} type="button">
                {cancelLabel ?? t('general.cancel')}
              </Button>
            )}
            {onApply && (
              <StateButton variant="primary" onClick={onApply} task={applyTask} disabled={applyDisabled}>
                {applyLabel ?? t('general.apply')}
              </StateButton>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};
