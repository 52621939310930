import { ServicesResponse } from 'src/js/model/api/response/services-response';
import { Services } from 'src/js/model/services';

export const mapServices = (response: ServicesResponse): Services => ({
  ems: response.ems === 1,
  http: response.http === 1,
  nic: response.nic,
  preview: response.preview === 1,
  snmp: response.snmp === 1,
  ssh: response.ssh === 1,
  telnet: response.telnet === 1,
});
