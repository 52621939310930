import { colorValue } from '@hai/ui-react';
import { TFunction } from 'i18next';
import { GenericResponse } from 'src/js/model/api/response/generic-response';
import { formatPreprocessorState, isNilOrEmpty, toTitleCase } from 'src/js/util/global-util';
import * as yup from 'yup';
import { translateStreamState } from '../stream/stream-helper';
import streamModels from '../stream/stream-store';

export enum DecoderInfoStateEnum {
  STOPPED = 0,
  STARTED = 1,
}

export enum DecoderStatsStateEnum {
  STOPPED = 0,
  STARTED = 1,
  ACTIVE = 2, // means is receiving stream
  NOT_DECODING = -1, // stream is not supported
}

export enum DecoderTroubleCodeEnum {
  NONE = 0,
  UNSUPPORTED = -1,
  UNLICENSED = -2,
  OVERSUBSCRIBED = -3,
  NO_MEMORY = -4,
  WAITING_FOR_KEY_FRAME = -5,
}

export enum BufferingModeEnum {
  UNSET = 0,
  ADAPTIVE,
  FIXED,
  AUTOMATIC,
  MULTISYNC,
  MINIMUM,
  STANDARD_HDR,
  LOWLATENCY,
}

export enum MultiSyncStateEnum {
  WCI_MULTISYNC_STATE_UNSET = 0,
  WCI_MULTISYNC_STATE_WORKING = 2,
  WCI_MULTISYNC_STATE_NTP_NOT_SET = 3,
  WCI_MULTISYNC_STATE_TIMECODE_NOT_PRESET = 4,
  WCI_MULTISYNC_STATE_TIMECODE_INVALID = 5,
  WCI_MULTISYNC_STATE_OUTSIDE_RANGE = 6,
}

export enum StillImagesEnum {
  FREEZE = 1,
  BLACK_SCREEN,
  BLUE_SCREEN,
  COLOR_BAR,
  MUTE,
  CUSTOM,
}

export enum DecoderHdrEnum {
  OFF = -1,
  AUTOMATIC = 0,
  FORCE_HLG = 1,
  FORCE_PQ = 2,
}

export enum QuadModeEnum {
  NORMAL = 0,
  QUAD_2SI,
  // Not in 1.0
  // QUAD_SQD,
}

export const outputFrameRateOptions = (t: TFunction) => [
  { value: 0, label: t(`decoder.frameRateAuto`) },
  { value: 60, label: '60' },
  { value: 59, label: '59.94' },
  { value: 50, label: '50' },
  { value: 30, label: '30' },
  { value: 29, label: '29.97' },
  { value: 25, label: '25' },
  { value: 24, label: '24' },
  { value: 23, label: '23.98' },
];

export interface DecoderStats {
  uptime: string;
  state: DecoderStatsStateEnum;
  troubleCode: number;
  streamState: number;
  streamHasSrtToUdp: boolean;
  decoderHasStarted: boolean;
  lastReset: string;

  // TODO enStateTroubleCode;

  // SMGR_CBUFFERING_STAT
  bufferingMode: string;
  bufferingState: string;
  bufferingAdjustments: string;
  videoLatency: string;
  stcToPcrLeadTime: string;
  videoOutputJitterMs: number;
  videoStcLeadTime: string;
  videoStcLeadTimeDetails: string;

  // SMGR_VFRAMER_STATS
  videoAlgorithm: string;
  videoLevel: string;
  videoProfile: string;
  videoFraming: string;
  videoSlicesPerFrame: string;
  preprocessorState: string;
  unlicensedReason?: string;
  unsupportedReason?: string;
  vframerNumInputPackets: number;
  videoInputResolution: string;
  videoInputFrameRate: string;

  // RANGES
  delayRangeMinMs: number;
  delayRangeMaxMs: number;
  multisyncDelayRangeMinMs: number;
  multisyncDelayRangeMaxMs: number;
  multisyncDelayActual: string;
  multisyncDelayRange: string;
  multisyncDelaySet: string;
  multisyncStatus: string;
  multisyncStatusCode: MultiSyncStateEnum;
  multisyncSystemTime: string;
  multisyncTimeDiff: string;
  multisyncTimecode: string;
  multisyncTimecodePackets: string;
  multisyncTimecodePacketsInvalid: string;
  multisyncTransmissionTime: string;

  //SMGR_METADATADEC_STATS
  klv: boolean;
  closedCaption: boolean;
  timeCode: boolean;
  afd: boolean;
  //klv
  klvUnlicensed: boolean;
  klvPayloadBytes: string;
  klvReceivedPackets: string;
  klvOutputPackets: string;
  klvFreedPackets: string;
  klvLatency: string;
  ccPayloadBytes: string;
  ccReceivedPackets: string;
  ccOutputPackets: string;
  ccFreedPackets: string;
  ccLatency: string;
  tcPayloadBytes: string;
  tcReceivedPackets: string;
  tcOutputPackets: string;
  tcFreedPackets: string;
  tcLatency: string;
  tcTimecodeValue: string;
  afdPayloadBytes: string;
  afdReceivedPackets: string;
  afdOutputPackets: string;
  afdFreedPackets: string;
  afdLatency: string;

  //SMGR_VCUDEC_STATS
  videoDecoderState: string;
  videoDisplayFormat: string;
  videoDisplayResolution: string;
  videoFrameRate: string;
  loadPercentage: number;
  stillImage: string;
  displayedOutputFrames: string;
  skippedOutputFrames: string;
  replayedOutputFrames: string;
  corruptedFrames: string;
  corruptedFramesDropped?: string;

  //SMGR_DECODER_AUDIO_STATS
  audioState: string;
  audioSampleRate?: string;
  audioPairs: [
    {
      avSyncMs: number;
      bitrate: string;
      compression: string;
      language: string;
      dbLeft: string;
      dbLeftMax: string;
      dbLeftMaxLastTimeSec: number;
      dbRight: string;
      dbRightMax: string;
      dbRightMaxLastTimeSec: number;
      decodeErrors: number;
      discontinuities: number;
      mode: string;
      surroundPair?: string;
      inputLayout?: string;
      inputLayoutDetailed?: string;
      outputLayout?: string;
      outputLayoutDetailed?: string;
      outputErrors: number;
      sampleRateIn: number;
      sampleRateOut: number;
      stcLeadTime: string;
      stcLeadTimeDetails: string;
    },
  ];
  audioPairsAmount: number;
  audioDecodedFrames?: string;
  audioPlayedFrames?: string;
  audioSkippedFrames?: string;
  downmixSurround?: boolean;

  // SMGR_DECODER_CLOCK_RECOVERY_STATS
  clockTrackingMode: string;
  clockStatus: string;
  clockReSyncCount: number;
  clockCurrentStc: string;
  clockStcAvg?: string;

  //HDR
  hdrTypeIn: string;
  hdrType: string;
  hdrColourPrimaries: string;
  hdrTransferCharacteristics: string;
  hdrMatrixCoefficients: string;
}

export interface Decoder {
  id: number;
  state: number;
  streamId?: number;
  altStreamId?: number;
  bufferingMode: number;
  bufferingDelay: number;
  downmixSurround: boolean;
  dropCorruptedFrames: boolean;
  hdrDynamicRange: number;
  multisyncBufferingDelay: number;
  name?: string;
  nNumOfOutputs: number;
  outputs: boolean[];
  outputFrameRate: number;
  quadMode: QuadModeEnum;
  previewIntervalSec: number;
  previewEnabled: boolean;
  stats?: DecoderStats;
  stillImage: number;
  stillImageDelay: number;
  stillImageFilename?: string;
}

export enum DecoderAction {
  START = 'start',
  STOP = 'stop',
  SAVE = 'save', // http PUT
}

export interface DecoderResponse extends GenericResponse {
  info: Decoder;
  stats: DecoderStats;
}

export interface GetAllDecodersResponse {
  data: DecoderResponse[];
}

export interface DecoderViewModel extends Decoder {
  stateString: string;
  stateSortable: number;
  color: string;
  dashboardColor: string;

  outputsOrig: boolean[];
  status: string;
  selected: boolean /* checked by checkbox */;
  hidden: boolean /* when filtered out */;
  updated: string /* to trigger setState to detect change and re-render */;
}

export interface MkRange {
  min: number;
  max: number;
}

export interface DecoderFrameGraphResponse {
  DateTime: string;
  Skipped: string;
  Replayed: string; // FIXME number?
  Displayed: string; // FIXME number?
}

export const mapDashboardColor = (id: number, state: DecoderInfoStateEnum) => {
  if (state !== DecoderInfoStateEnum.STARTED) {
    return 'haiui-gray-07';
  }

  if (id === 0) {
    return 'haiui-aqua-01';
  } else if (id === 1) {
    return 'haiui-blue-04';
  } else if (id === 2) {
    return 'haiui-purple-01';
  } else if (id === 3) {
    return 'haiui-yellow-01';
  } else {
    return 'haiui-gray-01';
  }
};

export const decoderBufferingModeToString = (mode: string, t: TFunction): string => {
  if (isNilOrEmpty(mode)) {
    return mode;
  }

  const modeArr = mode.split(' (');
  if (modeArr.length > 0) {
    return t(`decoder.bufferingModes.${modeArr[0]}`) + (modeArr.length > 1 ? ' (' + modeArr[1] : '');
  } else {
    return mode;
  }
};

const decoderStateToString = (state: DecoderStatsStateEnum, t: TFunction): string => {
  return t(`decoder.state.${state}`);
};

const decoderStateToSortable = (state: DecoderStatsStateEnum): number => {
  switch (state) {
    case DecoderStatsStateEnum.ACTIVE:
    case DecoderStatsStateEnum.STARTED:
      return 1;
    case DecoderStatsStateEnum.NOT_DECODING:
      return 2;
    case DecoderStatsStateEnum.STOPPED:
      return 3;
  }
};

export const translateDecoderState = (
  decoderState: DecoderStatsStateEnum,
  troubleCode: DecoderTroubleCodeEnum,
  t: TFunction,
): string => {
  if (decoderState === DecoderStatsStateEnum.NOT_DECODING && troubleCode !== DecoderTroubleCodeEnum.NONE) {
    return t(`decoder.state.${decoderState}`) + ' (' + t(`decoder.troubleCode.${troubleCode}`) + ')';
  }
  return t(`decoder.state.${decoderState}`);
};

// For ActionBar status filter
export const decoderColorToActionBarStatus = (color: string): string => {
  switch (color) {
    case colorValue('haiui-green-01'):
      return 'ACTIVE';
    case colorValue('haiui-blue-03'):
    case colorValue('haiui-blue-04'):
    case colorValue('haiui-blue-05'):
      return 'IDLE';
    case colorValue('haiui-red-01'):
    case colorValue('haiui-amber-01'):
      return 'ALERT';
    case colorValue('haiui-gray-01'):
    case colorValue('haiui-gray-02'):
    case colorValue('haiui-gray-03'):
    case colorValue('haiui-gray-04'):
    case colorValue('haiui-gray-05'):
    case colorValue('haiui-gray-06'):
    case colorValue('haiui-gray-07'):
    case colorValue('haiui-gray-08'):
      return 'INACTIVE';
  }
  return 'ALERT';
};

export const decoderStateToIcon = (decoder: Partial<DecoderViewModel>): string => {
  const enState = decoder?.stats?.state;
  const fixedRange = fixedDelayRange(decoder?.stats);
  const multiSyncRange = multiSyncDelayRange(decoder?.stats);
  switch (enState) {
    case DecoderStatsStateEnum.ACTIVE:
      if (decoder.bufferingMode === BufferingModeEnum.FIXED) {
        if (decoder.bufferingDelay < fixedRange.min) {
          return 'StatusWarning';
        } else if (decoder.bufferingDelay > fixedRange.max) {
          return 'StatusWarning';
        }
      } else if (decoder.bufferingMode === BufferingModeEnum.MULTISYNC) {
        if (decoder.multisyncBufferingDelay < multiSyncRange.min) {
          return 'StatusWarning';
        } else if (decoder.multisyncBufferingDelay > multiSyncRange.max) {
          return 'StatusWarning';
        } else if (
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_NTP_NOT_SET ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_OUTSIDE_RANGE ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_TIMECODE_INVALID ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_TIMECODE_NOT_PRESET ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_UNSET
        ) {
          return 'StatusWarning';
        }
      }
      if (decoder.stats?.preprocessorState === 'UNLICENSED HDR') {
        return 'StatusWarning';
      }
      return 'StatusStreaming';
    case DecoderStatsStateEnum.STARTED:
      return 'StatusListening';
    case DecoderStatsStateEnum.STOPPED:
      return 'StatusStopped';
    case DecoderStatsStateEnum.NOT_DECODING:
      return 'StatusError';
  }
};

export const decoderStateToColor = (decoder: Partial<DecoderViewModel>): string | undefined => {
  const enState = decoder?.stats?.state ?? decoder?.state;
  const fixedRange = fixedDelayRange(decoder?.stats);
  const multiSyncRange = multiSyncDelayRange(decoder?.stats);
  switch (enState) {
    case DecoderStatsStateEnum.ACTIVE:
      if (decoder.bufferingMode === BufferingModeEnum.FIXED) {
        if (decoder.bufferingDelay < fixedRange.min) {
          return colorValue('haiui-amber-01');
        } else if (decoder.bufferingDelay > fixedRange.max) {
          return colorValue('haiui-amber-01');
        }
      } else if (decoder.bufferingMode === BufferingModeEnum.MULTISYNC) {
        if (decoder.multisyncBufferingDelay < multiSyncRange.min) {
          return colorValue('haiui-amber-01');
        } else if (decoder.multisyncBufferingDelay > multiSyncRange.max) {
          return colorValue('haiui-amber-01');
        } else if (
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_NTP_NOT_SET ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_OUTSIDE_RANGE ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_TIMECODE_INVALID ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_TIMECODE_NOT_PRESET ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_UNSET
        ) {
          return colorValue('haiui-amber-01');
        }
      }
      if (decoder.stats?.preprocessorState === 'UNLICENSED HDR') {
        return colorValue('haiui-amber-01');
      }
      return colorValue('haiui-green-01');
    case DecoderStatsStateEnum.STARTED:
      if (decoder?.streamId == null || decoder?.streamId === -1) {
        return colorValue('haiui-amber-01');
      }
      return colorValue('haiui-blue-04');
    case DecoderStatsStateEnum.STOPPED:
      return colorValue('haiui-gray-07');
    case DecoderStatsStateEnum.NOT_DECODING:
      return colorValue('haiui-red-01');
  }
  return colorValue('haiui-red-01');
};

export const decoderStateToHintText = (decoder: DecoderViewModel, t: TFunction): string | undefined => {
  if (decoder == null) {
    return undefined;
  }
  let stream;
  const enState = decoder.stats?.state ?? decoder?.state;
  const fixedRange = fixedDelayRange(decoder?.stats);
  const multiSyncRange = multiSyncDelayRange(decoder?.stats);
  switch (enState) {
    case DecoderStatsStateEnum.ACTIVE:
      if (decoder.bufferingMode === BufferingModeEnum.FIXED) {
        if (decoder.bufferingDelay < fixedRange.min) {
          return t('decoder.stateHint.fixedDelayTooLow');
        } else if (decoder.bufferingDelay > fixedRange.max) {
          return t('decoder.stateHint.fixedDelayTooHigh');
        }
      } else if (decoder.bufferingMode === BufferingModeEnum.MULTISYNC) {
        if (decoder.multisyncBufferingDelay < multiSyncRange.min) {
          return t('decoder.stateHint.multiSyncDelayTooLow');
        } else if (decoder.multisyncBufferingDelay > multiSyncRange.max) {
          return t('decoder.stateHint.multiSyncDelayTooHigh');
        } else if (
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_NTP_NOT_SET ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_OUTSIDE_RANGE ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_TIMECODE_INVALID ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_TIMECODE_NOT_PRESET ||
          decoder.stats?.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_UNSET
        ) {
          return multiSyncWarning(decoder.stats, t, false);
        }
      }
      if (decoder.stats?.preprocessorState === 'UNLICENSED HDR') {
        return formatPreprocessorState(decoder.stats?.preprocessorState);
      }
      return t('decoder.stateHint.active');
    case DecoderStatsStateEnum.STARTED:
      if (decoder?.streamId == null || decoder?.streamId === -1) {
        return t('decoder.stateHint.noStream');
      }
      stream = streamModels.find((s) => s.id === decoder?.streamId);
      if (stream && stream.stats?.state) {
        return toTitleCase(translateStreamState(stream.stats.state, t));
      }
      return undefined;
    case DecoderStatsStateEnum.STOPPED:
      return t('decoder.stateHint.stopped');
    case DecoderStatsStateEnum.NOT_DECODING:
      if (decoder.stats?.unsupportedReason && decoder.stats?.unsupportedReason.length) {
        return decoder.stats?.unsupportedReason;
      } else if (decoder.stats?.unlicensedReason && decoder.stats?.unlicensedReason.length) {
        return decoder.stats?.unlicensedReason;
      } else if (decoder?.stats?.state === DecoderStatsStateEnum.NOT_DECODING && decoder?.stats?.troubleCode <= -1) {
        return translateDecoderState(decoder?.stats?.state, decoder?.stats?.troubleCode, t);
      }
      return undefined;
  }
  return undefined;
};

export const decoderModelToStatus = (model: DecoderViewModel): string => {
  return decoderColorToActionBarStatus(model.color);
};

export const decoderIsStopped = (state: DecoderInfoStateEnum): boolean => state === DecoderInfoStateEnum.STOPPED;

export const decoderIsStarted = (state: DecoderInfoStateEnum): boolean => state !== DecoderInfoStateEnum.STOPPED;
// ActionBar helpers ENDs

export const mapDecodersResponse = (response: GetAllDecodersResponse, t: TFunction): DecoderViewModel[] => {
  const mapped = response?.data.map((item) => mapDecoderViewModel(item, t));
  return mapped;
};

export const mapDecoderViewModel = (response: DecoderResponse, t: TFunction): DecoderViewModel => {
  const viewModel: Partial<DecoderViewModel> = {
    ...response.info,
    stats: { ...response.stats },
    name: response.info.name ?? t('decoder.name', { id: response.info.id }),
    stateString: decoderStateToString(response.stats.state, t),

    stateSortable: decoderStateToSortable(response.stats.state),
    //color: decoderStateToColor({...response.info, stats: response.stats}),
    dashboardColor: mapDashboardColor(response.info.id, response.info.state),
    outputsOrig: response.info.outputs,
    //  status: decoderColorToActionBarStatus(response.stats.state), // required for ActionBar
    selected: false,
    hidden: false,
    updated: new Date().toISOString(),
  };
  viewModel.color = decoderStateToColor(viewModel);
  viewModel.status = decoderColorToActionBarStatus(viewModel.color);
  return viewModel as DecoderViewModel;
};

export const multiSyncDelayRange = (stats: DecoderStats): MkRange => {
  return {
    min: stats?.multisyncDelayRangeMinMs ?? 0,
    max: stats?.multisyncDelayRangeMaxMs > 0 ? stats.multisyncDelayRangeMaxMs : 10000,
  };
};

export const fixedDelayRange = (stats: DecoderStats): MkRange => {
  return {
    min: stats?.delayRangeMinMs ?? 0,
    max: stats?.delayRangeMaxMs > 0 ? stats.delayRangeMaxMs : 3000,
  };
};

export const multiSyncWarning = (stats: DecoderStats, t: TFunction, short = false): string => {
  return stats.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_NTP_NOT_SET
    ? short
      ? t('decoder.fields.multisyncNtpTurnedOffShort')
      : t('decoder.fields.multisyncNtpTurnedOff')
    : stats.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_TIMECODE_INVALID
    ? short
      ? t('decoder.fields.multisyncTimecodeInvalidShort')
      : t('decoder.fields.multisyncTimecodeInvalid')
    : stats.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_TIMECODE_NOT_PRESET
    ? short
      ? t('decoder.fields.multisyncTimecodeNotPresentShort')
      : t('decoder.fields.multisyncTimecodeNotPresent')
    : stats.multisyncStatusCode === MultiSyncStateEnum.WCI_MULTISYNC_STATE_OUTSIDE_RANGE
    ? short
      ? t('decoder.fields.multisyncOutOfRangeShort')
      : t('decoder.fields.multisyncOutOfRange')
    : '';
};
/*
 * yup validation schema for the configuration form
 */
export const decoderValidationSchema = (
  multiSyncRange: MkRange,
  fixedDelayRange: MkRange,
  t: TFunction,
): yup.ObjectSchema =>
  yup.object<DecoderViewModel>().shape({
    bufferingDelay: yup.mixed().when('bufferingMode', {
      is: (value) => value == BufferingModeEnum.FIXED,
      then: yup
        .number()
        .typeError(
          t('validation.decoder.bufferingDelay', {
            min: fixedDelayRange.min,
            max: fixedDelayRange.max,
          }),
        )
        .min(
          0,
          t('validation.decoder.bufferingDelay', {
            min: 0,
            max: 3000,
          }),
        )
        .max(
          3000,
          t('validation.decoder.bufferingDelay', {
            min: 0,
            max: 3000,
          }),
        ),
    }),
    multisyncBufferingDelay: yup.mixed().when('bufferingMode', {
      is: (value) => value == BufferingModeEnum.MULTISYNC,
      then: yup
        .number()
        .typeError(
          t('validation.decoder.bufferingDelay', {
            min: multiSyncRange.min,
            max: multiSyncRange.max,
          }),
        )
        .min(
          0,
          t('validation.decoder.bufferingDelay', {
            min: 0,
            max: 10000,
          }),
        )
        .max(
          10000,
          t('validation.decoder.bufferingDelay', {
            min: 0,
            max: 10000,
          }),
        ),
    }),
    stillImageDelay: yup
      .number()
      .required(t('validation.required'))
      .typeError(t('validation.decoder.stillImageDelay'))
      .min(1, t('validation.decoder.stillImageDelay'))
      .max(1000, t('validation.decoder.stillImageDelay')),
  });

export const bufferingValidationSchema = (
  multiSyncRange: MkRange,
  fixedDelayRange: MkRange,
  t: TFunction,
): yup.ObjectSchema =>
  yup.object<DecoderViewModel>().shape({
    bufferingDelay: yup.mixed().when('bufferingMode', {
      is: (value) => value == BufferingModeEnum.FIXED,
      then: yup
        .number()
        .typeError(
          t('validation.decoder.bufferingDelay', {
            min: fixedDelayRange.min,
            max: fixedDelayRange.max,
          }),
        )
        .min(
          0,
          t('validation.decoder.bufferingDelay', {
            min: 0,
            max: 3000,
          }),
        )
        .max(
          3000,
          t('validation.decoder.bufferingDelay', {
            min: 0,
            max: 3000,
          }),
        ),
    }),
    multisyncBufferingDelay: yup.mixed().when('bufferingMode', {
      is: (value) => value == BufferingModeEnum.MULTISYNC,
      then: yup
        .number()
        .typeError(
          t('validation.decoder.bufferingDelay', {
            min: multiSyncRange.min,
            max: multiSyncRange.max,
          }),
        )
        .min(
          0,
          t('validation.decoder.bufferingDelay', {
            min: 0,
            max: 10000,
          }),
        )
        .max(
          10000,
          t('validation.decoder.bufferingDelay', {
            min: 0,
            max: 10000,
          }),
        ),
    }),
  });
