import { Dialog } from '@hai/ui-react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  bodyText?: string | ReactNode;
  cancelLabel?: string;
  middleLabel?: string;
  lastLabel?: string;
  middleVariant?: string;
  lastVariant?: string;
  onSave?: VoidFunction;
  onReboot?: VoidFunction;
  onCancel?: VoidFunction;
}

export const RebootNeededNotSavedModal: React.FunctionComponent<Props> = ({
  bodyText,
  cancelLabel,
  middleLabel,
  lastLabel,
  middleVariant,
  lastVariant,
  onSave,
  onReboot,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      headline={t('reboot.notSavedModal.title')}
      bodyText={
        bodyText ?? (
          <>
            {t('reboot.notSavedModal.body1')}
            <br />
            {t('reboot.notSavedModal.body2')}
          </>
        )
      }
      onClose={onCancel}
      show={true}
      className="mk-modal-medium-large"
      buttons={[
        { label: cancelLabel ?? t('general.cancel'), onClick: onCancel },
        { label: middleLabel ?? t('general.reboot'), variant: middleVariant ?? 'danger', onClick: onReboot },
        { label: lastLabel ?? t('general.saveReboot'), variant: lastVariant ?? 'primary', onClick: onSave },
      ]}
    />
  );
};
