import { TFunction } from 'i18next';
import { Moment } from 'moment';
import { from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { apiClient } from 'src/js/api';
import { rxTaskFactory } from 'src/js/helper/mobx-tasks';
import { mapUpdateDateTime } from 'src/js/mapper/update-date-time-mapper';
import { createUpdateDateTimeRequest } from 'src/js/model/api/request/factory/update-date-time-request-factory';
import { DateTimeResponse } from 'src/js/model/api/response/date-time-response';
import { NtpStatisticsResponse } from 'src/js/model/api/response/ntp-statistics-response';
import { ValidateNtpServerResponse } from 'src/js/model/api/response/validate-ntp-server-response';
import { NtpServerStatus } from 'src/js/model/ntp-server-status';
import { UpdateDateTime } from 'src/js/model/update-date-time';
import { DateTime, mapDateTime } from 'src/js/pages/settings/datetime/date-and-time-view-model';
import { PresetSavedResponse } from 'src/js/presets/preset-models';
import { handleValidateNtpServerResponse } from 'src/js/task/handler/settings-date-time-handlers';
import { NetworkTaskGenerator, SameTypeNetworkTaskGenerator } from 'src/js/util/global-type';

import { isCurrentPresetSaved } from '../presets/preset-store';

export const createGetNtpStatisticsTask: SameTypeNetworkTaskGenerator<NtpStatisticsResponse> = () =>
  rxTaskFactory(() => apiClient.settingsDateTimeController.getNtpStatistics());

export const createGetDateTimeTask: NetworkTaskGenerator<DateTimeResponse, DateTime> = () =>
  rxTaskFactory(() =>
    apiClient.settingsDateTimeController.getDateTime().pipe(map<DateTimeResponse, DateTime>(mapDateTime)),
  );

export const createUpdateDateTimeTask: NetworkTaskGenerator<
  DateTimeResponse & PresetSavedResponse,
  UpdateDateTime
> = () =>
  rxTaskFactory((timezone: string, ntp: boolean, server?: string, time?: Moment) =>
    apiClient.settingsDateTimeController.updateDateTime(createUpdateDateTimeRequest(timezone, ntp, server, time)).pipe(
      mergeMap((response: DateTimeResponse) =>
        from(isCurrentPresetSaved()).pipe(
          map<any, UpdateDateTime>((presetSavedResponse) =>
            mapUpdateDateTime({
              ...(presetSavedResponse.data as PresetSavedResponse),
              ...response,
            }),
          ),
        ),
      ),
    ),
  );

export const createValidateNtpServerTask: NetworkTaskGenerator<ValidateNtpServerResponse, NtpServerStatus> = (
  t: TFunction,
) =>
  rxTaskFactory((server: string) =>
    apiClient.settingsDateTimeController
      .validateNtpServer(server)
      .pipe(
        map<ValidateNtpServerResponse, NtpServerStatus>(
          (response: ValidateNtpServerResponse): NtpServerStatus => handleValidateNtpServerResponse(response, t),
        ),
      ),
  );
