import { Button, Dialog } from '@hai/ui-react';
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StateButton } from 'src/js/component/base/state-button';
import { RxTaskOrGroup } from 'src/js/helper/mobx-tasks';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useStores } from 'src/js/hook/use-stores';
import {
  Ipv4Addressing,
  NetworkViewModel,
  networkValidationSchema,
} from 'src/js/pages/settings/network/network-view-model';
import { trunk } from 'src/js/service/persistence';
import { submitAndValidate } from 'src/js/validator/validator';

import { NetworkEthernetForm } from '../interface/network-ethernet-form';
import { NetworkIpv4Form } from '../interface/network-ipv4-form';
import { NetworkIpv6Form } from '../interface/network-ipv6-form';
import { NetworkDnsForm } from './network-dns-form';
import { NetworkGeneralForm } from './network-general-form';

export interface NetworkFormValues extends NetworkViewModel {}

interface GeneralFormProps {
  viewModel?: NetworkViewModel;
  onReboot?: VoidFunction;
  onSubmit?: (viewModel: NetworkViewModel) => void;
  onSubmitTask?: RxTaskOrGroup<any>;
}
export const NetworkForm = (props: GeneralFormProps) => {
  const { t } = useTranslation();
  const [mdnsModalShown, showMdnsModal, hideMdnsModal] = useModalControls();
  const { preferenceStore } = useStores();
  const { onReboot, onSubmitTask, ...rest } = props;
  const [hasSeenMdnsModal, setSeenDnsModal] = useState(false);
  const [ethX, setEthX] = useState('eth0');
  const formRef = useRef(null);

  const handleClick = () => {
    if (
      preferenceStore.shouldDislayNetworkModal &&
      formRef.current.initialValues.eth0.ipv4Addressing === Ipv4Addressing.STATIC &&
      formRef.current.values.eth0.ipv4Addressing === Ipv4Addressing.DHCP &&
      !formRef.current.values.mDnsEnabled &&
      formRef.current.values.eth0.ipv4 &&
      !hasSeenMdnsModal
    ) {
      showMdnsModal();
      setSeenDnsModal(true);
    } else {
      submitAndValidate(formRef.current, t);
    }
  };

  const handleDontShowAgainModal = () => {
    preferenceStore.setHasSeenNetworkModal();
    trunk.persist();
    hideMdnsModal();
  };

  const onChangeTab = (tab: any) => {
    setEthX(tab.value);
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={props.viewModel}
      onSubmit={props.onSubmit}
      enableReinitialize={true}
      validationSchema={() => networkValidationSchema(t)}
      {...rest}
    >
      {(formikProps) => {
        return (
          <>
            <div className="buttonRow">
              <Button variant="secondary" onClick={onReboot}>
                {t('general.reboot')}
              </Button>
              <StateButton variant="primary" onClick={handleClick} disabled={!formikProps.dirty} task={onSubmitTask}>
                {t('general.apply')}
              </StateButton>
            </div>
            <NetworkGeneralForm />

            {ethX === 'eth0' && (
              <div className="ethXform eth0">
                <NetworkIpv4Form
                  ethX="eth0"
                  hasEth1={formikProps.values.hasEth1}
                  onOptionClicked={onChangeTab}
                  {...formikProps}
                />
                <NetworkIpv6Form ethX="eth0" {...formikProps} />
                <NetworkEthernetForm ethX="eth0" {...formikProps} />
              </div>
            )}

            {formikProps.values.hasEth1 && ethX === 'eth1' && (
              <div className="ethXform eth1">
                <NetworkIpv4Form
                  ethX="eth1"
                  hasEth1={formikProps.values.hasEth1}
                  onOptionClicked={onChangeTab}
                  {...formikProps}
                />
                <NetworkIpv6Form ethX="eth1" {...formikProps} />
                <NetworkEthernetForm ethX="eth1" {...formikProps} />
              </div>
            )}

            <NetworkDnsForm {...formikProps} />
            {mdnsModalShown && (
              <Dialog
                headline={t('settings.network.mdnsModal.title')}
                bodyText={t('settings.network.mdnsModal.body')}
                onClose={hideMdnsModal}
                show={true}
                buttons={[
                  { label: t('general.cancel') },
                  {
                    label: t('settings.network.mdnsModal.dontShowAgainButtonTitle'),
                    variant: 'primary',
                    onClick: handleDontShowAgainModal,
                  },
                ]}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};
