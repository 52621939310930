interface PathsInterface {
  bandwidthGraph: string;
  dashboard: string;
  login: string;
  help: string;
  rebooting: string;
  rebootingUpgrade: string;
  security: string;
  settings: string;
  stats: string;
  statsfull: string;
  streaming: string;
  test: string;
}

export const Paths: PathsInterface = {
  bandwidthGraph: '/bandwidth',
  dashboard: '/',
  login: '/login',
  help: '/help',
  rebooting: '/rebooting',
  rebootingUpgrade: '/rebooting-upgrade',
  security: '/security/:page',
  settings: '/settings/:page',
  stats: '/streaming/:page/stats',
  statsfull: '/streaming/:page/stats-full',
  streaming: '/streaming/:page',
  test: '/test/*',
};

export const defaultRoute = '/';
