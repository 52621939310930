import { Button, Dialog } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormSwitch } from 'src/js/component/base/form/form-switch';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { constant } from 'src/js/constant';

import { scrollToId } from '../helper/scroll-helper';
import { useMutable } from '../hook/use-mutable';
import { createListStore, ListSelector } from '../util/listSelector';
import { PresetDeleteModal } from './preset-delete-modal';
import { PresetEditModal } from './preset-edit-modal';
import { presetNames } from './preset-helper';
import { PresetImport } from './preset-import';
import { PresetViewModel, Presets, newPresetViewModel, Preset } from './preset-models';
import { v4 as uuidv4 } from 'uuid';
import {
  createPreset,
  duplicatePreset,
  fetchPresets,
  renamePreset,
  setAutoSavePreset,
  setStartupPreset,
} from './preset-store';
import { PresetTable } from './preset-table';

const SHOW_NEW_PRESET_BUTTON = false; /* New Preset is not shown as of 28.Nov.2023 */

interface Props {
  presets: Presets;
  onCancel: VoidFunction;
  shouldReloadData: VoidFunction;
}

export const PresetManager: React.FunctionComponent<Props> = ({ presets, onCancel, shouldReloadData }) => {
  const { t } = useTranslation();

  const [singleItem, setSingleItem] = useState<Preset>(); // for kebab selection
  let listController: ListSelector<Preset> = null;
  const [uuid] = useMutable(uuidv4());
  listController = createListStore(presets.data, uuid(), 'name');

  const reloadPresetData = (): void => {
    listController.unselectAll();
    shouldReloadData();
  };

  const handleSetAsStartup = (preset: PresetViewModel) => {
    setStartupPreset(preset, t).then((res) => {
      if (res.ok) {
        reloadPresetData();
      }
    });
  };

  const handleSetAutoSave = (checked: boolean) => {
    setAutoSavePreset(checked, t).then((res) => {
      if (res.ok) {
        reloadPresetData();
      }
    });
  };

  const [newShown, showNew, hideNew] = useModalControls();
  const handleNew = (preset: PresetViewModel, overwrite: boolean): void => {
    createPreset(
      {
        ...preset,
        name: `${preset.name}.cfg`,
        startup: preset.startup,
        changeActive: preset.startup,
      },
      overwrite,
      t,
    ).then((res) => {
      if (res.ok) {
        hideNew();
        fetchPresets(t).then(() => {
          setTimeout(() => {
            scrollToId(`${preset.name}.cfg`, 48);
          }, 300);
        });
      }
    });
  };

  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const onSuccessDelete = (): void => {
    hideDelete();
    listController.unselectAll();
    setSingleItem(undefined);
    shouldReloadData();
    //setSelectAll(false); // FIXME, needed?
  };

  const [importShown, showImport, hideImport] = useModalControls();
  const handleImport = (): void => {
    hideImport();
    shouldReloadData();
  };

  const [renameShown, showRename, hideRename] = useModalControls();
  const handleRename = (preset: PresetViewModel, overwrite: boolean, originalName: string): void => {
    renamePreset({ ...preset, name: `${preset.name}.cfg` }, overwrite, originalName, t).then((res) => {
      if (res.ok) {
        hideRename();
        shouldReloadData();
        listController.unselectAll();
      }
    });
  };

  const handleDuplicate = (item: Preset): void => {
    duplicatePreset(item, t).then((res) => {
      if (res.ok) {
        shouldReloadData();
        listController.unselectAll();
      }
    });
  };

  if (isNil(presets)) {
    return null;
  }

  const content = (): ReactNode => {
    return (
      <form className="HaiForm">
        <div className="d-flex">
          <FormSwitch
            name="autosave"
            switchLabel={t('presets.autoSave')}
            checked={presets.autosave}
            onChange={handleSetAutoSave}
            className="hai-mb-2 ml-auto"
          />
        </div>
        <PresetTable
          listController={listController}
          active={presets.active}
          autoSave={presets.autosave}
          onDelete={showDelete}
          onDuplicate={handleDuplicate}
          onRename={showRename}
          onSetAsStartup={presets.autosave ? null : handleSetAsStartup}
          shouldReloadData={shouldReloadData}
          setSingleItem={setSingleItem}
        />
        {!importShown && (
          <div className="d-flex hai-mt-8">
            <Button onClick={showImport}>{importShown ? t('general.cancel') : t('general.import')}</Button>
            {SHOW_NEW_PRESET_BUTTON && <Button onClick={showNew}>{t('presets.modal.form.newTitle')}</Button>}
            <Button
              variant="primary"
              className="ml-auto"
              onClick={() => {
                listController.unselectAll();
                onCancel();
              }}
            >
              {t('general.done')}
            </Button>
          </div>
        )}
        {importShown && (
          <PresetImport
            onCancel={hideImport}
            onImport={handleImport}
            presetNames={presetNames(presets.data.map((p) => p.name))}
          />
        )}
        {newShown && (
          <PresetEditModal
            autosave={presets.autosave}
            showSetAsStartupCheckbox={true}
            viewModel={newPresetViewModel()}
            presetNames={presetNames(presets.data.map((p) => p.name))}
            onSubmit={handleNew}
            onCancel={hideNew}
            title={t('presets.modal.form.newTitle')}
            btnText={t('general.create')}
          />
        )}
        {renameShown && (
          <PresetEditModal
            autosave={presets.autosave}
            showSetAsStartupCheckbox={false}
            viewModel={singleItem}
            presetNames={presetNames(presets.data.map((p) => p.name))}
            onSubmit={handleRename}
            onCancel={() => {
              hideRename();
              listController.unselectAll();
            }}
            title={t('presets.modal.form.renameTitle')}
            btnText={t('general.rename')}
          />
        )}
        {deleteShown && (
          <PresetDeleteModal
            itemsToDelete={singleItem ? [singleItem] : listController.selectedRows()}
            onCancel={() => {
              hideDelete();
              setSingleItem(undefined);
              listController.unselectAll();
            }}
            onSuccessDelete={onSuccessDelete}
          />
        )}
      </form>
    );
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('presets.presetManager')}
      onClose={() => {
        listController.unselectAll();
        onCancel();
      }}
      show={true}
      dialogType="activity"
      headerIcon="Presets"
      content={content()}
      size="lg"
    />
  );
};
