import { map } from 'rxjs/operators';
import { apiClient } from 'src/js/api';
import { rxTaskFactory } from 'src/js/helper/mobx-tasks';
import { mapUpgradeDetails } from 'src/js/mapper/upgrade-details-mapper';
import { DeleteFirmwareResponse } from 'src/js/model/api/response/delete-firmware-response';
import { MonitorFirmwareUploadResponse } from 'src/js/model/api/response/monitor-firmware-upload-response';
import { UpgradeDetailsResponse } from 'src/js/model/api/response/upgrade-details-response';
import { UploadFirmwareResponse } from 'src/js/model/api/response/upload-firmware-response';
import { UpgradeDetails } from 'src/js/model/api/upgrade-details';
import { SameTypeNetworkTaskGenerator } from 'src/js/util/global-type';

export const createGetUpgradeDetailsTask: SameTypeNetworkTaskGenerator<UpgradeDetails> = () =>
  rxTaskFactory(() =>
    apiClient.settingsUpgradeController
      .getUpgradeDetails()
      .pipe(map<UpgradeDetailsResponse, UpgradeDetails>(mapUpgradeDetails)),
  );

export const createDeleteFirmwareTask: SameTypeNetworkTaskGenerator<DeleteFirmwareResponse> = () =>
  rxTaskFactory((filename: string) => apiClient.settingsUpgradeController.deleteFirmware(filename));

export const createMonitorFirmwareUploadTask: SameTypeNetworkTaskGenerator<MonitorFirmwareUploadResponse> = (
  _responseHandler,
  errorHandler,
) =>
  rxTaskFactory((filename: string) =>
    apiClient.settingsUpgradeController.monitorFirmwareUpload(filename, errorHandler),
  );

export const createUploadFirmwareTask: SameTypeNetworkTaskGenerator<UploadFirmwareResponse> = () =>
  rxTaskFactory((file: File, callback: any) => apiClient.settingsUpgradeController.uploadFirmware(file, callback));
