import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { Task } from 'mobx-task';
import { omit } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RxTask } from 'src/js/helper/mobx-tasks';
import { NtpServerStatus } from 'src/js/model/ntp-server-status';
import { validateFormContext } from 'src/js/validator/validator';

import { DateTime, dateAndTimeSchema } from './date-and-time-view-model';
import { DateTimeButtons } from './datetime-buttons';
import { Server } from './server';

export interface DateAndTimeFormValues extends Omit<DateTime, 'chronydRunning'> {}

interface GeneralFormProps {
  loading: boolean;
  data: DateTime;
  onReboot?: VoidFunction;
  onStatistics?: VoidFunction;
  statisticsDisabled?: boolean;
  onSubmit?: (data: DateAndTimeFormValues) => void;
  submitTask?: Task<any, any>;
  validateNtpServerTask?: RxTask<NtpServerStatus>;
}

const excludedKeys = ['chronydRunning'];

export const DateAndTimeForm = (props: GeneralFormProps) => {
  const { loading, onReboot, onStatistics, statisticsDisabled, submitTask, validateNtpServerTask, ...rest } = props;

  const { t } = useTranslation();

  return (
    <Formik
      onSubmit={submitTask}
      initialValues={{ ...(omit(excludedKeys, props.data) as DateAndTimeFormValues) }}
      validationSchema={() => dateAndTimeSchema(t)}
      enableReinitialize={true}
      {...rest}
    >
      {(formikProps) => {
        return (
          <>
            <DateTimeButtons
              onReboot={onReboot}
              onStatistics={onStatistics}
              statisticsDisabled={statisticsDisabled || !formikProps.values.ntp}
              onSubmit={() => validateFormContext(formikProps, t)}
              submitTask={submitTask}
              applyDisabled={!formikProps.dirty}
            />
            <Server loading={loading} validateNtpServerTask={validateNtpServerTask} {...formikProps} />
            {/* MX4E only && <PTP {...formikProps} />*/}
          </>
        );
      }}
    </Formik>
  );
};

interface GeneralFormProps {
  data: DateTime;
  translator: TFunction;
  onReboot?: VoidFunction;
  onStatistics?: VoidFunction;
  statisticsDisabled?: boolean;
  onSubmit?: (data: DateAndTimeFormValues) => void;
  submitTask?: Task<any, any>;
  validateNtpServerTask?: RxTask<NtpServerStatus>;
}
