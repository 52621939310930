import { Button, DynamicContainer, Form, FormSection, Slider } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'src/js/hook/use-stores';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { constant } from 'src/js/constant';
import { useLocalStorage } from 'usehooks-ts';

export const setBrightnessContrast = (brightness: string, contrast: string) => {
  const html = document.getElementsByTagName('html');
  if (brightness === '100' && contrast === '100') {
    html[0].style.filter = 'initial';
  } else {
    const filter = 'brightness(' + brightness + '%) ' + 'contrast(' + contrast + '%)';
    html[0].style.filter = filter;
  }
};

export const UserSettings: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const [brightness, setBrightness] = useLocalStorage<string>(
    `${constant.lStorage.brightness}:${sessionStore.username}`,
    '100',
  );
  const [contrast, setContrast] = useLocalStorage<string>(
    `${constant.lStorage.contrast}:${sessionStore.username}`,
    '100',
  );
  const handleChangeBrightness = (value: string) => {
    setBrightness(value);
    setBrightnessContrast(value, contrast);
  };

  const handleChangeContrast = (value: string) => {
    setContrast(value);
    setBrightnessContrast(brightness, value);
  };
  const handleReset = () => {
    setBrightness('100');
    setContrast('100');
    setBrightnessContrast('100', '100');
  };

  const handleClearStorage = () => {
    Object.keys(localStorage)
      .filter((x) => x.startsWith(constant.lStoragePrefix))
      .forEach((x) => localStorage.removeItem(x));

    createAndDispatchNotification(
      t('settings.system.section.userSettings.clearStorageSuccess'),
      NotificationVariant.SUCCESS,
      t,
    );
  };

  return (
    <Form className="hai-mb-6">
      <FormSection title={t('settings.system.section.userSettings.title')}>
        <DynamicContainer className="hai-mb-4" minColumns={3}>
          <div className="haiui-label-02-med hai-gray-06">{t('settings.system.section.userSettings.brightness')}</div>
        </DynamicContainer>
        <DynamicContainer className="hai-mb-6" minColumns={3}>
          <Slider min="50" max="150" step="1" defaultValue={brightness} onChange={handleChangeBrightness} />
        </DynamicContainer>
        <DynamicContainer className="hai-mb-4" minColumns={3}>
          <div className="haiui-label-02-med hai-gray-06">{t('settings.system.section.userSettings.contrast')}</div>
        </DynamicContainer>
        <DynamicContainer className="hai-mb-6" minColumns={3}>
          <Slider min="50" max="150" step="1" defaultValue={contrast} onChange={handleChangeContrast} />
        </DynamicContainer>
        <DynamicContainer className="align-items-center hai-mb-9" minColumns={3}>
          <Button onClick={handleReset}>{t('general.reset')}</Button>
        </DynamicContainer>
        <DynamicContainer className="hai-mb-4" minColumns={3}>
          <div className="align-items-center haiui-label-02-med hai-gray-06">
            {t('settings.system.section.userSettings.clearStorage')}
          </div>
        </DynamicContainer>
        <DynamicContainer className="hai-mb-4" minColumns={3}>
          <Button onClick={handleClearStorage}>{t('general.clear')}</Button>
        </DynamicContainer>
      </FormSection>
    </Form>
  );
};
