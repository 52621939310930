import { isNil } from 'ramda';
import {
  DNSPrecedence,
  Duplex,
  Ipv6Addressing,
  Ipv6List,
  Link,
  NetworkInterfaceResponse,
  NetworkResponse,
  Speed,
} from 'src/js/model/api/response/network-response';
import {
  Ipv4Addressing,
  NetworkInterfaceViewModel,
  NetworkViewModel,
} from 'src/js/pages/settings/network/network-view-model';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';

const ipv6ListToStrings = (ipv6List: Ipv6List): string[] =>
  Object.values(ipv6List).filter((value: string) => !isNilOrEmpty(value));

export const defaultDHCPVendorId = constant.makitoX4Haivision;

export const mapNetworkInterfaceToViewModel = (response: NetworkInterfaceResponse): NetworkInterfaceViewModel => {
  if (isNil(response)) {
    return {
      ipv4: false,
      ipv4Addressing: Ipv4Addressing.DHCP,
      ip: '',
      netmask: '',
      gateway: '',
      dhcpVendorId: defaultDHCPVendorId,
      linklocal: false,
      ipv6: false,
      ipv6Addressing: Ipv6Addressing.AUTOMATIC_DHCP,
      ipv6Address: '',
      ipv6PrefixLength: '',
      ipv6Gateway: '',
      ipv6PrivacyExtension: false,
      ipv6LinkLocal: '',
      ipv6List: [],
      link: Link.AUTO,
      speed: Speed.ANY,
      currentSpeed: '' as Speed,
      duplex: Duplex.FULL,
      macAddress: '',
      bandwidth: 0,
    };
  } else {
    return {
      ipv4: response.ipv4,
      ipv4Addressing: response?.dhcp === 1 ? Ipv4Addressing.DHCP : Ipv4Addressing.STATIC,
      ip: response.ip,
      netmask: response.netmask,
      gateway: response.gateway,
      dhcpVendorId: response.dhcpVendorID,
      linklocal: response.linklocal === 1,
      ipv6: response.ipv6,
      ipv6Addressing: response.ipv6_mode,
      ipv6Address: response.ipv6_ip,
      ipv6PrefixLength: response.ipv6_prefix_length.toString(),
      ipv6Gateway: response.ipv6_gateway,
      ipv6PrivacyExtension: response.ipv6_privacy_extensions,
      ipv6LinkLocal: response.ipv6_linklocal,
      ipv6List: ipv6ListToStrings(response.ipv6_ips),
      link: response.link,
      speed: response.speed,
      currentSpeed: response.currentSpeed,
      duplex: response.duplex,
      macAddress: response.mac,
      bandwidth: response.bandwidth,
    };
  }
};

export const mapNetworkToNetworkViewModel = (response: NetworkResponse): NetworkViewModel => {
  if (isNil(response)) {
    return {
      alternateDns: '',
      autoDns: false,
      dnsPrecedence: DNSPrecedence.IPV6,
      domain: '',
      eth0: mapNetworkInterfaceToViewModel(null),
      eth1: mapNetworkInterfaceToViewModel(null),
      hasEth1: false,
      hostname: '',
      isEth1Sfp: false,
      mDnsEnabled: false,
      mDnsName: '',
      primaryDns: '',
    };
  } else {
    return {
      hostname: response.hostname,
      domain: response.domain,
      isEth1Sfp: response.isEth1Sfp,
      eth0: mapNetworkInterfaceToViewModel(response.eth0),
      hasEth1: response.hasEth1,
      eth1: mapNetworkInterfaceToViewModel(response.eth1),

      autoDns: !!response.autoDns,
      primaryDns: response.dns,
      alternateDns: response.dns2,
      dnsPrecedence: response.dnsPrecedence,
      mDnsEnabled: !!response.mDnsEnabled,
      mDnsName: response.mDnsName,
    };
  }
};
