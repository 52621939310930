/**
 * Joins the string with the given separator, but after removing all nulls.
 * @param strings
 * @param separator
 */
import { not } from 'src/js/helper/predicate/global.predicate';
import { isNilOrEmpty } from 'src/js/util/global-util';

export const joinNonNull = (strings: string[], separator = ' '): string => {
  return strings.filter(not(isNilOrEmpty)).join(separator);
};

export const wrapString = (string: string, leftString: string, rightString: string): string => {
  return leftString + string + rightString;
};
