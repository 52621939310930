import React, { ReactNode } from 'react';
import { generateDataAuto } from '../util/automation';

interface Props {
  children: ReactNode;
}
const SidebarLayout: React.FunctionComponent<Props> = ({ children }) => (
  <div className="sidebarLayout">
    <div className="pageContent" data-auto={generateDataAuto('page', 'content')}>
      {children}
    </div>
  </div>
);

export default SidebarLayout;
