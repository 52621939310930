import { Button, DropdownButton, DropdownMenuItem, Icon, Placeholder } from '@hai/ui-react';
import { IButtonProps } from '@hai/ui-react/dist/types';
import { isNil } from 'ramda';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ActionBar, { Status } from 'src/js/component/actionBar/actionBar';
import { Comparator, MakitoObject, SortDirection, SortType } from 'src/js/component/sort-manager';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import { useWatchObject } from 'src/js/store/use-watch';
import { generateDataAuto } from 'src/js/util/automation';
import { constant } from 'src/js/constant';
import { useLocalStorage } from 'usehooks-ts';

interface StreamingActionBarProps<T extends MakitoObject> {
  items: T[];
  searchKeys?: (string | any)[];
  sortInfo: {
    [K in keyof Partial<T>]: {
      title: string;
      type: SortType;
      /*
       *  Optional
       *  Supply custom comparator function receiving model A and B as input and returning -1 | 0 | 1
       */
      comparator?: Comparator<T>;
      /*
       *  Optional
       *  Value provider for the field. Takes model as input and output value to sort on
       */
      provider?: (arg: T) => any;
    };
  };
  statusTypes?: Status[];
  getStateValue: (model: T) => string;
  actionButtons: {
    onClick: (e: any) => void | Promise<any>;
    label: string;
    counter: () => any[];
  }[];
  List: FunctionComponent<any>;
  updateSelection: (item: any, selected: boolean) => void;
  storageKey: string;
  actionRightPrimary?: IButtonProps;
  actionRightSecondary?: IButtonProps;
  scrollToId?: number | string;
  placeholderThumbnail?: boolean;
  placeholderToggles?: number;
  placeholderActions?: number;
  placeholderRightComponents?: number;
  placeholderNumPanels?: number;
}

export const StreamingActionBar = <T extends MakitoObject>(props: StreamingActionBarProps<T>) => {
  const {
    items,
    searchKeys,
    sortInfo,
    statusTypes,
    getStateValue,
    actionButtons,
    List,
    updateSelection,
    storageKey,
    actionRightPrimary,
    actionRightSecondary,
    scrollToId,
    placeholderThumbnail = false,
    placeholderToggles = 3,
    placeholderActions = 3,
    placeholderRightComponents = 0,
    placeholderNumPanels = 6,
  } = props;
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  // as alternative to scrollToId
  const location = useLocation();

  const sortByKey = `${constant.lStoragePrefix}${storageKey}:sortBy:${sessionStore.username}`;
  const sortDirectionKey = `${constant.lStoragePrefix}${storageKey}:sortDirection:${sessionStore.username}`;
  const [sorting, setSorting] = useLocalStorage(sortByKey, 'name');
  const [direction, setDirection] = useLocalStorage(sortDirectionKey, SortDirection.ASCENDING);

  const [savedSelection, setSavedSelection] = useMutable([]);

  useEffect(() => {
    if (sortInfo && !Object.prototype.hasOwnProperty.call(sortInfo, sorting)) {
      //local storage sort key is not found in our sort options, go back to default
      setSorting('name');
    }
  }, []);

  const [actionBarItems, setActionBarItems] = useState<T[]>(items);
  const [actionBarItemsFiltered, setActionBarItemsFiltered] = useState<T[]>(items);

  useWatchObject(items, setActionBarItems);

  const trickleDownUpdates = (updates: T[]) => {
    setActionBarItemsFiltered((list) => {
      updates.map((update) => {
        const item = list.find((i) => i.id == update.id); // TODO we might need some uuid in a private field for deletable objects like streams
        if (item) {
          // console.log('trickle down updates, selected', update.id, item.selected);
          update.selected = item.selected;
          update.hidden = item.hidden;
        }
      });
      return updates;
    });
  };

  useWatchObject(items, trickleDownUpdates);
  useEffect(() => {
    setActionBarItems(items);
    trickleDownUpdates(items);
  }, [items]);

  const actionBarSetItems = (value: T[]) => {
    if (Array.isArray(value)) {
      // Translate incoming actionbar array into props `hidden` true/false of existing actionBarItemsFiltered array
      setActionBarItemsFiltered(() => {
        const newList = [...actionBarItems];
        let amountVisibleChecked = 0;
        let amountVisible = 0;
        newList.map((item) => {
          const actionBarItem = value.find((i) => i.id == item.id); // TODO: This could be refactored
          if (!actionBarItem) {
            if (!item.hidden) {
            }
            item.hidden = true;
            // Here items are falling out of the the filtered state
            // we keep the items selected like before NKE-2774
            item.selected = false;
            // console.log('item.selected = false;', item);
          } else {
            item.hidden = false;
            if (item.toBeRestored === true) {
              const toRestore = savedSelection().find((s) => s.id === item.id);
              if (toRestore) {
                item.selected = actionBarItem.selected = toRestore.selected;
                // console.log('toRestore item.selected = ', item.selected, item);
                item.toBeRestored = undefined;
              }
            } else {
              item.selected = actionBarItem.selected;
              //console.log('item.selected = ', item.selected, item);
            }
            if (item.selected) {
              amountVisibleChecked++;
            }
            amountVisible++;
          }
        });
        if (amountVisibleChecked === amountVisible && amountVisible > 0) {
          newList.map((item) => {
            const actionBarItem = value.find((i) => i.id == item.id);
            if (!actionBarItem && !item.hidden) {
              item.selected = true; // tagAA make sure all hidden one are checked so ActionBar renders the selectAll checkbox as checked
            }
          });
        }
        return newList;
      });
    }
  };

  const onSearch = () => {
    // clear all selection on new or deleted search entry
    setActionBarItemsFiltered((list) => {
      const newList = [...list];
      newList.map((item) => {
        item.selected = false;
      });
      return newList;
    });
  };

  const onSelectAll = (selected: boolean | 'indeterminate' | 'none') => {
    setActionBarItemsFiltered((list) => {
      const newList = [...list];
      newList.map((item) => {
        if (!item.hidden) {
          if (typeof selected === 'boolean') {
            item.selected = !selected;
          } else if (selected === 'indeterminate') {
            item.selected = false;
          }
        }
      });
      setSavedSelection(newList);
      return newList;
    });
  };

  const onChangeSort = (sections: any) => {
    if (sections.sortBy?.eventKey) {
      setSorting(sections.sortBy?.eventKey);
    }

    if (sections.direction?.eventKey === 'ascending') {
      setDirection(SortDirection.ASCENDING);
    } else if (sections.direction?.eventKey === 'descending') {
      setDirection(SortDirection.DESCENDING);
    } else {
      setDirection(SortDirection.IDLE);
    }
  };

  const sortDropdown = (): ReactNode => (
    <DropdownButton
      title={sortInfo[sorting as keyof T]?.title}
      onChange={onChangeSort}
      label={
        direction === SortDirection.ASCENDING ? (
          <>
            {t('sort.sortPrefix')}
            {'\u00A0'}
            <Icon
              iconname="SortAscending"
              color="haiui-gray-06"
              size="sm3"
              data-auto={generateDataAuto('label', 'icon sort ascending')}
            />
          </>
        ) : (
          <>
            {t('sort.sortPrefix')}
            {'\u00A0'}
            <Icon
              iconname="SortDescending"
              color="haiui-gray-06"
              size="sm3"
              data-auto={generateDataAuto('label', 'icon sort descending')}
            />
          </>
        )
      }
      alignLabel="left"
      floatRight
      menuMaxHeight="none"
    >
      <DropdownMenuItem sectionHead title={t('sort.sortBy')} eventKey="sortBy" />
      {Object.keys(sortInfo).map((key) => {
        const item = sortInfo[key as keyof T];
        return <DropdownMenuItem key={key} title={item.title} eventKey={key} selected={sorting === key} />;
      })}
      <DropdownMenuItem sectionHead title={t('sort.sortOrder')} eventKey="direction" />
      <DropdownMenuItem
        title={
          <>
            {t('sort.ascending')}
            {'\u00A0'}
            <Icon iconname="SortAscending" color="haiui-gray-06" size="sm3" />
          </>
        }
        data-auto={generateDataAuto('dropdown-item', 'ascending')}
        eventKey="ascending"
        selected={direction === SortDirection.ASCENDING}
      />
      <DropdownMenuItem
        title={
          <>
            {t('sort.descending')}
            {'\u00A0'}
            <Icon iconname="SortDescending" color="haiui-gray-06" size="sm3" />
          </>
        }
        data-auto={generateDataAuto('dropdown-item', 'descending')}
        eventKey="descending"
        selected={direction === SortDirection.DESCENDING}
      />
    </DropdownButton>
  );

  // handler for selecting/unselected one item at a time
  const handleSelect = useCallback(
    (item: T, selected: boolean) => {
      setActionBarItemsFiltered(actionBarItemsFiltered.map((i) => (i.id === item.id ? { ...i, selected } : i)));
      updateSelection(item, selected); // parent callback
      setSavedSelection(actionBarItemsFiltered);
    },
    [actionBarItemsFiltered],
  );

  const PlaceHolderPage = () => {
    return (
      <div className="hai-mt-1">
        <Placeholder.ListActionBar
          numToggleButtons={placeholderToggles}
          numDropdowns={1}
          numRightComponents={placeholderRightComponents}
        />
        <Placeholder.List
          numActions={placeholderActions}
          showThumbnail={placeholderThumbnail}
          numPanels={placeholderNumPanels}
        />
      </div>
    );
  };

  return (
    <>
      {items?.length === 0 ? (
        <PlaceHolderPage />
      ) : (
        <div id="streaming-actionbar">
          <ActionBar
            items={actionBarItemsFiltered}
            cleanItems={items}
            searchKeys={searchKeys}
            sortDropdown={sortDropdown()}
            onSearch={onSearch}
            onSelectAll={onSelectAll}
            setItems={actionBarSetItems}
            statusTypes={statusTypes}
            getStateValue={getStateValue}
            ActionButtons={
              actionButtons.length > 0 ? (
                <>
                  {actionButtons.map((button) => (
                    <Button onClick={button.onClick} disabled={button.counter().length === 0} key={button.label}>
                      {button.label}
                    </Button>
                  ))}
                </>
              ) : null
            }
            actionRightPrimary={actionRightPrimary}
            actionRightSecondary={actionRightSecondary}
          />
          <List
            list={actionBarItemsFiltered}
            sorting={sorting}
            direction={direction}
            onSelect={handleSelect}
            sortInfo={sortInfo}
            scrollToId={scrollToId ?? location?.state?.id}
          />
        </div>
      )}
    </>
  );
};

export interface ScrollProps {
  id: string | number;
}

export const scrollPanelIntoView = (props: ScrollProps, focus = false) => {
  const { id } = props;
  let elementId: string | number;
  if (!isNil(id)) {
    elementId = id;
  }

  if (!isNil(elementId)) {
    setTimeout(() => {
      const item = document.getElementById(`panel-${elementId}`);

      if (!isNil(item)) {
        item.scrollIntoView({ behavior: 'auto', block: 'nearest' });
        if (focus) {
          const ele: any = item.firstElementChild;
          ele.focus();
        }

        if (!isNil(id)) {
          // Clear state so that next Gear opening doesn't trigger scroll.
          window.history.replaceState({ id: null }, document.title);
        }
      }
    }, 300);
  }
};
