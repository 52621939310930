import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { notificationHandler } from 'src/js/notification/notification-handler';

interface Props {
  onApply: () => void;
  onCancel?: VoidFunction;
}

export const DeleteBannerConfirmModal: React.FunctionComponent<Props> = ({ onApply, onCancel }) => {
  const { t } = useTranslation();

  const handleDelete = (): void => {
    apiClientWithoutHandler.genericController.delete(APIPath.banner).then((res: any) => {
      notificationHandler(res, null, t('security.banner.notifications.deleteErrorMsg'), t);
      if (res.ok) {
        onApply();
      }
    });
  };

  return (
    <Dialog
      headline={t('security.banner.modal.delete.title')}
      bodyText={t('general.cannotUndo')}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel') },
        { label: t('general.delete'), variant: 'danger', onClick: handleDelete },
      ]}
    />
  );
};
