import { Button, Heading3Med, Heading4Med, Icon } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { DropdownMenu, DropdownOption } from 'src/js/component/base/dropdown-menu';
import { constant } from 'src/js/constant';
import { triggerEvent } from 'src/js/events';
import { createIntervalOptions } from 'src/js/helper/chart-helper';
import { useAdaptiveFetch } from 'src/js/hook/use-adaptive-fetch';
import { useStores } from 'src/js/hook/use-stores';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { StreamViewModel } from 'src/js/pages/streaming/stream/stream-models';
import { fetchStreamStatistics, resetStreamStatistics } from 'src/js/pages/streaming/stream/stream-store';
import SidebarLayout from 'src/js/sidebar';
import { openNewWindow } from 'src/js/util/global-util';
import { generateDataAuto } from 'src/js/util/automation';
import { useLocalStorage } from 'usehooks-ts';

import {
  DecoderViewModel,
  decoderStateToColor,
  decoderStateToIcon,
  decoderStateToHintText,
} from './decoder/decoder-model';
import { DecoderStatistics } from './decoder/decoder-statistics';
import { fetchDecoderStatistics, fetchDecoders, resetDecoderStatistics } from './decoder/decoder-store';
import { streamStateToColor, streamStateToIcon } from './stream/stream-view-model-mapper';

interface Props {
  sidebar?: boolean;
}

let connectionLost = false;
export const FullStatsPage: React.FunctionComponent<Props> = ({ sidebar = true }) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const [searchParams] = useSearchParams();
  const decoderParam = Number(searchParams.get('decoder') ?? -1);
  const streamParam = Number(searchParams.get('stream') ?? -1);
  let decoderDriven = true;
  if (decoderParam >= 0 && streamParam === -1) {
    decoderDriven = true;
  } else if (streamParam >= 0) {
    decoderDriven = false;
  }
  const [decoderId, setDecoderId] = useState<number>(decoderParam);
  const [streamId, setStreamId] = useState<number>(streamParam);

  const [decoderData, setDecoderData] = useState<DecoderViewModel>();
  const [streamData, setStreamData] = useState<StreamViewModel>();
  const [isSRT, setIsSRT] = useState(false);

  useEffect(() => {
    fetchDecoders(t);
  }, []);

  const [refreshInterval, setRefreshInterval] = useLocalStorage(
    `${constant.lStorage.refreshIntervalStats}:${sessionStore.username}`,
    constant.defaultRefreshInterval,
  );
  const intervalOptions: DropdownOption[] = createIntervalOptions(
    constant.defaultRefreshIntervals,
    undefined /*prefix*/,
    setRefreshInterval,
    t,
  );
  const intervalIndex = intervalOptions.findIndex((option) => option.value === refreshInterval);

  const refreshRateDecoder = decoderId > -1 ? refreshInterval : undefined;
  useAdaptiveFetch(
    () =>
      fetchDecoderStatistics({ id: decoderId }, t, setDecoderData).then((update) => {
        if (decoderDriven && update.ok && update.data?.info && update.data?.info.streamId !== streamId) {
          setStreamId(update.data.info.streamId);
        }
      }),
    refreshRateDecoder,
    [refreshRateDecoder, decoderId, refreshInterval],
  );

  const refreshRateStream = streamId > -1 ? refreshInterval : undefined;
  useAdaptiveFetch(
    () =>
      fetchStreamStatistics({ id: streamId }, t, setStreamData).then((res) => {
        if (res.ok) {
          if (connectionLost) {
            triggerEvent('connection-restored');
            connectionLost = false;
          }
          if (!isSRT && res.data.data?.stats?.srt) {
            setIsSRT(true);
          } else if (isSRT && !res.data?.data?.stats.srt) {
            setIsSRT(false);
          }
          if (decoderDriven === false) {
            if (res.data?.data?.info?.decoderId >= 0 && res.data?.data?.info?.decoderId !== decoderId) {
              setDecoderId(res.data.data.info.decoderId);
            }
          }
        } else if (res.problem === 'NETWORK_ERROR') {
          triggerEvent('connection-lost');
          connectionLost = true;
        }
      }),
    refreshRateStream,
    [refreshRateStream, streamId, refreshInterval],
  );

  const reset = (_e: React.MouseEvent<HTMLButtonElement>) => {
    resetDecoderStatistics(decoderData, t, setDecoderData);
    !isNil(streamData) &&
      resetStreamStatistics(streamData, t, setStreamData).then((res) => {
        if (res.ok) {
          createAndDispatchNotification(t('stream.notifications.resetBothSuccess'), NotificationVariant.SUCCESS, t);
        }
      });
  };

  const handleClickFullPage = () => {
    const url = window.location.pathname.replace('stats', 'stats-full') + window.location.search;
    openNewWindow(url);
  };

  const onDownloadCSV = () => {
    const url = '/apis/streams/' + streamData.id + '/stats?type=srt';
    openNewWindow(url, '_blank');
  };

  const statusColor = !isNil(decoderData)
    ? decoderStateToColor(decoderData)
    : streamStateToColor(streamData?.stats.state);

  const decoderIconTitle = decoderData && decoderStateToHintText(decoderData, t);

  const statisticsContent = (): ReactNode => {
    return (
      <>
        <header
          className="header d-flex align-items-center mt-3 mb-3"
          data-auto={generateDataAuto('header', 'detailed stats')}
        >
          <div className="d-flex align-items-center">
            <Heading4Med data-auto={generateDataAuto('title_detailed', 'stats')}>
              {t('statistics.header.detailedStatistics')}
            </Heading4Med>
            {sidebar && (
              <Icon
                iconname="GoTo"
                size="sm3"
                className="hai-ml-3 cursor-pointer"
                data-auto={generateDataAuto('icon_open', 'details')}
                color="haiui-blue-04"
                onClick={handleClickFullPage}
              ></Icon>
            )}
          </div>
          <div className="buttons ml-auto d-flex align-items-center">
            <div className="mr-3 mt-1 ml-4">
              <DropdownMenu
                style={{ width: '200px' }}
                label={t('statistics.options.refreshInterval')}
                options={intervalOptions}
                selectedIndex={intervalIndex}
              />
            </div>
            {!sessionStore.isUser() && (
              <Button variant="secondary" onClick={reset}>
                {t('statistics.button.resetStats')}
              </Button>
            )}

            {isSRT && (
              <Button variant="primary" className="hai-ml-4" onClick={onDownloadCSV}>
                {t('statistics.button.downloadCSV')}
              </Button>
            )}
          </div>
        </header>
        {!isNil(decoderData) && (
          <div className="full-page-header">
            <div className="status-indicator" style={{ backgroundColor: statusColor }} />
            <Icon
              iconname={decoderStateToIcon(decoderData)}
              style={{ fill: statusColor }}
              size={'md'}
              title={decoderIconTitle}
            />
            <Heading3Med className="header-title fs-mask" data-auto={generateDataAuto('decoder', 'title')}>
              {decoderData.name}
            </Heading3Med>
          </div>
        )}
        {isNil(decoderData) && !isNil(streamData) && (
          <div className="full-page-header">
            <div className="status-indicator" style={{ backgroundColor: statusColor }} />
            <Icon iconname={streamStateToIcon(streamData.stats?.state)} style={{ fill: statusColor }} size={'md'} />
            <Heading3Med className="header-title fs-mask">{streamData.name}</Heading3Med>
          </div>
        )}
        <DecoderStatistics
          decoder={decoderData}
          stream={streamData}
          fullpage={true}
          refreshInterval={refreshInterval}
          parent="FULLPAGE"
        />
      </>
    );
  };

  return (
    <>
      {sidebar ? (
        <SidebarLayout>
          <div className="mk-fullpage-stats mt-5">
            <Link className="go-back link mt-5" to="/streaming" data-auto={generateDataAuto('go_back', 'streaming')}>
              {t('statistics.goBack')}
            </Link>
            <div className="separator" />
            {statisticsContent()}
          </div>
        </SidebarLayout>
      ) : (
        <div className="hai-p-6">{statisticsContent()} </div>
      )}
    </>
  );
};
