import { TFunction } from 'i18next';
import { isNil } from 'ramda';
import * as yup from 'yup';

export enum AuditTransport {
  UDP = 'UDP',
  TLS = 'TLS',
}

export enum AuditTrustServer {
  ALL = 'All',
  CA_SIGNED = 'CA-signed',
  SELF_SIGNED = 'Self-signed',
}

export interface AuditModel {
  enabled: boolean;
  transport: string;
  server: string;
  trusted: string;
  fingerprint: string;
}

/*
 * Yup validation schema for the comment form
 */
export const auditValidationSchema = (t: TFunction) =>
  yup.object<AuditModel>().shape({
    fingerprint: yup.string().when(['enabled', 'transport', 'trusted'], {
      is: (enabled, transport, trusted) => {
        return enabled && transport !== 'UDP' && trusted === 'Self-signed';
      },
      then: yup
        .string()
        .required(t('validation.required'))
        .test('fingerprint', t('security.audit.validation.fingerprint'), function (value) {
          if (this.parent.transport !== 'TLS' || this.parent.trusted !== 'Self-signed') {
            return true; // ok
          }

          let regExp = new RegExp('^(md5(:([a-fA-F0-9]{2})){16}|sha1(:([a-fA-F0-9]{2})){20})$', 'i');
          let regResult = regExp.exec(value);
          if (regResult === null) {
            // verify based on length
            regExp = new RegExp('^((:?([a-fA-F0-9]{2})){16}|(:?([a-fA-F0-9]{2})){20})$', 'i');
            regResult = regExp.exec(value);
            if (regResult && value.length && value.length == 20 * 3 - 1) {
              // 20 chunks of 00: but the last one without :
              // sha1 without prefix
              return true; // OK
            } else if (regResult && value.length && value.length == 16 * 3 - 1) {
              // md5 without prefix
              return true; // OK
            }

            return false; // Invalid fingerprint
          }

          return true;
        }),
    }),
    server: yup.string().when('enabled', {
      is: (value) => value == true,
      then: yup
        .string()
        .required(t('validation.required'))
        .test('server', t('security.audit.validation.server'), function (value) {
          if (!this.parent.enabled) {
            return true; // ok
          }

          if (isNil(value) || value.length > 511) {
            return false;
          }

          const regExpIp = new RegExp(
            '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:[0-9]{1,5})?$',
          );
          const regResultIp = regExpIp.exec(value);
          const regExpIpv6 = new RegExp(
            /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/,
          );
          const regResultIpv6 = regExpIpv6.exec(value);
          const regExpHostname = new RegExp(
            /^((([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]*[a-zA-Z0-9_])\.)*(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.){1})?([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])(:[0-9]{1,5})?$/,
          ); // RFC 1123 + RFC 2181 for FQDN
          const regResultHostname = regExpHostname.exec(value);
          if (regResultIp === null && regResultIpv6 === null && regResultHostname === null) {
            return false;
          }

          return true;
        }),
    }),
  });
