import { TFunction } from 'i18next';
import { GenericResponse } from 'src/js/model/api/response/generic-response';
import * as yup from 'yup';

export interface Preset {
  mtime?: string; // unix time
  name: string;
  startup: boolean;
}

export interface PresetViewModel extends Preset {}

export interface PresetRequestModel extends PresetViewModel {
  changeActive?: boolean;
}

export interface Presets {
  data: Preset[];
  active: string;
  activeIsStartup: boolean;
  activeWasModified: boolean;
  autosave: boolean;
}

export const newPresetViewModel = (): Preset => ({
  name: '',
  startup: false,
});

export interface PresetResponse extends GenericResponse, Preset {}
export interface PresetRequest extends PresetResponse {}

export interface GetAllPresetsResponse extends Presets {}

export interface GenericResponseWithPreset extends GenericResponse {
  settings?: PresetResponse;
}

export interface PresetSavedResponse {
  isSaved: boolean;
}

export const presetValidationSchema = (presetNames: string[], t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t('validation.required'))
      .test('invalid-char', t('validation.invalidCharacters'), (name) => {
        if (name && /^[^!\\/*:<>|"$`';]+$/i.exec(name)) {
          return true;
        }
        if (name && /^[^!\\/*:<>|"$`';]+\.cfg$/i.exec(name)) {
          return true;
        }
        return false;
      })
      .test('duplicate', t('validation.duplicateName'), (name) => {
        return !presetNames.includes(name);
      }),
  });
