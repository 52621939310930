import { TFunction } from 'i18next';
import { concat, head, uniq } from 'ramda';
import * as yup from 'yup';

export const translateIdentityType = (identityType: string, t: TFunction): string =>
  t(`security.certificates.identityTypeSelectOptions.${identityType}`);

export interface CertificateIdentity {
  name: string;
  default?: boolean;
  type: string;
}

export interface CertificateCa {
  name: string;
  default?: boolean;
  type: string;
}

export interface CertificateIdentityViewModel extends CertificateIdentity {
  file?: File;
  isDefault?: boolean;
}

export interface IdentityGenerateViewModel {
  name: string;
  sign: string;
  subject: string;
}

export interface CertificateIdentityImport extends CertificateIdentity {
  file?: File;
  infmt?: string;
  password: string;
}

export interface CertificateCaImport extends CertificateCa {
  file?: File;
  infmt?: string;
  password: string;
}

export const newIdentityViewModel = (): CertificateIdentityImport => ({
  name: '',
  type: 'id',
  infmt: 'auto',
  password: '',
});

export const newCaImportModel = (): CertificateCaImport => ({
  name: '',
  type: 'ca',
  infmt: 'auto',
  password: '',
});

/*
 * Helper
 */
export const currentIdentityNames = (data: string[]): string[] => {
  const namesWithoutExtension = data.map<string>((value: string) => head(value.split('.pem'))); // FIXME buggy
  return uniq(concat(data, namesWithoutExtension));
};

export const currentCaNames = (data: string[]): string[] => {
  const namesWithoutExtension = data.map<string>((value: string) => head(value.split('.pem'))); // FIXME buggy
  return uniq(concat(data, namesWithoutExtension));
};

/*
 * Validations
 */
export const identityValidationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t('validation.required')),
  });

export const identityUploadValidationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t('validation.required')),
  });
