import { Button, DynamicContainer, Form, FormContext, FormGroup, FormLabel, FormSection } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { FormSwitch } from 'src/js/component/base/form/form-switch';
import { UploadZone } from 'src/js/component/base/upload-zone';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useStores } from 'src/js/hook/use-stores';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { SecurityTabContent } from 'src/js/pages/security/security-tab-content';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { validateFormContext } from 'src/js/validator/validator';
import useSWR from 'swr';

import { DeleteBannerConfirmModal } from './banner-delete-modal';
import { BannerModel, bannerValidationSchema } from './banner-models';

export const Banner: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { sessionStore } = useStores();
  const formRef = useRef();

  const [applyButtonState, setApplyButtonState] = useState<ButtonStateType>(undefined);
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);

  useEffect(() => {
    setApplyButtonDisabled(false);
  }, []);

  const escapeEl = document.createElement('textarea');
  const unescapeHTML = (html: any) => {
    escapeEl.innerHTML = html;
    return escapeEl.textContent;
  };

  const fetcher = (url: string) => {
    return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
      return res?.data;
    });
  };

  const { data, mutate } = useSWR(APIPath.banner, fetcher, { revalidateOnFocus: false });
  const shouldReloadData = () => {
    mutate().finally(() => {
      setApplyButtonDisabled(false);
    });
  };

  let banner = '';
  if (data != null && (data as any).banner) {
    banner = unescapeHTML((data as any).banner);
  }

  const [file, setFile] = useState<File>(undefined);
  const handleStartUpload = (file: File) => {
    setFile(file);
    apiClientWithoutHandler.genericController
      .upload(APIPath.banner, { file: file })
      .then((res: any) => {
        notificationHandler(res, null, t('security.banner.notifications.uploadErrorMsg'), t);
        return res;
      })
      .then((res: any) => {
        if (res.ok) {
          shouldReloadData();
          setFile(undefined);
        }
        return res;
      });
  };

  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const handleDelete = () => {
    hideDelete();
    shouldReloadData();
  };

  const handleSubmit = (values: any) => {
    setApplyButtonState('pending');
    setApplyButtonDisabled(true);
    apiClientWithoutHandler.genericController
      .put(APIPath.banner, values)
      .then((res: any) => {
        notificationHandler(
          res,
          t('security.banner.notifications.changeSuccessMsg'),
          t('security.banner.notifications.changeErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        setApplyButtonState(undefined);
        if (res.ok) {
          shouldReloadData();
        } else {
          setApplyButtonDisabled(false);
        }

        return res;
      });
  };

  return (
    <SecurityTabContent name={t('security.banner.title')}>
      <div id="banner">
        {data != null && (
          <Form
            initialValues={data}
            defaultValidation={true}
            handleSubmit={handleSubmit}
            restValidationProps={{
              enableReinitialize: true,
              validationSchema: bannerValidationSchema(t),
              innerRef: formRef,
            }}
          >
            <FormContext.Consumer>
              {(formContext: FormikProps<BannerModel>) => {
                const values = formContext.values;
                return (
                  <>
                    <div className="buttonRow">
                      {sessionStore.isAdmin() && (
                        <Button
                          variant="primary"
                          onClick={() => validateFormContext(formContext, t)}
                          disabled={!formContext.dirty || applyButtonDisabled}
                          state={applyButtonState}
                        >
                          {t('general.apply')}
                        </Button>
                      )}
                    </div>
                    <FormSection>
                      <DynamicContainer minColumns={3} className="hai-mt-panel-checkbox-align">
                        <FormSwitch
                          className="hai-mb-6"
                          name="enabled"
                          switchLabel={t('security.banner.form.displayBanner')}
                          disabled={isNilOrEmpty(values.banner)}
                        />
                        <div />
                        <div className="d-flex">
                          <Button className="ml-auto" onClick={showDelete} disabled={values?.banner?.length === 0}>
                            {t('general.deleteBtn')}
                          </Button>
                        </div>
                      </DynamicContainer>
                      <FormGroup className="hai-mb-4" controlId={'banner'}>
                        <FormLabel>{t('security.banner.form.bannerText')}</FormLabel>
                        <textarea
                          value={banner}
                          className="banner-textarea scrollable fs-mask"
                          data-auto="hai-textInput_bannerdisplay"
                        ></textarea>
                      </FormGroup>
                      <div className="hai-mb-6">
                        <UploadZone
                          accept={{ 'text/plain': constant.bannerFileTypes }}
                          file={file}
                          onUpload={handleStartUpload}
                          cancelBtnDisabledNoFile={true}
                        />
                      </div>
                    </FormSection>
                  </>
                );
              }}
            </FormContext.Consumer>
          </Form>
        )}
      </div>
      {deleteShown && <DeleteBannerConfirmModal onCancel={hideDelete} onApply={handleDelete} />}
    </SecurityTabContent>
  );
};
