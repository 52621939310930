/* Validates an NTP server */
import { Observable } from 'rxjs';
import { BaseController } from 'src/js/controller/base-controller';
import { UpdateDateTimeRequest } from 'src/js/model/api/request/update-date-time-request';
import { DateTimeResponse } from 'src/js/model/api/response/date-time-response';
import { NtpStatisticsResponse } from 'src/js/model/api/response/ntp-statistics-response';
import { ValidateNtpServerResponse } from 'src/js/model/api/response/validate-ntp-server-response';
import { APIPath } from 'src/js/api/route-path-index';

export class SettingsDateTimeController extends BaseController {
  /* Retrieves NTP statistics */
  public getNtpStatistics(): Observable<NtpStatisticsResponse> {
    return this.get$(APIPath.ntpStatistics);
  }

  /* Retrieves DateTime information */
  public getDateTime(): Observable<DateTimeResponse> {
    return this.get$(APIPath.datetime);
  }

  /* Updates DateTime information */
  public updateDateTime(request: UpdateDateTimeRequest): Observable<DateTimeResponse> {
    return this.put$(APIPath.datetime, {
      ...request,
    });
  }

  /* Validates an NTP server */
  public validateNtpServer(server: string): Observable<ValidateNtpServerResponse> {
    return this.post$(APIPath.validateNtpServer, { server });
  }
}
