import React from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/js/component/page-header';
import systemInfo from 'src/js/data/systemInfo';
import { BandwidthGraph, LegendLocation } from 'src/js/pages/dashboard/bandwidth-graph';

export const BandwidthGraphFullpage: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fullpage mk-bandwidth-graph-page box">
        <PageHeader
          title={t('statistics.chart.network', { deviceName: systemInfo.deviceName })}
          iconName="Meter"
          withPresets={false}
        />
        <div className="spacer p30" />
        <BandwidthGraph
          fullpage={true}
          showTimescale
          legend={LegendLocation.BOTTOM}
          title={t('statistics.bandwidth.title')}
        />
      </div>
    </>
  );
};
