import { TFunction } from 'i18next';
import { IconStatusType } from 'src/js/model/icon-status-type';
import { ipOrHostname, port } from 'src/js/validator/validator';
import * as yup from 'yup';

export enum EmsServiceState {
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  DISABLED = 'DISABLED',
  PAIRING = 'PAIRING',
  PAIRED = 'PAIRED',
  UNPAIRED = 'UNPAIRED',
  UNPAIRING = 'UNPAIRING',
}

export interface EmsServiceResponse {
  state: EmsServiceState;
  emsPort: number;
  waitingToUnpair: number;
  emsHostname: string;
  emsKeepalive: number;
  serviceEnabled: number;
  deviceID: number;
}

export interface EmsService {
  state: EmsServiceState;
  emsPort?: number;
  emsHostname?: string;
  emsKeepalive?: number;
  iconState?: IconStatusType;
}

export const getEmsStateIcon = (state: EmsServiceState): IconStatusType => {
  switch (state) {
    case EmsServiceState.CONNECTED:
      return IconStatusType.OK;
    case EmsServiceState.PAIRING:
    case EmsServiceState.CONNECTING:
    case EmsServiceState.PAIRED:
      return IconStatusType.STARTING;
    case EmsServiceState.DISABLED:
      return IconStatusType.INACTIVE;
    case EmsServiceState.UNPAIRED:
      return IconStatusType.STOPPED;
    case EmsServiceState.UNPAIRING:
      return IconStatusType.STOPPING;
    default:
      return null;
  }
};

export const mapEmsServices = (response: EmsServiceResponse): EmsService => ({
  state: response.waitingToUnpair === 1 ? EmsServiceState.UNPAIRING : response.state,
  emsPort: response.emsPort,
  emsHostname: response.emsHostname,
  emsKeepalive: response.emsKeepalive,
  iconState: getEmsStateIcon(response.waitingToUnpair === 1 ? EmsServiceState.UNPAIRING : response.state),
});

export interface EmsViewModel {
  emsPort: number | string;
  emsHostname: string;
  emsKeepalive: number | string;
}

export const mapEmsViewModel = (hostname: string, port: number, emsKeepalive: number): EmsViewModel => ({
  emsPort: port === 0 ? '' : port,
  emsHostname: hostname,
  emsKeepalive: emsKeepalive === 0 ? '' : emsKeepalive,
});

/*
 * Validation
 */
export const emsValidationSchema = (t: TFunction) =>
  yup.object().shape({
    pairingCode: yup.string().required(t('validation.required')),
    hostname: ipOrHostname(t).required(t('validation.required')),
    port: port(t).required(t('validation.required')),
    emsKeepalive: yup
      .number()
      .typeError(t('validation.service.keepAliveNumber'))
      .min(0, t('validation.service.keepAliveNumber'))
      .max(65535, t('validation.service.keepAliveNumber')),
  });
