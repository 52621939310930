import {
  Button,
  Form,
  FormContext,
  FormControl,
  FormGroup,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { isNil } from 'ramda';
import React, { useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { triggerEvent } from 'src/js/events';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useStores } from 'src/js/hook/use-stores';
import { useTask } from 'src/js/hook/use-task';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { accountValidationSchema } from 'src/js/pages/security/accounts/account-models';
import { trunk } from 'src/js/service/persistence';
import { createLogoutTask } from 'src/js/task/session-task';
import { SessionViewModel } from 'src/js/view-model/session-view-model';

import { clearLocalStorage, handleLogout } from './login';

interface Props {
  session: SessionViewModel;
}

export const PasswordExpired: React.FunctionComponent<Props> = (props: Props) => {
  const { session } = props;
  const { t } = useTranslation();
  const logoutTask = useTask(createLogoutTask());
  const { sessionStore } = useStores();
  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);
  const formRef = useRef();
  const navigate = useNavigate();

  const onCancelClicked = () => {
    logoutTask();
  };

  useSuccessReaction(logoutTask, () => {
    handleLogout(true, navigate);
  });

  const handleSubmit = (values: any) => {
    setButtonState('pending');
    const path = APIPathWithArguments(APIPath.account.update, { name: session.username });
    return apiClientWithoutHandler.genericController
      .put(path, values)
      .then((res: any) => {
        // notifications
        notificationHandler(
          res,
          t('security.accounts.notifications.passwordChangeSuccessMsg'),
          t('security.accounts.notifications.passwordChangeErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        setButtonState(undefined);
        if (res.ok) {
          // refresh data
          clearLocalStorage(session.username);
          sessionStore.login(session.username, session.gid); // this triggers 'authenticated' to change
          triggerEvent('routes-refresh');
          trunk.persist();
        }
        return res;
      });
  };

  if (isNil(session)) {
    return null;
  }

  return (
    <div>
      <Modal className="passwordExpired" show size="sm" centered>
        <ModalHeader>
          <ModalTitle>
            <Icon iconname="Security" className="hai-mr-2 aqua-svg" />
            {t('passwordExpired.title')}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Form
            id="form-password-expired-change"
            initialValues={{
              name: session.username,
              oldPassword: '',
              password: '',
              passwordConfirm: '',
            }}
            defaultValidation={true}
            restValidationProps={{
              enableReinitialize: true,
              validationSchema: accountValidationSchema(t),
              innerRef: formRef,
            }}
            handleSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <FormGroup controlId="name">
                  <FormLabel>{t('security.accounts.form.userName')}</FormLabel>
                  <FormControl name="name" disabled />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup controlId="oldPassword">
                  <FormLabel>{t('security.accounts.form.oldPassword')}</FormLabel>
                  <FormControl name="oldPassword" type="password" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup controlId="password">
                  <FormLabel>{t('security.accounts.form.newPassword')}</FormLabel>
                  <FormControl name="password" type="password" pwdToggle autoComplete="new-password" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup controlId="passwordConfirm">
                  <FormLabel>{t('security.accounts.form.passwordNewConfirm')}</FormLabel>
                  <FormControl name="passwordConfirm" type="password" pwdToggle autoComplete="new-password" />
                </FormGroup>
              </Col>
            </Row>

            <div className="buttonRow hai-mt-5 flex-column">
              <FormContext.Consumer>
                {(formContext: any) => {
                  return (
                    <>
                      <Button
                        variant="primary"
                        onClick={formContext.handleSubmit}
                        disabled={!formContext.dirty}
                        state={buttonState}
                        className="hai-mr-0"
                      >
                        {t('general.apply')}
                      </Button>
                      <Button variant="secondary" onClick={onCancelClicked} className="hai-mt-5">
                        {t('general.cancel')}
                      </Button>
                    </>
                  );
                }}
              </FormContext.Consumer>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};
