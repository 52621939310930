/**
 * Will generate the next duplicate name for an object.
 * We use the pattern `name (${duplicateNumber})` to generate duplicates
 * @param nameToDuplicate {string} the name you want to duplicate
 * @return {string} The new duplicate name
 */
export const generateObjectDuplicateName = (nameToDuplicate: string) => {
  // Find the trailing number or it will match the empty string
  const count = nameToDuplicate.match(/\s\((\d*?)\)$/);
  if (count) {
    const number = parseInt(count[1]) || 1;
    return `${nameToDuplicate.substring(0, count.index)} (${number + 1})`;
  } else {
    return `${nameToDuplicate} (2)`;
  }
};
