import { Button, Dialog } from '@hai/ui-react';
import { Formik } from 'formik';
import { isNil } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { FormikCheckbox } from '../component/base/form/formik-checkbox';

import { presetName } from './preset-helper';
import { PresetViewModel, newPresetViewModel, presetValidationSchema } from './preset-models';

export interface PresetFormValues extends PresetViewModel {}

interface Props {
  autosave: boolean;
  btnText?: string;
  presetNames?: string[];
  showSetAsStartupCheckbox: boolean;
  title: string;
  viewModel: PresetViewModel;
  onSubmit?: (preset: PresetViewModel, overwrite: boolean, originalName?: string) => void;
  onCancel?: VoidFunction;
}

export const PresetEditModal: React.FunctionComponent<Props> = ({
  autosave,
  btnText,
  presetNames = [],
  showSetAsStartupCheckbox,
  title,
  viewModel,
  onSubmit,
  onCancel,
}) => {
  const preset = viewModel ?? newPresetViewModel();
  const { t } = useTranslation();
  if (btnText === undefined) {
    btnText = t('general.saveBtn');
  }

  const [originalPresetName] = useState(presetName(preset.name));

  const nameInputRef = useRef(undefined);
  useEffect((): void => {
    if (!isNil(nameInputRef.current)) {
      nameInputRef.current.select();
    }
  }, [nameInputRef]);

  const [clicked, setClicked] = useState(false);

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') {
      const button = document.getElementById('preset-edit-form-submit');
      if (!isNilOrEmpty(button)) {
        e.preventDefault();
        button.click();
      }
    }
  };

  const content = () => {
    return (
      <Formik
        initialValues={{ name: presetName(preset.name), startup: preset.startup }}
        onSubmit={(model) => onSubmit(model, false, originalPresetName)}
        validationSchema={() => presetValidationSchema(presetNames, t)}
      >
        {(formikProps) => {
          const displayOverwrite = !formikProps.isValid && clicked && presetNames.includes(formikProps.values.name);
          return (
            <form className="HaiForm" onKeyDown={handleOnKeyDown}>
              <FormikTextfield name="name" mask title={t('general.name')} inputRef={nameInputRef} required />
              {showSetAsStartupCheckbox && !autosave && (
                <FormikCheckbox name="startup" label={t('presets.modal.form.setAsStartup')} />
              )}
              <div className="d-flex justify-content-end hai-mt-6">
                <Button onClick={onCancel}>{t('general.cancel')}</Button>
                {displayOverwrite ? (
                  <Button
                    onClick={() => onSubmit(formikProps.values, true, originalPresetName)}
                    variant="primary"
                    id="preset-edit-form-submit"
                  >
                    {t('presets.replace')}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setClicked(true);
                      formikProps.handleSubmit();
                    }}
                    disabled={!formikProps.dirty || formikProps.isSubmitting}
                    variant="primary"
                    id="preset-edit-form-submit"
                  >
                    {btnText}
                  </Button>
                )}
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={title}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="Presets"
      content={content()}
    />
  );
};
