import { TFunction } from 'i18next';
import { SidebarRoute } from 'src/js/model/sidebar-route';

export const translateRoutes = (routes: SidebarRoute[], translator: TFunction): SidebarRoute[] => {
  return routes.map((route) => ({
    key: route.key,
    title: translator(route.title),
    iconName: route.iconName,
    to: route.to,
    defaultPath: route.defaultPath,
    menuItems: route.menuItems?.map((menuItem) => ({
      caption: translator(menuItem.caption),
      key: menuItem.key,
      to: menuItem.to,
    })),
    disabled: route.disabled,
    onClick: route.onClick,
  }));
};
