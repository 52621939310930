import { Config } from 'src/js/config';
import {
  AuthenticationNetworkErrorHandler,
  NetworkErrorHandler,
  NotificationNetworkErrorHandler,
} from 'src/js/controller/network-error-handler';
import { t } from 'src/js/i18n';
import { ApiClient } from 'src/js/api/api-client';

// TODO: This architecture is hard to maintain, we should just use one
export const apiClient = new ApiClient(
  {
    baseURL: Config.baseUrl,
    withCredentials: true,
  },

  new AuthenticationNetworkErrorHandler(true, new NotificationNetworkErrorHandler(t)),
);

export const apiClientWithoutHandler = new ApiClient(
  {
    baseURL: Config.baseUrl,
    withCredentials: true,
  },

  new AuthenticationNetworkErrorHandler(true, new NetworkErrorHandler()),
);
