import { Button, DataTable, Form, FormSection, Placeholder } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/types';
import { observer } from 'mobx-react';
import { head } from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APIPath } from 'src/js/api/route-path-index';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useWatch } from 'src/js/store/use-watch';
import { openNewWindow } from 'src/js/util/global-util';
import { ListSelector } from 'src/js/util/listSelector';

import { LicenseViewModel } from './license-view-model';

interface Props {
  listController: ListSelector<LicenseViewModel>;
  loading?: boolean;
  onDelete?: VoidFunction;
  showImport: VoidFunction;
  setSingleItem: (preset: LicenseViewModel) => void;
}

export const LicenseTable: React.FunctionComponent<Props> = observer(
  ({ listController, loading = false, onDelete, showImport, setSingleItem }) => {
    const { t } = useTranslation();

    const [licenses, setLicenses] = useState(listController.list);
    useWatch(listController, 'list', (list: LicenseViewModel[]) => {
      setLicenses(list);
    });

    const bulkActionComponents = () => {
      return (
        <Button state="idle" size="small" onClick={onDelete}>
          {t('general.deleteBtn')}
        </Button>
      );
    };

    const handleAction = (eventKey: string, data: LicenseViewModel & { id: any } /* not exact but close*/) => {
      switch (eventKey) {
        case 'view':
          openNewWindow(compileWithArgs(APIPath.licenses.download)({ name: data.name }), data.name);
          break;

        case 'delete':
          {
            const item = listController.list.find((i) => i.name === data.id);
            setSingleItem(item);
          }
          onDelete();
          break;

        default:
          break;
      }
    };

    const columnStructure = [
      { fieldKey: 'name', useFsMask: true, title: t('general.name') },
      { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS },
    ];

    const [selectAll, setSelectAll] = useState(false);
    const handleCheckAll = (checked: boolean) => {
      setSelectAll(checked);
      checked ? listController.selectAll() : listController.unselectAll();
    };

    const PlaceHolderPage = () => {
      return <Placeholder.Table columnStructure={columnStructure} numRows={3} showTitlebar />;
    };

    return (
      <Form>
        <FormSection className="hai-p-0">
          {loading ? (
            <PlaceHolderPage />
          ) : (
            <DataTable
              selectable={true}
              sortable={false}
              columnStructure={columnStructure}
              onCheckAll={handleCheckAll}
              checkAll={selectAll}
              className="datatable-form-section-title"
              emptyStateText={t('settings.licensing.addFirst')}
            >
              <DataTable.TitleBar
                title={t('settings.licensing.sectionTitle')}
                rightComponent={
                  <Button size="small" onClick={showImport}>
                    {t('general.install')}
                  </Button>
                }
              />
              <DataTable.Header bulkActions={bulkActionComponents} />
              {licenses.map((value: LicenseViewModel, index: number) => (
                <DataTable.Row
                  key={`${value.name}-${index}`}
                  rowData={{
                    index: index,
                    id: value.name,
                    name: head(value.name.split('.lic')),
                  }}
                  onSelect={() => listController.toggleRow(value.name)}
                  checked={listController.isSelected(value.name)}
                  actionItems={[
                    {
                      actionIcon: 'GoTo',
                      title: t('general.view'),
                      eventKey: 'view',
                      onSelect: handleAction,
                    },
                    {
                      actionIcon: 'TrashCan',
                      title: t('general.delete'),
                      eventKey: 'delete',
                      onSelect: handleAction,
                    },
                  ]}
                ></DataTable.Row>
              ))}
            </DataTable>
          )}
        </FormSection>
      </Form>
    );
  },
);
