import { Button, DynamicContainer, Form, FormSection } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormTextfield } from 'src/js/component/base/form/form-textfield';
import { FormValue } from 'src/js/component/base/form/form-value';
import systemInfo, { updateSystemInfo } from 'src/js/data/systemInfo';
import { useStores } from 'src/js/hook/use-stores';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { FormChangeEvent } from 'src/js/util/global-type';

export const DeviceName: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();
  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);
  const [deviceName, setDeviceName] = useState(systemInfo.deviceName);

  const handleChangeDeviceName = (formEvent: FormChangeEvent) => {
    const { value } = formEvent.target;
    setDeviceName(value);
  };

  const handleApply = () => {
    setButtonState('pending');
    updateSystemInfo({ ...systemInfo, deviceName: deviceName }).then((res) => {
      notificationHandler(
        res,
        t('settings.system.notifications.updateSuccessMsg'),
        t('settings.system.notifications.updateErrorMsg'),
        t,
      );
      setButtonState(undefined);
      if (res.ok) {
        const response = res.data;
        setDeviceName(response.deviceName);
      }
    });
  };

  return (
    <>
      <Form className="hai-mb-6">
        <FormSection>
          <DynamicContainer minColumns={3}>
            {sessionStore.isAdmin() ? (
              <>
                <FormTextfield
                  name="deviceName"
                  mask
                  title={t('settings.system.section.deviceName.deviceName')}
                  onChange={handleChangeDeviceName}
                  value={deviceName}
                  placeholder={systemInfo.hostname}
                />
                <div />
                <div className="ml-auto">
                  <Button
                    variant="primary"
                    onClick={handleApply}
                    disabled={deviceName === systemInfo.deviceName}
                    state={buttonState}
                    style={{ marginTop: '23px' }}
                  >
                    {t('general.apply')}
                  </Button>
                </div>
              </>
            ) : (
              <FormValue label={t('settings.system.section.deviceName.deviceName')} value={deviceName} />
            )}
          </DynamicContainer>
        </FormSection>
      </Form>
    </>
  );
};
