import { TFunction } from 'i18next';
import { GenericResponse } from 'src/js/model/api/response/generic-response';
import { IconStatusType } from 'src/js/model/icon-status-type';
import { SourceType } from 'src/js/pages/streaming/stream/stream-helper';
import { isEvenNumber } from 'src/js/validator/number-validator-helper';
import {
  autoAssignOrNumberOrEmpty,
  ipOrHostname,
  ipOrHostnameOrEmpty,
  multicast,
  tsPid,
} from 'src/js/validator/validator';
import * as yup from 'yup';

export enum StreamEncapsulation {
  UDP = 2,
  RTP = 3,
  SRT = 34,
  RTSP = 64,
}

export enum StreamSourceType {
  VIDEO = 1,
  AUDIO = 2,
  DATA = 4,
}

// TODO: Remove this once we use RTSP and use StreamEncapsulation keys directly
export const supportedStreamEncapsulation = [StreamEncapsulation.UDP, StreamEncapsulation.RTP, StreamEncapsulation.SRT];

export const supportedStreamEncapsulationWithoutSRT = supportedStreamEncapsulation.filter(
  (t) => t !== StreamEncapsulation.SRT,
);

export enum SRTMode {
  CALLER = 0,
  LISTENER = 1,
  RENDEZVOUS = 2,
}

export enum SRTAuthentication {
  NONE = 'none',
  AUTO = 'auto',
  AES_GCM = 'AES-GCM',
}
export enum SRTAuthenticationListener {
  AUTO = 'auto',
  AES_GCM = 'AES-GCM',
}
export enum SRTAuthenticationOthers {
  NONE = 'none',
  AES_GCM = 'AES-GCM',
}

export enum NetworkInterfaces {
  AUTO = 'auto', // streams have 'auto', service nic have 'all'
  ETH0 = 'eth0',
  ETH1 = 'eth1',
}

export enum StreamPublishingMode {
  standard = 'standard',
  custom = 'custom',
}

export enum FecRTP {
  DISABLED = 0,
  MPEG_PRO_FEC = 2,
}

export enum FecLevel {
  A,
  B,
}

export enum StreamState {
  UNKNOWN = 0,
  STOPPED = 1,
  LISTENING = 2,
  ACTIVE = 3,
  RESOLVING = 4,
  CONNECTING = 5,
  SCRAMBLED = 6,
  SECURING = 7,
  CONNECTED = 8,
  INVALID = -1,
  FAILED = -2,
  UNLICENSED = -3,
}

interface SRTStats {
  state: string;
  authentication?: string;
  detectedRedundancyMode: SrtPathRedundancyMode;
  droppedPackets: string;
  droppedBytes: string;
  recoveredPackets: string;
  reconnections: string;
  lostPackets: string;
  rcvBytes?: string;
  rcvPackets?: string;
  localVersion: string;
  localEndpoint?: string;
  peerEndpoint?: string;
  peerVersion?: string;
  rxStreamID: string;
  sentAcks: string;
  sentNaks: string;
  pathMaxBandwidth: string;
  maxBandwidth?: string;
  sourceAddress?: string;
  remoteAddress?: string;
  localAddress?: string;
  peerAddress?: string;
  localPort?: number;
  peerPort?: number;
  remotePort?: number /* for connection string */;
  rtt: number | string;
  buffer: number;
  latency: number;
  encryption: string;
  mss?: number;
  decryptState?: string;
  keyLength?: string;
  rejectedLastTime?: string;
  rejectedReason?: string;
  rejectedStreamId?: string;
  path2: {
    state: string;
    authentication?: string;
    reconnections: string;
    droppedPackets: string;
    droppedBytes: string;
    recoveredPackets: string;
    rcvBytes?: string;
    rcvPackets?: string;
    lostPackets: string;
    peerVersion?: string;
    rxStreamID: string;
    localEndpoint?: string;
    peerEndpoint?: string;
    sentAcks: string;
    sentNaks: string;
    pathMaxBandwidth: string;
    callerAddress?: string;
    sourceAddress: string;
    remoteAddress: string;
    localAddress?: string;
    peerAddress?: string;
    localPort?: number;
    peerPort?: number;
    remotePort?: number;
    rtt: number | string;
    buffer: number;
    latency: number;
    encryption: string;
    decryptState?: string;
    keyLength?: string;
    decryptError?: string;
    mss?: number;
    maxBandwidth?: string;
  };
}

export enum SrtPathRedundancyMode {
  NONE = 'none',
  OPTIONAL = 'optional',
  ACTIVE_ACTIVE = 'active',
  ACTIVE_BACKUP = 'backup',
}

export enum SrtPathRedundancyModeOffered {
  NONE = 'none',
  ACTIVE_ACTIVE = 'active',
  ACTIVE_BACKUP = 'backup',
}

export enum SrtRedundancyPathRole {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface StreamInfo {
  id: number;
  decoderId?: number;
  name?: string;
  encapsulation: StreamEncapsulation;
  port: number;
  address?: string;
  sourceIp?: string;
  stillImage?: string;
  userData?: string;
  latency?: number;
  audioPids?: number[] | string;
  videoPids?: number[] | string;
  dataPids?: number[] | string;
  pids?: number[] | string;
  programNumber?: number;
  srtMode?: SRTMode;
  sourcePort?: number;
  networkInterface?: string;
  passphrase?: string;
  passphraseSet?: boolean;
  authentication?: SRTAuthentication;
  srtToUdp?: boolean;
  srtToUdp_address?: string;
  srtToUdp_port?: number;
  srtToUdp_tos?: string;
  srtToUdp_ttl?: number;
  fecRtp?: FecRTP;
  fecLevel?: FecLevel;
  fecRows?: number;
  fecColumns?: number;
  fecBlockAligned?: boolean;
  sapDiscovered?: boolean;
  srtAccessControlPublishingIdType?: string;
  srtAccessPublishingID?: string;
  srtAccessUserName?: string;
  srtAccessResourceName?: string;
  srtRedundancyMode?: SrtPathRedundancyMode;
  srtRedundancyPath1Role?: string;
  srtRedundancyPath1Address?: string;
  srtRedundancyPath2Address?: string;
  srtRedundancyPath1Port?: number;
  srtRedundancyPath2Port?: number;
  srtRedundancyPath1SourcePort?: number;
  srtRedundancyPath2SourcePort?: number;
  srtRedundancyPath1Name?: string;
  srtRedundancyPath2Name?: string;
  srtRedundancyPath2NetworkInterface?: string;
  srtListenerSecondPort?: number | '';
}

export interface StreamStats {
  state: StreamState;
  uptime: string;
  lastReset?: string; // TODO not available in backend yet
  receivedPackets: string;
  receivedBytes: string;
  receivedErrors: number | string;
  outputBytes: string;
  droppedBytes: string;
  lastPidDropped: number;
  lastReceived: string;
  programNumber: string;
  pcrPid: string;
  pmtPid: string;
  streamSummary: string;
  filteredOutPids: string;
  sourceAddressEnType?: number;
  lostSegmentMPEG2TS?: string;
  sourceAddress: string;
  bitrate: string;
  bitrateInKbps: number;
  connections?: string;
  fecRtp: number;
  fecLevel?: string;
  fecRows?: string;
  fecColumns?: string;
  fecBlockAligned?: string;
  recoveredPackets?: string;
  lostPackets?: string;
  corruptedFrames?: number | string;
  restarts?: string;
  srt?: SRTStats;
  sources?: Array<{
    name: string;
    type: StreamSourceType;
    compression: string;
    bitrate: string;
    bitrateInKbps: number;
    programId: number;
    receivedPackets: string;
    receivedBytes: string;
    PTS: string;
    DTS: string;
    language: string;
  }>;
}

export interface StreamResponse {
  info: StreamInfo;
  stats: StreamStats;
}

export interface GetStreamResponse extends GenericResponse {
  data: StreamResponse;
}

export interface GetAllStreamsResponse extends GenericResponse {
  data: StreamResponse[];
}

export interface StreamViewModel {
  id: number;
  decoderId?: number;
  name: string;
  connection: string;
  connectionForDecoderDropdown: string;
  protocol?: string;
  state?: StreamState;
  status?: IconStatusType;
  statusColor?: string;
  address?: string;
  sourceIp?: string;
  encapsulation: StreamEncapsulation;
  sourceType: SourceType;
  fecRtp: FecRTP;
  srtMode: SRTMode;
  sourcePort?: string;
  port?: string;
  latency?: string;
  encrypted: boolean;
  networkInterface?: string;
  passphrase?: string;
  passphraseSet?: boolean;
  authentication?: SRTAuthentication;
  udpStreamConversion: boolean; // TODO: rename srtToUdp
  srtToUdpAddress?: string;
  srtToUdpPort?: string;
  srtToUdpTtl?: string;
  srtToUdpTos?: string;
  lastUpdated: number; // internally generated in the frontend after each update
  srtStreamId: boolean; // pure UI helper variable
  srtAccessControlPublishingIdType?: string;
  srtAccessPublishingID?: string;
  srtAccessResourceName?: string;
  srtAccessUserName?: string;
  srtRedundancyMode: SrtPathRedundancyMode;
  srtRedundancyPath1Role: string;
  srtRedundancyPath1Address: string;
  srtRedundancyPath2Address: string;
  srtRedundancyPath1Port: string;
  srtRedundancyPath2Port: string;
  srtRedundancyPath1SourcePort: string;
  srtRedundancyPath2SourcePort: string;
  srtRedundancyPath1Name: string;
  srtRedundancyPath2Name: string;
  srtRedundancyPath2NetworkInterface: string;
  srtListenerSecondPort: string;
  stats: Partial<StreamStats>;
  encapsulationTextual: string;
  srtLatency: number;
  stateSortable: number;
  userData?: string;
  uuid?: string | number;

  // pids
  audioPids?: string | number[];
  videoPids?: string | number[];
  dataPids?: string | number[];
  audioPidsPlaceholder?: string;
  videoPidsPlaceholder?: string;
  dataPidsPlaceholder?: string;
  pcrPidPlaceholder?: string;
  pmtPidPlaceholder?: string;
  programNumberPlaceholder?: string;
  pcrPid?: number;
  pmtPid?: number;
  // not in decoder tsStreamId?: number;
  programNumber?: number | '';
  tsStreamIdPlaceholder?: string;

  /* internal to ui use */
  selected: boolean /* checked by checkbox */;
  hidden: boolean /* when filtered out */;
  updated: string /* to trigger setState to detect change and re-render */;
}

export const getStreamUuid = (model: StreamViewModel | StreamInfo) => {
  if (model.userData?.length > 0) {
    return model.userData;
  }
  return model.id; // for old streams or streams created by CLI and other interfaces
};

enum StreamPidType {
  VIDEO = 1,
  AUDIO = 2,
  DATA = 3,
}

export interface StreamPidTupple {
  type: StreamPidType;
  pid: number;
}

export interface StreamRequest {
  id?: number;
  name: string;
  encapsulation: StreamEncapsulation;
  userData?: string;
  address?: string;
  port: number;
  sourceIp?: string;
  latency?: number;
  pids?: StreamPidTupple[];
  audioPids?: number[];
  videoPids?: number[];
  dataPids?: number[];

  srtMode?: SRTMode;
  sourcePort?: number;
  networkInterface?: string;
  passphrase?: string;
  srtToUdp: boolean;
  srtToUdp_address?: string;
  srtToUdp_port?: number;
  srtToUdp_tos?: string;
  srtToUdp_ttl?: number;
  fecRtp?: FecRTP;
  fecLevel?: FecLevel;
  fecRows?: number;
  fecColumns?: number;
  fecBlockAligned?: boolean;
  sapDiscovered?: boolean;
  srtAccessPublishingID?: string;

  srtRedundancyMode?: SrtPathRedundancyMode;
  srtRedundancyPath1Role?: string;
  srtRedundancyPath2Address?: string;
  srtRedundancyPath2Port?: number;
  srtRedundancyPath2SourcePort?: number;
  srtRedundancyPath1Name?: string;
  srtRedundancyPath2Name?: string;
  srtRedundancyPath2NetworkInterface?: string;
}

export const streamIsStopped = (state: StreamState): boolean => state === StreamState.STOPPED;

export const streamIsStarted = (state: StreamState): boolean => state !== StreamState.STOPPED;

// Keep in sync with mx4e-ui
export const audioLanguages: { [key: string]: string } = {
  sqi: 'Albanian',
  ara: 'Arabic',
  hye: 'Armenian',
  arm: 'Armenian',
  bul: 'Bulgarian',
  zho: 'Chinese',
  chi: 'Chinese',
  hrv: 'Croatian',
  ces: 'Czech',
  cze: 'Czech',
  dan: 'Danish',
  nld: 'Dutch',
  dut: 'Dutch',
  eng: 'English',
  est: 'Estonian',
  fin: 'Finnish',
  fre: 'French',
  fra: 'French',
  deu: 'German',
  ger: 'German',
  ell: 'Greek',
  gre: 'Greek',
  heb: 'Hebrew',
  hin: 'Hindi',
  hun: 'Hungarian',
  ind: 'Indonesian',
  gle: 'Irish',
  isl: 'Icelandic',
  ice: 'Icelandic',
  ita: 'Italian',
  jpn: 'Japanese',
  khm: 'Khmer',
  kor: 'Korean',
  lav: 'Latvian',
  lit: 'Lithuanian',
  msa: 'Malay',
  may: 'Malay',
  mlt: 'Maltese',
  mon: 'Mongolian',
  nor: 'Norwegian',
  pan: 'Punjabi',
  fas: 'Persian',
  per: 'Persian',
  pol: 'Polish',
  por: 'Portugese',
  ron: 'Romanian',
  rum: 'Romanian',
  rus: 'Russian',
  slk: 'Slovak',
  slo: 'Slovak',
  slv: 'Slovenian',
  spa: 'Spanish',
  swa: 'Swahili',
  swe: 'Swedish',
  tur: 'Turkish',
  ukr: 'Ukrainian',
  vie: 'Vietnamese',
  tlh: 'Klingon',
};

/*
 * yup validation schema for the configuration form
 */
const portNumber = (t: TFunction) =>
  yup
    .number()
    .required(t('validation.required'))
    .typeError(t('validation.stream.portNumber'))
    .min(1, t('validation.stream.portNumber'))
    .max(65535, t('validation.stream.portNumber'));

/*
 * Schema for the form
 */
const portNumberNotRequired = (t: TFunction) =>
  yup
    .number()
    .typeError(t('validation.stream.portNumber'))
    .min(1, t('validation.stream.portNumber'))
    .max(65535, t('validation.stream.portNumber'));

const regExpYaml = /^[^,]*$/; // no commas

export const streamValidationSchema = (t: TFunction): yup.ObjectSchema =>
  yup.object().shape({
    name: yup.string().max(63, t('validation.maxLength', { length: 63 })),
    connection: yup.string().required(),
    protocol: yup.string(),
    address: yup
      .string()
      .when(['encapsulation', 'srtMode'], {
        is: (encapsulation, srtMode) => {
          if (
            encapsulation === StreamEncapsulation.SRT &&
            (srtMode === SRTMode.RENDEZVOUS || srtMode === SRTMode.CALLER)
          ) {
            return true;
          }
          return false;
        },
        then: ipOrHostname(t),
      })
      .when(['encapsulation', 'sourceType'], {
        is: (encapsulation, sourceType) => {
          if (
            (encapsulation === StreamEncapsulation.UDP || encapsulation === StreamEncapsulation.RTP) &&
            sourceType === SourceType.multicast
          ) {
            return true;
          }
          return false;
        },
        then: multicast(t),
      }),
    sourceIp: ipOrHostnameOrEmpty(t),
    encapsulation: yup.mixed().oneOf([2, 3, 34, 64], t('validation.stream.encapsulation')),
    fecRtp: yup.mixed().oneOf([FecRTP.DISABLED, FecRTP.MPEG_PRO_FEC], t('validation.stream.fecMode')),

    // SRT
    srtMode: yup.mixed().oneOf([0, 1, 2], t('validation.stream.srtMode')),
    sourcePort: autoAssignOrNumberOrEmpty(t),
    port: portNumber(t).when('encapsulation', {
      is: StreamEncapsulation.RTP,
      then: portNumber(t).test('is-even', t('validation.stream.portNumberRTPError'), isEvenNumber),
    }),
    srtListenerSecondPort: yup.mixed().when(['encapsulation', 'srtMode'], {
      is: (encapsulation, srtMode) => {
        if (encapsulation === StreamEncapsulation.SRT && srtMode === SRTMode.LISTENER) {
          return true;
        }
        return false;
      },
      then: portNumberNotRequired(t),
    }),
    srtRedundancyPath2Address: yup.mixed().when(['encapsulation', 'srtMode', 'srtRedundancyMode'], {
      is: (encapsulation, srtMode, srtRedundancyMode) => {
        if (
          encapsulation === StreamEncapsulation.SRT &&
          srtMode === SRTMode.CALLER &&
          (srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_ACTIVE ||
            srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_BACKUP)
        ) {
          return true;
        }
        return false;
      },
      then: ipOrHostname(t),
    }),
    srtRedundancyPath2Port: yup.mixed().when(['encapsulation', 'srtMode', 'srtRedundancyMode'], {
      is: (encapsulation, srtMode, srtRedundancyMode) => {
        if (
          encapsulation === StreamEncapsulation.SRT &&
          srtMode === SRTMode.CALLER &&
          (srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_ACTIVE ||
            srtRedundancyMode === SrtPathRedundancyMode.ACTIVE_BACKUP)
        ) {
          return true;
        }
        return false;
      },
      then: portNumber(t),
    }),
    srtAddress: yup.string(),
    latency: yup.number().when('encapsulation', {
      is: StreamEncapsulation.SRT,
      then: yup
        .number()
        .required(t('validation.required'))
        .typeError(t('validation.stream.latencyNumber'))
        .min(20, t('validation.stream.latencyNumber'))
        .max(8000, t('validation.stream.latencyNumber')),
    }),
    encrypted: yup.boolean(),
    passphrase: yup.string().when(['encapsulation', 'encrypted', 'passphraseSet'], {
      is: (encapsulation, encrypted, passphraseSet) =>
        encapsulation == StreamEncapsulation.SRT && encrypted && !passphraseSet,
      then: yup
        .string()
        .required(t('validation.required'))
        .min(10, t('validation.stream.maxPassphraseLength'))
        .max(79, t('validation.stream.maxPassphraseLength')),
    }),
    srtToUdpPort: yup.number().when('udpStreamConversion', {
      is: true,
      then: yup
        .number()
        .typeError(t('validation.stream.portNumber'))
        .min(1, t('validation.stream.portNumber'))
        .max(65535, t('validation.stream.portNumber')),
    }),
    udpStreamConversion: yup.boolean(),
    srtToUdpAddress: yup.string().when('udpStreamConversion', {
      is: true,
      then: ipOrHostname(t).required(),
    }),
    srtAccessUserName: yup.string().when(['srtStreamId', 'srtAccessControlPublishingIdType'], {
      is: (srtStreamId, srtAccessControlPublishingIdType) => {
        if (srtAccessControlPublishingIdType === 'custom') {
          return false;
        }
        if (srtStreamId) {
          return true;
        }
        return false;
      },
      then: yup.string().matches(regExpYaml, t('validation.stream.noCommas')),
    }),
    srtAccessResourceName: yup.string().when(['srtStreamId', 'srtAccessControlPublishingIdType'], {
      is: (srtStreamId, srtAccessControlPublishingIdType) => {
        if (srtAccessControlPublishingIdType === 'custom') {
          return false;
        }
        if (srtStreamId) {
          return true;
        }
        return false;
      },
      then: yup.string().matches(regExpYaml, t('validation.stream.noCommas')),
    }),
    srtToUdpTtl: yup.number().when('udpStreamConversion', {
      is: true,
      then: yup
        .number()
        .typeError(t('validation.stream.ttl'))
        .min(1, t('validation.stream.ttl'))
        .max(255, t('validation.stream.ttl')),
    }),
    srtToUdpTos: yup.number().when('udpStreamConversion', {
      is: true,
      then: yup
        .number()
        .typeError(t('validation.stream.tos'))
        .min(0, t('validation.stream.tos'))
        .max(255, t('validation.stream.tos')),
    }),
    audioPids: tsPid(t),
    videoPids: tsPid(t),
    dataPids: tsPid(t),
    programNumber: yup.number().when('encapsulation', {
      is: (value) =>
        value === StreamEncapsulation.RTP || value === StreamEncapsulation.SRT || value === StreamEncapsulation.UDP,
      then: yup
        .number()
        .typeError(t('validation.stream.programNumber'))
        .min(1, t('validation.stream.programNumber'))
        .max(65535, t('validation.stream.programNumber')),
    }),
  });

export const latencyValidationSchema = (t: TFunction): yup.ObjectSchema =>
  yup.object().shape({
    latency: yup
      .number()
      .required(t('validation.required'))
      .typeError(t('validation.stream.latencyNumber'))
      .min(20, t('validation.stream.latencyNumber'))
      .max(8000, t('validation.stream.latencyNumber')),
  });
