export const validation = {
  validation: {
    duplicateName: 'Name already exists',
    maxLength: 'Maximum {{length}} characters',
    maxValue: 'Maximum {{length}}',
    multicast: 'Invalid multicast address',
    multicastOr0: 'Invalid multicast address or 0.0.0.0',
    multicastIpv4: 'Invalid IPV4 unicast or multicast address',
    port: 'Valid values (1 - 65535)',
    fileError: '{{filename}} is not a valid file',
    invalidCharacters: 'Contains invalid characters',
    invalidSubmit: 'Please correct the field marked in red below',
    invalidSubmit_plural: 'Please correct the fields marked in red below',
    nonAlphabetic: 'Contains non-alphabetic characters',
    number: 'Invalid number',
    outOfRange: 'Out of range',
    required: 'Required field',
    timezone: 'Invalid timezone',

    accounts: {
      passwordMustMatch: 'Passwords must match',
    },
    decoder: {
      bufferingDelay: 'Valid values ({{min}} - {{max}})',
      stillImageDelay: 'Valid values (1 - 1000)',
    },
    network: {
      bandwidth: 'Invalid bandwidth',
      dns: 'Invalid DNS server address',
      domain: 'Invalid domain',
      ipOrHostname: 'Invalid IP address or hostname',
      ipv4: 'Invalid IPv4 address',
      ipv4DHCPMode: 'Invalid IPv4 DHCP mode',
      ipv6: 'Invalid IPv6 address',
      ipv6DHCPMode: 'Invalid IPv6 DHCP mode',
      ipv6PrefixLength: '1 - 128',
      netmask: 'Invalid netmask address',
      mDnsNameUnderscore: 'Contains _ character',
      mDnsNameSpace: 'Must not start or end with space',
    },
    policies: {
      autoLogoutTime: 'Valid values (1 - 1440)',
      digits: 'Valid values (0 - 40)',
      expirationDays: 'Valid values (1 - 180)',
      inactiveLockTime: 'Valid values (1 - 365)',
      limitPwdRetriesInterval: 'Valid values (5 - 60)',
      limitPwdRetriesMaxAttempts: 'Valid values (3 - 10)',
      minimumLifetime: 'Valid values (0 - 7)',
      minLen: 'Valid values (6 - 40)',
      rememberedPasswords: 'Valid values (5 - 500)',
      symbols: 'Valid values (0 - 40)',
      uppercase: 'Valid values (0 - 40)',
    },
    preview: { interval: 'Valid values (1 - 60)' },
    service: {
      keepAliveNumber: 'Valid values (0 - 65535)',
    },
    stream: {
      noCommas: 'No commas',
      autoAssign: 'Must be empty, auto-assign or a number',
      encapsulation: '${path} is not a valid encapsulation type',
      fecMode: '${path} is not a valid FEC mode',
      srtMode: '${path} is not a valid SRT mode',
      passphraseRequired: 'Passphrase required',
      portNumber: 'Valid values (1 - 65535)',
      programNumber: 'Valid values (1 - 65535)',
      portNumberRTP: 'Valid values (2 - 65534)',
      portNumberRTPError: 'RTP requires even port number',
      latencyNumber: 'Valid values (20 - 8000)',
      maxPassphraseLength: 'Valid values (10 - 79 characters)',
      tsPid: 'Valid values (16 - 8190)',
      ttl: 'Valid values (1 - 255)',
      tos: 'Valid values (0x00 - 0xFF)',
    },
  },
};
