import moment, { Moment } from 'moment';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormValue } from 'src/js/component/base/form/form-value';
import { useMutable } from 'src/js/hook/use-mutable';
import { constant } from 'src/js/constant';

interface Props {
  time: Moment;
  refreshTime?: number;
}

export const CurrentTime: React.FunctionComponent<Props> = ({ time, refreshTime = 1000 }) => {
  const { t } = useTranslation();
  const [currentTime, setCurrentTime] = useState<Moment>(undefined);
  const [currentTimeTimeoutId, setCurrentTimeTimeoutId] = useMutable(undefined);
  const clearCurrentTimeTimeout = (): void => {
    if (!isNil(currentTimeTimeoutId())) {
      clearInterval(currentTimeTimeoutId());
      setCurrentTimeTimeoutId(undefined);
    }
  };
  const serverTimeText = currentTime?.format(constant.momentFormat.dateTime);

  useEffect((): void => {
    if (isNil(currentTime) && !isNil(time)) {
      // set initial time only ONCE
      setCurrentTime(moment(time));
    }
  }, [time]);

  // the following block keeps the current time incrementing like a wall clock while the user watches the form.
  useEffect((): void => {
    if (!isNil(currentTime)) {
      setCurrentTimeTimeoutId(
        setTimeout(() => {
          setCurrentTime(currentTime.clone().add(refreshTime, 'ms'));
        }, refreshTime),
      );
    }
  }, [currentTime]);

  useEffect((): VoidFunction => {
    return clearCurrentTimeTimeout;
  }, []);

  return <FormValue label={t('settings.dateTime.section.timezone.currentTime')} value={serverTimeText} />;
};
