import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';

export const NotificationsTest: React.FunctionComponent = () => {
  const { t } = useTranslation();
  useEffect(() => {
    createAndDispatchNotification('testing notification 1', NotificationVariant.SUCCESS, t);
    createAndDispatchNotification('testing notification 2', NotificationVariant.SUCCESS, t);
    setInterval(() => {
      //createAndDispatchNotification('testing notification', NotificationVariant.SUCCESS, t);
    }, 1000);
  }, []);
  return <>{}</>;
};
