import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { RebootResponse } from 'src/js/model/api/response/reboot-response';
import { RebootNeededSavedModal } from 'src/js/reboot/reboot-needed-saved-modal';
import { Paths } from 'src/js/route';
import { SettingsTabContent } from 'src/js/pages/settings/settings-tab-content';
import { createRebootTask } from 'src/js/task/settings-tasks';

import { LicenseDeleteModal } from './license-delete-modal';
import { LicenseFeatures } from './license-features';
import { LicenseImportModal } from './license-import-modal';
import { fetchLicenses } from './license-store';
import { LicenseTable } from './license-table';
import { LicenseViewModel } from './license-view-model';
import { createListStore, ListSelector } from 'src/js/util/listSelector';
import { useMutable } from 'src/js/hook/use-mutable';
import { triggerEvent } from 'src/js/events';

export let licensesList: ListSelector<LicenseViewModel> = null;

export const Licenses: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // FIXME replace with <RebootNeeded> generic component
  const [rebootNeededSavedModalShown, showRebootNeededSavedModal, hideRebootNeededSavedModal] = useModalControls();

  const rebootTask = useTask(createRebootTask());
  const [licenses, setLicenses] = useState([]);
  let listController: ListSelector<LicenseViewModel> = null;
  const [uuid] = useMutable(uuidv4());
  licensesList = listController = createListStore(licenses, uuid(), 'name');
  const [singleItem, setSingleItem] = useState<LicenseViewModel>();

  useSuccessReaction(rebootTask, (result: RebootResponse) => {
    navigate(result.upgrade ? Paths.rebootingUpgrade : Paths.rebooting);
  });

  const [loading, setLoading] = useState(true);
  const fetchData = () => {
    fetchLicenses().then((res: any) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          setLicenses(response.data); // TODO find a better method incorporated with listController
          listController.updateList(response.data);
          triggerEvent('licenses-fetched');
        }
        setLoading(false);
      }
    });
  };

  // Load the data on mount
  useEffect(() => {
    fetchData();
  }, []);

  const shouldReloadData = (): void => {
    listController.unselectAll();
    fetchData();
  };

  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const onDeleteCompleted = (failed: boolean): void => {
    hideDelete();
    setSingleItem(undefined);
    shouldReloadData();
    if (!failed) {
      showRebootNeededSavedModal(); // FIXME add check for preset isSaved like on status page. Move to a common component.
    }
  };

  const [importShown, showImport, hideImport] = useModalControls();
  const handleImport = (): void => {
    hideImport();
    shouldReloadData();
    showRebootNeededSavedModal();
  };

  const handleOnReboot = (): void => {
    rebootTask();
  };

  // console.log('render root license with list', listController.list);
  return (
    <>
      <SettingsTabContent name={t('settings.licensing.title')}>
        <LicenseTable
          listController={listController}
          onDelete={showDelete}
          showImport={showImport}
          loading={loading}
          setSingleItem={setSingleItem}
        />
        <LicenseFeatures />
      </SettingsTabContent>

      {deleteShown && (
        <LicenseDeleteModal
          licensesToDelete={singleItem ? [singleItem] : listController.selectedRows()}
          onCancel={() => {
            hideDelete();
            setSingleItem(undefined);
            listController.unselectAll();
          }}
          onDeleteCompleted={onDeleteCompleted}
        />
      )}
      {importShown && <LicenseImportModal onCancel={hideImport} onImport={handleImport} />}
      {rebootNeededSavedModalShown && (
        <RebootNeededSavedModal
          onCancel={hideRebootNeededSavedModal}
          onReboot={() => {
            hideRebootNeededSavedModal();
            handleOnReboot();
          }}
        />
      )}
    </>
  );
};
