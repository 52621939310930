import { ButtonToggleGroup } from '@hai/ui-react';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import systemInfo from 'src/js/data/systemInfo';
import { useAdaptiveFetch } from 'src/js/hook/use-adaptive-fetch';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { AdaptivePadding, LineChart } from 'src/js/pages/statistic/plot/line-chart';
import { useWatchObject } from 'src/js/store/use-watch';
import { chartColors } from 'src/js/theme/chart';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { useLocalStorage } from 'usehooks-ts';
import { LegendButtonsToggleOption } from '../statistic/plot/legend-buttons';
import { LegendText } from '../statistic/plot/legend-text';

import bandwidth, { fetchBandwidth, mapBandwidthToPlotData } from './bandwidth-model';

export enum LegendLocation {
  NONE = 0,
  BOTTOM,
  LEFT,
}

interface Props {
  fullpage?: boolean;
  legend?: LegendLocation;
  showHeaderLegend?: boolean;
  showTimescale?: boolean;
  timescale?: StatsInterval;
  title?: string;
  forceData?: any;
  pauseFetch?: boolean;
}

export const BandwidthGraph = (props: Props) => {
  const {
    fullpage = false,
    legend = LegendLocation.NONE,
    showHeaderLegend = false,
    showTimescale = false,
    timescale,
    title,
    forceData,
    pauseFetch,
  } = props;

  const { t } = useTranslation();
  const [graphData, setGraphData] = useState(forceData ?? []);

  const { sessionStore } = useStores();
  const [timescaleSelection, setTimescale] = useLocalStorage(
    `${constant.lStorage.bandwidthInterval}:${sessionStore.username}`,
    timescale ?? StatsInterval.LAST_5_MINS,
  );

  const [defaultInactiveFields, setDefaultInactiveFields] = useLocalStorage(
    `${constant.lStorage.bandwidthInactiveFields}:${sessionStore.username}`,
    [],
  );
  // const [timescaleSelection, setTimescale] = useState(timescale ?? StatsInterval.LAST_5_MINS);
  const [reportedNics, setReporetdNics] = useMutable(['eth0']);

  const interval = pauseFetch ? undefined : 10 * 1000;
  if (!forceData) {
    useAdaptiveFetch(() => fetchBandwidth(timescaleSelection, setReporetdNics), interval, [timescaleSelection]);
    useWatchObject(bandwidth, setGraphData);
  }

  useEffect(() => {
    // clear graph
    setGraphData((existing: any) => {
      if (Array.isArray(existing) && existing.length > 2) {
        return [existing[existing.length - 1]];
      }
      return existing;
    });
  }, [timescaleSelection]);

  const fontSize = fullpage ? 14 : 12;
  const [width, setWidth] = useState(600);
  const [height] = useState(220);
  const [adj, setAdj] = useState(1.0);
  const ref = useRef<HTMLDivElement>();

  // Handle resize
  // const [firstTime, setFirstTime] = use(true);
  useEffect(() => {
    const onResizeLocal = (_ev: UIEvent) => {
      if (ref.current) {
        let parentHeight = ref.current.clientHeight;
        const parentWidth = ref.current.clientWidth;
        if (legend === LegendLocation.BOTTOM) {
          parentHeight -= 70; // reserve space for legend
        }
        if (parentHeight > 0) {
          const ratio = parentWidth / parentHeight;
          const newWidth = Math.round(height * ratio);
          //console.log('ratio?', ratio);
          //console.log('parentHeight?', parentHeight, height);
          setWidth(newWidth);
        }
      }
    };
    window.addEventListener('resize', onResizeLocal); // this doesnt work on modals
    window.dispatchEvent(new Event('resize'));
    return () => {
      window.removeEventListener('resize', onResizeLocal);
    };
  }, []);

  // Handle local width/height change
  useEffect(() => {
    const ratio = ref.current.clientHeight / height;
    const curFontSize = 12 * ratio;
    // method 2
    // const graphArea = width * height;
    // const divArea = ref.current.clientWidth * ref.current.clientHeight;
    // const ratio = divArea / graphArea;
    // const curFontSize = 12 * Math.sqrt(ratio);
    if (curFontSize != fontSize) {
      const adjustment = curFontSize / fontSize;
      setAdj(adjustment);
    }
  }, [width, height]);
  const mapped = mapBandwidthToPlotData(graphData, t);
  // console.log('bandwidth graph data mapped', mapped);
  // console.log('bandwidth graph width', width, width * adj, height);
  const textHeaderLegendOptions: LegendButtonsToggleOption[] = [
    { key: 1, label: 'Rx', indicatorColor: chartColors.bandwidth[0], active: true },
    { key: 2, label: 'Tx', indicatorColor: chartColors.bandwidth[1], active: true },
  ];
  const textHeaderLegendOptions2: LegendButtonsToggleOption[] = [
    { key: 1, label: 'Rx', indicatorColor: chartColors.bandwidth[2], active: true },
    { key: 2, label: 'Tx', indicatorColor: chartColors.bandwidth[3], active: true },
  ];
  return (
    <div className={classNames('box mk-bandwidth-graph', fullpage && 'fullpage sunken-container')}>
      {showHeaderLegend && (
        <div className="mk-text-header-legend">
          <span className="label">ETH 0:</span>
          <LegendText options={textHeaderLegendOptions} />
          {systemInfo.hasEth1 && reportedNics()?.length === 2 && (
            <>
              <span className="label">ETH 1:</span>
              <LegendText options={textHeaderLegendOptions2} />
            </>
          )}
        </div>
      )}
      <div className="mk-bandwidth-graph-container" ref={ref}>
        {!isNilOrEmpty(graphData) && (
          <div className="mk-horizontal-flex">
            {(title || showTimescale) && (
              <div className="header">
                {title && <span className="title">{title}</span>}
                {showTimescale && (
                  <div className="mk-timescale-selector d-flex justify-content-end">
                    <ButtonToggleGroup
                      options={[
                        {
                          label: t(`statistics.chart.timescale.optionsName.${StatsInterval.LAST_5_MINS}`),
                          value: StatsInterval.LAST_5_MINS,
                          active: timescaleSelection === StatsInterval.LAST_5_MINS,
                        },
                        {
                          label: t(`statistics.chart.timescale.optionsName.${StatsInterval.LAST_60_MINS}`),
                          value: StatsInterval.LAST_60_MINS,
                          active: timescaleSelection === StatsInterval.LAST_60_MINS,
                        },
                        {
                          label: t(`statistics.chart.timescale.optionsName.${StatsInterval.LAST_24_HOURS}`),
                          value: StatsInterval.LAST_24_HOURS,
                          active: timescaleSelection === StatsInterval.LAST_24_HOURS,
                        },
                      ]}
                      onOptionClicked={(opt: any) => {
                        // console.log('what', opt.value);
                        setTimescale(opt.value);
                      }}
                    ></ButtonToggleGroup>
                  </div>
                )}
              </div>
            )}
            <LineChart
              yTitle="kbps"
              width={width * adj}
              height={height * adj}
              padding={{ top: 14, right: 10, bottom: 16, left: 41 }}
              adaptiveLeftPadding={AdaptivePadding.LIGHT}
              data={mapped}
              lineColors={chartColors.bandwidth}
              yTickCount={fullpage ? 5 : 4}
              showLegend={legend === LegendLocation.LEFT ? 'left' : legend === LegendLocation.BOTTOM ? true : false}
              showCommasInAxisLabels={true}
              timescale={timescaleSelection ?? StatsInterval.LAST_5_MINS}
              xAxisMinutes={timescaleSelection === StatsInterval.LAST_5_MINS || timescaleSelection === undefined}
              yRange={[
                { limit: 9900, divideBy: 1000, label: 'Mbps' },
                { limit: 9900000, divideBy: 1000000, label: 'Gbps' },
              ]}
              defaultInactiveFields={defaultInactiveFields}
              setDefaultInactiveFields={setDefaultInactiveFields}
            />
          </div>
        )}
      </div>
    </div>
  );
};
