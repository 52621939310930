import { action, computed, IObservableArray, observable } from 'mobx';
import { ListSelectionStore } from 'src/js/store/list-selection-store';

/**
 * Generic store to use when there is a component with a table that can be selected and date needs to be stored
 * Allows multiple selection, toggling, getting the data.
 *
 * Use with ListSelectionWithDataStoreProvider to add in a flow
 */
export class ListSelectionWithDataStore2<T> extends ListSelectionStore {
  @observable
  private _data: IObservableArray<T> = observable.array();

  @action
  setData(data: T[]) {
    this.setTotalCount(data.length);
    this._data = observable.array(data);
  }

  @computed get data(): T[] {
    return this._data.toJS();
  }

  @computed get selectedRows(): T[] {
    return this._data.filter((_, index: number) => this.isSelected(index));
  }
}
