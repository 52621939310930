import { Observable } from 'rxjs';
import { APIPath } from 'src/js/api/route-path-index';
import { BaseController } from 'src/js/controller/base-controller';
import { NetworkErrorHandler } from 'src/js/controller/network-error-handler';
import { GetLocateStatusResponse } from 'src/js/model/api/response/get-locate-status-response';
import { StatusResponse } from 'src/js/model/api/response/status-response';

export class SettingsStatusController extends BaseController {
  /* Returns the status */
  public getStatus(errorHandler?: NetworkErrorHandler): Observable<StatusResponse> {
    return this.get$(APIPath.status.get, {}, { customErrorHandler: errorHandler });
  }
  /* Get locate status */
  public getLocateStatus(): Observable<GetLocateStatusResponse> {
    return this.get$(APIPath.leds);
  }
  /* Start locate */
  public startLocate(duration: number): Observable<{}> {
    return this.post$(APIPath.leds, { duration });
  }
  /* Stop locate */
  public stopLocate(): Observable<{}> {
    return this.delete$(APIPath.leds);
  }
}
