import { ApiResponse } from 'apisauce';
import { TFunction } from 'i18next';
import { isNil } from 'ramda';
import { Observer } from 'rxjs';
import { ServerError } from 'src/js/error/server-error';
import { GenericResponse } from 'src/js/model/api/response/generic-response';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { DecoderResponse } from 'src/js/pages/streaming/decoder/decoder-model';
import { StreamResponse } from 'src/js/pages/streaming/stream/stream-models';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';

export interface NotificationHandlerConfiguration {
  successMessage: string;
  errorMessage: string;
  reminderMessage: string;
  warningMessage: string;
}

/*
 * Non-Rx Handlers
 */
export const handleGenericResponse = (response: GenericResponse, t: TFunction): GenericResponse => {
  if (response.error || (!isNil(response.success) && !response.success)) {
    createAndDispatchNotification(response.message, NotificationVariant.ERROR, t);
    // TODO: Validate this behaviour
    throw new ServerError(response.message, 0 /*status is unavailable*/);
  }
  return response;
};

export const handleStreamCreate = (stream: StreamResponse, t: TFunction): StreamResponse => {
  if (stream.info.id !== constant.newModelId) {
    createAndDispatchNotification(
      t(
        isNilOrEmpty(stream.info.name)
          ? 'stream.notifications.createSuccessEmptyName'
          : 'stream.notifications.createSuccess',
        {
          name: stream.info.name,
          id: stream.info.id,
        },
      ),
      NotificationVariant.SUCCESS,
      t,
    );
  }
  return stream;
};

export const handleStreamUpdate = (stream: StreamResponse, t: TFunction): StreamResponse => {
  if (!isNil(stream)) {
    createAndDispatchNotification(
      t(
        isNilOrEmpty(stream.info.name)
          ? 'stream.notifications.updateSuccessEmptyName'
          : 'stream.notifications.updateSuccess',
        {
          name: stream.info.name,
        },
      ),
      NotificationVariant.SUCCESS,
      t,
    );
  }
  return stream;
};

/**
 * To use when you want to display a notification on success. Simply add this to the responseHandler, if the response
 * is not nil, it will display the notification you want
 * @param response: generic response, will receive what you need
 * @param t: translator for notification
 * @param type: notification type
 * @param message: notification message
 */
export const dispatchNotificationHandler = <T>(
  response: T,
  t: TFunction,
  type: NotificationVariant,
  message: string,
): T => {
  if (!isNil(response)) {
    createAndDispatchNotification(message, type, t);
  }
  return response;
};

/*
 * Rx Handlers
 */
// FIXME: Restore to simpler architecture
// DEPRECATED: remove this usage and replace with notificationHandler() below
export const decoderNotificationHandler = (
  t: TFunction,
  config: NotificationHandlerConfiguration,
): Observer<ApiResponse<DecoderResponse>> => {
  return {
    next(value: ApiResponse<DecoderResponse>) {
      if (value.ok) {
        createAndDispatchNotification(config.successMessage, NotificationVariant.SUCCESS_WITH_PREFIX, t);
      }
    },

    error(err: any) {
      createAndDispatchNotification(err.message, NotificationVariant.ERROR, t);
    },
  } as Observer<ApiResponse<DecoderResponse>>;
};

/*
 * Notification to be used in the component views
   TODO: Everything else than this function should be removed from the project and upgraded to use this one.
 */
export const notificationHandler = (
  response: ApiResponse<GenericResponse>,
  success: string,
  error: string,
  t: TFunction,
  withSuccessPrefix?: boolean,
): void => {
  if (response.ok) {
    createAndDispatchNotification(
      success,
      withSuccessPrefix ? NotificationVariant.SUCCESS_WITH_PREFIX : NotificationVariant.SUCCESS,
      t,
    );
  } else {
    // Error can be in json {errorText: ""} or as plain text "Error: Invalid stream id."
    if (typeof response.data === 'string' && (response.data as string).startsWith('<?xml version=')) {
      createAndDispatchNotification(t('notifications.internalServerError'), NotificationVariant.ERROR, t);
    } else if (typeof response.data === 'string') {
      const prefix = 'Error: ';
      const message = response.data as string;
      if (message.startsWith(prefix)) {
        createAndDispatchNotification(message.substring(prefix.length), NotificationVariant.ERROR, t);
      } else {
        createAndDispatchNotification(message, NotificationVariant.ERROR, t);
      }
    } else if (response.data && typeof response.data.error === 'string') {
      createAndDispatchNotification(response.data.error, NotificationVariant.ERROR, t);
    } else if (response.data && typeof response.data.error === 'boolean' && typeof response.data.message === 'string') {
      createAndDispatchNotification(response.data.message, NotificationVariant.ERROR, t);
    } else {
      createAndDispatchNotification(error, NotificationVariant.ERROR, t);
    }
  }
};
