import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { LicenseViewModel } from 'src/js/pages/settings/license/license-view-model';

import { deleteLicense } from './license-store';

interface Props {
  licensesToDelete: LicenseViewModel[];
  onDeleteCompleted?: (failed: boolean) => void;
  onCancel?: VoidFunction;
}

export const LicenseDeleteModal: React.FunctionComponent<Props> = ({
  licensesToDelete,
  onDeleteCompleted,
  onCancel,
}) => {
  const { t } = useTranslation();

  const handleDelete = (): void => {
    Promise.all(licensesToDelete.map((e) => deleteLicense(e))).then((responses) => {
      const failed = responses.filter((r) => !r.ok);
      if (failed.length > 0) {
        createAndDispatchNotification(
          t('settings.licensing.notifications.deleteErrorMsg', { count: failed.length }),
          NotificationVariant.ERROR,
          t,
        );
      }
      onDeleteCompleted?.(failed.length > 0);
    });
  };

  return (
    <Dialog
      headline={t('settings.licensing.modal.delete.title', { count: licensesToDelete.length })}
      bodyText={`${t('settings.licensing.modal.delete.bodyText')} ${t('general.cannotUndo')}`}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel') },
        { label: t('general.deleteBtn'), variant: 'danger', onClick: handleDelete },
      ]}
    />
  );
};
