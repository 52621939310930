import { FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { isEmpty } from 'ramda';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import {
  domainRegex,
  ipv4Regex,
  ipv6Regex,
  isDnsOrEmpty,
  isIpOrHostname,
  isIpOrHostnameOrEmpty,
  isMulticast,
  netmaskRegex,
} from 'src/js/validator/ip-validator-helper';
import { isAutoAssignOrNumber } from 'src/js/validator/port-validator-helper';
import * as yup from 'yup';

import { Config } from '../config';

export const ipv4 = (t: TFunction) => yup.string().matches(ipv4Regex, t('validation.network.ipv4'));

export const ipOrHostnameOrEmpty = (t: TFunction) =>
  yup.string().test('is-empty-ip-or-hostname', t('validation.network.ipOrHostname'), isIpOrHostnameOrEmpty);

export const ipOrHostname = (t: TFunction) =>
  yup.string().test('is-ip-or-hostname', t('validation.network.ipOrHostname'), isIpOrHostname);

export const multicast = (t: TFunction) => yup.string().test('is-multicast', t('validation.multicast'), isMulticast);

export const ipv6 = (t: TFunction) => yup.string().matches(ipv6Regex, t('validation.network.ipv6'));

export const netmask = (t: TFunction) => yup.string().matches(netmaskRegex, t('validation.network.netmask'));

export const domain = (t: TFunction) => yup.string().matches(domainRegex, t('validation.network.domain'));

export const port = (t: TFunction) =>
  yup.number().typeError(t('validation.port')).min(1, t('validation.port')).max(65535, t('validation.port'));

export const tsPidValidate = (pid?: string | number | Array<string | number>): boolean => {
  if (pid === '' || pid === null || pid === undefined) {
    return true; // empty allowed, it means to autodetect
  }
  if (typeof (pid as string)?.includes === 'function' && (pid as string).includes(',')) {
    pid = (pid as string).split(','); // comma separated string to array
  }

  if (Array.isArray(pid)) {
    for (let i = 0; i < pid.length; i++) {
      const number = Number(pid[i]);
      if (isNaN(number)) {
        return false;
      }
      if (number >= 16 && number <= 8190) {
        // ok
      } else {
        return false;
      }
    }
    return true;
  } else {
    // plain number
    const number = Number(pid);
    if (!isNaN(number)) {
      if (number >= 16 && number <= 8190) {
        return true;
      }
      return false;
    }
    return false;
  }
};

export const tsPid = (t: TFunction) => yup.string().test('ts-pid', t('validation.stream.tsPid'), tsPidValidate);

export const dnsOrEmpty = (t: TFunction) => yup.mixed().test('is-dns', t('validation.network.dns'), isDnsOrEmpty);

export const autoAssignOrNumberOrEmpty = (t: TFunction) =>
  yup.string().test('is-empty-auto-assign-or-number', t('validation.stream.autoAssign'), isAutoAssignOrNumber);

export const alphabetic = (t: TFunction) => yup.string().matches(/^[a-zA-Z]+$/, t('validation.nonAlphabetic'));

//FIXME this one is buggy because synchronous. Use submitAndValidate() below
export const validateFormContext = (formContext: FormikProps<any>, t: TFunction) => {
  formContext.handleSubmit();

  if (!formContext.isValid) {
    /*eslint no-console: 0*/
    Config.isDebug && console.log('Validation errors', formContext.errors);
    createAndDispatchNotification(
      t('validation.invalidSubmit', { count: Object.keys(formContext.errors).length }),
      NotificationVariant.ERROR,
      t,
    );
  }
};

export const submitAndValidate = async (formContext: FormikProps<any>, t: TFunction): Promise<any> => {
  //TODO try
  // const isInitialValid = schema.isValidSync(initialValues);

  return formContext.validateForm().then((errors) => {
    if (!isEmpty(errors)) {
      /*eslint no-console: 0*/
      Config.isDebug && console.log('Validation errors', formContext.errors);
      createAndDispatchNotification(
        t('validation.invalidSubmit', { count: Object.keys(formContext.errors).length }),
        NotificationVariant.ERROR,
        t,
      );
    }
    return formContext.submitForm();
  });
};
