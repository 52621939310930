import React from 'react';

interface Props {
  title: string;
  content: string;
  className?: string;
}

export const NtpStatisticsSection: React.FunctionComponent<Props> = ({ title, content, className }) => {
  return (
    <div className={className}>
      <div className="hai-mb-2">{title}</div>
      <div className="ntp-statistics-section">
        {content.split('\\n').map((line, index) => (
          <div className="line" key={index}>
            {line}
          </div>
        ))}
      </div>
    </div>
  );
};
