import { Button, FormSection, Icon, IconButton } from '@hai/ui-react';
import classNames from 'classnames';
import React, { ChangeEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOptions } from 'src/js/component/base/form/form-select';
import { FormTextfield } from 'src/js/component/base/form/form-textfield';
import { enumStringValues, enumValues } from 'src/js/helper/enum-helper';

import { FormCheckbox } from './base/form/form-checkbox';
import { FormSelect } from './base/form/form-select';
import { FormSwitch } from './base/form/form-switch';
import { FormValue } from './base/form/form-value';
import { generateDataAuto } from '../util/automation';

export enum FormFieldType {
  CHECKBOX = 1,
  COLUMN_START,
  COLUMN_END,
  DOUBLE_COLUMN_START,
  DOUBLE_COLUMN_END,
  DROPDOWN,
  MULTI_DROPDOWN,
  INPUT_TEXT,
  INPUT_PASSWORD,
  INPUT_NUMBER,
  LINE_BREAK,
  /** Use with 'node' prop */
  NODE,
  STATIC_TEXT,
  SUBTITLE,
  SWITCH,
  TITLE,
  SECTION_END,
  SECTION_START,
}

enum PrivateFieldType {
  GROUP = 'group',
  COLUMN = 'column',
  SECTION = 'section',
}

export enum FormLayout {
  /** No formatting
   *  Can be used to build single fields within a larger form
   */
  PLAIN_FIELDS = 0,
  /** Will surround fields with appropriate column layout CSS
   *  Note that if you print one or more FormFieldType.TITLE it will automatically switch to THREE_COLUMNS layout
   */
  THREE_COLUMNS_GRID = 1,
  THREE_COLUMNS_MASONRY = 2,
  THREE_COLUMNS_FLEX = 3,
  TWO_COLUMNS_FLEX = 4,
}

export interface FormField {
  name?: string;
  id?: string;
  className?: string;
  mask?: boolean; // fs-mask class for FullStory

  /**
   * value
   * If under HAIUI form, this needs not be set as the Formik value
   * will be forced by HAIUI. We cannot even override this at this time
   * If type==MULTI_DROPDOWN, this needs to be an array.
   */
  value?: any;

  /**
   * setValues
   * For type==MULTI_DROPDOWN only
   */
  setValues?: (values: any[]) => void;

  /**
   * maxAmount of dropdowns
   * For type==MULTI_DROPDOWN only
   */
  maxAmount?: number;

  /** Additional content printed after */
  addon?: ReactNode;

  /** Wrapper container */
  container?: (children: ReactNode) => ReactNode;

  breakBefore?: boolean;

  /**
   * enum
   * For type == DROPDOWN only
   * If set will fill up the dropdown from mapping the enum values
   * using t()`${translationPrefixEnums}.${enum/val}`)
   */
  enum?: { label: string; value: string | number }[] | any;

  /**
   * label
   * you can leave undefined and set 'translationPrefix' in the main props
   * in order to use t()`${translationPrefix}.${name}`) automatically as the label
   */
  label?: string | undefined;

  /**
   * title
   * for SWITCH and CHECKBOX
   */
  title?: string;

  /**
   * placeholder
   * for type == INPUT_TEXT / INPUT_NUMBER only
   */
  placeholder?: string;

  /**
   * hint
   * for type == INPUT_TEXT / INPUT_NUMBER only
   */
  hint?: string;

  type: FormFieldType | PrivateFieldType;

  /** Specify a custom ReactNode
   */
  node?: ReactNode;

  /**
   * options
   * for type == DROPDOWN only
   */
  options?: SelectOptions;
  direction?: string;

  /**
   * span
   * by default it spans 1 column
   * Accepted values [1,3]
   * */
  span?: number;

  /**
   * autoComplete
   * for type=INPUT_TEXT,
              INPUT_PASSWORD,
              INPUT_NUMBER,
   */
  autoComplete?: string;

  disabled?: boolean;
  required?: boolean;

  /**
   * when
   * Set to false to remove this element from the view
   */
  when?: boolean;

  onChange?: (e: any) => void;

  /**
   * onKeyUp
   * for type=INPUT_TEXT,
              INPUT_PASSWORD,
              INPUT_NUMBER,
   */
  onKeyUp?: (e: any) => void;

  /**
   * Specify a className to be applied to the FormTextField FormControl
   */
  inputClassName?: string;
}

interface PrivateFormField extends FormField {
  group?: FormField[];
}

interface FormSection {
  title: string | ReactNode;
  fields: FormField[];
}

interface Props {
  layout?: FormLayout;
  fields?: FormField[];
  translationPrefixEnums?: string;
  translationPrefixLabels?: string;
}

export const FormBuilder = (props: Props) => {
  const { layout = FormLayout.PLAIN_FIELDS, fields, translationPrefixEnums, translationPrefixLabels } = props;
  const { t } = useTranslation();
  let itemCount = 0;
  let groupCount = 0;

  const printField = (field: PrivateFormField, section?: FormSection): any => {
    itemCount++;
    let key = `item-${itemCount}`;
    if (typeof section?.title === 'string' && typeof field.name === 'string') {
      key = `${section.title}-${field.name}`;
    }
    if (field.when === false) {
      // remove field when false
      return undefined;
    }
    if (field.node) {
      return <React.Fragment key={key}>{field.node}</React.Fragment>;
    } else if (field.type === FormFieldType.TITLE) {
      groupCount = 0; // restart group count
      return (
        <React.Fragment key={key}>
          <FormSection
            className={`${field.className ? field.className : 'hai-mb-6'}`}
            title={field.label}
            key={field.label}
          >
            {field.group.map((group: FormField) => printField(group))}
          </FormSection>
        </React.Fragment>
      );
    } else if (field.type === PrivateFieldType.GROUP) {
      let layoutClassName = 'mk-group';
      if (layout === FormLayout.THREE_COLUMNS_GRID) {
        layoutClassName = 'form-fields-three-columns-grid';
      } else if (layout === FormLayout.THREE_COLUMNS_MASONRY) {
        layoutClassName = 'form-fields-three-columns-masonry';
      } else if (layout === FormLayout.THREE_COLUMNS_FLEX) {
        layoutClassName = 'form-fields-three-columns-flex';
      } else if (layout === FormLayout.TWO_COLUMNS_FLEX) {
        layoutClassName = 'form-fields-two-columns-flex';
      }
      groupCount++;
      if (layoutClassName === 'mk-group') {
        return <React.Fragment key={key}>{field.group.map((group: FormField) => printField(group))}</React.Fragment>;
      }
      return (
        <div className={layoutClassName} key={`group-${groupCount}-${groupCount}`}>
          {field.group.map((group: FormField) => printField(group))}
        </div>
      );
    } else if (field.type === PrivateFieldType.SECTION) {
      return <React.Fragment key={key}>{field.group.map((group: FormField) => printField(group))}</React.Fragment>;
    } else if (field.type === PrivateFieldType.COLUMN) {
      return (
        <div key={key} className="mk-layout-column flex-grow-1">
          {field.group.map((group: FormField) => printField(group))}
        </div>
      );
    } else if (field.type === FormFieldType.CHECKBOX) {
      return (
        <div
          key={key}
          className={`mk-form-checkbox${field.breakBefore ? ' mk-break-before' : ''}`}
          data-auto={generateDataAuto('form', `checkbox ${field.name}`)}
        >
          <FormCheckbox
            className={`${field.breakBefore ? 'mk-break-before' : ''}`}
            name={field.name}
            label={
              field.label === undefined && translationPrefixLabels === undefined
                ? undefined
                : field.label ?? t(`${translationPrefixLabels}.${field.name}`)
            }
            title={field.title}
            checked={field.value !== undefined ? !!field.value : undefined}
            disabled={field.disabled}
            onChange={field.onChange}
          />
          {field.addon}
        </div>
      );
    } else if (field.type === FormFieldType.SWITCH) {
      return (
        <React.Fragment key={key}>
          <FormSwitch
            className={`hai-mb-4 ${field.className}${field.breakBefore ? ' mk-break-before' : ''}`}
            name={field.name}
            mask={field.mask}
            switchLabel={
              field.label === undefined && translationPrefixLabels === undefined
                ? undefined
                : field.label ?? t(`${translationPrefixLabels}.${field.name}`)
            }
            title={field.title}
            checked={field.value !== undefined ? !!field.value : undefined}
            onChange={field.onChange}
          />
          {field.addon}
        </React.Fragment>
      );
    } else if (field.type === FormFieldType.DROPDOWN) {
      let stringEnum = false;
      if (field.enum) {
        const enumKeys = Object.keys(field.enum);
        if (enumKeys && enumKeys.length > 0 && /[a-zA-Z]/.exec(enumKeys[0])) {
          stringEnum = true;
        }
      }
      const enums = field.enum && (stringEnum ? enumStringValues(field.enum) : enumValues(field.enum));
      const options =
        field.options ??
        (field.enum
          ? enums.map((value) => ({
              value: value,
              label: t(`${translationPrefixEnums}.${field.name}.${value.toString() as string}`),
            }))
          : undefined);
      const dropdownEl = (
        <div
          key={key}
          className={classNames(
            `mk-form-select${field.breakBefore ? ' mk-break-before' : ''}`,
            field.mask && 'fs-mask',
          )} /* TODO when HAIUI fixes masking on opened dropdown we can remove this fs-mask */
          data-auto={generateDataAuto('form', `select ${field.name}`)}
        >
          <FormSelect
            className={`flex-grow-1 ${field.className ? field.className : ''}`}
            name={field.name}
            mask={field.mask}
            disabled={field.disabled}
            title={
              field.label === undefined && translationPrefixLabels === undefined
                ? undefined
                : field.label ?? t(`${translationPrefixLabels}.${field.name}`)
            }
            defaultValue={options && typeof options[0]?.value === 'string' ? field.value?.toString() : field.value}
            options={options}
            onChange={field.onChange}
            direction={field.direction}
          />
          {field.addon}
        </div>
      );
      if (field.container) {
        return field.container(dropdownEl);
      }
      return dropdownEl;
    } else if (field.type === FormFieldType.MULTI_DROPDOWN) {
      if (!Array.isArray(field.value)) {
        /*eslint no-console: 0*/
        console.error('Field value needs to be an array when type === MULTI_DROPDOWN');
        return 'Invalid usage';
      }
      const options =
        field.options ??
        (field.enum
          ? enumValues(field.enum).map((value) => ({
              value: value,
              label: t(`${translationPrefixEnums}.${field.name}.${value.toString() as string}`),
            }))
          : undefined);

      if (options.length === 0) {
        console.warn('Dropdown needs at least one option');
      }

      if (field.value.length === 0) {
        field.value.push(options[0].value);
      }

      const handleAddLocation = () => {
        const nextAvailableValue = field.options.find((o) => {
          const existing = field.value.find((v: any) => v === o.value);
          if (o.value === -1) {
            return false;
          }
          return existing === undefined;
        });
        field.value.push(nextAvailableValue?.value ?? -1);
        field.setValues(field.value);
      };
      const handleRemoveLocation = (e: any, index: number) => {
        e.preventDefault();
        const removeLocation = [...field.value];
        removeLocation.splice(index, 1);
        field.setValues(removeLocation);
      };

      return (
        <div
          key={key}
          className={`mk-multiselect flex-grow-1${field.breakBefore ? ' mk-break-before' : ''}`}
          data-auto={generateDataAuto('multi', `dropdown ${field.name}`)}
        >
          {field.value.map((value: any, index: number) => {
            const defaultValue = options && typeof options[0]?.value === 'string' ? value?.toString() : value;
            return (
              <React.Fragment key={`${key}-${index}`}>
                <div className="d-flex align-items-start mk-multiselect-item">
                  <FormSelect
                    className={index === 0 ? 'multiselect first' : 'multiselect'}
                    name={field.name + '_multi[' + index + ']'}
                    mask={field.mask}
                    disabled={field.disabled}
                    title={
                      index === 0
                        ? field.label === undefined && translationPrefixLabels === undefined
                          ? undefined
                          : field.label ?? t(`${translationPrefixLabels}.${field.name}`)
                        : ''
                    }
                    defaultValue={defaultValue}
                    options={options}
                    onChange={(e: ChangeEvent<any>) => {
                      field.value[index] = e.target.value;
                      field.setValues(field.value);
                      field.onChange?.(e);
                    }}
                  />
                  {field.value.length > 1 && (
                    <IconButton
                      style={{
                        visibility: field.value.length <= 1 && 'hidden',
                        backgroundColor: 'transparent',
                      }}
                      className="hai-ml-2 hai-mt-1"
                      onClick={(e: any) => handleRemoveLocation(e, index)}
                    >
                      <Icon iconname="Remove" className="gray-07-svg" />
                    </IconButton>
                  )}
                  {field.addon}
                </div>
                {index === field.value.length - 1 && (
                  <Button
                    key={`${key}-${index}-button`}
                    variant="secondary"
                    onClick={handleAddLocation}
                    style={{ marginTop: '7px', marginBottom: '24px' }}
                    disabled={field.maxAmount >= 0 ? field.value.length >= field.maxAmount : false}
                  >
                    {t('general.add')}
                    {/*24px is the space below haiui form fields, 20px validation error message + 4px bottom margin */}
                  </Button>
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    } else if (
      field.type === FormFieldType.INPUT_TEXT ||
      field.type === FormFieldType.INPUT_PASSWORD ||
      field.type === FormFieldType.INPUT_NUMBER
    ) {
      return (
        <div
          key={key}
          className={classNames(
            'mk-form-input-text',
            field.className,
            field.breakBefore && 'mk-break-before',
            field.span === 2 && 'grid-column-span2',
            field.span === 3 && 'grid-column-span3',
          )}
          data-auto={generateDataAuto('form', `input text ${field.name}`)}
        >
          <FormTextfield
            name={field.name}
            id={field.id}
            mask={field.mask}
            disabled={field.disabled}
            inputClassName={field.inputClassName}
            title={
              field.label === undefined && translationPrefixLabels === undefined
                ? undefined
                : field.label ?? t(`${translationPrefixLabels}.${field.name}`)
            }
            value={field.value}
            defaultValue={field.value}
            required={field.required}
            placeholder={field.placeholder}
            hint={field.hint}
            onChange={field.onChange}
            onKeyUp={field.onKeyUp}
            autoComplete={field.autoComplete}
            type={
              field.type === FormFieldType.INPUT_PASSWORD
                ? 'password'
                : field.type === FormFieldType.INPUT_TEXT
                ? 'text'
                : 'number'
            }
          />
          {field.addon}
        </div>
      );
    } else if (field.type === FormFieldType.STATIC_TEXT) {
      return (
        <div
          key={key}
          className={`mk-form-input-text${field.breakBefore ? ' mk-break-before' : ''}`}
          data-auto={generateDataAuto('form', `static text ${field.name}`)}
        >
          <FormValue
            name={field.name}
            mask={field.mask}
            className={`hai-mb-6 ${field.className}${field.breakBefore ? ' mk-break-before' : ''}`}
            label={
              field.label === undefined && translationPrefixLabels === undefined
                ? undefined
                : field.label ?? t(`${translationPrefixLabels}.${field.name}`)
            }
            value={field.value?.toString()}
          />
        </div>
      );
    } else if (field.type === FormFieldType.SUBTITLE) {
      return (
        <React.Fragment key={key}>
          <div className="grid-column-fullwidth mk-break-before">
            <h3 className="haiui-heading-04-light mt-0 mb-4">{field.label}</h3>
          </div>
        </React.Fragment>
      );
    } else {
      return `Not implemented type: ${field.type}`;
    }
  };

  // Convert flat declaration to grouped structures
  const digest: PrivateFormField[] = [];
  let layouts: PrivateFormField[] = []; // layouts are array of 3-column groups, it either has a array of TITLE or of fields
  let group: PrivateFormField[] = []; // one 3-column layout section
  let column: PrivateFormField[] = []; // column or two-columns within a group
  let isWithinColumn = false;
  let columnWhenClause: boolean = undefined;
  fields?.map((field) => {
    // handle fields that changes sections/groups
    switch (field.type) {
      case FormFieldType.SECTION_START:
      case FormFieldType.COLUMN_START:
        if (column.length) {
          group.push({ type: PrivateFieldType.COLUMN, group: column, when: columnWhenClause }); // push any previous column data
        }
        isWithinColumn = true;
        columnWhenClause = field.when;
        return;
      case FormFieldType.SECTION_END:
      case FormFieldType.COLUMN_END:
        group.push({
          type: field.type === FormFieldType.SECTION_END ? PrivateFieldType.SECTION : PrivateFieldType.COLUMN,
          group: column,
          when: columnWhenClause,
        });
        column = [];
        isWithinColumn = false;
        columnWhenClause = undefined;
        return;
      case FormFieldType.LINE_BREAK:
        if (group.length > 0) {
          group[0].breakBefore = true;
        }
        layouts.push({ type: PrivateFieldType.GROUP, group: group, breakBefore: true }); // FIXME to implement in CSS when under GRID layout
        group = [];
        return;
      case FormFieldType.TITLE:
        if (group.length) {
          layouts.push({ type: PrivateFieldType.GROUP, group: group });
          group = [];
        }
        if (layouts.length) {
          if (digest.length && digest[digest.length - 1].group !== layouts) {
            digest.push({ type: PrivateFieldType.GROUP, group: layouts });
          } else if (digest.length === 0) {
            digest.push({ type: PrivateFieldType.GROUP, group: layouts });
          }
          layouts = [];
        }
        digest.push({ ...field, group: layouts }); // start a new layout with TITLE element
        return;
      default:
      // fall through below for other normal fields types
    }
    // end one loop
    if (isWithinColumn) {
      column.push(field);
    } else {
      group.push(field);
    }
  });
  if (isWithinColumn) {
    console.warn('missing column end');
  }
  // close up the last open groups & layout
  if (group.length && layouts.length === 0 && digest.length === 0) {
    // just printing plain fields, no sections
    if (layout === FormLayout.PLAIN_FIELDS) {
      digest.push(...group);
    } else {
      digest.push({ type: PrivateFieldType.GROUP, group: group });
    }
    group = [];
  } else if (group.length) {
    layouts.push({ type: PrivateFieldType.GROUP, group: group });
  }
  // if last filled layout was not added, add it
  if (layouts.length && digest.length && digest[digest.length - 1].group !== layouts) {
    digest.push({ type: PrivateFieldType.GROUP, group: layouts });
    layouts = [];
  } else if (layouts.length && digest.length === 0) {
    digest.splice(0, 0, ...layouts);
    layouts = [];
  }

  return <>{digest && digest.map((field) => printField(field))}</>;
};
