import { Dialog } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { constant } from 'src/js/constant';

import { AccountAddForm } from './account-add-form';
import { AccountFormValues } from './account-form';
import { AccountViewModel, mapAccountViewModel } from './account-models';

interface Props {
  onApply: (model: AccountFormValues) => void;
  onCancel: VoidFunction;
  addButtonState: ButtonStateType;
}

export const AddAccountModal: React.FunctionComponent<Props> = ({ onApply, onCancel, addButtonState }) => {
  const { t } = useTranslation();
  const [formData] = useState<AccountViewModel>(mapAccountViewModel(null, t));

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('security.accounts.modal.add.title')}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="User"
      content={
        <AccountAddForm
          onSubmit={onApply}
          viewModel={formData}
          onCancel={onCancel}
          addButtonState={addButtonState}
          translator={t}
        />
      }
    />
  );
};
