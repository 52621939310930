import { Button, DynamicContainer, Form, FormContext, FormGroup, FormLabel, FormSection } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { FormCheckbox } from 'src/js/component/base/form/form-checkbox';
import { FormSelect } from 'src/js/component/base/form/form-select';
import { FormSwitch } from 'src/js/component/base/form/form-switch';
import { FormTextfield } from 'src/js/component/base/form/form-textfield';
import { useStores } from 'src/js/hook/use-stores';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { IsSavedRef, RebootIsSaved } from 'src/js/reboot/reboot-is-saved';
import { RebootNeeded, RebootNeededRef } from 'src/js/reboot/reboot-needed';
import { SecurityTabContent } from 'src/js/pages/security/security-tab-content';
import { idleAutoLogoutManager } from 'src/js/util/idle-auto-logout-manager';
import { validateFormContext } from 'src/js/validator/validator';
import useSWR from 'swr';

import { Compliance, PasswordQuality, policiesValidationSchema } from './policies-models';

export const Policies: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { sessionStore } = useStores();
  const formRef = useRef();

  const rebootNeededRef = useRef<RebootNeededRef>(null);
  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);

  const fetcher = (url: string) => {
    return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
      return res?.data;
    });
  };

  const { data, mutate } = useSWR(APIPath.policies, fetcher, { revalidateOnFocus: false });
  const shouldReloadData = (): void => {
    mutate(data);
  };

  // The reboot handling for the reboot button
  const childRef = useRef<IsSavedRef>(null);
  const handleReboot = (): void => {
    childRef.current.show();
  };

  const handleSubmit = (values: any) => {
    setButtonState('pending');
    return apiClientWithoutHandler.genericController
      .put(APIPath.policies, values)
      .then((res: any) => {
        notificationHandler(
          res,
          t('security.policies.notifications.updateSuccessMsg'),
          t('security.policies.notifications.updateErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        setButtonState(undefined);
        if (res.ok) {
          shouldReloadData();
          rebootNeededRef.current.show();
          idleAutoLogoutManager.stopMonitoring();
          idleAutoLogoutManager.startMonitoring();
        }
        return res;
      });
  };

  return (
    <SecurityTabContent name={t('security.policies.title')}>
      <div id="policies">
        {data && (
          <Form
            initialValues={data}
            defaultValidation={true}
            handleSubmit={handleSubmit}
            restValidationProps={{
              enableReinitialize: true,
              validationSchema: policiesValidationSchema(t),
              innerRef: formRef,
            }}
          >
            <FormContext.Consumer>
              {(formContext: any) => {
                const values = formContext.values;
                return (
                  <>
                    <div className="buttonRow">
                      {sessionStore.isAdmin() && (
                        <Button variant="secondary" onClick={handleReboot}>
                          {t('general.reboot')}
                        </Button>
                      )}
                      <Button
                        variant="primary"
                        onClick={() => validateFormContext(formContext, t)}
                        disabled={!formContext.dirty}
                        state={buttonState}
                      >
                        {t('general.apply')}
                      </Button>
                    </div>

                    <FormSection className="hai-mb-6" title={t('security.policies.section.passwordPolicies')}>
                      <DynamicContainer minColumns={3}>
                        <FormTextfield
                          name="minLen"
                          mask
                          title={t('security.policies.form.minLen')}
                          hint={t('security.policies.form.minLenExplain')}
                          type="number"
                          required
                        />
                        <FormSelect
                          name="quality"
                          title={t('security.policies.form.quality')}
                          options={Object.values(PasswordQuality).map((quality: string) => ({
                            value: quality,
                            label: t(`security.policies.enum.quality.${quality}`),
                          }))}
                        />
                      </DynamicContainer>
                      <DynamicContainer minColumns={3} className="hai-mb-4">
                        <FormCheckbox
                          name="expiration"
                          label={t('security.policies.form.expiration')}
                          container={false}
                        />
                        {values.quality === PasswordQuality.Strong && (
                          <FormGroup>
                            <FormLabel className="hai-mt-panel-checkbox-label-align">
                              {t('security.policies.form.strongRequirements')}
                            </FormLabel>
                          </FormGroup>
                        )}
                      </DynamicContainer>
                      <DynamicContainer minColumns={3}>
                        {values.expiration && (
                          <FormTextfield
                            name="expirationDays"
                            mask
                            title={t('security.policies.form.expirationDays')}
                            hint={t('security.policies.form.expirationDaysExplain')}
                            type="number"
                            required
                          />
                        )}
                        {!values.expiration && values.quality === PasswordQuality.Strong && <div />}
                        {values.quality === PasswordQuality.Strong && (
                          <>
                            <FormTextfield
                              name="uppercase"
                              mask
                              title={t('security.policies.form.uppercase')}
                              hint={t('security.policies.form.uppercaseExplain')}
                              type="number"
                              required
                            />
                            <FormTextfield
                              name="digits"
                              mask
                              title={t('security.policies.form.digits')}
                              hint={t('security.policies.form.digitsExplain')}
                              type="number"
                              required
                            />
                          </>
                        )}
                      </DynamicContainer>

                      <DynamicContainer minColumns={3}>
                        <div /> {/* NEEDED */}
                        {values.quality === PasswordQuality.Strong && (
                          <>
                            <FormTextfield
                              name="symbols"
                              mask
                              title={t('security.policies.form.symbols')}
                              hint={t('security.policies.form.symbolsExplain')}
                              type="number"
                              required
                            />
                            <FormTextfield
                              name="rememberedPasswords"
                              mask
                              title={t('security.policies.form.rememberedPasswords')}
                              hint={t('security.policies.form.rememberedPasswordsExplain')}
                              type="number"
                              required
                            />
                          </>
                        )}
                      </DynamicContainer>
                      <DynamicContainer minColumns={3}>
                        <div /> {/* NEEDED */}
                        {values.quality === PasswordQuality.Strong && (
                          <FormTextfield
                            name="minimumLifetime"
                            mask
                            title={t('security.policies.form.minimumLifetime')}
                            hint={t('security.policies.form.minimumLifetimeExplain')}
                            type="number"
                            required
                          />
                        )}
                      </DynamicContainer>
                    </FormSection>
                    <FormSection className="hai-mb-6" title={t('security.policies.section.sessionsPolicies')}>
                      <DynamicContainer minColumns={3} className="hai-mb-4">
                        <FormCheckbox
                          name="autoLogout"
                          label={t('security.policies.form.autoLogout')}
                          container={false}
                        />
                        <FormCheckbox
                          name="limitPwdRetries"
                          label={t('security.policies.form.limitPwdRetries')}
                          container={false}
                        />
                      </DynamicContainer>
                      <DynamicContainer minColumns={3}>
                        {values.autoLogout && (
                          <FormTextfield
                            name="autoLogoutTime"
                            mask
                            title={t('security.policies.form.autoLogoutTime')}
                            hint={t('security.policies.form.autoLogoutTimeExplain')}
                            type="number"
                            required
                          />
                        )}
                        {!values.autoLogout && values.limitPwdRetries && <div />}
                        {values.limitPwdRetries && (
                          <>
                            <FormTextfield
                              name="limitPwdRetriesMaxAttempts"
                              mask
                              title={t('security.policies.form.limitPwdRetriesMaxAttempts')}
                              hint={t('security.policies.form.limitPwdRetriesMaxAttemptsExplain')}
                              type="number"
                              required
                            />
                            <FormTextfield
                              name="limitPwdRetriesInterval"
                              mask
                              title={t('security.policies.form.limitPwdRetriesInterval')}
                              hint={t('security.policies.form.limitPwdRetriesIntervalExplain')}
                              type="number"
                              required
                            />
                          </>
                        )}
                      </DynamicContainer>
                    </FormSection>
                    <FormSection className="hai-mb-6" title={t('security.policies.section.accountPolicies')}>
                      <DynamicContainer minColumns={3} className="hai-mb-4">
                        <FormCheckbox
                          name="inactiveLock"
                          label={t('security.policies.form.inactiveLock')}
                          container={false}
                        />
                      </DynamicContainer>
                      {values.inactiveLock && (
                        <DynamicContainer minColumns={3}>
                          <FormTextfield
                            name="inactiveLockTime"
                            mask
                            title={t('security.policies.form.inactiveLockTime')}
                            hint={t('security.policies.form.inactiveLockTimeExplain')}
                            type="number"
                            required
                          />
                        </DynamicContainer>
                      )}
                    </FormSection>
                    <FormSection className="hai-mb-6" title={t('security.policies.section.cryptoPolicies')}>
                      <DynamicContainer minColumns={3}>
                        <FormSelect
                          name="compliance"
                          title={t('security.policies.form.compliance')}
                          options={Object.values(Compliance).map((item: string) => ({
                            value: item,
                            label: t(`security.policies.enum.compliance.${item}`),
                          }))}
                        />
                      </DynamicContainer>
                      <DynamicContainer maxColumns={4}>
                        <FormSwitch
                          name="tls1_2"
                          title={t('security.policies.form.tlsVersions')}
                          switchLabel={t('security.policies.form.tls1_2')}
                        />
                        <FormSwitch name="tls1_1" switchLabel={t('security.policies.form.tls1_1')} container />
                        <FormSwitch name="tls1" switchLabel={t('security.policies.form.tls1')} container />
                        <FormSwitch
                          name="ssl3"
                          switchLabel={t('security.policies.form.ssl3')}
                          checked={values.compliance === Compliance.NONE ? values.ssl3 : false}
                          disabled={values.compliance !== Compliance.NONE}
                          container
                        />
                      </DynamicContainer>
                    </FormSection>
                    <FormSection className="hai-mb-6" title={t('security.policies.section.httpPolicies')}>
                      <DynamicContainer minColumns={3} className="hai-mb-4">
                        <FormCheckbox name="hsts" label={t('security.policies.form.hsts')} container={false} />
                      </DynamicContainer>
                    </FormSection>
                  </>
                );
              }}
            </FormContext.Consumer>
          </Form>
        )}
      </div>

      <RebootIsSaved ref={childRef} />
      <RebootNeeded ref={rebootNeededRef} />
    </SecurityTabContent>
  );
};
