import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import { FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormValue } from 'src/js/component/base/form/form-value';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { Duplex, Link, Speed, SpeedManual } from 'src/js/model/api/response/network-response';
import { NetworkFormValues } from 'src/js/pages/settings/network/form/network-form';

import { EthX } from '../network-view-model';

interface InternalProps {
  ethX: EthX;
}

export const NetworkEthernetForm = (props: InternalProps & FormikProps<NetworkFormValues>) => {
  const { t } = useTranslation();

  const { ethX } = props;
  const isLinkAutomatic = (ethX === 'eth1' ? props.values.eth1.link : props.values.eth0.link) === Link.AUTO;
  const disabled = ethX === 'eth1' && props.values.isEth1Sfp;

  return (
    <Form>
      <FormSection>
        <DynamicContainer minColumns={3}>
          <FormikSelect
            name={ethX + '.link'}
            title={t('settings.network.form.link')}
            selectValues={Object.values(Link)}
            selectLabels={Object.values(Link).map((value: string) => t(`settings.network.link.${value}`))}
            disabled={disabled}
          />
          {isLinkAutomatic ? (
            <FormikSelect
              name={ethX + '.speed'}
              title={t('settings.network.form.speed')}
              selectValues={Object.values(Speed)}
              selectLabels={Object.values(Speed).map((value: string) => t(`settings.network.speed.${value}`))}
              disabled={disabled}
            />
          ) : (
            <FormikSelect
              name={ethX + '.speed'}
              title={t('settings.network.form.speed')}
              selectValues={Object.values(SpeedManual)}
              selectLabels={Object.values(SpeedManual).map((value: string) => t(`settings.network.speed.${value}`))}
              disabled={disabled}
            />
          )}
          <FormValue label={t('settings.network.form.currentSpeed')} value={props.values[ethX].currentSpeed} />
        </DynamicContainer>
        <DynamicContainer minColumns={3}>
          <FormikSelect
            name={ethX + '.duplex'}
            title={t('settings.network.form.duplex')}
            selectValues={Object.values(Duplex)}
            selectLabels={Object.values(Duplex).map((value: string) => t(`settings.network.duplex.${value}`))}
            disabled={disabled || isLinkAutomatic}
          />
          <FormValue label={t('settings.network.form.macAddress')} mask value={props.values[ethX].macAddress} />
        </DynamicContainer>
      </FormSection>
    </Form>
  );
};
