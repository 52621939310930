import { ProductName } from '@hai/ui-react/dist/hook';

export const emptyArray: string[] = [];

export const constant = {
  defaultRefreshInterval: 5000,
  defaultRefreshIntervals: ['00:00:05', '00:00:30', '00:01:00'],
  emptyArray: emptyArray,
  isMissionCritical: true,
  productName: ProductName.MAKITO_X4_DECODER,
  productColor: '#30cbd1',
  productColorClass: 'aqua-svg',
  headerSeparator: '', // TODO put back '>' when we have a functional collapsing mechanism
  stateButtonWaitTime: 500, // default wait time for a state button to show the success/error
  maxNumDecoders: 4, // max amount of decoders, might be lower per license
  maxNumOutput: 4, // amount of SDI outputs
  addMessageEvent: 'add-message',
  clearAllPermanentNotificationEvent: 'clear-all-permanent-notifications',
  clearAllNotificationEvent: 'clear-all-notifications',
  newModelId: -1,
  firmware: {
    fileTypes: ['.hai'],
    patternRegex: /makitox4_dec_v\d+.\d+.\d+-\d+.hai/,
  },
  dateTimePickerFormat: 'MMM Do YYYY h:mm A',
  momentFormat: {
    api: 'l',
    dateTime: 'l LT',
    presetDateTime: 'lll',
    status: 'l',
    time: 'HH:mm',
    timeWithSeconds: 'HH:mm:ss',
  },
  obfuscatedPassphrase: '••••••••••' /* utf8 dots */,
  lStoragePrefix: 'mx4d:',
  lStorage: {
    bandwidthInterval: 'mx4d:bandwidthInterval',
    bandwidthInactiveFields: 'mx4d:bandwidthInactiveFields',
    brightness: 'mx4d:brightness',
    contrast: 'mx4d:contrast',
    loginRememberMe: 'mx4d:loginRememberMe',
    loginUsername: 'mx4d:loginRememberMe',
    presetLoadTable: 'mx4d:presetLoadTable',
    presetTable: 'mx4d:presetTable',
    refreshIntervalStats: 'mx4d:refreshIntervalStats',
    securityTab: 'mx4d:securityTab',
    settingsTab: 'mx4d:settingsTab',
    showAudioMeters: 'mx4d:showAudioMeters',
    showUserAnalytics: 'mx4d:showUserAnalytics',
    sidebarCollapsed: 'mx4d:sidebarCollapsed',
    streamingTab: 'mx4d:streamingTab',
    tabAction: 'mx4d:tabAction',
  },
  makitoX4Haivision: 'Haivision Makito X4 Decoder',
  presetFileTypes: ['.cfg'],
  pubkeysFileTypes: ['.pub'],
  bannerFileTypes: ['.txt'],
  licenseFileTypes: ['.lic'],
  stillImageFileTypes: ['.png', '.jpg'],
  chart: {
    decoder: {
      frameBarGraphFields: ['Skipped', 'Replayed', 'Displayed'],
    },
    stream: {
      defaultFieldsRate: ['LostRate', 'AvailBw'],
      defaultFieldsDelay: ['RTT', 'PdDelay'],
      defaultInactiveRateFields: ['AvailBw', 'AvailBw_Path2'],
      firstFields: ['RecvBufs', 'RTT', 'PdDelay'],
      firstFieldsDashboard: ['RecvBufs', 'RTT'],
      secondFields: ['RecvRate', 'LostRate', 'AvailBw'],
      secondFieldsDashboard: ['RecvRate', 'LostRate'],
      genericFields: ['RecvRate'],
    },
  },
  defaultCSVFileName: 'CSV-Data',
  onlineDocURL: 'https://doc.haivision.com',
  onlineCheckURL: 'https://doc.haivision.com/favicon.ico',
  barGraphGroupWidthSec: 20,
  collapseTime: 300,
  graphRefreshRateMs: 5000,
  dashboardGraphRefreshRateMs: 10000,
  dashboardBarGraphRefreshRateMs: 10000,
  presetRefreshRateInSeconds: 30,
};
