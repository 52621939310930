import { StreamListExpansionStore } from 'src/js/store/stream-list-expansion-store';
import { PreferenceStore } from 'src/js/store/preference-store';
import { SessionStore } from 'src/js/store/session-store';
import { SidebarStore } from 'src/js/store/sidebar-store';

/**
 * List ALL the stores here.
 */
const sessionStore: SessionStore = new SessionStore();
const sidebarStore: SidebarStore = new SidebarStore();
const preferenceStore: PreferenceStore = new PreferenceStore();
const streamExpansionStore: StreamListExpansionStore = new StreamListExpansionStore();

/**
 * Export ALL persisted stores here.
 */
export const persistedStores = {
  sessionStore,
  preferenceStore,
  streamExpansionStore,
  sidebarStore,
};

/**
 * Export ALL the stores here.
 */
export const globalStores = {
  ...persistedStores,
};
