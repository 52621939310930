import React, { ReactNode } from 'react';
import { useMutable } from 'src/js/hook/use-mutable';
import { ListSelectionWithDataStore2 } from 'src/js/store/list-selection-with-data-store2';

const storeContext = React.createContext(null);

interface Props<T> {
  render?: (store: ListSelectionWithDataStore2<T>) => ReactNode;
  children: ReactNode;
}

export const ListSelectionWithDataStoreProvider2 = <T extends object>(props: Props<T>) => {
  const { render, children } = props;
  const [store] = useMutable<ListSelectionWithDataStore2<T>>(new ListSelectionWithDataStore2<T>());
  if (render) {
    return <storeContext.Provider value={store()}>{render(store())}</storeContext.Provider>;
  }
  return <storeContext.Provider value={store()}>{children}</storeContext.Provider>;
};

export const useListSelectionWithDataStore2 = <T extends object>(): ListSelectionWithDataStore2<T> => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};
