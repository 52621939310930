import { Button, Dialog, Form, Icon } from '@hai/ui-react';
import { FileUploadStages, IUploadState } from '@hai/ui-react/dist/types';
import { isEmpty, isNil } from 'ramda';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { UploadZone } from 'src/js/component/base/upload-zone';
import { NullifierNetworkErrorHandler } from 'src/js/controller/network-error-handler';
import { triggerEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useErrorReaction, useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { DeleteFirmwareResponse } from 'src/js/model/api/response/delete-firmware-response';
import {
  MonitorFirmwareUploadResponse,
  MonitorFirmwareUploadState,
} from 'src/js/model/api/response/monitor-firmware-upload-response';
import { RebootResponse } from 'src/js/model/api/response/reboot-response';
import { UploadFirmwareResponse } from 'src/js/model/api/response/upload-firmware-response';
import { Paths } from 'src/js/route';
import { createRebootTask } from 'src/js/task/settings-tasks';
import {
  createDeleteFirmwareTask,
  createMonitorFirmwareUploadTask,
  createUploadFirmwareTask,
} from 'src/js/task/settings-upgrade-tasks';
import { constant } from 'src/js/constant';

import { UpdateCancelConfirmModal } from './cancel-confirm-modal';
import { CurrentVersionViewModel } from './current-version-view-model';

interface CompleteProps {
  uploadStep: MonitorFirmwareUploadState;
  packageReady: boolean;
  fileUploaded: boolean;
  errorMessage: string;
}

const UploadComplete = (props: CompleteProps) => {
  const { uploadStep, packageReady, fileUploaded, errorMessage } = props;
  const { t } = useTranslation();

  return (
    <div className="upload-complete-container d-flex flex-column align-items-center justify-content-center">
      {fileUploaded || packageReady ? (
        <Icon iconname={'StatusOK'} size="md" />
      ) : uploadStep === MonitorFirmwareUploadState.ERROR ? (
        <Icon iconname={'StatusError'} size="md" />
      ) : (
        <Icon
          className={constant.isMissionCritical ? 'aqua-svg' : 'purple-svg'}
          iconname={'StatusLoading'}
          size="md"
          spinning={true}
        />
      )}
      <div className="mk-upload-zone-text haiui-body-02 hai-gray-08 hai-mt-2">
        {fileUploaded || packageReady
          ? t('settings.upgrade.modal.reboot')
          : uploadStep === MonitorFirmwareUploadState.ERROR
          ? errorMessage
          : t(`settings.upgrade.modal.uploadingSubTitle.step${uploadStep}`)}
      </div>
    </div>
  );
};

interface Props {
  data: CurrentVersionViewModel;
  onCancel: VoidFunction;
  packageReady: boolean;
  setPackageReady: React.Dispatch<React.SetStateAction<boolean>>;
  fileUploaded: boolean;
  setFileUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateSoftwareModal = (props: Props) => {
  const { data, onCancel, packageReady, setPackageReady, fileUploaded, setFileUploaded } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [file, setFile] = useState<File>(undefined);
  const [cancelShown, showCancel, hideCancel] = useModalControls(false);
  const [startUpload, setStartUpload] = useState<boolean>(false);
  const [uploadState, setUploadState] = useState<IUploadState>({
    stage: FileUploadStages.NONE,
    progress: 0,
  });

  const rebootTask = useTask(createRebootTask());
  const cancelTask = useTask(createDeleteFirmwareTask());

  useSuccessReaction(rebootTask, (_result: RebootResponse) => {
    navigate(Paths.rebootingUpgrade);
  });

  useSuccessReaction(cancelTask, (_result: DeleteFirmwareResponse) => {
    setFile(undefined);
    setFileUploaded(false);
    setPackageReady(false);
    setStartUpload(false);
    setUploadState({ stage: FileUploadStages.NONE, progress: 0 });
  });

  const handleOnCancel = (): void => {
    if (!isNil(file)) {
      cancelTask(file.name);
      setFileUploaded(false);
      setPackageReady(false);
    }
    onCancel();
  };

  const handleOnReboot = (): void => {
    rebootTask();
  };

  const handleOnRebootLater = (): void => {
    setFileUploaded(false);
    setPackageReady(false);
    if (!isNil(file)) {
      data.packageToBeInstalled = file.name;
    }
    onCancel();
  };

  const [monitoring, setMonitoring] = useState<boolean>(true);
  const [uploadStep, setUploadStep] = useState<MonitorFirmwareUploadState>(MonitorFirmwareUploadState.UPLOADING);

  // We don't want to handle the errors here because the backend returns 403 at the end of the upload but it's still valid...
  const monitorUploadTask = useTask(createMonitorFirmwareUploadTask(undefined, new NullifierNetworkErrorHandler()));

  const handleOnRetry = () => {
    if (!isNil(file)) {
      cancelTask(file?.name);
      setFileUploaded(false);
      setFile(undefined);
      setUploadState({
        stage: FileUploadStages.NONE,
        progress: 0,
      });
      setUploadStep(MonitorFirmwareUploadState.UPLOADING);
    }
  };

  const uploadFirmwareTask = useTask(createUploadFirmwareTask());

  useEffect(() => {
    if (startUpload) {
      setUploadStep(MonitorFirmwareUploadState.UPLOADING);
      setMonitoring(true);
      apiClientWithoutHandler.genericController.post(APIPath.upgrade.notify, {}).then((_res: any) => {
        uploadFirmwareTask(file, (e: any) => {
          const toProgress = (e.loaded * 100) / e.total;
          setUploadState((prev: IUploadState) => {
            return {
              ...prev,
              stage: FileUploadStages.INPROGRESS,
              progress: Math.round(toProgress),
            };
          });
          if (e.loaded === e.total) {
            setUploadStep(MonitorFirmwareUploadState.UNPACKING);
          }
        });
      });

      // give some time for the file to be there
      setTimeout(() => {
        monitorUploadTask(file.name);
      }, 1000);
    }
  }, [startUpload]);

  const onSuccessUpload = () => {
    triggerEvent('ui-enable');
    setFileUploaded(true);
    setUploadState((prev: IUploadState) => {
      return { ...prev, stage: FileUploadStages.COMPLETED };
    });
  };

  const [errorMessage, setErrorMessage] = useState<string>();
  const onErrorUpload = (message?: string) => {
    triggerEvent('ui-enable');
    setStartUpload(false);
    setUploadStep(MonitorFirmwareUploadState.ERROR);
    const prefix = 'Error: ';
    if (message?.startsWith(prefix)) {
      message = message.substring(prefix.length);
    }
    setErrorMessage(message);
  };

  useSuccessReaction(
    monitorUploadTask,
    (result: MonitorFirmwareUploadResponse) => {
      if (
        result.state === MonitorFirmwareUploadState.UPLOADING ||
        result.state === MonitorFirmwareUploadState.UNPACKING
      ) {
        // state 1 copying
        // state 2 verifying
        setUploadStep(MonitorFirmwareUploadState.UNPACKING);
      } else if (result.state === MonitorFirmwareUploadState.SYNCING) {
        setUploadStep(result.state);
        setMonitoring(false);
      }

      if (monitoring) {
        setTimeout(() => {
          monitorUploadTask(file.name);
        }, 1000);
      }
    },
    {},
    [file, monitoring],
  );

  useErrorReaction(
    monitorUploadTask,
    () => {
      // maybe the file was not ready, just retry
      if (monitoring) {
        setTimeout(() => {
          monitorUploadTask(file.name);
        }, 1000);
      }
    },
    {},
    [file, monitoring],
  );

  useSuccessReaction(uploadFirmwareTask, (result: UploadFirmwareResponse) => {
    if (result.error) {
      onErrorUpload(result.message);
      setMonitoring(false);
    } else {
      // go to step 2
      setUploadStep(MonitorFirmwareUploadState.UNPACKING);
      setUploadState((prev: IUploadState) => {
        return { ...prev, stage: FileUploadStages.INPROGRESS, progress: 100 };
      });

      const path = APIPath.upgrade.install;
      apiClientWithoutHandler.genericController.post(path).then((res: any) => {
        setMonitoring(false);

        if (res.ok) {
          if (res.data?.error) {
            onErrorUpload(res.data?.message ?? t('settings.upgrade.modal.failedInstallStep'));
            return res;
          }
          onSuccessUpload();
        } else {
          onErrorUpload(res.data?.message ?? t('settings.upgrade.modal.failedInstallStep'));
        }
        return res;
      });
    }
  });

  useErrorReaction(uploadFirmwareTask, () => {
    onErrorUpload();
  });

  const handleFileChange = (files: File[]) => {
    setFile(isEmpty(files) ? undefined : files[0]);
  };

  const handleUploadFile = () => {
    setStartUpload(true);
  };

  const getHint = () => {
    switch (uploadState.stage) {
      case FileUploadStages.NONE:
        return packageReady ? getSuccessMessage() : t('settings.upgrade.modal.uploadHai');

      case FileUploadStages.INPROGRESS:
        return t(`settings.upgrade.modal.uploadingTitle.step${uploadStep}`, {
          fileName: file.name,
        });

      case FileUploadStages.COMPLETED:
        return getSuccessMessage();
    }
    return '';
  };

  const getSuccessMessage = () => {
    return !isNil(data.packageToBeInstalled) || file?.name
      ? t('settings.upgrade.modal.uploadCompleted', {
          fileName: data.packageToBeInstalled || file.name,
        })
      : '';
  };

  const getUploadNotice = () => {
    const message = startUpload
      ? uploadState.stage === FileUploadStages.NONE
        ? t('settings.upgrade.modal.uploadNotice.stopping')
        : ''
      : t('settings.upgrade.modal.uploadNotice.willStop');

    return !isEmpty(message) ? (
      <>
        <div>
          <Icon iconname="StatusWarning" className="hai-mr-3" size="sm2" />
        </div>
        <div className="haiui-body-03 hai-gray-08">{message}</div>
      </>
    ) : (
      <></>
    );
  };

  const content = (): ReactNode => {
    return (
      <Form>
        <>
          <div className="haiui-label-01-med hai-gray-08 hai-mb-4">{getHint()}</div>
          {uploadStep === MonitorFirmwareUploadState.UPLOADING && !fileUploaded ? (
            <UploadZone
              accept={{ '': constant.firmware.fileTypes }}
              browseMessage={packageReady ? '' : undefined}
              conjunction={packageReady ? '' : undefined}
              file={file}
              icon="UpdateSoftware"
              uploadState={uploadState}
              onFileChange={handleFileChange}
            />
          ) : (
            <UploadComplete
              uploadStep={uploadStep}
              packageReady={packageReady}
              fileUploaded={fileUploaded}
              errorMessage={errorMessage}
            />
          )}
          <div className="d-flex hai-mt-6">
            {packageReady || fileUploaded ? (
              <div className="d-flex flex-equal justify-content-between hai-mt-4">
                <div className="d-flex align-items-center align-items-center">
                  <Button className="hai-mr-6" variant="ghost" onClick={showCancel}>
                    {t('general.cancel')}
                  </Button>
                </div>
                <div className="d-flex justify-content-end">
                  <Button className="hai-mr-6" variant="secondary" onClick={handleOnRebootLater}>
                    {t('settings.upgrade.modal.rebootLater')}
                  </Button>
                  <Button variant="primary" onClick={handleOnReboot}>
                    {t('general.reboot')}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-equal justify-content-between hai-mt-4">
                <div className="d-flex flex-equal align-items-center align-items-center">{getUploadNotice()}</div>
                <div className="d-flex flex-equal justify-content-end">
                  <Button
                    className="hai-mr-6"
                    variant="secondary"
                    onClick={handleOnCancel}
                    disabled={
                      uploadStep === MonitorFirmwareUploadState.UNPACKING ||
                      uploadStep === MonitorFirmwareUploadState.SYNCING
                    }
                  >
                    {t('general.cancel')}
                  </Button>
                  {uploadStep === MonitorFirmwareUploadState.ERROR ? (
                    <Button variant="primary" onClick={handleOnRetry}>
                      {t('general.retry')}
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={handleUploadFile} disabled={isNil(file) || startUpload}>
                      {t('general.upload')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          {cancelShown && (
            <UpdateCancelConfirmModal fileName={file.name} onCancel={hideCancel} onApply={handleOnCancel} />
          )}
        </>
      </Form>
    );
  };

  return (
    <Dialog
      className="update-software-modal"
      accentColor={constant.productColor}
      title={t('settings.upgrade.modal.title')}
      show={true}
      dialogType="activity"
      headerIcon="UpdateSoftware"
      size="lg"
      content={content()}
    />
  );
};
