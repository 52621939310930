import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { GenericResponse } from 'src/js/model/api/response/generic-response';
import { createWatchStore, setProperty, updateWatchStore } from 'src/js/store/use-watch';
import { isNilOrEmpty } from 'src/js/util/global-util';

export interface SystemInfo {
  analytics: string;
  audioDecoders: number;
  capabilities: number;
  COMPorts: number;
  deviceIp: string;
  deviceName: string;
  features: number;
  hasEth1: boolean;
  hasHDR: boolean;
  hasSrtRedundancy: boolean;
  hasSrtStreamId: boolean;
  hevc: boolean;
  hostname: string;
  licensed: boolean;
  rugged: boolean;
  upgradePending: boolean;
  version: string;
  videoDecoders: number;
}

// values will be inited from API at runtime
const systemInfo: SystemInfo = {
  analytics: '',
  audioDecoders: 0,
  capabilities: 0,
  COMPorts: 0,
  deviceIp: '',
  deviceName: '',
  features: 0,
  hasEth1: false,
  hasHDR: false,
  hasSrtRedundancy: false,
  hasSrtStreamId: false,
  hevc: false,
  hostname: '',
  licensed: false,
  rugged: false,
  upgradePending: false,
  version: '',
  videoDecoders: 0,
};

export const isBitSet = (value: number, bit: number) => (value & (1 << bit)) !== 0;

export const systemInfoCapabilities = {
  hasAnalytics: () => systemInfo.analytics === 'accepted',
  hasHDR: () => systemInfo.hasHDR,
  hasSRT: () => isBitSet(systemInfo.features, 2), // SMGR_FEATURE_SRT_STREAMING
  hasSrtRedundancy: () => isBitSet(systemInfo.features, 2) && !!systemInfo.hasSrtRedundancy, // SMGR_FEATURE_SRT_STREAMING
  isIPv4: () => isBitSet(systemInfo.capabilities, 11),
  isIPv6: () => isBitSet(systemInfo.capabilities, 12),
};

createWatchStore(systemInfo);

const setSystemInfoProperty = (key: string, value: any) => {
  setProperty(systemInfo, key as keyof typeof systemInfo, value);
};
export { setSystemInfoProperty };
export const saveResponseToSystemInfo = (response: SystemInfo) => {
  if (isNilOrEmpty(response.deviceName)) {
    response.deviceName = response.hostname;
  }
  updateWatchStore(systemInfo, response);
};

interface SystemInfoResponse extends GenericResponse, SystemInfo {}

export const fetchSystemInfo = () => {
  return apiClientWithoutHandler.genericController.get<SystemInfoResponse>(APIPath.systemInfo).then((res) => {
    if (res.ok) {
      const response = res.data as SystemInfo;
      if (response) {
        saveResponseToSystemInfo(response);
      }
    }
    return res;
  });
};

export const updateSystemInfo = (model: SystemInfo) => {
  return apiClientWithoutHandler.genericController.put<SystemInfoResponse>(APIPath.systemInfo, model).then((res) => {
    if (res.ok) {
      const response = res.data as SystemInfo;
      if (response) {
        saveResponseToSystemInfo(response);
      }
    }
    return res;
  });
};

export default systemInfo;
