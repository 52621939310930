import React, { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject } from 'rxjs';
import { MultipleFormAggregator, MultipleFormAggregatorRef } from 'src/js/component/multiple-form-aggregator';
import { useMutable } from 'src/js/hook/use-mutable';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { DecoderList } from 'src/js/pages/dashboard/decoder-list';
import { useWatchObject } from 'src/js/store/use-watch';

import decodersModels, { decodersStore } from 'src/js/pages/streaming/decoder/decoder-store';

interface Props {}
export interface DecodersDashboardContainerRef {
  submitApplyAllDecoders: () => Promise<any>;
  cancelChanges: VoidFunction;
}
export const DecodersDashboardContainer = forwardRef((_props: Props, ref: Ref<DecodersDashboardContainerRef>) => {
  const { t } = useTranslation();

  const [onClickGlobalSave] = useMutable<Subject<string>>(new Subject());
  const [decoders, setDecoders] = useState(decodersModels);

  const formAggregatorRef = useRef<MultipleFormAggregatorRef>(null);
  useWatchObject(decodersModels, setDecoders); // ? what if user has changes

  const cancelChanges = () => {
    decodersStore.reset();
    onClickGlobalSave().next('cancel');
  };

  const submitApplyAllDecoders = () => {
    if (formAggregatorRef.current.areAllClientsValid() === false) {
      // invalid alert
      createAndDispatchNotification(t('decoder.notifications.invalid'), NotificationVariant.ERROR, t);
      const promise = new Promise((resolve, _reject) => {
        resolve(null);
        return;
      });
      return promise;
    }
    // Send a message to all subscribers, which are the 4 Decoder Panels.
    // Build up SDI dependancy list
    // tag(ORDER-SAVE)
    onClickGlobalSave().next('prep');
    const promise = new Promise((resolve, _reject) => {
      resolve(null); // FIXME
      return;
    });
    return promise;
  };

  useImperativeHandle(ref, () => ({
    submitApplyAllDecoders,
    cancelChanges,
  }));

  return (
    <div id="decoders">
      <MultipleFormAggregator ref={formAggregatorRef} />
      <DecoderList onClickGlobalSave={onClickGlobalSave()} formAggregator={formAggregatorRef.current} data={decoders} />
    </div>
  );
});

DecodersDashboardContainer.displayName = 'DecodersDashboardContainer';
