import { action, observable } from 'mobx';

export class SessionStore {
  @observable
  authenticated = false;
  @observable
  username = '';
  @observable
  gid = 0;

  @action
  login(username: string, gid: number) {
    this.authenticated = true;
    this.username = username;
    this.gid = gid;
  }

  @action
  logout() {
    this.authenticated = false;
    this.username = '';
  }

  isAdmin() {
    return this.gid === 511;
  }

  isOperator() {
    return this.gid === 512;
  }

  isUser() {
    return this.gid === 513;
  }

  getRole() {
    if (this.gid === 511) {
      return 'Administrator';
    } else if (this.gid === 512) {
      return 'Operator';
    } else if (this.gid === 513) {
      return 'Guest';
    }
    return '';
  }
}
