import { action, computed, IObservableArray, observable } from 'mobx';
import append from 'ramda/src/append';
import includes from 'ramda/src/includes';
import range from 'ramda/src/range';
import without from 'ramda/src/without';

/**
 * Generic store to use when there is a component with a table that can be selected
 * Allows multiple selection, toggling.
 *
 * Use with ListSelectionStoreProvider to add in a flow
 */
export class ListSelectionStore {
  @observable
  private rowsCount = 0;
  @observable
  private selectedRowIndexes: IObservableArray<number> = observable.array();

  @computed get rowsSelected(): boolean {
    return this.selectedRowIndexes.length > 0;
  }

  @computed get allRowsSelected(): boolean {
    // We can't select anything if there's no rows
    if (this.rowsCount === 0) {
      return false;
    }
    return this.rowsCount === this.selectedRowIndexes.length;
  }

  isSelected(index: number): boolean {
    return includes(index, this.selectedRowIndexes);
  }

  @action
  setTotalCount(count: number) {
    this.rowsCount = count;
  }

  @action
  selectRow(index: number) {
    if (index < 0 || index >= this.rowsCount) {
      throw Error('`index` is out of bounds');
    }
    if (!includes(index, this.selectedRowIndexes)) {
      this.selectedRowIndexes = observable.array(append(index, this.selectedRowIndexes));
    }
  }

  @action
  unselectRow(index: number) {
    this.selectedRowIndexes = observable.array(without([index], this.selectedRowIndexes));
  }

  @action
  toggleRow(index: number) {
    includes(index, this.selectedRowIndexes) ? this.unselectRow(index) : this.selectRow(index);
  }

  @action
  toggleSelectAll() {
    this.allRowsSelected ? this.unselectAll() : this.selectAll();
  }

  @action
  selectAll() {
    this.selectedRowIndexes = observable.array(range(0, this.rowsCount));
  }

  @action
  unselectAll() {
    this.selectedRowIndexes = observable.array();
  }
}
