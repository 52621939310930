//import { ButtonToggleGroup, LabelMicroMed } from '@hai/ui-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { mapFrameSkipStatisticToBarData } from 'src/js/pages/statistic/statistic-mapper';
import { BarGraph } from '../statistic/plot/bar-graph';
import { DecoderFrameGraphResponse } from '../streaming/decoder/decoder-model';
import { GraphCtx } from './decoder-item-graphs';

interface ChartProps {
  id?: string | number;
  graphData?: DecoderFrameGraphResponse[];
  yTitle: string;
  fields: string[];
  inactiveFields?: string[];
  lineColors?: string[];
}

export const DashboardBarGraph = (props: ChartProps) => {
  const { graphData, yTitle, fields, inactiveFields, lineColors } = props;
  const { t } = useTranslation();
  const { fontSize, width, height } = useContext(GraphCtx);

  return (
    <BarGraph
      id={props.id}
      yTitle={yTitle}
      yTickCount={2}
      fontSize={fontSize}
      data={graphData ? mapFrameSkipStatisticToBarData(graphData, fields, t, false, 'csvValuesDashboard') : []}
      defaultInactiveFields={inactiveFields}
      timescale={StatsInterval.LAST_5_MINS}
      xAxisMinutes={true}
      lineColors={lineColors}
      width={width}
      height={height}
      showLegend={true}
      /* yRange={[
        { limit: 9900, divideBy: 1000, label: 'Mbps' },
        { limit: 9900000, divideBy: 1000000, label: 'Gbps' },
      ]}*/
    />
  );
};
