import { TFunction } from 'i18next';
import { isNil } from 'ramda';
import { UpdateAccountRequest } from 'src/js/model/api/request/change-password-request';
import { GenericResponse } from 'src/js/model/api/response/generic-response';
import * as yup from 'yup';

import { AccountFormValues } from './account-form';

export enum RoleType {
  SUPERUSER = 'Superuser',
  ADMINISTRATOR = 'Administrator',
  OPERATOR = 'Operator',
  GUEST = 'Guest',
}

export enum RoleTypeAdd {
  ADMINISTRATOR = 'Administrator',
  OPERATOR = 'Operator',
  GUEST = 'Guest',
}

export enum AccountState {
  ACTIVE = 0,
  LOCKED,
  DISABLED,
}

export interface Account {
  expiration: number;
  expirationDays: number;
  name: string;
  role: RoleType;
  state: AccountState;
}

export interface AccountKeyData {
  name: string;
  fingerprint: string;
}

export interface AccountKeys {
  accountName: string;
  data: AccountKeyData[];
}

export const accountIsDisabled = (state: AccountState): boolean => state === AccountState.DISABLED;
export const accountIsLocked = (state: AccountState): boolean => state === AccountState.LOCKED;

export const accountStateToIcon = (state: AccountState): string => {
  switch (state) {
    case AccountState.ACTIVE:
      return 'StatusOK';
    case AccountState.LOCKED:
      return 'StatusLocked';
    case AccountState.DISABLED:
      return 'StatusInactive';
  }
};

export const accountStateToName = (state: AccountState, t: TFunction): string => {
  switch (state) {
    case AccountState.ACTIVE:
      return t('security.accounts.states.enabled');
    case AccountState.LOCKED:
      return t('security.accounts.states.locked');
    case AccountState.DISABLED:
      return t('security.accounts.states.disabled');
  }
};

const accountStateToSortable = (state: AccountState): number => {
  switch (state) {
    case AccountState.ACTIVE:
      return 1;
    case AccountState.LOCKED:
      return 2;
    case AccountState.DISABLED:
      return 3;
  }
};

export const accountStateToColor = (state: AccountState): string => {
  switch (state) {
    case AccountState.ACTIVE:
      return 'haiui-green-01';
    case AccountState.LOCKED:
      return 'haiui-gray-10';
    case AccountState.DISABLED:
      return 'haiui-gray-07';
  }
};

// For ActionBar status filter
export const accountStateToStatus = (state: AccountState): string => {
  switch (state) {
    case AccountState.ACTIVE:
      return 'ACTIVE';
    case AccountState.LOCKED:
      return 'LOCKED';
    case AccountState.DISABLED:
      return 'DISABLED';
  }
};
export const accountModelToStatus = (model: AccountViewModel): string => {
  return accountStateToStatus(model.state);
};

export const accountPasswordExpiry = (expiration: number, expirationDays: number, t: TFunction): string => {
  switch (expiration) {
    case -1:
      return `${t('security.accounts.column.passwordExpiry')}: ${t('security.accounts.expiration.byAdministrator')}`;
    case 0:
      return `${t('security.accounts.column.passwordExpiry')}: ${t('security.accounts.expiration.never')}`;
    default: {
      let retMessage = '';
      const absDays = Math.abs(expirationDays);
      if (expirationDays > 0) {
        retMessage = `${t('security.accounts.column.passwordExpiry')}: ${t('security.accounts.expiration.expiring', {
          count: absDays,
        })}`;
      } else {
        retMessage = `${t('security.accounts.column.passwordExpiry')}: ${t('security.accounts.expiration.expired', {
          count: absDays,
        })}`;
      }
      return retMessage;
    }
  }
};

export interface AccountResponse extends GenericResponse {
  info: Account;
  keys?: AccountKeys;
}

export interface GetAllAccountsResponse {
  data: AccountResponse[];
}

export interface AccountViewModel extends Account {
  id: string;
  password?: string;
  passwordConfirm?: string;
  keys?: AccountKeys;
  state: AccountState;

  stateSortable: number;
  expiryTxt: string;

  status: string;
  selected: boolean /* checked by checkbox */;
  hidden: boolean /* when filtered out */;
  updated: string /* to trigger setState to detect change and re-render */;
}

export interface AccountKeyDataResponse extends GenericResponse, AccountKeys {}

export const mapAccountViewModel = (response: AccountResponse, t: TFunction): AccountViewModel => {
  if (isNil(response)) {
    return {
      id: '',
      name: '',
      role: RoleType.ADMINISTRATOR,
      state: 0,
      expiration: 0,
      expirationDays: 0,
      stateSortable: 0,
      expiryTxt: '',

      status: '',
      selected: false /* checked by checkbox */,
      hidden: false /* when filtered out */,
      updated: '',
    };
  } else {
    return {
      ...response.info,
      id: response.info.name,
      keys: { ...response.keys },
      state: response.info.state,

      stateSortable: accountStateToSortable(response.info.state),
      expiryTxt: accountPasswordExpiry(response.info.expiration, response.info.expirationDays, t),

      status: accountStateToStatus(response.info.state), // required for ActionBar
      selected: false,
      hidden: false,
      updated: new Date().toISOString(),
    };
  }
};

export const mapAccountResponse = (response: GetAllAccountsResponse, t: TFunction): AccountViewModel[] => {
  const mapped = response?.data.map((item) => mapAccountViewModel(item, t));
  return mapped;
};

export const mapChangeAccountPasswordRequest = (formValues: AccountFormValues): UpdateAccountRequest => {
  const request: UpdateAccountRequest = {
    name: formValues.name,
    oldPassword: formValues.oldPassword,
    password: formValues.password,
    passwordConfirm: formValues.passwordConfirm,
  };
  return request;
};

/*
 * Validations
 */
export const accountValidationSchema = (t: TFunction) =>
  yup.object().shape({
    oldPassword: yup.string().required(t('validation.required')),
    password: yup.string().required(t('validation.required')),
    passwordConfirm: yup
      .string()
      .required(t('validation.required'))
      .equals([yup.ref('password')], t('validation.accounts.passwordMustMatch')),
  });

export const accountValidationSchemaNoPassword = (t: TFunction) =>
  yup.object().shape({
    name: yup.string().required(t('validation.required')),
    password: yup.string().required(t('validation.required')),
    passwordConfirm: yup
      .string()
      .required(t('validation.required'))
      .equals([yup.ref('password')], t('validation.accounts.passwordMustMatch')),
  });
