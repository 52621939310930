import { Button, DynamicContainer, Form, FormContext, FormSection } from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import { FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { FormSelect } from 'src/js/component/base/form/form-select';
import { FormSwitch } from 'src/js/component/base/form/form-switch';
import { FormTextfield } from 'src/js/component/base/form/form-textfield';
import { useStores } from 'src/js/hook/use-stores';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { SecurityTabContent } from 'src/js/pages/security/security-tab-content';
import { submitAndValidate } from 'src/js/validator/validator';
import useSWR from 'swr';

import { AuditModel, AuditTransport, AuditTrustServer, auditValidationSchema } from './audit-models';

export const Audit: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { sessionStore } = useStores();
  const formRef = useRef(null);

  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);

  const fetcher = (url: string) => {
    return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
      return res?.data;
    });
  };

  const { data, mutate } = useSWR(APIPath.audit, fetcher, { revalidateOnFocus: false });
  const shouldReloadData = () => {
    mutate();
  };

  const handleSubmit = (values: any) => {
    setButtonState('pending');
    return apiClientWithoutHandler.genericController
      .put(APIPath.audit, values)
      .then((res: any) => {
        notificationHandler(
          res,
          t('security.audit.notifications.changeSuccessMsg'),
          t('security.audit.notifications.changeErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        setButtonState(undefined);
        if (res.ok) {
          shouldReloadData();
        }
        return res;
      });
  };

  return (
    <SecurityTabContent name={t('security.audit.title')}>
      <div id="audit">
        {data && (
          <Form
            initialValues={data}
            defaultValidation={true}
            handleSubmit={handleSubmit}
            restValidationProps={{
              enableReinitialize: true,
              validationSchema: auditValidationSchema(t),
              innerRef: formRef,
            }}
          >
            <FormContext.Consumer>
              {(formContext: FormikProps<AuditModel>) => {
                const values = formContext.values;
                return (
                  <>
                    <div className="buttonRow">
                      {sessionStore.isAdmin() && (
                        <Button
                          variant="primary"
                          onClick={() => submitAndValidate(formContext, t)}
                          disabled={!formContext.dirty}
                          state={buttonState}
                        >
                          {t('general.apply')}
                        </Button>
                      )}
                    </div>
                    <FormSection>
                      <DynamicContainer minColumns={3}>
                        <FormSwitch className="hai-mb-6" name="enabled" switchLabel={t('security.audit.form.audit')} />
                      </DynamicContainer>
                      {values.enabled && (
                        <>
                          <DynamicContainer minColumns={2}>
                            <FormTextfield mask name="server" title={t('security.audit.form.server')} required />
                            <FormSelect
                              name="transport"
                              title={t('security.audit.form.transport')}
                              options={Object.values(AuditTransport).map((transport: string) => ({
                                value: transport,
                                label: t(`security.audit.enum.transport.${transport}`),
                              }))}
                            />
                          </DynamicContainer>
                          <DynamicContainer minColumns={2}>
                            {values.transport !== 'UDP' && (
                              <FormSelect
                                name="trusted"
                                title={t('security.audit.form.trusted')}
                                options={Object.values(AuditTrustServer).map((trusted: string) => ({
                                  value: trusted,
                                  label: t(`security.audit.enum.trusted.${trusted}`),
                                }))}
                              />
                            )}
                            {values.transport !== 'UDP' && values.trusted === 'Self-signed' && (
                              <FormTextfield
                                mask
                                name="fingerprint"
                                title={t('security.audit.form.fingerprint')}
                                required
                              />
                            )}
                          </DynamicContainer>
                        </>
                      )}
                    </FormSection>
                  </>
                );
              }}
            </FormContext.Consumer>
          </Form>
        )}
      </div>
    </SecurityTabContent>
  );
};
