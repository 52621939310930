import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PresetViewModel } from './preset-models';
import { deletePreset } from './preset-store';
import { generateDataAuto } from '../util/automation';

interface Props {
  itemsToDelete: PresetViewModel[];
  onSuccessDelete?: (deletedPresets: PresetViewModel[]) => void;
  onCancel?: VoidFunction;
}

export const PresetDeleteModal: React.FunctionComponent<Props> = ({ itemsToDelete, onSuccessDelete, onCancel }) => {
  const { t } = useTranslation();

  const handleDelete = (): void => {
    Promise.all(itemsToDelete.map((item) => deletePreset(item, t))).then(() => {
      onSuccessDelete?.(itemsToDelete);
    });
  };

  return (
    <Dialog
      data-auto={generateDataAuto('modal', 'confirm delete')}
      headline={t('presets.modal.delete.title', { count: itemsToDelete.length })}
      bodyText={t('general.cannotUndo')}
      onClose={onCancel}
      show={true}
      buttons={[
        { label: t('general.cancel') },
        { label: t('general.deleteBtn'), variant: 'danger', onClick: handleDelete },
      ]}
    />
  );
};
