import { TFunction } from 'i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { notificationHandler } from 'src/js/notification/notification-handler';

import { LicenseViewModel } from './license-view-model';

export const fetchLicenses = () => {
  return apiClientWithoutHandler.genericController.get(APIPath.licenses.list).then((res) => {
    return res;
  });
};

export const deleteLicense = (model: LicenseViewModel) => {
  const path = APIPathWithArguments(APIPath.licenses.single, { name: model.name });
  return apiClientWithoutHandler.genericController.delete(path).then((res) => {
    return res;
  });
};

export const uploadLicense = (file: File, t: TFunction) => {
  return apiClientWithoutHandler.genericController.upload(APIPath.licenses.list, { file: file }).then((res) => {
    notificationHandler(res, null, t('settings.licensing.notifications.installErrorMsg', { name: file.name }), t);
    return res;
  });
};
