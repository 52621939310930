import React from 'react';
import { useTranslation } from 'react-i18next';
import { BandwidthGraph, LegendLocation } from 'src/js/pages/dashboard/bandwidth-graph';
import staticData from 'src/js/data/api/mock-bandwidth-response.json';
import { mapBandwidthResponse } from 'src/js/pages/dashboard/bandwidth-model';
import systemInfo from 'src/js/data/systemInfo';

export const BandwidthStaticTest: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="fullpage mk-bandwidth-graph-page">
      <div className="header">
        <h1>{t('statistics.chart.network', { deviceName: systemInfo.deviceName })}</h1>
      </div>
      <BandwidthGraph fullpage legend={LegendLocation.BOTTOM} forceData={mapBandwidthResponse(staticData as any)} />
    </div>
  );
};
