import { Card, Icon, OverlayTrigger, ProgressRing } from '@hai/ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { Labler } from 'src/js/component/base/labler';
import { PushButton } from 'src/js/component/base/push-button';
import { UserAnalytics } from 'src/js/component/base/user-analytics';
import PageHeader from 'src/js/component/page-header';
import systemStatus, { saveResponseToSystemStatus } from 'src/js/data/systemStatus';
import { triggerEvent, watchEvent } from 'src/js/events';
import { useAdaptiveFetch } from 'src/js/hook/use-adaptive-fetch';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import { mapStatus } from 'src/js/mapper/status-mapper';
import { StatusResponse } from 'src/js/model/api/response/status-response';
import {
  EmsServiceResponse,
  EmsServiceState,
  getEmsStateIcon,
  mapEmsServices,
} from 'src/js/pages/settings/services/ems-models';
import emsService, { saveResponseToEmsService } from 'src/js/pages/settings/services/ems-store';
import { DecoderViewModel } from 'src/js/pages/streaming/decoder/decoder-model';
import decoderModels, { decodersStore, fetchDecoders } from 'src/js/pages/streaming/decoder/decoder-store';
import { formatUptime } from 'src/js/pages/streaming/stream/stream-helper';
import { fetchStreams } from 'src/js/pages/streaming/stream/stream-store';
import SidebarLayout from 'src/js/sidebar';
import { useWatch, useWatchObject } from 'src/js/store/use-watch';
import { generateDataAuto } from 'src/js/util/automation';
import { toTitleCase } from 'src/js/util/global-util';

import { BandwidthGraph, LegendLocation } from './bandwidth-graph';
import { BandwidthGraphModal } from './bandwidth-graph-modal';
import { DecoderButtons } from './decoder-buttons';
import { DecodersDashboardContainer, DecodersDashboardContainerRef } from './decoder-container';
import { ResourcesCard } from './resources-card';

let connectionLost = false;
export const DashboardContainer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionStore } = useStores();

  const [bandwidthModalShown, showBandwidthModal, hideBandwidthModal] = useModalControls();
  const [decoders, setDecoders] = useState(decoderModels);
  useWatchObject(decoderModels, (updated) => {
    setDecoders(updated);
    decodersStore?.updateFromRestApi(updated);
  });

  const [firstTime, setFirstTime] = useMutable(true);
  const [pauseBandwidthGraphCard, setPauseBandwidthGraphCard] = useState(false);
  const [interval, setInterval] = useState(5000);
  const [fastIntervalCount, setFastIntervalCount] = useMutable(0);
  watchEvent('fast-update', () => {
    setInterval(1500);
    setFastIntervalCount(4);
  });

  useEffect(() => {
    // sync up immediatly any changes tbat may have been fetch from other pages. Other pages dont have decoderStore, so it's info is stale when navigating back to Dashboard
    decodersStore?.updateFromRestApi(decoderModels);
    // rest any user changes
    decodersStore?.reset();
  }, []);

  // trigger refresh after preset load
  watchEvent('preset-load', () => {
    setInterval(interval % 2 === 0 ? interval + 1 : interval - 1); //trigger fetch
  });

  useAdaptiveFetch(
    () =>
      fetchDecoders(t).then((res) => {
        if (firstTime()) {
          decodersStore.updateInitials(decoderModels); // update once to take snapshot at form initialize
          setFirstTime(false);
        }
        if (res.ok) {
          if (connectionLost) {
            triggerEvent('connection-restored');
            connectionLost = false;
          }
        } else if (res.problem === 'NETWORK_ERROR') {
          triggerEvent('connection-lost');
          connectionLost = true;
        }
        fetchStreams(t);

        if (fastIntervalCount() > 0) {
          setFastIntervalCount(fastIntervalCount() - 1);
          if (fastIntervalCount() === 0) {
            setInterval(5000);
            // console.log('back too normal speed')
          }
        }
      }),
    interval,
    [interval],
  );

  const fetchSystemStatus = () =>
    apiClientWithoutHandler.genericController.get<StatusResponse>(APIPath.status.get).then((res) => {
      if (res.ok) {
        const response: StatusResponse = res.data;
        if (response) {
          const systemStatus = mapStatus(response);
          saveResponseToSystemStatus(systemStatus);
        }
      }
      return res;
    });

  const fetchEmsService = () =>
    apiClientWithoutHandler.genericController.get<any>(APIPath.services.ems).then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          const emsService = mapEmsServices(response?.data as EmsServiceResponse);
          saveResponseToEmsService(emsService);
        }
      } else {
        saveResponseToEmsService({ state: EmsServiceState.DISABLED });
      }
      return res;
    });

  useAdaptiveFetch(fetchSystemStatus, interval * 3);

  const [status, setStatus] = useState(systemStatus);
  useWatchObject(systemStatus, setStatus);

  const [emsState, setEmsState] = useState(emsService.state);
  useWatch(emsService, 'state', setEmsState);

  useAdaptiveFetch(fetchEmsService, emsState !== EmsServiceState.DISABLED ? interval * 3 : undefined);

  let totalLoad = 0;
  const mapDecoderUsageSections = (list: DecoderViewModel[]) =>
    list.map((decoder) => {
      // console.log('decoder ID load', decoder.id, decoder.stats.loadPercentage);
      totalLoad += decoder.stats.loadPercentage ?? 0;

      return {
        value: decoder.stats.loadPercentage ?? 0,
        color: decoder.dashboardColor,
        tooltipText: (
          <>
            <div
              className="tooltipText"
              style={{
                display: 'flex',
                justifyItems: 'center',
                fontSize: '1.5em',
                margin: '5px 0 8px 0',
                color: 'black',
              }}
            >
              {decoder.stats.loadPercentage ?? 0}%
            </div>

            <div
              style={{
                color: 'black',
              }}
            >
              {t('dashboard.decoding.decoder')} {decoder.id}
            </div>
          </>
        ),
        tooltipBgColor: decoder.dashboardColor,
      };
    });

  const decoderLoadSections = mapDecoderUsageSections(decoders).filter((dec) => dec.value != 0);
  totalLoad = Math.round(totalLoad);
  if (totalLoad > 100) {
    totalLoad = 100;
  }
  const decoderRef = useRef<DecodersDashboardContainerRef>();
  const submitApplyAllDecoders = () => {
    return decoderRef.current.submitApplyAllDecoders();
  };
  const cancelDecodersChanges = () => {
    decoderRef.current.cancelChanges();
    return;
  };
  return (
    <div id="dashboard">
      <DecoderButtons submitApplyAllDecoders={submitApplyAllDecoders} cancelChanges={cancelDecodersChanges} />
      <div id="firstRow">
        <Card
          title={t('dashboard.decoding.title')}
          id="decoders-card"
          className="audio-16"
          icon="Decoders"
          titleOnClick={() => navigate('/streaming/decoder')}
        >
          <div className="dashboard-card">
            <ProgressRing
              data-auto={generateDataAuto('progress_ring', 'inuse')}
              label={t('dashboard.decoding.inUse')}
              currentPercentage={totalLoad}
              sections={decoderLoadSections}
            ></ProgressRing>
            <Labler label={t('dashboard.decoding.decoders')}>
              <div className="pushButtonSection decoder">
                {decoders.map((item, key) => (
                  <PushButton
                    data-auto={generateDataAuto('push_button', `decoder ${key}`)}
                    key={key}
                    href="/streaming/decoder"
                    id={item.id}
                    color={item.dashboardColor}
                    hoverText={item.stateString}
                    hoverBgColor={item.dashboardColor}
                  >
                    {item.id}
                  </PushButton>
                ))}
              </div>
            </Labler>
          </div>
        </Card>
        <ResourcesCard />
        <Card
          title={t('dashboard.bandwidth.title')}
          id="global-bandwidth-card"
          className="audio-16"
          icon="Meter"
          titleHref="/bandwidth"
          titleOnClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            setPauseBandwidthGraphCard(true); // pause dashboard graph so modal one fetch its own data
            showBandwidthModal();
          }}
        >
          <div className="cardGapper">
            <BandwidthGraph legend={LegendLocation.NONE} showHeaderLegend={true} pauseFetch={pauseBandwidthGraphCard} />
          </div>
        </Card>
        <Card
          title={t('dashboard.deviceStatus.title')}
          id="device-status-card"
          icon="Information"
          titleOnClick={() => navigate('/settings/system')}
        >
          <div className="text-status-card">
            <OverlayTrigger popoverContent={emsService.emsHostname} placement="top">
              <Labler
                label={t('dashboard.deviceStatus.emsStatus')}
                id="ems-state-string"
                data-auto={generateDataAuto('labler_text', 'ems status')}
              >
                <Icon type="status" subtype={getEmsStateIcon(emsState)} size="sm3" />
                {emsState && toTitleCase(emsState)}
              </Labler>
            </OverlayTrigger>
            <Labler
              mask
              label={t('dashboard.deviceStatus.serialNumber')}
              className="secondary"
              data-auto={generateDataAuto('labler_text', 'serial number')}
            >
              {status.serialNumber}
            </Labler>
            <Labler
              label={t('dashboard.deviceStatus.systemUptime')}
              className="tnum"
              data-auto={generateDataAuto('labler_text', 'system uptime')}
            >
              {formatUptime(status.uptime, true)}
            </Labler>
            <Labler
              label={t('dashboard.deviceStatus.firmware')}
              className="secondary"
              data-auto={generateDataAuto('labler_text', 'firmware')}
            >
              {status.firmwareVersion}
            </Labler>
          </div>
        </Card>
      </div>
      <DecodersDashboardContainer ref={decoderRef} />
      {sessionStore.isAdmin() && <UserAnalytics />}
      {bandwidthModalShown && (
        <BandwidthGraphModal
          onCancel={() => {
            hideBandwidthModal();
            setPauseBandwidthGraphCard(false);
          }}
          onAccept={() => {
            // console.log('off');
          }}
        />
      )}
    </div>
  );
};

export const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <SidebarLayout>
      <PageHeader title={t('sidebar.dashboard')} iconName="Dashboard" />
      <DashboardContainer />
    </SidebarLayout>
  );
};
