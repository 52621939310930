export enum NtpStatus {
  Valid,
  Warning,
  Error,
}

export interface NtpServerStatus {
  status: NtpStatus;
  message?: string;
}
