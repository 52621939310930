import { TFunction } from 'i18next';
import { isNil } from 'ramda';
import { ClientAuthenticationError, ClientNetworkError, ClientTimeoutError } from 'src/js/error/client-error';
import { ServerError } from 'src/js/error/server-error';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { persistedStores } from 'src/js/store';

/**
 * Chain of responsibility handler for the network requests.
 * By default, the handler does nothing. If you don't want the handler to do anything just return `null`.
 *
 * Make sure the handler are added in order, add the success to the handler so that it can call it if it didn't handle
 * the error itself. Make sure to return if you want to avoid calling the successor.
 */
export class NetworkErrorHandler {
  private _successor?: NetworkErrorHandler;

  public constructor(successor?: NetworkErrorHandler) {
    this._successor = successor;
  }

  // We chain the adding and removing of successor
  public set successor(successor: NetworkErrorHandler | null) {
    if (isNil(this.successor)) {
      this._successor = successor;
    } else {
      this._successor.successor = successor;
    }
  }

  public get successor(): NetworkErrorHandler {
    return this._successor;
  }

  public removeSuccessor(): void {
    if (isNil(this.successor)) {
      this._successor = null;
    } else {
      this._successor.successor = null;
    }
  }

  public handleError(_error: Error): Error | null {
    return _error;
  }
}

/**
 * This handle checks for a 401 error via the ClientAuthenticationError.
 *
 * If there is a 401, the handler will remove the session which will bring the user back to the
 * login screen.
 */
export class AuthenticationNetworkErrorHandler extends NetworkErrorHandler {
  private readonly shouldRedirect: boolean;

  public constructor(shouldRedirect?: boolean, successor?: NetworkErrorHandler) {
    super(successor);
    this.shouldRedirect = shouldRedirect ?? false;
  }
  handleError(error: Error): Error | null {
    if (error instanceof ClientAuthenticationError) {
      persistedStores.sessionStore.logout();
      //trunk.persist();
      return null;
    }
    return this.successor.handleError(error);
  }
}

export class NotificationNetworkErrorHandler extends NetworkErrorHandler {
  private readonly tFunction: TFunction;

  public constructor(t: TFunction, successor?: NetworkErrorHandler) {
    super(successor);
    this.tFunction = t;
  }

  handleError(error: Error): Error | null {
    if (error instanceof ServerError) {
      createAndDispatchNotification(error.message, NotificationVariant.ERROR, this.tFunction);
    } else if (error instanceof ClientNetworkError || error instanceof ClientTimeoutError) {
      createAndDispatchNotification(error.message, NotificationVariant.ERROR, this.tFunction);
    }
    return this.successor.handleError(error);
  }
}

/**
 * Use this handler if you want to simply dismiss the errors
 * DEPRECATED: use simpler generic architecture
 */
export class NullifierNetworkErrorHandler extends NetworkErrorHandler {
  handleError(): Error | null {
    return null;
  }
}
