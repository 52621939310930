import { action, observable } from 'mobx';

export class SidebarStore {
  @observable
  collapsed = false;

  @action
  toggleCollapse() {
    this.collapsed = !this.collapsed;
  }
}
