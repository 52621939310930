import { TFunction } from 'i18next';
import { isEmpty, isNil } from 'ramda';

/**
 * Checks if a value is null, undefined, or empty
 * @param value
 */
export const isNilOrEmpty = (value: string | Array<any> | unknown): boolean => isNil(value) || isEmpty(value);

/**
 * FIXME doesnt do what it says. It only capitalize the first letter
 * Converts a string to TitleCase
 * @param value
 */
export const toTitleCase = (value?: string): string =>
  !isNilOrEmpty(value) ? value.charAt(0).toUpperCase() + value.substring(1).toLowerCase() : undefined;

/**
 * FIXME doesnt do what it says. It only capitalize the first letter
 * Converts a string to TitleCase
 * @param value
 */
export const toTitleCaseDefaultToString = (value: string): string =>
  !isNilOrEmpty(value) ? value.charAt(0).toUpperCase() + value.substring(1).toLowerCase() : '';

export const formatPreprocessorState = (value?: string) => {
  if (isNilOrEmpty(value)) {
    return value;
  }
  const words = value.split(' ');
  const result = [];
  for (const word of words) {
    if (word === 'HDR') {
      // TODO provide array of non-changing words in argument
      result.push(word);
      continue;
    }
    result.push(toTitleCase(word));
  }
  return result.join(' ');
};
/**
 * Util to open a new window.
 * @param url
 * @param target
 * @param features
 */
export const openNewWindow = (url?: string, target = '_system', features?: string): Window | null => {
  return window.open(url, target, features);
};

/**
 * Set wait cursor on body to on/off.
 * @param wait
 */
export const setWaitCursor = (wait: boolean) => {
  const body = document.getElementsByTagName('body');
  if (body) {
    const element = body[0];
    element.style.cursor = wait ? 'wait' : 'unset';

    const className = 'reset-all-cursors';
    if (wait) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  }
};

export const scrollIntoView = (id: string, focus = false) => {
  if (!isNil(id)) {
    setTimeout(() => {
      const item = document.getElementById(id);
      if (!isNil(item)) {
        item.scrollIntoView({ block: 'center', inline: 'nearest' });
        if (focus) {
          const ele: any = item.firstElementChild;
          ele.focus();
        }
      }
    }, 150);
  }
};

// Input: Number in seconds
// Output: String formatted with up to days range
// Example: If input == 0, output == "0s"
// Example: If input == 0, output == "0s"
// Author: Francois Oligny-Lemieux
// Written: 23.Apr.2024
export const secondsToDhms = (seconds: number) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  let result = d > 0 ? `${d}d` : '';
  result += result !== '' || h > 0 ? `${h}h` : '';
  result += result !== '' || m > 0 ? `${m}s` : '';
  result += result !== '' || s >= 0 ? `${s}s` : '';
  return result;
};

// Prints "2d10h20s ago" or "Never occured" if < 0
export const formatLastOccuredTime = (seconds: number, t: TFunction) => {
  if (seconds < 0) {
    return t('general.neverOccured');
  }
  return `${secondsToDhms(seconds)} ${t('general.ago')}`;
};
