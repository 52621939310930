import { TFunction } from 'i18next';
import * as yup from 'yup';

export interface LoginViewModel {
  username: string;
  password: string;
}

/*
 * Schema for the form
 */
export const loginSchema = (t: TFunction) =>
  yup.object().shape({
    username: yup.string().required(t('login.username_required')).default(''),
    password: yup.string().required(t('login.password_required')).default(''),
  });
