export const security = {
  security: {
    accounts: {
      addFirst: 'No Public Keys to Display',
      column: {
        name: 'Name',
        role: 'Role',
        state: 'State',
        passwordExpiry: 'Password Expiration',
        action: 'Action',
        fingerprint: 'Fingerprint',
      },
      expiration: {
        byAdministrator: 'By Administrator',
        expired: '{{count}} Day Ago',
        expired_plural: '{{count}} Days Ago',
        never: 'Never',
        expiring: 'In {{count}} Day',
        expiring_plural: 'In {{count}} Days',
      },
      pubkeys: {
        title: 'Public Keys',
        import: {
          title: 'Import Public Key',
        },
        delete: {
          title: 'Delele this public key?',
          title_plural: 'Delete {{count}} public keys?',
        },
      },
      notifications: {
        changeErrorMsg: 'Account could not be updated',
        changeSuccessMsg: 'Account updated',
        createErrorMsg: "Account '{{name}}'could not be created",
        createSuccessMsg: "Account '{{name}}' created",
        deleteErrorMsg: 'Account could not be deleted',
        enableErrorMsg: 'Account could not be enabled',
        lockErrorMsg: 'Account could not be locked',
        passwordChangeErrorMsg: 'Password could not be updated',
        passwordChangeSuccessMsg: 'Password updated',
        pubkeyDeleteErrorMsg: 'Public key could not be deleted',
        pubkeyUploadErrorMsg: 'Public key could not be uploaded',
        userAlreadyExits: "Username '{{name}}' already exists",
      },
      form: {
        userName: 'Username',
        newPassword: 'New Password',
        oldPassword: 'Current Password',
        password: 'Password',
        passwordConfirm: 'Confirm Password',
        passwordNewConfirm: 'Confirm New Password',
        role: 'Role',
      },
      modal: {
        add: {
          title: 'Add Account',
          accountSettings: 'Account Settings',
        },
        delete: {
          title: 'Delete this account?',
          title_plural: 'Delete {{count}} accounts?',
        },
      },
      roleType: {
        Administrator: 'Administrator',
        Operator: 'Operator',
        Guest: 'Guest',
        Superuser: 'Superuser',
      },
      states: {
        disabled: 'DISABLED',
        active: 'ACTIVE',
        locked: 'LOCKED',
      },
      title: 'Accounts',
    },
    audit: {
      title: 'Audit',
      enum: {
        transport: {
          UDP: 'UDP',
          TLS: 'TLS',
        },
        trusted: {
          All: 'All',
          'CA-signed': 'CA-Signed',
          'Self-signed': 'Self-Signed',
        },
      },
      form: {
        audit: 'Audit',
        server: 'Audit Server Address',
        transport: 'Transport',
        trusted: 'Trusted Servers',
        fingerprint: 'Fingerprint',
      },
      notifications: {
        changeSuccessMsg: 'Audit settings updated',
        changeErrorMsg: 'Audit settings could not updated',
      },
      validation: {
        fingerprint: 'Invalid fingerprint',
        server: 'Must be a valid IP address or hostname',
      },
    },
    certificates: {
      title: 'Certificates',
      tabContent: 'Certificates',
      modal: {
        delete: {
          title: 'Delete this certificate?',
          title_plural: 'Delete {{count}} certificates?',
        },
        identity: {
          overwrite: {
            title: 'Certificate Name Already Exists',
            bodyText: 'Please choose another name or delete the existing certificate',
          },
        },
      },
      ca: {
        title: 'CA Certificates',
        addFirst: 'No CA Certificates to Display',
        import: {
          title: 'Import Certificate',
          typeEnumToLabel: {
            ca: 'root-CA',
          },
        },
      },
      identity: {
        title: 'Identity Certificates',
        addFirst: 'No Identity Certificates to Display',
        import: {
          title: 'Import Certificate',
          typeEnumToLabel: {
            id: 'Identity',
            chain: 'CA-Chain',
          },
        },
      },
      identityForm: {
        title: 'Generate Certificate',
      },
      identityTypeSelectOptions: {
        'Self-signed': 'Self-Signed',
        'CA-signed': 'CA-Signed',
        'CA-chained': 'CA-Chained',
        CSR: 'Certificate Signing Request',
      },
      identityEnumToLabel: {
        self: 'Self-Signed',
        request: 'Certificate Signing Request',
      },
      notifications: {
        defaultErrorMsg: "Certificate '{{name}}' could not be set as the default",
        defaultSuccessMsg: "Certificate '{{name}}' set as the default",
        deleteErrorMsg: 'Certificate could not be deleted',
        generateErrorMsg: 'Certificate could not be generated',
        generateSuccessMsg: 'Certificate generated',
        importErrorMsg: 'Certificate could not be imported',
        importSuccessMsg: 'Certificate imported',
      },
    },
    banner: {
      title: 'Banner',
      form: {
        bannerText: 'Banner Preview',
        displayBanner: 'Enable Sign-In Banner',
      },
      import: {
        title: 'Upload a Banner',
      },
      modal: {
        delete: {
          title: 'Delete this banner?',
        },
      },
      notifications: {
        uploadErrorMsg: 'Banner could not be uploaded',
        deleteErrorMsg: 'Banner could not be deleted',
        changeSuccessMsg: 'Banner updated',
        changeErrorMsg: 'Banner could not be updated',
      },
    },
    messages: {
      title: 'Messages',
      modal: {
        delete: {
          title: 'Clear the messages?',
        },
      },
      notifications: {
        deleteErrorMsg: 'Messages could not be cleared.',
      },
    },
    policies: {
      title: 'Policies',
      enum: {
        quality: {
          Strong: 'Strong',
          Basic: 'Basic',
        },
        compliance: {
          NONE: '(None)',
          FIPS140: 'FIPS 140-2',
          NDPP11: 'NDPP v1.1',
          'SP800-52R1': 'SP800-52 Revision 1 (deprecated)',
          'SP800-52R2': 'SP800-52 Revision 2',
        },
      },
      form: {
        autoLogout: 'Auto Logout',
        autoLogoutTime: 'Logout when Idle for (Minutes)',
        autoLogoutTimeExplain: '1 - 1440',
        compliance: 'Compliance',
        digits: 'Digits',
        digitsExplain: '0 - 40',
        expiration: 'Password Expiration',
        expirationDays: 'Change Password After (Days)',
        expirationDaysExplain: '1 - 180',
        hsts: 'Strict Transport Security',
        inactiveLock: 'Disable Inactive Accounts',
        inactiveLockTime: 'Inactivity Timeout (Days)',
        inactiveLockTimeExplain: '1 - 365',
        limitPwdRetries: 'Limit Login Attempts',
        limitPwdRetriesInterval: 'Failed Interval (Minutes)',
        limitPwdRetriesIntervalExplain: '5 - 60',
        limitPwdRetriesMaxAttempts: 'Max Failed Attempts',
        limitPwdRetriesMaxAttemptsExplain: '3 - 10',
        minimumLifetime: 'Minimum Lifetime (Days)',
        minimumLifetimeExplain: '0 - 7',
        minLen: 'Minimum Length',
        minLenExplain: '6 - 40',
        quality: 'Quality',
        rememberedPasswords: 'Remembered Last (Passwords)',
        rememberedPasswordsExplain: '5 - 500',
        ssl3: 'SSL v3',
        strongRequirements: 'Strong Requirements',
        symbols: 'Symbols',
        symbolsExplain: '0 - 40',
        tlsVersions: 'TLS Versions',
        tls1_1: 'TLS v1.1',
        tls1_2: 'TLS v1.2',
        tls1: 'TLS v1.0',
        uppercase: 'Uppercase Letters',
        uppercaseExplain: '0 - 40',
      },

      modal: {
        delete: {
          title: 'Confirm Delete',
          bodyText: 'This will clear the messages.',
        },
      },
      notifications: {
        updateSuccessMsg: 'Policies updated',
        updateErrorMsg: 'Policies could not be updated',
      },
      section: {
        cryptoPolicies: 'Cryptography',
        httpPolicies: 'HTTP',
        passwordPolicies: 'Passwords',
        sessionsPolicies: 'Sessions',
        accountPolicies: 'Accounts',
      },
    },
  },
};
