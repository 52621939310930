import { Button } from '@hai/ui-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { triggerEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { SettingsTabContent } from 'src/js/pages/settings/settings-tab-content';
import useSWR from 'swr';

import { StillImage } from './still-image-model';
import { StillImageImportModal } from './stillimage-import-modal';
import { StillImagesList } from './stillimages-list';
import { generateDataAuto } from 'src/js/util/automation';

export const StillImages: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [importShown, showImport, hideImport] = useModalControls();

  const fetcher = (url: string) => {
    return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
      return res?.data?.data;
    });
  };

  const { data, mutate } = useSWR(APIPath.stillimages.list, fetcher, { revalidateOnFocus: false });
  const shouldReloadData = (): void => {
    mutate(data);
  };

  useEffect(() => {
    if (data) {
      triggerEvent('stillimages-fetched', { count: data?.length });
    }
  }, [data]);

  const afterImport = () => {
    shouldReloadData();
    hideImport();
  };

  return (
    <SettingsTabContent name={t('settings.stillImages.title')}>
      <div id="stillimages">
        <div className="buttonRow" data-auto={generateDataAuto('button', 'row')}>
          <Button variant="secondary" onClick={showImport}>
            {t('general.import')}
          </Button>
        </div>
        <StillImagesList viewModels={data} shouldReloadData={shouldReloadData} />
        {importShown && (
          <StillImageImportModal
            onCancel={hideImport}
            afterImport={afterImport}
            listOfUsedNames={data.map((viewModel: StillImage) => viewModel.name)}
          />
        )}
      </div>
    </SettingsTabContent>
  );
};
