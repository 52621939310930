import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { triggerEvent } from 'src/js/events';
import { useMutable } from 'src/js/hook/use-mutable';
import { notificationHandler } from 'src/js/notification/notification-handler';
import {
  CertificateCa,
  CertificateCaImport,
  CertificateIdentityViewModel,
  IdentityGenerateViewModel,
} from 'src/js/pages/security/certificates/certificates-models';
import { SecurityTabContent } from 'src/js/pages/security/security-tab-content';
import { RebootNeeded, RebootNeededRef } from 'src/js/reboot/reboot-needed';
import { setWaitCursor } from 'src/js/util/global-util';
import { createListStore, ListSelector } from 'src/js/util/listSelector';
import useSWR, { KeyedMutator } from 'swr';
import { v4 as uuidv4 } from 'uuid';

import { CaTable } from './ca-table';
import { IdentityTable } from './identity-table';

export let dataStoreIdentity: ListSelector<CertificateIdentityViewModel> = null;
export let dataStoreCa: ListSelector<CertificateCa> = null;

export const Certificates: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const fetcher = (url: string) => {
    return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
      return res?.data?.data;
    });
  };

  const { data, mutate } = useSWR(APIPath.identity.index, fetcher, { revalidateOnFocus: false });
  let dataCa: any = null;
  let mutateCa: KeyedMutator<any> = null;
  {
    const { data, mutate } = useSWR(APIPath.ca.index, fetcher, { revalidateOnFocus: false });
    dataCa = data;
    mutateCa = mutate;
  }

  const [uuid1] = useMutable(uuidv4());
  dataStoreIdentity = createListStore(data, uuid1(), 'name');

  dataStoreCa = null;
  const [uuid2] = useMutable(uuidv4());
  dataStoreCa = createListStore(dataCa, uuid2(), 'name');

  // Reload identity
  const shouldReloadData = (): void => {
    dataStoreIdentity.unselectAll();
    mutate();
  };

  // Reload CA
  const caReloadData = (): void => {
    dataStoreCa.unselectAll();
    mutateCa();
  };

  const rebootNeededRef = useRef<RebootNeededRef>(null);

  // Identity certificate clicked to be as default
  const setAsDefault = (model: CertificateIdentityViewModel) => {
    setWaitCursor(true);

    const url = APIPathWithArguments(APIPath.identity.default, { name: model.name });
    return apiClientWithoutHandler.genericController
      .put(url)
      .then((res: any) => {
        notificationHandler(
          res,
          t('security.certificates.notifications.defaultSuccessMsg', { name: model.name }),
          t('security.certificates.notifications.defaultErrorMsg', { name: model.name }),
          t,
          true,
        );

        return res;
      })
      .then((res: any) => {
        if (res.ok) {
          const certs = data.map((c: CertificateIdentityViewModel) => {
            return { ...c, default: false };
          });

          const index = certs.findIndex((c: CertificateIdentityViewModel) => c.name === model.name);
          if (index > -1) {
            certs.splice(index, 1, { ...model, default: true });
          }
          mutate(certs);
          rebootNeededRef.current.show();
        }
        return res;
      })
      .finally(() => {
        setWaitCursor(false);
      });
  };

  // Identity generate form submit
  const handleIdentityGenerate = (model: IdentityGenerateViewModel): Promise<any> => {
    const path = APIPath.identity.generate;
    return apiClientWithoutHandler.genericController
      .post(path, model)
      .then((res: any) => {
        notificationHandler(
          res,
          t('security.certificates.notifications.generateSuccessMsg'),
          t('security.certificates.notifications.generateErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        return res;
      });
  };

  // Import identity form submit
  const handleUploadIdentity = (model: CertificateIdentityViewModel): Promise<any> => {
    const path = APIPath.identity.generate;
    return apiClientWithoutHandler.genericController
      .upload(path, model)
      .then((res: any) => {
        notificationHandler(
          res,
          t('security.certificates.notifications.importSuccessMsg'),
          t('security.certificates.notifications.importErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        return res;
      });
  };

  // Import identity form submit
  const handleUploadCa = (model: CertificateCaImport): Promise<any> => {
    const path = APIPath.ca.index;
    return apiClientWithoutHandler.genericController
      .upload(path, model)
      .then((res: any) => {
        notificationHandler(
          res,
          t('security.certificates.notifications.importSuccessMsg'),
          t('security.certificates.notifications.importErrorMsg'),
          t,
        );
        return res;
      })
      .then((res: any) => {
        return res;
      });
  };

  const [loadingIdentity, setLoadingIdentity] = useState(true);
  useEffect(() => {
    if (data) {
      setLoadingIdentity(false);
    }
  }, [data]);

  const [loadingCa, setLoadingCa] = useState(true);
  useEffect(() => {
    if (dataCa) {
      setLoadingCa(false);
      triggerEvent('certificates-fetched');
    }
  }, [dataCa]);

  return (
    <SecurityTabContent name={t('security.certificates.tabContent')}>
      <IdentityTable
        listController={dataStoreIdentity}
        shouldReloadData={shouldReloadData}
        setAsDefault={setAsDefault}
        onIdentityGenerate={handleIdentityGenerate}
        onUploadIdentity={handleUploadIdentity}
        loading={loadingIdentity}
      />

      <CaTable
        listController={dataStoreCa}
        shouldReloadData={caReloadData}
        onUploadCa={handleUploadCa}
        loading={loadingCa}
      />
      <RebootNeeded ref={rebootNeededRef} />
    </SecurityTabContent>
  );
};
