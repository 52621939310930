import { Dialog } from '@hai/ui-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { UploadZone } from 'src/js/component/base/upload-zone';
import { constant } from 'src/js/constant';

interface Props {
  onCancel: VoidFunction;
  onImport: (file: File) => Promise<any>;
}

export const PubkeyImportModal: React.FunctionComponent<Props> = ({ onCancel, onImport }) => {
  const { t } = useTranslation();

  const handleStartUpload = (file: File) => {
    onImport(file);
  };

  return (
    <Dialog
      accentColor={constant.productColor}
      title={t('security.accounts.pubkeys.import.title')}
      onClose={onCancel}
      show={true}
      dialogType="activity"
      headerIcon="Security"
      icon="none"
      content={
        <UploadZone
          accept={{ '': constant.pubkeysFileTypes }}
          onUpload={handleStartUpload}
          onCancel={onCancel}
          uploadBtnText={t('general.import')}
        />
      }
    />
  );
};
