import { action, IObservableArray, observable } from 'mobx';
import append from 'ramda/src/append';
import includes from 'ramda/src/includes';
import without from 'ramda/src/without';
import { format } from 'mobx-sync';

/**
 * Store for stream expansion state
 *
 * TODO: Make this a generic store
 * TODO: Add a ListExpansionStoreProvider for usage in a flow
 */
export class StreamListExpansionStore {
  @format((data: number[]) => observable.array(data), (value: IObservableArray<number>) => value.toJS())
  @observable
  private expandedRowIds: IObservableArray<number> = observable.array([]);

  isExpanded(id: number): boolean {
    return includes(id, this.expandedRowIds);
  }

  @action
  expandRow(id: number) {
    if (!includes(id, this.expandedRowIds)) {
      this.expandedRowIds = observable.array(append(id, this.expandedRowIds));
    }
  }

  @action
  collapseRow(index: number) {
    this.expandedRowIds = observable.array(without([index], this.expandedRowIds));
  }

  @action
  toggleRow(index: number) {
    includes(index, this.expandedRowIds) ? this.collapseRow(index) : this.expandRow(index);
  }
}
