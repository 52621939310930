export const statistics = {
  statistics: {
    bandwidth: {
      eth0Tx: 'ETH0 Tx',
      eth0Rx: 'ETH0 Rx',
      eth1Tx: 'ETH1 Tx',
      eth1Rx: 'ETH1 Rx',
      legendTitle: 'Metrics',
      title: 'Receive and Transmit Rates',
    },
    general: {
      notRunning: 'Not Running',
    },
    button: {
      downloadCSV: 'DOWNLOAD CSV',
      openDetailed: 'DETAILED STATISTICS',
      resetStats: 'RESET',
    },
    header: {
      detailedStatistics: 'DETAILED STATISTICS',
      downloadCSVButtonTitle: 'DOWNLOAD CSV',
      resetButtonTitle: 'RESET',
      srtStatistics: 'SRT STATISTICS',
      genericStatistics: '{{protocol}} STATISTICS',
      statistics: 'STATISTICS',
    },
    options: {
      refreshInterval: 'Refresh Every',
    },
    units: {
      sec: 'sec',
      ms: 'ms',
      smallerThan1Ms: '< 1 ms',
    },
    goBack: 'Back to Streaming',
    path: '',
    path1: 'Path 1',
    path2: 'Path 2',
    pathSelection: 'Show Paths',
    pathSelectionAll: 'All',
    pathSelectionNone: 'None',
    decoder: {
      title: '{{decoder}} Statistics',
      subTitle: 'DECODER STATISTICS',
      audioTitle: 'Decoder Audio',
      section: {
        general: 'GENERAL',
        buffering: 'BUFFERING',
        clockRecovery: 'CLOCK RECOVERY',
        video: 'VIDEO',
        audio: 'AUDIO',
        audioPair: 'AUDIO PAIR #{{id}}',
        metadata: 'METADATA',
        multisync: 'MULTISYNC',
        klv: 'KLV',
        tc: 'TIMECODE',
        cc: 'CLOSED CAPTION',
        afd: 'AFD',
        hdr: 'HIGH DYNAMIC RANGE (HDR)',
      },
      rows: {
        // General
        state: 'State',
        uptime: 'Uptime',
        // Buffering
        bufferingAdjustments: 'Buffering Adjustments',
        bufferingMode: 'Buffering Mode',
        bufferingState: 'Buffering State',
        audioStcLeadTime: 'STC Lead Time',
        stcToPcrLeadTime: 'STC to PCR Lead Time',
        videoLatency: 'Video Latency',
        // Multisync
        multisyncDelayActual: 'Delay Actual',
        multisyncDelayRange: 'Delay Range',
        multisyncDelaySet: 'Delay Set',
        multisyncStatus: 'Status',
        multisyncSystemTime: 'System Time',
        multisyncTimecode: 'Timecode',
        multisyncTimecodePackets: 'Packets',
        multisyncTimecodePacketsInvalid: 'Invalid Packets',
        multisyncTimeDiff: 'Difference',
        multisyncTransmissionTime: 'Transmission Time',
        // Video
        corruptedFrames: 'Corrupted Frames',
        corruptedFramesDropped: 'Corrupted Frames Dropped',
        displayedOutputFrames: 'Displayed Frames',
        lastReset: 'Last Reset',
        loadPercentage: 'Video Decoder Load',
        preprocessorState: 'Preprocessor State',
        unlicensedReason: 'Unlicensed Reason',
        unsupportedReason: 'Unsupported Reason',
        replayedOutputFrames: 'Replayed Frames',
        videoOutputJitterMs: 'Video Output Jitter',
        videoStcLeadTime: 'Video STC Lead Time',
        lastReplayedOutputFrames: 'Last Replayed Frames',
        skippedOutputFrames: 'Skipped Frames',
        lastSkippedOutputFrames: 'Last Skipped Frames',
        stillImage: 'Still Image',
        vframerNumInputPackets: 'Video Input Packets',
        videoAlgorithm: 'Algorithm',
        videoDecoderState: 'Video Decoder State',
        videoDisplayFormat: 'Display Format',
        videoFraming: 'Framing',
        videoInputResolution: 'Encoded Format',
        videoLevel: 'Level',
        videoProfile: 'Profile',
        videoSlicesPerFrame: 'Slices per Frame',
        // Audio
        audioBitrate: 'Audio Bitrate',
        audioCompression: 'Audio Compression',
        audioDecodedFrames: 'Decoded Frames',
        audioInputLayout: 'Input Layout',
        audioOutputLayout: 'Output Layout',
        audioMode: 'Audio Mode',
        audioPairsAmount: 'Number of Pairs',
        audioPlayedFrames: 'Played Frames',
        audioSampleRate: 'Audio Sample Rate',
        audioSkippedFrames: 'Skipped Frames',
        audioState: 'Audio Decoder State',
        avSyncMs: 'AV Sync',
        decodeErrors: 'Decode Errors',
        discontinuities: 'Discontinuities',
        language: 'Language',
        outputErrors: 'Output Errors',
        sampleRateIn: 'Sample Rate In',
        sampleRateOut: 'Sample Rate Out',
        downmixSurround: 'Downmix to Stereo',
        dbLevelLeft: 'dB Level Left',
        dbLevelRight: 'dB Level Right',
        surroundPair: 'Surround Sound Pair',
        // Metadata
        freedPackets: 'Freed Packets',
        latency: 'Latency',
        metadataState: 'Metadata State',
        outputPackets: 'Output Packets',
        payloadBytes: 'Payload Bytes',
        receivedPackets: 'Received Packets',
        tcTimecodeValue: 'Timecode Value',
        // Clock Recovery
        clockCurrentStc: 'Current STC',
        clockReSyncCount: 'ReSync Count',
        clockStatus: 'Status',
        clockStcAvg: 'STC Avg (last 2 min)',
        clockTrackingMode: 'Tracking Mode',
        // HDR
        hdrColourPrimaries: 'Color Primaries',
        hdrMatrixCoefficients: 'Matrix Coefficients',
        hdrTransferCharacteristics: 'Transfer Characteristics',
        hdrType: 'Dynamic Range Output',
        hdrTypeIn: 'Dynamic Range In Stream',
      },
    },
    stream: {
      none: 'None',
      title: '{{stream}} Statistics',
      subTitle: 'STREAM STATISTICS',
      audioTitle: 'Stream Audio',
      srtVersionPrefix: 'version',
      section: {
        stream: 'STREAM INFO',
        statistics: 'STATISTICS',
        errors: 'ERRORS',
        srt: 'SRT',
        fec: 'Pro-MPEG FEC',
      },
      rows: {
        // STREAM
        id: 'ID',
        uptime: 'Uptime',
        decoder: 'Decoder',
        name: 'Name',
        // STATISTICS
        bitrate: 'Bitrate',
        connections: 'Connections',
        lastConnections: 'Last Connection',
        encapsulation: 'Encapsulation',
        lastReceived: 'Last Received',
        pcrPid: 'PCR PID',
        programNumber: 'Program Number',
        receivedBytes: 'Received Bytes',
        receivedPackets: 'Received Packets',
        outputBytes: 'Output Bytes',
        outputPackets: 'Output Packets',
        droppedBytes: 'Dropped Bytes',
        droppedPackets: 'Dropped Packets',
        lastPidDropped: 'Last PID Dropped',
        sourceAddress: 'Source Address',
        sourceBitrate: 'Bitrate',
        sourceCompression: 'Compression',
        sourceVideoCompression: 'Video Compression',
        sourceDts: 'DTS',
        sourceLayout: 'Layout',
        sourceProgramId: 'PID',
        sourcePts: 'PTS',
        sourceReceivedBytes: 'Received Bytes',
        sourceReceivedPackets: 'Received Packets',
        sourceLanguage: 'Language',
        streamSummary: 'Stream Summary',
        filteredOutPids: 'Filtered PIDs',
        // ERRORS
        corruptedFrames: 'Corrupted Frames',
        restarts: 'Resumed Stream Flags',
        // SRT
        authentication: 'Authentication',
        buffer: 'Local Buffer Level',
        detectedRedundancyMode: 'Redundancy',
        decryption: 'Decryption',
        encryption: 'Encryption',
        keyLength: 'Key Length',
        latency: 'Latency',
        linkBandwidth: 'Link Bandwidth',
        localEndpoint: 'Local Endpoint',
        localAddress: 'Local Address',
        localPort: 'Local Port',
        lostPackets: 'Lost Packets',
        pathState: 'State',
        pathName: 'Path Name',
        peerAddress: 'Peer Address',
        peerEndpoint: 'Peer Endpoint',
        peerPort: 'Peer Port',
        peerVersion: 'Peer Version',
        rxStreamID: 'Peer Publishing ID',
        reconnections: 'Reconnections',
        rejectedLastTime: 'Last Rejected',
        rejectedReason: 'Reason',
        rejectedStreamId: 'Rejected Publishing ID',
        localStreamID: 'Local Publishing ID',
        rtt: 'RTT',
        sentAcks: 'Sent ACKs',
        sentNaks: 'Sent NAKs',
        skippedPackets: 'Skipped Packets',
        srtMode: 'Mode',
        // FEC
        blockAligned: 'Block Aligned',
        level: 'Level',
        numberOfColumns: 'Number of Columns',
        numberOfRows: 'Number of Rows',
        recoveredPackets: 'Recovered Packets',
      },
    },
    chart: {
      network: 'Network: {{deviceName}}',
      bandwidth: 'Bandwidth',
      bandwidthTitle: 'BANDWIDTH USED',
      bandwidthTitleFull: 'Bandwidth Used',
      delay: 'Delay',
      delayTitle: 'DELAY',
      delayTitleFull: 'Delay',
      timescale: {
        optionsName: {
          last5min: '5 mins',
          last60min: '60 mins',
          last24hours: '24 hrs',
        },
      },
      csvValues: {
        AvailBw: 'Link BW',
        AvailBw_Path2: 'Link BW',
        eth0Rx: 'ETH0 Rx',
        eth0Tx: 'ETH0 Tx',
        eth1Rx: 'ETH1 Rx',
        eth1Tx: 'ETH1 Tx',
        LostRate: 'Lost Rate',
        LostRate_Path2: 'Lost Rate',
        PdDelay_Path2: 'Latency',
        PdDelay: 'Latency',
        RecvBufs_Path2: 'Receive Buffer',
        RecvBufs: 'Receive Buffer',
        RecvRate_Path2: 'Receive Rate',
        RecvRate: 'Receive Rate',
        RTT_Path2: 'Round Trip',
        RTT: 'Round Trip',
      },
      csvValuesDashboard: {
        AvailBw: 'Link BW',
        LostRate: 'Lost Rate',
        PdDelay: 'Latency',
        RecvBufs: 'Rx Buffer',
        RecvRate: 'Rx Rate',
        RTT: 'RTT',
        Displayed: 'Displayed',
        Replayed: 'Replayed',
        Skipped: 'Skipped',
      },
    },
    thumbnailTitle: 'OUTPUT REVIEW',
  },
};
