export const timezoneNames = new Map<string, string>([
  ['Etc/GMT+12', '(UTC/GMT-12:00)'],
  ['Etc/GMT+11', '(UTC/GMT-11:00)'],
  ['Pacific/Apia', '(GMT-11:00) Apia'],
  ['Pacific/Midway', '(GMT-11:00) Midway'],
  ['Pacific/Niue', '(GMT-11:00) Niue'],
  ['Pacific/Pago_Pago', '(GMT-11:00) Pago_Pago'],
  ['Pacific/Samoa', '(GMT-11:00) Samoa'],
  ['Etc/GMT+10', '(UTC/GMT-10:00)'],
  ['America/Adak', '(GMT-10:00) Adak'],
  ['Pacific/Fakaofo', '(GMT-10:00) Fakaofo'],
  ['Pacific/Honolulu', '(GMT-10:00) Hawaii Time - Honolulu'],
  ['Pacific/Johnston', '(GMT-10:00) Johnston'],
  ['Pacific/Rarotonga', '(GMT-10:00) Rarotonga'],
  ['Pacific/Tahiti', '(GMT-10:00) Tahiti'],
  ['Pacific/Marquesas', '(GMT-09:30) Marquesas'],
  ['Etc/GMT+9', '(UTC/GMT-9:00)'],
  ['America/Anchorage', '(GMT-09:00) Alaska Time - Anchorage'],
  ['America/Atka', '(GMT-09:00) Alaska Time - Atka'],
  ['Pacific/Gambier', '(GMT-09:00) Gambier'],
  ['America/Juneau', '(GMT-09:00) Alaska Time - Juneau'],
  ['America/Nome', '(GMT-09:00) Alaska Time - Nome'],
  ['America/Yakutat', '(GMT-09:00) Alaska Time - Yakutat'],
  ['Etc/GMT+8', '(UTC/GMT-8:00)'],
  ['America/Los_Angeles', '(GMT-08:00) Pacific Time - Los_Angeles'],
  ['America/Tijuana', '(GMT-08:00) Pacific Time - Tijuana'],
  ['America/Vancouver', '(GMT-08:00) Pacific Time - Vancouver'],
  ['America/Whitehorse', '(GMT-08:00) Pacific Time - Whitehorse'],
  ['Pacific/Pitcairn', '(GMT-08:00) Pitcairn'],
  ['Etc/GMT+7', '(UTC/GMT-7:00)'],
  ['America/Boise', '(GMT-07:00) Mountain Time - Boise'],
  ['America/Cambridge_Bay', '(GMT-07:00) Mountain Time - Cambridge_Bay'],
  ['America/Ensenada', '(GMT-07:00) Ensenada'],
  ['America/Inuvik', '(GMT-07:00) Inuvik'],
  ['America/Chihuahua', '(GMT-07:00) Mountain Time - Chihuahua'],
  ['America/Dawson', '(GMT-07:00) Mountain Time - Dawson'],
  ['America/Dawson_Creek', '(GMT-07:00) Mountain Time - Dawson Creek'],
  ['America/Denver', '(GMT-07:00) Mountain Time - Denver'],
  ['America/Edmonton', '(GMT-07:00) Mountain Time - Edmonton'],
  ['America/Hermosillo', '(GMT-07:00) Mountain Time - Hermosillo'],
  ['America/Mazatlan', '(GMT-07:00) Mountain Time - Mazatlan'],
  ['America/Yellowknife', '(GMT-07:00) Mountain Time - Yellowknife'],
  ['America/Phoenix', '(GMT-07:00) Mountain Time - Phoenix'],
  ['America/Shiprock', '(GMT-07:00) Mountain Time - Shiprock'],
  ['Etc/GMT+6', '(UTC/GMT-6:00)'],
  ['America/Belize', '(GMT-06:00) Belize'],
  ['America/Cancun', '(GMT-06:00) Cancun'],
  ['America/North_Dakota', '(GMT-06:00) Center North Dakota'],
  ['America/Chicago', '(GMT-06:00) Central Time - Chicago'],
  ['America/Mexico_City', '(GMT-06:00) Central Time - Mexico City'],
  ['America/Regina', '(GMT-06:00) Central Time - Regina'],
  ['America/Tegucigalpa', '(GMT-06:00) Central Time - Tegucigalpa'],
  ['America/Winnipeg', '(GMT-06:00) Central Time - Winnipeg'],
  ['America/Costa_Rica', '(GMT-06:00) Costa_Rica'],
  ['Pacific/Easter', '(GMT-06:00) Easter'],
  ['America/El_Salvador', '(GMT-06:00) El_Salvador'],
  ['Pacific/Galapagos', '(GMT-06:00) Galapagos'],
  ['America/Guatemala', '(GMT-06:00) Guatemala'],
  ['America/Indiana/Knox', '(GMT-06:00) Central Time - Knox'],
  ['America/Managua', '(GMT-06:00) Managua'],
  ['America/Menominee', '(GMT-06:00) Central Time - Menominee'],
  ['America/Merida', '(GMT-06:00) Merida'],
  ['America/Monterrey', '(GMT-06:00) Monterrey'],
  ['America/North_Dakota/New_Salem', '(GMT-06:00) Central Time - New Salem'],
  ['America/Rainy_River', '(GMT-06:00) Central Time - Rainy River'],
  ['America/Rankin_Inlet', '(GMT-06:00) Central Time - Rankin Inlet'],
  ['America/Swift_Current', '(GMT-06:00) Central Time - Swift Current'],
  ['America/Indiana/Tell_City', '(GMT-06:00) Central Time - Tell City'],
  ['Etc/GMT+5', '(UTC/GMT-5:00)'],
  ['America/Atikokan', '(GMT-05:00) Eastern Time - Atikokan'],
  ['America/Bogota', '(GMT-05:00) Bogota'],
  ['America/Cayman', '(GMT-05:00) Cayman'],
  ['America/Coral_Harbour', '(GMT-05:00) Eastern Time - Coral_Harbour'],
  ['America/Detroit', '(GMT-05:00) Eastern Time - Detroit'],
  ['America/Iqaluit', '(GMT-05:00) Eastern Time - Iqaluit'],
  ['America/Montreal', '(GMT-05:00) Eastern Time - Montreal'],
  ['America/Toronto', '(GMT-05:00) Eastern Time - Toronto'],
  ['America/Eirunepe', '(GMT-05:00) Eirunepe'],
  ['America/Fort_Wayne', '(GMT-05:00) Eastern Time - Fort_Wayne'],
  ['America/Grand_Turk', '(GMT-05:00) Grand_Turk'],
  ['America/Guayaquil', '(GMT-05:00) Guayaquil'],
  ['America/Havana', '(GMT-05:00) Havana'],
  ['America/Indiana/Indianapolis', '(GMT-05:00) Eastern Time - Indianapolis'],
  ['America/Jamaica', '(GMT-05:00) Jamaica'],
  ['America/Lima', '(GMT-05:00) Lima'],
  ['America/Kentucky/Louisville', '(GMT-05:00) Louisville'],
  ['America/Indiana/Marengo', '(GMT-05:00) Marengo'],
  ['America/Kentucky/Monticello', '(GMT-05:00) Monticello'],
  ['America/Nassau', '(GMT-05:00) Nassau'],
  ['America/New_York', '(GMT-05:00) Eastern Time - New York'],
  ['America/Nipigon', '(GMT-05:00) Nipigon'],
  ['America/Panama', '(GMT-05:00) Panama'],
  ['America/Pangnirtung', '(GMT-05:00) Pangnirtung'],
  ['America/Indiana/Petersburg', '(GMT-05:00) Petersburg'],
  ['America/Port-au-Prince', '(GMT-05:00) Port-au-Prince'],
  ['America/Porto_Acre', '(GMT-05:00) Porto_Acre'],
  ['America/Resolute', '(GMT-05:00) Resolute'],
  ['America/Thunder_Bay', '(GMT-05:00) Thunder_Bay'],
  ['America/Indiana/Vevay', '(GMT-05:00) Vevay'],
  ['America/Indiana/Vincennes', '(GMT-05:00) Vincennes'],
  ['America/Indiana/Winamac', '(GMT-05:00) Winamac'],
  ['America/Caracas', '(GMT-04:30) Caracas'],
  ['Etc/GMT+4', '(UTC/GMT-4:00)'],
  ['America/Anguilla', '(GMT-04:00) Anguilla'],
  ['America/Antigua', '(GMT-04:00) Antigua'],
  ['America/Aruba', '(GMT-04:00) Aruba'],
  ['America/Asuncion', '(GMT-04:00) Asuncion'],
  ['America/Barbados', '(GMT-04:00) Barbados'],
  ['Atlantic/Bermuda', '(GMT-04:00) Bermuda'],
  ['America/Blanc-Sablon', '(GMT-04:00) Blanc-Sablon'],
  ['America/Boa_Vista', '(GMT-04:00) Boa_Vista'],
  ['America/Campo_Grande', '(GMT-04:00) Campo_Grande'],
  ['America/Cuiaba', '(GMT-04:00) Cuiaba'],
  ['America/Curacao', '(GMT-04:00) Curacao'],
  ['America/Dominica', '(GMT-04:00) Dominica'],
  ['America/Glace_Bay', '(GMT-04:00) Glace_Bay'],
  ['America/Goose_Bay', '(GMT-04:00) Goose_Bay'],
  ['America/Grenada', '(GMT-04:00) Grenada'],
  ['America/Guadeloupe', '(GMT-04:00) Guadeloupe'],
  ['America/Guyana', '(GMT-04:00) Guyana'],
  ['America/Halifax', '(GMT-04:00) Atlantic Time - Halifax'],
  ['America/La_Paz', '(GMT-04:00) La_Paz'],
  ['America/Manaus', '(GMT-04:00) Manaus'],
  ['America/Marigot', '(GMT-04:00) Marigot'],
  ['America/Martinique', '(GMT-04:00) Martinique'],
  ['America/Moncton', '(GMT-04:00) Moncton'],
  ['America/Montserrat', '(GMT-04:00) Montserrat'],
  ['Antarctica/Palmer', '(GMT-04:00) Palmer'],
  ['America/Port_of_Spain', '(GMT-04:00) Port-of-Spain'],
  ['America/Porto_Velho', '(GMT-04:00) Porto_Velho'],
  ['America/Puerto_Rico', '(GMT-04:00) Puerto_Rico'],
  ['America/Rio_Branco', '(GMT-04:00) Rio_Branco'],
  ['America/Argentina/San_Juan', '(GMT-04:00) San_Juan'],
  ['America/Santiago', '(GMT-04:00) Santiago'],
  ['America/Santo_Domingo', '(GMT-04:00) Santo_Domingo'],
  ['America/St_Barthelemy', '(GMT-04:00) St_Barthelemy'],
  ['America/St_Kitts', '(GMT-04:00) St_Kitts'],
  ['America/St_Lucia', '(GMT-04:00) St_Lucia'],
  ['America/St_Thomas', '(GMT-04:00) St_Thomas'],
  ['America/St_Vincent', '(GMT-04:00) St_Vincent'],
  ['Atlantic/Stanley', '(GMT-04:00) Stanley'],
  ['America/Thule', '(GMT-04:00) Thule'],
  ['America/Tortola', '(GMT-04:00) Tortola'],
  ['America/Virgin', '(GMT-04:00) Virgin'],
  ['America/St_Johns', '(GMT-03:30) Newfoundland Time - St.Johns'],
  ['Etc/GMT+3', '(UTC/GMT-3:00)'],
  ['America/Araguaina', '(GMT-03:00) Araguaina'],
  ['America/Bahia', '(GMT-03:00) Bahia'],
  ['America/Belem', '(GMT-03:00) Belem'],
  ['America/Argentina/Buenos_Aires', '(GMT-03:00) Buenos_Aires'],
  ['America/Argentina/Catamarca', '(GMT-03:00) Catamarca'],
  ['America/Cayenne', '(GMT-03:00) Cayenne'],
  ['America/Argentina/ComodRivadavia', '(GMT-03:00) ComodRivadavia'],
  ['America/Argentina/Cordoba', '(GMT-03:00) Cordoba'],
  ['America/Fortaleza', '(GMT-03:00) Fortaleza'],
  ['America/Godthab', '(GMT-03:00) Godthab'],
  ['America/Argentina/Jujuy', '(GMT-03:00) Jujuy'],
  ['America/Argentina/La_Rioja', '(GMT-03:00) La_Rioja'],
  ['America/Maceio', '(GMT-03:00) Maceio'],
  ['America/Argentina/Mendoza', '(GMT-03:00) Mendoza'],
  ['America/Miquelon', '(GMT-03:00) Miquelon'],
  ['America/Montevideo', '(GMT-03:00) Montevideo'],
  ['America/Paramaribo', '(GMT-03:00) Paramaribo'],
  ['America/Recife', '(GMT-03:00) Recife'],
  ['America/Argentina/Rio_Gallegos', '(GMT-03:00) Rio_Gallegos'],
  ['America/Rosario', '(GMT-03:00) Rosario'],
  ['Antarctica/Rothera', '(GMT-03:00) Rothera'],
  ['America/Sao_Paulo', '(GMT-03:00) Sao_Paulo'],
  ['America/Argentina/Tucuman', '(GMT-03:00) Tucuman'],
  ['America/Argentina/Ushuaia', '(GMT-03:00) Ushuaia'],
  ['Etc/GMT+2', '(UTC/GMT-2:00)'],
  ['America/Noronha', '(GMT-02:00) Noronha'],
  ['Atlantic/South_Georgia', '(GMT-02:00) South_Georgia'],
  ['Etc/GMT+1', '(UTC/GMT-1:00)'],
  ['Atlantic/Azores', '(GMT-01:00) Azores'],
  ['Atlantic/Cape_Verde', '(GMT-01:00) Cape_Verde'],
  ['America/Scoresbysund', '(GMT-01:00) Scoresbysund'],
  ['Etc/UTC', '(UTC)'],
  ['Etc/GMT', '(GMT)'],
  ['Africa/Abidjan', '(GMT+00:00) Abidjan'],
  ['Africa/Accra', '(GMT+00:00) Accra'],
  ['Africa/Bamako', '(GMT+00:00) Bamako'],
  ['Africa/Banjul', '(GMT+00:00) Banjul'],
  ['Europe/Belfast', '(GMT+00:00) Belfast'],
  ['Africa/Bissau', '(GMT+00:00) Bissau'],
  ['Atlantic/Canary', '(GMT+00:00) Canary'],
  ['Africa/Casablanca', '(GMT+00:00) Casablanca'],
  ['Africa/Conakry', '(GMT+00:00) Conakry'],
  ['Africa/Dakar', '(GMT+00:00) Dakar'],
  ['America/Danmarkshavn', '(GMT+00:00) Danmarkshavn'],
  ['Europe/Dublin', '(GMT+00:00) Dublin'],
  ['Africa/El_Aaiun', '(GMT+00:00) El_Aaiun'],
  ['Atlantic/Faeroe', '(GMT+00:00) Faeroe'],
  ['Africa/Freetown', '(GMT+00:00) Freetown'],
  ['Europe/Guernsey', '(GMT+00:00) Guernsey'],
  ['Europe/Isle_of_Man', '(GMT+00:00) Isle_of_Man'],
  ['Europe/Jersey', '(GMT+00:00) Jersey'],
  ['Europe/Lisbon', '(GMT+00:00) Lisbon'],
  ['Africa/Lome', '(GMT+00:00) Lome'],
  ['Europe/London', '(GMT+00:00) London'],
  ['Atlantic/Madeira', '(GMT+00:00) Madeira'],
  ['Africa/Monrovia', '(GMT+00:00) Monrovia'],
  ['Africa/Nouakchott', '(GMT+00:00) Nouakchott'],
  ['Africa/Ouagadougou', '(GMT+00:00) Ouagadougou'],
  ['Atlantic/Reykjavik', '(GMT+00:00) Reykjavik'],
  ['Africa/Sao_Tome', '(GMT+00:00) Sao_Tome'],
  ['Atlantic/St_Helena', '(GMT+00:00) St_Helena'],
  ['Africa/Timbuktu', '(GMT+00:00) Timbuktu'],
  ['Etc/GMT-1', '(UTC/GMT+1:00)'],
  ['Africa/Algiers', '(GMT+01:00) Algiers'],
  ['Europe/Amsterdam', '(GMT+01:00) Amsterdam'],
  ['Europe/Andorra', '(GMT+01:00) Andorra'],
  ['Africa/Bangui', '(GMT+01:00) Bangui'],
  ['Europe/Berlin', '(GMT+01:00) Berlin'],
  ['Europe/Bratislava', '(GMT+01:00) Bratislava'],
  ['Africa/Brazzaville', '(GMT+01:00) Brazzaville'],
  ['Europe/Brussels', '(GMT+01:00) Brussels'],
  ['Europe/Budapest', '(GMT+01:00) Budapest'],
  ['Europe/Belgrade', '(GMT+01:00) Central European Time - Belgrade'],
  ['Europe/Prague', '(GMT+01:00) Central European Time - Prague'],
  ['Africa/Ceuta', '(GMT+01:00) Ceuta'],
  ['Europe/Copenhagen', '(GMT+01:00) Copenhagen'],
  ['Africa/Douala', '(GMT+01:00) Douala'],
  ['Europe/Gibraltar', '(GMT+01:00) Gibraltar'],
  ['Atlantic/Jan_Mayen', '(GMT+01:00) Jan_Mayen'],
  ['Africa/Kinshasa', '(GMT+01:00) Kinshasa'],
  ['Africa/Lagos', '(GMT+01:00) Lagos'],
  ['Africa/Libreville', '(GMT+01:00) Libreville'],
  ['Europe/Ljubljana', '(GMT+01:00) Ljubljana'],
  ['Arctic/Longyearbyen', '(GMT+01:00) Longyearbyen'],
  ['Africa/Luanda', '(GMT+01:00) Luanda'],
  ['Europe/Luxembourg', '(GMT+01:00) Luxembourg'],
  ['Europe/Madrid', '(GMT+01:00) Madrid'],
  ['Africa/Malabo', '(GMT+01:00) Malabo'],
  ['Europe/Malta', '(GMT+01:00) Malta'],
  ['Europe/Monaco', '(GMT+01:00) Monaco'],
  ['Africa/Ndjamena', '(GMT+01:00) Ndjamena'],
  ['Africa/Niamey', '(GMT+01:00) Niamey'],
  ['Europe/Oslo', '(GMT+01:00) Oslo'],
  ['Europe/Paris', '(GMT+01:00) Paris'],
  ['Europe/Podgorica', '(GMT+01:00) Podgorica'],
  ['Africa/Porto-Novo', '(GMT+01:00) Porto-Novo'],
  ['Europe/Rome', '(GMT+01:00) Rome'],
  ['Europe/San_Marino', '(GMT+01:00) San_Marino'],
  ['Europe/Sarajevo', '(GMT+01:00) Sarajevo'],
  ['Europe/Skopje', '(GMT+01:00) Skopje'],
  ['Europe/Stockholm', '(GMT+01:00) Stockholm'],
  ['Europe/Tirane', '(GMT+01:00) Tirane'],
  ['Africa/Tunis', '(GMT+01:00) Tunis'],
  ['Europe/Vaduz', '(GMT+01:00) Vaduz'],
  ['Europe/Vatican', '(GMT+01:00) Vatican'],
  ['Europe/Vienna', '(GMT+01:00) Vienna'],
  ['Europe/Warsaw', '(GMT+01:00) Warsaw'],
  ['Africa/Windhoek', '(GMT+01:00) Windhoek'],
  ['Europe/Zagreb', '(GMT+01:00) Zagreb'],
  ['Europe/Zurich', '(GMT+01:00) Zurich'],
  ['Etc/GMT-2', '(UTC/GMT+2:00)'],
  ['Asia/Amman', '(GMT+02:00) Amman'],
  ['Europe/Athens', '(GMT+02:00) Athens'],
  ['Asia/Beirut', '(GMT+02:00) Beirut'],
  ['Africa/Blantyre', '(GMT+02:00) Blantyre'],
  ['Europe/Bucharest', '(GMT+02:00) Bucharest'],
  ['Africa/Bujumbura', '(GMT+02:00) Bujumbura'],
  ['Africa/Cairo', '(GMT+02:00) Cairo'],
  ['Europe/Chisinau', '(GMT+02:00) Chisinau'],
  ['Asia/Damascus', '(GMT+02:00) Damascus'],
  ['Africa/Gaborone', '(GMT+02:00) Gaborone'],
  ['Asia/Gaza', '(GMT+02:00) Gaza'],
  ['Africa/Harare', '(GMT+02:00) Harare'],
  ['Europe/Helsinki', '(GMT+02:00) Helsinki'],
  ['Asia/Istanbul', '(GMT+02:00) Istanbul'],
  ['Asia/Jerusalem', '(GMT+02:00) Jerusalem'],
  ['Africa/Johannesburg', '(GMT+02:00) Johannesburg'],
  ['Europe/Kiev', '(GMT+02:00) Kiev'],
  ['Africa/Kigali', '(GMT+02:00) Kigali'],
  ['Africa/Lubumbashi', '(GMT+02:00) Lubumbashi'],
  ['Africa/Lusaka', '(GMT+02:00) Lusaka'],
  ['Africa/Maputo', '(GMT+02:00) Maputo'],
  ['Europe/Mariehamn', '(GMT+02:00) Mariehamn'],
  ['Africa/Maseru', '(GMT+02:00) Maseru'],
  ['Africa/Mbabane', '(GMT+02:00) Mbabane'],
  ['Europe/Minsk', '(GMT+02:00) Minsk'],
  ['Europe/Kaliningrad', '(GMT+02:00) Moscow-01 - Kaliningrad'],
  ['Asia/Nicosia', '(GMT+02:00) Nicosia'],
  ['Europe/Riga', '(GMT+02:00) Riga'],
  ['Europe/Simferopol', '(GMT+02:00) Simferopol'],
  ['Europe/Sofia', '(GMT+02:00) Sofia'],
  ['Europe/Tallinn', '(GMT+02:00) Tallinn'],
  ['Asia/Tel_Aviv', '(GMT+02:00) Tel_Aviv'],
  ['Europe/Tiraspol', '(GMT+02:00) Tiraspol'],
  ['Africa/Tripoli', '(GMT+02:00) Tripoli'],
  ['Europe/Uzhgorod', '(GMT+02:00) Uzhgorod'],
  ['Europe/Vilnius', '(GMT+02:00) Vilnius'],
  ['Europe/Zaporozhye', '(GMT+02:00) Zaporozhye'],
  ['Etc/GMT-3', '(UTC/GMT+3:00)'],
  ['Africa/Addis_Ababa', '(GMT+03:00) Addis_Ababa'],
  ['Asia/Aden', '(GMT+03:00) Aden'],
  ['Indian/Antananarivo', '(GMT+03:00) Antananarivo'],
  ['Africa/Asmara', '(GMT+03:00) Asmara'],
  ['Africa/Asmera', '(GMT+03:00) Asmera'],
  ['Asia/Baghdad', '(GMT+03:00) Baghdad'],
  ['Asia/Bahrain', '(GMT+03:00) Bahrain'],
  ['Indian/Comoro', '(GMT+03:00) Comoro'],
  ['Africa/Dar_es_Salaam', '(GMT+03:00) Dar_es_Salaam'],
  ['Africa/Djibouti', '(GMT+03:00) Djibouti'],
  ['Africa/Kampala', '(GMT+03:00) Kampala'],
  ['Africa/Khartoum', '(GMT+03:00) Khartoum'],
  ['Asia/Kuwait', '(GMT+03:00) Kuwait'],
  ['Indian/Mayotte', '(GMT+03:00) Mayotte'],
  ['Africa/Mogadishu', '(GMT+03:00) Mogadishu'],
  ['Europe/Moscow', '(GMT+03:00) Moscow+00'],
  ['Africa/Nairobi', '(GMT+03:00) Nairobi'],
  ['Asia/Qatar', '(GMT+03:00) Qatar'],
  ['Asia/Riyadh', '(GMT+03:00) Riyadh'],
  ['Antarctica/Syowa', '(GMT+03:00) Syowa'],
  ['Europe/Volgograd', '(GMT+03:00) Volgograd'],
  ['Asia/Tehran', '(GMT+03:30) Tehran'],
  ['Etc/GMT-4', '(UTC/GMT+4:00)'],
  ['Asia/Baku', '(GMT+04:00) Baku'],
  ['Asia/Dubai', '(GMT+04:00) Dubai'],
  ['Indian/Mahe', '(GMT+04:00) Mahe'],
  ['Indian/Mauritius', '(GMT+04:00) Mauritius'],
  ['Europe/Samara', '(GMT+04:00) Moscow+00 - Samara'],
  ['Asia/Muscat', '(GMT+04:00) Muscat'],
  ['Indian/Reunion', '(GMT+04:00) Reunion'],
  ['Asia/Tbilisi', '(GMT+04:00) Tbilisi'],
  ['Asia/Yerevan', '(GMT+04:00) Yerevan'],
  ['Asia/Kabul', '(GMT+04:30) Kabul'],
  ['Etc/GMT-5', '(UTC/GMT+5:00)'],
  ['Asia/Aqtau', '(GMT+05:00) Aqtau'],
  ['Asia/Aqtobe', '(GMT+05:00) Aqtobe'],
  ['Asia/Ashgabat', '(GMT+05:00) Ashgabat'],
  ['Asia/Dushanbe', '(GMT+05:00) Dushanbe'],
  ['Asia/Karachi', '(GMT+05:00) Karachi'],
  ['Indian/Kerguelen', '(GMT+05:00) Kerguelen'],
  ['Indian/Maldives', '(GMT+05:00) Maldives'],
  ['Asia/Yekaterinburg', '(GMT+05:00) Moscow+02 - Yekaterinburg'],
  ['Asia/Oral', '(GMT+05:00) Oral'],
  ['Asia/Samarkand', '(GMT+05:00) Samarkand'],
  ['Asia/Tashkent', '(GMT+05:00) Tashkent'],
  ['Asia/Calcutta', '(GMT+05:30) Calcutta'],
  ['Asia/Colombo', '(GMT+05:30) Colombo'],
  ['Asia/Katmandu', '(GMT+05:45) Katmandu'],
  ['Etc/GMT-6', '(UTC/GMT+6:00)'],
  ['Asia/Almaty', '(GMT+06:00) Almaty'],
  ['Asia/Bishkek', '(GMT+06:00) Bishkek'],
  ['Indian/Chagos', '(GMT+06:00) Chagos'],
  ['Asia/Dhaka', '(GMT+06:00) Dhaka'],
  ['Antarctica/Mawson', '(GMT+06:00) Mawson'],
  ['Asia/Novosibirsk', '(GMT+06:00) Moscow+03 - Novosibirsk'],
  ['Asia/Omsk', '(GMT+06:00) Moscow+03 - Omsk'],
  ['Asia/Qyzylorda', '(GMT+06:00) Qyzylorda'],
  ['Asia/Thimphu', '(GMT+06:00) Thimphu'],
  ['Antarctica/Vostok', '(GMT+06:00) Vostok'],
  ['Indian/Cocos', '(GMT+06:30) Cocos'],
  ['Asia/Rangoon', '(GMT+06:30) Rangoon'],
  ['Etc/GMT-7', '(UTC/GMT+7:00)'],
  ['Asia/Bangkok', '(GMT+07:00) Bangkok'],
  ['Indian/Christmas', '(GMT+07:00) Christmas'],
  ['Antarctica/Davis', '(GMT+07:00) Davis'],
  ['Asia/Hovd', '(GMT+07:00) Hovd'],
  ['Asia/Jakarta', '(GMT+07:00) Jakarta'],
  ['Asia/Krasnoyarsk', '(GMT+07:00) Moscow+04 - Krasnoyarsk'],
  ['Asia/Phnom_Penh', '(GMT+07:00) Phnom_Penh'],
  ['Asia/Pontianak', '(GMT+07:00) Pontianak'],
  ['Asia/Saigon', '(GMT+07:00) Saigon'],
  ['Asia/Vientiane', '(GMT+07:00) Vientiane'],
  ['Etc/GMT-8', '(UTC/GMT+8:00)'],
  ['Asia/Brunei', '(GMT+08:00) Brunei'],
  ['Antarctica/Casey', '(GMT+08:00) Casey'],
  ['Asia/Choibalsan', '(GMT+08:00) Choibalsan'],
  ['Asia/Chungking', '(GMT+08:00) Chungking'],
  ['Asia/Harbin', '(GMT+08:00) Harbin'],
  ['Asia/Hong_Kong', '(GMT+08:00) Hong_Kong'],
  ['Asia/Kashgar', '(GMT+08:00) Kashgar'],
  ['Asia/Kuala_Lumpur', '(GMT+08:00) Kuala_Lumpur'],
  ['Asia/Kuching', '(GMT+08:00) Kuching'],
  ['Asia/Macau', '(GMT+08:00) Macau'],
  ['Asia/Makassar', '(GMT+08:00) Makassar'],
  ['Asia/Manila', '(GMT+08:00) Manila'],
  ['Asia/Irkutsk', '(GMT+08:00) Moscow+05 - Irkutsk'],
  ['Asia/Shanghai', '(GMT+08:00) Shanghai'],
  ['Asia/Singapore', '(GMT+08:00) Singapore'],
  ['Asia/Taipei', '(GMT+08:00) Taipei'],
  ['Asia/Ujung_Pandang', '(GMT+08:00) Ujung_Pandang'],
  ['Asia/Ulaanbaatar', '(GMT+08:00) Ulaanbaatar'],
  ['Asia/Urumqi', '(GMT+08:00) Urumqi'],
  ['Australia/West', '(GMT+08:00) West'],
  ['Australia/Perth', '(GMT+08:00) Western Time - Perth'],
  ['Australia/Eucla', '(GMT+08:45) Eucla'],
  ['Etc/GMT-9', '(UTC/GMT+9:00)'],
  ['Asia/Dili', '(GMT+09:00) Dili'],
  ['Asia/Jayapura', '(GMT+09:00) Jayapura'],
  ['Asia/Yakutsk', '(GMT+09:00) Moscow+06 - Yakutsk'],
  ['Pacific/Palau', '(GMT+09:00) Palau'],
  ['Asia/Pyongyang', '(GMT+09:00) Pyongyang'],
  ['Asia/Seoul', '(GMT+09:00) Seoul'],
  ['Asia/Tokyo', '(GMT+09:00) Tokyo'],
  ['Australia/Broken_Hill', '(GMT+09:30) Broken_Hill'],
  ['Australia/Adelaide', '(GMT+09:30) Central Time - Adelaide'],
  ['Australia/Darwin', '(GMT+09:30) Central Time - Darwin'],
  ['Australia/Yancowinna', '(GMT+09:30) Yancowinna'],
  ['Etc/GMT-10', '(UTC/GMT+10:00)'],
  ['Australia/Canberra', '(GMT+10:00) Canberra'],
  ['Australia/Currie', '(GMT+10:00) Currie'],
  ['Antarctica/DumontDUrville', '(GMT+10:00) DumontDUrville'],
  ['Australia/Brisbane', '(GMT+10:00) Eastern Time - Brisbane'],
  ['Australia/Hobart', '(GMT+10:00) Eastern Time - Hobart'],
  ['Australia/Melbourne', '(GMT+10:00) Eastern Time - Melbourne'],
  ['Australia/Sydney', '(GMT+10:00) Eastern Time - Sydney'],
  ['Pacific/Guam', '(GMT+10:00) Guam'],
  ['Australia/Lindeman', '(GMT+10:00) Lindeman'],
  ['Asia/Sakhalin', '(GMT+10:00) Moscow+07 - Sakhalin'],
  ['Australia/NSW', '(GMT+10:00) NSW'],
  ['Pacific/Port_Moresby', '(GMT+10:00) Port_Moresby'],
  ['Australia/Queensland', '(GMT+10:00) Queensland'],
  ['Pacific/Saipan', '(GMT+10:00) Saipan'],
  ['Australia/Tasmania', '(GMT+10:00) Tasmania'],
  ['Pacific/Truk', '(GMT+10:00) Truk'],
  ['Australia/Victoria', '(GMT+10:00) Victoria'],
  ['Asia/Vladivostok', '(GMT+10:00) Vladivostok'],
  ['Pacific/Yap', '(GMT+10:00) Yap'],
  ['Australia/Lord_Howe', '(GMT+10:30) Lord Howe Island'],
  ['Etc/GMT-11', '(UTC/GMT+11:00)'],
  ['Asia/Anadyr', '(GMT+11:00) Anadyr'],
  ['Pacific/Efate', '(GMT+11:00) Efate'],
  ['Pacific/Guadalcanal', '(GMT+11:00) Guadalcanal'],
  ['Pacific/Kosrae', '(GMT+11:00) Kosrae'],
  ['Asia/Magadan', '(GMT+11:00) Moscow+08 - Magadan'],
  ['Pacific/Noumea', '(GMT+11:00) Noumea'],
  ['Pacific/Ponape', '(GMT+11:00) Ponape'],
  ['Pacific/Norfolk', '(GMT+11:30) Norfolk'],
  ['Etc/GMT-12', '(UTC/GMT+12:00)'],
  ['Pacific/Auckland', '(GMT+12:00) Auckland'],
  ['Pacific/Fiji', '(GMT+12:00) Fiji'],
  ['Pacific/Funafuti', '(GMT+12:00) Funafuti'],
  ['Pacific/Kwajalein', '(GMT+12:00) Kwajalein'],
  ['Pacific/Majuro', '(GMT+12:00) Majuro'],
  ['Antarctica/McMurdo', '(GMT+12:00) McMurdo'],
  ['Asia/Kamchatka', '(GMT+12:00) Moscow+08 - Kamchatka'],
  ['Pacific/Nauru', '(GMT+12:00) Nauru'],
  ['Antarctica/South_Pole', '(GMT+12:00) South_Pole'],
  ['Pacific/Tarawa', '(GMT+12:00) Tarawa'],
  ['Pacific/Wake', '(GMT+12:00) Wake'],
  ['Pacific/Wallis', '(GMT+12:00) Wallis'],
  ['Pacific/Chatham', '(GMT+12:45) Chatham'],
  ['Etc/GMT-13', '(UTC/GMT+13:00)'],
  ['Pacific/Enderbury', '(GMT+13:00) Enderbury'],
  ['Pacific/Tongatapu', '(GMT+13:00) Tongatapu'],
  ['Etc/GMT-14', '(UTC/GMT+14:00)'],
  ['Pacific/Kiritimati', '(GMT+14:00) Kiritimati'],
]);
