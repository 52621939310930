import { Icon, OverlayTrigger } from '@hai/ui-react';
import classNames from 'classnames';
import { TFunctionResult } from 'i18next';
import React from 'react';
import { generateDataAuto } from 'src/js/util/automation';
import { isNilOrEmpty } from 'src/js/util/global-util';

interface Props {
  /** Either a string like "262 [0.14%]" or an array of two strings */
  value: number | string | TFunctionResult | Array<string>;
}

const FormatString = (props: Props) => {
  const { value } = props;
  if (isNilOrEmpty(value)) {
    return undefined;
  }
  // Separate the string into it's various componments i.e. value [percentage] (units)
  let pureArray = true;
  let valueArr = value as Array<string>;
  if (!Array.isArray(value)) {
    pureArray = false;
    valueArr = value.toString().split(/\[|\(/);
  }
  if (valueArr.length === 2) {
    return (
      <div className="d-flex mk-dual-format">
        {valueArr[0]}
        <div className="hai-ml-1 hai-gray-06">{pureArray ? valueArr[1] : valueArr[1].split(/\]|\)/)[0]}</div>
      </div>
    );
  } else {
    return <>{value}</>;
  }
};

export interface StatsRowViewModel {
  name: string;
  value: string | number | TFunctionResult;
  value2?: string | number | TFunctionResult;
  twoColumns?: boolean;
  className?: string;
  warning?: string;
  formatString?: boolean;
  mask?: boolean;
}

export const StatisticRow: React.FunctionComponent<StatsRowViewModel> = ({
  name,
  value,
  value2,
  twoColumns,
  className,
  warning,
  formatString = false,
  mask,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classNames(
        'box mk-stats-row',
        twoColumns && 'twoColumns',
        !isNilOrEmpty(warning) && 'warning',
        className,
      )}
    >
      <span>{name}</span>
      <span>
        <div
          className={classNames('dataColumn tnum', mask && 'fs-mask')}
          title={value?.toString()}
          data-auto={generateDataAuto('stats', 'row value')}
        >
          {isNilOrEmpty(warning) ? (
            <>{formatString ? <FormatString value={value} /> : value?.toString()}</>
          ) : (
            <>
              <OverlayTrigger placement="top-center" popoverContent={warning}>
                <div className="d-flex align-items-center">
                  {value?.toString()}
                  <Icon className="hai-ml-1" iconname="StatusWarning" size="sm3" />
                </div>
              </OverlayTrigger>
            </>
          )}
        </div>
      </span>
      {twoColumns && (
        <span>
          <div className={classNames('dataColumn tnum', mask && 'fs-mask')} title={value2?.toString()}>
            <>{formatString ? <FormatString value={value2?.toString()} /> : value2?.toString()}</>
          </div>
        </span>
      )}
    </div>
  );
};
