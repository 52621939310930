import { Icon, Spinner, IconButton } from '@hai/ui-react';
import React from 'react';

interface Busy {
  busy: boolean;
  /** <IconButton> vs <Icon> */
  iconButton?: boolean;
  color?: string;
  disabled?: boolean;
  isActive: boolean;
  activeIcon: string;
  inactiveIcon: string;
  tooltip?: string;
  onClick?: (ev: any) => void;
}

const BusyIcon: React.FunctionComponent<Busy> = ({
  busy,
  iconButton,
  color,
  disabled,
  isActive,
  activeIcon,
  inactiveIcon,
  tooltip,
  onClick,
}: Busy) => {
  return busy ? (
    <Spinner loopTime={1} style={{ width: '18px', height: '18px' }} />
  ) : iconButton ? (
    <IconButton disabled={disabled} onClick={onClick} title={tooltip}>
      <Icon iconname={isActive ? activeIcon : inactiveIcon} color={color} />
    </IconButton>
  ) : (
    <Icon
      iconname={isActive ? activeIcon : inactiveIcon}
      color={color}
      disabled={disabled}
      onClick={onClick}
      title={tooltip}
    />
  );
};

export default BusyIcon;
