import React from 'react';
import { useTranslation } from 'react-i18next';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { StreamSrtStatsResponse } from 'src/js/model/api/response/stream-srt-stats-response';
import { LineChart } from 'src/js/pages/statistic/plot/line-chart';
import { mapStreamGenericStatisticToPlotData } from 'src/js/pages/statistic/statistic-mapper';
import { StreamViewModel } from 'src/js/pages/streaming/stream/stream-models';
import { chartColors } from 'src/js/theme/chart';
import { constant } from 'src/js/constant';

interface Props {
  header?: React.ReactNode;
  model: StreamViewModel;
  graphData: StreamSrtStatsResponse[];
  timescale: StatsInterval;
  width?: number;
  height?: number;
  fullpage?: boolean;
}

const emptyDefaultInactiveFields: string[] = [];
export const StreamGenericPlotContainer: React.FunctionComponent<Props> = ({
  graphData,
  header,
  timescale,
  width,
  height,
  fullpage,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mk-graph-container">
      {header}

      {graphData != null && (
        <>
          <LineChart
            data={mapStreamGenericStatisticToPlotData(graphData, constant.chart.stream.genericFields, t)}
            defaultInactiveFields={emptyDefaultInactiveFields}
            height={height}
            lineColors={chartColors.stream.generic}
            showLegend={true}
            timescale={timescale}
            title={fullpage ? t('statistics.chart.bandwidthTitleFull') : t('statistics.chart.bandwidthTitle')}
            width={width}
            yTitle="kbps"
          />
        </>
      )}
    </div>
  );
};
