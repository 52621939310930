import { Button, DynamicContainer, Form, FormSection, Icon } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { triggerEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { RebootResponse } from 'src/js/model/api/response/reboot-response';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import presets from 'src/js/presets/preset-store';
import { RebootNeededNotSavedModal } from 'src/js/reboot/reboot-needed-not-saved-modal';
import { Paths } from 'src/js/route';
import { createRebootTask } from 'src/js/task/settings-tasks';
import { createDeleteFirmwareTask } from 'src/js/task/settings-upgrade-tasks';
import { generateDataAuto } from 'src/js/util/automation';

import { UpdateCancelConfirmModal } from './cancel-confirm-modal';
import { CurrentVersionParameter } from './current-version-parameter';
import { CurrentVersionViewModel } from './current-version-view-model';
import { UpdateSoftwareModal } from './update-software-modal';

interface Props {
  data: CurrentVersionViewModel;
}

export const UpdateSoftware: React.FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [packageReady, setPackageReady] = useState<boolean>(!isNil(data.packageToBeInstalled));
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [updateShown, showUpdate, hideUpdate] = useModalControls(false);
  const [cancelShown, showCancel, hideCancel] = useModalControls(false);
  const [savePromptModalShown, showSavePromptModal, hideSavePromptModal] = useModalControls();

  const onUpdateClicked = () => {
    if (presets.activeWasModified) {
      showSavePromptModal();
      return;
    }
    showUpdate();
  };

  useEffect((): void => {
    setPackageReady(!isNil(data.packageToBeInstalled));
  }, [data.packageToBeInstalled]);

  const cancelTask = useTask(createDeleteFirmwareTask());
  const handleOnCancel = (): void => {
    if (!isNil(data.packageToBeInstalled)) {
      cancelTask(data.packageToBeInstalled);
      setFileUploaded(false);
      setPackageReady(false);
      data.packageToBeInstalled = null;
    }
  };

  const rebootTask = useTask(createRebootTask());
  useSuccessReaction(rebootTask, (_result: RebootResponse) => {
    navigate(Paths.rebootingUpgrade);
  });

  const handleOnReboot = (): void => {
    rebootTask();
  };

  return (
    <>
      <Form>
        {packageReady && (
          <div className="buttonRow">
            <Button variant="primary" onClick={handleOnReboot}>
              {t('general.reboot')}
            </Button>
          </div>
        )}
        <FormSection className={packageReady ? '' : 'hai-mt-3'}>
          <DynamicContainer minColumns={3}>
            <CurrentVersionParameter
              label={t('settings.upgrade.section.currentVersion.currentVersion')}
              value={data.currentVersion}
            />
            <Button variant="secondary" onClick={onUpdateClicked} style={{ marginTop: '23px' }} disabled={packageReady}>
              {t('btn.update')}
            </Button>
          </DynamicContainer>

          {!isNil(data.currentVersion) && (
            <DynamicContainer>
              <div
                className="haiui-body-02 hai-gray-08 d-flex align-items-center hai-mb-4"
                data-auto={generateDataAuto('warning', 'pending package')}
              >
                {packageReady ? (
                  <>
                    <Icon iconname="StatusWarning" className="hai-mr-3" size="sm2" />
                    <div>
                      {`${t('settings.upgrade.section.currentVersion.packageToInstall', {
                        fileName: data.packageToBeInstalled,
                      })}`}
                    </div>
                    <div className="hai-blue-04 hai-ml-pt5 cursor-pointer" onClick={showCancel}>
                      {t('settings.upgrade.section.currentVersion.packageToInstallCancel')}
                    </div>
                    {'.'}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </DynamicContainer>
          )}
        </FormSection>
      </Form>
      {updateShown && (
        <UpdateSoftwareModal
          data={data}
          onCancel={hideUpdate}
          packageReady={packageReady}
          setPackageReady={setPackageReady}
          fileUploaded={fileUploaded}
          setFileUploaded={setFileUploaded}
        />
      )}
      {cancelShown && (
        <UpdateCancelConfirmModal fileName={data.packageToBeInstalled} onCancel={hideCancel} onApply={handleOnCancel} />
      )}

      {savePromptModalShown && (
        <RebootNeededNotSavedModal
          onCancel={hideSavePromptModal}
          onReboot={() => {
            hideSavePromptModal();
            showUpdate();
          }}
          onSave={(): void => {
            hideSavePromptModal();
            triggerEvent('preset-save-callback', () => {
              createAndDispatchNotification(
                t('presets.notifications.successSavingPreset'),
                NotificationVariant.SUCCESS,
                t,
              );
              showUpdate();
            });
          }}
          bodyText={
            <>
              {t('reboot.upgradeNotSavedModal.body1')}
              <br />
              {t('reboot.upgradeNotSavedModal.body2')}
            </>
          }
          middleLabel={t('btn.update')}
          lastLabel={t('btn.saveAndUpdate')}
          middleVariant="secondary"
          lastVariant="danger"
        />
      )}
    </>
  );
};
