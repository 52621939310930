import { TFunction } from 'i18next';
import { FecRTP, SRTMode, StreamEncapsulation, StreamState } from 'src/js/pages/streaming/stream/stream-models';
import { isNilOrEmpty } from 'src/js/util/global-util';

export enum SourceType {
  multicast = 'multicast',
  unicast = 'unicast',
}

// UNUSED
export const translateSourceType = (sourceType: SourceType, t: TFunction): string =>
  t(`stream.form.typeSelectOptions.${sourceType}`); // FIXME missing translation

export const translateSrtRedundancyMode = (input: string, t: TFunction): string =>
  t(`stream.enums.srtRedundancyOptions.${input}`);

export const translateSrtRedundancyRole = (input: string, t: TFunction): string =>
  t(`stream.enums.srtRedundancyRoleOptions.${input}`);

export const translateStreamEncapsulation = (streamEncapsulation: StreamEncapsulation, t: TFunction): string =>
  t(`stream.enums.encapsulation.${streamEncapsulation}`);

export const translateSRTMode = (srtMode: SRTMode, t: TFunction): string =>
  t(`stream.form.srt.modeSelectOptions.${srtMode}`);

export const translateFECMode = (fecMode: FecRTP, t: TFunction): string => t(`stream.form.fecSelectOptions.${fecMode}`);

export const translateStreamState = (streamState: StreamState, t: TFunction): string =>
  isNilOrEmpty(streamState) ? undefined : t(`stream.enums.state.${streamState}`);

export const isEmptyOrNone = (streamName: string, t: TFunction): boolean =>
  isNilOrEmpty(streamName) || streamName === t('stream.unnamedStream');

export const formatUptime = (uptime: string, system = false) => {
  if (isNilOrEmpty(uptime)) {
    return '';
  }

  let formatted = '';
  if (system) {
    formatted = uptime
      ?.replace(':', 'h ')
      .replace(':', 'm ')
      .concat('s')
      .replace('00h ', '')
      .replace('00m ', '')
      .replace('00s', '')
      .replace(' days', 'd')
      .replace(' day', 'd')
      .replace(/^0d /, '')
      .split(' 0')
      .join(' ');

    const indexOfD = formatted.indexOf('d');
    if (indexOfD > -1 && Number(formatted.substring(indexOfD - 2, indexOfD)) >= 1 && formatted.indexOf('m') >= 1) {
      // Drop seconds if larger than 1 hr
      formatted = formatted.substring(0, formatted.indexOf('m') + 1);
    }

    if (formatted.startsWith('0')) {
      // Drop leading
      formatted = formatted.substring(1);
    }
  } else {
    formatted = uptime?.replace('d', 'd ').replace('h', 'h ').replace('m', 'm ');
    const indexOfH = formatted.indexOf('h');
    if (formatted.includes('m') && indexOfH > -1 && Number(formatted.substring(indexOfH - 2, indexOfH)) >= 1) {
      // Drop seconds if larger than 1 hr
      formatted = formatted.substring(0, formatted.indexOf('m') + 1);
    }
  }
  return formatted;
};
