import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import { FormikProps } from 'formik';
import { splitEvery } from 'ramda';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormValue } from 'src/js/component/base/form/form-value';
import { FormikCheckbox } from 'src/js/component/base/form/formik-checkbox';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { FormikSwitch } from 'src/js/component/base/form/formik-switch';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { Ipv6Addressing } from 'src/js/model/api/response/network-response';
import { NetworkFormValues } from 'src/js/pages/settings/network/form/network-form';
import { isNilOrEmpty } from 'src/js/util/global-util';

import { EthX, filterIPv6Value } from '../network-view-model';

interface InternalProps {
  ethX: EthX;
}

export const NetworkIpv6Form = (props: InternalProps & FormikProps<NetworkFormValues>) => {
  const { t } = useTranslation();

  const { ethX } = props;
  const shouldDisplayIpv6 = props.values[ethX].ipv6;
  const isIpv6Automatic = props.values[ethX].ipv6Addressing === Ipv6Addressing.AUTOMATIC;
  const isIpv6Static = props.values[ethX].ipv6Addressing === Ipv6Addressing.STATIC;
  const shouldDisplayIpList = !isNilOrEmpty(props.values[ethX].ipv6List);
  const shouldDisplayIpv6LinkLocal = !isNilOrEmpty(props.values[ethX].ipv6LinkLocal);

  return (
    <Form>
      <FormSection>
        <DynamicContainer minColumns={3}>
          <FormikSwitch name={ethX + '.ipv6'} switchLabel={t('settings.network.form.ipv6')} />
        </DynamicContainer>

        {shouldDisplayIpv6 && (
          <DynamicContainer minColumns={3}>
            <FormikSelect
              name={ethX + '.ipv6Addressing'}
              title={t('settings.network.form.addressing')}
              selectValues={Object.values(Ipv6Addressing)}
              selectLabels={Object.values(Ipv6Addressing).map((value: string) =>
                t(`settings.network.ipv6Addressing.${value}`),
              )}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue === Ipv6Addressing.STATIC) {
                  const originalValue = (props.values as any)[ethX].ipv6Address;
                  if (originalValue?.length > 0) {
                    const filteredIPv6 = filterIPv6Value(originalValue);
                    if (filteredIPv6?.length > 0 && originalValue !== filteredIPv6) {
                      props.setFieldValue(ethX + '.ipv6Address', filteredIPv6, true);
                      // If you do setFieldTouched, it will momentarily flash red validation error around the field
                      // props.setFieldTouched(ethX + '.ipv6Address', true);
                    }
                  }
                }
              }}
            />
          </DynamicContainer>
        )}

        {shouldDisplayIpv6 && isIpv6Static && (
          <DynamicContainer minColumns={3}>
            <FormikTextfield
              name={ethX + '.ipv6Address'}
              mask
              title={t('settings.network.form.globalIpv6Address')}
              required
            />
            <FormikTextfield
              name={ethX + '.ipv6PrefixLength'}
              title={t('settings.network.form.subnetPrefixLength')}
              required
            />
            <FormikTextfield name={ethX + '.ipv6Gateway'} mask title={t('settings.network.form.gateway')} required />
          </DynamicContainer>
        )}

        {shouldDisplayIpv6 &&
          !isIpv6Static &&
          shouldDisplayIpList &&
          splitEvery(3, props.values[ethX].ipv6List).map((rowStrings: string[], index: number) => (
            <DynamicContainer minColumns={3} key={`${JSON.stringify(rowStrings)}-${index}`}>
              {rowStrings.map((row: string, internalLoopIndex: number) => (
                <FormValue
                  mask
                  label={t('settings.network.form.ipv6AddressList', {
                    index: index * 3 + internalLoopIndex,
                  })}
                  value={row}
                  key={`${row}-${index * 3 + internalLoopIndex}`}
                />
              ))}
              <FormValue label={t('settings.network.form.gateway')} mask value={props.values[ethX].ipv6Gateway} />
            </DynamicContainer>
          ))}

        {shouldDisplayIpv6 && isIpv6Automatic && (
          <DynamicContainer minColumns={3}>
            <FormikCheckbox
              name={ethX + '.ipv6PrivacyExtension'}
              label={t('settings.network.form.ipv6PrivacyExtension')}
            />
          </DynamicContainer>
        )}

        {shouldDisplayIpv6 && shouldDisplayIpv6LinkLocal && (
          <DynamicContainer minColumns={3}>
            <FormValue label={t('settings.network.form.ipv6LinkLocal')} mask value={props.values[ethX].ipv6LinkLocal} />
          </DynamicContainer>
        )}
      </FormSection>
    </Form>
  );
};
