import { isNil } from 'ramda';
import { Services } from 'src/js/model/services';
import { ServicesViewModel } from 'src/js/pages/settings/services/services-view-model';

export const mapGeneralServicesViewModel = (services: Services): ServicesViewModel => {
  if (isNil(services)) {
    return {
      ems: false,
      http: false,
      preview: false,
      snmp: false,
      ssh: false,
      telnet: false,
      nic: {},
    };
  }

  return services;
};
