import { colorValue } from '@hai/ui-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from 'src/js/component/actionBar/actionBar';
import { SortType } from 'src/js/component/sort-manager';
import { triggerEvent, watchEvent } from 'src/js/events';
import { useAdaptiveFetch } from 'src/js/hook/use-adaptive-fetch';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useStores } from 'src/js/hook/use-stores';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { fetchStreams } from 'src/js/pages/streaming/stream/stream-store';
import { StreamingActionBar } from 'src/js/component/actionBar/streaming-action-bar';
import { useWatchObject } from 'src/js/store/use-watch';
import { isNilOrEmpty } from 'src/js/util/global-util';

import { DecoderList } from './decoder-list';
import {
  BufferingModeEnum,
  DecoderViewModel,
  decoderIsStarted,
  decoderIsStopped,
  decoderModelToStatus,
} from './decoder-model';
import { DecoderSetBufferingModal } from './decoder-set-buffering-modal';
import decoderModels, { fetchDecoders, resetDecoderStatistics, startStopDecoder, updateDecoder } from './decoder-store';
import { trickleDownSelectedHiddenState } from 'src/js/util/generic-store';

let connectionLost = false;
export const DecoderPage: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  // state management BEGINs
  const [decoders, setDecoders] = useState(decoderModels);
  useWatchObject(decoderModels, (updates) => {
    setDecoders((existings) => {
      trickleDownSelectedHiddenState(updates, existings);
      return updates;
    });
  });

  useAdaptiveFetch(
    () =>
      fetchDecoders(t).then((res) => {
        if (res.ok) {
          if (connectionLost) {
            triggerEvent('connection-restored');
            connectionLost = false;
          }
        } else if (res.problem === 'NETWORK_ERROR') {
          triggerEvent('connection-lost');
          connectionLost = true;
        }
      }),
    5000,
  );

  const fetchData = async () => {
    await fetchStreams(t);
  };

  // if we need streams
  // const [streams, setStreams] = useState(streamModels);
  // useWatchObject(streamModels, setStreams);

  useEffect(() => {
    fetchData();
  }, []);

  watchEvent('preset-load', () => {
    fetchDecoders(t);
    fetchData();
  });
  // state management ENDs

  const statusTypes: Status[] = [
    // TODO translations
    { status: 'ACTIVE', color: colorValue('haiui-green-01') },
    { status: 'IDLE', color: colorValue('haiui-blue-04') },
    { status: 'ALERT', color: colorValue('haiui-amber-01') },
    { status: 'INACTIVE', color: colorValue('haiui-gray-07') },
  ];

  const filteredStart = () => decoders.filter((e) => e.selected && decoderIsStopped(e.state));
  const handleStartDecoder = () => {
    const filtered = filteredStart();
    if (filtered.length > 0) {
      Promise.all(filtered.map((e) => startStopDecoder(e, true, true, t))).then((responses) => {
        triggerEvent('preset-update');
        const failed = responses.filter((r) => !r.ok);
        if (failed.length > 1) {
          // Message also in start/stop
          createAndDispatchNotification(
            t('decoder.notifications.startError', { count: failed.length }),
            NotificationVariant.ERROR,
            t,
          );
        }
      });
    }
  };

  const filteredStop = () => decoders.filter((e) => e.selected && decoderIsStarted(e.state));
  const handleStopDecoder = () => {
    const filtered = filteredStop();
    if (filtered.length > 0) {
      Promise.all(filtered.map((e) => startStopDecoder(e, false, true, t))).then((responses) => {
        triggerEvent('preset-update');
        const failed = responses.filter((r) => !r.ok);
        if (failed.length > 1) {
          // Message also in start/stop
          createAndDispatchNotification(
            t('decoder.notifications.stopError', { count: failed.length }),
            NotificationVariant.ERROR,
            t,
          );
        }
      });
    }
  };

  const filteredSelect = () => decoders.filter((e) => e.selected);

  const handleResetDecoderStatistics = () => {
    const filtered = filteredSelect();
    if (filtered.length > 0) {
      Promise.all(filtered.map((e) => resetDecoderStatistics(e, t))).then((responses) => {
        const success = responses.filter((r) => r.ok);
        /* if we want to reset streams stats too
         if (success.length === filtered.length) {
         Promise.all(
            filtered.map((e) =>
              resetStreamStatistics(
                streams.find((s) => s.decoderId === e.id),
                t,
              ),
            ),
          ).then((responses) => {
            const success = responses.filter((r) => r.ok);*/
        if (success.length === filtered.length) {
          createAndDispatchNotification(
            t('decoder.notifications.resetSelectedDecoderSuccess', { count: filtered.length }),
            NotificationVariant.SUCCESS,
            t,
          );
        }
        //});
        //}
      });
    }
  };

  const handleSetBufferingDecoder = (data: any) => {
    hideBuffering();
    const filtered = filteredSelect();
    if (filtered.length > 0) {
      Promise.all(
        filtered.map((e) => {
          const bufferingMode = data.bufferingMode !== BufferingModeEnum.UNSET ? data.bufferingMode : e.bufferingMode;

          const unsetDelay = !isNilOrEmpty(data.unsetDelay) ? data.unsetDelay : undefined;

          const bufferingDelay =
            data.bufferingMode === BufferingModeEnum.UNSET
              ? unsetDelay
              : !isNilOrEmpty(data.bufferingDelay)
              ? data.bufferingDelay
              : undefined;

          const multisyncBufferingDelay =
            data.bufferingMode === BufferingModeEnum.UNSET
              ? unsetDelay
              : !isNilOrEmpty(data.multisyncBufferingDelay)
              ? data.multisyncBufferingDelay
              : undefined;

          const decoder = {
            ...e,
            bufferingMode: bufferingMode,
            bufferingDelay: bufferingMode === BufferingModeEnum.FIXED ? bufferingDelay : undefined,
            multisyncBufferingDelay:
              bufferingMode === BufferingModeEnum.MULTISYNC ? multisyncBufferingDelay : undefined,
          };

          return updateDecoder(decoder, t, false);
        }),
      ).then((responses) => {
        triggerEvent('preset-update');
        const success = responses.filter((r) => r.ok);
        createAndDispatchNotification(
          t('decoder.notifications.updateBufferSuccess', { count: success.length }),
          NotificationVariant.SUCCESS,
          t,
        );
      });
    }
  };

  const [bufferingShown, showBuffering, hideBuffering] = useModalControls();

  const handleSelect = (item: DecoderViewModel, selected: boolean) => {
    const selectedDecoders = decoders.map((i: DecoderViewModel) => (i.id === item.id ? { ...i, selected } : i));

    setDecoders(selectedDecoders);
  };

  const sortInfo = {
    id: { title: t('sort.sortText.id'), type: SortType.GENERIC },
    name: { title: t('sort.sortText.name'), type: SortType.NATURAL_SORT },
    stateSortable: { title: t('sort.sortText.status'), type: SortType.GENERIC },
  };

  return (
    <>
      <StreamingActionBar
        items={decoders}
        sortInfo={sortInfo}
        statusTypes={statusTypes}
        getStateValue={decoderModelToStatus}
        scrollToId={(props as any).scrollToId}
        actionButtons={
          sessionStore.isUser()
            ? []
            : [
                { onClick: handleStartDecoder, counter: filteredStart, label: t('general.start') },
                { onClick: handleStopDecoder, counter: filteredStop, label: t('general.stop') },
                {
                  onClick: handleResetDecoderStatistics,
                  counter: filteredSelect,
                  label: t('decoder.RESETSTATS'),
                },
                {
                  onClick: showBuffering,
                  counter: filteredSelect,
                  label: t('decoder.setBuffering'),
                },
              ]
        }
        List={DecoderList}
        updateSelection={handleSelect}
        storageKey="decoder"
        placeholderThumbnail={true}
        placeholderNumPanels={4}
      />
      {bufferingShown && (
        <DecoderSetBufferingModal
          decoders={filteredSelect()}
          onSubmit={handleSetBufferingDecoder}
          onCancel={hideBuffering}
        />
      )}
    </>
  );
};
