import { apiClientWithoutHandler } from '.';

export const mapSingleData = (res: any) => {
  return res?.data;
};

export const genericDataFetcher = (url: string, mapper?: (input: any) => any) => {
  return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
    if (mapper) {
      return mapper(res);
    }

    return res?.data?.data;
  });
};
