import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaskRunner } from 'src/js/component/base/task-runner';
import { useTask } from 'src/js/hook/use-task';
import { UpgradeDetails } from 'src/js/model/api/upgrade-details';
import { SettingsTabContent } from 'src/js/pages/settings/settings-tab-content';
import { mapCurrentVersionViewModel } from 'src/js/pages/settings/upgrade/current-version-view-model-mapper';
import { UpdateSoftware } from 'src/js/pages/settings/upgrade/update-software';
import { createGetUpgradeDetailsTask } from 'src/js/task/settings-upgrade-tasks';

export const Upgrade: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const upgradeDetailsTask = useTask<UpgradeDetails>(createGetUpgradeDetailsTask());

  return (
    <SettingsTabContent name={t('settings.upgrade.title')}>
      <TaskRunner task={upgradeDetailsTask}>
        {(upgradeDetails: UpgradeDetails) => <UpdateSoftware data={mapCurrentVersionViewModel(upgradeDetails)} />}
      </TaskRunner>
    </SettingsTabContent>
  );
};
