import { Button, DynamicContainer, FormSection } from '@hai/ui-react';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from 'src/js/component/base/form/formik-select';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { useStores } from 'src/js/hook/use-stores';
import { UpdateAccountRequest } from 'src/js/model/api/request/change-password-request';
import {
  AccountViewModel,
  accountValidationSchema,
  accountValidationSchemaNoPassword,
} from 'src/js/pages/security/accounts/account-models';
import { validateFormContext } from 'src/js/validator/validator';

import { RoleType } from './account-models';

export interface AccountFormValues extends UpdateAccountRequest {}

interface GeneralFormProps {
  settings?: AccountViewModel;
  onReboot?: VoidFunction;
  onCancel?: VoidFunction;
  onSubmit?: (formValues: AccountFormValues, callback?: () => void) => void;
  buttonState: string;
  requireCurrentPassword: boolean;
}

export const AccountPasswordChangeForm: React.FunctionComponent<GeneralFormProps> = (props) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: props.settings?.name,
        role: props.settings?.role,
        oldPassword: '',
        password: '',
        passwordConfirm: '',
      }}
      onSubmit={(values, formikBag) => {
        props.onSubmit?.(values, () => formikBag.resetForm());
      }}
      validationSchema={() => {
        return props.requireCurrentPassword ? accountValidationSchema(t) : accountValidationSchemaNoPassword(t);
      }}
    >
      {(formikProps) => {
        return (
          <form className="HaiForm" style={{ marginTop: sessionStore.isUser() ? '-32px' : '-40px' }}>
            <DynamicContainer minColumns={2} maxColumns={2} className="align-items-center hai-mt-panel-name-align">
              <FormikTextfield
                name="name"
                mask
                title={t('security.accounts.form.userName')}
                className="max-width"
                disabled={true}
              />
              <div className="d-flex ml-auto hai-mt-panel-apply-sticky">
                {props.onCancel && (
                  <Button className="hai-mr-6" onClick={props.onCancel}>
                    {t('general.cancel')}
                  </Button>
                )}
                <Button
                  variant="primary"
                  onClick={() => validateFormContext(formikProps, t)}
                  disabled={!formikProps.dirty}
                  state={props.buttonState}
                >
                  {t('general.apply')}
                </Button>
              </div>
            </DynamicContainer>

            <FormSection className="hai-mb-6" title={t('security.accounts.form.password')}>
              <DynamicContainer minColumns={3}>
                <FormikSelect
                  name="role"
                  title={t('security.accounts.form.role')}
                  selectValues={Object.values(RoleType)}
                  selectLabels={Object.values(RoleType).map((value: string) =>
                    t(`security.accounts.roleType.${value}`),
                  )}
                  disabled
                />{' '}
              </DynamicContainer>{' '}
              <DynamicContainer minColumns={3}>
                {props.requireCurrentPassword && (
                  <FormikTextfield
                    name="oldPassword"
                    title={t('security.accounts.form.oldPassword')}
                    autoComplete="new-password"
                    type="password"
                    required
                  />
                )}
                <FormikTextfield
                  name="password"
                  title={t('security.accounts.form.newPassword')}
                  autoComplete="new-password"
                  type="password"
                  pwdToggle
                  required
                />
                <FormikTextfield
                  name="passwordConfirm"
                  title={t('security.accounts.form.passwordNewConfirm')}
                  autoComplete="new-password"
                  type="password"
                  pwdToggle
                  required
                />
              </DynamicContainer>
            </FormSection>
          </form>
        );
      }}
    </Formik>
  );
};
