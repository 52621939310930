import { TFunction } from 'i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath, APIPathWithArguments } from 'src/js/api/route-path-index';
import { notificationHandler } from 'src/js/notification/notification-handler';
import { createWatchStore, updateWatchStore } from 'src/js/store/use-watch';

import { AccountFormValues } from './account-form';
import {
  AccountKeyDataResponse,
  AccountResponse,
  AccountViewModel,
  GetAllAccountsResponse,
  mapAccountResponse,
  mapAccountViewModel,
} from './account-models';

const accountModels: AccountViewModel[] = [];
createWatchStore(accountModels);
export default accountModels;

export const saveResponseToAccounts = (response: AccountViewModel[]) => {
  updateWatchStore(accountModels, response);
};

const replaceViewModel = (response: AccountResponse, t: TFunction, selected = false) => {
  const viewModel = mapAccountViewModel(response, t);
  const accounts = [...accountModels];
  const index = accounts.findIndex((e) => e.id === viewModel.id);
  if (index > -1) {
    accounts.splice(index, 1, { ...viewModel, selected: selected });
  }

  saveResponseToAccounts(accounts);
};

const deleteViewModel = (model: AccountViewModel) => {
  const accounts = [...accountModels];
  const index = accounts.findIndex((e) => e.id === model.id);
  if (index > -1) {
    accounts.splice(index, 1);
  }

  saveResponseToAccounts(accounts);
};

export const fetchAccounts = (t: TFunction) => {
  return apiClientWithoutHandler.genericController.get<GetAllAccountsResponse>(APIPath.account.list).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        const viewModels = mapAccountResponse(response, t);
        saveResponseToAccounts(viewModels);
      }
    }
    return res;
  });
};

export const getAccount = (name: string, t: TFunction, selected: boolean) => {
  const path = APIPathWithArguments(APIPath.account.get, {
    name: name,
  });
  return apiClientWithoutHandler.genericController.get<AccountResponse>(path).then((res) => {
    if (res.ok) {
      const response = res.data;
      if (response) {
        replaceViewModel(response, t, selected);
      }
    }
    return res;
  });
};

export const lockUnlockAccount = (model: AccountViewModel, lock: boolean, selected: boolean, t: TFunction) => {
  const path = APIPathWithArguments(lock ? APIPath.account.lock : APIPath.account.unlock, {
    name: model.name,
  });
  return apiClientWithoutHandler.genericController
    .put<AccountResponse>(path)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          replaceViewModel(response, t, selected);
        }
      }
      return res;
    })
    .then((res) => {
      notificationHandler(res, null, t('security.accounts.notifications.lockErrorMsg'), t);
      return res;
    });
};

export const enableAccount = (model: AccountViewModel, selected: boolean, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.account.enable, {
    name: model.name,
  });
  return apiClientWithoutHandler.genericController
    .put<AccountResponse>(path)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          replaceViewModel(response, t, selected);
        }
      }
      return res;
    })
    .then((res) => {
      notificationHandler(res, null, t('security.accounts.notifications.enableErrorMsg'), t);
      return res;
    });
};

export const createAccount = (model: AccountFormValues, t: TFunction) => {
  return apiClientWithoutHandler.genericController
    .post<AccountResponse>(APIPath.account.add, model)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          const viewModel = mapAccountViewModel(response, t);
          const accounts = [...accountModels];
          accounts.splice(0, 0, { ...viewModel, selected: false });
          saveResponseToAccounts(accounts);
        }
      }
      return res;
    })
    .then((res) => {
      notificationHandler(
        res,
        t('security.accounts.notifications.createSuccessMsg', { name: model.name }),
        t('security.accounts.notifications.createErrorMsg', { name: model.name }),
        t,
      );
      return res;
    });
};

export const updateAccount = (model: AccountViewModel, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.account.update, { name: model.name });
  return apiClientWithoutHandler.genericController
    .put<AccountResponse>(path, model)
    .then((res) => {
      if (res.ok) {
        const response = res.data;
        if (response) {
          replaceViewModel(response, t);
        }
      }
      return res;
    })
    .then((res) => {
      notificationHandler(
        res,
        t('security.accounts.notifications.changeSuccessMsg'),
        t('security.accounts.notifications.changeErrorMsg'),
        t,
      );
      return res;
    });
};

export const deleteAccount = (model: AccountViewModel, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.account.delete, { name: model.name });
  return apiClientWithoutHandler.genericController
    .delete<AccountResponse>(path)
    .then((res) => {
      if (res.ok) {
        deleteViewModel(model);
      }
      return res;
    })
    .then((res) => {
      notificationHandler(res, null, t('security.accounts.notifications.deleteErrorMsg'), t);
      return res;
    });
};

export const uploadPublicKey = (name: string, file: File, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.account.pubkeys, {
    name: name,
  });
  return apiClientWithoutHandler.genericController
    .upload<AccountKeyDataResponse>(path, { file: file })

    .then((res) => {
      notificationHandler(res, null, t('security.accounts.notifications.pubkeyUploadErrorMsg'), t);
      return res;
    });
};

export const deletePubicKey = (name: string, accountName: string, t: TFunction) => {
  const path = APIPathWithArguments(APIPath.account.pubkey, {
    name: name,
    pubkey: accountName,
  });
  return apiClientWithoutHandler.genericController
    .delete<AccountKeyDataResponse>(path)

    .then((res) => {
      notificationHandler(res, null, t('security.accounts.notifications.pubkeyDeleteErrorMsg'), t);
      return res;
    });
};
