import { Chip, Icon, List } from '@hai/ui-react';
import { clone, isNil } from 'ramda';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import defaultThumbnail from 'src/images/thumbnail-default.jpg';
import { Ellipsis } from 'src/js/component/base/ellipsis';
import { triggerEvent, watchEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import { NotificationVariant } from 'src/js/notification/notification';
import { createAndDispatchNotification } from 'src/js/notification/notification-helper';
import { DecoderStatsStateEnum } from 'src/js/pages/streaming/decoder/decoder-model';
import { sdiOutputs } from 'src/js/pages/streaming/decoder/decoder-row';
import { DecoderStatistics } from 'src/js/pages/streaming/decoder/decoder-statistics';
import decoderModels from 'src/js/pages/streaming/decoder/decoder-store';
import { scrollPanelIntoView } from 'src/js/component/actionBar/streaming-action-bar';
import { useWatchRenderOnChange } from 'src/js/store/use-watch';
import { constant } from 'src/js/constant';
import { generateObjectDuplicateName } from 'src/js/util/duplicateObject';

import { StreamDeleteConfirmModal } from './delete-confirm-modal';
import { StreamEdit } from './stream-edit';
import { StreamEncapsulation, StreamState, StreamViewModel } from './stream-models';
import { addStream, deleteStream, resetStreamStatistics } from './stream-store';
import {
  streamListStreamName,
  streamStateMessage,
  streamStateToColor,
  streamStateToIcon,
} from './stream-view-model-mapper';
import { toTitleCase } from 'src/js/util/global-util';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  model: StreamViewModel;
  scrollToId: number | string;
  onSelect: (item: StreamViewModel, selected: boolean) => void;
}

export const StreamRow: React.FunctionComponent<Props> = ({ model, scrollToId, onSelect }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sessionStore } = useStores();

  useWatchRenderOnChange(decoderModels);

  const location = useLocation();
  const showStats = location?.state?.stats === true;

  const scroll = location?.state?.id === model.id || scrollToId === model.id;

  watchEvent('expandAll:edit', () => {
    setExpandContent('SETTINGS');
    setIsExpanded(true);
  });
  watchEvent('expandAll:stats', () => {
    setExpandContent('STATISTICS');
    setIsExpanded(true);
  });
  const collapseAll = () => {
    // console.log('collapseAll')
    setIsExpanded(false);
    setCollapseTimeoutId(
      setTimeout(() => {
        // the component needs to stay rendered during close for smooth closing
        setExpandContent(null);
        setCollapseTimeoutId(null);
      }, constant.collapseTime),
    );
  };
  watchEvent('collapseAll:edit', collapseAll);
  watchEvent('collapseAll:stats', collapseAll);

  const [isExpanded, setIsExpanded] = useState<boolean>(scroll);
  const [collapseTimeoutId, setCollapseTimeoutId] = useMutable(null);
  const [expandContent, setExpandContent] = useState<string>(scroll ? (showStats ? 'STATISTICS' : 'SETTINGS') : null);
  const onExpandedStateChange = (expandedState: { eventKey: any; expanded: boolean }) => {
    if (isExpanded !== expandedState.expanded) {
      setIsExpanded(expandedState.expanded);
    }
  };

  const [deleteShown, showDelete, hideDelete] = useModalControls();
  const handleDeleteStream = () => {
    deleteStream(model).then((res) => {
      if (!res.ok) {
        createAndDispatchNotification(
          t('stream.notifications.deleteError', { count: 1 }),
          NotificationVariant.ERROR,
          t,
        );
      } else {
        // remove all deleted streams from decoder streamId
        const streamId = (res.data as any).streamId;
        if (streamId > 0) {
          decoderModels
            .filter((d) => d.streamId === streamId)
            .forEach((d) => {
              d.streamId = -1;
            });
        }
      }
    });
  };

  const onSelectHandler = (eventKey: string, expanded: boolean, ev: KeyboardEvent) => {
    // console.log('onSelectHandler', ev);
    switch (eventKey) {
      case 'STATISTICS':
      case 'SETTINGS':
        if (ev && ev.shiftKey) {
          // expend all
          if (expanded) {
            eventKey === 'SETTINGS' ? triggerEvent('expandAll:edit') : triggerEvent('expandAll:stats');
          } else {
            eventKey === 'SETTINGS' ? triggerEvent('collapseAll:edit') : triggerEvent('collapseAll:stats');
          }
          return;
        }
        setExpandContent(eventKey);
        if (expanded) {
          setIsExpanded(true);
          if (collapseTimeoutId() !== null) {
            clearTimeout(collapseTimeoutId());
            setCollapseTimeoutId(null); // prevent closing of panel
          }
          scroll && scrollPanelIntoView({ id: model.id });
        } else {
          setIsExpanded(false);
          setCollapseTimeoutId(
            setTimeout(() => {
              // the component needs to stay rendered during close for smooth closing
              //setExpandContent(null); commented out to remember last setting
              setCollapseTimeoutId(null);
            }, constant.collapseTime),
          );
        }
        break;
      case 'RESETSTATS':
        resetStreamStatistics(model, t).then((res) => {
          if (res.ok) {
            createAndDispatchNotification(t('stream.notifications.resetStreamSuccess'), NotificationVariant.SUCCESS, t);
          }
        });
        break;
      case 'DUPLICATE': {
        const newStream = clone(model);
        newStream.name = generateObjectDuplicateName(model.name);
        addStream(newStream, t).then((res) => {
          if (res.ok) {
            const response = res.data;
            navigate('/streaming/stream/', { state: { id: response.data.info.id } });
          } else {
            createAndDispatchNotification(
              t('stream.notifications.addError', { name: newStream.name }),
              NotificationVariant.ERROR,
              t,
            );
          }
        });
        break;
      }
      case 'DELETE':
        showDelete();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    return () => {
      // final cleanup
      if (collapseTimeoutId() !== null) {
        clearTimeout(collapseTimeoutId());
      }
    };
  }, []);

  const onClickListPanel = (ev: UIEvent) => {
    ev?.stopPropagation();
    ev?.preventDefault();
    //console.log('onClickListPanel', (ev.target as any)?.className)
    if (
      (ev.target as any)?.className !== undefined
      /*
      (ev.target as any)?.className?.includes?.('HaiListPanelTitle') ||
      (ev.target as any)?.className?.includes?.('list-panel-main-content')*/
    ) {
      if (expandContent == null) {
        setExpandContent('SETTINGS');
      }
      // so we dont expand empty content
      setIsExpanded(!isExpanded);
    }
  };

  const decoder = decoderModels.find((v) => v.streamId === model.id);

  // thumbnail refresh
  const [thumbnailDate, setThumbnailDate] = useState<string>(`${new Date().getTime()}`);
  useEffect(() => {
    let endrun = false;
    let timer: any;
    const triggerRefresh = () => {
      if (!endrun) {
        setThumbnailDate(`${new Date().getTime()}`);
        if (decoder?.previewIntervalSec >= 1) {
          timer = setTimeout(triggerRefresh, decoder?.previewIntervalSec * 1000);
        }
      }
    };

    timer = setTimeout(() => {
      triggerRefresh();
    }, (decoder?.previewIntervalSec || 10) * 1000);

    return () => {
      // cleanup
      endrun = true;
      clearTimeout(timer);
    };
  }, [decoder?.previewIntervalSec]);

  const snapshotRef = useRef<HTMLImageElement>();
  const [defaultThumbnailHidden, setDefaultThumbnailHidden] = useState(false);
  const [realThumbnailHidden, setRealThumbnailHidden] = useState(true);
  const onLoadThumbnail = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (e.type !== 'error' && snapshotRef?.current) {
      snapshotRef.current.style.visibility = 'visible';
      setDefaultThumbnailHidden(true);
      setRealThumbnailHidden(false);
      return;
    }
    setDefaultThumbnailHidden(false);
    setRealThumbnailHidden(true);
  };

  useEffect(() => {
    if (model.state !== StreamState.ACTIVE || !decoder?.previewEnabled) {
      setDefaultThumbnailHidden(false);
      setRealThumbnailHidden(true);
    }
  }, [model.state, decoder?.previewEnabled]);

  const handleClickDecoder = (event: MouseEvent, id: number) => {
    event.stopPropagation();
    navigate('/streaming/decoder/', { state: { id: id } });
  };

  const btnId = `stream-settings-${model.id}`;
  const handleCancelEdit = () => {
    const btn = document.getElementById(btnId);
    if (!isNil(btn)) {
      btn.click();
    }
  };

  const statusColor = streamStateToColor(model.state);
  const streamMsg = streamStateMessage(model, decoder, t);

  const ref = useRef<HTMLDivElement>();
  const possibleText = toTitleCase(streamMsg);
  useEffect(() => {
    const panelIconDiv = ref.current?.querySelector('.list-panel-title-wrapper > div > div');
    if (panelIconDiv) {
      if (possibleText) {
        (panelIconDiv as any).title = possibleText;
      } else if (!possibleText && (panelIconDiv as any).title && (panelIconDiv as any).title.length) {
        // cleaning title
        (panelIconDiv as any).title = '';
      }
    }
  }, [model?.stats.state, possibleText]);

  return (
    <>
      <div
        id={`panel-${model.id}`}
        className={model.hidden ? 'mk-row-hidden' : ''}
        style={{ scrollMarginTop: '21px' }}
        ref={ref}
      >
        <List.Panel
          onClick={onClickListPanel}
          onSelect={(selected: boolean) => onSelect(model, selected)}
          checked={model.selected}
          panelColor={statusColor}
          expandedState={{ eventKey: expandContent, expanded: isExpanded }}
          onExpandedStateChange={onExpandedStateChange}
        >
          <List.PanelTitle
            useFsMask={true}
            title={streamListStreamName(t, model.name)}
            statusIcon={streamStateToIcon(model.state)}
            iconColor={statusColor}
          />

          {model.state === StreamState.ACTIVE &&
            decoder?.stats?.state === DecoderStatsStateEnum.ACTIVE &&
            decoder?.previewEnabled === true && (
              <List.Thumbnail
                useFsMask
                src={`/apis/decoders/${decoder?.id}/preview?t=${thumbnailDate}`}
                onError={onLoadThumbnail}
                onLoad={onLoadThumbnail}
                componentRef={snapshotRef}
                hidden={model.hidden || realThumbnailHidden}
              />
            )}
          <List.Thumbnail
            src={defaultThumbnail}
            hidden={model.hidden || defaultThumbnailHidden}
            overlayText={
              (model.state !== StreamState.ACTIVE ||
                decoder?.stats?.state !== DecoderStatsStateEnum.ACTIVE ||
                !decoder?.previewEnabled) &&
              streamMsg
            }
            overlayStyles={{ backgroundColor: 'transparent' }}
          />

          <List.PanelDetail>
            <List.PanelDetailItem
              divider="vertical"
              text={t('general.id')}
              data-auto={generateDataAuto('list_panel', 'detail id')}
            >
              <Chip size="sm">{model.id.toString()}</Chip>
            </List.PanelDetailItem>
            <List.PanelDetailItem
              divider="vertical"
              text={model.encapsulationTextual}
              data-auto={generateDataAuto('list_panel', 'detail encapsulation')}
            />
            <List.PanelDetailItem
              useFsMask
              divider={model.encapsulation === StreamEncapsulation.SRT || decoder ? 'vertical' : ''}
              text={<Ellipsis maxWidth="calc(max(200px, 22vw)">{model.connection}</Ellipsis>}
              data-auto={generateDataAuto('list_panel', 'detail port')}
            />
            {model.encapsulation === StreamEncapsulation.SRT && (
              <List.PanelDetailItem
                divider="vertical"
                text={`${t('stream.row.latency')} ${model.srtLatency}`}
                data-auto={generateDataAuto('list_panel', 'detail srt latency')}
              />
            )}
            {model.encapsulation === StreamEncapsulation.SRT && (
              <List.PanelDetailItem
                divider={decoder ? 'vertical' : ''}
                text={`${t('stream.row.skippedPackets')} ${
                  model.stats?.srt?.droppedPackets ? model.stats?.srt?.droppedPackets : '0'
                }`}
                data-auto={generateDataAuto('list_panel', 'detail skipped packets')}
              />
            )}
            {/* video */}
            {decoder && (
              <List.PanelDetailItem
                text={<Icon iconname="Video" size="sm3" />}
                data-auto={generateDataAuto('list_panel', 'detail video icon')}
              />
            )}
            {decoder && (
              <List.PanelDetailItem
                text={
                  <Chip size="sm" tooltip={<>{decoder?.name}</>} id={decoder.id} onClick={handleClickDecoder}>
                    {decoder.id}
                  </Chip>
                }
                data-auto={generateDataAuto('list_panel', 'detail decoder name')}
              />
            )}
            {decoder && decoder.nNumOfOutputs > 0 && (
              <List.PanelDetailItem icon="OutputSDI" data-auto={generateDataAuto('list_panel', 'detail output sdi')} />
            )}
            {decoder && decoder.nNumOfOutputs > 0 && (
              <List.PanelDetailItem
                key="SdiIcons"
                collapseText={t('SDI icons')}
                text={sdiOutputs(decoder, t)}
                data-auto={generateDataAuto('list_panel', 'detail icon sdi')}
              ></List.PanelDetailItem>
            )}
          </List.PanelDetail>

          {sessionStore.isUser() && (
            <List.Actions>
              <List.ActionItem
                expandButton
                eventKey="STATISTICS"
                icon="ReportStats"
                title={t('general.statistics')}
                onSelect={onSelectHandler}
              />
              <List.ActionItem
                expandButton
                eventKey="SETTINGS"
                icon="Settings"
                title={t('general.settings')}
                onSelect={onSelectHandler}
                id={btnId}
              />
            </List.Actions>
          )}
          {!sessionStore.isUser() && (
            <List.Actions dropdownAlignment="right">
              <List.ActionItem
                expandButton
                eventKey="STATISTICS"
                icon="ReportStats"
                title={t('general.statistics')}
                onSelect={onSelectHandler}
              />
              <List.ActionItem
                expandButton
                eventKey="SETTINGS"
                icon="Settings"
                title={t('general.settings')}
                onSelect={onSelectHandler}
                id={btnId}
              />
              <List.ActionItem isDivider />
              <List.ActionItem
                as="menuItem"
                eventKey="RESETSTATS"
                icon="Repeat"
                title={t('dashboard.resetStatistics')}
                onSelect={onSelectHandler}
              />
              <List.ActionItem
                as="menuItem"
                eventKey="DUPLICATE"
                icon="Duplicate"
                title={t('general.duplicate')}
                onSelect={onSelectHandler}
              />
              <List.ActionItem
                as="menuItem"
                eventKey="DELETE"
                icon="TrashCan"
                title={t('general.delete')}
                onSelect={onSelectHandler}
              />
            </List.Actions>
          )}
          <List.ExpandedPanel>
            {expandContent === 'STATISTICS' && isExpanded && (
              <DecoderStatistics stream={model} showDecoderStats={false} isExpanded={isExpanded} parent="STREAM" />
            )}
            {expandContent === 'SETTINGS' && isExpanded && <StreamEdit model={model} onCancel={handleCancelEdit} />}
          </List.ExpandedPanel>
        </List.Panel>
      </div>
      {deleteShown && (
        <StreamDeleteConfirmModal itemsToDelete={[model]} onCancel={hideDelete} onApply={handleDeleteStream} />
      )}
    </>
  );
};
