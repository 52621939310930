import { ApisauceConfig, ApisauceInstance, create } from 'apisauce';
import merge from 'lodash/merge';
import { DEFAULT_CONFIG } from 'src/js/api/default-config';

/**
 * This client is made to be composed with multiple controllers.
 */
export class BaseClient {
  protected api: ApisauceInstance;
  protected config: ApisauceConfig;

  constructor(config: ApisauceConfig) {
    const combinedConfig = merge(DEFAULT_CONFIG, config);
    this.api = create(combinedConfig);
    this.config = combinedConfig;
  }
}
