import { SyncStorage, SyncTrunk } from 'mobx-sync';

interface StoreMap {
  [id: string]: {};
}
export let trunk: SyncTrunk;

const initialize = (storeMap: StoreMap, storage: SyncStorage): void => {
  trunk = new SyncTrunk(storeMap, { storage });
  trunk.init();
};

export const initializePersistence = (storeMap: StoreMap, storage: SyncStorage): void => {
  initialize(storeMap, storage);
};
