import { TFunction } from 'i18next';
import { GenericResponse } from 'src/js/model/api/response/generic-response';
import * as yup from 'yup';

export interface PreviewViewModel {
  enabled: boolean;
  interval: number;
  width: number;
  height: number;
}

export interface PreviewDecoderModel {
  interval: number;
  enabled?: boolean;
  width?: number;
  height?: number;
}

export interface PreviewViewModel {
  enabled: boolean;
  decoders: Array<PreviewDecoderModel>;
}

export interface PreviewResponse extends PreviewViewModel, GenericResponse {}

/*
 * Yup validation schema
 */
// export const previewValidationSchema = (t: TFunction) =>
//   yup.object<PreviewViewModel>().shape({
//     interval: yup
//       .number()
//       .typeError(t('validation.preview.interval'))
//       .min(1, t('validation.preview.interval'))
//       .max(60, t('validation.preview.interval')),
//   });

export const previewValidationSchema = (t: TFunction) =>
  yup.object<PreviewViewModel>().shape({
    decoders: yup.array().of(
      yup.object<PreviewDecoderModel>().shape({
        interval: yup
          .number()
          .typeError(t('validation.preview.interval'))
          .min(1, t('validation.preview.interval'))
          .max(60, t('validation.preview.interval')),
      }),
    ),
  });
