import { compile } from 'path-to-regexp';

const BasePaths = {
  account: '/apis/accounts',
  ca: '/apis/ca',
  decoders: '/apis/decoders',
  ems: '/apis/ems',
  hostnamevalidate: '/apis/hostnamevalidate',
  identity: '/apis/identity',
  licenses: '/apis/licenses',
  presets: '/apis/presets',
  services: '/apis/services',
  stillimages: '/apis/stillimages',
  streams: '/apis/streams',
  upgrade: '/apis/upgrade',
};

const withBase = (base: string, path: string) => base.concat(path);

// This is just a helper method, but maybe we want to add some default options.
export const APIPathWithArguments = <T extends object>(path: string, data: T): string => compile(path)(data);

interface APIPathInterface {
  account: {
    add: string;
    delete: string;
    enable: string;
    get: string;
    list: string;
    lock: string;
    pubkey: string /*single item with id*/;
    pubkeys: string;
    unlock: string;
    update: string;
  };
  audit: string;
  bandwidth: string;
  banner: string;
  ca: {
    index: string;
    delete: string;
    download: string;
  };
  datetime: string;
  decoder: {
    list: string;
    get: string;
    put: string;
    start: string;
    stop: string;
    stats: string;
  };
  deleteFirmware: (filename: string) => string;
  ems: {
    decode: string;
    pair: string;
    unpair: string;
    unpairForce: string;
  };
  identity: {
    default: string;
    delete: string;
    download: string;
    generate: string;
    index: string;
  };
  leds: string;
  licenses: {
    list: string;
    download: string;
    single: string;
  };
  license: {
    get: string;
  };
  messages: string;
  monitorFirmwareUpload: (filename: string) => string;
  network: string;
  ntpStatistics: string;
  policies: string;
  presets: {
    add: string;
    delete: string;
    download: string;
    duplicate: string;
    isSaved: string;
    list: string;
    load: string;
    rename: string;
    startup: string;
    update: string;
  };
  services: {
    ems: string;
    preview: string;
    status: string;
    update: string;
  };
  session: string;
  stillimages: {
    list: string;
    upload: string;
    single: string;
  };
  hostnamevalidate: {
    stream: string;
  };
  streams: {
    list: string;
    single: string;
    stats: string;
    start: string;
    stop: string;
  };
  system: string;
  systemInfo: string;
  reboot: string;
  status: {
    get: string;
    snapshot: string;
  };
  upgrade: {
    get: string;
    notify: string;
    upload: string;
    install: string;
  };
  validateNtpServer: string;
}

export const APIPath: APIPathInterface = {
  account: {
    add: BasePaths.account,
    delete: withBase(BasePaths.account, '/:name'),
    enable: withBase(BasePaths.account, '/:name/enable'),
    get: withBase(BasePaths.account, '/:name'),
    list: BasePaths.account,
    lock: withBase(BasePaths.account, '/:name/lock'),
    pubkey: withBase(BasePaths.account, '/:name/pubkeys/:pubkey'),
    pubkeys: withBase(BasePaths.account, '/:name/pubkeys'),
    unlock: withBase(BasePaths.account, '/:name/unlock'),
    update: withBase(BasePaths.account, '/:name'),
  },
  audit: '/apis/audit',
  bandwidth: '/apis/bandwidth/:index',
  banner: '/apis/banner',
  ca: {
    index: BasePaths.ca,
    delete: withBase(BasePaths.ca, '/:name'),
    download: withBase(BasePaths.ca, '/:name'),
  },
  datetime: 'apis/datetime',
  decoder: {
    list: BasePaths.decoders,
    get: withBase(BasePaths.decoders, '/:id'),
    put: withBase(BasePaths.decoders, '/:id'),
    start: withBase(BasePaths.decoders, '/:id/start'),
    stop: withBase(BasePaths.decoders, '/:id/stop'),
    stats: withBase(BasePaths.decoders, '/:id/stats'),
  },
  deleteFirmware: (filename: string) => `/apis/upgrade/${filename}`,
  ems: {
    decode: withBase(BasePaths.ems, '/decode'),
    pair: withBase(BasePaths.ems, '/pair'),
    unpair: withBase(BasePaths.ems, '/unpair'),
    unpairForce: withBase(BasePaths.ems, '/unpair/force'),
  },
  identity: {
    default: withBase(BasePaths.identity, '/:name/default'),
    delete: withBase(BasePaths.identity, '/:name'),
    download: withBase(BasePaths.identity, '/:name'),
    generate: BasePaths.identity,
    index: BasePaths.identity,
  },
  leds: '/apis/leds',
  licenses: {
    list: BasePaths.licenses,
    download: withBase(BasePaths.licenses, '/:name'),
    single: withBase(BasePaths.licenses, '/:name'),
  },
  license: {
    get: '/apis/license',
  },
  messages: '/apis/messages',
  monitorFirmwareUpload: (filename: string) => `/${filename}`,
  network: '/apis/network',
  ntpStatistics: 'apis/chronyd',
  policies: 'apis/policies',
  presets: {
    add: BasePaths.presets,
    delete: withBase(BasePaths.presets, '/:name'),
    download: withBase(BasePaths.presets, '/:name'),
    duplicate: withBase(BasePaths.presets, '/:name/duplicate'),
    isSaved: withBase(BasePaths.presets, '/current/issaved'),
    list: BasePaths.presets,
    load: withBase(BasePaths.presets, '/:name/load'),
    rename: withBase(BasePaths.presets, '/:name/rename'),
    startup: withBase(BasePaths.presets, '/:name/startup'),
    update: withBase(BasePaths.presets, '/:name'),
  },
  services: {
    ems: '/apis/ems',
    preview: withBase(BasePaths.services, '/preview'),
    status: withBase(BasePaths.services, '/status'),
    update: BasePaths.services,
  },
  session: '/apis/authentication',
  stillimages: {
    list: BasePaths.stillimages,
    upload: BasePaths.stillimages,
    single: withBase(BasePaths.stillimages, '/:id'),
  },
  hostnamevalidate: {
    stream: withBase(BasePaths.hostnamevalidate, '/stream'),
  },
  reboot: '/apis/reboot',
  streams: {
    list: BasePaths.streams,
    single: withBase(BasePaths.streams, '/:id'),
    stats: withBase(BasePaths.streams, '/:id/stats'),
    start: withBase(BasePaths.streams, '/:id/start'),
    stop: withBase(BasePaths.streams, '/:id/stop'),
  },
  system: '/apis/system',
  systemInfo: '/apis/system_info',
  status: {
    get: '/apis/status',
    snapshot: '/apis/start_system_snapshot',
  },
  upgrade: {
    get: BasePaths.upgrade,
    notify: withBase(BasePaths.upgrade, '/notify'),
    upload: withBase(BasePaths.upgrade, '/upload'),
    install: withBase(BasePaths.upgrade, '/install'),
  },
  validateNtpServer: '/apis/ntpvalidate',
};
