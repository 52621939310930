import { Checkbox, FormContext, FormControlContainer, FormGroup, FormLabel } from '@hai/ui-react';
import { FormikProps } from 'formik';
import { isNil } from 'ramda';
import React from 'react';

import { ConditionalWrapper } from './conditional-wrapper';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  name: string;
  disabled?: boolean;
  label?: string;
  title?: string;
  className?: string;
  checked?: boolean;
  onChange?: Function;
  container?: boolean;
}

export const FormCheckbox: React.FunctionComponent<Props> = ({
  name,
  disabled,
  label,
  title,
  className,
  checked,
  onChange,
  container = true,
}) => {
  return (
    <FormContext.Consumer>
      {(formContext: FormikProps<any>) => {
        // If HAIUI ever handles this internally for checkbox we could remove this block of formik code
        const meta = formContext?.getFieldMeta(name);
        const helper = formContext?.getFieldHelpers(name);
        const formikValue = meta?.touched ? meta.value : meta?.initialValue;
        const onChangeLocal = (e: any) => {
          if (!isNil(name)) {
            const { checked } = e.target;
            helper?.setTouched(true);
            helper?.setValue(checked);
          }
          onChange?.(e);
        };
        return (
          <FormGroup className={className}>
            {title && <FormLabel>{title}</FormLabel>}

            <ConditionalWrapper
              condition={container}
              wrapper={(children) => (
                <FormControlContainer className={title && 'mt-0'}>{children}</FormControlContainer>
              )}
            >
              <Checkbox
                name={name}
                checked={checked ?? !!formikValue}
                onChange={onChangeLocal}
                disabled={disabled}
                data-auto={generateDataAuto('checkbox', name)}
              >
                {label}
              </Checkbox>
            </ConditionalWrapper>
            {container && <div className="hai-mb-5" />}
          </FormGroup>
        );
      }}
    </FormContext.Consumer>
  );
};
