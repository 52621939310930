import { colorValue, Chip, List } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import defaultThumbnail from 'src/images/thumbnail-default.jpg';
import BusyIcon from 'src/js/component/base/busy-icon';
import { triggerEvent, watchEvent } from 'src/js/events';
import { keyFromValue } from 'src/js/helper/enum-helper';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import streamModels from 'src/js/pages/streaming/stream/stream-store';
import { scrollPanelIntoView } from 'src/js/component/actionBar/streaming-action-bar';
import { constant } from 'src/js/constant';
import { decoderOptionsStreamName } from '../stream/stream-view-model-mapper';

import { DecoderEdit } from './decoder-edit';
import {
  BufferingModeEnum,
  DecoderInfoStateEnum,
  DecoderViewModel,
  decoderIsStopped,
  decoderStateToIcon,
  translateDecoderState,
  decoderStateToHintText,
  QuadModeEnum,
} from './decoder-model';
import { DecoderStatistics } from './decoder-statistics';
import { startStopDecoder } from './decoder-store';
import { SdiCheckbox, SDIType } from 'src/js/component/base/form/sdi-checkbox';
import classNames from 'classnames';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  model: DecoderViewModel;
  onSelect: (item: DecoderViewModel, selected: boolean) => void;
  scrollToId: number | string;
}

export const sdiOutputs = (decoder: DecoderViewModel, t: TFunction) => {
  const quadMode2SI = decoder.outputs.every((output) => output) && decoder.quadMode == QuadModeEnum.QUAD_2SI;
  const outputs: ReactNode = (
    <div className="sdi-checkbox-container tiny">
      <div className={classNames('sdi-checkboxes', quadMode2SI && 'quadMode2SI')}>
        {decoder.outputs.map((value, index) => (
          <>
            {decoder.outputsOrig[index] === true && (
              <SdiCheckbox
                key={index}
                name={`outputs[${index}]`}
                tooltip={t('decoder.sdiId', { id: index + 1 })}
                checked={value}
                type={
                  value !== decoder.outputsOrig[index] && value == true
                    ? SDIType.HIGHLIGHT
                    : value !== decoder.outputsOrig[index] && value == false
                    ? SDIType.OUTLINE
                    : SDIType.NORMAL
                }
              >{`${index + 1}`}</SdiCheckbox>
            )}
          </>
        ))}
      </div>
    </div>
  );

  return outputs;
};

export const DecoderRow: React.FunctionComponent<Props> = ({ model, onSelect, scrollToId }) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const location = useLocation();
  const showStats = location?.state?.stats === true;

  const scroll = location?.state?.id === model.id || scrollToId === model.id;

  watchEvent('expandAll:edit', () => {
    setExpandContent('SETTINGS');
    setIsExpanded(true);
  });
  watchEvent('expandAll:stats', () => {
    setExpandContent('STATISTICS');
    setIsExpanded(true);
  });
  const collapseAll = () => {
    setIsExpanded(false);
    setCollapseTimeoutId(
      setTimeout(() => {
        // the component needs to stay rendered during close for smooth closing
        setExpandContent(null);
        setCollapseTimeoutId(null);
      }, constant.collapseTime),
    );
  };
  watchEvent('collapseAll:edit', collapseAll);
  watchEvent('collapseAll:stats', collapseAll);

  const [isExpanded, setIsExpanded] = useState<boolean>(scroll);
  const [collapseTimeoutId, setCollapseTimeoutId] = useMutable(null);
  const [expandContent, setExpandContent] = useState<string>(scroll ? (showStats ? 'STATISTICS' : 'SETTINGS') : null);
  const onExpandedStateChange = (expandedState: { eventKey: any; expanded: boolean }) => {
    if (isExpanded !== expandedState.expanded) {
      setIsExpanded(expandedState.expanded);
    }
  };

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const onSelectHandler = (eventKey: string, expanded: boolean, ev: KeyboardEvent) => {
    switch (eventKey) {
      case 'STATISTICS':
      case 'SETTINGS':
        if (ev && ev.shiftKey) {
          // expend all
          if (expanded) {
            eventKey === 'SETTINGS' ? triggerEvent('expandAll:edit') : triggerEvent('expandAll:stats');
          } else {
            eventKey === 'SETTINGS' ? triggerEvent('collapseAll:edit') : triggerEvent('collapseAll:stats');
          }
          return;
        }
        setExpandContent(eventKey);
        if (expanded) {
          setIsExpanded(true);
          if (collapseTimeoutId() !== null) {
            clearTimeout(collapseTimeoutId());
            setCollapseTimeoutId(null); // prevent closing of panel
          }
          scroll && scrollPanelIntoView({ id: model.id });
        } else {
          setIsExpanded(false);
          setCollapseTimeoutId(
            setTimeout(() => {
              // the component needs to stay rendered during close for smooth closing
              //setExpandContent(null); commented out to remember last setting
              setCollapseTimeoutId(null);
            }, constant.collapseTime),
          );
        }
        break;
      case 'PLAY':
        setIsUpdating(true);
        startStopDecoder(model, decoderIsStopped(model.state), false, t).then(() => {
          triggerEvent('preset-update');
          setIsUpdating(false);
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    return () => {
      // final cleanup
      if (collapseTimeoutId() !== null) {
        clearTimeout(collapseTimeoutId());
      }
    };
  }, []);

  // thumbnail refresh
  const [thumbnailDate, setThumbnailDate] = useState<string>(`${new Date().getTime()}`);
  useEffect(() => {
    let endrun = false;
    let timer: any;
    const triggerRefresh = () => {
      if (!endrun) {
        setThumbnailDate(`${new Date().getTime()}`);
        if (model.previewIntervalSec >= 1) {
          timer = setTimeout(triggerRefresh, model.previewIntervalSec * 1000);
        }
      }
    };

    timer = setTimeout(() => {
      triggerRefresh();
    }, model.previewIntervalSec * 1000);

    return () => {
      // cleanup
      endrun = true;
      clearTimeout(timer);
    };
  }, [model.previewIntervalSec]);

  watchEvent('thumbnail-update', () => {
    if (model.previewIntervalSec >= 1) {
      setThumbnailDate(`${new Date().getTime()}`);
    }
  });

  const snapshotRef = useRef<HTMLImageElement>();
  const [defaultThumbnailHidden, setDefaultThumbnailHidden] = useState(false);
  const [realThumbnailHidden, setRealThumbnailHidden] = useState(true);
  const onLoadThumbnail = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (e.type !== 'error' && snapshotRef?.current) {
      snapshotRef.current.style.visibility = 'visible';
      setDefaultThumbnailHidden(true);
      setRealThumbnailHidden(false);
      return;
    }
    setDefaultThumbnailHidden(false);
    setRealThumbnailHidden(true);
  };

  useEffect(() => {
    if (!model.previewEnabled) {
      setDefaultThumbnailHidden(false);
      setRealThumbnailHidden(true);
    }
  }, [model.state, model.previewEnabled]);

  const btnId = `decoder-settings-${model.id}`;
  const handleCancelEdit = () => {
    const btn = document.getElementById(btnId);
    if (!isNil(btn)) {
      btn.click();
    }
  };

  const onClickListPanel = (ev: UIEvent) => {
    ev?.stopPropagation();
    ev?.preventDefault();
    if (
      (ev.target as any)?.className !== undefined /*
      (ev.target as any)?.className?.includes?.('HaiListPanelTitle') ||
      (ev.target as any)?.className?.includes?.('list-panel-main-content')*/
    ) {
      if (expandContent == null) {
        setExpandContent('SETTINGS');
      }
      // so we dont expand empty content
      setIsExpanded(!isExpanded);
    }
  };

  const stream = streamModels?.find((s) => s.id === model.streamId);
  const statusColor = model.color;
  const resolution = model.stats?.videoInputResolution;
  const bufferingMode = t(`decoder.bufferingModes.${keyFromValue(BufferingModeEnum, model.bufferingMode)}`);
  const bufferingStr =
    model.bufferingMode === BufferingModeEnum.FIXED
      ? `${bufferingMode} ${model.bufferingDelay}ms`
      : model.bufferingMode === BufferingModeEnum.MULTISYNC
      ? `${bufferingMode} ${model.multisyncBufferingDelay}ms`
      : bufferingMode;

  const ref = useRef<HTMLDivElement>();
  const possibleText = decoderStateToHintText(model, t);
  useEffect(() => {
    const panelIconDiv = ref.current?.querySelector('.list-panel-title-wrapper > div > div');
    if (panelIconDiv) {
      if (possibleText) {
        (panelIconDiv as any).title = possibleText;
      } else if (!possibleText && (panelIconDiv as any).title && (panelIconDiv as any).title.length) {
        // cleaning title
        (panelIconDiv as any).title = '';
      }
    }
  }, [model?.stats.state, possibleText]);
  let streamName = decoderOptionsStreamName(t, stream);
  if (streamName === '') {
    streamName = t('decoder.streamSelectNone');
  }
  return (
    <div
      id={`panel-${model.id}`}
      className={model.hidden ? 'mk-row-hidden' : ''}
      style={{ scrollMarginTop: '21px' }}
      ref={ref}
    >
      <List.Panel
        onClick={onClickListPanel}
        onSelect={(selected: boolean) => onSelect(model, selected)}
        checked={model.selected}
        panelColor={statusColor}
        expandedState={{ eventKey: expandContent, expanded: isExpanded }}
        onExpandedStateChange={onExpandedStateChange}
      >
        <List.PanelTitle
          useFsMask={true}
          title={model.name}
          statusIcon={decoderStateToIcon(model)}
          iconColor={statusColor}
        />
        {model?.previewEnabled && (
          <List.Thumbnail
            useFsMask
            src={`/apis/decoders/${model.id}/preview?t=${thumbnailDate}`}
            onError={onLoadThumbnail}
            onLoad={onLoadThumbnail}
            componentRef={snapshotRef}
            hidden={model.hidden || realThumbnailHidden}
          />
        )}
        <List.Thumbnail
          src={defaultThumbnail}
          hidden={model.hidden || defaultThumbnailHidden}
          overlayText={
            model.state !== DecoderInfoStateEnum.STARTED || !model?.previewEnabled
              ? translateDecoderState(model.stats.state, model.stats.troubleCode, t).toUpperCase()
              : undefined
          }
          overlayStyles={{ backgroundColor: 'transparent' }}
        />
        <List.PanelDetail>
          <List.PanelDetailItem
            divider="vertical"
            text={t('general.id')}
            data-auto={generateDataAuto('list_panel', 'detail id')}
          >
            <Chip size="sm">{model.id.toString()}</Chip>
          </List.PanelDetailItem>
          <List.PanelDetailItem
            data-auto={generateDataAuto('list_panel', 'detail stream')}
            divider="vertical"
            text={streamName}
            icon="Streams"
            tooltip="Stream"
            // onClick={() => console.log('_')} // workaround testing
          />
          {resolution && (
            <List.PanelDetailItem
              divider="vertical"
              text={resolution}
              data-auto={generateDataAuto('list_panel', 'detail resolution')}
            />
          )}
          <List.PanelDetailItem
            divider={model.nNumOfOutputs > 0 ? 'vertical' : ''}
            text={bufferingStr}
            data-auto={generateDataAuto('list_panel', 'detail output')}
          />
          {model.nNumOfOutputs > 0 && <List.PanelDetailItem icon="OutputSDI" />}
          {model.nNumOfOutputs > 0 && (
            <List.PanelDetailItem
              data-auto={generateDataAuto('list_panel', 'detail sdi icons')}
              key="SdiIcons"
              collapseText={t('SDI icons')}
              text={sdiOutputs(model, t)}
            ></List.PanelDetailItem>
          )}
        </List.PanelDetail>
        {sessionStore.isUser() ? (
          <List.Actions>
            <List.ActionItem
              expandButton
              eventKey="STATISTICS"
              icon="ReportStats"
              title={t('general.statistics')}
              onSelect={onSelectHandler}
            />
            <List.ActionItem
              expandButton
              eventKey="SETTINGS"
              icon="Settings"
              title={t('general.settings')}
              onSelect={onSelectHandler}
              id={btnId}
            />
          </List.Actions>
        ) : (
          <List.Actions>
            <List.ActionItem
              expandButton
              eventKey="STATISTICS"
              icon="ReportStats"
              title={t('general.statistics')}
              onSelect={onSelectHandler}
            />
            <List.ActionItem
              expandButton
              eventKey="SETTINGS"
              icon="Settings"
              title={t('general.settings')}
              onSelect={onSelectHandler}
              id={btnId}
            />
            <List.ActionItem isDivider />
            <List.ActionItem
              eventKey="PLAY"
              icon={
                <BusyIcon
                  isActive={!decoderIsStopped(model.state)}
                  activeIcon="StopFilled"
                  inactiveIcon="PlayFilled"
                  busy={isUpdating}
                  color={colorValue('haiui-gray-04')}
                />
              }
              showActive={false}
              onSelect={onSelectHandler}
              disabled={isUpdating}
            />
          </List.Actions>
        )}
        <List.ExpandedPanel>
          {expandContent === 'STATISTICS' && isExpanded && (
            <DecoderStatistics decoder={model} stream={stream} isExpanded={isExpanded} parent="DECODER" />
          )}
          {expandContent === 'SETTINGS' && isExpanded && <DecoderEdit model={model} onCancel={handleCancelEdit} />}
        </List.ExpandedPanel>
      </List.Panel>
    </div>
  );
};
