import { isNil } from 'ramda';
import { UpgradeDetailsResponse } from 'src/js/model/api/response/upgrade-details-response';
import { UpgradeDetails } from 'src/js/model/api/upgrade-details';
import { isNilOrEmpty } from 'src/js/util/global-util';

export const mapUpgradeDetails = (response: UpgradeDetailsResponse): UpgradeDetails => {
  if (isNil(response)) {
    return {
      currentVersion: undefined,
      packageToBeInstalled: undefined,
    };
  }
  return {
    currentVersion: response.currentVersion,
    packageToBeInstalled: isNilOrEmpty(response.data) ? undefined : response.data[0].name,
  };
};
