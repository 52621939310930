import { DynamicContainer, Form, FormSection } from '@hai/ui-react';
import { Formik } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { isNil } from 'ramda';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { FormValue } from 'src/js/component/base/form/form-value';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { useMutable } from 'src/js/hook/use-mutable';
import { IconStatusType } from 'src/js/model/icon-status-type';
import { CancelPairingButton } from 'src/js/pages/settings/services/cancel-pairing-button';
import { PairButton } from 'src/js/pages/settings/services/pair-button';
import { UnpairButton } from 'src/js/pages/settings/services/unpair-button';
import { FormChangeEvent } from 'src/js/util/global-type';
import { isNilOrEmpty, toTitleCase } from 'src/js/util/global-util';

//import { Loading } from 'src/js/component/loading';
import {
  EmsServiceResponse,
  EmsServiceState,
  emsValidationSchema,
  getEmsStateIcon,
  mapEmsServices,
} from './ems-models';
import { saveResponseToEmsService } from './ems-store';
import { ServicesViewModel } from './services-view-model';

interface Props {
  services: ServicesViewModel /* loading animation could be displayed while services.nic?.ems === undefined */;
}

export const EmsForm: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const pollingInterval = 5000;
  const pairingPollingInterval = 300;
  const [passcodeChangeTimeoutId, setPasscodeChangeTimeoutId] = useMutable(undefined);
  const [firstTime, setFirstTime] = useMutable(true);
  const [lastPairingError, setLastPairingError] = useState('');
  const [pollingTimeout, setPollingTimeout] = useMutable(pollingInterval);
  const [emsFetchTimeoutId, setEmsFetchTimeoutId] = useMutable(null);
  const [emsState, setEmsState] = useState<EmsServiceState>(undefined);
  const [iconState, setIconState] = useState(getEmsStateIcon(emsState));
  const [underDecoding, setUnderDecoding] = useState(false);
  const formRef = useRef(null);

  let endrun = false;
  useEffect(() => {
    return () => {
      endrun = true;
    };
  }, []);

  // checks if the device is currently pairing/unpairing and adjust the polling timeout if needed
  const pairingCheck = (state: EmsServiceState): void => {
    if (
      (state === EmsServiceState.PAIRING || state === EmsServiceState.UNPAIRING) &&
      pollingTimeout() !== pairingPollingInterval
    ) {
      setPollingTimeout(pairingPollingInterval);
      if (emsFetchTimeoutId() !== null) {
        // console.log('clearing timeout to trigger right away a new fetch')
        clearTimeout(emsFetchTimeoutId());
        setEmsFetchTimeoutId(null);
      }
    }
    if (
      (state === EmsServiceState.CONNECTED || state === EmsServiceState.UNPAIRED) &&
      pollingTimeout() !== pollingInterval
    ) {
      setPollingTimeout(pollingInterval);
    }
  };

  const fetchStatusFunc = () => {
    if (endrun) {
      // console.error('count/endrun', count, endrun);
      return; // stop
    }

    apiClientWithoutHandler.genericController.get(APIPath.services.ems).then((res: any) => {
      // console.log('CALLBACK fetch, uuid', uuid, endrun);
      if (endrun) {
        // console.error('CALLBACK fetch, all instances stopped, aborting', uuid);
        // we got destroyed, abort
        return;
      }
      const data = res?.data?.data;
      if (res?.ok) {
        const response = mapEmsServices(res.data?.data as EmsServiceResponse);
        saveResponseToEmsService(response);

        const state = data.state;
        setEmsState(state);
        setIconState(response.iconState);
        pairingCheck(response.state);

        if (firstTime() && formRef.current) {
          setFirstTime(false);
          formRef.current.setFieldValue('hostname', response.emsHostname, false);
          if (response.emsPort !== 0) {
            formRef.current.setFieldValue('port', response.emsPort, false);
          }
          formRef.current.setFieldValue('keepAlive', response.emsKeepalive, false);
        }
      }

      if (res?.ok && data?.lastPairingError && data?.lastPairingError.length) {
        // only valid for these states
        const state = data.state;
        if (
          state === 'CONNECTING' ||
          state === 'PAIRING' ||
          state === 'PAIRED' ||
          state === 'UNKNOWN' ||
          state === 'UNPAIRED'
        ) {
          /*
          // if we want notifications
          const errPrefix = 'Error ';
          if (!notificationShown) {
            notificationShown = true;

            if (data.lastPairingError.startsWith(errPrefix)) {
              createAndDispatchNotification(
                data.lastPairingError.substr(errPrefix.length),
                NotificationVariant.ERROR,
                t,
              );
            } else {
              createAndDispatchNotification(data.lastPairingError, NotificationVariant.RED, t);
            }

          }*/

          // for static red error text
          setLastPairingError(data.lastPairingError);

          if (state === 'UNPAIRED' && data.lastPairingError?.length) {
            // console.log('pairing ENDED', emsState)
            if (emsState !== state) {
              // fixing state
              setEmsState(EmsServiceState.UNPAIRED);
            }
          }

          if (data.lastPairingError === '') {
            setLastPairingError('');
          }
        } else if (lastPairingError !== '') {
          setLastPairingError('');
        }
      }

      if (!endrun) {
        const timeoutVal = pollingTimeout();
        // console.log('renewing fetch', timeoutVal);
        setEmsFetchTimeoutId(setTimeout(fetchStatusFunc, timeoutVal));
      } else {
        // console.error('not renewing fetch')
      }
    });
  };

  // just run once or when changing interval
  useEffect(() => {
    if (emsFetchTimeoutId() === null) {
      // console.log('triggerFetch because we have null ID')
      setEmsFetchTimeoutId(setTimeout(fetchStatusFunc, 0));
    }
  }, [emsFetchTimeoutId]);

  /**
   * Because we use the pair buttons to validate, we set the field to touched before so the errors
   * can be displayed. This can be removed if we ever get back to an architecture where the pair buttons
   * are submitting the forms.
   */
  const handleValidate = (): Promise<FormikErrors<any>> => {
    formRef.current.setTouched({ hostname: true, keepAlive: true, pairingCode: true, port: true }, false);
    return formRef.current.validateForm();
  };

  const clearPasscodeChangeTimeout = (): void => {
    if (!isNil(passcodeChangeTimeoutId())) {
      clearTimeout(passcodeChangeTimeoutId());
      setPasscodeChangeTimeoutId(undefined);
    }
  };
  const handlePasscodeChange = (pairingCode: string) => {
    if (!isNilOrEmpty(pairingCode)) {
      const path = APIPath.ems.decode;
      setUnderDecoding(true);
      return apiClientWithoutHandler.genericController
        .put(path, { pairingCode: pairingCode })
        .then((res: any) => {
          const response = res?.data?.data;
          if (response) {
            formRef.current.setFieldValue('hostname', response.emsHostname, true);
            formRef.current.setFieldValue('port', response.emsPort, true);
            formRef.current.setFieldValue('keepAlive', response.emsKeepalive, true);
          }
          return res;
        })
        .finally(() => {
          setUnderDecoding(false);
        });
    }
    return null;
  };

  const onOperationStarted = (emsState: EmsServiceState): void => {
    setLastPairingError('');
    setEmsState(emsState);
    setIconState(IconStatusType.STARTING);
    pairingCheck(emsState);
  };

  // TODO use the same field names as the REST API names in the form for simplification
  const renderPairingButton = (values: any): ReactNode => {
    switch (emsState) {
      case EmsServiceState.PAIRING:
      case EmsServiceState.PAIRED:
      case EmsServiceState.CONNECTING:
        return <CancelPairingButton onOperationStarted={() => onOperationStarted(EmsServiceState.PAIRING)} />;
      case EmsServiceState.UNPAIRED:
        return (
          <PairButton
            validate={handleValidate}
            onOperationStarted={() => onOperationStarted(EmsServiceState.PAIRING)}
            pairingCode={values.pairingCode}
            hostname={values.hostname}
            busy={underDecoding}
            port={typeof values.port !== 'number' || isNaN(values.port) ? 0 : values.port}
            keepAlive={typeof values.keepAlive !== 'number' || isNaN(values.keepAlive) ? 0 : values.keepAlive}
          />
        );
      case EmsServiceState.CONNECTED:
      case EmsServiceState.UNPAIRING:
        return (
          <UnpairButton
            onOperationStarted={() => onOperationStarted(EmsServiceState.UNPAIRING)}
            disabled={emsState === EmsServiceState.UNPAIRING}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Formik
      className="hai-mb-6"
      initialValues={{
        keepAlive: 3,
        pairingCode: '',
        hostname: '',
        port: '',
      }}
      onSubmit={() => {
        //
      }}
      innerRef={formRef}
      validationSchema={emsValidationSchema(t)}
    >
      {(formikProps) => {
        return (
          <Form className="hai-mb-6">
            <FormSection title={t('settings.services.section.ems.title')}>
              <DynamicContainer minColumns={3}>
                <FormValue
                  label={t('settings.services.section.ems.connectionStatus.label')}
                  value={toTitleCase(emsState)}
                  icon={iconState}
                />
                <div />
                <div className="d-flex ml-auto">{renderPairingButton(formikProps.values)}</div>
              </DynamicContainer>
              <DynamicContainer minColumns={1}>
                <FormikTextfield
                  name="pairingCode"
                  mask
                  title={t('settings.services.section.ems.passcode.label')}
                  placeholder={t('settings.services.section.ems.passcode.labelExplain')}
                  onChange={(event: FormChangeEvent) => {
                    const passcode = event.target.value;
                    clearPasscodeChangeTimeout();
                    setPasscodeChangeTimeoutId(
                      setTimeout(() => {
                        handlePasscodeChange(passcode);
                      }, 500),
                    );
                  }}
                  onFocus={(e) => {
                    // select all on focus
                    (e.target as HTMLInputElement).select();
                  }}
                  required
                />
              </DynamicContainer>
              <DynamicContainer minColumns={3}>
                <FormikTextfield
                  name="hostname"
                  mask
                  title={t('settings.services.section.ems.hostname.label')}
                  required
                />
                <FormikTextfield
                  name="port"
                  mask
                  title={t('settings.services.section.ems.port.label')}
                  type="number"
                  required
                />
                <FormikTextfield
                  name="keepAlive"
                  title={t('settings.services.section.ems.keepAlive.label')}
                  placeholder={t('general.auto')}
                  type="number"
                />
              </DynamicContainer>
              {lastPairingError && (
                <div>
                  <span className="error">{lastPairingError}</span>
                </div>
              )}
            </FormSection>
          </Form>
        );
      }}
    </Formik>
  );
};
