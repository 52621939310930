import { DropdownButton, DropdownMenuItem, Icon } from '@hai/ui-react';
import { isEmpty, isNil } from 'ramda';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { watchEvent } from 'src/js/events';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useStores } from 'src/js/hook/use-stores';
import { useTask } from 'src/js/hook/use-task';
import { RebootResponse } from 'src/js/model/api/response/reboot-response';
import { handleLogout } from 'src/js/pages/login/login';
import { Paths } from 'src/js/route';
import { useWatchObject } from 'src/js/store/use-watch';
import { createLogoutTask } from 'src/js/task/session-task';
import { createRebootTask } from 'src/js/task/settings-tasks';
import { isNilOrEmpty } from 'src/js/util/global-util';

import { useAdaptiveFetch } from '../hook/use-adaptive-fetch';
import { constant } from '../constant';
import { PresetEditModal } from './preset-edit-modal';
import { presetName, presetNames } from './preset-helper';
import { PresetLoad } from './preset-load';
import { PresetManager } from './preset-manager';
import { PresetSavedResponse, PresetViewModel } from './preset-models';
import presetsModel, { createPreset, fetchPresets, isCurrentPresetSaved, updatePreset } from './preset-store';

const PresetWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [presets, setPresets] = useState(presetsModel);
  useWatchObject(presetsModel, setPresets);
  const { sessionStore } = useStores();

  const shouldReloadData = (): void => {
    if (!sessionStore.isUser()) {
      fetchPresets(t);
    }
  };

  if (!sessionStore.isUser()) {
    useAdaptiveFetch(() => fetchPresets(t), constant.presetRefreshRateInSeconds * 1000);
  }

  const [managerShown, showManager, hideManager] = useModalControls(false);
  const [loadShown, showLoad, hideLoad] = useModalControls(false);
  const [saveAsShown, showSaveAs, hideSaveAs] = useModalControls(false);

  const rebootTask = useTask(createRebootTask());
  const logoutTask = useTask(createLogoutTask());
  useSuccessReaction(rebootTask, (result: RebootResponse) => {
    navigate(result.upgrade ? Paths.rebootingUpgrade : Paths.rebooting);
  });

  const handleSave = (): Promise<any> => {
    const preset = presets.data.find((p) => p.name === presets.active);
    const overwrite = true;
    const displaySuccess = false;
    return updatePreset(preset, overwrite, displaySuccess, t).then((res) => {
      if (res.ok) {
        shouldReloadData();
      }
      return res;
    });
  };

  const [reboot, setReboot] = useState(false);
  const [logout, setLogout] = useState(false);
  const handleSaveAs = (preset: PresetViewModel, overwrite: boolean) => {
    createPreset(
      {
        name: `${preset.name}.cfg`,
        startup:
          preset.startup /*, TODO here we could further improve by passing an overwrite: overwrite to the backend to indicate the user confirmed the overwrite */,
      },
      overwrite,
      t,
    ).then((res) => {
      if (res.ok) {
        hideSaveAs();
        shouldReloadData();
        reboot && rebootTask();
        logout && logoutTask();
      }
    });
  };

  watchEvent('preset-save-reboot', () => {
    if (isNilOrEmpty(presets.active)) {
      setReboot(true);
      showSaveAs();
    } else {
      handleSave().then(() => {
        rebootTask();
      });
    }
  });

  watchEvent('preset-save-callback', (callback: any) => {
    if (isNilOrEmpty(presets.active)) {
      showSaveAs(); // no callback because this one has notification
    } else {
      handleSave().then(() => {
        callback?.();
      });
    }
  });

  useSuccessReaction(logoutTask, () => {
    handleLogout(false, navigate);
  });

  watchEvent('preset-save-logout', () => {
    if (isNilOrEmpty(presets.active)) {
      setLogout(true);
      showSaveAs();
    } else {
      handleSave().then(() => {
        logoutTask();
      });
    }
  });

  watchEvent('preset-update', () => {
    isCurrentPresetSaved().then((res) => {
      if (res.ok) {
        const response: PresetSavedResponse = res.data;
        setPresets((prev) => ({
          ...prev,
          activeWasModified: !response.isSaved,
        }));
      }
    });
  });

  // Allow clicking on Icon to open dropdown
  const handleClick = () => {
    const dropdown = document.getElementsByClassName('presetDropdown');
    if (!isNilOrEmpty(dropdown)) {
      const button = dropdown[0].getElementsByTagName('button');
      if (!isNilOrEmpty(button)) {
        button[0].click();
      }
    }
  };

  if (isNil(presets.data) || sessionStore.isUser()) {
    return null;
  }

  return (
    <>
      <div id="presets-widget" className={'tab-header-section-container ml-auto cursor-pointer'} onClick={handleClick}>
        <div className="position-relative" title={presets.activeWasModified ? t('presets.configNotSaved') : ''}>
          <Icon
            iconname="Presets"
            className="pure-white-svg"
            size="sm2"
            indicatorColor={presets.activeWasModified ? 'haiui-amber-01' : ''}
          />
        </div>

        <DropdownButton
          useFsMask={true}
          className="presetDropdown"
          title={isNilOrEmpty(presets.active) ? t('presets.noPreset') : presetName(presets.active)}
          withCheckmark={false}
        >
          <DropdownMenuItem sectionHead eventKey="devicePreset" title={t('presets.dropdownHeader')} />
          {!presets.autosave && presets.activeWasModified && !isNilOrEmpty(presets.active) && (
            <DropdownMenuItem
              eventKey="save"
              title={t('general.save')}
              icon="Save"
              selectable={false}
              onSelect={handleSave}
            />
          )}
          <DropdownMenuItem
            eventKey="saveAs"
            title={t('presets.menu.saveAs')}
            icon="Duplicate"
            selectable={false}
            onSelect={showSaveAs}
          />
          {!isEmpty(presets.data) && (
            <DropdownMenuItem
              eventKey="load"
              title={t('presets.menu.load')}
              icon="Import"
              selectable={false}
              onSelect={showLoad}
            />
          )}
          {(sessionStore.isAdmin() || sessionStore.isOperator()) && (
            <DropdownMenuItem
              eventKey="manage"
              title={t('presets.menu.managePresets')}
              icon="Presets"
              selectable={false}
              onSelect={showManager}
            />
          )}
        </DropdownButton>
      </div>
      <>
        {managerShown && <PresetManager presets={presets} onCancel={hideManager} shouldReloadData={shouldReloadData} />}
        {loadShown && <PresetLoad presets={presets} onCancel={hideLoad} shouldReloadData={shouldReloadData} />}
      </>
      {saveAsShown && (
        <PresetEditModal
          autosave={presets.autosave}
          showSetAsStartupCheckbox={true}
          viewModel={{
            ...presets.data.find((p) => p.name === presets.active),
            startup: presets.autosave,
          }}
          presetNames={presetNames(presets.data.map((p) => p.name))}
          onSubmit={handleSaveAs}
          onCancel={hideSaveAs}
          title={t('presets.modal.form.saveAsTitle')}
        />
      )}
    </>
  );
};

export default PresetWidget;
