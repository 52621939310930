import { FormGroup, FormLabel, LabelValue } from '@hai/ui-react';
import React from 'react';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  label: string;
  value?: string;
}

export const CurrentVersionParameter: React.FunctionComponent<Props> = ({ label, value, ...rest }) => {
  return (
    <FormGroup {...rest}>
      <FormLabel>{label}</FormLabel>
      <LabelValue showCopy={false} data-auto={generateDataAuto('form-label', 'current version')}>
        {value}
      </LabelValue>
    </FormGroup>
  );
};
