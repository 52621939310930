import { Observable } from 'rxjs';
import { BaseController } from 'src/js/controller/base-controller';
import { NetworkRequest } from 'src/js/model/api/request/network-request';
import { NetworkResponse } from 'src/js/model/api/response/network-response';
import { APIPath } from 'src/js/api/route-path-index';

export class SettingsNetworkController extends BaseController {
  /* Retrieves Network information */
  public getNetwork(): Observable<NetworkResponse> {
    return this.get$(APIPath.network);
  }

  /* Updates Network information */
  public putNetwork(request: NetworkRequest): Observable<NetworkResponse> {
    return this.put$(APIPath.network, request);
  }
}
