import { Dialog, PanelGroup } from '@hai/ui-react';
import { isEmpty, isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { useMutable } from 'src/js/hook/use-mutable';
import { constant } from 'src/js/constant';
import { isNilOrEmpty, scrollIntoView } from 'src/js/util/global-util';
import { GridManager, GridManagerRef } from 'src/js/component/grid-manager';
import useSWR from 'swr';

import { OneStillImage } from './onestillimage';
import { StillImage } from './still-image-model';

interface Props {
  selected?: string;
  onClose?: VoidFunction;
  onCancel?: VoidFunction;
  onSelect: (stillImageName: string) => Promise<any> | void;
}

export const StillImagesPickupModal: React.FunctionComponent<Props> = ({ selected, onClose, onCancel, onSelect }) => {
  const { t } = useTranslation();

  const [imageSelected, setImageSelected] = useState(selected);
  const [mutableSelected, setMutableSelected] = useMutable(selected); // useState wont work with a custom event callback addEventListener
  const [newScrollTrigger /*, setNewScrollTrigger*/] = useState(0);

  const modal = React.useRef<HTMLDivElement>();
  const gridManagerRef = React.useRef<GridManagerRef<StillImage>>();
  /*
  let endrun = false;
  useEffect(() => {
    return () => {
      endrun = true;
    };
  }, []);*/

  const fetcher = (url: string) => {
    return apiClientWithoutHandler.genericController.get(url).then((res: any) => {
      return res?.data?.data;
    });
  };

  const { data } = useSWR(APIPath.stillimages.list, fetcher, { revalidateOnFocus: false });

  const onLocalSelect = (model: StillImage, isSelected: boolean) => {
    if (model == null) {
      return;
    }
    if (isSelected) {
      setImageSelected(model.name);
      setMutableSelected(model.name);
    } else {
      setImageSelected(null);
      setImageSelected(null);
    }
  };

  const onApply = () => {
    onSelect(imageSelected);
  };
  /*
  let triggerMoveTimeout: any = null;
  const onKeyPressForm = (e: UIEvent & React.KeyboardEvent<HTMLElement>): void | boolean => {
    // console.log('keydown', e.key);
    if ((e as any).srcElement?.className?.toString().includes('dropdown')) {
      // from dropdown
      return false;
    }

    if (e.key === 'Enter') {
      return;
    }

    if (e.key === 'ESC') {
      return;
    }

    if ((e as any).srcElement?.className?.toString().includes('HaiFormControl')) {
      // from form element
      return false; // ignore for Up/Down/Left/Right arrow
    }

    if (e.key === 'ArrowUp') {
      onLocalSelect(gridManagerRef.current?.onKeyUp(), true);
    }
    if (e.key === 'ArrowDown') {
      onLocalSelect(gridManagerRef.current?.onKeyDown(), true);
    }
    if (e.key === 'ArrowLeft') {
      onLocalSelect(gridManagerRef.current?.onKeyLeft(), true);
    }
    if (e.key === 'ArrowRight') {
      onLocalSelect(gridManagerRef.current?.onKeyRight(), true);
    }

    const triggerMove = () => {
      if (endrun) {
        triggerMoveTimeout = null;
        return;
      }
      // console.log('triggerMove', e.key);

      setNewScrollTrigger((v) => v + 1);
      triggerMoveTimeout = null;
    }; // end triggerMove

    if (triggerMoveTimeout === null) {
      triggerMoveTimeout = setTimeout(triggerMove, 10); // debounce
    }

    if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      // prevent bubble up and causing focus to go outside on other fields
      e.preventDefault();
      return false;
    }
  };*/

  const onKeyup = (e: any) => {
    e.preventDefault();
    if (e.keyCode === 27) {
      // ESC
      onCancel();
    }
    if (e.keyCode === 13) {
      // ENTER
      onSelect(mutableSelected());
      onClose();
    }
  };
  useEffect(() => {
    if (!isNil(modal.current)) {
      document.addEventListener('keyup', onKeyup);
      // BUGGY FIXME  document.addEventListener('keydown', onKeyPressForm as EventListener);
    }
    return () => {
      document.removeEventListener('keyup', onKeyup);
      //  document.removeEventListener('keydown', onKeyPressForm as EventListener);
    };
  }, [modal]);

  useEffect(() => {
    if (imageSelected && imageSelected.length) {
      scrollIntoView(`stillimage-pickup-${imageSelected}`);
    }
  }, [newScrollTrigger]);

  return (
    <>
      <Dialog
        className="max-size"
        accentColor={constant.productColor}
        title={t('settings.stillImages.select.title')}
        onClose={onClose}
        show={true}
        dialogType="activity"
        headerIcon="StillImage"
        icon="none"
        size="lg"
        content={
          <div id="stillimages" ref={modal}>
            <div className="selectModal">
              <PanelGroup className="grid-4x">
                <GridManager ref={gridManagerRef}>
                  {(gridManager) => {
                    return (
                      <>
                        {!isEmpty(data) &&
                          data &&
                          data.map((value: StillImage, index: number) => (
                            <OneStillImage
                              gridManager={gridManager}
                              key={`${value.name}-${index}`}
                              id={`stillimage-pickup-${value.name}`}
                              model={value}
                              selectable
                              selected={value?.name === imageSelected}
                              onSelect={onLocalSelect}
                            />
                          ))}
                      </>
                    );
                  }}
                </GridManager>
              </PanelGroup>
            </div>
          </div>
        }
        buttons={[
          { label: t('general.cancel'), onClick: onCancel },
          {
            label: t('general.select'),
            variant: 'primary',
            onClick: onApply,
            disabled: isNilOrEmpty(imageSelected),
          },
        ]}
      />
    </>
  );
};
