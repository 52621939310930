import { createWatchStore, updateWatchStore, useWatch } from 'src/js/store/use-watch';

const Events: any = {};
createWatchStore(Events, { allowWatchingFutureProperties: true });
export default Events;

export const triggerEvent = (name: string, data?: string | object | boolean) => {
  const newObj: any = {};
  newObj[name] = data || true;
  updateWatchStore(Events, newObj);
  delete Events[name];
};

export const watchEvent = (name: string, callback: (data?: any) => void) => {
  const data = Events[name];
  useWatch(Events, name, data ? () => callback(data) : callback);
};
