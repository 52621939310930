import { List } from '@hai/ui-react';
import React, { useEffect } from 'react';
import { SortDirection, SortManager } from 'src/js/component/sort-manager';
import { scrollPanelIntoView } from 'src/js/component/actionBar/streaming-action-bar';
import { constant } from 'src/js/constant';

import { DecoderViewModel } from './decoder-model';
import { DecoderRow } from './decoder-row';

interface Props {
  list: DecoderViewModel[];
  direction: SortDirection;
  sorting: string;
  onSelect: (item: DecoderViewModel, selected: boolean) => void;
  sortInfo: any;
  scrollToId: any;
}

export const DecoderList: React.FunctionComponent<Props> = ({
  list,
  direction,
  sorting,
  onSelect,
  sortInfo,
  scrollToId,
}) => {
  useEffect(() => {
    scrollPanelIntoView({ id: scrollToId });
  }, []);

  return (
    <>
      <List accentColor={constant.productColor} removeMediaQueries>
        <SortManager sortInfo={sortInfo} list={list} sortKey={sorting} direction={direction}>
          {list &&
            list.map((decoder) => {
              return <DecoderRow key={decoder.id} model={decoder} onSelect={onSelect} scrollToId={scrollToId} />;
            })}
        </SortManager>
      </List>
    </>
  );
};
