import { Button, Modal, ModalBody, ModalHeader, ModalTitle } from '@hai/ui-react';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSuccessReaction } from 'src/js/hook/use-reaction';
import { useTask } from 'src/js/hook/use-task';
import { NtpStatisticsResponse } from 'src/js/model/api/response/ntp-statistics-response';
import { createGetNtpStatisticsTask } from 'src/js/task/settings-date-time-tasks';

import { NtpStatisticsSection } from './ntp-statistics-section';
import { generateDataAuto } from 'src/js/util/automation';

interface Props {
  pollingInterval?: number; // in ms
  onClose?: VoidFunction;
}

export const NtpStatisticsModal: React.FunctionComponent<Props> = ({ pollingInterval = 5000, onClose }) => {
  const { t } = useTranslation();
  const ntpStatisticsTask = useTask(createGetNtpStatisticsTask());
  const [statistics, setStatistics] = useState<NtpStatisticsResponse>(undefined);
  useEffect((): VoidFunction => {
    ntpStatisticsTask();
    const getNtpStatisticsIntervalId = setInterval(() => {
      ntpStatisticsTask();
    }, pollingInterval);
    return (): void => {
      clearInterval(getNtpStatisticsIntervalId);
    };
  }, []);
  useSuccessReaction(ntpStatisticsTask, (result) => {
    setStatistics(result);
  });

  return (
    <Modal show onHide={onClose} centered data-auto={generateDataAuto('ntp', 'statistics modal')} size="lg">
      <ModalHeader>
        <ModalTitle>{t('settings.dateTime.section.statistics.title')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        {!isNil(statistics) && (
          <div>
            <NtpStatisticsSection
              title={t('settings.dateTime.section.statistics.tracking')}
              content={statistics.tracking}
            />
            <NtpStatisticsSection
              title={t('settings.dateTime.section.statistics.sources')}
              content={statistics.sources}
              className="hai-mt-6"
            />
            <NtpStatisticsSection
              title={t('settings.dateTime.section.statistics.sourceStats')}
              content={statistics.sourcestats}
              className="hai-mt-6"
            />
          </div>
        )}
        <div className="hai-mt-9 d-flex justify-content-center">
          <Button variant="primary" onClick={onClose}>
            {t('general.close')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
