import {
  Button,
  DynamicContainer,
  Form,
  FormContext,
  FormControl,
  FormGroup,
  FormLabel,
  Icon,
  IconButton,
  SelectOption,
} from '@hai/ui-react';
import { ButtonStateType } from '@hai/ui-react/dist/types';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { isNil } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FormTextfield } from 'src/js/component/base/form/form-textfield';
import { SDIType, SdiCheckbox } from 'src/js/component/base/form/sdi-checkbox';
import { FormBuilder, FormFieldType, FormLayout } from 'src/js/component/form-builder';
import systemInfo from 'src/js/data/systemInfo';
import { triggerEvent } from 'src/js/events';
import { keyValueForEnum } from 'src/js/helper/enum-helper';
import { useModalControls } from 'src/js/hook/use-modal-controls';
import { useMutable } from 'src/js/hook/use-mutable';
import { useStores } from 'src/js/hook/use-stores';
import { StillImagesPickupModal } from 'src/js/pages/settings/stillimages/stillimages-pickup-modal';
import streamModels from 'src/js/pages/streaming/stream/stream-store';
import { RebootNeeded, RebootNeededRef } from 'src/js/reboot/reboot-needed';
import { isNilOrEmpty } from 'src/js/util/global-util';
import { validateFormContext } from 'src/js/validator/validator';
import { decoderOptionsStreamName } from '../stream/stream-view-model-mapper';

import {
  BufferingModeEnum,
  DecoderHdrEnum,
  DecoderViewModel,
  MkRange,
  QuadModeEnum,
  StillImagesEnum,
  decoderValidationSchema,
  fixedDelayRange,
  multiSyncDelayRange,
  multiSyncWarning,
  outputFrameRateOptions,
} from './decoder-model';
import decoders, { updateDecoder } from './decoder-store';
import { generateDataAuto } from 'src/js/util/automation';
import { changes } from 'src/js/util/formik-helper';

interface Props {
  model: DecoderViewModel;
  onCancel: VoidFunction;
}

export const CompanionField = (props: any) => {
  return (
    <div className="d-flex mk-companioned-field">
      {props.children}
      {props.companion}
    </div>
  );
};
CompanionField.displayName = 'CompanionField';

export const DecoderEdit: React.FunctionComponent<Props> = ({ model, onCancel }) => {
  const { t } = useTranslation();
  const { sessionStore } = useStores();

  const formRef = useRef<FormikProps<DecoderViewModel>>();
  const [formikProps, setFormikProps] = useState<FormikProps<DecoderViewModel>>(formRef.current);

  useEffect(() => {
    if (isNil(formikProps) && !isNil(formRef.current)) {
      setFormikProps(formRef.current); //FIXME this is not reliable
    }
  }, [formRef.current]);

  const rebootNeededRef = useRef<RebootNeededRef>(null);

  // setting a state here prevents live update from modifying the edit form
  const [decoder, setDecoder] = useState<DecoderViewModel>(model);
  const [buttonState, setButtonState] = useState<ButtonStateType>(undefined);

  const navigate = useNavigate();
  const handleClickOpenStreamEdit = (streamId: number) => {
    navigate('/streaming/stream/', { state: { id: streamId, stats: false } });
  };

  const handleSubmit = (values: DecoderViewModel) => {
    setButtonState('pending');

    const data = { ...values };
    data.stillImage = Number(data.stillImage);
    if (data.stillImage === StillImagesEnum.CUSTOM && isNilOrEmpty(values.stillImageFilename)) {
      data.stillImage = undefined;
    }

    return updateDecoder(data, t).then((res) => {
      if (res.ok) {
        const dec = decoders.find((e) => e.id === model.id);
        setDecoder(dec); // update edit view with newest model
        formikProps.resetForm({ values: dec });
        triggerEvent('preset-update');
        triggerEvent('thumbnail-update');
      }
      setButtonState(undefined);
      return res;
    });
  };

  const handleChangeSDIOutput = (event: any) => {
    const index = event.target.dataset.index;
    const outputs = [...formRef.current.values.outputs];
    outputs[index] = event.target.checked;
    formRef.current.setFieldValue('outputs', outputs);
    formRef.current.setFieldTouched('outputs');
  };

  const isQuadMode2SI = (decoder: DecoderViewModel, quadMode: QuadModeEnum) =>
    decoder?.outputs.every((output) => output) && quadMode === QuadModeEnum.QUAD_2SI;
  const [quadMode2SI, setQuadMode2SI] = useState(isQuadMode2SI(decoder, decoder.quadMode));
  const handleChangeQuadMode = (event: any) => {
    setQuadMode2SI(isQuadMode2SI(formRef.current.values, event.target.value));
  };

  const [stillImagePrev, setStillImagePrev] = useMutable(null);
  const [stillImagePickupShown, showStillImagePickup, hideStillImagePickup] = useModalControls();
  const handleChangeStillImage = (event: any) => {
    if (Number(event.target.value) === StillImagesEnum.CUSTOM) {
      setStillImagePrev(Number(formRef.current.values.stillImage));
      showStillImagePickup();
    }
    formRef.current.setFieldValue('stillImage', Number(event.target.value)); // this is almost useless because HaiUI will set it in formik after our callback is called. TODO: Open a bug in HaiUI
    formRef.current.setFieldTouched('stillImage');
  };

  const onSelectStillImage = (stillImage: string) => {
    formRef.current.setFieldValue('stillImage', StillImagesEnum.CUSTOM);
    formRef.current.setFieldValue('stillImageFilename', stillImage);
    formRef.current.setFieldTouched('stillImage');
  };

  const handleCancelStillImage = () => {
    hideStillImagePickup();
    formRef.current.setFieldValue('stillImage', stillImagePrev());
  };

  const multiSyncValidationRange: MkRange = multiSyncDelayRange(decoder.stats);
  const fixedDelayValidationRange: MkRange = fixedDelayRange(decoder.stats);

  // stream options
  let streamOptions: { value: string | number; label: string }[] = [
    { value: '-1', label: t('decoder.streamSelectNone') },
  ];
  //let selectedStreamOption = streamOptions[0]; // for react-select
  let selectedStreamOptionValue = streamOptions[0].value; // for HaiUI dropdowns
  streamOptions = streamOptions.concat(
    streamModels.map((stream) => {
      const option = {
        value: stream.id,
        label: decoderOptionsStreamName(t, stream),
      };
      if (decoder.streamId === stream.id) {
        //selectedStreamOption = option;
        selectedStreamOptionValue = option.value;
      }
      return option;
    }),
  );

  return (
    <>
      <Form
        initialValues={decoder}
        defaultValidation={true}
        handleSubmit={handleSubmit}
        className="hai-mt-n8"
        restValidationProps={{
          enableReinitialize: false, // "false" requires manual resetForm() above. We can then do partial update of the form while it's opened.
          validationSchema: decoderValidationSchema(multiSyncValidationRange, fixedDelayValidationRange, t),
          innerRef: formRef,
        }}
      >
        <FormContext.Consumer>
          {(formContext: FormikProps<DecoderViewModel>) => {
            const values = formContext.values;

            let applyButtonEnabled = formContext.dirty;
            if (applyButtonEnabled) {
              const changed = changes(values, formContext.initialValues);
              if (changed.stillImage !== undefined) {
                if (values.stillImage == formContext.initialValues?.stillImage) {
                  delete changed.stillImage; // string vs integer
                }
              }
              // final calculation
              if (Object.keys(changed).length === 0) {
                applyButtonEnabled = false;
              }
            }

            const fixedRange = fixedDelayRange(values.stats);
            const showFixedBufferingOutOfRange =
              values.bufferingMode === BufferingModeEnum.FIXED &&
              (values.bufferingDelay < fixedRange.min || values.bufferingDelay > fixedRange.max);

            const multiSyncRange = multiSyncDelayRange(values.stats);
            const showMultiSyncOutOfRange =
              values.bufferingMode === BufferingModeEnum.MULTISYNC &&
              (values.multisyncBufferingDelay < multiSyncRange.min ||
                values.multisyncBufferingDelay > multiSyncRange.max);

            const companionField = (children: any) => {
              const disabled = values.streamId == -1;
              return (
                <CompanionField>
                  {children}
                  <div className="box mk-icon-action">
                    <IconButton
                      data-auto={generateDataAuto('stream', 'icon')}
                      onClick={() => handleClickOpenStreamEdit(values.streamId)}
                      title={t('dashboard.Stats')}
                      disabled={disabled}
                    >
                      <Icon iconname="Streams" />
                    </IconButton>
                  </div>
                </CompanionField>
              );
            };

            return (
              <>
                <DynamicContainer minColumns={2} maxColumns={2} className="hai-mt-panel-name-align">
                  <FormTextfield
                    name="name"
                    mask
                    placeholder={t('decoder.name', { id: decoder.id })}
                    title={t('general.name')}
                    className="max-width"
                  />
                  {!sessionStore.isUser() && (
                    <div className="d-flex ml-auto hai-mt-panel-apply-sticky">
                      <Button className="hai-mr-6" onClick={onCancel}>
                        {t('general.cancel')}
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => validateFormContext(formContext, t)}
                        disabled={!applyButtonEnabled}
                        state={buttonState}
                      >
                        {t('general.apply')}
                      </Button>
                    </div>
                  )}
                </DynamicContainer>
                <FormBuilder
                  translationPrefixLabels="decoder.fields"
                  translationPrefixEnums="decoder.enums"
                  layout={FormLayout.THREE_COLUMNS_GRID}
                  fields={[
                    {
                      type: FormFieldType.TITLE,
                      label: t('decoder.sections.stream'),
                    },
                    {
                      type: FormFieldType.DROPDOWN,
                      mask: true,
                      name: 'streamId',
                      value: selectedStreamOptionValue,
                      options: streamOptions,
                      container: companionField,
                    },
                    {
                      type: FormFieldType.NODE,
                      node: (
                        <FormGroup className="sdi-checkbox-container">
                          <FormLabel>{t('decoder.sdiOutputs')}</FormLabel>
                          <div className={classNames('sdi-checkboxes hai-mt-1', quadMode2SI && 'quadMode2SI')}>
                            {values.outputs.map((value, index) => {
                              const type =
                                value !== values.outputsOrig[index] && value == true
                                  ? SDIType.HIGHLIGHT
                                  : value !== values.outputsOrig[index] && value == false
                                  ? SDIType.OUTLINE
                                  : SDIType.NORMAL;
                              return (
                                <SdiCheckbox
                                  key={index}
                                  name={`outputs[${index}]`}
                                  data-index={index}
                                  checked={value}
                                  type={type}
                                  onChange={handleChangeSDIOutput}
                                >{`${index + 1}`}</SdiCheckbox>
                              );
                            })}
                          </div>
                        </FormGroup>
                      ),
                    },
                    {
                      type: FormFieldType.DROPDOWN,
                      name: 'quadMode',
                      options: keyValueForEnum(QuadModeEnum).map(([, value]) => ({
                        label: t(`decoder.enums.quadMode.${value}`),
                        value: value,
                      })),
                      onChange: handleChangeQuadMode,
                      when: values.outputs.every((output) => output),
                    },
                    {
                      type: FormFieldType.TITLE,
                      label: t('decoder.sections.input'),
                    },
                    {
                      type: FormFieldType.STATIC_TEXT,
                      name: 'resolution',
                      value: model.stats.videoInputResolution,
                    },
                    {
                      type: FormFieldType.LINE_BREAK,
                    },
                    /* removed in 1.6 R4KD-1974
                    {
                      type: FormFieldType.STATIC_TEXT,
                      name: 'audio',
                      value: model.stats.audioSampleRate,
                    },*/
                    {
                      type: FormFieldType.DROPDOWN,
                      name: 'bufferingMode',
                      options: [
                        {
                          value: BufferingModeEnum.FIXED,
                          label: t(`decoder.bufferingModes.FIXED`),
                        },
                        {
                          value: BufferingModeEnum.MULTISYNC,
                          label: t(`decoder.bufferingModes.MULTISYNC`),
                        },
                        {
                          value: BufferingModeEnum.AUTOMATIC,
                          label: t(`decoder.bufferingModes.AUTOMATIC`),
                        },
                      ],
                      addon: (
                        <>
                          {values.bufferingMode === BufferingModeEnum.MULTISYNC && (
                            <div className="warning-container">{multiSyncWarning(values.stats, t)}</div>
                          )}
                        </>
                      ),
                    },
                    {
                      type: FormFieldType.INPUT_NUMBER,
                      name: 'bufferingDelay',
                      hint: `${fixedDelayValidationRange.min} - ${fixedDelayValidationRange.max}`,
                      when: values.bufferingMode === BufferingModeEnum.FIXED,
                      className: showFixedBufferingOutOfRange && 'warningHint',
                    },
                    {
                      type: FormFieldType.INPUT_NUMBER,
                      name: 'multisyncBufferingDelay',
                      hint: `${multiSyncValidationRange.min} - ${multiSyncValidationRange.max}`,
                      when: values.bufferingMode === BufferingModeEnum.MULTISYNC,
                      className: showMultiSyncOutOfRange && 'warningHint',
                    },
                    {
                      type: FormFieldType.CHECKBOX,
                      name: 'dropCorruptedFrames',
                    },
                    {
                      type: FormFieldType.TITLE,
                      label: t('decoder.sections.output'),
                    },
                    {
                      type: FormFieldType.STATIC_TEXT,
                      name: 'outputResolution',
                      value: model.stats.videoDisplayResolution,
                    },
                    {
                      type: FormFieldType.DROPDOWN,
                      name: 'outputFrameRate',
                      options: outputFrameRateOptions(t).map((fr) => ({
                        label: fr.label,
                        value: fr.value,
                      })),
                    },
                    {
                      type: FormFieldType.CHECKBOX,
                      name: 'downmixSurround',
                      title: t('decoder.fields.downmixSurroundTitle'),
                    },
                    {
                      type: FormFieldType.LINE_BREAK,
                    },
                    {
                      type: FormFieldType.DROPDOWN,
                      name: 'hdrDynamicRange',
                      options: keyValueForEnum(DecoderHdrEnum).map(([, value]) => ({
                        label: t(`decoder.enums.hdr.${value}`),
                        value: value,
                      })),
                      when: systemInfo.hasHDR === true,
                    },
                    {
                      type: FormFieldType.NODE,
                      node: (
                        <div className={`mk-form-select`}>
                          <FormGroup>
                            <FormLabel>{t('decoder.fields.stillImage')}</FormLabel>
                            <FormControl
                              name="stillImage"
                              as="select"
                              defaultSelect={
                                values.stillImage == StillImagesEnum.CUSTOM
                                  ? values.stillImageFilename
                                  : t(`decoder.enums.stillImage.${values.stillImage}`)
                              }
                              onChange={handleChangeStillImage}
                            >
                              {keyValueForEnum(StillImagesEnum).map(([, value]) => {
                                // using value.toString() is kind of a hack. otherwise defaultSelect wont work rendering the custom filename
                                return (
                                  <SelectOption key={value} value={value.toString()}>
                                    {t(`decoder.enums.stillImage.${value}`)}
                                  </SelectOption>
                                );
                              })}
                            </FormControl>
                          </FormGroup>
                        </div>
                      ),
                    },
                    {
                      type: FormFieldType.INPUT_NUMBER,
                      name: 'stillImageDelay',
                      hint: t('decoder.fields.stillImageDelayExplain'),
                      required: true,
                    },

                    {
                      type: FormFieldType.TITLE,
                      label: t('decoder.sections.metadata'),
                    },
                    {
                      type: FormFieldType.STATIC_TEXT,
                      name: 'klv',
                      value: model.stats?.klvUnlicensed
                        ? t('decoder.metadata.unlicensed')
                        : model.stats?.klv
                        ? t('decoder.metadata.present')
                        : t('decoder.metadata.notPresent'),
                    },
                    {
                      type: FormFieldType.STATIC_TEXT,
                      name: 'cc',
                      value: model.stats?.closedCaption
                        ? t('decoder.metadata.present')
                        : t('decoder.metadata.notPresent'),
                    },
                    {
                      type: FormFieldType.STATIC_TEXT,
                      name: 'tc',
                      value: model.stats?.timeCode ? t('decoder.metadata.present') : t('decoder.metadata.notPresent'),
                    },
                    {
                      type: FormFieldType.STATIC_TEXT,
                      name: 'afd',
                      value: model.stats?.afd ? t('decoder.metadata.present') : t('decoder.metadata.notPresent'),
                    },
                  ]}
                />
              </>
            );
          }}
        </FormContext.Consumer>
      </Form>
      {stillImagePickupShown && (
        <StillImagesPickupModal
          onSelect={(imageName: string) => {
            hideStillImagePickup();
            return onSelectStillImage(imageName);
          }}
          onCancel={handleCancelStillImage}
          onClose={hideStillImagePickup}
          selected={formRef.current?.values?.stillImageFilename ?? decoder.stillImageFilename}
        ></StillImagesPickupModal>
      )}
      <RebootNeeded ref={rebootNeededRef} />
    </>
  );
};
