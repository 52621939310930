import { FormContext, FormGroup, FormLabel, Icon, LabelValue } from '@hai/ui-react';
import { isNil } from 'ramda';
import React from 'react';
import { IconStatusType } from 'src/js/model/icon-status-type';

interface Props {
  className?: string;
  name?: string;
  label: string;
  labelClass?: string;
  mask?: boolean;
  icon?: IconStatusType;
  value?: string;
}

export const FormValue: React.FunctionComponent<Props> = ({
  name,
  label,
  labelClass = '',
  mask,
  icon,
  value,
  ...rest
}) => {
  return (
    <FormContext.Consumer>
      {(formContext: any) => {
        if (name !== undefined && name !== '' && value === undefined) {
          const meta = formContext?.getFieldMeta(name);
          const formikValue = meta?.touched ? meta.value : meta?.initialValue;
          value = formikValue;
        }
        return (
          <FormGroup {...rest}>
            <FormLabel className={labelClass}>{label}</FormLabel>
            <LabelValue showCopy={false} useFsMask={mask}>
              {!isNil(icon) && <Icon type="status" subtype={icon} size="sm3" />}
              {value}
            </LabelValue>
          </FormGroup>
        );
      }}
    </FormContext.Consumer>
  );
};
