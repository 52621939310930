/**
 * This is an application specific type, we keep the same names but change the values
 */
export enum IconStatusType {
  ERROR = 'error',
  OK = 'ok',
  ACTIVE = 'streaming',
  WARNING = 'warning',
  OFFLINE = 'offline',
  PAUSED = 'paused',
  INACTIVE = 'inactive',
  STREAMING = 'to-storage',
  READY = 'listening',
  TO_STORAGE = 'to-storage',
  STOPPED = 'inactive',
  UNASSIGNED = 'unassigned',
  STARTING = 'starting',
  STOPPING = 'stopping',
}
