import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { TestRouter } from 'src/js/component-tester/test-router';
import { NotFound } from 'src/js/component/not-found';
import { AuthenticatedRoute } from 'src/js/component/router/authenticated-route';
import { Config } from 'src/js/config';
import { watchEvent } from 'src/js/events';
import { useStores } from 'src/js/hook/use-stores';
import { Dashboard } from 'src/js/pages/dashboard/dashboard';
import { Login } from 'src/js/pages/login';
import { SecurityPage } from 'src/js/pages/security/security';
import { SecurityRoutes } from 'src/js/pages/security/security-router';
import { Settings } from 'src/js/pages/settings/settings';
import { SettingsRoutes } from 'src/js/pages/settings/settings-router';
import { BandwidthGraphFullpage } from 'src/js/pages/statistic/bandwidth-graph-fullpage';
import { FullStatsPage } from 'src/js/pages/streaming/full-stats-page';
import { Streaming } from 'src/js/pages/streaming/streaming';
import { StreamsRoutes } from 'src/js/pages/streaming/streaming-router';
import { Rebooting } from 'src/js/reboot/rebooting';
import { RebootingUpgrade } from 'src/js/reboot/rebooting-upgrade';
import { Paths } from 'src/js/route';

export const MakitoRoutes: React.FunctionComponent = () => {
  require(`src/scss/mx4d.scss`);
  const { sessionStore } = useStores();

  const [settingsRoutes, setSettingsRoutes] = useState(SettingsRoutes);
  const [securityRoutes, setSecurityRoutes] = useState(SecurityRoutes);
  const refreshRoutes = () => {
    setSettingsRoutes(sessionStore.isAdmin() ? SettingsRoutes : SettingsRoutes.filter((r: any) => !r.restricted));
    setSecurityRoutes(sessionStore.isAdmin() ? SecurityRoutes : SecurityRoutes.filter((r: any) => !r.restricted));
  };

  useEffect(() => {
    refreshRoutes();
  }, []);

  watchEvent('routes-refresh', () => {
    refreshRoutes();
  });

  return (
    <Routes>
      <Route path={Paths.login} element={<Login />} />
      <Route path={Paths.help} element={<AuthenticatedRoute></AuthenticatedRoute>} />

      <Route
        index
        path={Paths.dashboard}
        element={
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        }
      />

      <Route element={<AuthenticatedRoute />}>
        <Route element={<Streaming />}>
          {StreamsRoutes.map((route: any, i: number) => (
            <Route key={i} {...route} path={'/streaming/' + route.path} />
          ))}
        </Route>
      </Route>

      <Route element={<AuthenticatedRoute />}>
        <Route element={<Settings />}>
          {settingsRoutes.map((route: any, i: number) => (
            <Route key={i} {...route} path={'/settings/' + route.path} />
          ))}
        </Route>
      </Route>

      <Route element={<AuthenticatedRoute />}>
        <Route element={<SecurityPage />}>
          {securityRoutes.map((route: any, i: number) => (
            <Route key={i} {...route} path={'/security/' + route.path} />
          ))}
        </Route>
      </Route>

      <Route
        path={Paths.stats}
        element={
          <AuthenticatedRoute>
            <FullStatsPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={Paths.statsfull}
        element={
          <AuthenticatedRoute sidebar={false}>
            <FullStatsPage sidebar={false} />
          </AuthenticatedRoute>
        }
      />

      <Route path={Paths.bandwidthGraph} element={<BandwidthGraphFullpage />} />
      <Route path={Paths.test} element={Config.isDebug ? <TestRouter /> : <NotFound />} />
      <Route path={Paths.rebooting} element={<Rebooting />} />
      <Route path={Paths.rebootingUpgrade} element={<RebootingUpgrade />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
