import { map } from 'rxjs/operators';
import { rxTaskFactory } from 'src/js/helper/mobx-tasks';
import { mapLedStatus } from 'src/js/mapper/locate-status-mapper';
import { GetLocateStatusResponse } from 'src/js/model/api/response/get-locate-status-response';
import { LocateStatus } from 'src/js/model/locate-status';
import { apiClient } from 'src/js/api';
import { NetworkTaskGenerator, SameTypeNetworkTaskGenerator } from 'src/js/util/global-type';

export const createGetLocateStatusTask: NetworkTaskGenerator<GetLocateStatusResponse, LocateStatus> = () =>
  rxTaskFactory(() =>
    apiClient.settingsStatusController
      .getLocateStatus()
      .pipe(map<GetLocateStatusResponse, LocateStatus>((response: GetLocateStatusResponse) => mapLedStatus(response))),
  );

export const createStartLocateTask: SameTypeNetworkTaskGenerator<{}> = () =>
  rxTaskFactory((duration: number) => apiClient.settingsStatusController.startLocate(duration));

export const createStopLocateTask: SameTypeNetworkTaskGenerator<{}> = () =>
  rxTaskFactory(() => apiClient.settingsStatusController.stopLocate());
