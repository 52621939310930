import React from 'react';
import { Navigate } from 'react-router-dom';
import { compileWithArgs } from 'src/js/helper/path-to-regexp-helper';
import { useStores } from 'src/js/hook/use-stores';
import { ListSelectionWithDataStoreProvider } from 'src/js/provider/list-selection-with-data-store-provider';
import { Paths } from 'src/js/route';
import { constant } from 'src/js/constant';
import { useReadLocalStorage } from 'usehooks-ts';

import { DateAndTime } from './datetime/date-and-time';
import { Licenses } from './license/licenses';
import { Network } from './network/network';
import { Services } from './services/services';
import { SettingKey, SettingsParameters } from './settings';
import { Status } from './status/status';
import { StillImages } from './stillimages/stillimages';
import { Upgrade } from './upgrade/upgrade';

export const DefaultSettingsRoute: React.FunctionComponent = () => {
  const { sessionStore } = useStores();

  const selectedTab =
    useReadLocalStorage(`${constant.lStorage.settingsTab}:${sessionStore.username}`) ?? SettingKey.SYSTEM;

  return (
    <Navigate
      to={compileWithArgs<SettingsParameters>(Paths.settings)({
        page: selectedTab as SettingKey, // show page in localstorage if a direct section access without any specific page
      })}
      /* replace current entry in history stack instead of adding new one, thus allowing to use back button a single time to go back (as expected) instead of two */
      replace={true}
    />
  );
};

export const SettingsRoutes: any = [
  {
    path: '',
    element: <DefaultSettingsRoute />,
  },
  {
    path: SettingKey.SYSTEM,
    element: <Status />,
  },
  {
    path: SettingKey.LICENSING,
    element: (
      <ListSelectionWithDataStoreProvider>
        <Licenses />
      </ListSelectionWithDataStoreProvider>
    ),
    restricted: true,
  },
  {
    path: SettingKey.UPGRADE,
    element: <Upgrade />,
    restricted: true,
  },
  {
    path: SettingKey.NETWORK,
    element: <Network />,
    restricted: true,
  },
  {
    path: SettingKey.DATETIME,
    element: <DateAndTime />,
    restricted: true,
  },
  {
    path: SettingKey.SERVICES,
    element: <Services />,
    restricted: true,
  },
  {
    path: SettingKey.STILLIMAGES,
    element: <StillImages />,
    restricted: true,
  },
];
