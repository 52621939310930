import moment from 'moment-timezone';
import drop from 'ramda/src/drop';
import head from 'ramda/src/head';
import init from 'ramda/src/init';
import isEmpty from 'ramda/src/isEmpty';
import last from 'ramda/src/last';
import split from 'ramda/src/split';
import zipObj from 'ramda/src/zipObj';
import { constant } from 'src/js/constant';
import { isNilOrEmpty } from 'src/js/util/global-util';
import v from 'voca';

export const convertCSVStringToData = <T>(csvString: string): T[] => {
  if (csvString == null) {
    return [];
  }
  csvString = csvString.replace(/\r/g, '');
  let lines = csvString.split('\n');
  const keys = split(',', head(lines));
  lines = drop(1, lines);
  lines = v.isEmpty(last(lines)) ? init(lines) : lines;

  return lines.map((line) => (!v.isEmpty(line) ? (zipObj(keys, split(',', line)) as unknown) : null)) as T[];
};

export const convertDataToCSV = <T>(data: T[]): string => {
  if (isEmpty(data)) {
    return '';
  }
  let file: string = Object.keys(head(data)).join(',') + '\n';
  data.forEach((row: T) => {
    file += Object.values(row).join(',') + '\n';
  });
  return file;
};

// initiates a download of the string as a file
// adapted from https://stackoverflow.com/a/24922761
export const downloadFile = (filename: string, data: string): void => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  if ((navigator as any).msSaveBlob) {
    // IE 10+
    (navigator as any).msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

// generates filename for file based on route name and current timestamp
export const chartDataFilename = (filePrefix?: string) => {
  const prefix = isNilOrEmpty(filePrefix) ? constant.defaultCSVFileName : filePrefix;
  return moment().format(`[${prefix}]-YYYY-M-D-H[h]mm[m]ss[s][.csv]`);
};
