import { apiClientWithoutHandler } from 'src/js/api';
import { APIPath } from 'src/js/api/route-path-index';
import { handleLogout } from 'src/js/pages/login/login';
import { Policies } from 'src/js/pages/security/policies/policies-models';
import { v4 as uuidv4 } from 'uuid';

import { constant } from '../constant';
import { pushHistory } from './nav-util';

export interface IdleAutoLogoutProps {}

export let idleAutoLogoutManager: IdleAutoLogoutManager = null;

const MILLISECONDS_IN_A_MINUTE = 60000;

let lastChecked = 0;
const onUserAction = () => {
  const now = Date.now();
  if (now - lastChecked > 1000) {
    lastChecked = now;
    idleAutoLogoutManager.updateIdleTimer();
  }
};

/**
 * Component to control the notifications. The component listens to dom events and will display
 * the notification based on this.
 *
 */
export class IdleAutoLogoutManager {
  uuid: string;
  authenticated: boolean;
  monitoring: boolean;
  logoutTimer: NodeJS.Timeout;
  policies: Policies;
  constructor(_props: IdleAutoLogoutProps) {
    this.authenticated = true;
    this.monitoring = false;
    this.uuid = uuidv4();
    this.policies = null;
    this.logoutTimer = null;
  }

  public updateIdleTimer() {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
    if (this.monitoring) {
      this.logoutTimer = setTimeout(() => {
        // this tab idled

        // first check if other tabs are active
        const lastActivity = localStorage.getItem(constant.lStorage.tabAction);
        const lastActivityNum = parseInt(lastActivity, 10);
        if (lastActivityNum > 0) {
          const limit = Date.now() - this.policies.autoLogoutTime * MILLISECONDS_IN_A_MINUTE;
          const diff = lastActivityNum - limit;
          if (diff > 0) {
            // another tab is active, not loging out
            return;
          }
        }

        // actual logout
        apiClientWithoutHandler.genericController.delete(APIPath.session).then(() => {
          handleLogout(false, pushHistory);
        });
      }, this.policies.autoLogoutTime * MILLISECONDS_IN_A_MINUTE);

      localStorage.setItem(constant.lStorage.tabAction, Date.now().toString());
    }
  }

  public startMonitoring() {
    // get the policy
    if (this.monitoring === true) {
      return;
    }

    apiClientWithoutHandler.genericController.get('/apis/policies').then((res: any) => {
      if (res?.data) {
        const policies: Policies = res.data;
        this.policies = policies;
        if (policies.autoLogout && policies.autoLogoutTime > 0) {
          this.monitoring = true;
          document.addEventListener('keydown', onUserAction); // keydown is better than keypress because it catches PageUp/PageDown
          document.addEventListener('mousemove', onUserAction);
          document.addEventListener('touchend', onUserAction); // touchpads
          document.addEventListener('wheel', onUserAction); // mouse scrolling
          this.updateIdleTimer();
        }
      }
    });
  }

  public stopMonitoring() {
    //console.log('stopping to monitor', this.uuid);
    document.removeEventListener('keydown', onUserAction);
    document.removeEventListener('mousemove', onUserAction);
    document.removeEventListener('touchend', onUserAction);
    document.removeEventListener('wheel', onUserAction);
    if (this.monitoring) {
      this.monitoring = false;
      if (this.logoutTimer) {
        clearTimeout(this.logoutTimer);
        this.logoutTimer = null;
      }
    }
  }
}

idleAutoLogoutManager = new IdleAutoLogoutManager({} as IdleAutoLogoutProps);
