import { TFunction } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { append, has, isNil, prop } from 'ramda';
import { convertCSVStringToData } from 'src/js/helper/csv-helper';
import { StatsInterval } from 'src/js/model/api/request/stream-stat-request';
import { StreamSrtStatsResponse } from 'src/js/model/api/response/stream-srt-stats-response';
import { StatisticSectionViewModel } from 'src/js/pages/statistic/statistic-view-model';
import { constant } from 'src/js/constant';
import { ChartDataViewModel, ChartViewModel } from 'src/js/view-model/chart-view-model';
import { DecoderFrameGraphResponse } from '../streaming/decoder/decoder-model';

// remove undefined rows
export const filterUndefinedRows = (sections: StatisticSectionViewModel[]): StatisticSectionViewModel[] => {
  sections.forEach((section) => {
    const twoColumns = section.twoColumns;
    section.rows = section.rows.filter((row) => {
      if (twoColumns === true) {
        return !isNil(row.value) || !isNil(row.value2); // TODO change to != null check
      }
      return !isNil(row.value);
    });
  });

  // DEV check unit keys (readonly check)
  // FIXME make a unit test
  /*
  const sectionskeys:any = {};
  const keys:any = {};
  sections.forEach((section) => {
    if (sectionskeys[section.title] !== undefined) {
      console.error('duplicated sections key', section.title);
    }
    sectionskeys[section.title] = 1;
    section.rows.map((row) => {
      if (keys[section.title + '-' + row.name] !== undefined) {
        console.error('duplicated key', section.title + '-' + row.name);
      }
      keys[section.title + '-' + row.name] = 1;
    });
  });*/

  return sections;
};

export const mapStreamSRTStatisticToPlotData = (
  data: (StreamSrtStatsResponse | DecoderFrameGraphResponse)[],
  yFields: string[],
  t: TFunction,
  showRedundancy?: boolean,
  csvValuesSection = 'csvValues',
): ChartViewModel[] => {
  // Create the view models with the proper key (will translate later)
  let viewModels: ChartViewModel[] = yFields.map((yField: string) => ({
    name: yField,
    values: [],
    label: yField,
  }));
  if (showRedundancy) {
    viewModels = viewModels.concat(
      yFields.map((yField: string) => ({
        name: yField + '_Path2',
        values: [],
        label: yField + '_Path2',
      })),
    );
  }

  // Map all the data to the view models
  data.slice(-120).map((series) => {
    // slice() is a failover if the backend returns spurious large amount of CSV data
    viewModels.map((field: ChartViewModel) => {
      // @ts-ignore
      const value = has(field.name, series) && prop(field.name, series);
      if (!isNil(value)) {
        field.values = append({ collectedAt: moment(series.DateTime), value: Number(value) }, field.values);
      }
    });
  });
  // Translate the keys
  const mapped = viewModels.map((viewModel) => ({
    name: viewModel.name,
    label: t(`statistics.chart.${csvValuesSection}.${viewModel.name}`),
    values: viewModel.values,
  }));
  return mapped;
};

export const mapStreamGenericStatisticToPlotData = (
  data: StreamSrtStatsResponse[],
  yFields: string[],
  t: TFunction,
  csvValuesSection = 'csvValues',
): ChartViewModel[] => {
  // Create the view models with the proper key (will translate later)
  const viewModels: ChartViewModel[] = yFields.map((yField: string) => ({
    name: yField,
    values: [],
    label: yField,
  }));

  // Map all the data to the view models
  data.slice(-120).map((series: StreamSrtStatsResponse) => {
    // slice() is a failover if the backend returns spurious large amount of CSV data
    viewModels.map((field: ChartViewModel) => {
      // @ts-ignore
      const value = has(field.name, series) && prop(field.name, series);
      if (!isNil(value)) {
        field.values = append({ collectedAt: moment(series.DateTime), value: Number(value) }, field.values);
      }
    });
  });
  // Translate the keys
  const mapped = viewModels.map((viewModel) => ({
    name: viewModel.name,
    label: t(`statistics.chart.${csvValuesSection}.${viewModel.name}`),
    values: viewModel.values,
  }));
  return mapped;
};

export const mapFrameSkipStatisticToBarData = (
  data: DecoderFrameGraphResponse[],
  yFields: string[],
  t: TFunction,
  showRedundancy?: boolean,
  csvValuesSection = 'csvValues',
): ChartViewModel[] => {
  // Create the view models with the proper key (will translate later)
  let viewModels: ChartViewModel[] = yFields.map((yField: string) => ({
    name: yField,
    values: [],
    label: yField,
  }));
  if (showRedundancy) {
    viewModels = viewModels.concat(
      yFields.map((yField: string) => ({
        name: yField + '_Path2',
        values: [],
        label: yField + '_Path2',
      })),
    );
  }

  // Map all the data to the view models
  data.slice(-120).map((series) => {
    // slice() is a failover if the backend returns spurious large amount of CSV data
    viewModels.map((field) => {
      // @ts-ignore
      const value = has(field.name, series) && prop(field.name, series);
      if (!isNil(value)) {
        field.values = append({ collectedAt: moment(series.DateTime), value: Number(value) }, field.values);
      }
    });
  });
  // Translate the keys
  const mapped = viewModels.map((viewModel) => ({
    name: viewModel.name,
    label: t(`statistics.chart.${csvValuesSection}.${viewModel.name}`),
    values: viewModel.values,
  }));
  return mapped;
};

/*
 * Victory helper
 */
export interface VictoryData {
  x: string | number; // number == unix timestamp
  y: number;
  yOriginal?: number; // because y number can be manipulated when y axis range changes (kbps => Mbps). Original value is kept here, can be used by tooltip
  lineName?: string;
  dataPointsAggregated?: number;
}

export const mapToVictoryData = (
  viewModels: ChartDataViewModel[],
  timescale: StatsInterval,
  lineName?: string,
): VictoryData[] => {
  return viewModels.map((viewModel, _index) => ({
    x: viewModel.collectedAt.format(
      timescale === StatsInterval.LAST_5_MINS ? constant.momentFormat.timeWithSeconds : constant.momentFormat.time,
    ),
    y: viewModel.value,
    lineName: lineName /* this is repeated but needed for tooltips callback */,
  }));
};

export const mapToVictoryInMinutes = (viewModels: ChartDataViewModel[], lineName?: string): VictoryData[] => {
  if (viewModels === undefined || viewModels.length == 0) {
    return viewModels as any;
  }
  return viewModels.map((viewModel, _index) => ({
    x: viewModel.collectedAt?.unix(),
    y: viewModel.value,
    lineName: lineName /* this is repeated but needed for tooltips callback */,
  }));
};

export const mapToVictoryInMinutesReduce = (
  viewModels: ChartDataViewModel[],
  reduceToIntervalSec: number,
  lineName?: string,
): VictoryData[] => {
  if (viewModels === undefined || viewModels.length == 0) {
    return viewModels as any;
  }
  const result: VictoryData[] = [];
  let startTime: number;
  let accumulator = 0; // x
  let dataPoints = 0;
  //let skip = 0;
  let lastBarTime = 0;
  // let blockAlignAdjusted = false;
  let distanceBetweenPoints = 5; // in seconds
  if (viewModels?.length > 2) {
    const totalSpan = Math.abs(viewModels[0].collectedAt.unix() - viewModels[viewModels.length - 1].collectedAt.unix());
    const oneUnitSpan = totalSpan / (viewModels.length - 1);
    distanceBetweenPoints = oneUnitSpan;
  }
  viewModels.map((viewModel, _index) => {
    const pointTime = viewModel.collectedAt.unix();
    if (result.length === 0 && dataPoints === 0 && pointTime % reduceToIntervalSec !== 0) {
      // skip non aligned blocks
      // console.log('skip', skip++, viewModel.collectedAt, pointTime)
      return;
    }
    if (dataPoints === 0) {
      lastBarTime = pointTime;
    }
    dataPoints++;
    accumulator += viewModel.value;
    if (startTime === undefined) {
      startTime = pointTime;
      /*  NOT WORKING OR NEEDED because of the pointTime % reduceToIntervalSec !== 0 line above
      if (lastRange != null) {
        const diff = startTime - lastRange();
       // console.log('diff', diff)
        if (lastRange() !== 0 && diff > 0 && blockAlignAdjusted === false && diff % reduceToIntervalSec !== 0) {
          // skip to align blocks
          console.log('skip for diff', diff, viewModel.collectedAt)
          startTime = undefined;
          accumulator = 0;
          dataPoints = 0;
          return;
        } else {
          if (blockAlignAdjusted === false) {
            blockAlignAdjusted = true;
          }
          if (lastRange() === 0) {
            setLastRange?.(startTime);
            console.log('FOUND startTime for setLastRange', diff, startTime, lastRange(), viewModel.collectedAt)
          }
        }
      }*/
    }

    if (dataPoints >= reduceToIntervalSec / distanceBetweenPoints) {
      const timeAdjustment =
        reduceToIntervalSec > distanceBetweenPoints ? reduceToIntervalSec - distanceBetweenPoints : 0;
      result.push({
        x: pointTime - timeAdjustment,
        y: accumulator || 0, //(Math.random() > 0.998 ? Math.round(Math.random()*10) : 0.1),
        dataPointsAggregated: dataPoints,
        lineName: lineName, // this is repeated but needed for tooltips callback
      });
      startTime = undefined;
      accumulator = 0;
      dataPoints = 0;
    }
  });

  // build up rest of graph gradually
  // the last points in the dataset are the most recent ones.
  if (dataPoints > 0) {
    result.push({
      x: lastBarTime,
      y: accumulator || 0, //(Math.random() > 0.998 ? Math.round(Math.random()*10) : 0.1),
      dataPointsAggregated: dataPoints,
      lineName: lineName, // this is repeated but needed for tooltips callback,
    });
  }

  return result;
};

// Note to convert CSV to object you can use d3 function like this
// var dp = d3.csv.parse(data);
export const mergeGraphDataInnerJoin = (data1: any[] | string, data2: any[] | string, keys: string[]) => {
  if (typeof data1 === 'string') {
    data1 = convertCSVStringToData(data1);
  }
  if (typeof data2 === 'string') {
    data2 = convertCSVStringToData(data2);
  }
  /* if (keys.includes('DateTime')) {
    delete keys.DateTime;
  }*/
  if (data2) {
    // MakitoX specific, merge both data set
    // 1 - rename columns of second path
    const dp2f = data2.map((item) => {
      keys.map((key) => {
        item[key + '2'] = item[key];
        delete item[key];
      });
      return item;
    });

    let mergedData = _.clone(data1);
    // loop through each line of second dataset, either add it or merge if existing timestamp
    dp2f.forEach((row) => {
      let foundObject = mergedData.find((d) => {
        return d.DateTime === row.DateTime;
      });
      let closest = Infinity;
      let closestObject: any = null;
      const rowDate = new Date(row.DateTime);
      if (!foundObject) {
        // find closest {
        mergedData.find((d) => {
          const loopDate = new Date(d.DateTime);
          const diff = Math.abs(loopDate.getTime() - rowDate.getTime());
          if (diff < closest) {
            closest = diff;
            closestObject = d;
          }
        });
      }

      if (closestObject) {
        // console.log('closest match', row.DateTime, closestObject.DateTime)
        foundObject = closestObject;
      }

      if (foundObject) {
        keys.map((key) => {
          foundObject[key + '2'] = row[key + '2'];
        });
      } else {
        // append
        // we dont have data yet for dp1, this row will be trimmed in the later filtering.
        mergedData.push(row);
      }
    });

    mergedData = _.filter(mergedData, (row) => {
      if (row[keys[0]] === undefined) {
        // Incomplete row. No dp1 entry
        return false;
      }
      if (row[keys[0] + '2'] === undefined) {
        // Incomplete row. No dp2 entry
        return false;
      }
      return true;
    });
    //console.log('datam', mergedData)
    data1 = mergedData; // replace
  }

  return data1;
};

// mapColumns()
// Used to rename columns in an array of objects
export const mapColumns = (input: any, map: Array<Record<string, any>>) => {
  if (!input || !Array.isArray(input)) {
    return input;
  }

  const mapped = input.map((line: any) => {
    const result = line;
    map.forEach((m) => {
      const src = Object.keys(m)[0];
      const dst = m[src];
      result[dst] = line[src];
      delete result[src];
    });
    return result;
  });
  return mapped;
};
