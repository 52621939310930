import { Decoder, DecoderViewModel } from './decoder-model';

export const mapViewModelToDecoder = (viewModel: Partial<DecoderViewModel>): Partial<Decoder> => ({
  id: viewModel.id,
  streamId: viewModel.streamId,
  altStreamId: viewModel.altStreamId,
  bufferingMode: viewModel.bufferingMode,
  bufferingDelay: viewModel.bufferingDelay,
  dropCorruptedFrames: viewModel.dropCorruptedFrames,
  multisyncBufferingDelay: viewModel.multisyncBufferingDelay,
  hdrDynamicRange: viewModel.hdrDynamicRange,
  state: viewModel.state,
  outputs: viewModel.outputs,
  outputFrameRate: viewModel.outputFrameRate,
  previewIntervalSec: viewModel.previewIntervalSec, // read-only
  previewEnabled: viewModel.previewEnabled, // read-only
  quadMode: viewModel.quadMode,
  stillImage: viewModel.stillImage,
  stillImageDelay: viewModel.stillImageDelay,
  stillImageFilename: viewModel.stillImageFilename,
});
