import { DynamicContainer, FormSection } from '@hai/ui-react';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormSwitch } from 'src/js/component/base/form/form-switch';
import { FormikTextfield } from 'src/js/component/base/form/formik-textfield';
import { StateButton } from 'src/js/component/base/state-button';
import { validateFormContext } from 'src/js/validator/validator';

import { PreviewViewModel, previewValidationSchema } from './preview-view-model';

export interface PreviewFormValues extends PreviewViewModel {}

interface Props {
  viewModel: PreviewViewModel;
  onSubmit?: (viewModel: PreviewViewModel) => Promise<any>;
  previewStatus: boolean;
  onPreviewServiceChange: (value: boolean) => void;
}

export const PreviewForm = (props: Props) => {
  const { t } = useTranslation();
  const { previewStatus, onPreviewServiceChange } = props;

  return (
    <Formik
      initialValues={props.viewModel}
      validationSchema={() => previewValidationSchema(t)}
      enableReinitialize={true}
      onSubmit={props.onSubmit}
    >
      {(formikProps) => {
        return (
          <form className="hai-mb-6">
            <FormSection title={t('settings.services.section.preview.title')}>
              {props.viewModel !== null && (
                <>
                  <DynamicContainer minColumns={2}>
                    <FormSwitch
                      loading={props.viewModel === undefined}
                      checked={previewStatus}
                      switchLabel={t('settings.services.section.preview.title')}
                      onChange={onPreviewServiceChange}
                    />
                    {previewStatus && (
                      <div className="ml-auto">
                        <StateButton
                          variant="primary"
                          onClick={() => validateFormContext(formikProps, t)}
                          disabled={!formikProps.dirty}
                        >
                          {t('general.apply')}
                        </StateButton>
                      </div>
                    )}
                  </DynamicContainer>
                  {previewStatus && (
                    <DynamicContainer minColumns={3}>
                      <FormikTextfield
                        name={'decoders[0].interval'}
                        type="number"
                        title={t('settings.services.section.preview.interval')}
                        hint={t('settings.services.section.preview.intervalExplain')}
                      />
                    </DynamicContainer>
                  )}
                </>
              )}
            </FormSection>
          </form>
        );
      }}
    </Formik>
  );
};
